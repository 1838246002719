import React from 'react';
import styled from 'styled-components';
// import { Draggable } from 'react-beautiful-dnd';
import KanbanModal from '../../Cruds/CommonModals/KanbanModal';
import { Card, CardBody } from 'reactstrap';
import fetch from '../../../config/service'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { settings } from '../../../redux/actions/settingsAction';
import Switch from '@material-ui/core/Switch';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import Config from '../../../config/config';
import RFIModal from '../../Cruds/CommonModals/RFIModal'

const CardContainer = styled.div`
    margin-bottom:  ${props => (!props.condensedView ? "1.5rem" : "0.5rem")};
    box-shadow: -4px 4px 6px 0 rgb(55 70 95 / 12%);
    position: relative;
    background: white;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color:#505050;
    background-color: ${props => (props.isDragging ? '#e6eefd' : 'white')};
  border-style: none none none solid;
  border-width: 2px;
  border:1px solid #dcd9d9!important;
 
`;
const theme = createTheme({
    overrides: {
        MuiSwitch: {

        },
    },
});
class Task extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // timer: this.props.task.timer === "start" ? "no" : "yes",
        };
    }

    //opens the task automatically when it is 
    componentDidMount = async () => {
        let taskId = localStorage.getItem('taskId')
        if (taskId && this.props.getlastTaskindex && this.props.showCard) {
            if (this.props.task && this.props.task._id === taskId) {
                this.getCardData(this.props.task, 'edit', 'e', 'loggedThroughmail')
            } else {
                this.gettaskData(taskId)
            }
        }
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (user) {
            await this.setState({
                userName: user._id
            })
        }
    }

    /*
    *get task details based on the id from Url 
    */
    gettaskData = async () => {
        return fetch('GET', `tasks/${localStorage.getItem('taskId')}`)
            .then(async (response) => {
                if (response && response.details) {
                    localStorage.removeItem('taskId')
                    this.getCardData(response.details, 'edit', 'e', 'loggedThroughmail')
                } else if (response && response.errorMessage) {
                    this.props.getErrorMessage(response.errorMessage)
                }
                await this.props.isLoading(false)
            }).catch((err) => {
                return err;
            });
    }

    /**
     * 
     * @param {Object} cardData 
     * @param {String} type 
     * @param {Object} e 
     * @param {String} view 
     * open task when click on task
     */
    getCardData = async (cardData, type, e, view) => {
        var data = cardData.title.includes("RFI")

        await this.setState({
            isOpenKanbanModal: data === true ? false : true,
            isOpenRFIModal: data === true ? true : false,
            selectedObj: cardData,
            // isOpenKanbanModal: true,
            taskData: cardData,
            type: type,
            view: view
        })
    }

    /**
     * 
     * @param {Object} values 
     * @param {String} done 
     * @param {String} type 
     * @returns PUT Call when the toggle is changed
     * task details,timer(bool),type("slider" or percentage) 
     *percentage is removed for now
     * 
     */

    submit = async (values, done, type) => {
        await this.props.isLoading(true)
        let userBody = {}
        if (type === "slider") {
            userBody = this.props.task
            userBody.timer = done
        } else {
            values.percentageDone = done
            delete values.state
            userBody = values
        }
        if (done === "stop") {
            localStorage.setItem("ManuallyOFF", true)
        }
        return fetch('PUT', `tasks/${values._id}`, userBody)
            .then(async (response) => {
                if (response && response.respCode) {
                } else if (response && response.errorMessage) {
                    this.props.getErrorMessage(response.errorMessage)
                }
                if (values.assignToId === this.state.userName) {
                    // let filters = this.props.filterData
                    // filters.loginTasks = this.props.loginTasks
                    // filters.unassignedOnly = this.props.unassignedOnly
                    // if (this.props.departmentArr) {
                    //     localStorage.setItem("selectedFlagfilters", JSON.stringify(this.props.departmentArr))
                    // }
                    // localStorage.setItem("isTaskfilters", JSON.stringify(filters))
                    // window.location.reload()
                    localStorage.setItem("isTaskUpdated", true)
                    window.localStorage.setItem("isTaskUpdated", true)
                    window.dispatchEvent(new Event('storage'))

                }
                await this.props.isLoading(false)
                this.props.getKanbanCards(this.props.filterData, '', this.props.dateQuery)
                // this.props.getKanbanCards()
            }).catch((err) => {
                return err;
            });
    }

    /*
    * cancel the modal if it opens from the card
    */
    cancelReset = () => {
        this.setState({
            isOpenKanbanModal: false,
        })
    }


    isOpenRFIModal = async () => {
        await this.setState({
            isOpenRFIModal: true,
        })
    }

    getErrorResponsefromServer = (resp) => {
        console.log("response", resp)
    }

    openRFIModal() {
        return (<RFIModal
            isOpenRFIModal={this.state.isOpenRFIModal}
            selectedObj={this.state.selectedObj}
            getErrorResponsefromServer={this.getErrorResponsefromServer}
            isCloseRFIModal={this.closeRFIModal}
            type={"edit"}
            formType={"edit"} />
        )

    }

    closeRFIModal = () => {
        this.setState({
            isOpenRFIModal: false
        })
        this.props.load(this.props.details);
    }


    /*
    *Calling Task modal when the card is clicked
    */
    getKanbanModal = () => {
        return (
            <KanbanModal
                openUserPasswordResetModal={this.state.isOpenKanbanModal}
                rowData={this.state.taskData}
                type='edit'
                view={this.state.view}
                filterData={this.props.filterData}
                dateQuery={this.props.dateQuery}
                cancelReset={this.cancelReset}
                getKanbanCards={this.props.getKanbanCards}
                departmentArr={this.props.departmentArr}
                unassignedOnly={this.props.unassignedOnly}
                loginTasks={this.props.loginTasks}
            />

        )
    }

    /**
     * 
     * @param {Object} e 
     * @param {bool} done 
     * @param {Object} task 
     *   for buttons Done Button 
     * not using now
     */
    showdonepercentbtn = (e, done, task) => {
        e.preventDefault();
        this.setState(prevState => ({
            show25percentbtn: !prevState.show25percentbtn,
            done: done
        }));
        if (this.state.show25percentbtn === true) {
            this.setState({
                done: done
            })
        }
        this.submit(task, done, 'percentage')
    }

    /**
     * 
     * @param {Object} e 
     * @param {Object} task 
     */
    onChangeevent = async (e, task) => {
        e.preventDefault();
        await this.setState({
            timer: task.timer === "start" ? "stop" : "start"
        })
        this.submit(task, this.state.timer, 'slider')
    }

    render() {
        const { handleSubmit, task, taskStates } = this.props;
        const statesLength = task && task.states ? task.states.length : 0
        const state = taskStates && taskStates.length > 12 ? taskStates.slice(0, 10) : taskStates
        return (
            <div>
                {/* 
                <Draggable 
                key={this.props.index}
                    draggableId={this.props.task._id}
                    index={this.props.index} */}
                <div>
                    {/* {(provided, snapshot) => ( */}
                    <CardContainer
                        // {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        // ref={provided.innerRef}
                        condensedView={this.props.condensedView}
                    >
                        <form className="form " onSubmit={handleSubmit(this.submit)}>
                            {!this.props.condensedView ?

                                <Card className="kanbanCard">
                                    <CardBody className=" kanbanCardbody"  >
                                        <div className="row ml-0 mr-0 align-items-center" style={{ cursor: "pointer" }}
                                            onClick={(e) => this.getCardData(task, 'edit', e, 'taskView')}
                                        >
                                            <span className="sub_text mainTitle" ><b>{task.title}</b>{task.title.includes("RFI") === true ? <span> (<b style={{ color: "rgb(0, 112, 192)" }}>{task.claimID}</b>)</span> : null}</span>
                                            <span className="kanban_title sub_text mb-1" data-toggle="tooltip" title={task.company} style={{ fontSize: 13, color: "rgba(0,112,192)" }} >{task.opraEstateID ? "(" + task.opraEstateID + ") " : ""}{task.company}</span>
                                            <span className="" style={{ marginTop: 1, marginBottom: 1 }}>
                                                {task.assignImage ?
                                                    <img
                                                        src={`${Config.imgUrl}/${task.assignImage}`}
                                                        alt=""
                                                        className="kanbancardimg ml-0" />
                                                    :
                                                    <span className="circle ml-0" style={{ backgroundColor: task.imgbgcolor, textTransform: "uppercase" }}>{this.props.imageIcon}</span>}
                                            </span>
                                            <div className="mt-1" style={{ flex: 1 }} >
                                                <span className="pl-1 kanban_title " data-toggle="tooltip" title={task.assignTo} style={{ fontSize: 13 }}>{task.assignTo}</span>
                                            </div>
                                            <span className="sub_text kanban_title" data-toggle="tooltip" title={state}><b className="sub_title">States: </b>{state} {statesLength > 3 ? ".." : ""}({statesLength})</span>
                                            <span className="sub_text kanban_title" data-toggle="tooltip" title={task.purpose}><b className="sub_title">Purpose: </b>{task.purpose}</span>
                                            <span className="sub_text kanban_title" data-toggle="tooltip" title={task.status}><b className="sub_title">Status: </b>{task.status}</span>
                                            <span className="sub_text kanban_title" data-toggle="tooltip" title={task.priority}><b className="sub_title">Priority: </b>{task.priority}</span>
                                        </div>
                                        <div className=" kanbanCardfooter row" style={{ margin: "0px", display: "block" }} >
                                            <span className="row ml-0 mr-0 pl-0 pr-0" onClick={(e) => this.getCardData(task, 'edit', e, 'taskView')}>
                                                <span className="pt-0 sub_text" >
                                                    {/* <b className="sub_title">Time Spent:</b> {task.timeSpentString ? task.timeSpentString : "0m"} */}
                                                </span>
                                            </span>
                                            <div className="kanbanprogress" >
                                                <span data-toggle="buttons" style={task.assignTo === this.state.userName ? { marginTop: 20 } : { pointerEvents: "none", marginTop: 20 }}>
                                                    <span className="timeField pl-0 " style={{ color: "rgb(143, 170, 220)", fontSize: 16 }}>
                                                        {task.taskID}
                                                        {/* Time Spent :{this.props.rowData && this.props.rowData.timeSpentString ? this.props.rowData.timeSpentString : '  0m'} */}
                                                    </span>
                                                    {/* <label className={`btnlabel btn btn-outline-primary  button_season${this.state.done >= 25 || task.percentageDone >= 25 ? ' active' : ''}`}
                                                    id="active">
                                                    <input type="checkbox" className="filterButton" name="season_revenue_1" value="25" onClick={e => this.showdonepercentbtn(e, '25', task)} />
                                                </label>
                                                <label className={`btnlabel btn btn-outline-primary  button_season${this.state.done >= 50 || task.percentageDone >= 50 ? ' active' : ''}`}
                                                >
                                                    <input type="checkbox" className="filterButton" name="season_revenue_2" value="50" onClick={e => this.showdonepercentbtn(e, '50', task)} />
                                                </label>
                                                <label className={`btnlabel btn btn-outline-primary  button_season${this.state.done >= 75 || task.percentageDone >= 75 ? ' active' : ''}`}>
                                                    <input type="checkbox" className="filterButton" name="season_revenue_3" value="75" onClick={e => this.showdonepercentbtn(e, '75', task)} />
                                                </label>
                                                <label className={`btnlabel btn btn-outline-primary  button_season${this.state.done === 100 || task.percentageDone === 100 ? ' active' : ''}`}>
                                                    <input type="checkbox" className="filterButton" name="season_revenue_3" value="100" onClick={e => this.showdonepercentbtn(e, '100', task)} />
                                                </label> */}
                                                </span>
                                                <>
                                                    <MuiThemeProvider theme={theme} style={task.assignToId === this.state.userName || task.assignTo !== "Unassigned" ? { marginTop: 10 } : { pointerEvents: "none", marginTop: 10, cursor: "none" }}>
                                                        <FormGroup style={{ marginLeft: "auto" }} onClick={task.assignToId === this.state.userName ? (e) => this.onChangeevent(e, task) : ""}>
                                                            <Switch
                                                                className="kanban_Switch mb-1"
                                                                checked={task.timer === "start" ? true : false}
                                                                style={{ height: '0px', width: 45 }}
                                                            // 
                                                            />
                                                        </FormGroup>
                                                    </MuiThemeProvider>
                                                </>
                                            </div>
                                        </div>

                                    </CardBody>

                                </Card>
                                : <Card className="kanbanCard">
                                    <CardBody className=" kanbanCardbody"  >
                                        <div className="row ml-0 mr-0 align-items-center" style={{ cursor: "pointer" }}

                                        ><span onClick={(e) => this.getCardData(task, 'edit', e, 'taskView')} style={{ width: "100%" }}>
                                                {/* <p className="d-flex mt-0" style={{ width: "100%" }}><span className="kanban_title sub_text mb-1" data-toggle="tooltip" title={task.company} style={{ fontSize: 13, color: "rgba(0,112,192)" }} >{task.opraEstateID ? "(" + task.opraEstateID + ") " : ""}{task.company}</span>
                                                <span className="timeField pl-0 mr-0 ml-auto" style={{ color: "rgb(143, 170, 220)", fontSize: 13 }}>
                                                    {task.taskID}
                                                    {/* Time Spent :{this.props.rowData && this.props.rowData.timeSpentString ? this.props.rowData.timeSpentString : '  0m'} */}
                                                {/* </span></p> */}
                                                <p className="d-flex mt-0" style={{ width: "100%" }}>
                                                    <span className=" kanban_title" data-toggle="tooltip" title={state} style={{ fontSize: 13, color: "rgba(0,112,192)" }}>{task.opraEstateID && !task.taskTitle ? "(" + task.opraEstateID + ") " : ""}{task.taskTitle ? task.taskTitle : task.company} </span>
                                                    <span className=" ml-auto" data-toggle="tooltip" title={task.taskID} style={{ color: "rgb(143, 170, 220)", fontSize: 13, textAlign: "end" }}>
                                                        {task.taskID}</span>
                                                </p>
                                                <p className="d-flex mt-0 justify-content-between" style={{ width: "100%" }}>
                                                    <span className="sub_text kanban_title" data-toggle="tooltip" title={state}><b className="sub_title">States: </b>{state} {statesLength > 3 ? ".." : ""}({statesLength})</span>
                                                    <span className="sub_text kanban_title" data-toggle="tooltip" title={task.priority}><b className="sub_title">Priority: </b>{task.priority}</span>
                                                </p>
                                                <p className="d-flex mt-0 justify-content-between" style={{ width: "100%" }}>
                                                    <span className="sub_text kanban_title" data-toggle="tooltip" title={task.title}><b className="sub_title">Label: </b>{task.title}</span>
                                                    <span className="sub_text kanban_title" data-toggle="tooltip" title={task.purpose}><b className="sub_title">Purpose: </b>{task.purpose}</span>
                                                </p>
                                                <p className="d-flex mt-0 justify-content-between" style={{ width: "100%" }}>
                                                    <span className="sub_text kanban_title" data-toggle="tooltip" title={task.status}><b className="sub_title">Status: </b>{task.status}</span>
                                                </p>

                                            </span>
                                            <span style={{ width: "100%" }}>
                                                <p className="d-flex mt-0 justify-content-between" style={{ width: "100%" }}>
                                                    <span className="d-flex" onClick={(e) => this.getCardData(task, 'edit', e, 'taskView')}>
                                                        <span className="" style={{ marginTop: 1, marginBottom: 1 }}>
                                                            {task.assignImage ?
                                                                <img
                                                                    src={`${Config.imgUrl}/${task.assignImage}`}
                                                                    alt=""
                                                                    className="kanbancardimg ml-0" />
                                                                :
                                                                <span className="circle ml-0" style={{ backgroundColor: task.imgbgcolor, textTransform: "uppercase" }}>{this.props.imageIcon}</span>}
                                                        </span>
                                                        <div className="mt-1" style={{ flex: 1 }} >
                                                            <span className="pl-1 kanban_title " data-toggle="tooltip" title={task.assignTo} style={{ fontSize: 13 }}>{task.assignTo}</span>
                                                        </div>
                                                    </span>
                                                    <MuiThemeProvider theme={theme} style={task.assignToId === this.state.userName || task.assignTo !== "Unassigned" ? { marginTop: 10 } : { pointerEvents: "none", marginTop: 10, cursor: "none" }}>
                                                        <FormGroup style={{ marginLeft: "auto", float: "right" }} onClick={task.assignToId === this.state.userName ? (e) => this.onChangeevent(e, task) : ""}>
                                                            <Switch
                                                                className="kanban_Switch mb-0 mt-0"
                                                                checked={task.timer === "start" ? true : false}
                                                                style={{ height: '0px', width: 45 }}
                                                            // 
                                                            />
                                                        </FormGroup>
                                                    </MuiThemeProvider>
                                                </p>
                                            </span>

                                        </div>

                                    </CardBody>

                                </Card>}
                        </form>
                    </CardContainer>

                    {/* ) */}
                    {/* } */}
                </div>
                {/* </Draggable> */}


                {
                    this.state.isOpenKanbanModal ?
                        this.getKanbanModal()
                        : null
                }
                {this.state.isOpenRFIModal ? this.openRFIModal() : null}

            </div >
        );

    }
}
Task = reduxForm({
    form: 'Task', // a unique identifier for this form
    enableReinitialize: true,
})(Task);

// You have to connect() to any reducers that you wish to connect to yourself
Task = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    {
        load: loadAccount,// bind account loading action creator
        settingsLoad: settings //binding settingsDAta
    }
)(Task);

export default withTranslation('common')(Task);

