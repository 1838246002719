
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
// fecth method from service.js file
import apiCalls from '../../config/apiCalls';
import fetch from '../../config/service';
import dateFormats from '../UI/FormatDate/formatDate';
import { withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import { CSVLink } from "react-csv";
import fetchMethodRequest from '../../config/service';
import Loader from '../App/Loader';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';

class NamesLookupFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      setGoButton: true,
      searchName: ""
    };
    localStorage.removeItem("searchName")
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.getStatuses()
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getStatuses = () => {
    let apiUrl;
    return fetchMethodRequest('GET', 'seller/UniqueDealStatus')
      .then(async (response) => {
        if (response && response.statuses && response.statusDetails) {
          console.log("response")
          let statusLabels = response.statuses;
          let deatilsLabels = response.statusDetails;
          let modifiedStatusLabels = [];
          let modifiedStatusDetailsLabels = [];
          for (let label of statusLabels) {
            if (label.DealStatus != null) {
              modifiedStatusLabels.push({
                label: label.DealStatus,
                value: label.DealStatus,
              })
            }
          }
          for (let label of deatilsLabels) {
            if (label.DealType != null) {
              modifiedStatusDetailsLabels.push({
                label: label.DealType,
                value: label.DealType,
              })
            }
          }
          await this.setState({
            DealStatus: modifiedStatusLabels,
            DealStatusDetails: modifiedStatusDetailsLabels,
          })
        } else {
          this.setState({
            DealStatus: [],
            DealStatusDetails: [],
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage']) {
              this.setState({
                sessionWarning: true
              })
            }
          } else if (localStorage.getItem('sessionexpired') === true) {
            let sessionexpired = await localStorage.getItem('sessionexpired')
            if (sessionexpired === "true") {
              await this.setState({ sessionExpiryModal: true })
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  submit = async (values) => {
    if (values === "Go") {
      if (this.state.searchName && (this.state.searchName !== "" || this.state.searchName === null)) {
        await this.setState({
          setGoButton: true
        })
        let filterCriteria = this.props.filterCriteria
        localStorage.setItem("searchName", this.state.searchName.EstateID)

        this.props.getFilteredValues(filterCriteria, this.state.searchName, this.state.selectedStatus, this.state.selectedStatusDetails)
      } else {
        this.props.getErrorMessage("Please search for a company")
      }
    }
  }

  onSearchNameChange = async (e) => {
    this.setState({
      searchName: e.target.value,
      setGoButton: false
    })
  }

  onChangeStatus = async (e) => {
    await this.setState({
      selectedStatus: e.value,
      setGoButton: false
    })
  }

  onChangeStatusDetails = async (e) => {
    await this.setState({
      selectedStatusDetails: e.value,
      setGoButton: false
    })
  }

  // clear input data
  clearFilter = async (field) => {
    await this.setState({
      [field]: "",
      setGoButton: false
    })
  }

  clearNameFilter = async (field) => {
    await this.setState({
      [field]: "",
      setGoButton: true
    })
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Loader
          loader={this.state.isLoading}
          progress={this.state.progress}
          className={"progress-loader"} />
        <div style={{ display: 'block', border: "2px solid lightgrey" }} >
          <form className="form" style={{ backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px" }} onSubmit={handleSubmit(this.submit)}>
            <div className='row col-sm-12 m-0 p-0 d-flex'>
              {/* {this.props.type === "Name Lookup" ? */}
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                <label style={{ marginBottom: 4, width: "100%" }}>
                  <div className='row'>
                    <div className='col-sm-4'>
                      {'Status'}
                    </div>
                    <div className='col-sm-8' style={{ width: "87%", display: "inline-flex", justifyContent: "right" }}>
                      <span >
                        <FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          id={"clear_Deal"}
                          color={this.state.selectedStatus ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          title="Clear Filter"
                          onClick={() => this.clearFilter('selectedStatus')}
                          style={this.state.selectedStatus ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        /></span>
                    </div>
                  </div>
                </label>
                <MultiSelect
                  name="status"
                  id='status'
                  style={{ height: 33, width: "100%" }}
                  options={this.state.DealStatus}
                  value={this.state.selectedStatus}
                  onChange={(e) => this.onChangeStatus(e, 'status', 'eq')}
                  placeholder={"Select Status"}
                  size={"20"} />
              </div>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                <label style={{ marginBottom: 4, width: "100%" }}>
                  <div className='row'>
                    <div className='col-sm-4'>
                      {'Status Type'}
                    </div>
                    <div className='col-sm-8' style={{ width: "87%", display: "inline-flex", justifyContent: "right" }}>
                      <span >
                        <FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          id={"clear_Deal"}
                          color={this.state.selectedStatusDetails ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          title="Clear Filter"
                          onClick={() => this.clearFilter('selectedStatusDetails')}
                          style={this.state.selectedStatusDetails ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        /></span>
                    </div>
                  </div>
                </label>
                <MultiSelect
                  name="statusDetails"
                  id='statusDetails'
                  style={{ height: 33, width: "100%" }}
                  options={this.state.DealStatusDetails}
                  value={this.state.selectedStatusDetails}
                  onChange={(e) => this.onChangeStatusDetails(e, 'statusDetails', 'eq')}
                  placeholder={"Select Type"}
                  size={"20"} />
              </div>
              <div className='col-sm-4' style={{ textAlign: "left", placeSelf: "center" }}>
                <div className='row' style={{ alignItems: "center" }}>
                  <div className='col-sm-10' style={{ padding: "initial" }} >
                    <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                      {this.props.type === "Name Lookup" ? 'Business Name' : 'Buisness Add.'}
                      {<div style={{ width: "79%", display: "inline-flex", justifyContent: "space-between" }}>
                        <span >
                          <FontAwesomeIcon icon={faStarOfLife}
                            className='pl-1'
                            color='red'
                            data-toggle="tool-tip"
                            title="Mandotary Field"
                            style={{ width: 11 }}
                          /></span>
                        <span >
                          <FontAwesomeIcon icon={faTimes}
                            className='pl-1 mr-2'
                            id={"clear_Deal"}
                            color={this.state.searchName ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            title="Clear Filter"
                            onClick={() => this.clearNameFilter('searchName')}
                            style={this.state.searchName ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                          /></span>
                      </div>}
                    </label>
                    <div >
                      <InputText
                        type="search"
                        name='searchName'
                        id='searchName'
                        value={this.state['searchName']}
                        onChange={(e) => this.onSearchNameChange(e)}
                        placeholder={this.props.type === "Name Lookup" ? 'Search Business Name' : 'Search Buisness Location'}
                        size="100"
                      />
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <Button color="primary"
                      outline
                      className="mt-4"
                      style={{ color: "white", backgroundColor: '#354f6e' }}
                      disabled={this.state.setGoButton || this.state.searchName === "" ? true : false}
                      onClick={() => this.submit("Go")}>
                      Go
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div >
      </div >
    );
  }
}



NamesLookupFilter = reduxForm({
  form: "User Password Reset Form", // a unique identifier for this form
  enableReinitialize: true,
})(NamesLookupFilter);

// You have to connect() to any reducers that you wish to connect to yourself
NamesLookupFilter = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(NamesLookupFilter);

export default withTranslation('common')(NamesLookupFilter);