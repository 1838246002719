import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'reactstrap';

export const ChainOfTitle = (props) => {
    // let selectedFiles = useRef(null);  
    const [selectedFiles, setSelectedFiles] = useState({});
    useEffect(() => {
        setInitialSelection();
    }, [props.chainOfTitleData, props.showChainOfTitle])

    const getBGColor = (num) => {
        let color = null
        switch (num) {
            case 1:
                color = '#F9F3CC';
                break;
            case 2:
                color = '#DFCCFB';
                break;
            case 3:
                color = '#D0E7D2';
                break;
            case 4:
                color = '#fff2f2';
                break;
            case 5:
                color = '#FFFEC8';
                break;
            case 6:
                color = '#e3ffe3';
                break;
            case 7:
                color = '#ffeaea';
                break;
            case 8:
                color = '#eee0e5';
                break;
            case 9:
                color = '#e7fff8';
                break;
            case 10:
                color = '#eef1f8';
                break;
            case 11:
                color = '#faf8e8';
                break;
            case 12:
                color = '#ffefda';
                break;


            default:
                color = null;
                break;
        }
        return color;
    }

    /**
     * on select deselect files check COT validation.
     * @param {Number} index 
     * @param {Object} obj 
     */
    const onSelectCheckbox = (index, obj) => {
        let tempObj = JSON.parse(JSON.stringify(selectedFiles))
        if (selectedFiles[index]) {
            delete tempObj[index];
        } else {
            tempObj[index] = obj;
        }
        setSelectedFiles(tempObj);
        props.checkCOTFileSelection(Object.values(tempObj));
    }

    const setInitialSelection = () => {
        let tempObj = {};
        props.chainOfTitleData.forEach((elem, i) => {
            if (elem.fileName) {
                tempObj[i] = elem;
            }
        });
        setSelectedFiles(tempObj);
    }


    return <>

        <div className='p-2 chain-of-title' style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <div className='d-flex flex-column'>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>{props.fromGuide ? 'Date' : 'Event Date Year'}</th>
                            <th style={{ width: '29%' }}>Description</th>
                            <th style={{ width: '22%' }}>Name Sheet Name</th>
                            <th style={{ width: '22%' }}>Connected Name</th>
                            {!props.fromGuide && <th style={{ width: '22%' }}>File Name</th>}

                        </tr>
                    </thead>
                    <tbody>
                        {(props.chainOfTitleData && props.chainOfTitleData.length > 0) &&
                            <>
                                {props.chainOfTitleData.map((elem, i) => {
                                    return <tr>
                                        <td >{elem.year}</td>
                                        <td >
                                            {elem.description}
                                        </td>

                                        <td style={{ backgroundColor: `${getBGColor(elem.colorCode)}` }} >{elem.businessName}</td>
                                        <td style={{ backgroundColor: `${props.cotHirCount === elem.colorCode + 1 ? null : getBGColor(elem.colorCode + 1)}` }}>{elem.legacyConnectedName}</td>
                                        <td >{elem.fileName ? <div className='d-flex align-items-baseline'>
                                            <input type='checkbox' checked={selectedFiles[i] ? true : false} className='mr-2' onChange={() => { onSelectCheckbox(i, elem) }} />
                                            <span data-toggle="tooltip" data-placement="left" title={`Document ID - ${elem.docId}`}>{elem.fileName}</span> </div>
                                            : 'No document available'}
                                        </td>

                                    </tr>
                                })}
                            </>
                        }

                    </tbody>
                </table>
            </div>
            {!props.fromGuide &&
                <div className='text-center mt-4'>
                    <Button color='primary' outline onClick={() => { props.showHideChainOfTitle(false, Object.values(selectedFiles)) }} disabled={props.disableCOTAdd}>Add</Button>
                    {/* <Button color='primary' outline onClick={() => { props.loadClaimPacket(Object.values(selectedFiles)) }} disabled={props.disableCOTAdd}>Add</Button> */}
                    <Button color='primary' outline onClick={() => { props.showHideChainOfTitle(false) }} >Cancel</Button>

                </div>

            }
            {(props.disableCOTAdd) && <div className='text-center mt-1'>The Add button will remain disabled until all Chain Of Title documents are available.</div>}

        </div>


    </>
}


