import React from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Card, CardBody, ButtonToolbar, ButtonGroup, Container, Row, Col, Table,
} from 'reactstrap';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from '../../../App/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DefaultInput from '../../shared/components/form/DefaultInput';
import DefaultInput from '../../../../shared/components/form/DefaultTextArea';
import { faPlusCircle, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import config from '../../../../config/config'
// import fetch from '../../../../config/service';
import fetchMethodRequest from '../../../../config/service';
import DeleteRowModal from '../../CommonModals/DeleteRowModal';
import PropertySearchUpdateModal from '../../CommonModals/PropertySearchUpdateModal';
import ExpiredSessionModal from '../../CommonModals/ExpiredSessionModal'
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Search from './Search'
import PreviousSearches from './PreviousSearches'
import dateFormats from '../../../UI/FormatDate/formatDate';
let timeout
class PropertySearch extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        payload: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.string,
            value: PropTypes.string,
        })).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            expandedRows: null,
            index: 0,
            pageLimit: 100,
            activeIndex: 0,
            dashboardData: {},
            isLoading: false,
            type: "propertySearch",
            filterCriteria: [],
            serverCriteria: [],
            Filters: [
                { value: "propertyId", field: "Prop. ID" },
                { value: "sourceState", field: "Source State" },
                { value: "statePropertyId", field: "State Prop. ID" },
                { value: "propertyOwnerName", field: "Owner Name" },
                { value: "propertyAddressLine1", field: "Owner Address" },
                // { value: "propertyValue", field: "propertyValue" },
                { value: "holderName", field: "Holder Name" },
                // { value: "propertyType", field: "propertyType" },
                { value: "propertyDescription", field: "Property Description" },
                // { value: "shares", field: "Shares" },
                // { value: "claimNotes", field: "Claim Notes" },
                // { value: "opraAddedDate", field: "Imported Date" },
                { value: "escheatmentDate", field: "Date Escheated" },
                { value: "dateOfLastContact", field: "Date of Last Contact" },
                // { value: "propertyAddressLine1", field: "Owner Address Line 1" },
                // { value: "propertyAddressLine2", field: "Owner Address Line 2" },
                // { value: "propertyAddressLine3", field: "Owner Address Line 3" },
                { value: "keyWord", field: "Key Word" },
                // { value: "fileYear", field: "File Year" },
                { value: "propertyDataSource", field: "Data Source" },
                { value: "propertyStatus", field: "Property Status" },
            ],
            States: [
                { name: 'AF Pacific', key: 'AF' },
                { name: 'Albama', key: 'AL' },
                { name: 'Alaska', key: 'AK' },
                { name: 'Alberta', key: 'Albt' },
                { name: 'Antilles', key: 'Ant' },
            ],
            selectedStates: [],
            Sources: [
                { name: 'State File', key: 'SF' },
                { name: 'Bankruptcy Courts', key: 'BC' },
                { name: 'Co-op', key: 'CO' },
                { name: 'County', key: 'CNT' },
                { name: 'State Warrant', key: 'SW' },
            ],
            selectedSources: [],
            showSearchResult: false,
            showPreviousSearches: false,
            openClearModal: false,
            propertyStatuses: [],
            selectedPropertyStatus: []
        };
    }
    componentDidUpdate() {
        // if (localStorage.getItem('loggedTime')) {
        //     let loggedTime = localStorage.getItem('loggedTime')
        //     if (timeout) {
        //         clearTimeout(timeout)
        //     }
        //     timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
        // }

    }
    componentDidMount = () => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (user) {
            this.setState({
                loginData: user,
                role: user.role,
                RulesListArray: []
            })
        }
        this.getStates()
        this.getDataSource()
        this.getPropertyStatus()
    }

    getStates = async () => {

        await fetchMethodRequest('GET', `seller/StateList?type=test`)
            .then(async (response) => {
                if (response) {
                    if (response.respCode) {
                        showToasterMessage(response.respMessage, 'success');
                        let states = []
                        for (let state of response.states) {
                            states.push({ label: state.Name, value: state.Abbreviation })
                        }
                        await this.setState({
                            States: states
                        })
                    } else if (response.errorMessage) {
                        // display message
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }
            })
    }

    getDataSource = async () => {
        await fetchMethodRequest('GET', `seller/dataSourceList?type=test`)
            .then(async (response) => {
                if (response) {
                    if (response.respCode) {
                        showToasterMessage(response.respMessage, 'success');
                        let dataSources = []
                        for (let source of response.dataSources) {
                            dataSources.push({ label: source.Name, value: source.Name })
                        }
                        await this.setState({
                            Sources: dataSources
                        })
                    } else if (response.errorMessage) {
                        // display message
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }
            })
    }

    getPropertyStatus = async () => {
        await fetchMethodRequest('GET', `seller/propertyStatus?type=test`)
            .then(async (response) => {
                if (response) {
                    if (response.respCode) {
                        showToasterMessage(response.respMessage, 'success');
                        let propertyStatuses = []
                        for (let status of response.propertyStatuses) {
                            propertyStatuses.push({ label: status.Name, value: status.Name })
                        }
                        await this.setState({
                            propertyStatuses: propertyStatuses
                        })
                    } else if (response.errorMessage) {
                        // display message
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }
            })
    }

    hoverTemplate = (rowData, column) => {
        return <span className='' data-toggle="tool-tip"
            title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
            {rowData[column.field] ? rowData[column.field] : ""}
        </span >
    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    isShowLoader = async (value) => {
        this.setState({
            isLoading: value
        })
        if (value === true) {
            this.handlePercentage("1")
        }
    }

    getIndex = async (i) => {
        let index = this.state.filterCriteria.findIndex((obj) => obj.index === i)
        return index;
    }

    // Edit
    openFormModal = async (i) => {
        let index = await this.getIndex(i)
        this.setState({
            editRowIndex: index,
            openFormModal: true,
        });
    }

    //close Edit modal
    closeFormModal = async () => {
        this.setState({
            openFormModal: false,
        })
    }

    // conformation for delete item
    deleteConfirmAction = async (i) => {
        let index = await this.getIndex(i)
        this.setState({
            deleteRowIndex: index,
            openDeleteModal: true,
        });
    }

    //close delete modal
    closeDeleteModal = async () => {
        this.setState({
            openDeleteModal: false,
        })
    }

    deleteItem = async () => {
        let criteria = this.state.filterCriteria;
        criteria.splice(this.state.deleteRowIndex, 1);

        let serverCriteria = this.state.serverCriteria;
        serverCriteria.splice(this.state.deleteRowIndex, 1);
        await this.setState({
            filterCriteria: criteria,
            serverCriteria: serverCriteria,
            openDeleteModal: false,
            deleteRowIndex: -1,
            showSearchResult: false
        })
    }

    // Delete Modal
    getDeleteRowModal() {
        return (
            <DeleteRowModal
                openDeleteModal={this.state.openDeleteModal}
                closeDeleteModal={this.closeDeleteModal}
                deleteSelectedRow={this.deleteItem}
                confirmModalText={"Are you sure want to delete"}
                type={this.props.type}
            />
        )
    }

    UpdateFilter = (Criteria, serverCriteria) => {
        console.log("Criteria, serverCriteria", Criteria, serverCriteria)
        this.setState({
            filterCriteria: Criteria,
            openFormModal: false,
            serverCriteria: serverCriteria,
            showSearchResult: false
        })
    }

    getPropertySearchUpdateModal() {
        return (
            <PropertySearchUpdateModal
                openFormModal={this.state.openFormModal}
                closeDeleteModal={this.closeFormModal}
                filterCriteria={this.state.filterCriteria}
                serverCriteria={this.state.serverCriteria}
                editRowIndex={this.state.editRowIndex}
                criteria={this.state.filterCriteria[this.state.editRowIndex]}
                UpdateFilter={this.UpdateFilter}
                Actions={this.Actions}
                States={this.state.States}
                Sources={this.state.Sources}
                selectedStates={this.state.selectedStates}
                selectedSources={this.state.selectedSources}
                propertyStatuses={this.state.propertyStatuses}
            />
        )
    }

    Actions = async (index) => {
        console.log("index", index)
        return (
            <div className='row d-flex col-12'
                style={{ justifyContent: 'center' }}>
                {
                    <FontAwesomeIcon
                        icon={faPen}
                        className='delete search'
                        color={"#85878A"}
                        style={{
                            height: "25px",
                            width: "25px",
                            marginRight: "10px"
                        }}
                        onClick={() => this.openFormModal(index)}
                    />
                }
                {
                    <FontAwesomeIcon
                        icon={faTrash}
                        className='delete search'
                        color={"#85878A"}
                        style={{
                            height: "25px",
                            width: "25px",
                            marginLeft: "10px"
                        }}
                        onClick={() => this.deleteConfirmAction(index)}
                    />
                }

            </div >
        )
    }

    UpdateCriteria = async (field, type) => {
        this.setState({ showSearchResult: false })
        let Criteria = this.state.filterCriteria;
        let serverCriteria = this.state.serverCriteria;
        let filter = {}
        let index = this.state.index
        if (type == "input1") {
            filter = {
                Criteria: field,
                Type: this.state[field + "word"],
                Value: this.state[field],
                Actions: await this.Actions(index),
                index: index
            }
        } else if (type == "input2") {
            filter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedStates),
                Actions: await this.Actions(index),
                index: index
            }
        } else if (type == "input3") {
            filter = {
                Criteria: field,
                Type: "MinMax",
                Value: `${this.state[field + "min"]} to ${this.state[field + "max"]}`,
                minValue: this.state[field + "min"],
                maxValue: this.state[field + "max"],
                Actions: await this.Actions(index),
                index: index
            }

        } else if (type == "input4") {
            filter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedSources),
                Actions: await this.Actions(index),
                index: index
            }
        } else if (type == "input5") {
            filter = {
                Criteria: field,
                Type: "autoComplete",
                Value: this.state[field],
                Actions: await this.Actions(index),
                index: index
            }
        } else if (type == "input6") {
            let inputValue = ''
            if (this.state[field + "minDate"] && this.state[field + "maxDate"]) {
                inputValue = `${dateFormats.formatDate(this.state[field + "minDate"], "MM/DD/YY")} to ${dateFormats.formatDate(this.state[field + "maxDate"], "MM/DD/YY")}`
            } else if (this.state[field + "minDate"] && !this.state[field + "maxDate"]) {
                inputValue = `from ${dateFormats.formatDate(this.state[field + "minDate"], "MM/DD/YY")}`
            } else if (!this.state[field + "minDate"] && this.state[field + "maxDate"]) {
                inputValue = `upto ${dateFormats.formatDate(this.state[field + "maxDate"], "MM/DD/YY")}`
            }
            filter = {
                Criteria: field,
                Type: "MinMaxDate",
                Value: inputValue,
                minDateValue: this.state[field + "minDate"],
                maxDateValue: this.state[field + "maxDate"],
                Actions: await this.Actions(index),
                index: index
            }
        } else if (type == "input7") {

            filter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedPropertyStatus),
                Actions: await this.Actions(index),
                index: index
            }
        }

        Criteria.push(filter)
        console.log("Criteria,>>>>>>>>>>", Criteria)
        let serverFilter = { ...filter };
        delete serverFilter.Actions;
        serverCriteria.push(serverFilter)

        console.log("Criteria,>>>>>>>>>>", Criteria)

        await this.setState({
            filterCriteria: Criteria,
            serverCriteria: serverCriteria
        })
        // clear filters
        await this.setState({
            [field + "word"]: "",
            [field]: "",
            [field + "min"]: "",
            [field + "max"]: "",
            [field + "minDate"]: "",
            [field + "maxDate"]: "",
            selectedStates: [],
            selectedSources: [],
            selectedPropertyStatus: [],
            index: this.state.index + 1,
            expandedRows: []
        })
    }

    handlekeyValueChange = async (value, field) => {
        await this.setState({
            [field + "word"]: value
        })
    }

    onStateChange = async (e) => {
        console.log("e.value", e)
        // let _selectedStates = [...this.state.selectedStates];

        // if (e.checked)
        //     _selectedStates.push(e.value);
        // else
        //     _selectedStates = _selectedStates.filter(state => state.Abbreviation !== e.value.Abbreviation);
        // await this.setState({
        //     selectedStates: _selectedStates
        // })


        if (e && e.value) {
            await this.setState({
                selectedStates: e.value,
            })
        } else {
            await this.setState({
                selectedStates: e,
            })
        }

    };

    onSourceChange = async (e) => {
        if (e && e.value) {
            await this.setState({
                selectedSources: e.value,
            })
        } else {
            await this.setState({
                selectedSources: e,
            })
        }
    };

    onPropertyStatusChange = async (e) => {
        if (e && e.value) {
            await this.setState({
                selectedPropertyStatus: e.value,
            })
        } else {
            await this.setState({
                selectedPropertyStatus: e,
            })
        }
    }

    ClearCriteria = () => {
        this.setState({
            filterCriteria: [],
            serverCriteria: [],
            openClearModal: false,
            showSearchResult: false
        })
    }

    GetDataFromServer = async () => {
        this.setState({
            isLoading: true
        })
        this.handlePercentage('1')
        let url = `${config.apiDealUrl}seller/getPropertyPresentFromDifferentSources?propertyId=${this.state.PropertyId}&site=Opra2`
        let request = fetch(url, { method: "GET" })

        return request
            .then(res => {
                let result = res.json()
                return result
            })
            .then(async (response) => {
                if (response) {
                    if (response.respCode && response.respMessage) {
                        showToasterMessage(response.respMessage, 'success');
                        await this.setState({
                            data: response.details,
                            isLoading: false
                        })
                        this.updateTable()
                    } else if (response.errorMessage) {
                        // display message
                        showToasterMessage(response.errorMessage, 'error');
                        this.setState({
                            isLoading: false
                        })
                    }
                }
            })
    }

    //getRow Expand collapse for Deal Sizing in Sellers
    rowExpansionTemplate = (data) => {
        let input1 = ["Owner Name", "Owner Address", "Owner City", "Holder Name", "Key Word", "Property Description"] // owner city
        let input2 = ["State", "Source State"]
        let input3 = ["Property Value", "Shares", "File Year"]
        let input4 = ["Data Source"]
        let input5 = ["Owner postal code", "Prop. ID", "State Prop. ID"]
        let input6 = ["Date Escheated", "Date of Last Contact"]
        let input7 = ["Property Status"]
        if (input1.includes(data.field)) {
            return (
                <div style={{ margin: '20px' }}>
                    <div className='row pl-5 d-flex col-12' >
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <InputText id="in" value={this.state[data.value]} onChange={(e) => this.setState({ [data.value]: e.target.value })} />
                            <label htmlFor="in">Criteria</label>
                        </div>
                        <div className='d-flex col-6' style={{ justifyContent: "space-around" }}>
                            <div >
                                <div style={{ verticalAlign: "middle", paddingLeft: "20px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={data.value + "word"}
                                            value={"word"}
                                            checked={this.state[data.value + "word"] === "word" ? true : false}
                                            onChange={(e) => this.handlekeyValueChange(e.target.value, data.value)}
                                        />
                                        &nbsp;{"Word"}
                                    </label>
                                </div>

                                <div style={{ verticalAlign: "middle", paddingLeft: "20px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={data.value + "word"}
                                            value={"wordPrefix"}
                                            checked={this.state[data.value + "word"] === "wordPrefix" ? true : false}
                                            onChange={(e) => this.handlekeyValueChange(e.target.value, data.value)}
                                        />
                                        &nbsp;{"Word Prefix"}
                                    </label>
                                </div>

                                <div style={{ verticalAlign: "middle", paddingLeft: "20px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={data.value + "word"}
                                            value={"wildCard"}
                                            checked={this.state[data.value + "word"] === "wildCard" ? true : false}
                                            onChange={(e) => this.handlekeyValueChange(e.target.value, data.value)}
                                        />
                                        &nbsp;{"Wild Card"}
                                    </label>
                                </div>

                                <div style={{ verticalAlign: "middle", paddingLeft: "20px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={data.value + "word"}
                                            value={"fuzzy"}
                                            checked={this.state[data.value + "word"] === "fuzzy" ? true : false}
                                            onChange={(e) => this.handlekeyValueChange(e.target.value, data.value)}
                                        />
                                        &nbsp;{"Fuzzy"}
                                    </label>
                                </div>
                            </div>
                            {/* <div style={{ alignSelf: "center" }}>
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className='pl-1'
                                    color='black'
                                    data-toggle="tool-tip"
                                    title="Mandotary Field"
                                    style={{ width: 30 }}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state[data.value + "word"] && this.state[data.value] ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data.value, "input1")}
                        >
                            {"Save"}
                        </Button>
                    </div>
                </div>
            )
        } else if (input2.includes(data.field)) {
            return (
                <div className="card flex justify-content-center">
                    <div className="flex pl-5 pt-3 flex-column gap-3">
                        {/* {
                            this.state.States.map((state) => {
                                return (
                                    <div key={state.Abbreviation} className="flex align-items-center">
                                        <Checkbox
                                            inputId={state.Abbreviation}
                                            name="state"
                                            value={state}
                                            onChange={(e) => this.onStateChange(e)}
                                            checked={this.state.selectedStates.some((item) => item.key === state.Abbreviation)}
                                        />
                                        <label htmlFor={state.Abbreviation} className="ml-2">{state.Name}</label>
                                    </div>
                                );
                            })
                        } */}
                        <MultiSelect
                            name={"Source State"}
                            id="state"
                            filter={true}
                            value={this.state.selectedStates}
                            style={{ width: "50%", height: 32 }}
                            appendTo={document.body}
                            // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                            maxSelectedLabels={1}
                            options={this.state.States}
                            onChange={(e) => this.onStateChange(e, "Source State")}

                        />
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state.selectedStates.length > 0 ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data.value, "input2")}
                        >
                            {"Save"}
                        </Button>
                    </div>
                </div>
            )
        } else if (input3.includes(data.field)) {
            return (
                <div style={{ margin: '20px' }}>
                    <div className='row pl-5 d-flex col-12' >
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <InputText id="in" value={this.state[data.value + "min"]} onChange={(e) => this.setState({ [data.value + "min"]: e.target.value })} />
                            <label htmlFor="in">minimum</label>
                        </div>
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <InputText id="in" value={this.state[data.value + "max"]} onChange={(e) => this.setState({ [data.value + "max"]: e.target.value })} />
                            <label htmlFor="in">maximum</label>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state[data.value + "min"] && this.state[data.value + "max"] ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data.value, "input3")}
                        >
                            {"Save"}
                        </Button>
                    </div>
                </div>
            )
        } else if (input4.includes(data.field)) {
            return (
                <div className="card flex justify-content-center">
                    <div className="flex pl-5 pt-3 flex-column gap-3">
                        {/* {
                            this.state.Sources.map((source) => {
                                return (
                                    <div key={source.key} className="flex align-items-center">
                                        <Checkbox
                                            inputId={source.key}
                                            name="source"
                                            value={source}
                                            onChange={(e) => this.onSourceChange(e)}
                                            checked={this.state.selectedSources.some((item) => item.key === source.key)}
                                        />
                                        <label htmlFor={source.key} className="ml-2">{source.name}</label>
                                    </div>
                                );
                            })
                        } */}
                        <MultiSelect
                            name={"Source"}
                            id="sourece"
                            filter={true}
                            value={this.state.selectedSources}
                            style={{ width: "50%", height: 32 }}
                            appendTo={document.body}
                            maxSelectedLabels={1}
                            options={this.state.Sources}
                            onChange={(e) => this.onSourceChange(e, "Source")}

                        />
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state.selectedSources.length > 0 ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data.value, "input4")}
                        >
                            {"Save"}
                        </Button>
                    </div>
                </div>
            )
        } else if (input5.includes(data.field)) {
            return (
                <div style={{ margin: '20px' }}>
                    <div className='row pl-5 d-flex col-12' >
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <InputText id="in" keyfilter={"pint"} value={this.state[data.value]} onChange={(e) => this.setState({ [data.value]: e.target.value })} />
                            <label htmlFor="in">Criteria</label>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state[data.value] ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data.value, "input5")}
                        >
                            {"Save"}
                        </Button>
                    </div>
                </div>
            )
        } else if (input6.includes(data.field)) {
            return (
                <div style={{ margin: '20px' }}>
                    <div className='row pl-5 d-flex col-12' >
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <Calendar
                                style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                                appendTo={document.body}
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange="1940:2530"
                                maxDate={this.state[data.value + "maxDate"]}
                                name={"calendar1"}
                                // placeholder='mm/dd/yyyy'
                                dateFormat="mm/dd/yy"
                                value={this.state[data.value + "minDate"]}
                                onChange={(e) => this.setState({ [data.value + "minDate"]: e.value })}
                            />
                            <label htmlFor="in">Min Date</label>
                        </div>
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <Calendar
                                style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                                appendTo={document.body}
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange="1940:2530"
                                minDate={this.state[data.value + "minDate"]}
                                name={"calendar2"}
                                // placeholder='mm/dd/yyyy'
                                dateFormat="mm/dd/yy"
                                value={this.state[data.value + "maxDate"]}
                                onChange={(e) => this.setState({ [data.value + "maxDate"]: e.value })}
                            />
                            <label htmlFor="in">Max Date</label>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state[data.value + "minDate"] || this.state[data.value + "maxDate"] ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data.value, "input6")}
                        >
                            {"Save"}
                        </Button>
                    </div>
                </div >
            )
        } else if (input7.includes(data.field)) {
            return (
                <div className="card flex justify-content-center">
                    <div className="flex pl-5 pt-3 flex-column gap-3">
                        <MultiSelect
                            name={"PropertyStatus"}
                            id="propertyStatus"
                            filter={true}
                            value={this.state.selectedPropertyStatus}
                            style={{ width: "50%", height: 32 }}
                            appendTo={document.body}
                            // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                            maxSelectedLabels={1}
                            options={this.state.propertyStatuses}
                            onChange={(e) => this.onPropertyStatusChange(e, "propertyStatus")}

                        />
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state.selectedPropertyStatus.length > 0 ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data.value, "input7")}
                        >
                            {"Save"}
                        </Button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="p-md-10 ml-4" style={{ fontWeight: 'bold' }}>No filter found</div>
            );
        }
    }

    getAccordionHeader() {
        return (
            <div className='d-flex'>
                <span>Editor</span>
                <span style={{ marginLeft: "40px", color: this.state.errorcolor }}>{this.state.errorResponse ? this.state.errorMessage : ""}</span>

            </div>
        )
    }

    closeAccordion = async () => {
        console.log("this.state.activeIndex", this.state.activeIndex)
        await this.setState({ showSearchResult: true, showPreviousSearches: false, activeIndex: null, expandedRows: [] })
        console.log("this.state.activeIndex", this.state.activeIndex)
    }

    setActiveIndex(e) {
        this.setState({ activeIndex: e })
    }


    //close delete modal
    closeClearModal = async () => {
        this.setState({
            openClearModal: false,
        })
    }

    // Clear Modal
    getClearRowModal() {
        return (
            <DeleteRowModal
                openDeleteModal={this.state.openClearModal}
                closeDeleteModal={this.closeClearModal}
                deleteSelectedRow={this.ClearCriteria}
                confirmModalText={"Are you sure want to Clear"}
                type={this.state.type}
            />
        )
    }

    getSearchResults() {
        return (
            <Row>
                <Search
                    criteria={this.state.serverCriteria}
                    pageLimit={this.state.pageLimit}
                />
            </Row>
        )
    }
    cancelexpiredSessionModal = async () => {
        await this.setState({
            expiredSessionModal: false
        })
    }
    expiredSessionModal() {
        return (
            <ExpiredSessionModal
                openConfirmationModal={this.state.expiredSessionModal}
                cancelSessionWarningModal={this.cancelexpiredSessionModal}
                getData={this.getDataFromServer}

            />
        )
    }
    render() {
        return (
            <div>
                <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />
                {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
                {this.state.openClearModal ? this.getClearRowModal() : null}
                {this.state.openFormModal ? this.getPropertySearchUpdateModal() : null}
                <Container className="tableCardBody mt-3" style={{ width: '98%', marginLeft: '20px', backgroundColor: "white" }}>
                    {/* <Accordion activeIndex={this.state.activeIndex}> */}
                    <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setActiveIndex(e.index)}>
                        <AccordionTab header="Property Search">
                            <Row>
                                {/* <div className="topbar__left">
                                    <span className={`tableheadericon ml-3 lnr lnr-layers`} />
                                    <h4 className="Kanban_title_card " >{"Property Search"}</h4></div> */}

                                <div className="row">
                                    {/* global Search */}
                                    <div className='col-6'>
                                        <DataTable
                                            value={this.state.Filters}
                                            // header="Name Issue Note Details"
                                            scrollable={true}
                                            scrollHeight="650px"
                                            resizableColumns={true}
                                            columnResizeMode="expand"
                                            expandedRows={this.state.expandedRows}
                                            onRowToggle={(e) => this.setState({ expandedRows: e.data && e.data.length == 1 ? [e.data[0]] : e.data && e.data.length == 2 ? [e.data[1]] : [] })}
                                            rowExpansionTemplate={(e) => this.rowExpansionTemplate(e)}
                                        >
                                            <Column field="expander"
                                                expander={true}
                                                bodyStyle={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    width: 20,
                                                    fontSize: 12,
                                                    textAlign: "left",
                                                    verticalAlign: "inherit"
                                                }} headerStyle={{
                                                    width: 20,
                                                    textAlign: "center",
                                                }} body={this.hoverTemplate} />
                                            <Column field="field" header="field" bodyStyle={{
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                width: 150,
                                                fontSize: 12,
                                                textAlign: "left",
                                                verticalAlign: "inherit"
                                            }} headerStyle={{
                                                width: 150,
                                                textAlign: "center",
                                            }} body={this.hoverTemplate} />
                                        </DataTable>
                                    </div>
                                    <div className='col-6'>
                                        <DataTable
                                            value={this.state.filterCriteria}
                                            // header="Name Issue Note Details"
                                            scrollable={true}
                                            scrollHeight="650px"
                                            resizableColumns={true}
                                            columnResizeMode="expand"
                                        >
                                            <Column
                                                field="Criteria"
                                                header="Criteria"
                                                bodyStyle={{
                                                    textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 150, fontSize: 12, textAlign: "center", verticalAlign: "inherit"
                                                }}
                                                headerStyle={{
                                                    width: 150, textAlign: "center",
                                                }}
                                                body={this.hoverTemplate}
                                            />
                                            <Column
                                                field="Type"
                                                header="Type"
                                                bodyStyle={{
                                                    textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 150, fontSize: 12, textAlign: "center", verticalAlign: "inherit"
                                                }}
                                                headerStyle={{
                                                    width: 150, textAlign: "center",
                                                }}
                                                body={this.hoverTemplate}
                                            />
                                            <Column
                                                field="Value"
                                                header="Value"
                                                bodyStyle={{
                                                    textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 150, fontSize: 12, textAlign: "center", verticalAlign: "inherit"
                                                }}
                                                headerStyle={{
                                                    width: 150, textAlign: "center",
                                                }}
                                                body={this.hoverTemplate}
                                            />
                                            <Column
                                                field="Actions"
                                                header="Actions"
                                                bodyStyle={{
                                                    textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: 150, fontSize: 12, textAlign: "center", verticalAlign: "inherit"
                                                }}
                                                headerStyle={{
                                                    width: 150, textAlign: "center",
                                                }}
                                                body={this.hoverTemplate}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </Row>
                        </AccordionTab>
                    </Accordion>
                    <Row style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>
                        <div>
                            <Button
                                outline
                                color="primary"
                                size="sm"
                                disabled={this.state.showSearchResult ? true : false}
                                className={'p-1 m-2'}
                                style={{ width: "120px" }}
                                onClick={this.closeAccordion}
                            >
                                {"Go"}
                            </Button>
                            {/* <div style={{ textAlign: "right" }}>
                            <Button
                                outline
                                color="primary"
                                size="sm"
                                // disabled={this.state[data.value + "word"] && this.state[data.value] ? false : true}
                                className={'p-1 m-2'}
                                style={{ width: "160px" }}

                                onClick={() => this.setState({ showPreviousSearches: true, showSearchResult: false, })}

                            // onClick={() => this.UpdateCriteria(data.value, "input1")}
                            >
                                {"Previous Searches"}
                            </Button>
                        </div> */}

                            <Button
                                outline
                                color="primary"
                                size="sm"
                                // disabled={this.state[data.value + "word"] && this.state[data.value] ? false : true}
                                className={'p-1 m-2'}
                                style={{ width: "120px" }}
                                onClick={() => this.setState({ openClearModal: true })}
                            >
                                {"Clear All"}
                            </Button>
                        </div>
                        <div className='p-1 m-2'>
                            <InputText
                                style={{ width: "70px" }}
                                id="pageLimit"
                                value={this.state.pageLimit}
                                onChange={(e) => this.setState({ pageLimit: e.target.value ? e.target.value : 500 })}
                            />

                        </div>
                    </Row>
                    {this.state.showSearchResult ? this.getSearchResults() : ""}
                    {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
                    {/* {this.state.showPreviousSearches ?
                        <Row>
                            <PreviousSearches criteria={this.state.serverCriteria} />
                        </Row> : ""} */}
                </Container >
            </div>
        );
    }
}

export default withTranslation('common')(PropertySearch);


