import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';

// config file
export default class searchTermOnSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'searchTermOnSite',
      loading: false,
      sessionWarning: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getSettingsData = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' },
    //  { key: 'screenType', value: ["Claims"], type: 'in', add_Filter: true }
    ];
    fetchMethodRequest('GET', `PropertyStatus?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["propertyStatuses"] && response["propertyStatuses"].length > 0) {
        let propertyStatuses = response["propertyStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        this.setState({ settingsData: StatusArray })
      }
    })
    return this.state.settingsData;
  }
  getClaimStatus = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    fetchMethodRequest('GET', `${apiCalls.ClaimStatuses}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["claimStatuses"] && response["claimStatuses"].length > 0) {
        let propertyStatuses = response["claimStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        this.setState({ claimStatus: StatusArray })
      }
    })
    return this.state.settingsData;
  }
  getAnalyst = () => {
    let userArray = []
    return fetchMethodRequest('GET', `${apiCalls.Users}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          for (let user of response.users) {
            userArray.push({ label: user.display_name, value: user.display_name })
          }
          await this.setState({ userArray: userArray })
        }
      }).catch((err) => {
        return err;
      });
  }


  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    this.getSettingsData()
    await this.getClaimStatus()
    await this.getAnalyst()
  }

  getTableFields = () => {
    let data = [



    ]
    return data;
  };


  getFormFields = () => {
    return ([
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "EstateName",
        "label": "Seller Name",
        "id": "EstateName",
        "placeholder": "Seller Name",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "SourceState",
        "label": "Source State",
        "id": "SourceState",
        "placeholder": "Source State",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "BusinessName",
        "label": "Business Name",
        "id": "BusinessName",
        "placeholder": "Business Name",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkForm",
        "label": "Check From",
        "id": "checkForm",
        "placeholder": "Check From",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "textArea",
        "name": "note",
        "label": "Note",
        "id": "note",
        "placeholder": "Note",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkNumber",
        "label": "Check Number",
        "id": "checkNumber",
        "placeholder": "Check Number",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkAmount",
        "label": "Check Amount",
        "id": "checkAmount",
        "placeholder": "Check Amount",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "date",
        "name": "checkDate",
        "label": "Check Date",
        "id": "checkDate",
        "placeholder": "Check Date",
        "required": true
      },
    ]);
  }

  getClaimStatusFormFields = () => {
    return ([{
      "value": "",
      "type": "text",
      "name": "ClaimStatus",
      "label": "Claim Status",
      "id": "ClaimStatus",
      "placeholder": "Claim Status",
      "required": false,
      // "disable": true
    },])
  }

  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          claimStatusFormFields={this.getClaimStatusFormFields}
          statusFilter={false}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          isClearFilters={false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          isEdit={false}
          multiApi={`${apiCalls.OverrideClaimStatus}/multi`}
          multiApiKey={"Claims"}
          gridRequried={false}
          sample={false}
          dataKey={"OppClaimID"}
          // criteria={[{ "key": "PropertyStatus", "value": "Filed", "type": "eq" }]}
          criteria={[]}
          // globalSearchFieldName='user'
          // globalSearch={'Search'}
          type='searchTermOnSite'
          dontShowTitle={true}
          displayright={true}
          settingsData={this.state.settingsData}
          icon='lnr lnr-menu-circle'
          routeTo='claims'
          groupField='claimID'
          // sortField={"OppClaimID"}
          displayViewOfForm='modal'
          // apiResponseKey='claims'
          loading={true}
          // apiUrl={apiCalls.ChildClaims}
          entityType='employee'
          sessionWarning={this.state.sessionWarning}
        />

      </span>
    );
  }
}