import React from 'react';
import { Button, Modal, CardBody, Table, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';

import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import ConfirmationModal from '../../Cruds/CommonModals/ConfirmationModal';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ClaimDetail from "../../Cruds/CommonModals/ClaimDetails"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../App/Loader';
import configMessages from '../../../config/configMessages'
import { Rnd } from "react-rnd";

// import ClaimDetails from '../../Cruds/CommonModals/ClaimCreationfromPE/NewClaim'

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid gray"
};

// config file
class TableModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      states: [
        { label: "Alabama - AL", value: "Alabama - AL", show: false, shortCode: "AL" },
        { label: "Alaska - AK", value: "Alaska - AK", show: false, shortCode: "AK" },
        { label: "Arizona - AZ", value: "Arizona - AZ", show: false, shortCode: "AZ" },
        { label: "Arkansas - AR", value: "Arkansas - AR", show: false, shortCode: "AR" },
        { label: "California - CA", value: "California - CA", show: false, shortCode: "CA" },
        { label: "Colorado - CO", value: "Colorado - CO", show: false, shortCode: "CO" },
        { label: "Connecticut - CT", value: "Connecticut - CT", show: false, shortCode: "CT" },
        { label: "Delaware - DE", value: "Delaware - DE", show: false, shortCode: "DE" },
        { label: "Florida - FL", value: "Florida - FL", show: false, shortCode: "FL" },
        { label: "Georgia - GA", value: "Georgia - GA", show: false, shortCode: "GA" },
        { label: "Hawaii - HI", value: "Hawaii - HI", show: false, shortCode: "HI" },
        { label: "Idaho - ID", value: "Idaho - ID", show: false, shortCode: "ID" },
        { label: "Illinois - IL", value: "Illinois - IL", show: false, shortCode: "IL" },
        { label: "Indiana - IN", value: "Indiana - IN", show: false, shortCode: "IN" },
        { label: "Iowa - IA", value: "Iowa - IA", show: false, shortCode: "IA" },
        { label: "Kansas - KS", value: "Kansas - KS", show: false, shortCode: "KS" },
        { label: "Kentucky - KY", value: "Kentucky - KY", show: false, shortCode: "KY" },
        { label: "Louisiana - LA", value: "Louisiana - LA", show: false, shortCode: "LA" },
        { label: "Maine - ME", value: "Maine - ME", show: false, shortCode: "ME" },
        { label: "Maryland - MD", value: "Maryland - MD", show: false, shortCode: "MD" },
        { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false, shortCode: "MA" },
        { label: "Michigan - MI", value: "Michigan - MI", show: false, shortCode: "MI" },
        { label: "Minnesota - MN", value: "Minnesota - MN", show: false, shortCode: "MN" },
        { label: "Mississippi - MS", value: "Mississippi - MS", show: false, shortCode: "MS" },
        { label: "Missouri - MO", value: "Missouri - MO", show: false, shortCode: "MO" },
        { label: "Montana - MT", value: "Montana - MT", show: false, shortCode: "MT" },
        { label: "Nebraska - NE", value: "Nebraska - NE", show: false, shortCode: "NE" },
        { label: "Nevada - NV", value: "Nevada - NV", show: false, shortCode: "NV" },
        { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false, shortCode: "NH" },
        { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false, shortCode: "NJ" },
        { label: "New Mexico - NM", value: "New Mexico - NM", show: false, shortCode: "NM" },
        { label: "New York - NY", value: "New York - NY", show: false, shortCode: "NY" },
        { label: "North Carolina - NC", value: "North Carolina - NC", show: false, shortCode: "NC" },
        { label: "North Dakota - ND", value: "North Dakota - ND", show: false, shortCode: "ND" },
        { label: "Ohio - OH", value: "Ohio - OH", show: false, shortCode: "OH" },
        { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false, shortCode: "OK" },
        { label: "Oregon - OR", value: "Oregon - OR", show: false, shortCode: "OR" },
        { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false, shortCode: "PA" },
        { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false, shortCode: "PR" },
        { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false, shortCode: "RI" },
        { label: "South Carolina - SC", value: "South Carolina - SC", show: false, shortCode: "SC" },
        { label: "South Dakota - SD", value: "South Dakota - SD", show: false, shortCode: "SD" },
        { label: "Tennessee - TN", value: "Tennessee - TN", show: false, shortCode: "TN" },
        { label: "Texas - TX", value: "Texas - TX", show: false, shortCode: "TX" },
        { label: "Utah - UT", value: "Utah - UT", show: false, shortCode: "UT" },
        { label: "Vermont - VT", value: "Vermont - VT", show: false, shortCode: "VT" },
        { label: "Virginia - VA", value: "Virginia - VA", show: false, shortCode: "VA" },
        { label: "Washington - DC", value: "Washington - DC", show: false, shortCode: "DC" },
        { label: "Washington - WA", value: "Washington - WA", show: false, shortCode: "WA" },
        { label: "West Virginia - WV", value: "West Virginia - WV", show: false, shortCode: "WV" },
        { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false, shortCode: "WI" },
        { label: "Wyoming - WY", value: "Wyoming - WY", show: false, shortCode: "WY" },
      ],
      width: 1200,
      height: 600,
      x: 885,
      y: -950,
      allSelectedRows: {},
      connectedTaskArray: this.props.rowData
    };
  }

  componentDidMount = async () => {
    const { rowData } = this.props
    await this.centerElement()
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.setState({
      activeTab: rowData.connectedTaskID && rowData.connectedTaskID.length > 0 && rowData.connectedClaimID && rowData.connectedClaimID.length > 0 || rowData.connectedTaskID && rowData.connectedTaskID.length === 0 && rowData.connectedClaimID && rowData.connectedClaimID.length > 0 || rowData.connectedTaskID === null ? "2" : "1",
    })

    if (rowData.connectedClaimID && rowData.connectedClaimID.length > 0) {
      await this.getConnectedClaimIDRec(rowData.connectedClaimID)
    } else {
      this.getTaskDetails()
    }

  }
  centerElement = async (element) => {
    // Get the dimensions of the viewport
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.screen.height
    // Get the dimensions of the element
    const elementWidth = 1200;
    const elementHeight = 600;
    const leftPosition = (viewportWidth - elementWidth) / 2;
    const topPosition = (viewportHeight - elementHeight) / 2;
    await this.setState({ x: leftPosition, y: topPosition })
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  getConnectedClaimIDRec = () => {
    let url
    let filterCriteria = {}
    url = `${apiCalls.Claims}`
    filterCriteria.criteria = [{ key: "oppClaimID", value: this.props.rowData.connectedClaimID, type: "eq" }]
    return fetchMethodRequest('GET', `${url}?filter=${JSON.stringify(filterCriteria)}&ignoreUnassigned=true`)
      .then(async (response) => {
        let downloadData = []
        if (response && response.claims && response.claims.length === 1) {
          await this.setState({
            allSelectedRows: response.claims[0],
            claimArray: response.claims
          })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            allSelectedRows: {}
          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getTaskDetails = async () => {
    let dataToGet = this.state.connectedTaskArray
    let url
    let filterCriteria = {}
    if (dataToGet.connectedTaskID) {
      if (this.props.type === "Property Editor" || this.props.type === "HighValueProperties") {
        url = 'tasks'
        filterCriteria.criteria = [{ key: "taskID", value: dataToGet.connectedTaskID, type: "in" }]
      }
      this.handlePercentage()
      return fetchMethodRequest('GET', `${url}?filter=${JSON.stringify(filterCriteria)}&ignoreUnassigned=true`)
        .then(async (response) => {
          let downloadData = []
          if (response && response.tasks && response.tasks.length > 0) {
            await this.setState({
              activities: response.tasks,
              isLoading: false
            })
          } else if (response && response['errorMessage'] === configMessages.warningMessage) {
            await this.setState({
              sessionWarning: true,
              isLoading: false

            })
          }
        }).catch((err) => {
          return err;
        })
    }
  }
  deleteSelectedRow = () => {
    // this.props.deleteSelectedRow();
  }
  deletePropertyFromTask = async () => {
    let item = this.state.selectedDeleteItem
    let connectedPropertiesArray = [], statePropertyIDsArray = [], state = []
    connectedPropertiesArray.push(this.props.rowData.propertyID)
    statePropertyIDsArray.push(this.props.rowData.statePropertyId)
    let connectedTaskArray = this.state.connectedTaskArray
    let getNewData = connectedTaskArray.connectedTaskID.filter(function (el) {
      return el !== item.taskID;
    });

    connectedTaskArray.connectedTaskID = getNewData
    await this.setState({ isLoading: true, connectedTaskArray: connectedTaskArray })

    let z = this.state.states.find(elem => elem.shortCode === this.props.rowData.sourceState)
    if (z) {
      //with z values hit the api call
      state.push(z.label)
    }
    let body = {}
    body.title = item.title
    body.states = state
    body.company = item.company
    body.oppSize = item.oppSize
    body.propertyCount = 1
    body.unknownPropertyCount = this.props.rowData.propertyMaxValueAmount === null || this.props.rowData.propertyMaxValueAmount === "" ? 1 : 0
    body.taskID = item.taskID
    body.totalMinPropertyValue = this.props.rowData.propertyMinValueAmount
    body.statePropertyIDs = statePropertyIDsArray
    body.connectedProperties = connectedPropertiesArray
    body.oPRAEstateID = item.opraEstateID
    await fetchMethodRequest('POST', `${apiCalls.RemoveFromConnectedTasks}`, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        await this.getTaskDetails(this.state.connectedTaskArray)
        if (this.state.activities && this.state.activities.length === 0) {
          await this.props.cancelReset()
        }
      }
      this.setState({ isLoading: false })
    }).catch((err) => {
      return err;
    })

  }


  toggle = async (tab) => {
    await this.setState({ activeTab: tab })
    if (tab === "1") {
      this.getTaskDetails(this.state.connectedTaskArray)
    }
    if (tab === "2") {
      this.getConnectedClaimIDRec()
    }
    if (tab === "closeByIcon") {
      await this.setState({ selectedRows: [], allSelectedRows: [] })
      this.props.cancelReset([])
    }
  }
  /**
   * 
   * @param {*} type 
   * closing the model for debtors
   */
  cancelReset = async (type, item) => {
    await this.setState({
      isopenColumnModal: true,
      selectedDeleteItem: item
    })
  }
  /**
  * 
  * @param {*} type 
  * Clsoing the show hide model after confirmation
  */
  closeShowHideColumnsModal = async (type) => {
    await this.setState({
      isopenColumnModal: false
    })
    if (type === "close") {
      await this.deletePropertyFromTask()
      // this.props.closeShowHideColumnsModal('close', null)
    }

  }






  saveClaim = async (values) => {
    let api, type, selectedIds = []
    let body = this.state.allSelectedRows && this.state.allSelectedRows.length > 1 ? {} : values
    if (this.state.allSelectedRows && this.state.allSelectedRows.length > 1) {
      for (let selectedRow of this.state.allSelectedRows) {
        selectedIds.push(selectedRow._id)
      }
      body.selectedIds = selectedIds
      body.claimStatus = values.claimStatus
      body.claimReason = values.claimReason
      body.claimNotes = values.claimNotes
      if (values.next_Action_Date) {
        body.nextActionDate = values.next_Action_Date
      }
      body.analyst = values.analyst
      type = "PUT"
      api = `${apiCalls.Claims}/multi`
    }
    else if (body._id) {
      type = "PUT"
      api = `${apiCalls.Claims}/${body._id}`
      if (!values.CompanyID) {
        body.CompanyID = this.state.CompanyID
      }
    } else {
      type = "POST"
      api = `${apiCalls.Claims}`
      if (!values.CompanyID) {
        body.CompanyID = this.state.CompanyID
      }
    }

    return fetchMethodRequest(type, api, body).then(async (resp) => {
      if (resp) {
        this.props.cancelReset([])
        // await this.toggle("close")
        // await this.getDataFromServer(this.state.Object1, this.state.filterCriteria, "Preset")

      }
    });

  }


  /**
       * 
       * @returns Opening the confirmation model
       */
  getConfirmaionModel() {
    return (
      <ConfirmationModal
        openConfirmationModal={this.state.isopenColumnModal}
        closeConfirmationModal={this.closeShowHideColumnsModal}
        shownText={`Are you sure you want to remove OPRA PropertyId ${this.props.rowData.propertyID} from TaskID ${this.state.selectedDeleteItem.taskID}?`}
      />
    )
  }

  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }



  render() {
    const { t } = this.props
    return (
      <div>
        <div className="rnd_Modal" >
          <Rnd
            className={` p-0 claim_creation_modal rnd_modal`}
            style={style}
            size={{ width: this.state.width, height: this.state.height }}
            position={{ x: this.state.x, y: this.state.y }}
            onDragStop={(e, d) =>                        this.setState({ x: d.x, y: d.y })
          }
            onResizeStop={(e, direction, ref, delta, position) => {
              this.setState({
                width: ref.style.width,
                height: ref.style.height,
                ...position
              });
            }}
          // enableResizing={false}
          ><CardBody className='deleteModalBody p-0' style={{ textAlign: "initial" }}>
            < div className="claimTab" style={{ width: "100%" }}>
              <Nav tabs style={{ "backgroundColor": "#354f6e" }} >
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}

                  >
                    Tasks
                  </NavLink>
                </NavItem>
                <NavItem >
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2', 'business', 'Business'); }}
                  // disabled={Object.keys(this.state.allSelectedRows).length === 0}
                  >
                    Claim Details
                  </NavLink>
                </NavItem>



                <div style={{ marginLeft: "auto", }}>

                  <span className="topbar__centerresponse topBarImageAlignment" >{this.state.successResponse ? this.state.successMessage : null}</span>
                  {/* <Button style={{ backgroundColor: "Blue", color: "white", marginBottom: "1px" }}  >{"Claim Builder"}</Button>
                  <Button className="mr-1" style={{ backgroundColor: "Blue", color: "white" }}  >{"Save"}</Button> */}
                  <FontAwesomeIcon
                    className={'tableheadericon'}
                    color="#354f6e"
                    style={{ marginTop: "13px", marginRight: "6px", color: "white" ,cursor:"pointer"}}
                    onClick={() => { this.toggle("closeByIcon"); }}
                    icon={faTimesCircle}
                  />
                </div>





              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                {this.state.activeTab === "1"
                  || (this.state.isSelectedRow && this.state.emptyselectedRows) || this.state.openClaimDetails
                  ?
                  <TabPane tabId={this.state.activeTab} >
                    <div className='col-12 px-0 splitTasks m-3' style={{ height: 400, width: "96%", overflow: "auto" }}>

                      <Table bordered={true}>
                        <thead>
                          <tr>
                            <th>Task ID</th>
                            <th>Label</th>
                            <th>Purpose</th>
                            <th>Status</th>
                            <th>Assigned User</th>
                          </tr>
                        </thead>
                        <tbody style={{ maxHeight: 80 }}>
                          {this.state.activities && this.state.activities.length > 0 ?
                            this.state.activities.map((item, i) => {

                              return <tr key={i}>
                                <td onClick={() => this.props.openFormModal(item, 'edit')} style={{ color: "#354f6e", cursor: "pointer" }}>{item.taskID}</td>
                                <td >{item.title}</td>
                                <td>{item.purpose}</td>
                                <td> {item.status}</td>
                                <td> {item.assignTo}</td>
                                <td>  < FontAwesomeIcon
                                  className="status_logo"
                                  // color={value === "true" ? '#5cb85c' : 'red'}
                                  style={{ cursor: "pointer", color: "red", width: "5%" }}
                                  icon={faTimes}
                                  data-toggle="tool-tip"
                                  // title={value === "true" ? 'Active' : 'Inactive'}
                                  onClick={() => { this.cancelReset("close", item); }}
                                /></td>

                              </tr>
                            }) : "No Connected Tasks"}
                        </tbody>

                      </Table>
                    </div>
                    <Row className="mx-auto" style={{ justifyContent: "center" }}> <Button
                      color="danger"
                      outline
                      onClick={this.props.cancelReset}>Cancel</Button></Row>
                  </TabPane>
                  :
                  null
                }
                {this.state.activeTab === "2" ?
                  Object.keys(this.state.allSelectedRows).length > 0 ?
                    <TabPane tabId={this.state.activeTab} >
                      <ClaimDetail saveClaim={this.saveClaim} width={this.state.width} seller={this.props.seller} details={this.state.allSelectedRows} type={"Edit"} isClaim={"new"} allSelectedRows={this.state.claimArray} selectedRows={this.state.claimArray} claimID={this.props.rowData.connectedClaimID} />

                    </TabPane>
                    :
                    <div className="connectedClaim">No Connected Claims</div>
                  : null}

              </TabContent>

            </div>
          </CardBody>
          </Rnd></div>
        {this.state.isopenColumnModal ?
          this.getConfirmaionModel() : null
        }
      </div>
    );
  }
}
export default withTranslation('common')(TableModal);
