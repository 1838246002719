import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faSave } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from 'primereact/dialog';
import { Button } from 'reactstrap';
import { InputText } from 'primereact/inputtext';
import Alerts from '../../DocumentUpload/components/Alerts';
import CommonPdfEdiitor from '../../../../shared/pdf/CommonPdfEdiitor';
import ExpiredSessionModal from '../../CommonModals/ExpiredSessionModal';
import fetchMethod from '../../../../config/service';
import '../../../../scss/component/pdf-editor.scss';
import config from '../../../../config/config';
import Loader from '../../../App/Loader';
import * as documentsAPIs from '../../../../shared/documentsApis';
import * as fs from 'file-saver';

let timeout
export default class PdfEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: '',
            fileName: '',
            update: '',
            saveToLocal: false,
            blobToSave: '',
            showModal: false,
            saveFileName: '',
            showSuccessMessage: false,
            fromDocMaintain: false,
            isLoading: false,
            response: { type: '', message: '' }
        }
        this.loginCredentials = localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials")) : null;
        this.dealDetails = null;
    }
    componentDidMount = () => {
        const url = window.location;
        const params = new URLSearchParams(url.search);
        const deal = params.get('details') ? params.get('details') : null;
        if (deal && localStorage.getItem('pdfDocDetails')) {
            this.dealDetails = JSON.parse(localStorage.getItem('pdfDocDetails'));            
            this.setState({ fromDocMaintain: true, fileName: this.dealDetails.fileName });
            this.getDocumentData(this.dealDetails.sharePointDetails);
        }
    }
    componentDidUpdate() {
        // if (localStorage.getItem('loggedTime')) {
        //     let loggedTime = localStorage.getItem('loggedTime')
        //     if (timeout) {
        //         clearTimeout(timeout)
        //     }
        //     timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
        // }

    }
    componentWillUnmount(){
        if (localStorage.getItem('pdfDocDetails')) {
            localStorage.removeItem('pdfDocDetails');
        }
    }

    getDocumentData = (document) => {
        let data = {
            details: document
        }
        fetchMethod('POST', `GetFileContent`, data).then(async (res) => {
            if (res && res.details) {
                if (res.details.length > 0 && res.details[0].fileContent) {
                    this.createBlobFromBase64(res.details[0].fileContent);
                }

            } else {
                this.setState({ loading: false });
            }
        });
    }
    cancelexpiredSessionModal = async () => {
        await this.setState({
            expiredSessionModal: false
        })
    }
    expiredSessionModal() {
        return (
            <ExpiredSessionModal
                openConfirmationModal={this.state.expiredSessionModal}
                cancelSessionWarningModal={this.cancelexpiredSessionModal}
                getData={this.getDataFromServer}

            />
        )
    }
    createBlobFromBase64 = (base64String, url) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const tUrl = URL.createObjectURL(blob);
        console.log(tUrl);
        this.setState({ document: tUrl });
    }
    /**
     * Function to read file and set file data into state on user selects file.
     * @param {Event} e     
     */
    onFileSelect = async (e) => {
        const file = e.target.files[0];
        this.setState({ update: file.name })
        if (file) {
            this.reader(e.target.files[0]).then((res) => {
                this.setState({ document: res, fileName: file.name, saveFileName: file.name });
            }).catch((err) => { throw err });
        }
    }


    /**
     * Reading file & convert it into Data url using FileReader api
     * @param {File} file 
     * @returns [promise resove Data url]
     */
    reader = (file) => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => resolve(fr.result);
            fr.onerror = (err) => reject(err);
            fr.readAsDataURL(file);
        });
    }

    /**
     * Saving edited pdf file to local machine
     * @param {Blob} blob 
     */
    savePdfToLocal = async (blob) => {
        this.setState({ saveToLocal: false });
        if (this.dealDetails) {
            this.fileUploadToServer(blob);
            return;
        }
        if (window.showSaveFilePicker) { // checking 'showSaveFilePicker' api is available or not (only available in chrome based browser)
            const fileHandle = await window.showSaveFilePicker({
                suggestedName: this.state.saveFileName,
                types: [
                    {
                        description: 'Pdf File',
                        accept: {
                            'application/pdf': ['.pdf'],
                        },
                    },
                ]
            });
            const writableStream = await fileHandle.createWritable();
            await writableStream.write(blob);
            await writableStream.close();
            this.setState({ showSuccessMessage: true });
            setTimeout(() => { this.setState({ showSuccessMessage: false }); }, 5000);
        } else {
            // for non chrome based browser.
            // Fall back functionality. asks user for filename. and saves the file with that file name in browser default download location.               
            await this.setState({ blobToSave: blob, showModal: true });

        }
    }


    /**
     * Saving file to local machine using file saver - for non chrome based browser.     
     */
    saveWithFileSaver = () => {
        fs.saveAs(this.state.blobToSave, this.state.saveFileName);
    }

    /**
     * Upload file to web server.
     * @param {Object} data 
     */
    fileUploadToServer = async (blob) => {
        this.setState({ isLoading: true });
        let fileName = JSON.parse(JSON.stringify(this.dealDetails.fileName));
        fileName.replace("/", "_")
        const formData = new FormData();
        formData.append("file", blob, fileName.slice(0, -4));
        try {
            const resPromise = await fetch(`${config.apiUrl}uploads?uploadPath=claims&type=PDF&extType=pdf`, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.loginCredentials.accessToken}`
                },
            });
            const res = await resPromise.json();
            if (res && res.fileName) {
                const sanFileName = encodeURIComponent(res.fileName);
                this.uploadPdfDocument(sanFileName, this.dealDetails.documentNumber);
            }

        } catch (err) {
            this.showRespMessage('danger', 'Failed. Please contact admin');
        }
    }

    /**
   * Uploading file to sharepoint server
   * @param {String} fileName 
   * @param {String} documentId 
   */
    uploadPdfDocument = async (fileName, documentId) => {
        fetchMethod('POST', `UploadPDFDocument?fileName=${fileName}&DocumentID=${documentId}&uploadPath=${this.dealDetails.sharepointLocation ? encodeURIComponent(this.dealDetails.sharepointLocation) : ''}&DealID=${this.dealDetails.dealId}&DealType=${this.dealDetails.dealType}`).then(async (res) => {
            if (res && res.resultCode && res.resultCode === 200 && res.success) {
                this.updateDocument();
            } else {
                this.showRespMessage('danger', 'Failed. Please contact admin');
            }
        });
    }

    updateDocument = async () => {
        const res = await documentsAPIs.updateDocumentRecord(this.dealDetails);
        if (res && res.respCode && res.respCode === 200) {
            this.showRespMessage('success', 'Document saved successfully');
        } else {
            this.showRespMessage('danger', 'Failed. Please contact admin');
        }

    }

    showRespMessage = (type, message) => {
        this.setState({ response: { type: type, message: message }, isLoading: false });
        setTimeout(() => { this.hideRespMessage() }, 1000 * 5);
    }

    hideRespMessage = () => {
        this.setState({ response: { type: '', message: '' } })
    }

    render() {

        return (
            <div className='bg-white p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='my-2'>
                        {!this.dealDetails && <>
                            <label for="pdf-editor-uploader" className="upload-button">
                                <FontAwesomeIcon
                                    icon={faFilePdf} />  Open New File
                            </label>
                            <input type='file' id="pdf-editor-uploader" accept="application/pdf" onChange={(e) => this.onFileSelect(e)}></input>
                        </>
                        }
                        <button className='upload-button ml-3' onClick={() => this.setState({ saveToLocal: true })}>
                            <FontAwesomeIcon
                                icon={faSave} />  Save File
                        </button>

                    </div>

                    {this.state.showSuccessMessage &&
                        <div className='text-success'>
                            <strong> File saved successfully </strong>
                        </div>
                    }
                    <div><b>File Name:</b>&nbsp;{this.state.fileName}</div>
                </div>
                {this.state.response.type && <Alerts type={this.state.response.type} message={this.state.response.message} changeStatus={this.hideRespMessage} />}

                {this.state.document &&
                    <div style={{ width: '100%', height: '100%' }}>
                        <CommonPdfEdiitor
                            pdfDoc={this.state.document}
                            enableNewDocLoad={true}
                            fileName={this.state.fileName}
                            update={this.state.update}
                            saveToLocal={this.state.saveToLocal}
                            savePdfToLocal={this.savePdfToLocal}
                        />
                    </div>
                }
                <Dialog header={'Enter file name'} visible={this.state.showModal} style={{ minWidth: '30vw', maxWidth: '75vw' }} modal={true} onHide={() => this.setState({ showModal: false })}>
                    <div className='text-center'>
                        <InputText className='w-100' value={this.state.saveFileName} onChange={(e) => this.setState({ saveFileName: e.target.value })} />
                        <div className='mt-4'>
                            <Button color='primary' outline onClick={() => this.setState({ showModal: false })} >Close</Button>
                            <Button color='primary' outline onClick={() => this.saveWithFileSaver()} >Save</Button>
                        </div>
                    </div>
                </Dialog>
                {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
                <Loader loader={this.state.isLoading} />
            </div>
        )
    }
}