import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import { Row } from 'reactstrap';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Weekly Recovery Plan',
      loading: false,
      sessionWarning: false,
      readOnly: false,
      roleOptions: [],
      business: [],
      FilterSortOptions: [
        // { label: 'Type of UP Recovery', value: "dealType" },
        { label: 'Business Name', value: "company" },
        { label: 'Site Discovery Target', value: "searchTarget" },
        // { label: 'Validation Target', value: "validationTarget" },
        // { label: 'File Target', value: "fileTarget" }
        // { label: 'Property City', value: "propertyCity" },
        // { label: 'Property State', value: "propertyState" },
      ]
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions[this.state.type] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }
  }


  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 140,
      //   "fieldType": 'label',
      //   "isBold": "true",
      //   "field": "typeCompany",
      //   //"header": "type / Company",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "sortable": false
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "isBold": "true",
        "fieldType": 'label',
        "field": "dealType",
        "editField": "dropDown",
        options: [
          { label: 'BK - New', value: 'BK - New' },
          { label: 'BK - Refresh', value: 'BK - Refresh' },
          { label: 'CR - New', value: 'CR - New' },
          { label: 'CR - Refresh', value: 'CR - Refresh' },
        ],
        "mobile": false,
        //"header": "Type of UP Recovery",
        "filter": false,
        // "fieldType": 'profile',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 75,
        "isBold": "true",
        "fieldType": 'label',
        "field": "crRound",
        "editField": "dropDown",
        options: [
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" }],
        "mobile": false,
        //"header": "Type of UP Recovery",
        "filter": false,
        // "fieldType": 'profile',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": 'left',
        "width": 220,
        "isBold": "true",
        "field": 'company',
        "mobile": true,
        "editField": "autoComplete",
        "fieldType": 'label',
        "tableField": 'name',
        //"header": ' Business Name',
        // "options": this.state.roleOptions,
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "analysts",
        "fieldType": "Array",
        "mobile": true,
        // "options": this.state.roleOptions,
        //"header": "Analyst",
        "editField": "dropDown",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "transitionTarget",
      //   "mobile": true,
      //   "fieldType": 'number',
      //   "keyfilter": "money",
      //   //"header": "Weekly Target for Transition Target in (%)",
      //   // "editField": "text",
      //   "displayInSettings": true,
      //   "filter": false,
      //   "sortable": false,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "mobile": true,
        "field": "searchTarget",
        "fieldType": 'number',
        //"header": "Weekly Target for Site Discovery  in (%)",
        "keyfilter": "money",
        "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "mobile": true,
        "field": "forecastSearchHours",
        "fieldType": 'currency',
        "keyfilter": "money",
        //"header": "Plan Site Discovery Hours",
        "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "fieldType": 'currency',
        "field": "searchHours",
        //  "editField": "text",
        "keyfilter": "money",
        //"header": "Actual Site Discovery Hours",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 70,
      //   "mobile": true,
      //   "field": "forecastValidationHours",
      //   "fieldType": 'number',
      //   "keyfilter": "money",
      //   //"header": "Plan Validation Hours",
      //   // "editField": "text",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": false,
      //   "sortable": false,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 70,
      //   "fieldType": 'currency',
      //   "field": "validationHours",
      //   // "editField": "text",
      //   "keyfilter": "money",
      //   //"header": "Actual Validation Hours",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "sortable": false
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "fieldType": 'currency',
      //   "field": "reviewHours",
      //   //"header": "Review Hours",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 70,
      //   "field": "forecastFileHours",
      //   "mobile": true,
      //   "fieldType": 'number',
      //   "keyfilter": "money",
      //   //"header": "Plan File Hours",
      //   // "editField": "text",
      //   "displayInSettings": true,
      //   "filter": false,
      //   "sortable": false,
      // }, {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 70,
      //   "fieldType": 'currency',
      //   "field": "fileHours",
      //   "header": "Actual File Hours",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "sortable": false
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "mobile": true,
      //   "field": "forecastReviewHours",
      //   "fieldType": 'number',
      //   "keyfilter": "money",
      //   //"header": "forecastReviewHours",
      //   "editField": "text",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": false,
      //   "sortable": true,
      // },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "field": "comments",
        // "fieldType": 'number',
        //"header": "comments",
        "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
    ]
    return data;
  };

  getTableFieldsbasedonRole = () => {
    let data = this.getTableFields();
    if (!this.state.readOnly) {
    } else {
      data.push(
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "fieldType": "Actions",
          "field": "Actions",
          //"header": "Delete",
          "filter": false,
          // "displayInSettings": true,
          "sortable": false
        },

      )
    }
    return data;
  }

  getFormFields = () => {
    return (
      [
      ]
    );
  }

  getEditSiteDiscoveryColumns() {
    let headerArr = ["Site Discovery in(%)", "Plan", "Actual", "Comments", "Delete", "Edit"]
    return <ColumnGroup>
      <Row>
        <Column header="Type of Up Recovery" rowSpan={2} />
        <Column header="Round" rowSpan={2} />
        <Column header="Business  (Round from Card)" frozen={true} rowSpan={2} />
        <Column header="Analyst" frozen={true} rowSpan={2} />
        <Column header="Weekly Target For" colSpan={1} />
        <Column header="Site Discovery Hours" colSpan={2} />
        <Column header="" colSpan={1} />
        <Column header="Actions" colSpan={2} />
      </Row>
      <Row>
        {headerArr && headerArr.length > 0 ? headerArr.map((item, index) => {
          return <Column header={item} />
        }) : null}
      </Row>

    </ColumnGroup>;
  }

  getSiteDiscoveryColumns() {
    let headerArr = ["Site Discovery in(%)", "Plan", "Actual", "Comments"]
    return <ColumnGroup>
      <Row>
        <Column header="Type of Up Recovery" rowSpan={2} />
        <Column header="Round" rowSpan={2} />
        <Column header="Business  (Round from Card)" frozen={true} rowSpan={2} />
        <Column header="Analyst" frozen={true} rowSpan={2} />
        <Column header="Weekly Target For" colSpan={1} />
        {/* <Column header="Transition Hours" colSpan={2} /> */}
        <Column header="Site Discovery Hours" colSpan={2} />
        {/* <Column header="File Hours" colSpan={2} /> */}
        <Column header="" colSpan={1} />

        {/* <Column header="Actions" colSpan={2} /> */}
      </Row>
      <Row>
        {headerArr && headerArr.length > 0 ? headerArr.map((item, index) => {
          return <Column header={item} />
        }) : null}
      </Row>

    </ColumnGroup>;
  }

  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFieldsbasedonRole}
          formFields={this.getFormFields}
          getSiteDiscoveryColumns={this.getSiteDiscoveryColumns()}
          getEditSiteDiscoveryColumns={this.getEditSiteDiscoveryColumns()}
          FilterSortOptions={this.state.FilterSortOptions}
          className={true}
          exportRequried={false}
          sortedField={"dealType"}
          groupField={"dealType"}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          // frozen={false}
          // headerGroup={headerGroup}
          // analysts={this.state.roleOptions}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Weekly Recovery Plan'
          scrollHeight={"55vh"}
          isEdit={this.state.readOnly ? true : false}
          multiApi={`${apiCalls.PlanningGrid}/multi`}
          multiApiKey={"allRecords"}
          multiMethod="POST"
          displayright={true}
          icon='calendar-full'
          sortField={"dealType, Business Name, Transition Target, Site Discovery Target, Validation Target, File Target"}
          routeTo='planningGrid'
          displayViewOfForm='modal'
          apiResponseKey='viewResults'
          apiUrl={apiCalls.PlanningGrid}
          entityType='employee'
          sessionWarning={this.state.sessionWarning}
        />

      </span>
    );
  }
}