import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { Redirect } from 'react-router-dom';
// file upload
import { Row } from 'reactstrap';
import { RadioButton } from 'primereact/radiobutton';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../App/Loader';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import apiCalls from '../../config/apiCalls';
import fetch from '../../config/service'
import dateFormats from '../UI/FormatDate/formatDate';
// validate
// import validate from './validate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../redux/actions/userActions';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import configMessages from '../../config/configMessages';
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class PropertySearchV2Filters extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      click: true,
      resultSet: 0,
      setSearchType: "Exact"
    };
  }

  getStates = async () => {

    await fetch('GET', `seller/StateList?type=test`)
      .then(async (response) => {
        if (response) {
          if (response.respCode) {
            // showToasterMessage(response.respMessage, 'success');
            let states = []
            for (let state of response.states) {
              states.push({ label: state.Abbreviation, value: state.Abbreviation })
            }
            await this.setState({
              States: states
            })
          } else if (response.errorMessage) {
            // display message
            // showToasterMessage(response.errorMessage, 'error');
          }
        }
      })
  }
  /**
 * 
 * @param {Object} e 
 * @param {String} key 
 * @param {String} selectedtype 
 * setting the 
 */
  async onChange(e, key, selectedtype) {
    var val
    if (e && e.persist) {
      e.persist();
    }
    if (e && e.target) {
      val = e.target.value
      if (key) {
        await this.setState({
          [key]: val,
        })
      }
      let filtercriteria = {
        "key": key,
        "value": key === "status" || key === "department" ? val : encodeURIComponent(val),
        "type": selectedtype,
        "add_Filter": true
      }
      // filter: { "limit": 100, "page": 1, "criteria": [{ "Criteria": "propertyOwnerName", "Type": "fuzzy", "Value": "ex", "index": 0 }, { "Criteria": "holderName", "Type": "fuzzy", "Value": "ex", "index": 1 }], "sortfield": "sequence", "direction": "desc" }
      // site: Opra2

    }
  }
  setResultSet = async (count) => {
    await this.setState({ resultSet: count })
  }
  setSearchType = async (type) => {
    await this.setState({ setSearchType: type })
  }
  getDataFromServer = async () => {
    let sort = "", fields = ["propertyOwnerName", "propertyAddressLine1", "holderName", "sourceState"]
    for (let field of fields) {
      if (this.state[field]) {
        sort = sort + field + ", "
      }
    }
    console.log("sortsssss", sort)
    await this.setState({ showMessage: true })
    let filterCriteria = { "limit": 200, "page": 1, criteria: [], "sortfield": sort, "direction": "desc" }
    if (this.state.propertyOwnerName) {
      filterCriteria["criteria"].push({ "Criteria": "propertyOwnerName", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.propertyOwnerName, "index": 0 })
    }
    if (this.state.holderName) {
      filterCriteria["criteria"].push({ "Criteria": "holderName", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.holderName, "index": 1 })
    }
    if (this.state.sourceState && this.state.sourceState.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "sourceState", "Type": "Includes", "Value": JSON.stringify(this.state.sourceState), "index": 2 })
    }
    if (this.state.propertyAddressLine1) {
      filterCriteria["criteria"].push({ "Criteria": "propertyAddressLine1", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.propertyAddressLine1, "index": 3 })
    }
    this.props.setDataCriteriaforOpportunity(filterCriteria, {})

  }
  clearData = async () => {
    await this.setState({
      holderName: "",
      propertyAddressLine1: "",
      propertyOwnerName: "",
      resultSet: 0,
      showMessage: false,
      sourceState: [],
      setSearchType: "Exact",
      sourceState: null
    })
    let filterCriteria = { "limit": 200, "page": 1, criteria: [], "sortfield": "sequence", "direction": "desc" }
    this.props.closeDataTable()
    // this.props.setDataCriteriaforOpportunity(filterCriteria, {})
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    await this.setState({ loginCredentials: loginCredentials })
    this.getStates()

  }
  render() {
    return (
      <Card className="p-0" >
        {/* <CardHeader >
          Missing Money Results File Upload
        </CardHeader> */}
        <Loader loader={this.state.isLoading} />
        <CardBody className="p-0">
          <div className='row'>
            <div className='col-lg-12 pl-0 pt-2 mb-3' style={{ display: "flex" }}>
              <div className='col-lg-3'>
                {/* <h4><b>{"Property Search"}</b></h4> */}
              </div>
              <div className='col-lg-5' >
                <p><b>{"Search Mode"}</b></p>
                <div className='row mr-5 '>
                  <div className="field-radiobutton mr-2">
                    <RadioButton inputId="Exact" className="ml-2 mr-2" name="Exact" value="Exact" onChange={() => this.setSearchType("Exact")}
                      checked={this.state.setSearchType === 'Exact'}
                    />
                    <label htmlFor="city1" className='mb-0'>Exact Match Only </label>
                  </div>
                  <div className="field-radiobutton mr-2">
                    <RadioButton inputId="Relative" className="ml-2 mr-2" name="Relative" value="Relative" onChange={() => this.setSearchType("Relative")}
                      checked={this.state.setSearchType === 'Relative'}
                    />
                    <label htmlFor="city2" className='mb-0'>Relative Match Results </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='row col-lg-12'>
              <div className='col-lg-2'>
                <label>Reported Owner</label>
              </div>
              <div className='col-lg-6'>
                <InputText
                  value={this.state.propertyOwnerName}
                  name='propertyOwnerName'
                  id="propertyOwnerName"
                  style={{ height: 33, width: "100%" }}
                  onKeyPress={(e) => this.onChange(e, 'propertyOwnerName', 'regexOr')}
                  onChange={(e) => this.onChange(e, 'propertyOwnerName', 'regexOr')}
                  size={"20"} />
              </div>
              <div className='col-lg-1'>
                <Button color="primary"
                  outline
                  disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                  // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                  style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white", width: "100%" }}
                  onClick={(e) => this.getDataFromServer()}
                >
                  Search
                </Button>
              </div>


            </div>
            <div className='row col-lg-12 mt-2'>
              <div className='col-lg-2'>
                <label>Property Address</label>
              </div>
              <div className='col-lg-6'>
                <InputText
                  value={this.state.propertyAddressLine1}
                  name='propertyAddressLine1'
                  id="propertyAddressLine1"
                  style={{ height: 33, width: "100%" }}
                  onKeyPress={(e) => this.onChange(e, 'propertyAddressLine1', 'regexOr')}
                  onChange={(e) => this.onChange(e, 'propertyAddressLine1', 'regexOr')}
                  size={"20"} />
              </div>

            </div>
            <div className='row col-lg-12 mt-2'>
              <div className='col-lg-2'>
                <label>Holder</label>
              </div>
              <div className='col-lg-6'>
                <InputText
                  value={this.state.holderName}
                  name='holderName'
                  id="holderName"
                  style={{ height: 33, width: "100%" }}
                  onKeyPress={(e) => this.onChange(e, 'holderName', 'regexOr')}
                  onChange={(e) => this.onChange(e, 'holderName', 'regexOr')}
                  size={"20"} />
              </div>
              <div className='col-lg-1'>
                <Button color="primary"
                  outline
                  disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                  // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                  style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white", width: "100%" }}
                  onClick={(e) => this.clearData()}
                >
                  Clear
                </Button>
              </div>

            </div>
            <div className='row col-lg-12 mt-2'>
              <div className='col-lg-2'>
                <label>Property State</label>
              </div>
              <div className='col-lg-6'>
                <MultiSelect
                  name={"sourceState"}
                  id="sourceState"
                  filter={true}
                  value={this.state.sourceState}
                  style={{ height: 33, width: "100%" }}
                  appendTo={document.body}
                  // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                  maxSelectedLabels={1}
                  options={this.state.States}
                  onChange={(e) => this.onChange(e, 'sourceState', 'regexOr')}

                />
              </div>

            </div>
            <div className='row col-lg-12'>
              <div className='col-lg-2 mt-3 mb-2'>
                <h4><b>{`Results Set : ${this.state.resultSet}`}</b></h4>
              </div>
              {/* <div className='col-lg-2 mt-3 mb-2'>
                <Button color="primary"
                  outline
                  disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                  // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                  style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white" }}
                  onClick={(e) => this.getDataFromServer()}
                >
                  Go
                </Button>
              </div> */}
              {/* <div className='col-lg-2 mt-3 mb-2'>
                <Button color="primary"
                  outline
                  disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                  // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                  style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white" }}
                  onClick={this.props.openShowHideColumnsModal}
                >
                  Request Excel File
                </Button>
              </div> */}
              {/* <div className='col-lg-2 mt-3 mb-2'>
                <Button color="primary"
                  outline
                  disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                  // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                  style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white" }}
                  onClick={(e) => this.clearData()}
                >
                  Clear Data
                </Button>
              </div> */}
              {this.state.showMessage ?
                <div className='col-lg-5 mt-3 mb-2 p-0 ml-4' style={{ fontWeight: "bold", color: "green" }}>
                  {configMessages.PropertySearchV2Message}
                </div> : null}

            </div>
          </div>

        </CardBody>
      </Card >
    )
  }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PropertySearchV2Filters = reduxForm({
  form: "PropertySearchV2Filters", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(PropertySearchV2Filters);

// You have to connect() to any reducers that you wish to connect to yourself
PropertySearchV2Filters = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(PropertySearchV2Filters);

export default withTranslation('common')(PropertySearchV2Filters);