import React from 'react';
import {
  Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Row
} from 'reactstrap';
import classnames from 'classnames';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { settings } from '../../../redux/actions/settingsAction'
import ExpiredSessionModal from '../../Cruds/CommonModals/ExpiredSessionModal';

import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import configImages from '../../../config/configImages';

import OpenCorporate from '../OpenCorporates/index';
import OpenCorporateBranchBatch from './components/OpenCorporateBranchBatch';
import OpenCorporateBranch from './components/OpenCorporateBranch';
import OpenCorporateSearch from './components/OpenCorporateSearch';

let timeout

class OpenCorporateModel extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        { label: 'OPRA Validated Property Updater Logs', value: 'OPRA Validated Property Updater Logs' },
      ],
      isLoading: false,
      isUserUpdated: false,
      activeTab: '1'
    };
  }

  componentDidMount = async () => {
    this.setState({
      isLoading: true
    });
  }
  componentDidUpdate() {

    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }


  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }

  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }

  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state
    let isSubmitting = false

    return (
      <Card className="cardForListMargin m-0 card">

        <CardBody className="tableCardBody">
          <Row className="col-sm-12">

            <h4><span className='postionRelative pt-2 ml-1'>
              <b
              ><Link to={"#"} onClick={this.cancelReset} className="tableType pr-0" >
                  <img className={''} src={configImages.OpenCorporate} style={{ height: '35px', width: '35px', marginTop: 0, borderRadius: 0 }} alt="" />{" Open Corporates"}
                </Link>{this.state.selectedDealID ? ` - ${this.state.selectedDeal.EntityName} - ${this.state.selectedDealID}` : null}</b>

            </span>
            </h4>

          </Row>
          <div style={{ width: "100%" }}>
            <Nav tabs >
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  OC API Data
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                  disabled={this.state.isTabdisabled || this.state.isChildTrue}
                >
                  OC Branch Data
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                  disabled={this.state.isTabdisabled || this.state.isChildTrue}
                >
                  OC Branch Batch
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                  disabled={this.state.isTabdisabled || this.state.isChildTrue}
                >
                  OC Search
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {this.state.activeTab === "1" ?
                <TabPane tabId={this.state.activeTab} >
                  <OpenCorporate
                    activeTab={this.state.activeTab}
                  />
                </TabPane>
                :
                null
              }
              {this.state.activeTab === "2" ?
                <TabPane tabId={this.state.activeTab} >
                  <OpenCorporateBranch
                    activeTab={this.state.activeTab}
                  />
                </TabPane>
                :
                null
              }
              {this.state.activeTab === "3" ?
                <TabPane tabId={this.state.activeTab} >
                  <OpenCorporateSearch
                    activeTab={this.state.activeTab}
                  />
                </TabPane>
                :
                null
              }
              {this.state.activeTab === "4" ?
                <TabPane tabId={this.state.activeTab} >
                  <OpenCorporateBranchBatch
                    activeTab={this.state.activeTab}
                  />
                </TabPane>
                :
                null
              }
              <TabPane tabId="2">
                {/* {this.getTab2Body()} */}
              </TabPane>
            </TabContent>
          </div >
        </CardBody>
        {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
      </Card>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
OpenCorporateModel = reduxForm({
  form: "Common Form", // a unique identifier for this form
  // validate,
  enableReinitialize: true,
})(OpenCorporateModel);

// You have to connect() to any reducers that you wish to connect to yourself
OpenCorporateModel = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings,
  }
)(OpenCorporateModel);

export default OpenCorporateModel;






