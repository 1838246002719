import config from './config';

let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

// To get logged in user token
const getLoggedInuserToken = () => {
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    if (userData) {
        headers.Authorization = `Bearer ${userData.accessToken}`;
        headers.logggedInUserData = {
            email: userData.email,
            password: userData.password,
        }
        return headers;
    } else {
        return headers;
    }
}


const fetchMethodRequest = (method, url, body, type) => {
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    // let loginScreen = url.includes("localhost")
    if (url === 'auth/login') {
        // if (loginScreen === true) {
        // return sendRequestToServer(method, url, body, headers);
        // } else {
        throw Error("Login not available with fetchMethod. Use sendLoginRequestForToken")
        // }
    } else {
        let headers = getLoggedInuserToken();
        // upload method conditions, headers
        if (type && type === 'upload') {
            let formData = new FormData();
            if (body && !body.files) {
                formData.append('file', body);
                formData.append('uploadedByUsername', userData.displayName);
                formData.append('uploadedByUserId', userData.azureAdUserId);

            } else {
                if (body && body.files) {
                    for (var file of body.files) {
                        formData.append('files', file);
                        formData.append('uploadedByUsername', userData.displayName);
                        formData.append('uploadedByUserId', userData.azureAdUserId);
                    }
                }
            }
            if (headers.logggedInUserData) {
                delete headers.logggedInUserData;
            }
            body = {
                isUploadingImage: true,
                imageInfo: formData,
            }
        }
        // console.log("service urllllll", url)
        return sendRequestToServer(method, url, body, headers)
            .then(response => {
                if (response) {
                    if (response.errorCode && response.errorCode === 9001) { // token expiry
                        return response;
                    } else {
                        return response;
                    }
                }
            })
            .catch((err) => {

            });
    }
}

// generate guid and update headers from Azure Access Token
export const sendLoginRequestForToken = (accessToken) => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    return sendRequestToServer('POST', 'auth/login', {
        'accessType': config.entityType,
        offsetTime: new Date().getTimezoneOffset() / 60,
    }, headers, false)
        .then(
            (response) => {
                if (response && response.respCode && response.respCode === 200) {
                    let userDetails = response.details;
                    if (userDetails && userDetails._id) {
                        if (response.accessToken) {
                            let tokenInfo = {
                                accessToken: response.accessToken,
                                refreshToken: response.refreshToken,
                                tokenExpires: response.tokenExpires,
                            };
                            userDetails = { ...userDetails, ...tokenInfo };
                            // save user credentials in storage

                            let expiryTime = parseFloat(response.expireTime) * 60 * 1000
                            localStorage.setItem('loggedTime', expiryTime);
                            localStorage.setItem("presentTime", new Date())
                            localStorage.setItem("onTask", true)
                            // save user credentials in storage
                            localStorage.setItem('loginCredentials', JSON.stringify(userDetails));

                        }
                        if (userDetails && userDetails.rolePermissions) {
                            let permissions = userDetails.rolePermissions;
                            localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                        }
                    }
                    return userDetails;
                } else if (response.errorCode) {
                    throw Error(`Login Failed: ${response.errorMessage}`)
                }
            });
}

const sendRequestToServer = (method, url, body, headers, catchExceptions = true) => {
    let reqHeaders = { ...headers };

    if (reqHeaders && reqHeaders.logggedInUserData) {
        delete reqHeaders.logggedInUserData;
    }
    if (reqHeaders && (url === "auth/loginemail" || url === "auth/loginemail")) {
        delete reqHeaders.Authorization;
    }
    let isImageReqSent = false;
    let request;

    if (body && body.isUploadingImage) {
        // only for image upload
        isImageReqSent = true;
        if (url && url.includes("uploadMissingMoneyZip")) {
            request = fetch(`${config.missingMoneyUrl}${url}`, {
                method: method,
                headers: {
                    'Authorization': `${reqHeaders.Authorization}`
                },
                body: body.imageInfo
            })

        } else {
            request = fetch(`${config.apiUrl}${url}`, {
                method: method,
                headers: {
                    'Authorization': `${reqHeaders.Authorization}`
                },
                body: body.imageInfo
            })
        }
    }

    if (!isImageReqSent) {
        // send request for call except image upload 
        if (url && url.includes("us-zipcode.api.smartystreets.com")) {
            request = fetch(`${url}`, { method: method, headers: reqHeaders })
        }
        else if (method === 'GET' || method === 'DELETE') {
            if (url && (url.substring(0, 3) === "opp")) {
                request = fetch(`${config.nameUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders })
            } else {
                if (url && url.includes("sellerData")) {
                    request = fetch(`${url}&site=Opra2`, { method: method, headers: reqHeaders })

                } else if (url.includes("openCorporatesSearch/branchData") || url.includes("openCorporatesSearch/searchData") || url.includes("openCorporatesSearch/fileDownload") || url.includes("openCorporatesSearch/getOCReports")) {
                    request = fetch(`${config.openCorporateSearchUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders })

                } else if (url && (url.includes("fileImportQueue/getAll") || url.includes("fileImportQueue/getErrorsForFile"))) {
                    request = fetch(`${config.missingMoneyUrl}${url}`, {
                        method: method,
                        headers: {
                            'Authorization': `${reqHeaders.Authorization}`
                        },

                    })

                } else if (url.includes("getPropertyPresentFromDifferentSources") || url.includes("queueList") || url.includes("restartServer")
                    || url.includes("updatePropertyAddressDetails") || url.includes("getPropertyPresentFromDifferentSources") || url.includes("seller/dataSourceList") ||
                    url.includes("seller/propertyStatus") || url.includes("seller/StateList") || url.includes("propertySearches") || url.includes("updateSimilarProperties") ||
                    url.includes("StartOrStopDealsBatch") || url.includes("ScoringDealsBatch") || url.includes("StartOrStopBatch") || url.includes("ScoringBatch")) {
                    request = fetch(`${config.apiDealUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders })

                } else if (url) {
                    request = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders })
                }
            }
        } else if (method === 'POST' || method === 'PUT') {
            if (url && url.includes("settings/ScoringBatch?flag=") || url.includes("settings/ScoringDealsBatch?flag=") || url.includes("seller/addMails")) {
                request = fetch(`${config.apiDealUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders, body: JSON.stringify(body) })
            } else if (url.includes("openCorporatesSearch/branchData") || url.includes("openCorporatesSearch/searchData")) {
                request = fetch(`${config.openCorporateSearchUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders, body: JSON.stringify(body) })
            } else {
                request = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders, body: JSON.stringify(body) })
            }
        }
    }

    return request.

        then(res => {
            var headers = res.headers.get('Expires');
            var loggedTime = parseFloat(headers)
            if (loggedTime) {
                let expiryTime = loggedTime * 60 * 1000
                localStorage.setItem('loggedTime', expiryTime);
                localStorage.setItem("presentTime", new Date())
                localStorage.setItem("onTask", true)
            }
            return res.json()
        })
        .then(responseJson => {
            if (responseJson && responseJson['errorMessage'] === "Session expired please login again.") {
                localStorage.setItem('sessionexpired', true)
            }

            return responseJson;
        }).catch(err => {
            if (catchExceptions) {
                localStorage.setItem('serverError', true)
            } else {
                throw err;
            }
            // showToasterMessage(config.serverErrMessage, 'error');
            // // localStorage.setItem('serverErrMessage', true)
            // return 'serverError';
        });
}
export default fetchMethodRequest;
