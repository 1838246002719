/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import fetch from '../../config/service'
// import NotificationSystem from 'rc-notification';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import configMessages from '../../config/configMessages';
// import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';
// import { BasicNotification } from '../../shared/components/Notification';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { SidebarProps, ThemeProps } from '../../shared/prop-types/ReducerProps';
import ExpiredSessionModal from '../Cruds/CommonModals/ExpiredSessionModal';
import SessionexpiryModal from '../Cruds/CommonModals/SessionexpiryModal';

// let notification = null;

// const showNotification = () => {
//   notification.notice({
//     // content: <BasicNotification
//     //   title="👋 Welcome to the Rota!"
//     //   message="You have successfully registered in the Rota. Now you can start to explore the dashboard
//     //             interface with a bunch of components and applications. Enjoy!"
//     // />,
//     duration: 5,
//     closable: true,
//     style: { top: 0, left: 'calc(100vw - 100%)' },
//     className: 'right-up',
//   });
// };

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    theme: ThemeProps.isRequired,
  };



  constructor(props) {
    super(props);
    this.state = {
      expiredSessionModal: false,
      sessionExpiryModal: false
    }
  }

  componentDidMount = () => {
    this.setupSharedWorker()
    this.setUpLogoutSharedWorker()
    this.setupEventListeners()
    this.sessionWorker.port.onmessage = (e) => {
      if (e.data === "Session Logout") {
        console.log("uttej logout")
        this.setState({ expiredSessionModal: true, });
        this.sessionWorker.port.close()
      }
      else if (e.data === "ClearPopUp") {
        this.setState({ expiredSessionModal: false })
        this.signedIn()
        this.setupSharedWorker()
      } else if (e.data === "redirect") {
        this.setState({ sessionExpiryModal: true })
      } else if (e.data === "Session Expired") {
        this.setState({ sessionExpiryModalForPop: true, expiredSessionModal: false })

      }
    }
    this.sessionWorkerForLogout.port.onmessage = (e) => {
      if (e.data === "Session Expired") {
        this.setState({ sessionExpiryModalForPop: true, expiredSessionModal: false })
        this.sessionWorkerForLogout.port.close()
      }
    }
    // NotificationSystem.newInstance({}, n => notification = n);
    // setTimeout(() => showNotification(), 700);
  }



  setupSharedWorker() {
    if (this.sessionWorker) {
      this.sessionWorker.port.close();
    }
    this.sessionWorker = new SharedWorker('sessionLogoutWorker.js');
  }
  setUpLogoutSharedWorker() {
    if (this.sessionWorkerForLogout) {
      this.sessionWorkerForLogout.port.close();
    }
    this.sessionWorkerForLogout = new SharedWorker('sessionWorkerForLogout.js');
  }
  cancelexpiredSessionModal = async (data) => {
    await this.setState({
      expiredSessionModal: false
    })
    if (data === "close") {
      await this.signedIn()

    }
    if (data === "redirect") {
      this.setState({ sessionExpiryModal: true })
      // this.sessionWorker.port.close()

    }
    this.sessionWorker.port.postMessage({
      type: 'ClearPopUp', data: data
    });
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}
        setupSharedWorker={this.setupSharedWorker}
        signedIn={this.signedIn}

      />
    )
  }


  signedIn = () => {
    console.log("trueeeeeeeeeeeeeeeeeeeeeeeeeeeee")
    let sessionexpired = localStorage.getItem('sessionexpired')
    // if (sessionexpired === "true") {
    //     this.setState({ sessionExpiryModal: true })
    // }

    return fetch('POST', `Auth/refresh-AuthenticationToken`)
      .then((response) => {
        if (response) {
          if (response.respCode && response.respCode === 200) {
            localStorage.setItem('multiTabsOpen', true);
            // display message
            // showToasterMessage(response.respMessage, 'success');
          } else if (response.errorMessage && (response.errorMessage === configMessages.tokenMessage || response.errorMessage === configMessages.sessionExpired)) {
            this.setState({ sessionExpiryModal: true })
            localStorage.setItem('multiTabsOpen', false);

          }
          this.setState({ isLoading: false });
          this.cancelexpiredSessionModal()

          // this.props.getData()
        }
      }).catch((err) => {
        return err;
      });
  }

  setupEventListeners() {
    document.addEventListener('mousemove', this.workerForSessionTimeOut);
    document.addEventListener('keydown', this.workerForSessionTimeOut);

  }

  workerForSessionTimeOut = () => {
    let loggedTime = parseInt(localStorage.getItem('loggedTime'))
    let forceLogoutTimeInMin = parseInt(localStorage.getItem('forceLogoutTimeInMin'))
    this.sessionWorker.port.postMessage({
      type: 'active', data: "", loggedTime: loggedTime
    });
    this.sessionWorkerForLogout.port.postMessage({
      type: 'active', data: "", loggedTime: forceLogoutTimeInMin
    });


  }

  componentWillUnmount() {
    // notification.destroy();
  }

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  // toggleTopNavigation = () => {
  //   const { dispatch } = this.props;
  //   dispatch(toggleTopNavigation());
  // };

  // changeBorderRadius = () => {
  //   const { dispatch } = this.props;
  //   dispatch(changeBorderRadius());
  // };

  // toggleBoxShadow = () => {
  //   const { dispatch } = this.props;
  //   dispatch(toggleBoxShadow());
  // };

  render() {
    const { sidebar } = this.props;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      // 'layout--top-navigation': customizer.topNavigation,
    });
    return (
      <div className={layoutClass}>
        <Topbar
          urlPath={this.props.route}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
        />
        <Sidebar
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
        {this.state.sessionExpiryModalForPop ?
          <SessionexpiryModal
            SOpen={this.state.sessionExpiryModalForPop}

          />
          : null}
        {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
        {this.state.sessionExpiryModal ?
          <Redirect to="/log_in" /> : null}
      </div>

    );
  }
}

export default withRouter(connect(state => ({
  customizer: state.customizer,
  sidebar: state.sidebar,
  theme: state.theme,
}))(Layout));
