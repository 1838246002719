import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft,faCaretRight } from "@fortawesome/free-solid-svg-icons";

export default class Guidelines extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           showGuideLines:true
        }
    }
    render(){
        return (
            <div className='guidelines-wrap d-flex'>
                <div className='menu' >
                    
                    <a onClick={()=>{this.setState({showGuideLines:!this.state.showGuideLines})}}><FontAwesomeIcon
                        // color='red'
                        icon={this.state.showGuideLines ? faCaretLeft : faCaretRight} />Guidelines &nbsp;</a>
                </div>
                {this.state.showGuideLines &&
                    <div className='guidelines'>
                        <h5>{this.props.activePageType} Guidelines </h5>
                        <ul className='mt-1'>
                            {(this.props.guidelines && this.props.guidelines[this.props.activePageType] && this.props.guidelines[this.props.activePageType].length > 0) ? 
                            this.props.guidelines[this.props.activePageType].map((guideline) => <li className='my-3'> {guideline}
                            </li>)
                            : 'No  guidelines found'}
                            
                           
                           
                           
                        </ul>
                    </div>
                }
            </div>
        )
    }
}