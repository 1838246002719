

import React from 'react';
import {
	Button, Container,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Loader from '../../App/Loader';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
// import DefaultInput from '../../shared/components/form/DefaultInput';
import fetch from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiSelect } from 'primereact/multiselect';
import { Redirect } from 'react-router-dom';

// import DeleteRowModal from '../CommonModals/DeleteRowModal';
// import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
class RuleConfiguration extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		payload: PropTypes.arrayOf(PropTypes.shape({
			color: PropTypes.string,
			value: PropTypes.string,
		})).isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			dashboardData: {},
			isLoading: false,
			modifiedStates: [
				{ label: "AL", value: "AL" },
				{ label: "AK", value: "AK" },
				{ label: "AZ", value: "AZ" },
				{ label: "AR", value: "AR" },
				{ label: "CA", value: "CA" },
				{ label: "CO", value: "CO" },
				{ label: "CT", value: "CT" },
				{ label: "DE", value: "DE" },
				{ label: "FL", value: "FL" },
				{ label: "GA", value: "GA" },
				{ label: "HI", value: "HI" },
				{ label: "ID", value: "ID" },
				{ label: "IL", value: "IL" },
				{ label: "IN", value: "IN" },
				{ label: "IA", value: "IA" },
				{ label: "KS", value: "KS" },
				{ label: "KY", value: "KY" },
				{ label: "LA", value: "LA" },
				{ label: "ME", value: "ME" },
				{ label: "MD", value: "MD" },
				{ label: "MA", value: "MA" },
				{ label: "MI", value: "MI" },
				{ label: "MN", value: "MN" },
				{ label: "MS", value: "MS" },
				{ label: "MO", value: "MO" },
				{ label: "MT", value: "MT" },
				{ label: "NE", value: "NE" },
				{ label: "NV", value: "NV" },
				{ label: "NH", value: "NH" },
				{ label: "NJ", value: "NJ" },
				{ label: "NM", value: "NM" },
				{ label: "NY", value: "NY" },
				{ label: "NC", value: "NC" },
				{ label: "ND", value: "ND" },
				{ label: "OH", value: "OH" },
				{ label: "OK", value: "OK" },
				{ label: "OR", value: "OR" },
				{ label: "PA", value: "PA" },
				{ label: "PR", value: "PR" },
				{ label: "RI", value: "RI" },
				{ label: "SC", value: "SC" },
				{ label: "SD", value: "SD" },
				{ label: "TN", value: "TN" },
				{ label: "TX", value: "TX" },
				{ label: "UT", value: "UT" },
				{ label: "VT", value: "VT" },
				{ label: "VA", value: "VA" },
				{ label: "DC", value: "DC" },
				{ label: "WA", value: "WA" },
				{ label: "WV", value: "WV" },
				{ label: "WI", value: "WI" },
				{ label: "WY", value: "WY" },
			],
			isRedirect: false
		};
	}

	componentDidMount = () => {
		let user = JSON.parse(localStorage.getItem('loginCredentials'));
		let addorEdit = window.location.pathname
		let isAdd = addorEdit.slice(-3);
		if (user) {
			this.setState({
				loginData: user,
				role: user.role,
				RulesListArray: [],
				iteration: "",
				isNewRule: isAdd === "add" ? true : false,
				isEditedId: isAdd !== "add" ? window.location.pathname.split("/")[2] : null
			})

			this.getMenuListFromServer();
			this.getRulesForWorkflow();
			this.getRoles();
		}
	}
	getRulesForWorkflow = async () => {
		if (window.location.pathname.split("/")[2]) {

			let filter = {}
			filter.criteria = [{
				key: "_id",
				value: window.location.pathname.split("/")[2],
				type: "eq"
			}]
			return fetch('GET', `workflowRuleDetails/${window.location.pathname.split("/")[2]}`)
				.then(async (response) => {
					if (response && response.details) {
						let data = response.details, tempData = [];
						await this.setState({
							RulesListArray: data.rules,
							ruleName: data.name,
							ruleDescription: data.description,
							selectedScreen: data.environment,
							selectedRole: data.role,
							activevalue: data.active,
							selectedNotes: data.notes,
							workflowRulesDetailsIdentifier: data.workflowRulesDetailsIdentifier,
							UpdatedId: data._id,
							activeIndicator: data.activeIndicator,
						})
						this.handleeditChangeData()
						this.sendColumnDataToServer(data.environment);

						console.log("HGHGHG", data)
					} else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
						await this.setState({
							sessionWarning: true,

						})
					} else {
						await this.setState({
							selectedWorkflow: []
						})
					}
				}).catch((err) => {
					return err;
				});
		}
		// this.sendColumnDataToServer();

	}
	handleeditChangeData = async (value, field, index, i) => {
		let changedData = this.recursiveeditOperatorChangeData()
		console.log("changedData", changedData)
		await this.setState({
			RulesListArray: changedData
		})
	}
	recursiveeditOperatorChangeData = (list) => {
		list = list ? list : this.state.RulesListArray
		return list.map(item => { return { ...item } }).filter(item => {
			if ('rules' in item) {
				item.rules = this.recursiveeditOperatorChangeData(item.rules);
			}
			if (item && item.id !== null) {
				console.log(item)
				document.getElementById(`selectedOperator${item.id}`).value = item.operator !== null ? item.operator : "=";
			}
			return item;
		});
	}

	getPostRulesForWorkflow = async () => {
		let data = {
			rules: this.state.RulesListArray,
			Name: this.state.ruleName,
			notes: this.state.selectedNotes,
			Description: this.state.ruleDescription,
			Environment: this.state.selectedScreen,
			Role: this.state.selectedRole,
			Active: this.state.activevalue,
		}
		let body = data
		let method, api
		if (this.state.isNewRule) {
			method = "POST"
			api = `workflowRuleDetails`
		} else {
			method = "PUT"
			api = `workflowRuleDetails/${window.location.pathname.split("/")[2]}`
			body.workflowRulesDetailsIdentifier = this.state.workflowRulesDetailsIdentifier
			body._id = this.state.UpdatedId
			body.activeIndicator = this.state.activeIndicator
		}
		return fetch(method, api, body)
			.then(async (response) => {
				if (response && response.respCode) {
					await this.setState({
						isRedirect: true
					})
					// if (!type) this.saveDataFormat(values)
				} else if (response && response.errorMessage) {
					// this.getErrorMessage(response.errorMessage)
				}
			}).catch((err) => {
				return err;
			});
	}

	handlePercentage = async (Val) => {
		let counter = 0;
		const interval = setInterval(async () => {
			if (this.state.isLoading) {
				counter = counter + 15;
				await this.setState({
					progress: counter,
				})
			} else {
				if (!this.state.isLoading) {
					clearInterval(interval);
				}
			}
		}, 100);
	}

	isShowLoader = async (value) => {
		this.setState({
			isLoading: value
		})
		if (value === true) {
			this.handlePercentage("1")
		}
	}


	//Get THreshold Values from SQL
	getMenuListFromServer = async () => {
		let filterCriteria = {}, url;
		filterCriteria.sortfield = 'sequenceNo';
		filterCriteria.direction = 'asc';
		url = `${apiCalls.MenuList}?filter=${JSON.stringify(filterCriteria)}`;
		let neWmenuList = [];
		return fetch('GET', url)
			.then(async (res) => {
				if (res && res.menulists && res.menulists.length > 0) {
					if (res.menulists) {
						res.menulists.forEach((item) => {
							if (item.submenus && item.submenus.length > 0) {
								item.submenus.forEach(sitem => {
									neWmenuList.push({
										label: sitem.displayTitle,
										value: sitem.displayTitle,
									});
								})
							} else {
								neWmenuList.push({
									label: item.displayTitle,
									value: item.displayTitle,
								});
							}
						});
					}
					this.setState({
						menuList: neWmenuList
					})
				} else {
					this.setState({
						menuList: []
					})
				}
			})
	}


	getRoles = async () => {
		return fetch('GET', apiCalls.Roles)
			.then(async (response) => {
				if (response && response.roles && response.roles.length > 0) {
					let data = response.roles, tempData = [];
					for (let i = 0; i < data.length; i++) {
						tempData.push({ 'label': data[i]['role'], "value": data[i]['role'] })
					}
					await this.setState({
						roleOptions: tempData
					})
				} else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
					await this.setState({
						sessionWarning: true,

					})
				} else {
					await this.setState({
						roleOptions: []
					})
				}
			}).catch((err) => {
				return err;
			});
	}


	deleteKeyValueItem = async () => {
		this.isShowLoader(true)

		let apiUrl = "ruleConfiguration";
		let method = "DELETE", url, body = {}
		let id = this.state.deleteRowDataId._id
		url = `${apiUrl}/${id}`;
		if (id) {
			return fetchMethodRequest(method, url, body)
				.then(async (response) => {
					let sessionexpired = localStorage.getItem('sessionexpired')
					if (sessionexpired === "true") {
						this.setState({ sessionExpiryModal: true })
					}
					await this.setState({
						openDeleteModal: false
					});
					if (response && response.respCode) {
						//showToasterMessage(response.respMessage, 'success');
						this.getDataFromServer(this.state.filterCriteria);
					} else if (response && response.errorMessage) {
						// showToasterMessage(response.errorMessage, 'error');
						// this.getErrorMessage(response.errorMessage, "", "error")
						await this.setState({
							errorMessage: response.errorMessage,
							errorResponse: false,
							errorcolor: "red"
						})
					}
				}).catch((err) => {
					return err;
				});
		}
		else {
			let list_arr = this.state.RulesListArray;
			list_arr.splice(this.state.deleteRowIndex, 1)
			this.isShowLoader(false)
			await this.setState({
				RulesListArray: JSON.parse(JSON.stringify(list_arr)),
				openDeleteModal: false,
				deleteRowDataId: null,
				deleteRowIndex: -1,
			})
		}

	}

	// conformation for delete item
	deleteConfirmAction = async (item, i, selectActions) => {

		this.setState({
			deleteRowDataId: item,
			deleteRowIndex: i,
			openDeleteModal: true,
		});
	}

	//close delete modal
	closeDeleteModal = async () => {
		this.setState({
			openDeleteModal: false,
		})
	}

	getKeyValueButton() {
		// const { t } = this.props
		return (
			<div className='d-flex col-12 pl-0 pr-0 keylist'>
				<div className='col-12 px-0'>
					<div>
						{this.getKeyValueTable()}
					</div>
					<div className='row ml-0 mr-0 pt-4'>
						<h5 className={"mr-auto mt-auto mb-auto"} style={{ paddingLeft: "15px", fontWeight: "400" }}>{""}</h5>
					</div>

				</div>
			</div>
		)
	}

	KeyValueTable = async (keyCount) => {
		let RulesListArray = this.state.RulesListArray;
		if (!this.state.validationExists) {
			let index = RulesListArray && RulesListArray.length ? RulesListArray.length + 1 : 1
			RulesListArray.push({
				action: this.state.actionData,
				actionCondition: this.state.actionCondition,
				id: index.toString(),
				name: ``,
				rules: [],
				new: true
			})
		}
		//RulesListArray.push([])
		await this.setState({
			RulesListArray: RulesListArray,
		});

	}

	handleChange = async (value, field, index, subIndex) => {
		console.log("operatoroperator", value)
		var RulesListArray = this.state.RulesListArray
		if (field === "rules") {
			let stepIndex
			if (subIndex.rules.length === 0) {
				stepIndex = subIndex.id + ".1"
			} else {
				stepIndex = (subIndex.id).toString() + "." + parseInt(subIndex.rules.length + 1)
			}
			RulesListArray[index][field].push({
				id: stepIndex.toString(),
				action: subIndex.action,
				rules: [],
				Condition: "singleCondition",
				new: true
			})
		} else {
			RulesListArray[index][field] = value
		}
		if (field === "Condition" && (value === "and" || value === "or")) {

		}
		await this.setState({
			RulesListArray: RulesListArray
		})
	}

	handleChangeData = async (value, field, index, i) => {
		let changedData = this.recursiveChangeData('', value, field, index)
		await this.setState({
			RulesListArray: changedData
		})
	}
	recursiveChangeData = (list, value, field, id) => {
		list = list ? list : this.state.RulesListArray
		return list.map(item => { return { ...item } }).filter(item => {
			if ('rules' in item) {
				item.rules = this.recursiveChangeData(item.rules, value, field, id);
			}
			if (item.id === id) {
				item[field] = value
			}
			return item;
		});
	}

	getpushedParentrecord(object, criteria) {
		var i, j, result = [];
		for (i in object) {
			if (i === criteria.type && object[i] == criteria.index) {
				result.push(object);
			}
			if (i === 'rules' && object[i].length > 0) {
				for (j = 0; j < object[i].length; j++) {
					result = result.concat(this.getpushedParentrecord(object[i][j], criteria));
				}
			} else {
				if (i === 'rules' && object[i].length === 0)
					this.getpushedParentrecord(object[i], criteria)
			}
		}
		return result;
	}

	//delete start working
	handleDeleteRecord = async (item, id) => {//delete functionality
		let data = this.recursiveRemove('', id)
		await this.setState({
			RulesListArray: data
		})
	}
	recursiveRemove(list, id) {
		list = list ? list : this.state.RulesListArray
		return list.map(item => { return { ...item } }).filter(item => {
			if ('rules' in item) {
				item.rules = this.recursiveRemove(item.rules, id);
			}
			return item.id !== id;
		});
		// for (var obj of list) {

	}
	//delete functionality ending




	findValue = (arr, str) => {
		var str = str ? str : "";
		arr = arr ? arr : this.state.RulesListArray
		if (arr.length === 0) {
			return str;
		}
		for (var i = 0; i <= arr.length; i++) {
			//str = str
			const obj = arr[i]

			if (obj.id) {
				if (obj && obj.propertyName) {
					obj.operator = document.getElementById(`selectedOperator${obj.id}`).value
					str = str + obj.Condition + "   " + obj.propertyName + " " + document.getElementById(`selectedOperator${obj.id}`).value + " " + obj.Value;
				} else {
					str = str + obj.Value;
				}
				if (obj.rules) {
					return this.findValue(obj.rules, str);
				}
			}
		}
	};
	findValueforAllRules = async () => {
		let data;
		let iteration = "";
		var arr1 = this.state.RulesListArray
		let noOfConditions = [];
		for (var j = 0; j <= this.state.RulesListArray.length - 1; j++) {
			var rufarr = []
			rufarr.push(this.state.RulesListArray[j])
			data = await this.findValue(rufarr, "");
			data = data + this.state.RulesListArray[j]["choosedthen"] + this.state.RulesListArray[j]["choosedelse"]
			noOfConditions.push(data)
			console.log("RulesListArray", this.state.RulesListArray)
			console.log("RulesListArray test", noOfConditions)
			iteration = iteration + data;
		}
		await this.setState({
			noOfConditions: noOfConditions
		})
	}




	handleChangeaddSubItem = async (subindex) => {
		var RulesListArray = this.state.RulesListArray
		let stepIndex
		if (subindex.rules.length === 0) {
			stepIndex = subindex.id + ".1"
			// if()
		} else {
			stepIndex = (subindex.id).toString() + "." + parseInt(subindex.rules.length + 1)
		}
		let getId = parseInt(subindex.id.toString().split(".")[0]) - 1
		let pushedObj = RulesListArray[getId]
		let criteria = ({ type: 'id', index: subindex.id })
		pushedObj = await this.getpushedParentrecord(pushedObj, criteria)
		pushedObj[0]["rules"].push({
			id: stepIndex.toString(),
			description: ``,
			action: subindex.action,
			Condition: "singleCondition",
			rules: [],
			new: true
		})
		// RulesListArray[pushedItem.id]=
		await this.setState({
			RulesListArray: RulesListArray
		})
	}


	getKeyValueTable() {//mainTable
		return (
			<div className='d-flex col-12 ' style={{ width: "100%" }}>
				<div className='col-12 px-0'>
					<div >
						{
							this.state.RulesListArray && this.state.RulesListArray.length > 0 ?
								this.state.RulesListArray.map((arrayItem, index) => {
									return <div>
										<div className='col-sm-12 row mt-2'>
											<div className='stepNo'>{index + 1}</div>
											<div className='stepNo'>{arrayItem.actionCondition}</div>

											{arrayItem.action === "Prompt User – True/False Continue" ?
												<>
													<div className='stepNo'>{arrayItem.action}</div>
													<InputText
														className={`selected_value${arrayItem.id}`}
														value={arrayItem.value}
														style={{ marginLeft: 5 }}
														onChange={(e) => this.handleChange(e.target.value, "value", index)}
													/>
													<span style={arrayItem && arrayItem.Condition && arrayItem.Condition !== "singleCondition" ? { display: "block" } : { display: "none" }}>
														<FontAwesomeIcon
															icon={faPlus}
															color="blue"
															className='mt-0 mr-0' size='lg'
															onClick={() => this.handleChange(arrayItem, "rules", index, arrayItem)}
															data-toggle="tool-tip" title="List"
														/>
													</span><span style={arrayItem && arrayItem.Condition && arrayItem.Condition !== "singleCondition" ? { display: "block", marginLeft: "auto" } : { display: "none" }}>
														<FontAwesomeIcon
															icon={faMinus}
															color="red"
															onClick={() => this.handleDeleteRecord(arrayItem, index + 1)}

															className='mt-0 mr-0' size='lg'
															data-toggle="tool-tip" title="List"
														/>
													</span>
												</>
												:

												<>	<Dropdown
													className={`select_condition${arrayItem.id}`}
													style={{ marginLeft: 5 }}
													value={arrayItem.condition}
													onChange={(e) => this.handleChange(e.value, "condition", index)}
													options={[{ label: "Single Condition", value: "singleCondition" }, { label: "And", value: "and" }, { label: "OR", value: "or" },]}
												/><Dropdown
														className={`select_screen_field${arrayItem.id}`}
														style={{ marginLeft: 5 }}
														value={arrayItem.propertyName}
														onChange={(e) => this.handleChange(e.value, "propertyName", index)}
														options={this.state.propertyNames}
													/>
													<select name={`selectedOperator${arrayItem.id}`} id={`selectedOperator${arrayItem.id}`} style={{ width: 50 }} onChange={(e) => this.handleChange(document.getElementById(`selectedOperator${arrayItem.id}`).value, "operator", index)}>
														<option value="=">=</option>
														<option value="!=">!=</option>
														<option value="<">{">"}</option>
														<option value=">">{">"}</option>
														<option value="IN">IN</option>
														{/*	<option value="nin">Exists</option>
											<option value="in">Not Exists</option> */}
													</select>
													{arrayItem.propertyName === "sourceState" ?
														<>	<MultiSelect
															className={`selected_value${arrayItem.id}`}
															style={{ marginLeft: 5 }}
															value={arrayItem.value}
															maxSelectedLabels={1}
															onChange={(e) => this.handleChange(e.value, "value", index)}
															options={this.state.modifiedStates}
														/>
															<span style={arrayItem && arrayItem.Condition && arrayItem.Condition !== "singleCondition" ? { display: "block" } : { display: "none" }}>
																<FontAwesomeIcon
																	icon={faPlus}
																	color="blue"
																	className='mt-0 mr-0' size='lg'
																	onClick={() => this.handleChange(arrayItem.rules ? arrayItem.rules + 1 : 1, "rules", index)}
																	data-toggle="tool-tip" title="List"
																/>
															</span>	</>
														: <><InputText
															className={`selected_value${arrayItem.id}`}
															value={arrayItem.value}
															style={{ marginLeft: 5 }}
															onChange={(e) => this.handleChange(e.target.value, "value", index)}
														/>
															<span style={arrayItem && arrayItem.condition && arrayItem.condition !== "singleCondition" ? { display: "block" } : { display: "none" }}>
																<FontAwesomeIcon
																	icon={faPlus}
																	color="blue"
																	className='mt-0 mr-0' size='lg'
																	onClick={() => this.handleChange(arrayItem, "rules", index, arrayItem)}
																	data-toggle="tool-tip" title="List"
																/>
															</span><span style={arrayItem && arrayItem.condition && arrayItem.condition !== "singleCondition" ? { display: "block", marginLeft: "auto" } : { display: "none" }}>
																<FontAwesomeIcon
																	icon={faMinus}
																	color="red"
																	onClick={() => this.handleDeleteRecord(arrayItem, index + 1)}

																	className='mt-0 mr-0' size='lg'
																	data-toggle="tool-tip" title="List"
																/>
															</span></>
													}</>

											}

										</div>
										{this.getSubItems(arrayItem, index)}
										<div className='d-flex'>	<div className='stepNo'>then</div>
											<Dropdown
												className='select_screen_field'
												style={{ marginLeft: 5 }}
												value={arrayItem.thenCase}
												onChange={(e) => this.handleChange(e.value, "thenCase", index)}
												options={[{ label: "Continue Flow", value: "continueFlow" }, { label: "Stop Flow", value: "stopFlow" },]}
											/>
											<div className='d-flex'>	<div className='stepNo'>Else</div>
												<Dropdown
													className='select_screen_field'
													style={{ marginLeft: 5 }}
													value={arrayItem.elseCase}

													onChange={(e) => this.handleChange(e.value, "elseCase", index)}
													options={[{ label: "Continue Flow", value: "continueFlow" }, { label: "Stop Flow", value: "stopFlow" },]}
												/>				</div>		</div>
									</div>

								}) : null}
					</div>

				</div>

				{/* </Table> */}
			</div>

		)
	}




	getSubItems = (arrayItem, index) => {
		return (
			<>
				{
					arrayItem && arrayItem.rules && arrayItem.rules.length > 0 ?
						arrayItem.rules.map((item, i) => {
							return <div className='col-sm-12 mt-2  row' style={{ marginLeft: `${i}%` }}>
								<div className='stepNo'>{item.id}</div>
								{/* <div className='stepNo'>{arrayItem.actionCondition}</div> */}
								{arrayItem.action === "Prompt User – True/False Continue" ?
									<>
										<InputText
											className={`selected_value${item.id}`}
											style={{ marginLeft: 5 }}
											value={item && item.value ? item.value : null}
											onChange={(e) => this.handleChangeData(e.target.value, "value", item.id, i)}
										/>

									</>
									:

									<>
										<Dropdown
											className={`select_condition${item.id}`}
											style={{ marginLeft: 5 }}
											value={item.condition}
											onChange={(e) => this.handleChangeData(e.value, "condition", item.id)}
											options={[{ label: "Single Condition", value: "singleCondition" }, { label: "And", value: "and" }, { label: "OR", value: "or" },]}
										/>
										<Dropdown
											style={{ marginLeft: 5 }}
											className={`select_screen_field${item.id}`}
											value={item && item.propertyName ? item.propertyName : null}
											onChange={(e) => this.handleChangeData(e.value, "propertyName", item.id, i)}
											options={this.state.propertyNames}
										/>
										<select name={`selectedOperator${item.id}`} id={`selectedOperator${item.id}`} style={{ width: 50 }} onChange={(e) => this.handleChange(document.getElementById(`selectedOperator${item.id}`).value, "operator", index, i)}>
											<option value="=">=</option>
											<option value="!=">!=</option>
											<option value=">">{">"}</option>
											<option value="<">{"<"}</option>

										</select>

										{item.propertyName === "sourceState" ?
											<MultiSelect
												className={`selected_value${item.id}`}
												value={item && item.value ? item.value : null}
												onChange={(e) => this.handleChangeData(e.value, "value", item.id, i)}
												style={{ marginLeft: 5 }}
												maxSelectedLabels={1}
												options={this.state.modifiedStates} /> :
											<InputText
												className={`selected_value${item.id}`}
												style={{ marginLeft: 5 }}
												value={item && item.value ? item.value : null}
												onChange={(e) => this.handleChangeData(e.target.value, "value", item.id, i)}
											/>
										}

									</>}
								<div className='d-contents'
									style={item && item.condition && item.condition !== "singleCondition" ? { display: "block" } : { display: "none" }}>

									{/* style={item && (item.Condition && item.Condition !== "singleCondition") || item.action === "Prompt User – True/False Continue" ? { display: "block" } : { display: "none" }}> */}
									<span>

										<FontAwesomeIcon
											icon={faPlus}
											color="blue"
											className='mt-0 mr-0' size='lg'
											onClick={() => this.handleChangeaddSubItem(item, index, item, i)}
											data-toggle="tool-tip" title="List"
										/></span>
									<span className='ml-2'
									// style={arrayItem && (arrayItem.Condition && arrayItem.Condition !== "singleCondition" || item.action === "Prompt User – True/False Continue") ? { display: "block", marginLeft: "auto" } : { display: "none" }}
									>
										<FontAwesomeIcon
											icon={faMinus}
											color="red"
											className='mt-0 mr-0' size='lg'
											onClick={() => this.handleDeleteRecord(arrayItem, item.id)}
											data-toggle="tool-tip" title="List"
										/>
									</span>
								</div>

								{this.getSubItems(item, index)}

							</div>

						}) : null}
			</>
		)
	}

	//user preference col order
	sendColumnDataToServer = async (val) => {
		let colOrder = localStorage.getItem(`${val}_column_order`);
		let localcolumns = await JSON.parse(colOrder);
		let tempTableFields = [];
		if (localcolumns) {
			if (localcolumns && localcolumns.details && localcolumns.details["Default"]) {
				tempTableFields = localcolumns.details["Default"];

			} else if (localcolumns && localcolumns.details) {
				tempTableFields = localcolumns.details["Default"];
			} else {
				tempTableFields = localcolumns
			}
		} else {
			let method = "GET";
			let url = "";
			let keyname = `${val}_column_order`;
			let loginData = localStorage.getItem("loginCredentials");
			// if (loginData) {
			loginData = JSON.parse(loginData);
			url = `${apiCalls.Users}/preferences/${loginData._id}?key=${keyname}`;
			await fetchMethodRequest(method, url).then(async (resp) => {
				if (resp) {
					if (resp) {
						if (resp.details) {
							let localcolumns = resp.details
							if (resp && resp.details && resp.details["Default"]) {
								tempTableFields = resp.details["Default"];

							} else if (resp && resp.details) {
								tempTableFields = resp.details["Default"];
							} else {
								tempTableFields = resp
							}

						}
					}
				}
			});
		}
		let emptyArr = []
		for (var obj of tempTableFields) {
			emptyArr.push({
				label: obj.header,
				value: obj.field
			})
		}

		this.setState({ selectedScreen: val, propertyNames: emptyArr })

	};
	getScreenFields = async (val) => {
		if (val) {
			let colOrder = localStorage.getItem(`${val}_column_order`);
			let localcolumns = await JSON.parse(colOrder);
			let tempTableFields = [];
			if (localcolumns && localcolumns.details && localcolumns.details["Default"]) {
				tempTableFields = localcolumns.details["Default"];

			} else if (localcolumns && localcolumns.details) {
				tempTableFields = localcolumns.details["Default"];
			} else {
				tempTableFields = localcolumns
			}
			let emptyArr = []
			for (var obj of tempTableFields) {
				emptyArr.push({
					label: obj.field,
					value: obj.field
				})
			}

			this.setState({ selectedScreen: val, propertyNames: emptyArr })
		}
	}
	addCondition = async (value) => {
		await this.setState({
			actionCondition: value,
		})

		if (this.state.actionData === "t/f") {
			this.KeyValueTable()
		}
	}
	userPrompt = async (e) => {
		await this.setState({
			actionData: e,
			actionCondition: ""
		})
		if (e == "Prompt User – True/False Continue") {
			this.KeyValueTable()
		}
	}


	render() {
		return (
			<div>
				<Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />

				<Container className="tableCardBody mt-3" style={{ width: '98%', marginLeft: '20px', backgroundColor: "white" }}>
					<div className='rules_details configuration_intro_screen col-sm-12 p-0'>
						<h3 className='rule_screen_title'>Rule Configuration</h3>
						<div className=' col-sm-12 px-0 row m-0 py-2'>
							<div className='col-sm-6 -flex px-0'>
								<label className='rule_title_field mx-2'><b>Rule :</b></label>
								<InputText
									value={this.state.ruleName}
									onChange={(e) => this.setState({ ruleName: e.target.value })}
									style={{ width: "70%" }} />
							</div>
							<div className='col-sm-6 d-flex px-0 justify-content-between'>
								{/* <span><Button color="primary" className='add_rule_btn mb-0' onClick={() => this.setState({ isNewRule: !this.state.isNewRule, ruleName: "" })} outline>Add Rule</Button></span> */}
								<span className='mr-3'>
									<Button color="primary" className='save_rule_btn mb-0' outline onClick={() => this.getPostRulesForWorkflow()}>Save Rule</Button>
									<Button color="danger" className='cancel_rule_btn mb-0' outline onClick={() => this.setState({ isRedirect: true })}>Cancel Rule</Button>
								</span>
							</div>

						</div>
						<hr style={{ color: "#007ad9" }} />
						<div className='col-sm-12 row m-0 p-0'>

							<div className='col-sm-6'><span>
								<p className='p-0 pt-2'>
									<label className='rule_title_field mx-2 mb-0' ><b>Rule Description:</b></label>
									<InputText
										value={this.state.ruleDescription}
										style={{ width: "70%" }}
										onChange={(e) => this.setState({ ruleDescription: e.target.value })} /></p>

								<p className='p-2'>
									<label className='rule_title_field mb-0 mx-2'><b>Environment :</b></label>
									<Dropdown
										className='select_screen'
										value={this.state.selectedScreen}
										options={this.state.menuList}
										onChange={(e) => this.sendColumnDataToServer(e.value)}
										style={{ width: "70%" }}
									/></p>
								<p className='p-0'>
									<label className='rule_title_field mb-0 mx-2'><b>Roles :</b></label>
									<Dropdown
										className='select_role'
										value={this.state.selectedRole}
										options={this.state.roleOptions}
										onChange={(e) => this.setState({ selectedRole: e.value })}
										style={{ width: "70%" }}

									/></p>
							</span>
							</div>
							<div className='col-sm-6'>
								<span className='py-2'>
									<p>				<label className='rule_title_field mb-0'><b>Active :</b></label>			<InputSwitch checked={this.state.activevalue} onChange={(e) => this.setState({ activevalue: e.value })} className='mt-1' /></p>
									<p><label className='rule_title_field mb-0'><b>Rule Notes :</b></label></p>
									<InputTextarea
										className='select_screen'
										value={this.state.selectedNotes}
										style={{ width: "80%" }}
										onChange={(e) => this.setState({ selectedNotes: e.target.value })}
										rows={3}
									/></span>
							</div>



						</div>


					</div>
					{this.getKeyValueButton()}
					{
						// <Button color="primary" className='save_rule_btn mb-0' outline onClick={() => this.findValueforAllRules()}>Save Rule</Button>

					}
					<div className='row m-0 col-sm-12 p-0 pt-2 rules_conditiones'>
						<div className='row col-sm-12 m-0 p-0 rules_actions_container'>
							<span className='p-0 pt-2 col-sm-6'>
								<label className='rule_title_field mx-2 mb-0' ><b>Action:</b></label>
								<Dropdown
									value={this.state.actionData}
									options={[{ label: "T/F Prompt", value: "t/f" }, { label: "User Input Prompt", value: "Prompt User – True/False Continue" }]}
									// onChange={(e) => this.setState({ actionData: e.value })}
									onChange={(e) => this.userPrompt(e.value)}
								/></span>
							<span className='p-0 col-sm-6 pt-2'>
								<label className='rule_title_field mx-2 mb-0' ><b>Condition:</b></label>
								<Dropdown
									value={this.state.actionCondition}
									onChange={(e) => this.addCondition(e.value)}
									options={[{ label: "If (Match All)", value: "If-Match-All" }, { label: "If (Match Any)", value: "If-Match-Any" },]}
								/></span>

						</div>

					</div>
					{/* {this.state.openDeleteModal ? this.getDeleteRowModal() : null} */}
				</Container >
				{this.state.isRedirect ?
					<Redirect to={{
						pathname: "/ruleConfiguration",
					}}

					/> : null}
			</div>
		);
	}
}

export default withTranslation('common')(RuleConfiguration);












// Name: "String",
// 	Description: "String",
// 		Environment: "string",
// 			Role: "string",
// 			Trigger:""
// 				Active: boolean
// rules: [
// 	propertyName: "String",
// 	Operator: "string",
// 	Value: "String",
// 	Condition: "string",
// 	rules: [propertyName: "String",
// 		Operator: "string",
// 		Value: "String",
// 		Condition: "string",
// 		rules: []
// 	]
// ]


