import React from 'react';
import { FileUpload } from 'primereact/fileupload';

import fetchMethodRequest from '../../../config/service';

// toaster message
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { picture: [], fileName: '' };

  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  onSelect = async (event) => {
    await this.setState({ picture: event.files[0], files: event.files });
    if (this.props.type && this.props.type === 'bulkUpload') {
      return;
    } else {
      if (event) {
        this.UploadFileToServer(event.files);
      }
    }
  }

  UploadFileToServer() {
    // var file = this.state.picture;
    var reader = new FileReader();
    reader.onloadend = function () {

      /******************* for Binary ***********************/
      // var data = (reader.result).split(',')[1];
      // var binaryBlob = atob(data);

    }
    if (this.state.picture && this.props.type !== "bulkUpload") {
      let data = {};
      if (this.props.projectType === 'attach' && this.props.type !== "bulkUpload") {
        if (this.props.projectId) {
          data.contextId = { 'project': this.props.projectId };
          data.contextType = "project";
        } else if (this.props.taskId) {
          data.contextId = { 'task': this.props.taskId };
          data.contextType = "task";
        } else if (this.props.issueId) {
          data.contextId = { 'issue': this.props.issueId };
          data.contextType = "issue";
        }
        data.eventType = "attachments";
        data.attachment = this.state.picture;
      }
      fetchMethodRequest('POST', 'uploads?uploadPath=employee', this.state.picture, 'upload')
        .then(async (response) => {
          if (response && response.respCode) {
            if (response.fileName || response.fineName) {
              let fileName = response.fileName ? response.fileName : response.fineName;
              this.setState({ fileName: fileName });
              let data = JSON.parse(localStorage.getItem('loginCredentials'))
              data.photo = fileName;
              localStorage.setItem('loginCredentials', JSON.stringify(data))
              // call function in parent
              if (this.props.getFileName) {
                this.props.getFileName(fileName);
              }
              if (this.props.type === 'profile') {
                if (fileName) {
                  this.props.input.onChange(fileName);
                }
              }
              if (response.respMessage === '204') {
                showToasterMessage(response.respMessage, 'success');
              }
            }
            if (this.props.close) {
              this.props.close();
            }
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          } else {

            if (response.sucess && response.sucess.respMessage) {
              showToasterMessage(response.sucess.respMessage, 'success');
            } else if (response.failure && response.failure.errorMessage) {
              showToasterMessage(response.failure.errorMessage, 'error');
            }
            if (this.props.close) {
              this.props.close();
            }
          }
        }).catch((err) => {
          return err;
        });
    } else {
      fetchMethodRequest('POST', this.props.url, this.state.picture, 'upload')
        .then(async (response) => {
          if (response && response.respMessage) {
            // showToasterMessage(response.respMessage, 'success');
            this.props.toggleNames(this.props.activeTab)
            this.props.close();
            this.props.setResponse(response.respMessage, "", "Success")
          } else {
            // showToasterMessage(response.errorMessage, 'error');
            this.props.toggleNames(this.props.activeTab)
            this.props.close();
            this.props.setResponse(response.errorMessage, "", "error")

          }
        }).catch((err) => {
          return err;
        });
    }
  }

  UploadMultiPleFiles(event) {
    this.props.uploadProgress(true)
    fetchMethodRequest('POST', 'uploads/UploadMultiple?uploadPath=task', event, 'upload')
      .then(async (response) => {
        if (response && response.fileResponses && response.respMessage) {
          this.props.getFileName(response.fileResponses, 'success')
          // showToasterMessage(response.sucess.respMessage, 'success');
        } else if (response && response.errorMessage) {
          this.props.getFileName(response, 'failure')

          // showToasterMessage(response.failure.errorMessage, 'error');
        }
        this.props.uploadProgress(false)
      }).catch((err) => {
        return err;
      });
  }
  clear = async () => {
    if (this.props && this.props.onCancelDocuments)
      this.props.onCancelDocuments()
  }
  render() {
    return (
      <FileUpload onSelect={this.props.customUpload === false || !this.props.customUpload ? this.onSelect : ""}
        mode={this.props.customUpload === false || !this.props.customUpload ? "basic" : "advanced"}
        name={this.props.name ? this.props.name : "file"}
        // disabled={this.props.isDisableUpload}
        onClear={(event) => this.clear(event)}
        uurl="./upload.php"
        maxFileSize={10000000} //max file size 
        auto={false}
        chooseLabel={this.props.label ? this.props.label : "Browse"}
        accept={this.props.acceptType}
        multiple={this.props.multiple ? this.props.multiple : false}
        uploadHandler={this.props.customUpload && this.props.customUpload === true ? (event) => this.UploadMultiPleFiles(event) : ""}
        customUpload={this.props.customUpload ? this.props.customUpload : false}
      />
    )
  }
}
