import React from 'react';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import Loader from '../../../App/Loader';
import config from '../../../../config/config'

// config file
export default class OpenCorporateSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'OpenCorporateSearch',
    };
  }


  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }


  getOCSearchTables = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "accounts_last_made_up_date",
        "mobile": true,
        header: "accounts_last_made_up_date",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "accounts_next_due",
        "mobile": true,
        header: "accounts_next_due",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "accounts_reference_date",
        "mobile": true,
        header: "accounts_reference_date",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "annual_return_last_made_up_date",
        "mobile": true,
        header: "annual_return_last_made_up_date",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "annual_return_next_due",
        "mobile": true,
        header: "annual_return_next_due",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "branch",
        "mobile": true,
        header: "branch",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "business_number",
        "mobile": true,
        header: "business_number",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "company_number",
        "mobile": true,
        header: "company_number",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "company_type",
        "mobile": true,
        header: "company_type",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "current_alternative_legal_name",
        "mobile": true,
        header: "current_alternative_legal_name",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "current_alternative_legal_name_language",
        "mobile": true,
        header: "current_alternative_legal_name_language",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "current_status",
        "mobile": true,
        header: "current_status",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "dissolution_date",
        "mobile": true,
        header: "dissolution_date",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "has_been_liquidated",
        "mobile": true,
        header: "has_been_liquidated",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "has_charges",
        "mobile": true,
        header: "has_charges",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "has_insolvency_history",
        "mobile": true,
        header: "has_insolvency_history",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "home_jurisdiction_code",
        "mobile": true,
        header: "home_jurisdiction_code",
        filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "home_jurisdiction_company_number",
        "mobile": true,
        header: "home_jurisdiction_company_number",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "home_jurisdiction_text",
        "mobile": true,
        header: "home_jurisdiction_text",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "inactive",
        "mobile": true,
        header: "inactive",
       filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "incorporation_date",
        "mobile": true,
        header: "incorporation_date",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "industry_code_uids",
        "mobile": true,
        header: "industry_code_uids",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "jurisdiction_code",
        "mobile": true,
        header: "jurisdiction_code",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "latest_accounts_assets",
        "mobile": true,
        header: "latest_accounts_assets",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "latest_accounts_cash",
        "mobile": true,
        header: "latest_accounts_cash",
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "latest_accounts_date",
        "mobile": true,
        header: "latest_accounts_date",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "latest_accounts_liabilities",
        "mobile": true,
        header: "latest_accounts_liabilities",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "name",
        "mobile": true,
        header: "name",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "native_company_number",
        "mobile": true,
        header: "native_company_number",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "nonprofit",
        "mobile": true,
        header: "nonprofit",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "normalised_name",
        "mobile": true,
        header: "normalised_name",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "number_of_employees",
        "mobile": true,
        header: "number_of_employees",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "previous_names",
        "mobile": true,
        header: "previous_names",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "registered_address.country",
        "mobile": true,
        header: "registered_address.country",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "registered_address.in_full",
        "mobile": true,
        header: "registered_address.in_full",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "registered_address.locality",
        "mobile": true,
        header: "registered_address.locality",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "registered_address.postal_code",
        "mobile": true,
        header: "registered_address.postal_code",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "registered_address.region",
        "mobile": true,
        header: "registered_address.region",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "registered_address.street_address",
        "mobile": true,
        header: "registered_address.street_address",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "restricted_for_marketing",
        "mobile": true,
        header: "restricted_for_marketing",
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: "retrieved_at",
        "mobile": true,
        header: "retrieved_at",
       filter: true,
        sortable: true,
      },
    ]
    return data;
  }



  //  Table Column Fields 
  getCompanyTableFields = () => { 
    let data = [
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'company_number',
        mobile: true,
        header: 'Company Number',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'jurisdiction_code',
        mobile: true,
        header: 'Jurisdiction Code',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'home_jurisdiction_code',
        mobile: true,
        header: 'Home Company Number',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'home_jurisdiction_company_number',
        mobile: true,
        header: 'Home Jurisdiction Code',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'name',
        mobile: true,
        header: 'Name',
        filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'normalised_name',
        mobile: true,
        header: 'Normalized Name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'previous_names',
        mobile: true,
        header: 'Previous Name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'current_alternative_legal_name',
        mobile: true,
        header: 'Current Alt. Legal Name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'registered_address.in_full',
        mobile: true,
        header: 'Reg. Address',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'registry_url',
        mobile: true,
        header: 'Registry URL',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'company_type',
        mobile: true,
        header: 'Company Type',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'nonprofit',
        mobile: true,
        header: 'Non-Profit',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'current_status',
        mobile: true,
        header: 'Current Status',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'incorporation_date',
        mobile: true,
        header: 'Incorp. Date',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'dissolution_date',
        mobile: true,
        header: 'Dissolution Date',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'branch',
        mobile: true,
        header: 'Branch',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'business_number',
        mobile: true,
        header: 'Business Number',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'current_alternative_legal_name_language',
        mobile: true,
        header: 'Curr. Alt. Legal Name',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'home_jurisdiction_text',
        mobile: true,
        header: 'Home Juris. Code',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'native_company_number',
        mobile: true,
        header: 'Native Company Number',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'registered_address.street_address',
        mobile: true,
        header: 'Reg. Street Address',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'retrieved_at',
        mobile: true,
        header: 'Retrieved At',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'restricted_for_marketing',
        mobile: true,
        header: 'Restricted For Marketing',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'inactive',
        mobile: true,
        header: 'Inactive',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'accounts_next_due',
        mobile: true,
        header: 'Accounts Next Due',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'accounts_reference_date',
        mobile: true,
        header: 'Accounts Reference Date',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'accounts_last_made_up_date',
        mobile: true,
        header: 'Account Last Made Up Date',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'annual_return_next_due',
        mobile: true,
        header: 'Annual Return Next Due',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'annual_return_last_made_up_date',
        mobile: true,
        header: 'Annual Return Last Made Up Date',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'has_been_liquidated',
        mobile: true,
        header: 'Has Been Liquidated',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'has_insolvency_history',
        mobile: true,
        header: 'Has Insolvency History',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'has_charges',
        mobile: true,
        header: 'Has Charge',
        filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'number_of_employees',
        mobile: true,
        header: 'No. Of Employees',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'registered_address.locality',
        mobile: true,
        header: 'Reg. Add. Locality',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'registered_address.region',
        mobile: true,
        header: 'Reg. Add. Region',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'registered_address.postal_code',
        mobile: true,
        header: 'Reg. Add. Postal',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'registered_address.country',
        mobile: true,
        header: 'Reg. Add. Country',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'industry_code_uids',
        mobile: true,
        header: 'Industry Code Uids',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'latest_accounts_date',
        mobile: true,
        header: 'Latest Accounts Date',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'latest_accounts_cash',
        mobile: true,
        header: 'Latest Accounts Cash',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'latest_accounts_assets',
        mobile: true,
        header: 'Latest Accounts Assets',
       filter: false,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'latest_accounts_liabilities',
        mobile: true,
        header: 'Latest Accounts Liabilities',
       filter: false,
        sortable: false
      }
    ]
    return data;
  };

  getCompanyAddresSearchFields = () => {
    let data = [
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'source',
        mobile: true,
        header: 'Source',
       filter: true,
        sortable: true
      }, {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'registered_address.in_full',
        mobile: true,
        header: 'Registered Address',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'company_number',
        mobile: true,
        header: 'Company Number',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'jurisdiction_code',
        mobile: true,
        header: 'Jurisdiction Code',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'name',
        mobile: true,
        header: 'name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'normalised_name',
        mobile: true,
        header: 'normalised_name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'previous_names',
        mobile: true,
        header: 'previous_names',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'current_alternative_legal_name',
        mobile: true,
        header: 'current_alternative_legal_name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'address_type',
        mobile: true,
        header: 'Non Reg Address Type',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'street_address',
        mobile: true,
        header: 'Non Reg Street Address',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'locality',
        mobile: true,
        header: 'Non Reg Locality',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'region',
        mobile: true,
        header: 'Non Reg Region',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'postal_code',
        mobile: true,
        header: 'Non Reg Postal Code',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'country',
        mobile: true,
        header: 'Non Reg Country',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'country_code',
        mobile: true,
        header: 'Non Reg Country Code',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'start_date',
        mobile: true,
        header: 'Non Reg Start Date',
       filter: true,
        sortable: false
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'end_date',
        mobile: true,
        header: 'Non Reg End Date',
       filter: true,
        sortable: false
      }
    ]
    return data;
  }
  getTaxFields = () => {
    let data = [
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'name',
        mobile: true,
        header: 'name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'normalised_name',
        mobile: true,
        header: 'normalised_name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'previous_names',
        mobile: true,
        header: 'previous_names',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'current_alternative_legal_name',
        mobile: true,
        header: 'current_alternative_legal_name',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'alternative_names',
        mobile: true,
        header: 'Alternative Names',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'company_number',
        mobile: true,
        header: 'Company Number',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'jurisdiction_code',
        mobile: true,
        header: 'Jurisdiction Code',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'uid',
        mobile: true,
        header: 'Tax ID',
       filter: true,
        sortable: true
      },
      {
        show: true,
        textAlign: 'left',
        width: 110,
        field: 'identifier_system_code',
        mobile: true,
        header: 'Tax ID Type',
       filter: true,
        sortable: true
      }
    ]
    return data;
  }
  //  Table Column Fields
  getFEINTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-id',
        "mobile": true,
        header: 'Company-id',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-name',
        "mobile": true,
        header: 'Company-name',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company Former Name',
        "mobile": true,
        header: 'Company Former Name',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-jurisdiction_code',
        "mobile": true,
        header: 'Company-jurisdiction_code',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Identifier Type',
        "mobile": true,
        header: 'Identifier Type',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Identifier Value',
        "mobile": true,
        header: 'Identifier Value',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-company_number',
        "mobile": true,
        header: 'Company-company_number',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-current_status',
        "mobile": true,
        header: 'Company-current_status',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-incorporation_date_orig',
        "mobile": true,
        header: 'Company-incorporation_date_orig',
       filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-dissolution_date_orig',
        "mobile": true,
        header: 'Company-dissolution_date_orig',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-branch',
        "mobile": true,
        header: 'Company-branch',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-home_jurisdiction_text',
        "mobile": true,
        header: 'Company-home_jurisdiction_text',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-home_jurisdiction_company_number',
        "mobile": true,
        header: 'Company-home_jurisdiction_company_number',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-current_alternative_legal_name',
        "mobile": true,
        header: 'Company-current_alternative_legal_name',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-previous_names',
        "mobile": true,
        header: 'Company-previous_names',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-id',
        "mobile": true,
        header: 'Alternative Name-id',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-name',
        "mobile": true,
        header: 'Alternative Name-name',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-jurisdiction_code',
        "mobile": true,
        header: 'Alternative Name-jurisdiction_code',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-company_number',
        "mobile": true,
        header: 'Alternative Name-company_number',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-type',
        "mobile": true,
        header: 'Alternative Name-type',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-start_date_orig',
        "mobile": true,
        header: 'Alternative Name-start_date_orig',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-end_date_orig',
        "mobile": true,
        header: 'Alternative Name-end_date_orig',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-registered_address.in_full',
        "mobile": true,
        header: 'Company-registered_address.in_full',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Non-Registered Addresses-in_full',
        "mobile": true,
        header: 'Non-Registered Addresses-in_full',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-has_been_liquidated',
        "mobile": true,
        header: 'Company-has_been_liquidated',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-has_insolvency_history',
        "mobile": true,
        header: 'Company-has_insolvency_history',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-registry_url',
        "mobile": true,
        header: 'Company-registry_url',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-retrieved_at_orig',
        "mobile": true,
        header: 'Company-retrieved_at_orig',
       filter: true,
        sortable: true,
      },
    ]
    return data;
  };

  //  Table Column Fields
  getAddressTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-id',
        "mobile": true,
        header: 'Company-id',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-name',
        "mobile": true,
        header: 'Company-name',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company Former Name',
        "mobile": true,
        header: 'Company Former Name',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-jurisdiction_code',
        "mobile": true,
        header: 'Company-jurisdiction_code',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-registered_address.in_full',
        "mobile": true,
        header: 'Company-registered_address.in_full',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Non-Registered Addresses-in_full',
        "mobile": true,
        header: 'Non-Registered Addresses-in_full',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-company_number',
        "mobile": true,
        header: 'Company-company_number',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-current_status',
        "mobile": true,
        header: 'Company-current_status',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-incorporation_date_orig',
        "mobile": true,
        header: 'Company-incorporation_date_orig',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-dissolution_date_orig',
        "mobile": true,
        header: 'Company-dissolution_date_orig',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-branch',
        "mobile": true,
        header: 'Company-branch',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-home_jurisdiction_text',
        "mobile": true,
        header: 'Company-home_jurisdiction_text',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-home_jurisdiction_company_number',
        "mobile": true,
        header: 'Company-home_jurisdiction_company_number',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-current_alternative_legal_name',
        "mobile": true,
        header: 'Company-current_alternative_legal_name',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-previous_names',
        "mobile": true,
        header: 'Company-previous_names',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-id',
        "mobile": true,
        header: 'Alternative Name-id',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-name',
        "mobile": true,
        header: 'Alternative Name-name',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-jurisdiction_code',
        "mobile": true,
        header: 'Alternative Name-jurisdiction_code',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-company_number',
        "mobile": true,
        header: 'Alternative Name-company_number',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-type',
        "mobile": true,
        header: 'Alternative Name-type',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-start_date_orig',
        "mobile": true,
        header: 'Alternative Name-start_date_orig',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-end_date_orig',
        "mobile": true,
        header: 'Alternative Name-end_date_orig',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-has_been_liquidated',
        "mobile": true,
        header: 'Company-has_been_liquidated',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-has_insolvency_history',
        "mobile": true,
        header: 'Company-has_insolvency_history',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-registry_url',
        "mobile": true,
        header: 'Company-registry_url',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-retrieved_at_orig',
        "mobile": true,
        header: 'Company-retrieved_at_orig',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Identifier Type',
        "mobile": true,
        header: 'Identifier Type',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Identifier Value',
        "mobile": true,
        header: 'Identifier Value',
       filter: true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Officer Name',
        "mobile": true,
        header: 'Officer Name',
       filter: true,
        sortable: true,
      },
    ]
    return data;
  };

  // Form Input Fields
  getFormFields = () => {
    return ([]);
  }
  getTableFields = () => {
    if (this.datatableref) {

      let selectedFields = this.datatableref.getSearchedCompany()
     // console.log("selectedFields", selectedFields)

      if (selectedFields && selectedFields.company) {
        return this.getCompanyTableFields()
      } else if (selectedFields && selectedFields.feinLeiEin) {
        // return this.getFEINTableFields()
        return this.getTaxFields()
      } else if (selectedFields && selectedFields.address) {
        // return this.getAddressTableFields()
        return this.getCompanyAddresSearchFields()
      } else {
        return ([]);
      }

    } else {
      return ([]);
    }

  }


  // submit = async (item) => {


  // }

  render() {

    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          dontShowTitle={true}
          printRequried={false}
          Limit={250}
          sortField={"Company-id"}
          addRequried={false}
          editRequired={false}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={false}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          type='OpenCorporateSearch'
          displayright={false}
          icon='user'
          routeTo='openCorporateSearch'
          displayViewOfForm='modal'
          apiResponseKey="openCorporateSearch"
          apiUrl={apiCalls.openCorporateSearch}
          entityType='employee'
        />
      </span>
    );
  }
}