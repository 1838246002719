/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Profile from './components/Profile';
import fetch from '../../../config/service';
// import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import apiCalls from '../../../config/apiCalls';

class ProfilePage extends PureComponent {
  // submit form data
  constructor() {
    super();
    this.state = {
      isUserUpdated: false,

    };
  }

  handleSubmit = (formValues) => {
    if (formValues) {
      formValues.pageLimit = parseInt(formValues.pageLimit)
      // delete formValues.email;
      // delete formValues.password;
      localStorage.setItem("defaultPageOption", formValues.pageLimit)
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls.Users}/${user._id}`;
      }
      return fetch('PUT', Url, formValues)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 200) {
            // showToasterMessage(response.respMessage, 'success');
            this.profileRef.getUserData();
            this.setState({
              isUserUpdated: true
            })
          } else if (response && response.errorMessage) {
            this.setState({
              isUserUpdated: true
            })
            // showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          return err;
        });

    } else {
      return;
    }
  };

  render() {
    return (
      <>
        <Container>
          <Row style={{ margin: 15, marginTop: 40 }}>
            <Profile
              onRef={(ref) => { this.profileRef = ref }}
              onSubmit={this.handleSubmit}
              locationProps={this.props}
            />
          </Row>
        </Container>
        {
          this.state.isUserUpdated ?
            <Redirect to='/taskView' /> : null
        }
      </>
    );
  }
}

export default ProfilePage;
