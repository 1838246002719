import React from 'react';
import { Row } from 'reactstrap';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';

import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Weekly Recovery Plan',
      loading: false,
      sessionWarning: false,
      readOnly: false,
      roleOptions: [],
      business: [],
      FilterSortOptions: [
        // { label: 'Type of UP Recovery', value: "dealType" },
        { label: 'Business Name', value: "company" },
        { label: 'Transition Target', value: "transitionTarget" },
        { label: 'Site Discovery Target', value: "searchTarget" },
        { label: 'Validation Target', value: "validationTarget" },
        { label: 'File Target', value: "fileTarget" }
        // { label: 'Property City', value: "propertyCity" },
        // { label: 'Property State', value: "propertyState" },
      ]
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getReportColumns() {
    let headerArr = ["Transition in(%)", "Site Discovery in (%)", "Validation in (%)", "File in (%)", "Plan", "Actual", "Plan", "Actual", "Plan", "Actual", "Plan", "Actual", "Actual", "Comments"]
    return <ColumnGroup>
      <Row>
        <Column header="Type of Up Recovery" rowSpan={2} />
        <Column header="Round" rowSpan={2} />
        <Column header="Business  (Round from Card)" rowSpan={2} />
        <Column header="Analyst" rowSpan={2} />
        <Column header="Weekly Target For" colSpan={4} />
        <Column header="Transition Hours" colSpan={2} />
        <Column header="Site Discovery Hours" colSpan={2} />
        <Column header="Validation Hours" colSpan={2} />
        <Column header="File Hours" colSpan={2} />
        <Column header=" Side Project" colSpan={1} />
        <Column header="" colSpan={1} />
      </Row>
      <Row>
        {headerArr && headerArr.length > 0 ? headerArr.map((item, index) => {
          return <Column header={item} />
        }) : null}
      </Row>
    </ColumnGroup>
  }


  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions[this.state.type] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "isBold": "true",
        "fieldType": 'label',
        "field": "dealType",
        options: [
          { label: 'BK - New', value: 'BK - New' },
          { label: 'BK - Refresh', value: 'BK - Refresh' },
          { label: 'CR - New', value: 'CR - New' },
          { label: 'CR - Refresh', value: 'CR - Refresh' },
        ],
        "editField": "dropDown",
        "mobile": false,
        //"header": "Type of UP Recovery",
        "filter": false,
        // "fieldType": 'profile',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 75,
        "isBold": "true",
        "fieldType": 'label',
        "field": "crRound",
        options: [
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" }],
        "editField": "dropDown",
        "mobile": false,
        //"header": "Type of UP Recovery",
        "filter": false,
        // "fieldType": 'profile',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": 'left',
        "width": 180,
        "isBold": "true",
        "field": 'company',
        "mobile": true,
        "editField": "autoComplete",
        "fieldType": 'label',
        "tableField": 'name',
        //"header": ' Business Name',
        // "options": this.state.roleOptions,
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "field": "analysts",
        "fieldType": "Array",
        "mobile": true,
        // "options": this.state.roleOptions,
        //"header": "Analyst",
        "editField": "dropDown",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "transitionTarget",
        "mobile": true,
        "fieldType": 'number',
        "keyfilter": "money",
        //"header": "Weekly Target for Transition Target in (%)",
        "editField": "text",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "mobile": true,
        "field": "searchTarget",
        "fieldType": 'number',
        // "header": "Weekly Target for Site Discovery  in (%)",
        "keyfilter": "money",
        "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "validationTarget",
        "mobile": true,
        "fieldType": 'number',
        "keyfilter": "money",
        //"header": "Weekly Target for Validation  in (%)",
        "editField": "text",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "mobile": true,
      //   "field": "reviewTarget",
      //   "fieldType": 'number',
      //   //"header": "Weekly Target for Review  in (%)",
      //   "editField": "text",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": false,
      //   "sortable": true,
      // },

      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "mobile": true,
        "field": "fileTarget",
        "fieldType": 'number',
        "keyfilter": "money",
        //"header": "Weekly Target for File in (%)",
        "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "mobile": true,
        "field": "forecastTransitionHours",
        "fieldType": 'currency',
        //"header": "Plan Transition Hours",
        "editField": "text",
        "keyfilter": "money",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "mobile": true,
        "field": "transitionHours",
        "fieldType": 'currency',
        //"header": "Actual Transition Hours",
        // "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "mobile": true,
        "field": "forecastSearchHours",
        "fieldType": 'currency',
        "keyfilter": "money",
        //"header": "Plan Site Discovery Hours",
        "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "fieldType": 'currency',
        "field": "searchHours",
        // "editField": "text",
        "keyfilter": "money",
        //"header": "Actual Site Discovery Hours",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }, {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "mobile": true,
        "field": "forecastValidationHours",
        "fieldType": 'currency',
        "keyfilter": "money",
        //"header": "Plan Validation Hours",
        "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "fieldType": 'currency',
        "field": "validationHours",
        // "editField": "text",
        "keyfilter": "money",
        //"header": "Actual Validation Hours",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "fieldType": 'currency',
      //   "field": "reviewHours",
      //   //"header": "Review Hours",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "sortable": true
      // }, 
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "field": "forecastFileHours",
        "mobile": true,
        "fieldType": 'currency',
        "keyfilter": "money",
        //"header": "Plan File Hours",
        "editField": "text",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "fieldType": 'currency',
        "field": "fileHours",
        //"header": "Actual File Hours",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "mobile": true,
      //   "field": "forecastReviewHours",
      //   "fieldType": 'number',
      //   "keyfilter": "money",
      //   //"header": "forecastReviewHours",
      //   "editField": "text",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": false,
      //   "sortable": true,
      // }, 
      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "fieldType": 'currency',
        "field": "recoverySideProjectHours",
        "header": "Side Project Hours",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "field": "comments",
        // "fieldType": 'number',
        //"header": "comments",
        "editField": "text",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
    ]
    return data;
  };
  getTableFieldsbasedonRole = () => {
    let data = this.getTableFields();
    if (!this.state.readOnly) {
    } else {
      // data.push(
      //   {
      //     "show": true,
      //     "textAlign": "left",
      //     "width": 80,
      //     "fieldType": "Actions",
      //     "field": "Actions",
      //     ////"header": "Delete",
      //     "filter": false,
      //     // "displayInSettings": true,
      //     "sortable": false
      //   },

      // )
    }
    return data;
  }

  getFormFields = () => {
    return (
      [
      ]
    );
  }


  getReportsFooter = (responseData) => {
    var totalForcastTransitionHours = Object.values(responseData).reduce((t, { forecastTransitionHours }) => t + forecastTransitionHours, 0)
    var totalActualTransitionHours = Object.values(responseData).reduce((t, { transitionHours }) => t + transitionHours, 0)
    var totalForcastSiteDiscovery = Object.values(responseData).reduce((t, { forecastSearchHours }) => t + forecastSearchHours, 0)
    var totalActualSiteDiscovery = Object.values(responseData).reduce((t, { searchHours }) => t + searchHours, 0)
    var totalForcastValidation = Object.values(responseData).reduce((t, { forecastValidationHours }) => t + forecastValidationHours, 0)
    var totalActualValidation = Object.values(responseData).reduce((t, { validationHours }) => t + validationHours, 0)
    var totalForcastFile = Object.values(responseData).reduce((t, { forecastFileHours }) => t + forecastFileHours, 0)
    var totalActualFile = Object.values(responseData).reduce((t, { fileHours }) => t + fileHours, 0)
    var totalSideProjectActual = Object.values(responseData).reduce((t, { recoverySideProjectHours }) => t + recoverySideProjectHours, 0)
    var ActualReportsHours = totalActualTransitionHours + totalActualValidation + totalActualFile;
    var ForecastReportsHours = totalForcastTransitionHours + totalForcastValidation + totalForcastFile;
    return <ColumnGroup>
      {/* {allUsersData && allUsersData.length > 0 ? */}
      {/* <div> */}
      <Row>
        <Column footer="Total:" colSpan={8} />
        <Column footer={totalForcastTransitionHours ? totalForcastTransitionHours.toFixed(1) : null} />
        <Column footer={totalActualTransitionHours ? totalActualTransitionHours.toFixed(1) : null} />
        <Column footer={totalForcastSiteDiscovery ? totalForcastSiteDiscovery.toFixed(1) : null} />
        < Column footer={totalActualSiteDiscovery ? totalActualSiteDiscovery.toFixed(1) : null} />
        <Column footer={totalForcastValidation ? totalForcastValidation.toFixed(1) : null} />
        < Column footer={totalActualValidation ? totalActualValidation.toFixed(1) : null} />
        <Column footer={totalForcastFile ? totalForcastFile.toFixed(1) : null} />
        <Column footer={totalActualFile ? totalActualFile.toFixed(1) : null} />
        <Column footer={totalSideProjectActual ? totalSideProjectActual.toFixed(1) : null} />
        <Column footer="" />
      </Row>
      <Row>
        <Column footer="Averages(%):" colSpan={8} />
        <Column colSpan={2} footer={totalForcastTransitionHours ? ((totalActualTransitionHours / totalForcastTransitionHours) * 100).toFixed(1) : null} />
        <Column colSpan={2} footer={totalForcastSiteDiscovery ? ((totalActualSiteDiscovery / totalForcastSiteDiscovery) * 100).toFixed(1) : null} />
        <Column colSpan={2} footer={totalForcastValidation ? ((totalActualValidation / totalForcastValidation) * 100).toFixed(1) : null} />
        <Column colSpan={2} footer={totalForcastFile ? ((totalActualFile / totalForcastFile) * 100).toFixed(1) : null} />
        <Column footer={totalSideProjectActual ? totalSideProjectActual.toFixed(1) : null} />
        <Column footer="" />
      </Row>

      <Row>
        <Column footer="Total Actual Hours:" colSpan={8} />
        <Column colSpan={10} footer={ActualReportsHours ? ActualReportsHours.toFixed(1) : null} />
      </Row>
      <Row>
        <Column footer="Total Planned Hours:" colSpan={8} />
        <Column colSpan={10} footer={ForecastReportsHours ? ForecastReportsHours.toFixed(1) : null} />
      </Row>
      <Row>
        <Column footer="Average Utilization(%):" colSpan={8} />
        <Column colSpan={10} footer={((ActualReportsHours / ForecastReportsHours) * 100).toFixed(1)} />
      </Row>
      {/* </div> */}
      {/* : null} */}
    </ColumnGroup>;
  }

  getFooter = (response) => {
    var data;
    data = this.getReportsFooter(response)
    if (data) {
      return data;
    } else {
      return ""
    }
  }

  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFieldsbasedonRole}
          getReportColumns={this.getReportColumns()}
          formFields={this.getFormFields}
          className={true}
          exportRequried={false}
          sortedField={"dealType"}
          groupField={"dealType"}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          tableFooter={this.getFooter}
          // frozen={false}
          // headerGroup={headerGroup}
          // analysts={this.state.roleOptions}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Weekly Recovery Report'
          scrollHeight={"55vh"}
          displayright={true}
          icon='calendar-full'
          sortField={"dealType"}
          routeTo='planningGrid'
          displayViewOfForm='modal'
          apiResponseKey='viewResults'
          FilterSortOptions={this.state.FilterSortOptions}
          apiUrl={apiCalls.PlanningGrid}
          entityType='employee'
          sessionWarning={this.state.sessionWarning}
        />

      </span>
    );
  }
}