import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'reactstrap';
export default class DocumentSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selectAll: true,
            isFilesFound: {}
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.setDataToShow();
    }

    componentDidUpdate(prevProps, prevState) {
        //Prepare files for api call to get documents
        if ((prevProps.getMultipleFiles != this.props.getMultipleFiles) && this.props.getMultipleFiles) {
            this.getFiles();
        }
        //For triggering file changes when user select different document type in claim packet
        if (JSON.stringify(prevProps.documentList) != JSON.stringify(this.props.documentList)) {
            this.setDataToShow();
        }
    }

    /**
     * Setting data to show 
     */
    setDataToShow = async () => {
        if (this.props.documentList && this.props.documentList.length > 0) {
            const items = this.props.documentList.map((item, index) => { return { ...item, show: true, dragID: `item-${item.id}` } });
            let isFound = {}
            this.props.selDocTypes.forEach((element) => {
                isFound[element] = this.props.documentList.some(doc => element === doc.documentTypeName)
            });
            await this.setState({ items: JSON.parse(JSON.stringify(items)), isFilesFound: isFound });
        }
    }

    /**
     * Prepare files for api call to get documents 
     */
    getFiles = () => {
        const files = (this.state.items.filter(item => item.show)).map((item) => {
            let obj = JSON.parse(JSON.stringify(item));
            delete obj.show;
            delete obj.dragID;
            return obj;
        });        
        this.props.getDocumentsData(files);
    }

    /**
     * Dynamic seting  number of files added for Chain of title
     * @param {number} count 
     */
    setCOTCount = (count, AddedCOT) => {
        this.setState({ countOfCOT: count, AddedCOT: AddedCOT })
    }

    /**
     * Reorder items darag and drop
     * @param {Array} list 
     * @param {number} startIndex 
     * @param {number} endIndex 
     * @returns 
     */
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * Drag and drop
     * @param {Object} result 
     * 
     */
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    }

    /**
     * on select or unselect document
     * @param {Array} items 
     * @param {number} index 
     * @param {boolean} isChecked 
     */
    onChangeCheckBox = (items, index, isChecked) => {
        items[index].show = isChecked;
        const files = (this.state.items.filter(item => item.show));
        this.props.checkGotAllMandatoryFiles(this.props.selectedDocType, files);
        this.setState({
            items: items
        });
        if (isChecked === false && this.state.selectAll) {
            this.setState({ selectAll: false });
        } else {
            let allSelected = true;
            for (let i = 0; i < items.length; i++) {
                if (items[i].show === false) {
                    allSelected = false;
                }
            }
            this.setState({ selectAll: allSelected });
        }
    }
    /**
     * on check or uncheck select all functionality
     * @param {Array} items 
     * @param {boolean} isChecked 
     */
    onSelectAll = (items, isChecked) => {
        for (let i = 0; i < items.length; i++) {
            items[i].show = isChecked;
        }
        this.setState({
            items: items, selectAll: isChecked
        });
    }

    render() {
        
        return (

            <DragDropContext onDragEnd={this.onDragEnd}>
                <div className='d-flex p-2'>
                    <Checkbox onChange={(e) => { this.onSelectAll(this.state.items, e.checked) }} checked={this.state.selectAll}></Checkbox>
                </div>
                <Droppable droppableId="droppable" >
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ textAlign: 'left' }}
                        >
                            {/* <div>{this.props.selectedDocType}</div> */}

                            {this.props.selDocTypes.length === 1 &&
                                <>

                                    {
                                        this.state.items.map((item, index) => (
                                            <>

                                                <Draggable key={item.id} draggableId={item.dragID} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className='document-name'
                                                        >
                                                            <Checkbox onChange={(e) => { this.onChangeCheckBox(this.state.items, index, e.checked) }} checked={this.state.items[index].show}></Checkbox>
                                                            <div className='name'>Load <span className='claim-file-color'>{item.fileName}</span></div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            </>
                                        ))
                                    }
                                </>
                            }
                            {
                                this.props.selDocTypes.length > 1 &&
                                <table class="table table-bordered">
                                    <tbody>
                                        {
                                            this.props.selDocTypes.map((elem, k) => (

                                                <tr>
                                                    <th scope="row">{k + 1}</th>

                                                    <td> {elem} {this.props.mandatoryDocs[elem] ? <b>(M)</b> : null}{this.props.SubCatDesc && this.props.SubCatDesc.length > 0 ? <p style={{ color: "lightCoral" }}>{this.props.SubCatDesc[k]}</p> : null}</td>
                                                    <td>
                                                        {elem === 'MAPPED NAME CHAIN OF TITLE' ? <>
                                                            <Button color='primary' className='mb-0' outline onClick={() => this.props.getChainOfTitle()} >{`Chain Of Title (${this.state.countOfCOT && this.state.AddedCOT ? this.state.countOfCOT : 0})`}</Button>
                                                        </> :
                                                            <>
                                                                {this.state.isFilesFound[elem] ?
                                                                    <>
                                                                        {this.state.items.map((item, index) => (
                                                                            <>
                                                                                {item.documentTypeName === elem ?
                                                                                    <Draggable key={item.id} draggableId={item.dragID} index={index}>
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                className='document-name'
                                                                                            >
                                                                                                <Checkbox onChange={(e) => { this.onChangeCheckBox(this.state.items, index, e.checked) }} checked={this.state.items[index].show}></Checkbox>
                                                                                                <div className='name'><span className='claim-file-color'>{item.fileName}</span></div>
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable> : null}
                                                                            </>
                                                                        ))}

                                                                    </>
                                                                    :
                                                                    <span className={`ml-2 ${this.props.mandatoryDocs[elem] ? 'text-danger' : 'text-warning'}`}>No files found.</span>
                                                                }
                                                            </>}

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            }

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        )
    }
}