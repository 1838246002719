import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';
import * as documentsAPIs from '../../../../shared/documentsApis';
import StateAttributeForm from './StateAttributesForm';
import { Dialog } from 'primereact/dialog';
import { Button } from 'reactstrap';
// config file
export default class StateUpFiling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'State Up Filing',
      loading: false,
      sessionWarning: false,
      showSideBar: false,
      documentSources: [],
      documentTypes: [],
      relevences: [],
      categories:[],
      sourceTypes:[],
      docTags:[],
      docScopes:[],
      subCategories:[],
      showDelConfirm:false,
      errorMessage:null
    };

  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }





  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    this.getDataForDropdowns();

  }

  getDataForDropdowns = async () => {    
    const sources = await documentsAPIs.getSources();
    const docTypes = await documentsAPIs.getDocTypes();
    const relevanceTypes = await documentsAPIs.getRelevenceTypes();
    const categories = await this.getCategories();
    const sourceTypes = await this.getDocSourceTypes();
    const tags = await this.getDocTags();
    const subCategories  =  await this.getSubCategories();
    const docScope = await this.getDocScopes();
    this.setState({       
        documentSources: sources.map((e) => {return {label:e.documentSourceName,value:e._id}}),
        documentTypes: docTypes.map((e) => {return {label:e.name,value:e._id}}),
        relevences: relevanceTypes.map((e) => {return {label:e.name,value:e._id}}),
        categories:categories.map((e) => {return {label:e.categoryName,value:e.categoryName}}),
        sourceTypes:sourceTypes.map((e) => {return {label:e.sourceType,value:e.sourceType}}),
        docTags:tags.map((e) => {return {label:e.tag,value:e.tag}}),
        docScopes:docScope.map((e) => {return {label:e.name,value:e.name}}),
        subCategories:subCategories
    });

  }

  getCategories = async () => {
    const res = await fetchMethodRequest('GET', `${apiCalls.Category}?type=exportToCsv`);
    if (res && res.categories) {
      return Promise.resolve(res.categories);
    } else {
      return Promise.resolve([]);
    }
  }
  getSubCategories = async () => {
    const res = await fetchMethodRequest('GET', `${apiCalls.SubCategory}?type=exportToCsv&filter=${JSON.stringify({"criteria":[{"key":"activeIndicator","value":true,"type":"eq","addFilter":true}]})}`);
    if (res && res.subCategories) {
      return Promise.resolve(res.subCategories);
    } else {
      return Promise.resolve([]);
    }
  }
  getDocSourceTypes = async () => {
    const res = await fetchMethodRequest('GET', `${'documentSourceType'}`);
    if (res && res.documentSourceTypes) {
      return Promise.resolve(res.documentSourceTypes);
    } else {
      return Promise.resolve([]);
    }
  }

  getDocTags = async () => {
    const res = await fetchMethodRequest('GET', `${'documentTag'}?type=exportToCsv`);
    if (res && res.documentTags) {
      return Promise.resolve(res.documentTags);
    } else {
      return Promise.resolve([]);
    }
  }
  getDocScopes = async () => {
    const res = await fetchMethodRequest('GET', `${'documentScope'}?type=exportToCsv`);
    if (res && res.documentScopes) {
      return Promise.resolve(res.documentScopes);
    } else {
      return Promise.resolve([]);
    } 
  }

  


  getTableFields = () => {
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "",
      //   // fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: false,
      // },
      // {
      //   "show": true,
      //   "mobile": true,
      //   "textAlign": 'left',
      //   "field": "expander",
      //   "width": 60,
      //   "fieldType": 'expander',
      //   "header": "expander"
      // },

      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "Category",
        "mobile": true,
        fieldType: "BoolText",
        "header": "Category",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SubCategory",
        "mobile": true,
        "header": "Sub Category",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "RelevanceTypeName",
        "mobile": true,
        "header": "Document Relevance",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "DocumentTypeName",
        "mobile": true,
        "header": "Document Type",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "DocumentDescription",
        "mobile": true,
        "header": "Document Desc.",
        "displayInSettings": true,
        "sortable": true,
      },
      
         
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "dealAttributeAssociation",
      //   "mobile": true,
      //   "header": "Deal Attribute Association",
      //   "displayInSettings": true,
      //   filter: false,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "claimPacketDocsGroup",
      //   "mobile": true,
      //   "header": "Claim Packet Docs Group ",
      //   "displayInSettings": true,
      //   filter: false,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "claimPacketDocsSubGroup",
      //   "mobile": true,
      //   "header": "Claim Packet Docs SubGroup ",
      //   "displayInSettings": true,
      //   filter: false,
      //   "sortable": true,
      // },
      
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "AttributeValue",
        "mobile": true,
        "editField": "text",
        // filterType: "num",
        "header": "Value",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "IsMandatory",
        "mobile": true,      
        "filterType": "num",
        "fieldType": "Boolean",
        "header": "Is Mandatory",
        "displayInSettings": true,
        filter: false,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "header": "Currently Active",
      //   "mobile": true,
      //   "field": "activeIndicator",
      //   fieldType: "Boolean",
      //   "displayInSettings": true,
      //   // "fieldType": "ActiveIndicator",
      //   filter: true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "FilingRequirementNote",
        "mobile": true,
        // "fieldType": "Array",
        "openNotes": true,
        // filterType: "num",
        "header": "Note",
        "displayInSettings": true,
        filter: false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "ClaimBuilderChevronSequence",
        "mobile": true,      
        "filterType": "num",
        "header": "Claim Builder Chevron Group Sequencee",
        "displayInSettings": true,
        filter: false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "DocSequenceWithinChevron",
        "mobile": true,      
        "filterType": "num",
        "header": "Claim Builder Chevron Group Sub-Sequence",
        "displayInSettings": true,
        filter: false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "ValueSequence",
        "mobile": true,      
        "filterType": "num",
        "header": "Claim Packet Document Sequence",
        "displayInSettings": true,
        filter: false,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "claimBuilderChevronSequence",
      //   "mobile": true,      
      //   "filterType": "num",
      //   "header": "Claim Builder Chevron Group Sequence",
      //   "displayInSettings": true,
      //   filter: false,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "DocSequenceWithinChevron",
      //   "mobile": true,      
      //   "filterType": "num",
      //   "header": "Claim Builder Chevron Group Sub-Sequence",
      //   "displayInSettings": true,
      //   filter: false,
      //   "sortable": true,
      // },          
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "header": "Effective From Date",
        "mobile": true,
        "field": "EffectiveFromDate",
        "displayInSettings": true,
        "fieldType": "dateTime",
        filterType: "num",
        "dateFormat": 'MM-DD-yyyy',
        filter: true,
        "sortable": true,
      },

      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "header": "created By",
      //   "mobile": true,
      //   "field": "createdBy",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Last Updated By",
        "mobile": true,
        "field": "UpdatedBy",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "Updated",
        filterType: "num",
        "mobile": true,
        "fieldType": "dateTime",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Last Updated Date",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        field: "Actions",
        // fieldType === "Actions"
        "fieldType": "Actions",
        "header": "Actions",
        "filter": false,
        "sortable": false,
        "showDelete":true
      }



    ]
    return data;
  };


  getFormFields = (rowData) => { 
    let fields = [
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "category",
        "label": "Category",
        "id": "EstateName",
        "placeholder": "Seller Name",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "subCategory",
        "label": "Sub Category",
        "id": "SourceState",
        "placeholder": "Source State",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "attributeValue",
        "label": "Values",
        "id": "BusinessName",
        "placeholder": "attribute Value",
        "required": true,
      },

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "notes",
        "label": "Note",
        "id": "note",
        "placeholder": "Note",
        // "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "date",
        "name": "effectiveFromDate",
        "label": "Effective From Dt",
        "id": "note",
        "placeholder": "Effective From Dt",
        // "required": true
      },
      
      
       {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Currently Active",
        "id": "checkForm",
        "placeholder": "Check From",
        // "required": true
      },

    ];
    
    if(rowData && rowData.category === 'Claim Packet'){
      fields.splice(-1,0,
        {
         
          "value": "",
          "type": "text",
          "name": "claimPacketDocsGroup",
          "label": "Claim Packet Docs Group",
          "id": "claimPacketDocsGroup",
          "placeholder": "Claim Packet Docs Group",
          "required": false,
          
        },
        {          
          "value": "",
          "type": "text",
          "name": "claimPacketDocsSubGroup",
          "label": "Claim Packet Docs Sub Group",
          "id": "claimPacketDocsSubGroup",
          "placeholder": "Claim Packet Docs Sub Group",
          "required": false,
          
        },
        {
        //'show': false,
        "value": "",
        "type": "number",
        "name": "valueSequence",
        "label": "Doc Sequence In Claim Packet",
        "id": "valueSequence",
        "placeholder": "value Sequence",
        "required": false,
      },
      {        
        "value": "",
        "type": "number",
        "name": "claimBuilderChevronSequence",
        "label": "Claim Builder Chevron Sequence",
        "id": "ClaimBuilderChevronSequence",
        "placeholder": "value Sequence",
        "required": false,
      },
      {        
        "value": "",
        "type": "number",
        "name": "docSequenceWithinChevron",
        "label": "Doc Sequence Within Chevron",
        "id": "DocSequenceWithinChevron",
        "placeholder": "value Sequence",
        "required": false,
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "isMandatory",
        "label": "Mandatory",
        "id": "mandatory",
        "placeholder": "mandatory",
        // "required": true
      },)
    }
    return fields;
  }

  getClaimStatusFormFields = () => {
    return ([{
      "value": "",
      "type": "text",
      "name": "ClaimStatus",
      "label": "Claim Status",
      "id": "ClaimStatus",
      "placeholder": "Claim Status",
      "required": false,
      // "disable": true
    },])
  }

  submit = async (item) => {

  }
  toggleSideBar = (value, type, filterCriteria, rowData) => {
    this.filterCriteria = filterCriteria;
    this.setState({ errorMessage: null, type: type, rowData: rowData, showSideBar: value, showDelConfirm: type === 'Delete' ? true : false });
  }

  deleteStateAttribute = async() => {
    let api = `${apiCalls.StateUpFiling}`;
    if(this.state.rowData && this.state.rowData._id){
      api = `${api}/${this.state.rowData._id}`;
    }
    const res = await fetchMethodRequest('DELETE', api);
    if(res){
      this.datatableref.getStateFIlingValues(this.filterCriteria);
      this.setState({ showDelConfirm: false })
    }
  }
  
  saveStateAttributesToServer = async (body) => {
    this.setState({ errorMessage: null});
    let api = `${apiCalls.StateUpFiling}`;
    let type = 'POST';
    if(this.state.type.toLowerCase() === 'add'){
      api = `${api}?type=multi`;
    }else {
      api = `${api}/${this.state.rowData && this.state.rowData._id ? this.state.rowData._id : ''}`;
      type = 'PUT';
    }
    const res = await fetchMethodRequest(type, api, body);
    if(res && res.respCode === 200){
      if (this.filterCriteria && this.filterCriteria.criteria && this.filterCriteria.criteria.length > 0) {
        this.datatableref.getStateFIlingValues(this.filterCriteria);
      }
      this.setState({ showSideBar: false });
    }
    if (res && res.errorMessage) {
      this.setState({ errorMessage: res.errorMessage });
    }
  }
  
  getForm = () => {
    return <StateAttributeForm
      showSideBar={this.state.showSideBar}
      relevences={this.state.relevences}
      documentTypes={this.state.documentTypes}
      documentSources={this.state.documentSources}
      categories={this.state.categories}
      sourceTypes={this.state.sourceTypes}
      docTags={this.state.docTags}
      type={this.state.type}
      subCategories={this.state.subCategories}
      docScopes={this.state.docScopes}
      rowData={this.state.rowData}
      toggleSideBar={this.toggleSideBar}
      errorMessage={this.state.errorMessage}
      saveStateAttributesToServer={this.saveStateAttributesToServer}
    />
  }

  

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          // claimStatusFormFields={this.getClaimStatusFormFields}
          statusFilter={false}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          isClearFilters={false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={false}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          // dataKey={"OppClaimID"}
          // criteria={[{ "key": "PropertyStatus", "value": "Filed", "type": "eq" }]}
          criteria={[]}
          // globalSearchFieldName='user'
          // globalSearch={'Search'}
          type='State Attributes'
          loading={true}
          dontShowTitle={true}
          displayright={true}
          settingsData={this.state.settingsData}
          icon='lnr lnr-menu-circle'
          routeTo='stateUpFiling'
          // groupField='claimID'
          // sortField={"OppClaimID"}
          displayViewOfForm='modal'
          // apiResponseKey='claims'
          apiUrl={apiCalls.StateUpFiling}
          entityType='employee'
          sessionWarning={this.state.sessionWarning}
          toggleSideBar={this.toggleSideBar}
          
        />
        {this.state.showSideBar && this.getForm()
        }
        <Dialog  visible={this.state.showDelConfirm} showHeader={false} style={{ width: '30vw' }} modal={true} onHide={() => this.setState({ showDelConfirm: false })}>
          <div className="text-center">
            <div className='mt-4 mb-2'>
             <h4> Are you sure, You want to delete ? </h4>
            </div>
           
            <Button
              color="primary"
              onClick={() => this.deleteStateAttribute()}
            >
              Yes
            </Button>
            <Button
              color="primary"
              onClick={() => this.setState({ showDelConfirm: false })}
            >
              No
            </Button>
          </div>
        </Dialog>

      </span>
    );
  }
}