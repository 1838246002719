import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';
import store from '../../../App/store';
// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class RequiredEvidence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Required Evidence'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    await this.getStatusOptions()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }
  getStatusOptions = () => {
    fetch('GET', apiCalls.PropertyStatuses)
      .then(async (response) => {
        if (response) {
          let statuses = response.propertyStatuses;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            })
          }
          await this.setState({
            Statuses: modifiedStatuses
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }

  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 6,
      //   field: 'reOrder',
      //   // "displayInSettings": true,
      //   // "displayInFilter": true,
      //   // sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 60,
        field: 'EstateName',
        // //fieldType: 'BoolText',
        "mobile": true,
        header: 'Deal',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        textAlign: 'left',
        width: 60,
        field: 'FileFor',
        //fieldType: 'BoolText',
        "mobile": true,
        header: 'Mapped Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 60,
        field: 'TotalProps',
        //fieldType: 'BoolText',
        "mobile": true,
        fieldType: "redirect",
        header: 'Total Props Missing Proof',
        // filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 60,
        fieldType: "redirect",
        field: 'SellerProof',
        //fieldType: 'BoolText',
        "mobile": true,
        header: '# Missing Seller Proof',
        // filter: true,
        // "fieldType": 'number',
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 60,
        field: 'NameProof',
        fieldType: "redirect",
        //fieldType: 'BoolText',
        "mobile": true,
        header: "# Missing Name Proof",
        // filter: true,
        // "fieldType": 'number',
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 60,
        field: 'AddressProof',
        //fieldType: 'BoolText',
        fieldType: "redirect",
        "mobile": true,
        // "fieldType": 'number',
        header: "# Missing Address Proof",
        // filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 60,
        field: 'FEINProof',
        fieldType: "redirect",
        //fieldType: 'BoolText',
        "mobile": true,
        header: "# Missing FEIN Proof",
        // filter: true,
        // "fieldType": 'number',
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 60,
        field: 'HolderProof',
        fieldType: "redirect",
        //fieldType: 'BoolText',
        "mobile": true,
        // "fieldType": 'number',
        header: "# Missing Holder Proof",
        // filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },


    ]
    return data;
  };

  getFormFields = () => {
    return ([
      // {
      //   //'show': false,
      //   // 'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "screen",
      //   "label": "Screen",
      //   "options": [{ label: "Ready To File", value: "Ready To File" }, { label: "On Hold", value: "On Hold" }],
      //   "id": "Screen",
      //   "placeholder": "Screen",
      //   "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "dealStageID",
        "label": "Stage ID",
        "id": "name",
        "placeholder": "Stage ID",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "dealStageName",
        "label": "Stage Name",
        "id": "name",
        "placeholder": "Stage Name",
        "required": true
      },
      // {
      //   //'show': false,
      //   // 'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "dealType",
      //   "label": "Deal Type",
      //   "options": [{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }],
      //   "id": "DealType",
      //   "placeholder": "Deal Type",
      //   "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "status",
        "placeholder": "status",
        // "required": true
      },
      // {
      //   //'show': false,
      //   'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "screenType",
      //   "label": "Screen",
      //   "id": "name",
      //   "options": [{ label: "Property Editor", value: "Property Editor" }, { label: "Claims", value: "Claims" }],
      //   "placeholder": "Screen",
      //   "required": true
      // },
    ]);
  }


  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          sortField={"EstateName"}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          // globalSearch={'Search'}
          type='Required Evidence'
          displayright={true}
          icon='tag'
          routeTo='reports'
          displayViewOfForm='modal'
          apiResponseKey={"results"}
          apiUrl={apiCalls.RequiredEvidence}
          entityType='employee'
        />

      </span>
    );
  }
}