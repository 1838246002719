import React, { useEffect, useState } from 'react';
import stateData from './states.json';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as documentsAPIs from '../../../../shared/documentsApis';
import fetchMethodRequest from '../../../../config/service';



export const DocSelectFields = (props) => {
    let selectedCat;
    let rowData;
    const [formData, setFormData] = useState({});
    const [deals, setDeals] = useState([]);
    const [selectingDeal, setSelectingDeal] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [editType, setEditType] = useState();
    const [disbaleGoButton, setDisbaleGoButton] = useState(true);
    const [selDocScopeName, setSelDocSccopeName] = useState([]);
    const [selCategory, setSelCategory] = useState();
    const [stateClaimIds, setStateClaimIds] = useState([]);
    const [mappedNames, setMappedNames] = useState([]);
    const [sellerRelevanceNames, setsellerRelevanceNames] = useState([]);
    const [selInEffect, setSelInEffect] = useState(false);

    useEffect(() => {
        getSubCategories();
    }, []);

    useEffect(() => {
        checkValidations(formData);
        saveInputValuesToSessionStorage();

    }, [formData]);

    useEffect(() => {        
        if (subCategories && subCategories.length > 0 && ((props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && (window.location.href.includes('documentUpload') || sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME))) || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && (window.location.href.includes('availableDocuments') || sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME)))) && !selInEffect) {
            let params = new URLSearchParams(window.location.search);
            if ((props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && !window.location.href.includes('documentUpload')) || (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && !window.location.href.includes('availableDocuments'))) {
                params = new URLSearchParams('');
            }
            if (params && !params.get('dealId')) {
                const values = sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME) ? JSON.parse(sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME)) : {};
                for (const prop in values) {
                    params.append(prop, values[prop]);
                }
            }
            if (params && params.get('dealId')) {
                if (props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME) {
                    rowData = params.get('rowData') ? JSON.parse(decodeURIComponent(params.get('rowData'))) : '';
                    if (params.get('type') && params.get('type') === 'edit') {
                        let selIndex = params.get('index');
                        if (selIndex || selIndex === 0) {
                            selIndex = parseInt(selIndex);
                            if (rowData.docId && rowData.docId.length > 0 && rowData.docId[selIndex] && props.getDocumentData) {
                                props.getDocumentData(rowData.docId[selIndex], rowData.fileId[selIndex]);
                            }
                        }
                    }
                    const subCategory = (rowData && rowData.SubCategory) ? rowData.SubCategory : selCategory && selCategory.subCategoryName ? selCategory.subCategoryName : params.get('SubCategory') ? params.get('SubCategory') : '';
                    if (subCategory) {
                        const selCat = subCategories.find((e) => e.subCategoryName === subCategory);
                        if (!selCategory) {
                            setSelInEffect(true);
                            setSelCategory(selCat);
                        } else {
                            setSelInEffect(false);
                        }
                        selectedCat = selCat;
                        const selScope = selCat && selCat.documentScopeName && selCat.documentScopeName.length > 0 ? selCat.documentScopeName : [];
                        setValuesFromParams(params, props.tab, selScope, selCat);
                    }
                } else {
                    setValuesFromParams(params, props.tab, []);
                }
            }
        }
    }, [subCategories, selCategory]);

    /**
     * Saving input values into session storage for input value persistence on tab change
     */
    const saveInputValuesToSessionStorage = () => {        
        let obj = sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME) ? JSON.parse(sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME)) : {};
        obj.SubCategory = selCategory && selCategory.subCategoryName ? selCategory.subCategoryName : obj.SubCategory ? obj.SubCategory : '';
        if (formData.deal && formData.deal.DealID) {
            obj.dealId = formData.deal.DealID;
        }
        if (formData.selMappedName && formData.selMappedName.BusinessNamesID) {
            obj.mappedNameId = formData.selMappedName.BusinessNamesID;
        }
        if (formData.selRelSellerName && formData.selRelSellerName.BusinessNamesID) {
            obj.relSellerNameId = formData.selRelSellerName.BusinessNamesID;
        }
        if (formData.selState && formData.selState.shortCode) {
            obj.stateCode = formData.selState.shortCode;
        }
        if (formData.selStateClaimId && formData.selStateClaimId.stateClaimID) {
            obj.stateClaimId = formData.selStateClaimId.stateClaimID;
        }
        sessionStorage.setItem(documentsAPIs.DOC_UPLOAD_TAB_NAME, JSON.stringify(obj));
    }

    const setValuesFromParams = async (params, tab, docScopeNames, selCat) => {
        let selFormatDataFromParams = {};
        const dealId = params.get('dealId');
        props.setLoader(true);
        let names = props.names;
        let relNames = props.relSellerNames;
        const deals = await documentsAPIs.getDeals(dealId, 'dealId');
        if (names && names.length === 0) {
            names = await documentsAPIs.getNames('sellerRelevanceName', dealId);
            props.setDocumentsDropdownsData('names', names);
        }
        if (relNames && relNames.length === 0) {
            relNames = await documentsAPIs.getNames('mappedName', dealId);
            props.setDocumentsDropdownsData('relSellerNames', names);
        }
        // const [deals, names, relNames] = await Promise.all([documentsAPIs.getDeals(dealId, 'dealId'), documentsAPIs.getNames('mappedName', dealId), documentsAPIs.getNames('sellerRelevanceName', dealId)]);
        setDeals(deals);
        if (deals && deals.length > 0) {
            selFormatDataFromParams.deal = deals[0];
            setSelectingDeal(deals[0]);
        }        
        if (names && names.length > 0 && params.get('mappedNameId') && (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || docScopeNames.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME))) {
            const selElem = names.find((e) => e.BusinessNamesID.toString() === params.get('mappedNameId'));            
            selFormatDataFromParams.selMappedName = selElem;
        }
        if (relNames && relNames.length > 0 && params.get('relSellerNameId') && (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || docScopeNames.includes(documentsAPIs.DOC_SCOPE_REL_SELLER))) {
            const selElem = relNames.find((e) => e.BusinessNamesID.toString() === params.get('relSellerNameId'));
            selFormatDataFromParams.selRelSellerName = selElem;
        }
        if (params.get('stateCode') && (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || docScopeNames.includes(documentsAPIs.DOC_SCOPE_STATE))) {
            selFormatDataFromParams.selState = stateData.states.find((e) => e.shortCode === params.get('stateCode'));
            if (params.get('stateClaimId') && (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || docScopeNames.includes(documentsAPIs.DOC_SCOPE_STATE))) {
                let stateClaimIdstemp = props.stateClaimIds;
                if (stateClaimIdstemp && stateClaimIdstemp.length === 0) {
                    stateClaimIdstemp = await documentsAPIs.getStateClaimIds(dealId, params.get('stateCode'));
                    props.setDocumentsDropdownsData('stateClaimIds', stateClaimIdstemp);
                }
                if (stateClaimIdstemp) {
                    selFormatDataFromParams.selStateClaimId = stateClaimIdstemp.find((e) => e.stateClaimID.toString() === params.get('stateClaimId'));
                }
                setStateClaimIds(stateClaimIdstemp);

            }
        }

        props.setLoader(false);
        setMappedNames(names);
        setsellerRelevanceNames(relNames);
        setFormData(selFormatDataFromParams);
        if (tab === documentsAPIs.AVAIL_DOCS_TAB_NAME) {
            let validated = checkValidations(selFormatDataFromParams);
            if (validated) {
                props.getAllAvailableDocuments(selFormatDataFromParams, JSON.parse(JSON.stringify(subCategories)));
            }
        } else {
            setSelDocSccopeName(docScopeNames);
            if (window.location.href.includes('documentUpload')) props.getDocumentDetails(selFormatDataFromParams, selCat, names, rowData);
        }
    }

    const setFormValues = (name, value) => {
        if (props.clearTableData) {
            props.clearTableData();
        }
        if (name === 'selCategory') {
            onSelCategory(value)
            return;
        }
        if (name === 'deal') {
            let formData = {
                [name]: value
            }
            setFormData(formData);
            if (!value) {
                setSelectingDeal(value)
                sessionStorage.setItem(documentsAPIs.DOC_UPLOAD_TAB_NAME, JSON.stringify({ SubCategory: selCategory && selCategory.subCategoryName ? selCategory.subCategoryNam : null }));
            }
        } else {
            setFormData((ps) => ({ ...ps, [name]: value }));
            if (name === 'selState') {
                setFormValues('selStateClaimId', '');
            }
        }

    }

    const getDeals = async (e) => {
        const res = await documentsAPIs.getDeals(e.query, 'str');
        setDeals(res);
    }

    const getNames = async (deal) => {
        props.setLoader(true);
        const names = await documentsAPIs.getNames('mappedName', deal.DealID);
        const relNames = await documentsAPIs.getNames('sellerRelevanceName', deal.DealID);
        setMappedNames(names);
        props.setDocumentsDropdownsData('names', names);
        setsellerRelevanceNames(relNames);
        props.setDocumentsDropdownsData('relSellerNames', relNames);
        props.setLoader(false);
    }

    const onSelCategory = (value) => {
        setSelInEffect(false);
        setSelCategory(value);
        setDisbaleGoButton(true);
        if (!value) {
            setFormValues('deal', null);
        }
        setSelectingDeal(null);
        if (props.onSelCategory) {
            props.onSelCategory(value);
        }
        if (value.documentScopeName && value.documentScopeName.length > 0) {
            setSelDocSccopeName(value.documentScopeName);
        }
    }
    const getSubCategories = async () => {
        const res = await documentsAPIs.getSubCategories();
        setSubCategories(res);
    }



    const checkValidations = (formData) => {
        let disableGo = false;
        if (props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME) {
            if (!formData.deal || !formData.selStateClaimId || !formData.selMappedName || !formData.selRelSellerName || !formData.selState) {
                disableGo = true;
            }


        } else if (props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && selDocScopeName && selDocScopeName.length > 0) {
            selDocScopeName.forEach((e) => {
                if ((e === documentsAPIs.DOC_SCOPE_DEAL && !formData.deal) || (e === documentsAPIs.DOC_SCOPE_CLAIMID && !formData.selStateClaimId) ||
                    (e === documentsAPIs.DOC_SCOPE_MAPPED_NAME && !formData.selMappedName) || (e === documentsAPIs.DOC_SCOPE_REL_SELLER && !formData.selRelSellerName) ||
                    (e === documentsAPIs.DOC_SCOPE_STATE && !formData.selState)) {
                    disableGo = true;
                }

            });

        }
        setDisbaleGoButton(disableGo);
        return !disableGo;
    }

    const removeSearchParams = () => {
        const params = new URLSearchParams(window.location.search);
        if (params && params.get('dealId')) {
            let valueChanged = false;
            params.forEach((value, key) => {
                if (!valueChanged) {
                    valueChanged = checkValueChange(key, value);                    
                }
            });
            if (valueChanged) {
                window.history.replaceState(null, null, `${window.location.origin}/Sellers/`);
                saveInputValuesToSessionStorage();
            }
        }
    }

    const checkValueChange = (elem, value) => {
        let changed = false
        switch (elem) {
            case 'dealId':
                if (formData.deal && formData.deal.DealID && formData.deal.DealID.toString() !== value) {
                    changed = true;
                }
                break;
            case 'mappedNameId':
                if (formData.selMappedName && formData.selMappedName.BusinessNamesID && formData.selMappedName.BusinessNamesID.toString() !== value) {
                    changed = true;
                }
            case 'relSellerNameId':
                if (formData.selRelSellerName && formData.selRelSellerName.BusinessNamesID && formData.selRelSellerName.BusinessNamesID.toString() !== value) {
                    changed = true;
                }
                break;
            case 'stateCode':
                if (formData.selState && formData.selState.shortCode && formData.selState && formData.selState.shortCode !== value) {
                    changed = true;
                }
                break;
            case 'stateClaimId':                
                if (formData.selStateClaimId && formData.selStateClaimId.stateClaimID && formData.selStateClaimId.stateClaimID.toString() !== value) {
                    changed = true;
                }
        }
        return changed;
    }

    const namesDropDownTemplate = (col) => {
        return <span>{col.CombinedName}</span>
    }

    const getStateClaimIds = async (selState) => {
        props.setLoader(true);
        let filter = {
            "criteria": [
                { "key": "CompanyID", "value": formData.deal && formData.deal.DealID ? formData.deal.DealID : '', "type": "eq" },
                { "key": "SourceState", "value": selState.shortCode ? selState.shortCode : '', "type": "eq" },
                { "key": "ClaimStatus", "value": 'Paid', "type": "ne" },
                { "key": "ASSET_TRACKING_STATUS", "value": ['Active'], "type": "in" }
            ],
        };
        const result = await fetchMethodRequest('GET', `nsClaim?filter=${JSON.stringify(filter)}&type=exportToCsv&project=false`);
        if (result && result.claims && result.claims.length > 0) {
            const data = result.claims.filter((elem) => elem.stateClaimID)
            setStateClaimIds(data);
            props.setDocumentsDropdownsData('stateClaimIds', data);
        } else {
            setStateClaimIds([]);
        }
        props.setLoader(false);
    }

    const onClickGo = () => {
        removeSearchParams();
        if (props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME) {
            props.getDocumentDetails(JSON.parse(JSON.stringify(formData)), selCategory, mappedNames);
        } else {
            props.getAllAvailableDocuments(JSON.parse(JSON.stringify(formData)), JSON.parse(JSON.stringify(subCategories)));
        }
    }

    /**
     * Show red star besides mandatory fields
     * @returns {HTMLElement}
     */
    const getMandatoryStar = () => {
        return <FontAwesomeIcon icon={faStarOfLife}
            className='pl-1'
            color='red'
            data-toggle="tool-tip"
            title="Mandotary Field"
            style={{ width: 8 }}
        />
    }

    const getCloseIcon = (field) => {
        return <FontAwesomeIcon icon={faTimes}
            className='pl-1 mr-2'
            color={formData[field] || (field === 'selCategory' && selCategory) ? 'red' : 'lightgrey'}
            data-toggle="tool-tip"
            title="Clear Filter"
            id="clear stClaimId"
            onClick={() => setFormValues(field, '')}
            style={{ width: 'unset', cursor: "pointer", float: "right", pointerEvents: `${(formData[field] || (field === 'selCategory' && selCategory)) ? 'auto' : 'none'}` }}
        />
    }

    const getLabel = (label, field) => {
        return <label className='d-flex justify-content-between mb-0'>
            <span>{label}{getMandatoryStar()}</span>
            {getCloseIcon(field)}
        </label>
    }

    return (
        <div className='row mt-1'>
            {props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME &&

                <div className="form__form-group col-lg-2">
                    {getLabel('Category', 'selCategory')}
                    <div className=' form__form-group-field'>
                        <Dropdown value={selCategory} optionLabel='subCategoryName' placeholder='Select Category' onChange={(e) => { onSelCategory(e.value) }} options={subCategories}
                            style={{ width: '100%' }} filter={true} filterPlaceholder="Search" dataKey="_id"
                            filterMatchMode='startsWith' filterInputAutoFocus={true} />
                    </div>
                </div>
            }
            {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || (selDocScopeName && selDocScopeName.length > 0)) && <>
                {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || selDocScopeName.includes(documentsAPIs.DOC_SCOPE_DEAL)) &&
                    <div className="form__form-group col-lg-2">
                        {getLabel('Deal', 'deal')}
                        <div className=' form__form-group-field'>
                            <AutoComplete value={selectingDeal}
                                field='combName'
                                className='w-100'
                                onChange={(e) => setSelectingDeal(e.value)}
                                onSelect={(e) => { setFormValues('deal', e.value); getNames(e.value) }}
                                onClear={(e) => { setFormValues('deal', e.value) }}
                                minLength={1}
                                suggestions={deals}
                                dropdown={false}
                                completeMethod={getDeals}
                                placeholder='Search Deal Nickname'
                            // disabled={editType}
                            />
                        </div>
                    </div>
                }
                {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || selDocScopeName.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME)) &&
                    <div className="form__form-group sellerCNNamesDropDown col-lg-2">
                        {getLabel('Mapped Name', 'selMappedName')}
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selMappedName} dataKey='BusinessNamesID' placeholder='Select Mapped Name' optionLabel='BusinessName' onChange={(e) => setFormValues('selMappedName', e.value)} options={mappedNames}
                                filter={true} filterPlaceholder="Search" filterMatchMode='startsWith' filterInputAutoFocus={true} filterBy="BusinessName" disabled={!formData.deal || editType === 'update'} itemTemplate={namesDropDownTemplate} />
                        </div>
                    </div>
                }
                {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || selDocScopeName.includes(documentsAPIs.DOC_SCOPE_REL_SELLER)) &&
                    <div className="form__form-group sellerCNNamesDropDown col-lg-2">
                        {getLabel('Relevant Seller Name', 'selRelSellerName')}
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selRelSellerName} dataKey='BusinessNamesID' placeholder='Select Relevant Seller Name.' optionLabel='BusinessName' onChange={(e) => setFormValues('selRelSellerName', e.value)} options={sellerRelevanceNames}
                                filter={true} filterPlaceholder="Search" filterMatchMode='startsWith' filterInputAutoFocus={true} filterBy="BusinessName" disabled={!formData.deal || editType === 'update'} itemTemplate={namesDropDownTemplate} />
                        </div>
                    </div>
                }
                {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || selDocScopeName.includes(documentsAPIs.DOC_SCOPE_STATE)) &&
                    <div className="form__form-group col-lg-2">
                        {getLabel('State', 'selState')}
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selState} optionLabel='label' placeholder='Select State' onChange={(e) => { setFormValues('selState', e.value); getStateClaimIds(e.value) }} options={stateData.states}
                                style={{ width: '100%' }} filter={true} filterPlaceholder="Search"
                                filterMatchMode='startsWith' filterInputAutoFocus={true} disabled={!formData.deal || editType === 'update'} />
                        </div>
                    </div>
                }
                {(props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME || selDocScopeName.includes(documentsAPIs.DOC_SCOPE_CLAIMID)) &&
                    <div className="form__form-group col-lg-2">
                        {getLabel('State Claim ID', 'selStateClaimId')}
                        <div className=' form__form-group-field'>
                            <Dropdown value={formData.selStateClaimId} placeholder='Select State Claim ID' optionLabel='stateClaimID' onChange={(e) => setFormValues('selStateClaimId', e.value)} options={stateClaimIds}
                                style={{ width: '100%' }} filterMatchMode='startsWith' dataKey='_id' filter={true} filterPlaceholder="Search" filterInputAutoFocus={true} disabled={!formData.deal || editType === 'update'} />
                        </div>
                    </div>
                }
                <div className="col-md-1 d-flex">
                    <Button
                        color="primary" style={{ marginTop: 'auto', marginBottom: '3px' }} disabled={disbaleGoButton} onClick={() => { onClickGo() }}  >
                        Go
                    </Button>
                </div>
            </>}

        </div>
    )
}