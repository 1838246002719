import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Row, Card, CardBody, ButtonGroup, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import '../../../scss/dashboardStyles.css';
import '../../../scss/app.css'
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { SelectButton } from 'primereact/selectbutton';
import { ProgressBar } from 'primereact/progressbar'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { OverlayPanel } from 'primereact/overlaypanel';
// import config from '../../../config/config';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DropDown } from 'primereact/dropdown';
import { Dropdown } from 'primereact/dropdown';
// import Select from './dropDownfield';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import NotesModal from '../../Cruds/CommonModals/NotesModal'
import ConfirmaionModels from '../../Cruds/CommonModals/isApplyFilter';
import EditorComponent from '../../Cruds/CommonModals/EditorComponent'
import EditorComponentDealSizing from '../../Form/DealSizingPropertyEditor/EditorComponent'
import ConfirmaionModelorSave from '../../Cruds/CommonModals/ConfirmationModal'
import TableModal from './TableModal'
import Workflow from '../../RuleConfiguration/index'
import { CSVLink } from "react-csv";
import { Redirect } from 'react-router-dom';
// import { withStyles } from '@material-ui/core/styles';
// import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';

//Modals
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import ContactNoticeModal from '../CommonModals/NoticeModal';
import HighValuePropertyFilters from '../../Cruds/HighValuePropertyFilters/index';
import FormModal from '../../Form/FormModal';
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import KanbanModal from '../CommonModals/KanbanModal';
import CreateTaskModel from '../CommonModals/CreateTaskModal'
import SellerModal from '../CommonModals/SellerModal';
// import SellerModal from '../CommonModals/SelerNModel'
import RulesModal from '../CommonModals/RulesModal';
import PropertyFilter from '../../Form/PropertyFilter';
import DealSizingPropertyFilter from '../../Form/DealSizingPropertyEditor/DealSizingFilter'
import DealStatistics from '../../Form/Statistics'
import FactSetFilter from '../../Form/FactSet'
import OpenCorporateFilter from '../../Form/OpenCorporateForm'
import NamesLookupFilter from '../../Form/NamesLookupFilter';
import StateUP from '../../../containers/StateUP';
import ClaimSummary from '../../../containers/ClaimSummaryMain'
import SearchTermOnSiteFilter from '../../Form/SearchTermOnSiteFilter'
import RolePermissions from '../CommonModals/Permissions'
import SessionWarningModal from '../CommonModals/SessionWarningModal';
import ExpiredSessionModal from '../CommonModals/ExpiredSessionModal';
import PaymentsModal from '../CommonModals/PaymentsModal';
import PropertyTrailViewerModal from '../CommonModals/PropertyTrailViewerModal'
import ValidatedPropertyUpdaterModal from '../CommonModals/ValidatedPropertyUpdaterModal'
import ManuallyLoaded from '../../Cruds/ManualMatchLoading/index'
// config
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import apiCalls from '../../../config/apiCalls';
import RFIModal from '../../Cruds/CommonModals/RFIModal'
// Toaster message
// import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
// import TextAutocomplete from '../../../shared/components/form/TextAutocomplete'

// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
import Summary from '../../Summary/index';
import AvailableToReMapFilters from '../../AvailableToReMapFilters/index';
import AvailableToMapFilters from '../../AvailableToMapFilters/index';

import PropertyLoaderFilters from '../../PropertyLoaderFilters/index'
import PropertySearchV2Filters from '../../PropertySearchV2Filters/index'
import AvailableFile from '../../Cruds/AvailableFile/index';
import AvailableFileConfirmOnSite from '../../Cruds/AvailableFileConfirmOnSite/index';
import EvidenceSummary from '../../Cruds/EvidenceSummary/index';
import DocumentUpload from '../../Cruds/DocumentUpload/index';
import AvailableDocumentsPage from '../../Cruds/AvailableDocuments/index';

// import HighValuePropertyFilters from '../../Cruds/HighValuePropertyFilters/index';
//Loader
import Loader from '../../App/Loader';
//store
// import validate from '../../Validations/validate'
// import { reduxForm, } from 'redux-form'
// import { connect } from 'react-redux'
import ExcelReact from '../../Cruds/CommonDataTable/ExcelReact'
import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faSync, faCheck, faCheckCircle, faArrowCircleLeft, faSave, faArrowCircleRight, faBuilding, faExternalLinkAlt, faMinus, faChevronDown, faCircle, faTimes, faToggleOff, faCity, faFolder, faUsers, faExpand, faClipboardList, faHistory, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Reload from '../../reload'


import { faCopy } from '@fortawesome/free-regular-svg-icons'

const countriesSF = require('./countries.json')
let timeout
class DataTables extends React.Component {
  constructor(props) {
    super(props);
    this.closeMultiSelectDropdown = React.createRef();
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    this.closeMultiSelectDropdown = React.createRef();
    let dueStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let dueEndDate = moment(start).add(1, "days").subtract(1, "seconds");
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.paramsData = Object.fromEntries(urlSearchParams.entries());
    this.state = {
      totalSelectedRows: 0,
      allUsersData: this.props.data ? this.props.data : [],
      deleteRowDataId: {},
      claimNotesEdit: false,
      isFrozenColumns: false,
      tableFields: this.props.getTableFields(),
      loginData: {},
      dueStartDate: dueStartDate,
      dueEndDate: dueEndDate,
      originalTableFields: this.props.getTableFields(),
      tablefieldsToShow: this.props.getTableFields(),
      isOpenShowHideColumnsModal: false,
      start: start,
      selectedvalueforStatus: this.props.selectedStatusFilter ? this.props.selectedStatusFilter : "",
      end: end,
      first: 0,
      postBodyFile: {},
      selectedButtonForAvailableMap: this.props.selectedButtonForAvailableMap,
      selectedCountrie: "US",
      isPreviousWeek: false,
      criteriaData: { criteria: [] },
      copiedA: false,
      lastScoredDate: "",
      // city: "Private",
      columnOrderError: false,
      isEditedClicked: false,
      addFilters: ["isExcludeRoyalties"],
      // clickCount: 0,
      validatedArray: [],
      rows: this.props.Limit ? this.props.Limit : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
      isShowTable: false,
      sortified: false,
      showError: false,
      Properties: true,
      removeDataSource: false,
      confirmation: true,
      showErrorMessage: false,
      exportHeaders: [],
      sellerEditType: "",
      isDisabledTab: false,
      direction: 'asc',
      redirectSellerId: null,
      negativePeriod: null,
      downloadData: [],
      PendingCount: 0,
      ConfirmedCount: 0,
      MissingCount: 0,
      columns: [

      ],
      propertyEditorCreateOptions: [{ label: "New Claim", value: "New Claim" }, { label: "Research Request", value: "Research Request" }, { label: "Seller Negative Notice", value: "Seller Negative Notice" }, { label: "General Task", value: "General Task" }, { label: "Site Confirmation", value: "Site Confirmation" }],
      propertyEditorExistingOptions: [{ label: "Existing Claim", value: "Existing Claim" }, { label: "Research Request", value: "Research Request" }, { label: "Seller Negative Notice", value: "Seller Negative Notice" }, { label: "General Task", value: "General Task" }, { label: "Site Confirmation", value: "Site Confirmation" }],
      // dynamicColumns: [],
      selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
      filterCriteria: {
        limit: this.props.Limit ? this.props.Limit : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
        page: 1,
        criteria: this.props.criteria ? this.props.criteria : [],
        sortfield: (this.props.type === "Property Status" || this.props.type === "Property Reason" ? "Status" :
          this.props.type === "Claims" ? "EstateName,SourceState,BusinessName" :
            this.props.sortField ? this.props.sortField : "created"),
        direction: (this.props.type === "Labels" || this.props.type === "Departments" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "OPRA2 Property Editor" ?
          'asc'
          : this.props.sortDirection ? this.props.sortDirection : "desc")
      },
      collapsefilter: false,
      selectednote: "",
      stats: [],
      selectedRows: '',
      activeTab: this.props.activeTab,
      selectedRowsId: '',
      selectedViewType: "Default",
      parentTableFields: [
        { header: "Parent Prop.Owner Name", field: "propertyName" ,width:450},
        { header: "Parent Prop.Address line1", field: "propertyAddressLine1",width:450 },
        {
          header: "Parent Prop.City",
          field: "propertyCity",
          width:200
        },
        {
          header: "Parent Prop.State",
          field: "propertyState", width:200
        }],
      dataChangeOptionswithFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
      ],
      dataChangeOptionswithoutSortwithFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
        { "label": "Freeze first 3", "value": 'freeze' },
        { "label": "UnFreeze first 3", "value": 'unfreeze' },
      ],
      dataChangeOptionswithoutSortwithoutFilter: [
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
        { "label": "Freeze first 3", "value": 'freeze' },
        { "label": "UnFreeze first 3", "value": 'unfreeze' },
      ],
      dataChangeOptionswithoutFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
      ],
      dataChangeOptionswithoutFilterPE: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
        { "label": "Freeze first 3", "value": 'freeze' },
        { "label": "UnFreeze first 3", "value": 'unfreeze' },
      ],
      dataChangeOptionswithFilterPE: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
        { "label": "Freeze first 3", "value": 'freeze' },
        { "label": "UnFreeze first 3", "value": 'unfreeze' },
      ],
      Apa: [{ "label": "10-Full Remnant,subject to Exclusions", "value": '10' },
      { "label": "14-Full Remnant,includes RCS,subject to Exclusions", "value": '14' },
      { "label": "20-Limited scope,see Inclusions,subject to Exclusions", "value": '20' },
      { "label": "24-Limited scope,includes RCS,see Inclusions", "value": '24' },
      { "label": "30-UP only", "value": '30' },
      { "label": "40-RCS only", "value": '40' },
      { "label": "50-Judgment only", "value": '50' },
      { "label": "60-Other,limited to named inclusion(s)", "value": '60' },
      { "label": "64-Other,limited to named inclusion (s) with RCS", "value": '64' },
      { "label": "70-Full Remnant,Unable to pursue recoveries", "value": '70' },
      { "label": "75-Full Remnant,PWC portfolio restriction", "value": '75' },
      { "label": "80-Corporate Remnants", "value": '80' },
      { "label": "82-Corporate Remnants,limited scope", "value": '82' },
      { "label": "85-Corporate Remnants,NDA", "value": '85' },
      { "label": "87-Corporate Remnants,Sold", "value": '87' },
      ],
      FilterSortOptions: [
        // { label: 'Type of UP Recovery', value: "dealType" },
        { label: 'Business Name', value: "company" },
        { label: 'Transition Target', value: "transitionTarget" },
        { label: 'Site Discovery Target', value: "searchTarget" },
        { label: 'Validation Target', value: "validationTarget" },
        { label: 'File Target', value: "fileTarget" }
        // { label: 'Property City', value: "propertyCity" },
        // { label: 'Property State', value: "propertyState" },
      ],
      totalRecords: [],
      exportData: [],
      city: "Private",
      analysttype: null,
      sellertype: null,
      selectFilterValue: "",
      isOpenFormModal: false,
      openViewModal: false,
      isLoading: false,
      isOpenRolesModal: false,
      isFilterShown: true,
      searchedCompany: "",
      isOpenConfirmationModal: false,
      isApplyFilter: false,
      selectedScreenPermission: '',
      loginRole: 'Admin',
      startDate: '',
      endDate: '',
      progress: 0,
      displayErrorMessage: false,
      unassignedOnly: false,
      isShowunAssign: false,
      // isTopFilter: true,
      apiUrl: this.props.apiUrl,
      totalRecordsLength: 0,
      tabType: this.props.tabType,
      sessionExpiryModal: false,
      mobileListFields: [],
      dateFormat: '',
      filteredAnalysts: [],
      investorCompany: [],
      openTemplateModal: false,
      isOpenProductsModal: false,
      selectschool: '',
      roleType: '',
      openId: 1,
      openActionDropDown: false,
      redirecToLogin: false,
      openDeleteModal: false,
      blockFamily: false,
      selectActions: '',
      viewType: 'list',
      claimID: "",
      issellerModalView: false,
      actions: '',
      displayViewOfForm: this.props.displayViewOfForm,
      displayBreadCrumbField: '',
      displayBreadCrumbValue: '',
      width: window.innerWidth,
      isChangeHit: false,
      height: window.innerHeight,
      globalSearch: '',
      showMultiSelectDropDown: false,
      multiSelectTypes: '',
      actionsTypes: '',
      userStatus: '',
      selectedAutoCompleteValue: '',
      suggestions: [],
      confirmModalText: '',
      confirmType: '',
      openUserPasswordResetModal: false,
      iskanbanModal: false,
      errorResponse: false,
      successResponse: false,
      errorMessage: '',
      successMessage: '',
      editedrowData: {},
      sessionWarning: false,
      forceLogout: false,
      iscontactNoticeModal: false,
      dateQuery: {},
      addRow: false,
      companyFilterType: "sw",
      propertyEditorTabs: [
        { name: "Green", color: "green", tooltip: "Green" },
        { name: "Blue", color: "blue", tooltip: "Blue" },
        { name: "Yellow", color: "#ff9700", tooltip: "Yellow" },
        { name: "Brown", color: "brown", tooltip: "Brown" },
        { name: "Black", color: "black", tooltip: "Black" },
        // { name: "Teal", color: "#008080" },
        { name: "SP", color: "blue", tooltip: "Similarly Validated Properties For This Deal" },
        { name: "All", color: "red", tooltip: "All validated properties for this Deal" },
      ],
      propertyEditorTabsforDealSizing:[
      { name: "Green", color: "green", tooltip: "Green" },
      { name: "Blue", color: "blue", tooltip: "Blue" },
      { name: "Yellow", color: "#ff9700", tooltip: "Yellow" },
      { name: "Brown", color: "brown", tooltip: "Brown" },
      { name: "Black", color: "black", tooltip: "Black" },
      // { name: "Teal", color: "#008080" },
      // { name: "SP", color: "blue", tooltip: "Similarly Validated Properties For This Deal" },
      { name: "All", color: "red", tooltip: "All validated properties for this Deal" },
    ],
      IssueCodes: {},
      ocFilters: [],
      sheets: [],
      csvOrderforPE: ["estateName", "companyID", "claimID", "propertyID", "propertyDataSource", "sourceState", "statePropertyId", "businessName", "propertyName", "businessAddressLine1", "businessCity", "businessState", "businessZipCode", "propertyAddressLine1", "PropertyAddressLine2", "propertyCity", "propertyState", "propertyZipCode", "propertyDescription", "holderName", "propertyMinValueAmount", "propertyMaxValueAmount", "escheatmentDate", "dateOfLastContact", "propertyStatus", "matchStatusReason", "matchStatusNotes", "fileFor", "propertyAlternateId", "alternateID", "prospectiveStatus", "nameIssueCode", "nameMatchScore", "addressLine1", "MatchScore", "cityStateZipScore", "batchName", "bucket", "scoreDate", "scoreUpdateDate", "isValidated", "validatedDate", "validatedBy", "hasPropertyCoOwner", "userEmailID", "opraValidatedFlag", "createdDate", "createdByUser", "createdBy", "updatedDate", "dateIndexedInElastic", "opraAddedDate", "activeproperty", "ResidentialFlag", "nameId", "addressId", "oprA_AddressDetailID", "_id", "note", "businessNameID", "addressLine1MatchScore", "propertyResidentialFlag", "active"],
      showAddButton: false,
      filteredAnalystsEmails: []
    };
  }

  componentDidUpdate() {
    let editedRecordsLength = document.getElementsByClassName("p-row-editor-cancel").length
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
    }
    if (this.state.errorResponseHighProperty) {
      setTimeout(() => this.setState({ errorResponseHighProperty: false, errorResponseHighPropertyMessage: '' }), 30000);
    }
    if (this.state.copiedA) {
      setTimeout(() => this.setState({ copiedA: false }), 10000);
    }
    if (!this.state.errorResponse && localStorage.getItem('serverError') === "true") {
      localStorage.removeItem('serverError')
    }
    if (this.state.successMessage) {
      setTimeout(() => this.setState({ successMessage: false, successResponse: '' }), 20000);
    }
    if (this.state.showSelectederror) {
      setTimeout(() => this.setState({ showSelectederror: false, errormsg: '', errorColor: false }), 20000);
    }
    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   setTimeout(() => this.setState({ forceLogout: true }), loggedTime);
    // }
    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }

    if (this.state.progress === 100) {
      setTimeout(() => this.setState({ progress: 0 }), 200);
    }
    if (this.state.isEditedClicked && editedRecordsLength === 0) {
      this.setState({
        isEditedClicked: false
      })
    }

  }

  /**
   * 
   * @returns to return the filter criteria into other files
   */
  getFiltercriteria = () => {
    return this.state.filterCriteria
  }

  /**
   * 
   * @returns the data group source arrray
   */
  getDataGroupSources = async () => {
    let StatusArray = [], dataArray = [];
    let filterCriteria = {}
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    let loginRole = loginCredentials.roleName
    let StateDataSource = []
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
    await fetchMethodRequest('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["dataSources"] && response["dataSources"].length > 0) {
        let propertyStatuses = response["dataSources"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.group, value: status.dataSourceNames })
          dataArray.push({ label: status.group, value: status.group })
          if (status.group === "State UP Data" || status.group === "CA-N" || status.group === "SEC" || status.group === "Empty") {
            StateDataSource = [...StateDataSource, ...status.dataSourceNames]
          }
        }
        this.setState({ dataSourceGroups: StatusArray, dataArray: dataArray, StateDataSource: StateDataSource })
      }
    })
    return this.state.settingsData;
  }

  /**
   * Returns the screen permissions from local storage to set edit functionality
   */
  getScreenPermissions = async () => {
    let screenPermissions
    if (this.props.type === "Legal Entity Suffix" || this.props.type === "Menu Configuration" || this.props.type === "Data Feed - Inclusion" || this.props.type === "Legal Entity Abbreviation" || this.props.type === "State-UP Filing Category" || this.props.type === "State-UP Filing Sub Category" || this.props.type === "Available To File - Statuses" || this.props.type === "Research Request Type" || this.props.type === "Data Source Groupings" || this.props.type === "Rfi Reasons" || this.props.type === "Claim Value Hint Bucket Avg") {
      screenPermissions = RolePermissions.screenPermissions("Configurations");
    } else if (this.props.type === "APA Type Configuration" || this.props.type === "Deal Stage Configuration" || this.props.type === "Deal Status Configuration" || this.props.type === "Deal Type Configuration") {
      screenPermissions = RolePermissions.screenPermissions("Deal Configurations");
    }
    else if (this.props.type === "Property Reason" || this.props.type === "Property Status" || this.props.type === "Next Action Date Projection" || this.props.type === "Claim Status" || this.props.type === "Claim Reason" || this.props.type === "Business Name Issue Code" || this.props.type === "Property And Claim Status") {
      screenPermissions = RolePermissions.screenPermissions("Status Reason Screen");
    } else if (this.props.type === "ClaimsManagement") {
      screenPermissions = RolePermissions.screenPermissions("Claims Management");
    } else if (this.props.type === "crMatrix") {
      screenPermissions = RolePermissions.screenPermissions("CR Matrix");
    } else if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt")) {
      screenPermissions = RolePermissions.screenPermissions("Deal Mgmt");
    } else if (this.props.type === "Regular Scoring Queue") {
      screenPermissions = RolePermissions.screenPermissions("RS Queue");
    } else if (this.props.type === "Departments") {
      screenPermissions = RolePermissions.screenPermissions("Teams");
    } else if (this.props.type === "Deal Sizing Queue") {
      screenPermissions = RolePermissions.screenPermissions("DS Queue");
    } else { screenPermissions = RolePermissions.screenPermissions(this.props.type); }
    await this.setState({
      screenPermissions: screenPermissions
    })
  }

  /**
   * 
   * @param {String} value 
   * @returns the trimmed value trimming spaces
   */
  trimmedValue = (value) => {
    if (value === "true" || value === "false") {
      return JSON.parse(value)
      // return true
    } else {
      let val = value.trim()
      if (val.includes(".")) {
        return parseFloat(val)
      }
      return parseInt(val)
    }
  }
  getDepartments = () => {
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }]
    return fetchMethodRequest('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          if (response && response.departments) {
            let labels = response.departments;
            let modifiedLabels = [];
            for (let label of labels) {
              modifiedLabels.push({
                label: label.departmentName,
                value: label.departmentName,
                departmentId: label._id

              })
            } const sorted = modifiedLabels.sort((a, b) => {
              let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            await this.setState({
              taskDepartmentItems: sorted
            })
            let user = JSON.parse(localStorage.getItem('loginCredentials'));
            let DepartmentArray = [], departMentIdArray = []
            if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
              DepartmentArray.push(user.department)
              departMentIdArray.push(user.departmentId)
              await this.setState({ Department: DepartmentArray, departMentIdArray: departMentIdArray, selectedDepartment: DepartmentArray })
              // this.submit()
            }

          }
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  componentDidMount = async () => {
    let tabType = this.state.tabType;
    if (window.location.href.includes('documentUpload')) {
      tabType = 'Document Upload';
    } else if (window.location.href.includes('availableDocuments')) {
      tabType = 'Available Documents';
    }else{
      tabType =this.state.activeTab
    }
    await this.setState({ activeTab: tabType, showDataTable: true });
    var notcallingApiatinitial = ["PropertySearchv2","OpenCorporateBranchBatch", "FactSet", "OpenCorporateBranch", "OpenCorporateSearch", "Name Lookup", "Address Lookup", "Negative Notice List Viewer", "Site Confirmation Editor", "Properties", "Property Editor", "OPRA2 Property Editor", "Deal Sizing Property Editor", "Claims", "ClaimsManagement", "Deal Sizing Report For Companies", "Deal Sizing Report For Private Equity Portfolio"]
    if (await localStorage.getItem("succ")) {
      this.getSuccessMessage(await localStorage.getItem("succ"))
    }
    Reload.reload()//for testing new Changes are present
    var arrTabs = this.state.propertyEditorTabs
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    // await this.getTimeFormat()
    await this.getLoginRole();
    await this.getActions();
    await this.getScreenPermissions()
    await this.getDepartments()
    if (this.props.type != "OpenCorporateBranch" && this.props.type !== "OpenCorporateBranchBatch" && this.props.type !== "OpenCorporateSearch") await this.getDataGroupSources()

    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    localStorage.removeItem("ISEdited")
    // let sessionexpired = await localStorage.getItem('sessionexpired')
    // if (sessionexpired === "true") {
    //   await this.setState({ sessionExpiryModal: true })
    // }
    if (this.props.type === "Deal Sizing Property Editor") {
      var propertyEditorCreateOptions = this.state.propertyEditorCreateOptions
      var propertyEditorExistingOptions = this.state.propertyEditorExistingOptions
      propertyEditorCreateOptions.shift()
      propertyEditorExistingOptions.shift()
      this.setState({ propertyEditorCreateOptions: propertyEditorCreateOptions, propertyEditorExistingOptions: propertyEditorExistingOptions })
    }
    const noContext = document.getElementsByClassName('tableCardBody');
    noContext[0].addEventListener("contextmenu", (e) => {
      e.preventDefault();
      this.copyContentonRightClick(e)
    });
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener("storage", (event) => {
      this.updateTaskStatus()
    });
    // document.addEventListener('mousedown', this.handleClickOutside)
    //showing the data only other than default role
    if (!loginCredentials) {
      await this.setState({ redirecToLogin: true })
    } else {
      var refreshevery30Sec
      if (this.props.type === "Queue") {
        refreshevery30Sec = setInterval(() => {
          this.getDataFromServer(this.state.filterCriteria, 'refresh')
        }, 30000);
      } else {
        clearInterval(refreshevery30Sec)
      }
      if (loginCredentials.roleName === "Analyst") {
        await this.setState({
          iscontactNoticeModal: true
        });
      }
      if (loginCredentials.roleName === "Admin" || loginCredentials.roleName === config.roleNames.coreOpsManager) {
        arrTabs.splice(5, 0,
          { name: "Teal", color: "#008080", tooltip: "Teal" },
        );
      } else {
          arrTabs = this.state.propertyEditorTabs
      }
      if (this.props.type === "Deal Sizing Property Editor") {
        arrTabs = this.state.propertyEditorTabsforDealSizing

      } 
      let userName = loginCredentials.displayName
      if (userName.includes("FINAC".toLowerCase()) || userName.includes("Integrity".toLowerCase())) {
        this.setState({
          isShowunAssign: true
        })
      }
      if (this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" || this.props.type === "List View") {
        await this.handleListViewCanrdsbasedonRole(loginCredentials)
      }
      if (this.props.type === "Weekly Recovery Plan" || this.props.type === "Recovery Planner V2" || this.props.type === "Utilization - US Team" || this.props.type === "ClaimsManagement") {
        await this.getAnalysts()
      }
      if (this.props.type === "Regular Scoring Queue") {
        await this.getEmails()
      }
      if (this.props.type === "ClaimsManagement") {
        await this.getClaimStatus()
      }

      let filterCriteria = this.state.filterCriteria
      filterCriteria["limit"] = loginCredentials.pageLimit ? loginCredentials.pageLimit :
        localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
          : 10
      await this.setState({
        rows: this.props.Limit ? this.props.Limit : loginCredentials.pageLimit ? loginCredentials.pageLimit :
          localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
            : 10,
        filterCriteria: filterCriteria,
        redirecToLogin: false,
        iscontactNoticeModal: false,
        // userRole: loginCredentials.roleName,
        loginCredentials: loginCredentials,
        displayViewOfForm: this.props.displayViewOfForm,
      })
    }
    if (this.props.type === "Company") {
      if (!localStorage.getItem('CompanyType')) {
        localStorage.setItem('CompanyType', this.state.city);
      } else {
        await this.setState({
          city: localStorage.getItem('CompanyType')
        })
      }
    } else {
      localStorage.removeItem('CompanyType');
      localStorage.removeItem('SearchedCompany');
    }
    if (this.props.type === "Weekly Recovery Plan" || this.props.type === "Recovery Planner V2" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity") {
      await this.handleDatesFormating()
    }
    // hari check where this should go
    if (this.props.getTableFields && !this.props.preset) {
      //let tablefields = await this.props.getTableFields();
      this.setState({
        tablefields: this.props.getTableFields(),
        originalTableFields: this.props.getTableFields(),
        // isLoading: false,
        mobileListFields: this.props.getTableFields(),
        searchedCompany: localStorage.getItem('SearchedCompany') ? localStorage.getItem('SearchedCompany') : "",
      }, async () => {
        let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
        let columns = await JSON.parse(colOrder);
        if (columns) {
          await this.getTableFieldsOrder();
        } else {
          await this.updateColumnsOrder(null);
        }
      });
    }
    if (document.getElementsByClassName("layout layout--collapse").length === 0) {
      document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
    }
    await this.sendColumnDataToServer("", "GET");
    // if (this.props.type !== "Negative Notice List Viewer" && this.props.type !== "Properties" && this.props.type !== "Property Editor" && this.props.type !== "OPRA2 Property Editor" && this.props.type !== "Deal Sizing-Properties" && this.props.type !== "Claims" && this.props.type !== "ClaimsManagement" && this.props.type !== "Deal Sizing Report For Companies" && this.props.type !== "Deal Sizing Report For Private Equity Portfolio") {
    if (this.props.type && !notcallingApiatinitial.includes(this.props.type)) {
      if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && localStorage.getItem("sellerFilter")) {
        let filter = JSON.parse(localStorage.getItem("sellerFilter"))
        if (filter && filter.limit) {
          filter.limit = loginCredentials.pageLimit ? loginCredentials.pageLimit :
            localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
              : 10
        }
        if (filter && filter.globalSearch) {
          delete filter.globalSearch
        }
        if (filter && filter.criteria) {
          filter.criteria = []
        }
        this.setState({ filterCriteria: filter })
      }
      if (this.props.type !== "Deal Statistics" && loginCredentials.roleName !== "Analyst" && this.props.type !== "Manually Loaded Properties" && this.props.type !== "ClaimSummary" && this.state.activeTab !== 'Document Upload' && this.state.activeTab !== 'Available Documents') {
        if (this.props.type !== "UP Opportunity") {
          //          this.getAdditionalFilter()
          let body = {
            // "states": [],
            "dealType": ["BK", "CR"],
            // "sellerId": 0,
          }
          this.getDataFromServer(this.state.filterCriteria, "", "", body);

        } else {
          let addFilters = ["isExcludeRoyalties"]
          this.getUsersSummaryData("", addFilters)
        }
      } else {
        this.setState({ isLoading: false })
      }
      if (this.props.type) {
        await this.setState({
          isShowTable: true
        })
      }

    } else {
      var url;
      if (localStorage.getItem("nspropertiesparamsUrl")) {
        url = new URL(localStorage.getItem("nspropertiesparamsUrl"));
      } else {
        url = new URL(window.location.href);
      }
      var c = url.searchParams.get("sellerName");
      if (c || url.searchParams.get("propertyId")) {
        this.getFilteredValues()
      }
      //user preference column order
      //for properties hide the sidebar by default
    }
    if (this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
      this.isShowTable(true)
    }
    // await this.setDynamicColumns()

    this.setState({
      formFields: this.props.formFields,
      limit: loginCredentials.pageLimit,
      propertyEditorTabs: arrTabs,
      showAddButton: true
    });
  }


  /**
   * seting the dynamic columns for datatable
   */
  setDynamicColumns = async () => {
    let columns = []
    let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
    colOrder = JSON.parse(colOrder)
    let details = colOrder.details
    let Defaults
    // let Defaults = details["Default"]
    if (details) {
      Defaults = details[this.state.selectedViewType]
      if (Defaults === undefined) {
        Defaults = this.props.getTableFields()
      }
    } else {
      Defaults = colOrder
    }

    for (let Default of Defaults) {
      if (Default.field !== "expander" && (Default.field === "AssetType" || Default.field === "Payments" || Default.field === "PaymentBtn"
        || Default.field === "CompanyID" || Default.field === "feinProof" || Default.field === "nameProof" || Default.field === "addressProof" || Default.field === "holderProof" || Default.field === "sellerProof"
        || Default.field === "EstateName" || Default.field === "PropertyID" || Default.field === "SourceState" || Default.field === "BusinessName" || Default.field === "StatePropertyID" || Default.field === "OppClaimID" || Default.field === "StateClaimID" || Default.field === "PropertyStatus" || Default.field === "PropertyReason" || Default.field === "PropertyNote" || Default.field === "ClaimAmount" || Default.field === "FileFor")) {
        columns.push({ field: Default.field, header: Default.header, bodyStyle: Default.width, show: Default.show })
      } else if (Default.field !== "expander") {
        columns.push({ field: "", header: "", bodyStyle: Default.width, show: Default.show })
      }
    }
    await this.setState({ columns: columns })
    if (this.props.type === "ClaimsManagement" && colOrder) {
      let dynamicColumns = this.state.columns.map((col, i) => {
        if (col.show) {
          return <Column key={col.field} field={col.field} textAlign={col.textAlign} selectionMode={col.selectionMode} body={this.changeFieldValues}
            bodyStyle={{ width: col.bodyStyle }} />;
        }
      });
      await this.setState({ dynamicColumns: dynamicColumns })
    }

  }

  /**
   * 
   * @param {Object} loginCredentials 
   * handling the cards pe
   */
  handleListViewCanrdsbasedonRole = async (loginCredentials) => {
    let result = loginCredentials.roleName.toLowerCase();
    let excludeStr = "Search"
    if (result.includes("analyst") && result !== "recovery analyst") {
      if (result.includes(excludeStr.toLowerCase())) {
        await this.setState({ userRole: false, showSiteDiscoveryCards: true, isShowunAssign: true })
      } else {
        await this.setState({ userRole: false })
      }
    } else if (result.includes("manager")) {
      if (result.includes(excludeStr.toLowerCase())) {
        await this.setState({ userRole: true, showSiteDiscoveryCards: true, isShowunAssign: false })
      } else {
        await this.setState({ userRole: true })
      }
    } else if (result === "admin" || result === "leadership" || result === "filer" || result === "reviewer") {
      await this.setState({ userRole: true })
    } else {
      if (result === "recovery analyst") {
        await this.setState({ userRole: true })
      } else {
        await this.setState({ userRole: false })
      }
    }
  }

  /**
   * Handling the date format for weekly recovery plan
   */

  handleDatesFormating() {
    let curr = new Date(); // get current date
    let first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    let firstday = new Date(curr.setDate(first)).toUTCString();
    let last = new Date(firstday).getDate() - (new Date(firstday).getDay() - 1) + 4;// last day is the first day + 6
    let startDateReports = dateFormats.formatDate(firstday, "MM/DD/YYYY");
    let lastday = new Date(new Date(firstday).setDate(last));
    let lastDateReports = dateFormats.formatDate(lastday, "MM/DD/YYYY");
    this.setState({
      startDateReports: startDateReports,
      lastDateReports: lastDateReports,
      disableDate: lastDateReports
    })
    this.searchItems();
  }

  endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 4;
    return new Date(date.setDate(lastday));
  }


  /**
   * 
   * @param {String} value 
   * @param {String} type 
   * @param {Object} view 
   * sending the user  preference col order to save and reset it on coloumn reorder
   */
  sendColumnDataToServer = async (value, type, view) => {
    let method = type;
    let url = "";
    let keyname = `${this.props.type}_column_order`;
    if (type === "PUT") {
      await this.setState({
        isLoading: true
      })
      this.handlePercentage("1")
      url = `${apiCalls.Users}/preferences/${this.state.loginData._id}?key=${keyname}`;
      let listPreferences = {};
      listPreferences[keyname] = value;
      let body = { listPreferences: listPreferences };
      if (view) {
        body.view = view.views
      }
      await fetchMethodRequest(method, url, body).then(async (resp) => {
        if (resp) {
          if (resp.respCode) {
            this.setState({ columnOrderError: true, columnOrderMsg: resp.respMessage })
            return;
          } else if (resp.errorMessage) {
            // showToasterMessage(resp.errorMessage, "error");
          }
        }
      });
    } else {
      url = `${apiCalls.Users}/preferences/${this.state.loginData._id}?key=${keyname}`;
      await fetchMethodRequest(method, url).then(async (resp) => {
        if (resp) {
          if (resp) {
            if (resp.details) {
              localStorage.setItem(
                `${this.props.type}_column_order`,
                JSON.stringify(resp)
              );
              this.setState({ columnOrderError: true, columnOrderMsg: resp.respMessage })
              this.scrollWin();
              // hari check where this should go
              if (this.props.getTableFields) {
                this.setState(
                  {
                    tablefields: this.props.getTableFields(),
                    originalTableFields: this.props.getTableFields(),
                    isLoading: false,
                    mobileListFields: this.props.getTableFields(),
                    displayViewOfForm: this.props.displayViewOfForm,
                  },
                  async () => {

                    let colOrder = resp.details[this.state.selectedViewType];
                    if (colOrder && colOrder.length > 0) {
                      await this.getTableFieldsOrder();
                    } else {
                      await this.updateColumnsOrder(null);
                    }
                  }
                );
              }
            } else {
              if (this.props.getTableFields) {
                this.setState(
                  {
                    tablefields: this.props.getTableFields(),
                    originalTableFields: this.props.getTableFields(),
                    isLoading: false,
                    mobileListFields: this.props.getTableFields(),
                    displayViewOfForm: this.props.displayViewOfForm,
                  },
                  async () => {
                    await this.updateColumnsOrder(null);
                  }
                );
              }
            }
          } else if (resp.errorMessage) {
            this.getErrorMessage(resp.errorMessage, "", "error")
            // showToasterMessage(resp.errorMessage, "error");
          }
        }
      });
    }
  };

  /**
   * 
   * @param {String} type 
   * @param {Object} columnFields 
   * @param {Array} changedTableFields 
   * Closing the show hide model 
   */
  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal based on confirmation
  closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];

      this.setState({
        isOpenShowHideColumnsModal: false,
      })

      fields.map(async item => {
        Object.entries(columnFields).forEach(async ([key, value]) => {
          if (item.field === key) {
            return item.show = value;
          }
        });
      })

      fields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            width: col.width
          }
        )
      });
      await this.sendColumnDataToServer(updatedOrder, "PUT", columnFields);
      await this.sendColumnDataToServer("", "GET");
      // localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
      await this.getTableFieldsOrder(type, this.state.originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];
      this.setState({
        isOpenShowHideColumnsModal: false,
      })
      await this.sendColumnDataToServer(fields, "PUT", columnFields);
      await this.sendColumnDataToServer("", "GET");
      // localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(fields));
      await this.getTableFieldsOrder(type, this.state.originalTableFields, "isLoading");
    } else {
      this.setState({
        isOpenShowHideColumnsModal: false,
        // isOpenConfirmColumnModel: true
      })
    }
    this.setDynamicColumns()
  }

  /**
   * Setting the action type format into the dropdown values
   */
  getActions = async () => {
    let selectedRows = this.state.selectedRows ? [...this.state.selectedRows] : [];
    let actionsTypes = this.props.actionsTypes ? [...this.props.actionsTypes] : [];
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
          let options = actionsTypes[i]['options']
          for (let j = 0; j < options.length; j++) {
            if (options[j]['show'] === true) {
              if (selectedRows && selectedRows.length >= 2 && options[j]['multiple'] === true) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              } else if (selectedRows && selectedRows.length === 1) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              } else if (selectedRows && selectedRows.length === 0) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "action": actionsTypes[i]['action'] })
              }
            }
          }
        }
      }
    }
    await this.setState({
      actionsTypes: result
    })
  }

  /**
   * Removing the dropdown values on the top if we click out side of the column
   */
  handleClickOutside = (event) => {
    if (
      this.closeMultiSelectDropdown.current &&
      !this.closeMultiSelectDropdown.current.contains(event.target)
    ) {
      this.setState({
        showMultiSelectDropDown: false
      });
    }
  };

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('contextmenu', this.copyContentonRightClick);
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  /**
   * Setting up the window size based on the icon 
   */
  updateDimensions = async () => {
    await this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  /**
   * Copy the content on right click
   */
  copyContentonRightClick = async (event) => {
    if (event && event.target) {
      event.preventDefault();
      if (event.target.closest('td') && event.target.innerText.length !== 0) {
        this.clipToCopyBoard(event.target.innerText)
        this.getErrorMessage('Copied', "", "success")
        setTimeout(() => this.getErrorMessage('', "", "success"), 200)
      }
    }
  };


  /**
   * 
   * @param {String} type 
   * @param {Array} test 
   * @param {string} loading 
   * Setting up the table fields order to hide and show and arranging in sequence
   */
  getTableFieldsOrder = async (type, test, loading) => {
    this.setState({
      tablefieldsToShow: [],
      isLoading: true,
    })
    this.handlePercentage('1')
    let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    if (columns && columns.details) {
      columns = columns.details[this.state.selectedViewType];
    }
    if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
      let iconIndex = columns.findIndex(a => a.field === 'taskIcon')
      iconIndex !== -1 && columns.splice(iconIndex, 1)
    }
    if (this.props.type === "PropertyLoader") {
      let iconIndex = columns.findIndex(a => a.field === 'errorIcon')
      iconIndex !== -1 && columns.splice(iconIndex, 1)
    }
    if (this.state.screenPermissions !== "Edit") {
      const findIndex = columns.findIndex(a => a.field === 'Actions')
      findIndex !== -1 && columns.splice(findIndex, 1)
    }
    let tempTableFields = [];
    let frozenWidth = 0;
    let newTableFields = [];
    let staticTableFields = [...this.state.originalTableFields];

    //adding newly added fields directly to list prefereneces of user
    if (columns) {
      let newArrayFields = []
      for (let k = 0; k < staticTableFields.length; k++) {
        let y = columns.findIndex(u => u.field === staticTableFields[k].field)
        if (y === -1) {
          newArrayFields.push({
            field: staticTableFields[k].field,
            header: staticTableFields[k].header,
            width: staticTableFields[k].width,
            show: true
          })
        }
      }
      if (!this.props.isPresets) {
        columns = [...columns, ...newArrayFields]
      }
      let columns1 = JSON.parse(colOrder);
      if (columns1 && columns1.details) {
        columns1.details[this.state.selectedViewType] = columns;
      }
      localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columns1))

      let showFrozen = [];
      for (let m = 0; m < columns.length; m++) {
        if (columns[m].show) {
          showFrozen.push(m)
        }

      }
      
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = typeof columns[i].show === "string" && columns[i].show === "true" ? true
              : typeof columns[i].show === "string" && columns[i].show === "false" ? false :
                columns[i].show;
            pushItem.width = columns[i].width ? columns[i].width : staticTableFields[j].width;
            pushItem.displayInSettings = columns[i].displayInSettings;
            if ((this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.isFrozenColumns && (i == 0 || i == showFrozen[1] || i === showFrozen[2] || i === showFrozen[3])) {
              pushItem.frozen = true
              frozenWidth = frozenWidth + columns[i].width
            } else {
              pushItem.frozen = false
              // frozenWidth = 0
            }
            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.width = columns[i].width ? columns[i].width : staticTableFields[j].width;
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            if ((this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.isFrozenColumns && (i == 0 || i == 1 || i === showFrozen[2] || i === showFrozen[3])) {
              pushItem.frozen = true
              frozenWidth = frozenWidth + columns[i].width
            } else {
              pushItem.frozen = false
              frozenWidth = frozenWidth
            }
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
      if (this.props.type === "Property Editor" || this.props.type === "HighValueProperties") {
        let iconIndex = tempTableFields.findIndex(a => a.field === 'taskIcon')
        iconIndex !== -1 && tempTableFields.splice(iconIndex, 1)
        let taskicon = {
          "show": true,
          "textAlign": "left",
          "width": 50,
          "field": "taskIcon",
          "fieldType": "openConnectedTasks",
          "mobile": true,
          // "header": "Tasks",
          "displayInSettings": true,
          "filter": false,
          "sortable": false,
          frozen: this.state.isFrozenColumns ? true : false,
        }
        tempTableFields.splice(1, 0, taskicon);
        frozenWidth = this.state.isFrozenColumns ? frozenWidth + 50 : frozenWidth

      } else if (this.props.type === "PropertyLoader") {
        let iconIndex = tempTableFields.findIndex(a => a.field === 'errorIcon')
        iconIndex !== -1 && tempTableFields.splice(iconIndex, 4)
        let errorIcon = {
          "show": true,
          "textAlign": "left",
          "width": 120,
          "field": "errorIcon",
          "fieldType": "openErrorIcon",
          "mobile": true,
          // "header": "Tasks",
          "displayInSettings": true,
          "filter": false,
          "sortable": false,
          frozen: this.state.isFrozenColumns ? true : false,

        }
        tempTableFields.splice(1, 0, errorIcon);
        frozenWidth = this.state.isFrozenColumns ? frozenWidth + 50 : frozenWidth

      }

    } else {
      tempTableFields = this.state.originalTableFields
    }
  
    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      // newTableFields.map(item => {
      newTableFields.forEach(item => {

        // TableFieldsToShow.push(item)
        if (item.show && item.show === true && this.state.viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && this.state.viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }

    //for remove editing for previous weeks
    if (this.props.type === "Weekly Recovery Plan" || this.props.type === "ClaimsManagement") {
      TableFieldsToShow = await this.getCustumFieldOrderforRecoveryPlan(TableFieldsToShow)
    }
    frozenWidth = frozenWidth
    this.setState({
      frozenWidth: frozenWidth.toString() + "px",
      originalTableFields: this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" ? TableFieldsToShow : this.state.originalTableFields,
      tablefieldsToShow: this.props.type === "Company" && this.state.city === "Public" ? this.props.getPublicCompanies() : TableFieldsToShow,
    })

    //get call is not calling then need to restrict the loading
    var notcallingApiatinitial = ["PropertySearchv2","FactSet", "Deal Sizing Property Editor", "OpenCorporateBranch", "OpenCorporateBranchBatch", "OpenCorporateSearch", "Name Lookup", "Address Lookup", "Negative Notice List Viewer", "Deal Statistics", "Site Confirmation Editor", "Properties", "Property Editor", "OPRA2 Property Editor", "Deal Sizing-Properties", "Claims", "ClaimsManagement", "Deal Sizing Report For Companies", "Deal Sizing Report For Private Equity Portfolio"]

    if (notcallingApiatinitial.includes(this.props.type) || loading || this.props.loading || this.state.isLoading) {
      this.setState({
        isLoading: false,
      })
    }
    this.scrollWin();
    if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && localStorage.getItem("sellerFilter")) {
      this.custumApplyFilter()
    }
  }

  /**
   * applying the inline filters based on the table order and applying the values for filter
   */

  custumApplyFilter = () => {
    let criteria = JSON.parse(localStorage.getItem("sellerFilter"))
    if (criteria && criteria.criteria.length > 0) {
      for (let crt of criteria.criteria) {
        var shownField = document.getElementsByClassName(`p-column-filter-Deal Mgmt${crt.key}`)[0]
        if (shownField) {
          shownField.style.display = "block"
          shownField.children[0].value = crt.type//for type
          shownField.children[1].value = crt.value//for value
        }
        if (crt.type && crt.type.includes("date")) {
          document.getElementsByClassName(`p-column-filterMode-${crt.key}`)[0].selectedIndex = crt.type === "dateeq" ? 0 : crt.type === "datelt" ? 1 : crt.type === "dategt" ? 2 : crt.type === "datelte" ? 3 : crt.type === "dategte" ? 4 : crt.type === "datenin" ? 5 : crt.type === "datein" ? 6 : null
          document.getElementsByClassName(`p-column-filter p-column-${crt.key}`).value = new Date(decodeURIComponent(crt.value))
        }
      }


    }
    if (criteria && criteria.globalSearch) {
      document.getElementById("globalSearch").value = ""
    }
  }
  /**
   * 
   * @returns calling the confirmation model 
   */
  getConfirmaionModel() {
    return (
      <ConfirmaionModels
        openConfirmationModal={this.state.isApplyFilter}
        closeConfirmationModal={this.isConfirmFilter}
        onFilterChange={this.onFilterChange}
        filterInput={this.state.filteredCol}
        handleFilterInputField={this.handleFilterInputField}

      />
    )
  }

  /**
   * Calling the custom column order for weekly recovery plan based on conditions
   */

  getCustumFieldOrderforRecoveryPlan(TableFieldsToShow) {
    let index = TableFieldsToShow.findIndex(a => a.fieldType === "rowEditor")
    let deleteindex = TableFieldsToShow.findIndex(a => a.fieldType === "Actions")

    if (this.state.isPreviousWeek || window.location.pathname === "/reportPlan") {
      if (index !== -1) {
        TableFieldsToShow.splice(index, 1)
      }
      if (deleteindex !== -1) {
        TableFieldsToShow.splice(deleteindex, 1)
      }
    } else {
      if (this.state.screenPermissions === "Edit") {
        var field, delfield;
        if (this.props.editRequired) {
          field = {
            "show": true,
            "textAlign": "left",
            "width": 70,
            "fieldType": "rowEditor",
            "header": "Edit",
            "filter": false,
            "sortable": false
          }
          if (index === -1) {
            TableFieldsToShow.push(field);
          }
        }
        if (this.props.deleteRequired) {
          var delfield = {
            "show": true,
            "textAlign": "left",
            "width": 80,
            "header": "Delete",
            "filter": false,
            "sortable": false
          }
          if (deleteindex === -1) {
            TableFieldsToShow.push(delfield);
          }
        }
      }
    }
    return TableFieldsToShow
  }

  changeCheckIcon = async (index, subIndex, key, value) => {
    let tablefieldsToShow = this.state.tablefieldsToShow;
    if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
      let filterCriteria = Object.assign({}, this.state.filterCriteria);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj)
        let objValue = obj.value.find(y => y === value)
        let i = obj.value.indexOf(objValue);
        filterCriteria.criteria[objIndex]['value'].splice(i, 1);
        let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
        if (length === 0) {
          filterCriteria.criteria.splice(objIndex, 1)
        }
      }
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      await this.setState({
        tablefieldsToShow: tablefieldsToShow,
        filterCriteria: filterCriteria
      })
      await this.onMultiSelectFilterChange(key, value, 'pop', index)
    } else {
      let filterCriteria = Object.assign({}, this.state.filterCriteria);
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      await this.setState({
        tablefieldsToShow: tablefieldsToShow
      })
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj);
        await this.onMultiSelectFilterChange(key, value, 'push', objIndex)
      } else {
        await this.onMultiSelectFilterChange(key, value, 'push', index)
      }
    }
  }
  showDatatable = async (type) => {
    if (type) {
      await this.isShowTable(true)
    } else {
      await this.isShowTable(false)
    }
  }
  clearData = async () => {
    await this.setState({
      uppropConfirmationMessage: false, uppropPropertyIds: [],
    })
  }
  showDatatableClaimSummary = async (type) => {

    if (type) {
      await this.isShowTable(true)
    } else {
      await this.setState({ totalRecordsLength: 0 })
      await this.isShowTable(false)
    }
  }
  //getting Error Message from formModa;
  getErrorMessage = async (errorMessage, type, color) => {
    if (type === "removingSeller") {
      let counts = {
        Green: 0, Blue: 0, Teal: 0, Brown: 0, Yellow: 0, Black: 0, All: 0, PV: 0, SP: 0
      }
      await this.setState({
        allUsersData: [],
        PendingCount: 0,
        ConfirmedCount: 0,
        MissingCount: 0,
        Counts: counts,
        fileForOptions: [],
        lastScoredDate: "",
        IssueCodes: {},
        redirectSellerId: null,
        negativePeriod: null,
        hasPropertyCoOwner: "",
        propertyStatus: "",
        propertyStatusReason: "",
        confirmedOnsite: "",
        effectiveFromDate: "",
        reportedMissing: "",
        propertyStatus: "",
        matchMatchStatus: "",
        matchStatusReason: "",
        propertyReason: ""

      })
      if (this.props.type !== "Up Prop Confirmation" && this.editorComponentRef) {
        this.editorComponentRef.handlePropertiesEditChange("", "Cancel", "removingSeller")
      }
      if (this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
        await this.setState({
          hidePropValues: true
        })
      }
      this.isShowTable(false)
    }
    if (errorMessage && errorMessage.length > 0) {
      await this.setState({
        errorMessage: errorMessage,
        errorResponse: true,
        errorcolor: color === "error" ? "red" : "green"
      })
    } else {
      await this.setState({
        errorMessage: errorMessage,
        errorResponse: false,
        errorcolor: color === "error" ? "red" : "green"
      })
    }
    if (type === "selectedSeller" || type === "claimName") {
      await this.setState({
        errorMessage: "",
        errorResponse: false,

      })
    }
  }

  getSuccessMessage = async (successMessage, color) => {
    await this.setState({
      successMessage: successMessage,
      successResponse: true,
      color: "green"
    })
    if (localStorage.getItem("succ")) {
      localStorage.removeItem("succ")
    }
  }

  changeViewType = async (viewType) => {
    await this.setState({ viewType: viewType })
    await this.getTableFieldsOrder()
  }

  // Handle Table fields order of display in DataTable
  updateColumnsOrder(currentOrder) {
    this.setState({
      isLoading: true,
    })
    this.handlePercentage('1')
    let originalTableFields = [...this.state.originalTableFields];
    //  [...this.state.originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...this.state.originalTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          // originalTableFields.map(col => {
          originalTableFields.forEach(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  width: col.width,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        // unmatchedTableFields.map(col => {
        unmatchedTableFields.forEach(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              width: col.width,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false
            }
          )
        })
      }
    } else {
      originalTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            width: col.width,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile
          }
        )
      });
    }
    localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
    this.getTableFieldsOrder(this.state.originalTableFields);
  }

  // static getDerivedStateFromProps(props, state) {
  //   let storeData = store.getState()
  //   let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "MM-DD-YYYY"
  //   if (state.dateFormat !== dateFormat) {
  //     return { dateFormat: dateFormat };

  //   }
  //   return { dateFormat: dateFormat };

  // }

  getLoginRole() {
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.roleName) {
        this.setState({
          loginRole: loginData.roleName,
          loginData: loginData,
        });
      }
    }
  }
  updateReasonfromserver(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (item.ClaimStatus) {
        let ReasonsArray = [];
        if (item.claimReasonsRow.length > 0) {
          for (let claimReasonRo of item.claimReasonsRow) {
            ReasonsArray.push({ label: claimReasonRo, value: claimReasonRo })
          }
        }
        item.claimReasonsRow = ReasonsArray
        modifiedData.push(item);
      } else {
        item.claimReasonsRow = []
        modifiedData.push(item);
      }
    })
    return modifiedData
  }
  updateReason(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (item.ClaimStatus) {
        let ReasonsArray = [];
        let filterCriteria = {};
        filterCriteria['criteria'] = [{ key: 'Status', value: item.ClaimStatus, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
        return fetchMethodRequest('GET', `${apiCalls.ClaimReasons}?filter=${JSON.stringify(filterCriteria)}`)
          .then(async (response) => {
            if (response && response["claimReasons"].length > 0) {
              let claimReasons = response["claimReasons"]
              for (let reason of claimReasons) {
                ReasonsArray.push({ label: reason.reason, value: reason.reason })
              }
              item.claimReasonsRow = ReasonsArray
              modifiedData.push(item);
            } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
              await this.setState({
                sessionWarning: true,

              })
            } else {
              item.claimReasonsRow = []
              modifiedData.push(item);
            }
          }).catch((err) => {
            return err;
          })
      } else {
        item.claimReasonsRow = []
        modifiedData.push(item);
      }
    })
    return modifiedData
  }
  updateSlNoToData(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (this.props.flags) {
        let flags = this.props.flags
        if (item[flags['label']] === flags['value']) {
          modifiedData.push(item);
        }
      } else {
        if (this.state.first) {
          item.Sno = (index + 1) + this.state.first;
        } else {
          item.Sno = index + 1;
        }
        modifiedData.push(item);
      }
    });
    return modifiedData;
  }
  setTeam = async (DepartmentArray, departMentIdArray) => {
    await this.setState({ departMentIdArray: departMentIdArray })

  }
  //properties Summary Call
  getUsersSummaryData = async (formValues, addFilters, type) => {
    let data, filterCriteria = this.state.filterCriteria;
    if (formValues) {
      data = formValues
      this.setState({
        summarySelectedranges: formValues.ranges,
        summaryObject: formValues,
        addFilters: addFilters,
        isChangeHit: true,
        isLoading: true
      })
    } else {
      this.setState({
        isChangeHit: true,
        summarySelectedranges: this.props.getDataObj.ranges
      })
      data = this.props.getDataObj
    }

    let allUsersData = await this.props.getFormatedUsersData(data, filterCriteria, addFilters, type)
    this.setState({
      allUsersData: allUsersData,
      isLoading: false
    })
  }


  updateDateFormat(itemdata, dateFormat, from) {
    let tableFields = this.props.tabOptions ? this.props.getTableFields(this.state.activeTab) : this.props.getTableFields();
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tableFields.length; k++) {
        if ("Date" === tableFields[k]['fieldType']) {
          if (this.props.type !== "Property Editor" && this.props.type !== "RFI View") {
            if (itemdata[i][tableFields[k]['field']] !== null) {
              let time = moment(itemdata[i][tableFields[k]['field']]).utcOffset(dateFormats.isDstObserved(itemdata[i][tableFields[k]['field']]) ? '-05:00' : '-06:00');
              itemdata[i][tableFields[k]['field']] =
                this.props.type === "Company" || this.props.type === "List View" || this.props.type === "RFI View" ?
                  dateFormats.formatDate(
                    itemdata[i][tableFields[k]['field']],
                    tableFields[k]['dateFormat'] && !from ? tableFields[k]['dateFormat'] : dateFormat, "NS")
                  :
                  dateFormats.formatDate(
                    time,
                    tableFields[k]['dateFormat'] && !from ? tableFields[k]['dateFormat'] : dateFormat);
            }
          } else {
            if (itemdata[i][tableFields[k]['field']] !== null) {
              itemdata[i][tableFields[k]['field']] =
                dateFormats.formatDate(
                  itemdata[i][tableFields[k]['field']],
                  !from ? "MM/DD/yyyy" : dateFormat, 'NS');
            }
          }
        } else if ("Time" === tableFields[k]["fieldType"]) {
          let val = itemdata[i][tableFields[k]["field"]];
          if (val) {
            let time = moment(val).utcOffset(dateFormats.isDstObserved(val) ? '-05:00' : '-06:00');
            let dd = dateFormats.formatDate(time, !from ? "MM/DD/YYYY hh:mm a" : dateFormat);
            itemdata[i][tableFields[k]["field"]] = dd;
          }

        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }
  setDepartement = async (DepartmentArray, departMentIdArray) => {
    await this.setState({ selectedDepartment: DepartmentArray, departMentIdArray: departMentIdArray, Department: DepartmentArray })
  }

  /**
   * 
   * @param {Object} filterCriteria 
   * @param {string} type 
   * @param {bool} setGoButton 
   * @param {string} url 
   * @returns Getting the data from server after applying all the criteria
   */
  getDataAfterCriteria = async (filterCriteria, type, setGoButton, url) => {
    let apiUrl;
    if (type === 'refresh') {
      let tablefieldsToShow = this.state.tablefieldsToShow;
      if (tablefieldsToShow && tablefieldsToShow.length > 0) {
        for (let i = 0; i < tablefieldsToShow.length; i++) {
          let options = tablefieldsToShow[i].options;
          if (options && options.length) {
            for (let j = 0; j < options.length; j++) {
              options[j]['checkIcon'] = false
            }
          }
        }
      }
      this.setState({
        globalSearch: '',
        allUsersData: [],
        teamFilter: false,
        apiUrlforExport: "",
        status: '',
        roleType: '',
        // selectFilterValue: '',
        tablefieldsToShow: tablefieldsToShow
      });
    }
    if (!filterCriteria || !filterCriteria['criteria']) {
      // let filterCriteria = {};
      let limit = localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10
      filterCriteria = {
        limit: limit, page: 1, criteria: [], sortfield: this.props.sortField ? this.props.sortField : "created", direction: this.props.type === "Weekly Recovery Plan" ? 'asc' : 'desc'
      };
      if (this.props.noLimit) {
        delete filterCriteria['limit']
      }
      if (this.props.Limit) {
        filterCriteria['limit'] = this.props.Limit
      }
    }
    if ((this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State") && this.state.setPage) {
      filterCriteria.page = 1
    }

    let reportTypeEmpty = this.state.reportTypeEmpty

    if ((reportTypeEmpty === "ReadyToFile" || reportTypeEmpty === "OnHold" || reportTypeEmpty === "SellerNegativeNotice") && filterCriteria && filterCriteria['criteria']) {
      if (this.state.sourceStateEmpty) {
        filterCriteria["criteria"].push({
          key: "SourceState",
          value: [""],
          type: "in"
        })
      }
    }
    if ((reportTypeEmpty === "ReadyToFile" || reportTypeEmpty === "OnHold" || reportTypeEmpty === "SellerNegativeNotice") && filterCriteria && filterCriteria['criteria']) {
      if (this.state.sourceStateNull) {
        filterCriteria["criteria"].push({
          key: "SourceState",
          value: [null],
          type: "in"
        })
      }
    }

    if (type === 'refresh' && filterCriteria) {
      filterCriteria['criteria'] = this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" ? this.state.filterCriteria.criteria : [];
      // delete filterCriteria.globalSearch;
      if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
        filterCriteria = await this.getAdditionalCriteriaafterRefresh(filterCriteria);
      }
      if (this.props.noLimit) {
        delete filterCriteria['limit']
      }
      if (this.props.type == "OpenCorporateBranchBatch") filterCriteria['page'] = 1;
      if (this.props.Limit) {
        filterCriteria['limit'] = this.props.Limit
      }
      filterCriteria['direction'] = this.state.filterCriteria.direction;
      // filterCriteria['sortfield'] = this.props.sortField ? this.props.sortField : "created";
      if (this.props.type === "Company" && localStorage.getItem('SearchedCompany')) {
        localStorage.removeItem('SearchedCompany');
      }
    }
    if (type === "globalSearch") {
      if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
        filterCriteria = await this.getAdditionalCriteriaafterRefresh(filterCriteria);
      }
      delete filterCriteria.globalSearch;
      this.setState({
        selectFilterValue: '',
      })
    }
    if (this.paramsData && window.location.pathname !== "/nsproperties" && this.paramsData && window.location.pathname !== "/dealproperties" && window.location.pathname !== "/sellerNegativeNotice" && window.location.pathname !== "/ResearchRequestViewers" && !(window.location.href.includes('documentUpload')) && !(window.location.href.includes('availableDocuments'))) {      
      let params = this.paramsData
      for (let i in params) {
        if (i) {
          let obj = {}
          obj['key'] = i
          obj['value'] = params[i]
          obj['type'] = 'regexOr'
          filterCriteria['criteria'].push(obj)
        }
      }
    }

    if (this.props.type === "Site Confirmation Editor") {
      let statusIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "pendingConfirmation")
      if (statusIndex === -1) {
        filterCriteria['criteria'].push({ "key": "pendingConfirmation", "value": "Pending", "type": "eq", "add_Filter": true }
        )
      }
    }
    //applyng filter when a row item is deleted 
    if (filterCriteria === undefined) {
      filterCriteria = { limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10, page: 1, criteria: [], direction: 'desc' };
      filterCriteria['sortfield'] = this.props.sortField ? this.props.sortField : "created";
      if (this.props.noLimit) {
        delete filterCriteria['limit']
      }
      if (this.props.Limit) {
        filterCriteria['limit'] = this.props.Limit
      }
      apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
    }
    if (this.props.filterExtension) {
      apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${this.props.filterExtension}`
    }
    if (this.props.hasQueryInCall) {
      apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
    }
    else {
      if (this.props.type === "List View" || this.props.type === "RFI View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" || this.props.type === "Recovery Planner V2") {
        filterCriteria = await this.getCriteriaListView(filterCriteria)
        apiUrl = await this.getUrlwithFlags(url, type, filterCriteria)
        this.setState({
          filterCriteria: filterCriteria
        })

      } else {
        if (this.props.type === "ClaimsManagement") {
          filterCriteria = await this.getCriteriaUrlforClaims(apiUrl, filterCriteria)
        }
        if (this.props.noLimit) {
          delete filterCriteria['limit']
          delete filterCriteria['page']
        }
        if (this.props.Limit) {
          filterCriteria['limit'] = this.props.Limit
        }
        var dataurl, obj;
        if (localStorage.getItem("nspropertiesparamsUrl")) {
          dataurl = new URL(localStorage.getItem("nspropertiesparamsUrl"));
        } else {
          dataurl = new URL(window.location.href);
        }
        if (this.state.PropertyData && this.state.PropertyData.groupMessage && this.state.PropertyData.groupFlag) {
          let flag = this.state.PropertyData.groupFlag.slice(0, 6);
          filterCriteria.sortfield = `${flag}ParentPropertyID, PropertyReviewedFlag, ${this.state.PropertyData.groupFlag}, PropertyName, PropertyAddressLine1`
        }
        if (this.state.PropertyData && this.state.PropertyData.groupMessage && !this.state.PropertyData.groupFlag) {
          filterCriteria.sortfield = "ParentPropertyID, PropertyName, PropertyAddressLine1"
        }
        // if (this.state.PropertyData && this.state.PropertyData.groupMessage) {
        //   filterCriteria.sortfield = "ParentPropertyID, PropertyName, PropertyAddressLine1"
        // }
        if (this.props.type === "FactSet" && this.state.searchedCompany && this.state.searchedCompany !== "") {
          delete filterCriteria.sortfield;
          delete filterCriteria.direction;
        }
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        apiUrl = await this.getUrlbasedonrequirements(apiUrl, filterCriteria, url)
      }
    }
    if (this.props.type === "Validated Prop. By State") {
      apiUrl = `${apiUrl}&sellerID=${this.props.sellerID}`
    }

    if (this.state.validationStatus && this.state.validationStatus !== "all") {
      apiUrl = `${apiUrl}&validationStatus=${this.state.validationStatus}`
    }

    if (this.props.additionalFlag) {
      apiUrl = `${apiUrl}` + `${this.props.additionalFlag}`
    }

    if (this.props.type === "FactSet" && this.state.searchedCompany && this.state.searchedCompany !== "") {
      if (this.state.activeTab === "FactSet Entity Subsidiary Count") {
        apiUrl = `${apiUrl}&str=${this.state.searchedCompany}`
      } if (this.state.activeTab === "FactSet Entity Tree") {
        apiUrl = `${apiUrl}&ParentId=${this.state.tabNo}`
      } else {
        apiUrl = `${apiUrl}&FactSetEntityID=${this.state.tabNo}`
      }
    }
    if (this.props.type === "OpenCorporateBranchBatch") {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      let email;
      if (user.secondaryEmail) {
        email = user.secondaryEmail;
      } else if (!user.secondaryEmail && user.email) {
        email = user.email;
        if (email.includes("oprausers.onmicrosoft"))
          email = email.replace("oprausers.onmicrosoft", "oakpointpartners");
      }
      apiUrl = `${apiUrl}&email=${email}`;
    }
    if ((this.props.type === "OpenCorporateBranch" || this.props.type === "OpenCorporateBranchBatch") && this.state.searchedCompany && this.state.searchedCompany !== "") {
      apiUrl = `${apiUrl}&str=${encodeURIComponent(JSON.stringify(this.state.searchedCompany))}`
    }

    if (this.props.type === "OpenCorporateSearch" && this.state.searchedCompany) {
      let filtersQuery = ''
      for (let key in this.state.searchedCompany) {
        filtersQuery += `&${key}=${encodeURIComponent(this.state.searchedCompany[key])}`
      }
      apiUrl = `${apiUrl}${filtersQuery}`
    }

    if (this.props.type === "Name Lookup" || this.props.type === "Address Lookup") {
      apiUrl = `${apiUrl}&str=${this.state.searchedBusiness}&selectedStatus=${this.state.selectedStatus ? encodeURIComponent(this.state.selectedStatus.join(", ")) : ''}&selectedStatusDetails=${this.state.selectedStatusDetails ? encodeURIComponent(this.state.selectedStatusDetails.join(", ")) : ''}&type=${this.props.type === "Name Lookup" ? "name" : "address"}`
    }

    if (this.props.type === "Ready To File") {
      if (this.state.activeTab && this.state.activeTab === "Ready To File") {
        let StatusArray = [], statusReasonArrayReadyFile = [];
        let filterCriteria1 = {}
        filterCriteria1['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'screen', value: "Ready To File", type: "eq" }];
        await fetchMethodRequest('GET', `${apiCalls.AvailableToFile}?filter=${JSON.stringify(filterCriteria1)}`).then(async (response) => {
          if (response && response["statusConfigurations"] && response["statusConfigurations"].length > 0) {
            let propertyStatuses = response["statusConfigurations"]
            for (let status of propertyStatuses) {

              StatusArray.push(status.status)
              if (status.reasons && status.reasons.length > 0) {
                statusReasonArrayReadyFile.push(status.reasons)
              }
            }
            this.setState({ ReadyToFileStatus1: StatusArray, statusReasonArrayReadyFile: statusReasonArrayReadyFile })
          }
        })

        // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
        let obj = {}
        obj['key'] = "propertyStatus"
        obj['value'] = this.props.ReadyToFileStatus && this.props.ReadyToFileStatus.length > 0 ? this.props.ReadyToFileStatus : this.state.ReadyToFileStatus1 ? this.state.ReadyToFileStatus1 : []
        obj['type'] = 'in'
        obj["add_Filter"] = true
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
        if (index !== -1) {
          filterCriteria["criteria"].splice(index, 1)

        }
        filterCriteria['criteria'].push(obj)
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&reportType=ReadyToFile${this.state.postBody && this.state.postBody.departMentIdArray && this.state.postBody.departMentIdArray.length > 0 ? `&selectedDepartment=` + encodeURIComponent(this.state.postBody.departMentIdArray.join(", ")) : ""}`;
      }
    } else if (this.props.type === "On Hold") {
      if (this.state.activeTab && this.state.activeTab === "On Hold") {
        let obj = {}
        obj['key'] = "propertyStatus"
        obj['value'] = this.props.OnHoldStatus ? this.props.OnHoldStatus : []
        obj['type'] = 'in'
        obj["add_Filter"] = true
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
        if (index !== -1) {
          filterCriteria["criteria"].splice(index, 1)

        }
        filterCriteria['criteria'].push(obj)
        // filterCriteria['criteria'].push(obj)
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&reportType=OnHold${this.state.postBody && this.state.postBody.departMentIdArray && this.state.postBody.departMentIdArray.length > 0 ? `&selectedDepartment=` + encodeURIComponent(this.state.postBody.departMentIdArray.join(", ")) : ""}`;
        // if (this.state.postBody.departMentIdArray && this.state.postBody.departMentIdArray.length > 0) {
        //   apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.postBody.departmentIdArray && this.state.postBody.departmentIdArray.length > 0 ? this.state.postBody.departmentIdArray.join(", ") : this.state.postBody.departMentIdArray.join(", "))}`
        // }
      }
      // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
    }

    else if (this.props.type === "CA-N Properties") {
      if (this.state.activeTab && this.state.activeTab === "CA-N Properties") {
        let obj = {}
        obj['key'] = "propertyStatus"
        obj['value'] = this.props.CANProperties ? this.props.CANProperties : []
        obj['type'] = 'in'
        obj["add_Filter"] = true
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
        if (index !== -1) {
          filterCriteria["criteria"].splice(index, 1)

        }
        filterCriteria['criteria'].push(obj)
        // filterCriteria['criteria'].push(obj)
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&reportType=CA-N${this.state.postBody && this.state.postBody.departMentIdArray && this.state.postBody.departMentIdArray.length > 0 ? `&selectedDepartment=` + encodeURIComponent(this.state.postBody.departMentIdArray.join(", ")) : ""}`;
      }
      // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
    }
    else if (this.props.type === "Seller Negative Notice") {
      if (this.state.activeTab && this.state.activeTab === "Seller Negative Notice") {
        let obj = {}
        obj['key'] = "propertyStatus"
        obj['value'] = this.props.SellerNegativeNoticeStatus ? this.props.SellerNegativeNoticeStatus : []
        obj['type'] = 'in'
        obj["add_Filter"] = true
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
        if (index !== -1) {
          filterCriteria["criteria"].splice(index, 1)

        }
        filterCriteria['criteria'].push(obj)
        // filterCriteria['criteria'].push(obj)
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&reportType=SellerNegativeNotice${this.state.postBody && this.state.postBody.departMentIdArray && this.state.postBody.departMentIdArray.length > 0 ? `&selectedDepartment=` + encodeURIComponent(this.state.postBody.departMentIdArray.join(", ")) : ""}`;
      }
      // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
    }
    else if (this.props.type === "Resource Needed") {
      if (this.state.activeTab && this.state.activeTab === "Resource Needed") {
        let obj = {}
        obj['key'] = "PropertyStatus"
        obj['value'] = this.props.ResourceNeededStatus ? this.props.ResourceNeededStatus : []
        obj['type'] = 'in'
        obj["add_Filter"] = true
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyStatus")
        if (index !== -1) {
          filterCriteria["criteria"].splice(index, 1)

        }
        filterCriteria['criteria'].push(obj)
        // filterCriteria['criteria'].push(obj)
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      this.setState({ postBodyFile: {} })
      // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
    } else if (this.props.type === "Grouped By Deal") {
      if (this.state.activeTab && this.state.activeTab === "Grouped By Deal") {

        // filterCriteria['criteria'].push(obj)
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&groupField=Deal`;
      }
      // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
    }
    else if (this.props.type === "Grouped By Deal/State") {
      if (this.state.activeTab && this.state.activeTab === "Grouped By Deal/State") {

        // filterCriteria['criteria'].push(obj)
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&groupField=Deal,SourceState`;
      }
      // apiUrl = `${apiUrl}&matchStatus=Validated-Ready to File`
    } else if (this.props.type === "HighValueProperties") {
      let index = filterCriteria && filterCriteria["criteria"] ? filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount") : null
      // if (index !== -1) filterCriteria["criteria"].splice(index, 1)
      if (index !== -1) {
        // filterCriteria["criteria"].splice(index, 1)

      } else {
        if (!this.state.postBody) {
          filterCriteria["criteria"].push({ key: "PropertyMaxValueAmount", value: 50000, type: "gte" })
        }
        let scoreindex = filterCriteria && filterCriteria["criteria"] ? filterCriteria["criteria"].findIndex(obj => obj.key === "NameMatchScore") : null
        if (scoreindex !== -1) {
        } else {
          if (!this.state.postBody) {
            filterCriteria["criteria"].push({ key: "NameMatchScore", value: 90, type: "gte" })
          }
        }
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      let statusindex = filterCriteria && filterCriteria["criteria"] ? filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatus") : null
      if (statusindex !== -1) {
      } else {
        if (!this.state.postBody) {
          let data = await this.props.getDefaultStatusArray()
          filterCriteria["criteria"].push({ key: "propertyStatus", value: data.statuses, type: "in" })
          filterCriteria["criteria"].push({ key: "propertyStatusReason", value: data.reasons, type: "in" })

        }
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      let indexDataSource = filterCriteria && filterCriteria["criteria"] ? filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource") : null
      // if (index !== -1) filterCriteria["criteria"].splice(index, 1)
      if (indexDataSource !== -1) {
        // filterCriteria["criteria"].splice(indexDataSource, 1)
      } else {
        filterCriteria["criteria"].push({ key: "PropertyDataSource", value: this.state.StateDataSource ? this.state.StateDataSource : [], type: "in" })
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }


    }
    if (this.props.type === "Activity Details Reports" && this.state.departMentIdArray && this.state.departMentIdArray.length > 0) {
      apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.departmentIdArray && this.state.departmentIdArray.length > 0 ? this.state.departmentIdArray.join(", ") : this.state.departMentIdArray.join(", "))}`
    }


    if (this.props.type === "ClaimSummary") {
      filterCriteria.sortfield = this.state.clearFiltersGroupBy
    }
    if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
      localStorage.setItem("sellerFilter", JSON.stringify(filterCriteria))
    } else {
      localStorage.setItem("sellerFilter", null)

    }
    await this.setState({
      apiUrlforExport: apiUrl
    })

    return apiUrl;
  }
  closeDataTable = async () => {
    this.isShowTable(false)
    await this.setState({ apiUrlforExport: null })
  }

  /**
   * 
   * @param {Object} filterCriteria 
   * @param {string} type 
   * @param {bool} setGoButton 
   * @param {Object} bodyData 
   * @returns get table list data from server with filters if any
   */
  getDataFromServer = async (filterCriteria, type, setGoButton, bodyData) => {
    if (this.state.PropertyData && (this.state.PropertyData.selectedProperty_Id || this.state.PropertyData.selectedOpraStId)) {
      bodyData = {
        propertyId: this.state.PropertyData.selectedProperty_Id ? this.state.PropertyData.selectedProperty_Id.toString() : null,
        statePropertyId: this.state.PropertyData.selectedOpraStId ? this.state.PropertyData.selectedOpraStId.toString() : null
      }
    }
    let url;
    if (this.props.type === "Company" && this.state.city === "Public") {
      url = apiCalls.publicCompanies
    } else {
      url = this.getAPIUrl();
    }
    if ((this.props.type === "AvailableToMap") && !this.state.postBody) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "ConfirmedOnsite")
      if (index === -1) {
        filterCriteria.criteria.push({ key: "ConfirmedOnsite", value: true, type: "eq" })
      }
      if (this.props.settingsData && this.props.settingsData.excludePossiblyDefaultEnable) {
        let indexExclude = filterCriteria["criteria"].findIndex(obj => obj.key === "ExcludePossiblyPaid")
        if (indexExclude === -1) {
          filterCriteria.criteria.push({ key: "ExcludePossiblyPaid", value: true, type: "eq" })
        }
      }
    }
    if (type === "dateQuery") {
      await this.setState({
        dateQuery: filterCriteria
      })
    } else if (type === "resetDateQuery") {
      await this.setState({
        dateQuery: null
      })
    }
    let SearchCompanyID = localStorage.getItem("SearchedCompany")
    if (SearchCompanyID && SearchCompanyID.length > 0) {
      document.getElementById("globalSearch").value = SearchCompanyID
    }
    localStorage.removeItem("ISEdited")
    if (this.props.type === "Company" && SearchCompanyID && SearchCompanyID.length > 0 && SearchCompanyID !== undefined) {
      filterCriteria.globalSearch = {
        value: encodeURIComponent(SearchCompanyID),
        type: this.props.globalSearchFieldName
      };
      await this.setState({
        hidePropValues: false
      })
    }
    if (url) {
      let apiUrl, Counts = [];
      this.setState({
        progress: 0,
        errorMessage: "",
        errorResponse: false,
        isLoading: true,
        apiUrlforExport: "",
        selectedRows: '',
        isPendingConfirmation: "",
      });
      await this.handlePercentage('1')

      apiUrl = await this.getDataAfterCriteria(filterCriteria, type, setGoButton, url)
      if (this.props.type === "AvailableToMap" && this.state.activeTab === "High Name Score") {
        apiUrl = apiUrl + `&reportType=HighNameScore`
      }
      if (this.props.type === "AvailableToMap" && this.state.activeTab === "High Address Score") {
        apiUrl = apiUrl + `&reportType=HighAddressScore`
      }
      if (bodyData && bodyData.departMentIdArray) {
        if (this.props.tabCarrying && this.props.tabCarrying === "available") {
          let params = apiUrl.split("&")
          let queryparams = params.filter(elem => elem.includes("selectedDepartment=") === false)
          apiUrl = queryparams.join("&")
        }
        apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(bodyData.departMentIdArray.join(", "))}`
      }
      let user = JSON.parse(localStorage.getItem('loginCredentials'));


      if ((this.props.type === "Ready To Review" || this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties") && this.state.departMentIdArray && this.state.departMentIdArray.length > 0 && !(bodyData && bodyData.departMentIdArray) && user && user.roleName && !user.roleName.includes("CoreOps")) {
        apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.departMentIdArray.join(", "))}`
      } else if ((this.props.type === "Recovery Planner V2" || this.props.type === "Ready To Review" || this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap" || this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" || this.props.type === "UP Opportunity – V2" || this.props.type === "AvailableToMap" || this.props.type === "Required Evidence" || this.props.type === "HighValueProperties") && this.state.departMentIdArray && this.state.departMentIdArray.length > 0 && !(bodyData && bodyData.departMentIdArray)) {
        apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.departMentIdArray.join(", "))}`
      } else if (this.props.type === "Resource Needed" && this.state.resourceNeededDepartment && this.state.resourceNeededDepartment.length > 0 && !(bodyData && bodyData.departMentIdArray) && user && user.roleName && !user.roleName.includes("CoreOps")) {
        apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.resourceNeededDepartmentId.join(", "))}`

      }

      if (this.state.departmentIdArray && this.state.departmentIdArray.length > 0) {
        const urlSearchParams = new URLSearchParams(apiUrl);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params.selectedDepartment) {
          apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.departmentIdArray.join(", "))}`
        }
      }
      if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
        let index = filterCriteria && filterCriteria["criteria"] ? filterCriteria["criteria"].findIndex(obj => obj.type === "fuzzyregexOr") : null
        // if (index !== -1) filterCriteria["criteria"].splice(index, 1)
        if (index !== -1) {
          apiUrl = `${apiUrl}&wildCard=${true}`
        }
      }

      let method, body = {};
      if (this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties" || this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State") {
        bodyData = this.state.postBodyFile ? this.state.postBodyFile : {}
      }
      if (this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap" || this.props.type === "Ready To Review" || this.props.type === "UP Opportunity – V2" || this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties" || this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" || this.props.type === "Property Editor"||this.props.type === "Deal Sizing Property Editor") {
        body = typeof bodyData !== "string" && bodyData !== undefined ? bodyData : this.state.postBody && this.state.postBody !== undefined ? this.state.postBody : body
        if (this.state.postBody && this.state.postBody.hitGoButton) {
          body.dealType = this.state.postBody.dealType
        } else if (this.props.type !== "AvailableToMap" && this.props.type !== "AvailableToReMap" && this.props.type !== "Ready To Review" && this.props.type !== "Property Editor" && this.props.type !== "Deal Sizing Property Editor"&&this.props.type !== "UP Opportunity – V2" && this.props.type !== "Grouped By Deal" && this.props.type !== "Grouped By Deal/State" && this.props.type !== "Ready To File" && this.props.type !== "On Hold" && this.props.type !== "Seller Negative Notice" && this.props.type !== "CA-N Properties") {
          body.dealType = ["BK", "CR"]
        }
        if (this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap" || this.props.type === "UP Opportunity – V2" || this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State") {
          body.dealType = this.state.postBody && this.state.postBody.dealType ? this.state.postBody.dealType : ["BK", "CR"]
        }
        // if (this.state.postBody && this.state.postBody.departMentIdArray) {
        //   body.selectedDepartment = this.state.postBody.departMentIdArray
        // }

        method = "POST"
        delete body.hitGoButton
        body = body
      } else {
        method = "GET"
      }
      this.setState({
        apiUrlforExport: apiUrl,
        postBodyFile: body
      });

      if (apiUrl && apiUrl.length) {
        return fetchMethodRequest(method, apiUrl, body)
          .then(async (response) => {
            if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor") {
              if (setGoButton) {
                if (this.propertyFilterRef) { this.propertyFilterRef.setGoButton() }
                if (this.dealSizingpropertyFilterRef) { this.dealSizingpropertyFilterRef.setGoButton() }
              }
            }
            let apiResponseKey = this.props.type === "OpenCorporateSearch" ? "companies" : this.state.city === "Public" ? "publicCompanies" : this.props.apiResponseKey;

            let selectedData = this.getSearchedCompany()
            if (selectedData && selectedData.company) {
              apiResponseKey = "companies"
            } else if (selectedData && selectedData.feinLeiEin) {
              apiResponseKey = "feinLeiEin"
            } else if (selectedData && selectedData.address) {
              apiResponseKey = "addresses"
            }
            if (this.props.type === "OpenCorporateBranchBatch") {
              apiResponseKey = "results"
            }
            let sessionexpired = await localStorage.getItem('sessionexpired')
            if (sessionexpired === "true") { await this.setState({ sessionExpiryModal: true }) }
            let responseData = [], totalRecordsLength = this.state.totalRecordsLength;
            if (response && response.details && response.details.length > 0) {
              responseData = response.details;
              totalRecordsLength = response.details.length;
            } else if (response && response['errorMessage']) {
              await this.getErrorResponseFromServer(response)
              this.setState({
                isLoading: false
              })
            }
            let IssueCodes = {}, defaultIssueNames = []
            if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
              let nameIssueCodes = [], newNameIssueCodes = []
              if (response.nameIssueCodes && response.nameIssueCodes.length > 0) {
                for (var item of response.nameIssueCodes) {
                  nameIssueCodes.push({ label: item === null ? "N/A" : item, value: item })
                  // if (item !== "5" && item !== "7" && item !== "2" && item !== "6") {
                  //   defaultIssueNames.push(item)
                  // }
                }
              }
              if (response.newNameIssueCodes && response.newNameIssueCodes.length > 0) {
                for (var obj of response.newNameIssueCodes) {
                  newNameIssueCodes.push({ label: obj, value: obj })
                }
              }
              IssueCodes.nameIssueCodes = nameIssueCodes
              IssueCodes.newNameIssueCodes = newNameIssueCodes
              IssueCodes.defaultIssueNames = []
            }
            if (this.props.type === "Deal Statistics") {
              await this.setDownloadData();
            }
            if (this.props.type === "PropertySearchv2" && this.propertyFilterv2Ref) {
              await this.propertyFilterv2Ref.setResultSet(response.totalCount)
            }
            if (apiResponseKey) {
              if ((type === null || type !== "column_filter") && this.state.activeTab !== "SP") {
                this.clickCount(response, this.props.type)
              }
              if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
                if (response.pagination && response.pagination.totalCount) {
                  totalRecordsLength = response.pagination.totalCount;
                }
                if (this.props.type === "PropertySearchv2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Properties" || this.props.type === "Site Confirmation Editor" || this.props.type === "Deal Sizing Report For Private Equity Portfolio" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "ClaimSummary") {
                  this.isShowTable(true)
                }
                responseData = response[apiResponseKey]
                if (this.state.PropertyData && this.state.PropertyData.groupFlag && this.state.activeTab === "SP") {
                  responseData = await this.props.getUpdatedResponseforParent(responseData, this.state.PropertyData.groupFlag);
                }
                responseData = this.updateSlNoToData(responseData);
                responseData = this.updateDateFormat(responseData, this.state.dateFormat);
              }
              if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount === 0)) {
                totalRecordsLength = response.pagination.totalCount;
                if (this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Properties") {
                  // if (setGoButton)
                  if (this.propertyFilterRef) { this.propertyFilterRef.setGoButton() }
                  if (this.dealSizingpropertyFilterRef) { this.dealSizingpropertyFilterRef.setGoButton() }
                }
              }

              if ((this.props.type === "Properties" && totalRecordsLength > 1000) || ((this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") && totalRecordsLength > 10000)) {
                this.isShowTable(false)
              }

              if (this.props.type === "Deal Sizing Report For Companies" && response.deal_sizing_reports.length === 0) {
                this.isShowTable(true)
              }
              if (this.props.type === "Manually Loaded Properties" || this.props.type === "Negative Notice List Viewer") {
                this.isShowTable(true)
              }
            }
            let colunHeight
            if (this.props.frozen && this.state.isFrozenColumns) {
              colunHeight = document.getElementsByClassName("p-datatable-thead")[1].clientHeight
            }
            await this.applyCustomChanges(responseData, response)

            if (this.props.type === "FactSet") {
              this.setState({
                FactSetCount: responseData.length
              })
            }
            if (this.paramsData && this.paramsData.taskID && responseData && responseData[0] && this.paramsData.taskID && window.location.pathname !== "/nsproperties" && this.props.type !== "Negative Notice List Viewer" && this.props.type !== "Site Confirmation Editor") {
              window.history.pushState(null, null, window.location.pathname);

              this.setState({
                formType: "edit",
                selectedObj: responseData[0],
                isOpenRFIModal: true
              })
              this.paramsData = null
            }
            if (this.props.noLimit) {
              this.setState({ rows: totalRecordsLength })
            }
            this.setState({
              progress: 100,
              responsesaveData: JSON.parse(JSON.stringify(responseData)),
              IssueCodes: IssueCodes,
              colunHeight: colunHeight,
              allUsersData: responseData,
              response: response,
              isLoading: false,
              disableIcon: false,
              isDisabledTab: false,
              defaultExclusionNote: response.defaultExclusionNote ? response.defaultExclusionNote.replace('Inclusions / Exclusions Appear here :', '') : "",
              lastScoredDate: response.lastScoredDate ? dateFormats.formatDate(moment(response.lastScoredDate).utcOffset(dateFormats.isDstObserved(response.lastScoredDate) ? '-05:00' : '-06:00'), "MM/DD/YYYY hh:mm A CT") : response.lastUpdatedDate ? dateFormats.formatDate(moment(response.lastUpdatedDate).utcOffset(dateFormats.isDstObserved(response.lastUpdatedDate) ? '-05:00' : '-06:00'), "MM/DD/YYYY hh:mm A CT") : "",
              totalRecordsLength: totalRecordsLength,
              showAddButton: true
            })
            return responseData;
          }).catch((err) => {
            // this.getErrorMessage(config.serverErrMessage)
            return err;
          });

      }
    } else {
      this.setState({
        isLoading: false,
      })
    }
  }

  /**
    * 
    * @returns setting the data for downlading the data for export
    */
  setDownloadData = async () => {
    let data, dataSummary
    let apiUrl = `nsScoredProperties/DealStatisticsSummary?filter=${JSON.stringify(this.state.SummaryCriteria)}&SellerID=${this.state.sellerId}&groupField=${this.state.groupByField}&type=exportToCsv`
    if (this.state.validationStatus && this.state.validationStatus !== "all") {
      apiUrl = `${apiUrl}&validationStatus=${this.state.validationStatus}`
    }
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let downloadData = []
          var headers_1 = []
          if (response && response.summary && response.summary.result) {
            dataSummary = await this.updateDateFormat(response.summary.result, "yyyy/MM/DD", "exportExcel");
            let obj = {
              name: "Summary",
              headers: this.setHeadersforxlsx("Summary"),
              data: await this.formatChange(dataSummary)
            }

            downloadData.push(obj)
          }
          if (response && response.data && response.data.ns_scored_propertys) {
            data = await this.updateDateFormat(response.data.ns_scored_propertys, "yyyy/MM/DD", "exportExcel");
            let obj = {
              name: "Data",
              headers: response.data.ns_scored_propertys && response.data.ns_scored_propertys.length === 0 ? [] : Object.keys(response.data.ns_scored_propertys[0]),
              data: await this.formatChange(data)
            }
            downloadData.push(obj)
          }

          await this.dealStatsFilterRef.getDownloadData(downloadData)

        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })

  }


  /**
   * 
   * @param {array} t 
   * expanding the rows  
   */
  expandAll(t) {
    let rowDataValues = this.expandDataAll(t)
    this.setState({
      expandedRows: rowDataValues
    });
  }
  /**
   * 
   * @param {array} t 
   * closing the rows
   */
  closeAll = async (t) => {
    let rowDataValues = await this.closeDataAll(t)
    this.setState({
      expandedRows: rowDataValues
    });
  }
  /**
   * 
   * @param {array} t 
   * @returns making every individual row with special id to close
   */
  closeDataAll = async (t) => {
    let expandedRows = {};
    t.forEach(element => {
      if (element.ChildCount > 0) {

        // expandedRows[`${element.DealID}`] = true;
        expandedRows = null;
      }
    });
    return expandedRows;
  }
  /**
   * 
   * @param {array} t 
   * @returns making every individual row with special id to expand
   */
  expandDataAll(t) {
    let expandedRows = {};
    t.forEach(element => {
      if (element.ChildCount > 0) {
        // expandedRows[`${element.DealID}`] = true;
        expandedRows[`${element.OppClaimID}`] = true;
      }
    });
    return expandedRows;
  }
  /**
   * 
   * @param {object} url 
   * setting the properties to datatable to dispaly in table for manual matching
   */
  showMatchedResponses = (url) => {
    let responseKey = url.claims ? "claims" : "properties"
    if (url && url[responseKey] && url.pagination) {
      this.updateDateFormat(url[responseKey], "MM/DD/YYYY")
      this.setState({
        isLoading: false,
        allUsersData: url[responseKey],
        totalRecordsLength: url.pagination.totalCount
      })
    } else {
      this.setState({
        isLoading: false,
        allUsersData: []
      })
    }
  }

  /**
   * 
   * @param {Array} responseData 
   * @param {objects} response 
   * setting up the counts based on response for property editor
   */
  applyCustomChanges = async (responseData, response) => {

    if ((this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report") && responseData && responseData.length > 0) {
      this.setWeeklyPlanChanges(responseData)
      if (this.props.tableFooter) {
        this.setState({
          footer: await this.props.tableFooter(responseData)
        })
      }

    }
    if (this.state.softWarning) {
      setTimeout(() => this.setState({ softMessage: "", softWarning: false }), 30000);
    }
    if ((this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Queue") && response.counts) {
      let counts = response.counts
      counts.Brown = counts.Brown !== undefined ? counts.Brown : ">100"
      counts.Black = counts.Black !== undefined ? counts.Black : ">100"
      await this.setState({
        Counts: response.counts
      })
    }
    if (this.props.type === "Deal Sizing Report For Companies") {
      if (response) {
        this.props.getReportSummaryDetail(response)
      }
      if (response.lastUpdatedDate) {
        if (this.propertyFilterRef) { this.propertyFilterRef.lastUpdatedDate(response.lastUpdatedDate) }
        if (this.dealSizingpropertyFilterRef) { this.dealSizingpropertyFilterRef.lastUpdatedDate(response.lastUpdatedDate) }
      }
    }

    localStorage.setItem('responsesaveData', JSON.stringify(responseData))

  }


  /**
   * return Counts
   */
  getCountstoreturn = () => {
    return this.state.Counts
  }


  /**
   * 
   * @param {object} response 
   * Setting up the error message for dispalying and disabling the datatable view
   */
  getErrorResponseFromServer = async (response) => {

    if (response && response['errorMessage'] === configMessages.warningMessage) {
      this.setState({
        sessionWarning: true,
        errorMessage: null
      })
    } else if (response && response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
      this.setState({
        sessionExpiryModal: true,
        errorMessage: null
      })
    } else if (response && response['errorMessage']) {
      this.getErrorMessage(response.errorMessage, "", "error")
      if (this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") {
        await this.setState({
          totalRecordsLength: 0,
          allUsersData: [],
          // errorMessage: null
        })
        await this.isShowTable(false)
      }
      if (this.props.type == "OpenCorporateBranch") {
        this.OpenCorporateFilterRef.setResData(true, response['errorMessage'])
        await this.setState({
          displayErrorMessage: true,
          errorMessage: response['errorMessage']
        });
      }
      // else if (response && response['errorMessage'] && (this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties")) {
      //   await this.setState({
      //     totalRecordsLength: 0,
      //   })
      //   await this.isShowTable(false)
      // }
    }
  }

  /**
   * 
   * @param {object} filterCriteria 
   * @returns Setting up the additional criteria for the property editor  for buckets 
   */
  getAdditionalCriteriaafterRefresh = async (filterCriteria) => {
    let index = filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
    if (index !== -1) filterCriteria["criteria"].splice(index, 1)
    filterCriteria['criteria'].push({
      key: "bucket",
      value: this.state.activeTab,
      type: "eq",
      add_Filter: true
    })
    if (this.state.PropertyData && this.state.PropertyData.selectedState) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "sourceState")
      if (index !== -1) filterCriteria["criteria"].splice(index, 1)
      filterCriteria['criteria'].push({
        key: this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" ? "sourceState" : this.props.type === "Deal Sizing-Properties" ? "sourceState" : "property_StateId",
        value: this.state.PropertyData.selectedState,
        type: "in"
      })
    }

    return filterCriteria;
  }


  /**
   * 
   * @param {url} apiUrl 
   * @param {object} filterCriteria 
   * @returns setting up the criteria for url in claims taking the details from local storage
   */
  getCriteriaUrlforClaims = async (apiUrl, filterCriteria) => {

    if (this.state.PropertyData && this.props.type === "ClaimsManagement") {
      let obj = this.state.PropertyData
      if (obj.selectedClaims.EstateID) {
        localStorage.setItem("claimSummaryID", obj.selectedClaims.EstateID)
        localStorage.setItem("claimSummaryName", obj.selectedClaims.EstateName)
        localStorage.setItem("hitback", "no")
        let index = filterCriteria.criteria.findIndex(obj => obj.key === "CompanyID")
        if (index !== -1)
          filterCriteria["criteria"].splice(index, 1)

        filterCriteria.criteria.push({
          key: "CompanyID",
          value: obj.selectedClaims.EstateID,
          type: "eq",
          add_Filter: true
        })

      }
      if (obj.selectedClaims.BusinessNameID) {
        let index = filterCriteria.criteria.findIndex(obj => obj.key === "BusinessNameID")
        if (index !== -1)
          filterCriteria["criteria"].splice(index, 1)

        filterCriteria.criteria.push({
          key: "BusinessNameID",
          value: obj.selectedClaims.BusinessNameID,
          type: "eq",
          add_Filter: true
        })
      }
      if (obj.selectedClaims.selectedOpraStClaimId) {
        let index = filterCriteria.criteria.findIndex(obj => obj.key === "StateClaimID")
        if (index !== -1)
          filterCriteria["criteria"].splice(index, 1)

        const results = obj.selectedClaims.selectedOpraStClaimId.map(element => {
          return element.trim();
        });
        filterCriteria.criteria.push({
          key: "StateClaimID",
          value: results,
          type: "in"
        })
      }
      if (obj.selectedClaims.selectedOpraClaimIds) {
        let index = filterCriteria.criteria.findIndex(obj => obj.key === "OppClaimID")
        if (index !== -1)
          filterCriteria["criteria"].splice(index, 1)

        const results = obj.selectedClaims.selectedOpraClaimIds.map(element => {
          return element.trim();
        });
        filterCriteria.criteria.push({
          key: "OppClaimID",
          value: results,
          type: "in"
        })
      }
      if (obj.selectedClaims.selectedOpraStId) {
        let index = filterCriteria.criteria.findIndex(obj => obj.key === "PropertyID")
        if (index !== -1)
          filterCriteria["criteria"].splice(index, 1)
        const results = obj.selectedClaims.selectedOpraStId.map(element => {
          return element.trim();
        });
        filterCriteria.criteria.push({
          key: "PropertyID",
          value: results,
          type: "in"
        })
      }
      if (obj.selectedClaims.setectedStOpraId) {
        let index = filterCriteria.criteria.findIndex(obj => obj.key === "StatePropertyID")
        if (index !== -1)
          filterCriteria["criteria"].splice(index, 1)
        const resultsData = obj.selectedClaims.setectedStOpraId.map(element => {
          return element.trim();
        });
        filterCriteria.criteria.push({
          key: "StatePropertyID",
          value: resultsData,
          type: "in"
        })
      }
      await this.setState({
        filterCriteria: filterCriteria
      })
    }

    return filterCriteria;
  }
  /**
   * 
   * @param {string} apiUrl 
   * @param {Object} filterCriteria 
   * @param {string} url 
   * @returns Setting up the criteria to send to api
   */
  getUrlbasedonrequirements = async (apiUrl, filterCriteria, url) => {
    if (this.props.type === "Properties" || this.props.type === "Site Confirmation Editor" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Deal Sizing Report For Private Equity Portfolio" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "ClaimSummary") {
      apiUrl = await this.setFlagsforProperties(apiUrl)
      if (this.editorComponentRef) {
        this.editorComponentRef.downloadData(apiUrl, this.state.tablefieldsToShow)
      }
      await this.setState({
        apiUrlforExport: apiUrl
      })
    }
    if (this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity") {
      apiUrl = await this.getCustomisedRecoveryUrl(url, filterCriteria)
    }
    // if (this.props.type === "Company" && this.state.isTopFilter) {
    //   apiUrl = `${apiUrl}&isTopFilter=true`
    // }
    if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
      apiUrl = `${apiUrl}&ScoringStatus=In Progress`
    } if (this.props.type === "Manually Loaded Properties") {
      apiUrl = await this.ManuallyLoadedRef.ManuallyLoadedRefValues(apiUrl)
      await this.setState({
        apiUrlforExport: apiUrl
      })
    } if (this.props.type === "Utilization - Integrity" || this.props.type === "Utilization - US Team") {
      apiUrl = await this.getReportsUrl(apiUrl)
      if (this.state.seletedSellerType) {

        apiUrl = `${apiUrl}&company=${this.state.seletedSellerType}`
      }
    }
    if (this.props.type === "Deal Statistics" && this.state.activeTab === "Summary") {
      apiUrl = `${apiUrl}&groupField=${this.state.groupByField}`
    }
    if (this.props.type === "Deal Statistics" && this.state.activeTab === "Data") {
      apiUrl = `${apiUrl}&sellerID=${this.state.sellerId}`
      await this.setState({
        apiUrlforExport: apiUrl
      })
    }
    return apiUrl;
  }
  /**
   * 
   * @param {string} apiUrl 
   * @returns setting up the api url based on the url for reports
   */
  getReportsUrl = async (apiUrl) => {
    if (window.location.href.includes("integrity")) {
      apiUrl = `${apiUrl}&team=Integrity`
    } else if (window.location.href.includes("executive")) {
      apiUrl = `${apiUrl}&team=US`
    }

    return apiUrl;
  }

  /**
   * 
   * @param {bool} e 
   * disabling and enabling the datatable by sending the flag
   */

  isShowTable = (e) => {
    let counts = {
      Green: 0, Blue: 0, Teal: 0, Brown: 0, Yellow: 0, Black: 0, All: 0, PV: 0, SP: 0
    }

    this.setState({
      Counts: e === false ? counts : this.state.Counts,
      isShowTable: e,
    })

    if (e === false) {
      this.setState({
        hasPropertyCoOwner: "",
        propertyStatus: "",
        propertyStatusReason: "",
        confirmedOnsite: "",
        effectiveFromDate: "",
        reportedMissing: "",
        propertyStatus: "",
        matchMatchStatus: "",
        matchStatusReason: "",
        propertyReason: ""
      })

    }
  }
  /**
   * 
   * @param {object} filterCriteria 
   * @returns Setting up the criteria for list view for assigning the required parameters
   */

  getCriteriaListView = async (filterCriteria) => {
    if (this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View") {
      if (!this.state.userRole && this.state.loginCredentials) {
        filterCriteria['criteria'].push({
          "key": "assignToId",
          "value": this.state.loginCredentials._id,
          "type": "eq"
        }
        )
      }
      var defstatus = this.props.getSelectedStatus()
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].findIndex(obj => obj.key === "status") === -1 && defstatus && defstatus.length !== 0) {
        filterCriteria['criteria'].push({
          "key": "status",
          "value": this.state.selectedStatus,
          "type": "in"
        }
        )
      }
      if ((this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View") && this.state.showSiteDiscoveryCards && filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].findIndex(obj => obj.key === "title") === -1) {
        filterCriteria['criteria'].push({
          "key": "title",
          "value": "Site",
          "type": "regexOr"
        })
      }
      let index = filterCriteria['criteria'].findIndex(obj => obj.key === "title")
      if (this.props.type === "Research Request Viewer" && filterCriteria && filterCriteria['criteria'] && (filterCriteria['criteria'].findIndex(obj => obj.key === "title") === -1 || filterCriteria['criteria'][index].value === "Site Discovery")) {
        filterCriteria['criteria'].push({
          "key": "title",
          "value": "Research Request",
          "type": "eq"
        })
      }

      // let index = filterCriteria['criteria'].findIndex(obj => obj.key === "title")
      if (this.props.type === "RFI View" && filterCriteria && filterCriteria['criteria'] && (filterCriteria['criteria'].findIndex(obj => obj.key === "title") === -1 || filterCriteria['criteria'][index].value === "Site Discovery")) {
        filterCriteria['criteria'].push({
          "key": "title",
          "value": "RFI - Request for Information",
          "type": "eq"
        })
      }

      if (this.props.type === "Seller Negative Notice Viewer" && filterCriteria && filterCriteria['criteria'] && (filterCriteria['criteria'].findIndex(obj => obj.key === "title") === -1 || filterCriteria['criteria'][index].value === "Site Discovery")) {
        filterCriteria['criteria'].push({
          "key": "title",
          "value": "Seller Negative Notice",
          "type": "eq"
        })
      }
    }
    return filterCriteria
  }

  /**
   *hits when ever the task changed in top navigation
   */
  updateTaskStatus = () => {
    if (this.props.type === "List View" || this.props.type === "RFI View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer") {
      if (localStorage.getItem("isTaskUpdatedfromTopbar")) {
        localStorage.removeItem("isTaskUpdatedfromTopbar")
        this.getDataFromServer(this.state.filterCriteria);
      }
    }
  }

  /**
   * 
   * @param {string} apiUrl 
   * @returns Setting up the query parameters for the api url 
   */
  setFlagsforProperties = async (apiUrl) => {
    if ((this.props.type === "Properties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" || this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.PropertyData && this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedName && this.state.PropertyData.selectedName.length > 0) {
      apiUrl = `${apiUrl}&sellerID=${this.state.PropertyData.selectedSeller.EstateID ? this.state.PropertyData.selectedSeller.EstateID : this.state.PropertyData.selectedSeller}&nameID=${encodeURIComponent(this.state.PropertyData.selectedName)}`
    } if ((this.props.type === "Properties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.PropertyData && this.state.PropertyData.selectedSeller && (!this.state.PropertyData.selectedName || this.state.PropertyData.selectedName.length === 0)) {
      apiUrl = `${apiUrl}&sellerID=${this.state.PropertyData.selectedSeller.EstateID}`
    } if ((this.props.type === "Property Editor" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedProperty_Id) {
      // apiUrl = `${apiUrl}&propertyId=${this.state.PropertyData.selectedProperty_Id}&&missingOPRAPropertyIds`
      apiUrl = `${apiUrl}`

    } if ((this.props.type === "Property Editor" || this.props.type === "Negative Notice List Viewer" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Site Confirmation Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedOpraStId) {
      // apiUrl = `${apiUrl}&statePropertyId=${this.state.PropertyData.selectedOpraStId}&missingStatePropertyIds`
      apiUrl = `${apiUrl}`
    }
    if (this.state.PropertyData && this.state.PropertyData.groupFlag) {
      let key = this.state.PropertyData.groupFlag
      apiUrl = `${apiUrl}&redirecting=${key}`
    }
    if (this.state.PropertyData && this.state.PropertyData.reportType) {
      let key = this.state.PropertyData.reportType
      apiUrl = `${apiUrl}&reportType=${key}`
    }
    if (this.state.PropertyData && this.state.PropertyData.confirmedOnsite) {
      let key = this.state.PropertyData.confirmedOnsite
      apiUrl = `${apiUrl}&confirmedOnsite=${key}`
    }

    if (this.props.type === "Negative Notice List Viewer") {
      apiUrl = `${apiUrl}&sellernegativelist=true`
    }
    if (this.props.type === "Site Confirmation Editor") {
      apiUrl = `${apiUrl}&siteconfirmation=true`
    }
    if (this.state.PropertyData && this.state.PropertyData.taskID) {
      apiUrl = `${apiUrl}&taskID=${this.state.PropertyData.taskID}`
    }


    // & taskID=${ this.state.PropertyData.taskID }
    if ((this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.PropertyData && this.state.PropertyData.selectedBucket) {
      apiUrl = `${apiUrl}&matchbucket=${this.state.PropertyData.selectedBucket}`
    }
    if (this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
      apiUrl = `${apiUrl}&CompanyID=${this.state.PropertyData && this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedSeller.EstateID ? this.state.PropertyData.selectedSeller.EstateID : this.state.PropertyData.selectedSeller}`
    }
    if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") {
      if (this.state.PropertyData) {
        if (this.state.PropertyData.isValidate) {
          apiUrl = `${apiUrl}&validated=true`
        }
        if (this.state.PropertyData.isexcludeValidated) {
          apiUrl = `${apiUrl}&excludeValidated=true`
        }
        if (this.state.PropertyData.isexcludePossiblyPaid) {
          apiUrl = `${apiUrl}&isexcludePossiblyPaid=true`
        }
        if (this.state.PropertyData.isexcludeProperties) {
          apiUrl = `${apiUrl}&excludeProperties=${this.state.PropertyData.isexcludeProperties}`
        }
        if (this.state.PropertyData.excludeCoOwners) {
          apiUrl = `${apiUrl}&excludeCoOwners=true`
        }
        if (this.state.PropertyData.excludeNotConfirmedOnSite) {
          apiUrl = `${apiUrl}&excludeNotConfirmedOnSite=true`
        }
        if (this.state.PropertyData.isexcludeRoyalties) {
          apiUrl = `${apiUrl}&excludeRoyalties=true`
        }
        if (this.state.PropertyData.selectedRanges) {
          let index = this.state.PropertyData.selectedRanges.indexOf("All");
          if (index === -1) {
            apiUrl = `${apiUrl}&ranges=${this.state.PropertyData.selectedRanges}`
          } else {
            apiUrl = `${apiUrl}&ranges=All`
          }
        }
      }
    }
    if (this.props.type === "ClaimSummary") {
      if (this.state.PropertyData && this.state.PropertyData.claimType) {
        apiUrl = `${apiUrl}&claimType=${this.state.PropertyData.claimType}`
      }
      if (this.state.PropertyData && this.state.PropertyData.DealId) {
        apiUrl = `${apiUrl}&DealId=${this.state.PropertyData.DealId}`
      }
    }
    return apiUrl;
  }

  //listView ApiwithFlags
  /**
   * 
   * @param {string} url 
   * @param {string} type 
   * @param {object} filterCriteria 
   * @returns the flags for query for llist view and Research Request Viewer
   */
  getUrlwithFlags = async (url, type, filterCriteria) => {
    let startDate = this.timeConversionString(new Date(this.state.startDateReports));
    let endDate = this.timeConversionString(new Date(this.state.lastDateReports));
    let selectedDates = {
      'startDate': startDate, 'endDate': endDate
    }
    let apiUrl;
    if (type === "dateQuery") {
      apiUrl = `${url}?${this.props.type === "List View" ? `view=list&` : ''}filter=${JSON.stringify(this.state.filterCriteria)}&dateQuery=${JSON.stringify(this.props.type === "List View" && this.state.dateQuery ? this.state.dateQuery : selectedDates ? selectedDates : null)}`
    } else {
      if (typeof this.state.dateQuery === "object") {
        apiUrl = `${url}?${this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" ? `view=list&` : ''}filter=${JSON.stringify(filterCriteria)}&dateQuery=${JSON.stringify(this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" ? this.state.dateQuery : selectedDates)}`
      } else {
        apiUrl = `${url}?${this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" ? `view=list&` : ''}filter=${JSON.stringify(filterCriteria)}`;
      }
    }

    if (this.state.unassignedOnly) {
      apiUrl = `${apiUrl}&isUnassigned=true`
    }
    if (this.state.selectedDepartment && this.state.selectedDepartment.length > 0) {
      if (this.props.tabCarrying && this.props.tabCarrying === "available") {
        let params = apiUrl.split("&")
        let queryparams = params.filter(elem => elem.includes("selectedDepartment=") === false)
        apiUrl = queryparams.join("&")
      }
      apiUrl = `${apiUrl}&selectedDepartment=${encodeURIComponent(this.state.departmentIdArray && this.state.departmentIdArray.length > 0 ? this.state.departmentIdArray.join(", ") : this.state.departMentIdArray.join(", "))}`
    }
    await this.setState({
      apiUrlforExport: apiUrl
    })
    return apiUrl
  }
  /**
   * 
   * @param {string} url 
   * @param {Object} filterCriteria 
   * @returns the url with flags for weekly recovery plan
   */
  getCustomisedRecoveryUrl = async (url, filterCriteria) => {
    let startDate = this.timeConversionString(new Date(this.state.startDateReports));
    let endDate = this.timeConversionString(new Date(this.state.lastDateReports));
    let selectedDates = {
      'startDate': startDate, 'endDate': endDate
    }
    let plan, apiUrl;
    if (window.location.pathname === "/nonSiteDiscoveryPlan") {
      plan = "Non-Search"
    } else if (window.location.pathname === "/siteDiscoveryPlan") {
      plan = "Search"
    }

    if (plan) {
      apiUrl = `${url}?dateQuery=${JSON.stringify(selectedDates)}&filter=${JSON.stringify(filterCriteria)}&plan=${plan}`
    } else {
      apiUrl = `${url}?dateQuery=${JSON.stringify(selectedDates)}&filter=${JSON.stringify(filterCriteria)}`
    }
    if (this.state.departMentIdArray && this.state.departMentIdArray.length > 0 && this.props.type !== "Utilization - Integrity") {
      if (this.props.tabCarrying && this.props.tabCarrying === "available") {
        let params = apiUrl.split("&")
        let queryparams = params.filter(elem => elem.includes("selectedDepartment=") === false)
        apiUrl = queryparams.join("&")
      }
      apiUrl = `${apiUrl}&selectedDepartment=${encodeURIComponent(this.state.departmentIdArray && this.state.departmentIdArray.length > 0 ? this.state.departmentIdArray.join(", ") : this.state.departMentIdArray.join(", "))}`
    }
    if (apiUrl) {
      return apiUrl;
    } else {
      return ""
    }

  }

  //
  /**
   * 
   * @param {array} responseData 
   * Counts for footer in weekly Recovery PLan
   */
  setWeeklyPlanChanges = async (responseData) => {
    var totalForcastTransitionHours = Object.values(responseData).reduce((t, { forecastTransitionHours }) => t + forecastTransitionHours, 0)
    var totalActualTransitionHours = Object.values(responseData).reduce((t, { transitionHours }) => t + transitionHours, 0)
    var totalForcastSiteDiscovery = Object.values(responseData).reduce((t, { forecastSearchHours }) => t + forecastSearchHours, 0)
    var totalActualSiteDiscovery = Object.values(responseData).reduce((t, { searchHours }) => t + searchHours, 0)
    var totalForcastValidation = Object.values(responseData).reduce((t, { forecastValidationHours }) => t + forecastValidationHours, 0)
    var totalActualValidation = Object.values(responseData).reduce((t, { validationHours }) => t + validationHours, 0)
    var totalForcastFile = Object.values(responseData).reduce((t, { forecastFileHours }) => t + forecastFileHours, 0)
    var totalActualFile = Object.values(responseData).reduce((t, { fileHours }) => t + fileHours, 0)
    var totalSideProjectActual = Object.values(responseData).reduce((t, { recoverySideProjectHours }) => t + recoverySideProjectHours, 0)
    await this.setState({
      totalForcastTransitionHours: totalForcastTransitionHours,
      totalActualTransitionHours: totalActualTransitionHours,
      totalForcastSiteDiscovery: totalForcastSiteDiscovery,
      totalActualSiteDiscovery: totalActualSiteDiscovery,
      totalForcastValidation: totalForcastValidation,
      totalActualValidation: totalActualValidation,
      totalForcastFile: totalForcastFile,
      totalActualFile: totalActualFile,
      totalSideProjectActual: totalSideProjectActual,
      totalActualHours: totalActualTransitionHours + totalActualValidation + totalActualFile,
      totalForcastHours: totalForcastTransitionHours + totalForcastValidation + totalForcastFile
    })
  }
  /**
   * 
   * @param {Object} obj 
   * @returns Function to return the lowest count value 
   */
  lowestValueAndKey = (obj) => {
    if (obj) {
      var [lowestItems] = Object.entries(obj).filter(a => a[1] > 0).sort(([, v1], [, v2]) => v1 - v2);
      if (lowestItems && lowestItems[0]) {
        return lowestItems[0]
      }
      else {
        return "Green"
      }
    }
  }

  /**
   * 
   * @param {object} response 
   * @param {string} type 
   * Checking the condition and redirecting to required bucket
   */
  clickCount = async (response, type) => {
    // if (response && response.counts && type === "Property Editor" && this.state.isChangeHit) {
    var lowestBucket;
    if (response && response.counts) {
      lowestBucket = this.lowestValueAndKey(response.counts)
    }
    if (response && response.counts && (type === "Property Editor" || type === "Deal Sizing Property Editor") && this.state.isChangeHit) {
      if (response.counts.Green === 0) {
        if (response.counts.Blue > 0) {
          if (response.counts.Blue > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Blue")
          }
        } else if (response.counts.Yellow > 0) {
          if (response.counts.Yellow > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Yellow")
          }
        } else if (response.counts.Brown > 0) {
          if (response.counts.Brown > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Brown")
          }
        } else if (response.counts.Black > 0) {
          if (response.counts.Black > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Black")
          }
        } else if (response.counts.Teal > 0 && (this.state.loginRole === "Admin" || this.state.loginRole === config.roleNames.coreOpsManager)) {
          if (response.counts.Teal > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Teal")
          }
        } else if (response.counts.All > 0) {
          if (response.counts.All > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("All")
          }
        } else if (response.counts.SP > 0) {
          if (response.counts.SP > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("SP")
          }
        } else {
          this.setState({
            activeTab: "Green"
          })
          // this.toggletab("Green")
          console.log("No Records to call API")
        }
      }
      await this.setState({ isChangeHit: false })
    }

    if (response && response.counts && type === "Site Confirmation Editor") {
      let counts = response.counts
      await this.setState({
        PendingCount: counts.Pending,
        ConfirmedCount: counts.Confirmed,
        MissingCount: counts.Missing
      })

    }
  }

  /**
   * 
   * @returns the api url from props
   */
  getAPIUrl() {
    return this.props.apiUrl;
  }
  /**
   * Calling the files to set the file for options
   */
  getDataForMatch = () => {
    if (this.props.getFileOptionsforMatching) {
      this.props.getSellerName(this.state.PropertyData.selectedSeller)
      this.props.getFileOptionsforMatching(this.state.fileForOptions)
    }
  }

  /**
   * 
   * @param {object} obj 
   * getting the updated and not updated list for up property confirmation
   */
  sendUpPropConfirmation = async (obj) => {
    let body = {
      // PropertyIds: obj.coutntValue ? obj.coutntValue.split(',').map(Number) : [],
      StatePropertyIds: obj.coutntValue ? obj.coutntValue : [],
      CompanyID: obj.selectedSeller.EstateID,
      SourceState: obj.selectedState
    }
    await this.setState({ isLoading: true })
    fetchMethodRequest('POST', 'NsScoredProperties/checkProperties', body)
      .then(async (response) => {
        if (response) {
          this.setState({
            uppropConfirmationMessage: true, uppropPropertyIds: response.notUpdatedList, upconfirmProperties: response
          })
          let notUpdateArray = []
          for (let id of response.notUpdatedList) {
            notUpdateArray.push({ id: id, clicked: false })
          }
          await this.setState({ notUpdateArray: notUpdateArray })
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage, "", "error")
        }
        await this.setState({ isLoading: false })


      }).catch((err) => {
        return err;
      });
    // `Out of ${response.totalPropertyCount} properties, ${response.updated} were update as Confirmed on site.${response.notUpdated} Properties were NOT found in the OPRA2, below are those properties: Please lot these properties in OPRA manually`
  }
  /**
   * Disabling the up prop confirmation and ids
   */
  clearOnsitemessage = async () => {
    await this.setState({ uppropConfirmationMessage: false, uppropPropertyIds: [] })
  }

  /**
   * 
   * @param {Object} obj 
   * @param {object} criteria 
   * @param {bool} changeHit 
   * @param {bool} setGoButton 
   * @param {Array} fileForOptions 
   * Setting up the filters after redirection from any tab
   */

  getFilteredValues = async (obj, criteria, changeHit, setGoButton, fileForOptions) => {
    if (this.props.type === "Deal Sizing Report For Companies") {
      // let criteria1 = criteria
      if (criteria && criteria.sortfield && criteria.sortfield === "EntityName") {
        criteria.sortfield = "BusinessName"
        criteria.direction = "asc"
        // this.setState({ filterCriteria: criteria })
      }
    } else if (this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
      if (criteria && criteria.sortfield && criteria.sortfield === "CompanyName") {
        criteria.sortfield = "bussinessName"
        criteria.direction = "asc"
        criteria.globalSearch = {}
        let index = criteria["criteria"].findIndex(obj => obj.key === "CompanyName" && obj.add_Filter === true)
        if (index !== -1) criteria["criteria"].splice(index, 1)

        // this.setState({ filterCriteria: criteria })
      }
    } else if (this.props.type === "ClaimSummary") {
      criteria = this.state.filterCriteria
      if (obj.groupBy) {
        this.setState({ clearFiltersGroupBy: obj.groupBy })

      }
      if (criteria) {
        criteria.sortfield = obj && obj.groupBy ? obj.groupBy : null
        // criteria.direction = "asc"
        // this.setState({ filterCriteria: criteria })
      }
    }

    let modifiedLabels = []
    if (changeHit) {
      await this.setState({ isChangeHit: true })
    }
    if (fileForOptions) {
      for (let name of fileForOptions) {
        modifiedLabels.push({
          label: name.companyName,
          value: {
            labelName: name.companyName,
            id: name.nameID,
          },
        })
      }
    } else {
      fileForOptions = []
    }
    if (obj && criteria) {
      await this.setState({
        PropertyData: obj,
        fileForOptions: this.props.type === "ClaimsManagement" ? modifiedLabels : fileForOptions,
        first: 0,
        redirectSellerId: null,
        negativePeriod: null

      })
      await this.getDataForMatch()
      criteria.page = "1"
      if (this.state.PropertyData && (this.state.PropertyData.selectedSeller && !this.state.PropertyData.selectedSeller.EstateID || this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedSeller.EstateID === "")) {
        delete criteria.globalSearch
        await this.setState({
          selectFilterValue: "",
          isShowTable: false,
          totalRecordsLength: 0
        })
      }
      if (obj.selectedClaims && this.editorComponentRef) {
        this.editorComponentRef.getFileForEditor(obj.selectedClaims.EstateID)
        this.editorComponentRef.getFileForEditorForSellerRelevanceName(obj.selectedClaims.EstateID)


        // let PropertyStatusindex = criteria.criteria.findIndex(obj => obj.key === "PropertyStatus")
        // if (PropertyStatusindex !== -1) criteria.criteria.splice(PropertyStatusindex, 1)

      } else {
        if (this.editorComponentRef) {
          this.editorComponentRef.getFileForEditor(this.state.PropertyData.selectedSeller.EstateID)
          this.editorComponentRef.getFileForEditorForSellerRelevanceName(this.state.PropertyData.selectedSeller.EstateID)
        }
      }
      if ((this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.PropertyData.selectedSeller) {
        this.toggletab("Green", setGoButton)
        this.getNegativeNoticeSellers()
      } else {
        if (this.state.PropertyData && this.state.PropertyData.isValidate) {
          this.getTableFieldsOrder();

        }

        this.getDataFromServer(criteria)
        this.getNegativeNoticeSellers()

      }
    } else {
      var url, obj;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (localStorage.getItem("nspropertiesparamsUrl") && params && params.from && params.from !== "AvailableToMap") {
        url = new URL(localStorage.getItem("nspropertiesparamsUrl"));
      } else {
        url = new URL(window.location.href);
      }

      obj = await this.getObjectFromCopiedUrl(url);
      let groupMessage = this.props && this.props.tabDescriptionArray ? this.props.tabDescriptionArray.filter(object => {
        return object.value.flagName === obj.groupFlag
      }) : []

      obj.groupMessage = groupMessage[0] ? groupMessage[0].value.description : ""
      obj.groupedflag = groupMessage[0] ? groupMessage[0].value.name : ""
      obj.redirectedGroup = groupMessage[0]
      let selectedProp = localStorage.getItem("matchedProperties") ? localStorage.getItem("matchedProperties") : obj.selectedProperty_Id ? obj.selectedProperty_Id : null
      let selectedstateProp = localStorage.getItem("matchedStateProperties") ? localStorage.getItem("matchedStateProperties") : obj.selectedOpraStId ? obj.selectedOpraStId : null
      localStorage.removeItem("matchedProperties")
      localStorage.removeItem("matchedStateProperties")
      obj.selectedProperty_Id = selectedProp
      obj.selectedOpraStId = selectedstateProp
      await this.setState({
        PropertyData: obj,
        isShowTable: true,
        activeTab: obj.bucket,
        isChangeHit: true
      })
      this.getNegativeNoticeSellers()
      if (this.editorComponentRef) {
        this.editorComponentRef.getFileForEditor(this.state.PropertyData.selectedSeller.EstateID)
        this.editorComponentRef.getFileForEditorForSellerRelevanceName(this.state.PropertyData.selectedSeller.EstateID)
      }
      await this.getDataForMatch()
      let filtercriteria = this.state.filterCriteria
      if (this.state.activeTab === "All") {
        filtercriteria.sortfield = "updatedDate"
      }
      let index = filtercriteria["criteria"].findIndex(obj => obj.key === "bucket")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
        filtercriteria["criteria"].push({
          key: "bucket",
          value: this.state.activeTab,
          type: "eq",

          // type: "eq",
          add_Filter: true
        })
      }
      if (this.state.removeDataSource && obj && obj.reportType && !obj.reportType.includes("SiteConfirmationReport")) {
        let indexDataSource = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (indexDataSource !== -1) filtercriteria["criteria"].splice(indexDataSource, 1)
      }
      let index2 = filtercriteria["criteria"].findIndex(obj => obj.key === "businessState" && obj.type === "in")
      if (index2 !== -1) filtercriteria["criteria"].splice(index, 1)
      if (obj.states && obj.states !== "null") {
        filtercriteria["criteria"].push({
          key: this.props.type === "Deal Sizing-Properties" ? "state" : "sourceState",
          value: obj.states.split(","),
          type: "in"
        })
      }
      if (obj.fiftyk && obj.fiftyk !== "null") {
        filtercriteria["criteria"].push({
          key: "propertyMaxValueAmount",
          value: 50000,
          type: "gt"
        })
      } if (obj.unknown && obj.unknown !== "null") {
        filtercriteria["criteria"].push({
          key: "propertyMaxValueAmount",
          value: 0,
          type: "nexists"
        })
      }
      if (obj.Count10kTo50k && obj.Count10kTo50k !== "null") {
        filtercriteria["criteria"].push(
          { key: "propertyMaxValueAmount", value: 10000, type: "gte" }, { key: "propertyMaxValueAmount", value: 50000, type: "lte" }
        )
      }

      if (obj.SellerProof && obj.SellerProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "SellerProof", value: "", type: "nexists" }
        )
      }
      if (obj.ClaimId) {
        filtercriteria["criteria"].push(
          { key: "claimID", value: obj.ClaimId, type: "eq" },
        )
      }
      if (obj.TotalProps && obj.TotalProps !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "AllProof", value: "", type: "nexists" }
        )
      }
      if (obj.ClaimId) {
        filtercriteria["criteria"].push(
          { key: "claimID", value: obj.ClaimId, type: "eq" },
        )
      }
      if (obj.NameProof && obj.NameProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "NameProof", value: "", type: "nexists" }
        )
      } if (obj.AddressProof && obj.AddressProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "AddressProof", value: "", type: "nexists" }
        )
      } if (obj.FEINProof && obj.FEINProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "FEINProof", value: "", type: "nexists" }
        )
      } if (obj.HolderProof && obj.HolderProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "HolderProof", value: "", type: "nexists" }
        )
      }

      let matchStatusIndex = filtercriteria["criteria"].findIndex(obj => (obj.key === "PropertyStatus" || obj.key === "propertyStatus") && obj.type === "in")
      if (matchStatusIndex !== -1) filtercriteria["criteria"].splice(matchStatusIndex, 1)
      if (obj.selectedPropertyStatus && obj.selectedPropertyStatus !== "null") {
        filtercriteria["criteria"].push({
          key: "propertyStatus",
          value: obj.selectedPropertyStatus.split(","),
          type: "in"
        })
      }
      if (obj.selectedPropertyReasons && obj.selectedPropertyReasons !== "null") {
        filtercriteria["criteria"].push({
          key: "PropertyStatusReason",
          value: obj.selectedPropertyReasons.split(","),
          type: "in"
        })
      }
      if (obj.newNameIssueCode) {
        let splitIssueCode = obj.newNameIssueCode.split(",")
        if (splitIssueCode.length > 0) {
          const newArr = splitIssueCode.map(item => item === "Blank" ? "" : item);
          obj.newNameIssueCode = newArr
        }
        filtercriteria["criteria"].push({
          key: "newNameIssueCode",
          value: obj.newNameIssueCode,
          type: "in"
        })
        obj.newNameIssueCode = obj.newNameIssueCode.join(",")
      }
      if (obj.legacyNameIssueCode) {
        let splitLegacyIssueCode = obj.legacyNameIssueCode.split(",")
        if (splitLegacyIssueCode.length > 0) {
          const LegacyNewArr = splitLegacyIssueCode.map(item => item === "Blank" ? "" : item);
          obj.legacyNameIssueCode = LegacyNewArr
        }
        filtercriteria["criteria"].push({
          key: "nameIssueCode",
          value: obj.legacyNameIssueCode,
          type: "in"
        })
        obj.legacyNameIssueCode = obj.legacyNameIssueCode.join(",")
      }
      if (obj.ConfirmedOnsiteDate) {
        filtercriteria["criteria"].push({
          key: "confirmedOnsiteDate",
          value: new Date(obj.ConfirmedOnsiteDate),
          type: "dategte"
        })
      }

      if (obj.selectedPropertyReasonsForResource && obj.selectedPropertyReasonsForResource !== "null") {
        filtercriteria["criteria"].push({
          key: "PropertyStatusReason",
          value: obj.selectedPropertyReasonsForResource.split(","),
          type: "in"
        })
      }
      if (obj.maxValueAmount) {
        filtercriteria["criteria"].push(
          { key: "PropertyMaxValueAmount", value: obj.maxValueAmount, type: "gte" }
        )
      }
      if (obj.nameMatchScore) {
        filtercriteria["criteria"].push(
          { key: "nameMatchScore", value: obj.nameMatchScore, type: "gte" }
        )
      }
      if (obj.dataSourceArrayNew && obj.dataSourceArrayNew.length > 0) {
        filtercriteria["criteria"].push(
          { key: "PropertyDataSource", value: obj.dataSourceArrayNew.split(","), type: "in" }
        )
      } else {
        let indexDataSource = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (indexDataSource !== -1) filtercriteria["criteria"].splice(indexDataSource, 1)
      }

      if (obj.FileFor && obj.FileFor !== "null" && obj.FileFor.length > 0) {
        filtercriteria["criteria"].push({
          key: "fileFor",
          value: obj.FileFor,
          type: "eq"
        })
      } else if (obj.reportType && (obj.reportType === "ReadyToFile" || obj.reportType === "OnHold" || obj.reportType === "SellerNegativeNotice" || obj.reportType === "ReadyToReview") && obj.FileFor && obj.FileFor == "null") {
        filtercriteria["criteria"].push({
          key: "fileFor",
          value: null,
          type: "eq"
        })
      } else if (obj.reportType && (obj.reportType === "ReadyToFile" || obj.reportType === "OnHold" || obj.reportType === "SellerNegativeNotice" || obj.reportType === "ReadyToReview")) {
        filtercriteria["criteria"].push({
          key: "fileFor",
          value: "",
          type: "eq"
        })

      }
      if (obj.FileFor === "null") {
        obj.FileFor = null
      }

      if (obj.states && obj.states !== "null") {

      } else if (obj.reportType && (obj.reportType === "ReadyToFile" || obj.reportType === "OnHold" || obj.reportType === "SellerNegativeNotice") && obj.states && obj.states == "null") {
        await this.setState({ sourceStateNull: true, reportTypeEmpty: obj.reportType })

      } else if (obj.reportType && (obj.reportType === "ReadyToFile" || obj.reportType === "OnHold" || obj.reportType === "SellerNegativeNotice")) {
        await this.setState({ sourceStateEmpty: true, reportTypeEmpty: obj.reportType })

      }
      if (obj.HolderName && obj.HolderName !== "null") {
        filtercriteria["criteria"].push({
          key: "holderName",
          value: obj.HolderName,
          type: "eq"
        })
      } else if (obj.reportType && obj.reportType === "CA-N" && obj.HolderName && obj.HolderName === "null") {
        filtercriteria["criteria"].push({
          key: "holderName",
          value: null,
          type: "eq"
        })
      } else if (obj.reportType && obj.reportType === "CA-N") {
        filtercriteria["criteria"].push({
          key: "holderName",
          value: "",
          type: "eq"
        })
      }
      if (obj.HolderName === "null") {
        obj.HolderName = null
      }
      if (obj.states === "null") {
        obj.states = null
      }
      if (this.propertyFilterRef) { this.propertyFilterRef.getData(obj) }
      if (this.dealSizingpropertyFilterRef) { this.dealSizingpropertyFilterRef.getData(obj) }
      let body = {
        propertyId: this.state.PropertyData.selectedProperty_Id ? this.state.PropertyData.selectedProperty_Id.toString() : null,
        statePropertyId: this.state.PropertyData.selectedOpraStId ? this.state.PropertyData.selectedOpraStId.toString() : null
      }
      this.setState({
        postBodyFile: body
      })

      this.getDataFromServer(this.state.filterCriteria, '', '', body)

      localStorage.removeItem("nspropertiesparamsUrl")
      window.history.pushState(null, null, `${this.props.routeTo}`);
    }
    if (this.props.type === "ClaimsManagement") {
      if (this.state.resized) {
        await this.sendColumnDataToServer("", "GET")
        await this.setState({ resized: false })
      }
      await this.setDynamicColumns()
    }
  }

  /**
   * @param {Array} fileOptions 
   * Setting the file for options for inline edit of claims management
   */
  getFileOptions = async (fileOptions) => {

    if (this.props.type === "ClaimsManagement") {

      let modifiedLabels = []
      for (let name of fileOptions) {
        modifiedLabels.push({
          label: name.companyName,
          value: {
            labelName: name.companyName,
            id: name.nameID,
          },
        })
      }

      await this.setState({
        fileForOptions: modifiedLabels
      })

    } else {
      await this.setState({
        fileForOptions: fileOptions
      })
    }
  }

  /**
   * 
   * @param {String} url 
   * @returns Getting the values from the redirected url and setting in object
   */
  getObjectFromCopiedUrl = async (url) => {
    var sellerName = url.searchParams.get("sellerName");
    var sellerId = url.searchParams.get("sellerId");
    var nameId = url.searchParams.get("nameId");
    var isValidate = url.searchParams.get("isValidated")
    var isBucket = url.searchParams.get("bucket")
    var selectedStates = url.searchParams.get("state")
    var Count10kTo50k = url.searchParams.get("10kto50k")
    var SellerProof = url.searchParams.get("SellerProof")
    var ClaimId = url.searchParams.get("ClaimId")
    var TotalProps = url.searchParams.get("TotalProps")
    var NameProof = url.searchParams.get("NameProof")
    var AddressProof = url.searchParams.get("AddressProof")
    var FileFor = url.searchParams.get("FileFor")
    var FEINProof = url.searchParams.get("FEINProof")
    var HolderProof = url.searchParams.get("HolderProof")
    var taskID = url.searchParams.get("taskID")
    var fiftyk = url.searchParams.get("50k")
    var reportType = url.searchParams.get("reportType")
    var confirmedOnsite = url.searchParams.get("confirmedOnsite")
    var unknown = url.searchParams.get("unknown")
    var HolderName = url.searchParams.get("HolderName")
    var Property_Id = url.searchParams.get("propertyId")
    var maxValueAmount = url.searchParams.get('maxValueAmount')
    var dataSourceArrayNew = url.searchParams.get("dataSourceArrayNew")
    var state_property_Id = url.searchParams.get("statePropertyId")
    var is_excludeValidated = url.searchParams.get("isexcludeValidated")
    var dataSource = url.searchParams.get("dataSource")
    var is_excludeProperties = url.searchParams.get("isexcludeProperties")
    var exclude_CoOwners = url.searchParams.get("excludeCoOwners")
    var isexcludePossiblyPaid = url.searchParams.get("isexcludePossiblyPaid")
    var selectedPropertyStatus = url.searchParams.get("selectedPropertyStatus")
    var selectedPropertyReasons = url.searchParams.get("selectedPropertyReasons")
    var newNameIssueCode = url.searchParams.get("newNameIssueCode")
    var legacyNameIssueCode = url.searchParams.get("legacyNameIssueCode")
    var from = url.searchParams.get("from")
    var ConfirmedOnsiteDate = url.searchParams.get("ConfirmedOnsiteDate")
    var selectedPropertyReasonsForResource = url.searchParams.get("selectedPropertyReasonsForResource")
    var isexcludeRoyalties = url.searchParams.get("excludeRoyalties")
    var selectedRanges = url.searchParams.get("ranges")
    var excludeNotConfirmedOnSite = url.searchParams.get("excludeNotConfirmedOnSite")
    var ClaimId = url.searchParams.get("ClaimId")
    var groupFlag = url.searchParams.get("GroupFlag")
    var nameMatchScore = url.searchParams.get("nameMatchScore")
    let obj = {}
    let SellerfromURL = {
      EstateName: sellerName,
      EstateID: sellerId
    }
    obj.selectedSeller = SellerfromURL
    if (nameId !== "null") {
      obj.selectedName = nameId
      obj.nameId = nameId
    }
    obj.ClaimId = ClaimId
    obj.selectedPropertyStatus = selectedPropertyStatus
    obj.selectedPropertyReasons = selectedPropertyReasons
    obj.newNameIssueCode = newNameIssueCode
    obj.legacyNameIssueCode = legacyNameIssueCode
    obj.ConfirmedOnsiteDate = ConfirmedOnsiteDate
    obj.selectedPropertyReasonsForResource = selectedPropertyReasonsForResource
    obj.selectedProperty_Id = Property_Id
    obj.maxValueAmount = maxValueAmount
    obj.taskID = taskID
    obj.bucket = isBucket
    obj.from = from
    obj.SellerProof = SellerProof
    obj.ClaimId = ClaimId
    obj.TotalProps = TotalProps
    obj.nameMatchScore = nameMatchScore
    obj.reportType = reportType
    obj.confirmedOnsite = confirmedOnsite
    obj.NameProof = NameProof
    obj.AddressProof = AddressProof
    obj.dataSourceArrayNew = dataSourceArrayNew
    obj.dataSourceRedirect = dataSource
    obj.HolderName = HolderName
    obj.FileFor = encodeURIComponent(FileFor)
    obj.FEINProof = FEINProof
    obj.HolderProof = HolderProof
    obj.Count10kTo50k = Count10kTo50k
    obj.fiftyk = fiftyk
    obj.unknown = unknown
    obj.isexcludeValidated = is_excludeValidated
    obj.selectedOpraStId = state_property_Id
    obj.isexcludeProperties = is_excludeProperties
    obj.isexcludeRoyalties = isexcludeRoyalties
    obj.isexcludePossiblyPaid = isexcludePossiblyPaid
    obj.excludeCoOwners = exclude_CoOwners
    obj.selectedRanges = selectedRanges
    obj.excludeNotConfirmedOnSite = excludeNotConfirmedOnSite
    obj.groupFlag = groupFlag
    if (selectedStates) {
      obj.states = selectedStates
    }
    await this.setState({ removeDataSource: true })

    if (isValidate === "true") {
      obj.isValidate = isValidate
    }
    return obj;
  }


  goBack = async () => {
    localStorage.setItem("hitback", "yes")
    await this.setState({ goBackToClaims: true })
  }
  /**
   * 
   * @returns return api url from props
   */
  getFilterUrl() {
    return this.props.apiUrl;
  }

  /**
   * 
   * @returns Getting the back button option in claim summary
   */
  getBackButton() {
    let claimSummaryName = localStorage.getItem('claimSummaryName');

    return (
      <div className='d-flex'>

        <Button
          color='primary'
          className="mb-0"
          outline
          style={{ height: "34px" }}

          // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
          onClick={() => this.goBack()}
        >

          <FontAwesomeIcon
            color='#354f6e'
            style={{ cursor: "pointer", height: "1em" }}
            icon={faArrowCircleLeft}
            data-toggle="tool-tip"
            onClick={() => this.goBack()}
          />
        </Button>
        <p style={{ "font-weight": "600", marginLeft: "10px", marginTop: "3px", fontSize: "18px" }}>{`Deal : ${claimSummaryName}`}</p>

      </div >
    )
  }

  /**
   * 
   * @param {Array} data 
   * @returns Changing the data 
   * for change the format for the downloadig data through export
   */
  formatChange = (data) => {
    data.forEach((item) => {
      var notearry = []
      for (let key in item) {
        if (item[key] && item[key]) {
        } else {
          item[key] = ""
        }
        if (item[key] && item[key].name) {
          item[key] = item[key].name;
        }
        if (key === "matchStatusNotes") {
          if (item[key] && item[key].length > 0) {
            for (var obj of item[key]) {
              if (obj.note) {
                notearry.push(obj.note)
              }
            }
            if (data.length > 0) {
              item[key] = this.getArrayVal(JSON.stringify(notearry))
            }
          }

        } else if (typeof item[key] == "object") {
          item[key] = this.getArrayVal(JSON.stringify(item[key]))
        }
        let formatValues = ["PropertyEstimatedMaxValueAmountSum", "BlueEstimatedValue", "GreenEstimatedValue", "SP3EstimatedValue", "SP2EstimatedValue", "SP1EstimatedValue"]
        if (formatValues.includes(key)) {
          item[key] = parseInt(item[key] ? item[key].toFixed(0) : 0)
        }
        let DealValues = ["company"]
        if (DealValues.includes(key) && item[key].includes(" - ") && item[key].includes(" (")) {
          item[key] = this.changeDealNameFormatForExcel(item[key])
        }

      }
    });
    return data
  }
  changeDealNameFormatForExcel = (val) => {
    var y
    if (val) {
      var split = val.split(" - ")
      let x = split[1]
      y = x.split(" (")
    }
    return y[0]
  }

  /**
   * 
   * @returns Component that is used for Download excel sheet
   */
  getExcelDownload = () => {
    let todaydate = dateFormats.formatDate(
      new Date(),
      "MM/DD/YYYY", "nhg")
    return (
      <span className='excel-React d-none'>
        <ExcelReact
          EstateName={this.props.tabOptions && this.state.activeTab !== this.props.type ? `${this.state.activeTab}-${this.props.type}` : `${this.props.type}`}
          sheets={this.state.sheets}
          type="Sellers"
        />
      </span>
    )
  }

  handleSelectedAction = async () => {
    this.setState({ isLoading: true })
    this.handlePercentage()
    var exportedData
    let apiUrl = this.props.apidataforExport ? this.props.apidataforExport : `${this.state.apiUrlforExport}`;
    let responsekey = this.props.apiResponseKey
    let method = ""
    let bodyData = {}
    if (this.props.customisedExport || this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" || this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties") {
      bodyData = this.state.postBodyFile
      method = "POST"
    } else {
      method = "GET"
    }
    if (this.props.type === "UP Opportunity") {
      exportedData = await this.props.apidataforExport()

      let headers = [{
        label: "Seller Name", field: "EstateName"
      },
      {
        label: "Green", field: "GreenCOUNT"
      },
      { label: "Total Of Min Estimated Value In Green", field: "GreenPropertyMinValueAmount" },
      { label: "Blue", field: "BlueCOUNT" },
      { label: "Total Of Min Estimated Value In Blue", field: "BluePropertyMinValueAmount" },
      { label: "Team", field: "Team" }
      ]

      await this.formatforExcel(exportedData, headers)
    } else {
      if (this.props.type === "PropertySearchv2") {
        apiUrl = apiUrl.replace("200", "9999")
      }
      fetchMethodRequest(method, `${apiUrl}&type=exportToCsv`, bodyData)
        .then(async (response) => {
          exportedData = response[responsekey]
          await this.formatforExcel(exportedData)
        })
    }

  }

  formatforExcel = async (exportedData, headersData) => {
    if (exportedData && exportedData.length > 0) {
      let headers = []
      let columns
      if (this.props.type !== "RFI View") {
        columns = this.props.tabOptions ? this.props.getTableFields(this.state.activeTab) : JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));
      } else {
        columns = this.state.tablefieldsToShow
      }
      if (columns && columns.details) {
        columns = columns.details[this.state.selectedViewType];
        if (columns === undefined) {
          columns = this.props.getTableFields()
        }
      }
      for (let key of columns) {
        let index = headers.findIndex(obj => obj.key === key.header)
        if (index === -1 && key.field.length > 0 && key.field !== "downloadBtn" && key.field !== "Actions") {
          headers.push(
            { label: key.header ? key.header.charAt(0).toUpperCase() + key.header.slice(1) : key.field, field: key.field }
          )
        }
      }
      exportedData = this.updateDateFormat(exportedData, "yyyy/MM/DD", "exportExcel");
      let obj = [{
        name: await this.removeSpecialCharactersAndWhitespace(this.props.type, "notencode"),
        headers: headersData ? headersData : headers,
        data: await this.formatChange(exportedData)
      }]
      await this.setState({
        sheets: obj,
        isLoading: false
      })
      document.getElementsByClassName("excel-React")[0].children[0].click()
    } else {
      await this.setState({
        sheets: [],
        errorMessage: "No Records To Download",
        errorResponse: true,
        errorColor: "red",
        isLoading: false
      })
    }
  }

  /**
   *Get all data of current screen with filters applied from server to export to CSV
   */
  getDataToExport = async () => {
    if (this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" || this.props.type === "UP Opportunity" || this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties" || this.props.customisedExport) {
      await this.getCustumisedDatatoExport()
    } else {
      await this.setState({ isLoading: true, isexported: true })
      let filterCriteriaData = this.state.filterCriteria;
      if (this.props.exportwithoutcriteria) {
        filterCriteriaData.criteria = this.props.exportwithoutcriteria
      }
      // delete filterCriteriaData['limit'];
      // delete filterCriteriaData['page'];
      let url;
      url = this.props.apiResponseKey
      let apiUrl;
      if (this.props.type === "FactSet" || this.props.type === "List View" || this.props.type === "Property Editor" || this.props.type === "Recovery Planner V2" || this.props.type === "Manually Loaded Properties" || this.props.type === "ClaimSummary") {
        if (this.state.apiUrlforExport) {
          this.getErrorMessage("The file will be downloaded in few minutes", "", "Success")
          apiUrl = `${this.state.apiUrlforExport}&type=exportToCsv`;
        } else {
          await this.setState({ isLoading: false })

          this.getErrorMessage("Please select seller", "", "error")
        }
      } if (this.state.onGoing) {
        apiUrl = `${apiCalls.OnGoing}?filter=${JSON.stringify(filterCriteriaData)}`
      } else {
        apiUrl = `${this.props.type === "Resource Needed" ? "NsScoredProperties/resourceNeededReport" : this.state.apiUrl}?filter=${JSON.stringify(filterCriteriaData)}&type=exportToCsv`;
      }
      if (apiUrl) {
        return fetchMethodRequest('GET', apiUrl)
          .then(async (response) => {
            let sessionexpired = await localStorage.getItem('sessionexpired')
            if (sessionexpired === "true") {
              await this.setState({ sessionExpiryModal: true })
            }
            let headers = [], item2 = {}, data = []
            let arrayFormatcolumns = []
            if (this.props.type !== "ClaimSummary" && this.props.type !== "Users" && this.props.type !== "Resource Needed" && !this.state.onGoing) {
              let columns = this.props.tabOptions ? this.props.getTableFields(this.state.activeTab) : JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));
              if (columns && columns.details) {
                columns = columns.details[this.state.selectedViewType];
                if (columns === undefined) {
                  columns = this.props.getTableFields()
                }
              } else {
                if (columns && columns.details) {
                  columns = columns.details[this.state.selectedViewType];
                  if (columns === undefined) {
                    columns = this.props.getTableFields()
                  }
                } else {
                  columns = columns
                }
              }
              for (var obj of columns) {
                if (obj.field !== "Actions" && obj.field !== "" && obj.field !== "edit" && obj.field !== "taskIcon") {
                  arrayFormatcolumns.push(obj.field)
                }
              }
            } else if (this.props.type !== "ClaimSummary" && this.props.type !== "Users" && this.props.type !== "Resource Needed" && !this.state.onGoing) {
              arrayFormatcolumns = ["entityEntitlementStateEntityIdNO", "EntityEntitlementExhibitNo", "EntityEntitlementExhibitNo", "FEIN", "FEINEvidenceExhibitNo", "AddressExhibitNo", "ClaimID", "PropertyID", "ClaimStatus", "ReportedName", "ReportedAddress1", "ReportedAddress2", "ReportedCity", "ReportedState", "ReportedZipCode"]
            } else if (this.state.onGoing) {
              arrayFormatcolumns = ["id"]
            } else if (response[url] && response[url].length > 0) {
              arrayFormatcolumns = Object.keys(response[url][0])
              if (this.props.type === "Resource Needed") {
                const index = arrayFormatcolumns.indexOf("_id");
                if (index > -1) { // only splice array when item is found
                  arrayFormatcolumns.splice(index, 1); // 2nd parameter means remove one item only
                }
              }
            }
            if (response && response[url] && response[url].length && response[url].length > 0) {
              let exportData = this.updateDateFormat(response[url], "MM/DD/YYYY");
              let item = exportData[0]
              for (var key of arrayFormatcolumns) {
                if (key === "matchStatusNotes") {
                  if (item[key] && item[key].length > 0) {
                    for (var obj of item[key]) {
                      if (obj.note) {
                        data.push(obj.note)
                      }
                    }
                    if (data.length > 0) {
                      item2[key] = this.getArrayVal(JSON.stringify(data))
                    }
                  } else {
                    item2[key] = null
                  }
                } else if (key === "addressProof" || key === "feinProof" || key === "holderProof" || key === "nameProof" || key === "sellerProof" || key === "note") {
                  if (item[key] && item[key].length > 0) {
                    item2[key] = item[key].replace(/,/g, " ")
                  } else {
                    item2[key] = ""
                  }
                } else {
                  if ((key === "researchNotes" || key === "sellerNegativeNoticeNotes" || key === "requestDescription") && item[key] && item[key].length > 0) {
                    item[key] = this.getArrayVal(JSON.stringify(item[key]))
                  }
                  item2[key] = item[key]
                }
              }
              for (let key in item2) {
                let index = headers.findIndex(obj => obj.key === key)
                if (index === -1) {
                  headers.push(
                    { label: key.charAt(0).toUpperCase() + key.slice(1), key: key }
                  )
                }
              }
              exportData[0] = item2
              exportData.forEach((item) => {


                for (let key in item) {
                  if (item[key] && item[key].name) {
                    item[key] = item[key].name;
                  }
                  if (key === "matchStatusNotes") {
                    if (item[key] && item[key].length > 0) {
                      for (var obj of item[key]) {
                        if (obj.note) {
                          data.push(obj.note)
                        }
                      }
                      item[key] = this.getArrayVal(JSON.stringify(data))
                    } else {
                      item[key] = null
                    }
                  }
                }
                for (let key in item) {
                  if (item[key] && item[key].name) {
                    item[key] = item[key].name;
                  }

                  if (key === "matchStatusNotes" || key === "notes") {
                    if (item[key] && item[key].length > 0) {
                      for (var obj of item[key]) {
                        if (obj.note) {
                          data.push(obj.note)
                        }
                      }
                      item[key] = this.getArrayVal(JSON.stringify(data))
                      data = []
                    } else {
                      item[key] = null
                    }
                  } else if (typeof key === "array" || ((key === "researchNotes" || key === "sellerNegativeNoticeNotes" || key === "requestDescription") && item[key] && item[key].length > 0)) {
                    item[key] = this.getArrayVal(JSON.stringify(item[key]))
                  }
                }
                item = exportData[0]
              });
              this.setState({
                exportData: exportData,
                exportHeaders: headers,
                isLoading: false,
                onGoing: false
              }, () => {
                // click the CSVLink component to trigger the CSV download
                this.csvLinkRef.link.click();
                this.setState({
                  exportData: [],
                  isexported: false
                })
              });
            } else {
              this.setState({
                isLoading: false
              });
            }
          }).catch((err) => {
            this.setState({
              isLoading: false
            });
            return err;
          });
      }
    }
  }
  /**
   * 
   * @returns only required fields in data to export
   */
  getCustumisedDatatoExport = async () => {
    var customisedExportData = []
    if (this.props.type === "UP Opportunity") {
      let Data = ["EstateName", "GreenCOUNT", "GreenPropertyMinValueAmount", "GreenPropertyMaxValueAmount", "BlueCOUNT", "BluePropertyMinValueAmount", "BluePropertyMaxValueAmount"]
      if (this.state.summaryObject === undefined) {
        await this.getUsersSummaryData("", this.state.addFilters, "export")
      } else {
        await this.getUsersSummaryData(this.state.summaryObject, this.state.addFilters, "export")

      }
      let allUsersData = this.state.allUsersData
      if (allUsersData) {
        for (var obj of allUsersData) {
          let dataitem = {}
          dataitem.EstateName = obj.EstateName
          for (var item of obj.buckets) {
            if (item.bucket === "Green") {
              dataitem.GreenCOUNT = item["COUNT(ns᎐CompanyID)"]
              dataitem.GreenPropertyMinValueAmount = item["PropertyMinValueAmountSum"]
              dataitem.GreenPropertyMaxValueAmount = item["PropertyMaxValueAmountSum"]
            }
            if (item.bucket === "Blue") {
              dataitem.BlueCOUNT = item["COUNT(ns᎐CompanyID)"]
              dataitem.BluePropertyMinValueAmount = item["PropertyMinValueAmountSum"]
              dataitem.BluePropertyMaxValueAmount = item["PropertyMaxValueAmountSum"]
            }
          }
          customisedExportData.push(dataitem)
        }
        this.setState({
          exportData: customisedExportData,
          exportHeaders: Object.keys(customisedExportData[0]),
        }, () => {
          // click the CSVLink component to trigger the CSV download
          this.csvLinkRef.link.click();

        });
      }
    } else {
      let bodyData;
      if (this.props.customisedExport || this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" || this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties") {
        bodyData = this.state.postBodyFile
      }
      return fetchMethodRequest('POST', `${this.state.apiUrlforExport}&type=exportToCsv`, bodyData)
        .then(async (response) => {
          if (response && response[this.props.apiResponseKey] && response[this.props.apiResponseKey].length && response[this.props.apiResponseKey].length > 0) {
            if (this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap") {
              customisedExportData = response["summary"] ? response["summary"] : []
            } else {
              customisedExportData = response["results"] ? response["results"] : response["filingPlanningSummary"]
            }
            let headers = Object.keys(customisedExportData[0])
            const index = headers.indexOf("_id");
            if (index > -1) { // only splice array when item is found
              headers.splice(index, 1); // 2nd parameter means remove one item only
            }
            this.setState({
              exportData: customisedExportData,
              exportHeaders: headers,
            }, () => {
              // click the CSVLink component to trigger the CSV download
              this.csvLinkRef.link.click();

            });
          }

        }).catch((err) => {
          return err;
        })


    }


  }

  //getAll Call
  /**
   * Getting the attributes from api call to send to form modal
   */
  attributesgetCall = async () => {
    fetchMethodRequest('GET', apiCalls.getAll)
      .then(async (response) => {
        if (response) {
          await this.setState({
            getAllresponse: response
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * 
   * @param {Object} item 
   * Method to get the notes based on id for showing as a model
   */
  showNotesData = async (item) => {
    fetchMethodRequest('GET', `NsScoredProperties/propertyNotes?propertyId=${item.propertyID}&companyId=${this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.state.PropertyData.selectedSeller.EstateID : item.companyID}`)
      .then(async (response) => {
        if (response) {
          await this.setState({
            validatedNotesId: item.propertyID,
            getNotesresponse: response.notes,
            isValidData: true
          })
        }
      }).catch((err) => {
        return err;
      });
  }
  /**
   * 
   * @param {Object} item 
   * @returns opening the seller in new tab based on icon beside edit
   */
  openSellerInNewTab = async (item) => {
    let url = `${window.location.protocol}//${window.location.host}/sellersEdit/deal/${item.DealID}`
    return <a href={url} target="_blank"> </a>
  }
  openErrorIcon = async (item) => {
    await this.propertyLoaderRef.setErrorFilesBasedOnRowClick(item)
  }
  /**
   * Opening the form modal and setting up the values required
   */

  openFormModal = async (rowData, type, claimType, paymentItem, index) => {
    if (this.props.customForm) {
      this.props.customForm(rowData)
    } else {
      if (claimType === "Payments") {
        this.setState({
          formFields: this.props.formFields,
        })
      } else if (claimType === "ClaimStatus") {
        this.setState({
          formFields: this.props.claimStatusFormFields,
        })
      }
      if ((this.props.type === "Attributes" && type === 'edit') || this.props.type === "Rules") {
        this.attributesgetCall()
      }
      if (rowData === 'add') {
        if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
          await this.setState({
            issellerModal: true,
            formType: 'add',
          });
        } else if (this.props.type === "crMatrix") {
          await this.setState({
            iscrMatrixModal: true,
            formType: 'add',
          });
        } else {
          await this.setState({
            formType: 'add',
          });
        }
      }
      if (this.props.type !== "List View" && this.props.type !== "Site Confirmation Editor" && this.props.type !== "RFI View" && this.props.type !== "Negative Notice List Viewer" && this.props.type !== "HighValueProperties" && this.props.type !== "Research Request Viewer" && this.props.type !== "Seller Negative Notice Viewer" && this.props.type !== "Property Editor" && this.props.type !== "Recovery Planner V2" && this.props.type !== "Sellers" && this.props.type !== "crMatrix" && this.props.type !== "Rules Configuration Workflow" && this.props.type !== 'Deal Mgmt') {
        await this.setState({
          isOpenFormModal: true,
          formType: type ? type : 'add',
          selectedRows: ''
        });
      }
      if (type === 'view') {
        if (this.state.isOpenFormModal && this.formModalRef) {
          await this.formModalRef.getViewData(rowData);
        }
      }

      if (type === 'edit') {
        if (this.state.isOpenFormModal && this.formModalRef) {
          await this.setState({
            selectedInfo: rowData

          })
          await this.formModalRef.getRowData(rowData, 'edit', paymentItem ? paymentItem : "", index);
        }
        if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
          await this.setState({
            issellerModalEdit: true,
            sellerID: rowData.DealID,
          });
        } else if (this.props.type === "crMatrix") {
          await this.setState({
            iscrMatrixModalEdit: true,
            crMatrixId: rowData.Version,
          });
        }
        var data = rowData && rowData.title ? rowData && rowData.title.includes("RFI") : false
        if (this.props.type === "List View" || this.props.type === "HighValueProperties" || this.props.type === "Site Confirmation Editor" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Recovery Planner V2" || this.props.type === "Rules Configuration Workflow" && type) {
          await this.setState({
            taskrowData: rowData,
            selectedObj: rowData,
            formType: type ? type : 'add',
            iskanbanModal: data === true ? false : true,
            isOpenRFIModal: data === true ? true : false,
          });
        }
      }

      if (this.props.type === "List View" || this.props.type === "HighValueProperties" || this.props.type === "RFI View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Recovery Planner V2" || this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Rules Configuration Workflow") {
        var data = rowData && rowData.title ? rowData.title.includes("RFI") : false
        await this.setState({
          selectedObj: {},
          formType: type ? type : 'add',
          iskanbanModal: data === true ? false : true,
          isOpenRFIModal: data === true ? true : false,
        });
      }
    }
  }

  //close form modal
  /**
   * method to disable all atributes to close edit model
   */
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
      iskanbanModal: false,
      createTaskModal: false,
      issellerModal: false,
      createTask: "",
      iskanbanModalForHighProperties: false,
      createTaskAdd: "",
      iskanbanModalForHighProperties: false,
      iscrMatrixModal: false,
      sellerEditType: ""
    })
    if (this.state.isOpenTable && this.tableModalRef) {
      this.tableModalRef.getTaskDetails()
    }
    if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
      this.getDataFromServer(this.state.filterCriteria)
    }

  }
  /**
   * 
   * @param {String} e 
   * Closing the task model and display message for already connected
   */
  cancelCreateTaskModal = async (e) => {
    let propertyIds = this.state.selectedRows.filter(u => u.connectedTaskID != null && u.connectedTaskID.includes(e)).map(u => u.propertyID).toString()
    await this.setState({ createTaskModal: false, createTask: "", createTaskAdd: "" })
    this.getErrorMessage(`Properties are already connected to selected Task with Opra property ids ${propertyIds}`, "", "error")
  }
  /**
   * 
   * @param {String} e 
   * Closing the kanban model and display message for already connected
   */
  cancelKanbanModal = async (e) => {
    let propertyIds = this.state.selectedRows.filter(u => u.connectedTaskID != null && u.connectedTaskID.includes(e)).map(u => u.propertyID).toString()
    await this.setState({ iskanbanModal: false, iskanbanModalForHighProperties: false, createTask: "", createTaskAdd: "" })
    this.getErrorMessage(`Properties are already connected to selected Task with Opra property ids ${propertyIds}`, "", "error")
  }



  handleunassigned = async (e) => {
    await this.setState({
      unassignedOnly: this.state.unassignedOnly === true ? false : true
    })
    if (this.state.unassignedOnly) {
      await this.setState({
        first: 0
      })
      this.state.filterCriteria.page = 1
    }
    this.getDataFromServer(this.state.filterCriteria)
  }
  onChangeDepartment = async (e, type, departmentIdArray) => {
    let departmentArr = {
      departMentIdArray: departmentIdArray,
      selectedDepartment: e,
    }
    await this.setState({
      [type]: e,
      departmentIdArray: departmentIdArray,
      departmentArr: departmentArr
    })
    // await this.getDataFromServer(this.state.filterCriteria)
  }

  onChangeDepartmentForMap = async (e, type, departmentIdArray) => {
    let departmentArr = {
      departMentIdArray: departmentIdArray,
      selectedDepartment: e,
    }
    await this.setState({
      [type]: e,
      departMentIdArray: departmentIdArray,
      departmentArr: departmentArr
    })
    // await this.getDataFromServer(this.state.filterCriteria)
  }
  onChangeDepartmentForReports = async (e) => {
    let filterCriteria = this.state.filterCriteria
    let DepartmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        DepartmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    //departMentIdArray: departmentIdArray, selectedDepartment: e.value, Department: e.value,
    await this.setState({ resourceNeededDepartment: e.value, resourceNeededDepartmentId: departmentIdArray })
    this.getDataFromServer(filterCriteria)

  }

  showParentModal = (field, item) => {
    let recordVal = field.includes("group1") ? item["group1Parent"] : field.includes("group2") ? item["group2Parent"] : field.includes("group3") ? item["group3Parent"] : {}
    let myArray = []
    myArray.push(recordVal)
    console.log("tyyt", this.open)
    this.setState({
      myArray:myArray,
      selectedRecordChildid:item.propertyID
    })
  }

  setOverlayPanelDescription() {
    let colors = ["#12853C", "#F4A61E", "#256BD1"]
    return (
      <div>
        <div class="btn-group-vertical tooltipClaims mt-2" style={{ marginRight: "0px", height: "30px" }} onClick={(e) => this.op.toggle(e)} data-toggle="tool-tip"
          title="click to see definations" >
          <button style={{ backgroundColor: "#12853C" }} type="button" class="btn btn-secondary overlayPanelForReport mb-0"></button>
          <button style={{ backgroundColor: "#F4A61E" }} type="button" class="btn btn-secondary overlayPanelForReport mb-0"></button>
          <button style={{ backgroundColor: "#256BD1" }} type="button" class="btn btn-secondary overlayPanelForReport"></button>
        </div>
        <OverlayPanel style={{ left: "1234px", right: "66px" }} appendTo={document.body} ref={(el) => this.op = el} showCloseIcon={true} dismissable={true}>
          {
            this.props.modifiedLabels && this.props.modifiedLabels.length > 0 ? this.props.modifiedLabels.map((item, index) => {
              return (
                <div className='row ml-1'>
                  <button style={{ backgroundColor: colors[index], color: "white" }} type="button" class="btn btn-secondary overlayPanelForReportButton">{item.value.name}</button>
                  <label style={{ width: "73%" }}>
                    {item.value.description}
                  </label>
                </div>
              )
            }) : null
          }
        </OverlayPanel>
      </div>
    )
  }
  getStateFIlingValues = async (filter, subCategory, category, stateSubCategories, stateDealType, UpState, type) => {
    if (!filter.page) {
      filter.page = 1
    }
    if (type === "clear") {
      this.getremoveFilters()
    }
    if (!filter.limit) {
      filter.limit = this.state.rows
    }
    this.setState({
      isLoading: true, reasonByRow: false
    })
    if (filter && filter.criteria.length > 0) {
      return fetchMethodRequest('GET', `StateUpFiling?filter=${JSON.stringify(filter)}`)
        .then(async (response) => {
          if (response && response.stateUpFilings && response.stateUpFilings.length > 0) {
            await this.setState({
              allUsersData: JSON.parse(JSON.stringify(response.stateUpFilings)),
            })
            // await this.showDatatable("true")
          } else if (response && response.stateUpFilings && response.stateUpFilings.length === 0) {
            await this.setState({
              editedindex: null,
              attributeFields: [],
              allUsersData: []
            })
            await this.showDatatable("true")
          } else if (response && response.errorMessage) {
            await this.setState({
              editedindex: null,
              attributeFields: [],
            })
          }
          await this.setState({
            progress: 100,
            responsesaveData: JSON.parse(JSON.stringify(response.stateUpFilings)),
            isLoading: false,
            disableIcon: false,
            isDisabledTab: false,
            filterCriteria: filter,
            stateSubCategory: subCategory,
            stateCategory: category,
            stateDealType: stateDealType,
            UpState: UpState,
            stateSubCategories: stateSubCategories,
            totalRecordsLength: response.pagination.totalCount,
          })
        }).catch((err) => {
          return err;
        });
    }



  }
  OpenConfirmaionModelorSave = async () => {
    await this.setState({
      isopenConfirmaionModelforSave: true
    })
  }

  closeConfirmationModalforSave = async (type) => {
    await this.setState({
      isopenConfirmaionModelforSave: false
    })
    if (type === "close") {
      await this.getDataFromServer()
      this.CancelMultiLine()
    } else {
      this.handleCheckValidations()
    }
  }

  getClaimSummary() {
    let Id = localStorage.getItem('claimSummaryID');
    Id = JSON.parse(Id);
    return (
      <>
        <ClaimSummary
          onRef={(ref) => this.propertyFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          getFilteredValues={this.getFilteredValues}
          sendUpPropConfirmation={this.sendUpPropConfirmation}
          getErrorMessage={this.getErrorMessage}
          showTable={this.showDatatableClaimSummary}
          type={this.props.type}
          getFileOptions={this.getFileOptions}
          DealId={Id}
          settingsData={this.props.settingsData}
          getErrorResponseFromServer={this.getErrorResponseFromServer}
          uniqueAddressLines={this.state.uniqueAddressLines}
          uniquePropertyStatuses={this.state.uniquePropertyStatuses}
          uniqueStates={this.state.uniqueStates}
          getDataFromServer={this.getDataFromServer}
        />
      </>
    )
  }
  getStateUpFiling() {
    return (
      <>
        <StateUP
          onRef={(ref) => this.propertyFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          getFilteredValues={this.getStateFIlingValues}
          sendUpPropConfirmation={this.sendUpPropConfirmation}
          getErrorMessage={this.getErrorMessage}
          showTable={this.showDatatable}
          type={this.props.type}
          getFileOptions={this.getFileOptions}
          settingsData={this.props.settingsData}
          getErrorResponseFromServer={this.getErrorResponseFromServer}
          uniqueAddressLines={this.state.uniqueAddressLines}
          uniquePropertyStatuses={this.state.uniquePropertyStatuses}
          uniqueStates={this.state.uniqueStates}
          getDataFromServer={this.getDataFromServer}
          toggleSideBar={this.props.toggleSideBar} />
      </>
    )
  }
  selectedOpra = async (val) => {
    await this.setState({ selectedOpra: val })
    if (this.props.selectedOpra) {
      await this.props.selectedOpra(val)
    }
    if (this.state.PropertyData && this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedSeller.EstateID && this.editorComponentRef) {
      this.editorComponentRef.getFileForEditor(this.state.PropertyData.selectedSeller.EstateID)
      this.editorComponentRef.getFileForEditorForSellerRelevanceName(this.state.PropertyData.selectedSeller.EstateID)

    }
  }
  getpropertyFilters() {
    return (
      <>
        <PropertyFilter
          onRef={(ref) => this.propertyFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          openFormModal={this.openFormModal}
          groupMessage={this.state.groupMessage}
          OpenConfirmaionModelorSave={this.OpenConfirmaionModelorSave}
          getFilteredValues={this.getFilteredValues}
          getremoveFilters={this.getremoveFilters}
          defaultExclusionNote={this.state.defaultExclusionNote}
          getTableFieldItem={this.getTableFieldItem()}
          sendUpPropConfirmation={this.sendUpPropConfirmation}
          isDisabledTab={this.state.isDisabledTab}
          openShowHideColumnsModal={this.openShowHideColumnsModal}
          allUsersData={this.state.allUsersData}
          tabDescriptionArray={this.props.tabDescriptionArray}
          loggedUserName={this.state.loginData.displayName}
          clearData={this.clearData}
          selectedOpra={this.selectedOpra}
          isShowTable={this.isShowTable}
          getUrlbyParams={this.getUrlbyParams}
          PropertySharebody={this.state.PropertySharebody}
          lastScoredDate={this.state.lastScoredDate}
          getErrorMessage={this.getErrorMessage}
          type={this.props.type}
          activeTab={this.state.activeTab}
          missingOPRAPropertyIds={this.state.response && this.state.response.missingOPRAPropertyIds ? this.state.response.missingOPRAPropertyIds : []}
          missingStatePropertyIds={this.state.response && this.state.response.missingStatePropertyIds ? this.state.response.missingStatePropertyIds : []}
          selectedViewType={this.selectedViewType}
          getFileOptions={this.getFileOptions}
          settingsData={this.props.settingsData}
          getErrorResponseFromServer={this.getErrorResponseFromServer}
          uniqueAddressLines={this.state.uniqueAddressLines}
          uniquePropertyStatuses={this.state.uniquePropertyStatuses}
          uniqueStates={this.state.uniqueStates}
          getDataFromServer={this.getDataFromServer}
          IssueCodes={this.state.IssueCodes} />

      </>
    )
  }

  getDealSizingpropertyFilters() {
    return (
      <>
        <DealSizingPropertyFilter
          onRef={(ref) => this.dealSizingpropertyFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          openFormModal={this.openFormModal}
          groupMessage={this.state.groupMessage}
          OpenConfirmaionModelorSave={this.OpenConfirmaionModelorSave}
          getFilteredValues={this.getFilteredValues}
          getremoveFilters={this.getremoveFilters}
          defaultExclusionNote={this.state.defaultExclusionNote}
          getTableFieldItem={this.getTableFieldItem()}
          sendUpPropConfirmation={this.sendUpPropConfirmation}
          isDisabledTab={this.state.isDisabledTab}
          openShowHideColumnsModal={this.openShowHideColumnsModal}
          allUsersData={this.state.allUsersData}
          tabDescriptionArray={this.props.tabDescriptionArray}
          loggedUserName={this.state.loginData.displayName}
          clearData={this.clearData}
          selectedOpra={this.selectedOpra}
          isShowTable={this.isShowTable}
          getUrlbyParams={this.getUrlbyParams}
          PropertySharebody={this.state.PropertySharebody}
          lastScoredDate={this.state.lastScoredDate}
          getErrorMessage={this.getErrorMessage}
          type={this.props.type}
          activeTab={this.state.activeTab}
          missingOPRAPropertyIds={this.state.response && this.state.response.missingOPRAPropertyIds ? this.state.response.missingOPRAPropertyIds : []}
          missingStatePropertyIds={this.state.response && this.state.response.missingStatePropertyIds ? this.state.response.missingStatePropertyIds : []}
          selectedViewType={this.selectedViewType}
          getFileOptions={this.getFileOptions}
          settingsData={this.props.settingsData}
          getErrorResponseFromServer={this.getErrorResponseFromServer}
          uniqueAddressLines={this.state.uniqueAddressLines}
          uniquePropertyStatuses={this.state.uniquePropertyStatuses}
          uniqueStates={this.state.uniqueStates}
          getDataFromServer={this.getDataFromServer}
          IssueCodes={this.state.IssueCodes} />

      </>
    )
  }


  getSearchedCompany = async => {
    return this.state.searchedCompany
  }

  getdataTableFields = async () => {
    let tableFields = this.props.getTableFields();
    await this.setState({
      tableFields: tableFields,
      originalTableFields: tableFields,
      tablefieldsToShow: tableFields,
    })
  }

  getSelectedFilterValuesOpenCorporate = async (criteria, data, searchedCompany) => {
    await this.setState({
      StatCriteria: criteria,
      criteriaStatis: JSON.stringify(criteria),
      downloadData: [],
      allUsersData: [],
      searchedCompany: searchedCompany
    })
    this.isShowTable(true)

    await this.getdataTableFields()

    this.getDataFromServer(this.state.criteria)
  }

  getSelectedFilterValues = async (criteria, data, status, groupByField) => {

    let getlocaldata = JSON.parse(localStorage.getItem("tableFields_download"))
    if (this.state.activeTab === "Summary") {
      await this.setState({ SummaryCriteria: criteria })
    }
    await this.setState({
      StatCriteria: criteria,

      groupByField: groupByField,
      criteriaStatis: JSON.stringify(criteria),
      downloadData: [],
      // tablefieldsToShow: data,
      tablefieldsToShow: data.length > 0 ? data : data && getlocaldata["Summary"] ? getlocaldata["Summary"] : [],
      allUsersData: [],
      activeTab: "Summary",
      validationStatus: status === "onlyFiledandPaid" ? "OnlyFiledOrPaid" : status === "positivelyValidated" ? "OnlyPositivelyValidated" : status
    })
    this.isShowTable(true)
    // this.getDataFromServer(this.state.criteria)
    this.getSelectedTabTypeInfo("Summary", "", this.state.tablefieldsToShow)
  }

  getSelectedFilterValuesFactSet = async (criteria, data, searchedCompany) => {

    let getlocaldata = JSON.parse(localStorage.getItem("tableFields_download"))

    await this.setState({
      StatCriteria: criteria,
      criteriaStatis: JSON.stringify(criteria),
      downloadData: [],
      // tablefieldsToShow: data,
      // tablefieldsToShow: data.length > 0 ? data : data && getlocaldata["FactSet Entity Subsidiary Count"] ? getlocaldata["FactSet Entity Subsidiary Count"] : [],
      allUsersData: [],
      activeTab: "FactSet Entity Subsidiary Count",
      searchedCompany: searchedCompany
    })
    this.isShowTable(true)

    // this.getDataFromServer(this.state.criteria)
    this.getSelectedTabTypeInfo("FactSet Entity Subsidiary Count", "", null, true)

  }

  getSelectedFilterValuesNamesLookup = async (criteria, searchedBusiness, selectedStatus, selectedStatusDetails) => {

    let getlocaldata = JSON.parse(localStorage.getItem("tableFields_download"))

    await this.setState({
      StatCriteria: criteria,
      criteriaStatis: JSON.stringify(criteria),
      downloadData: [],
      allUsersData: [],
      searchedBusiness: searchedBusiness,
      selectedStatus: selectedStatus,
      selectedStatusDetails: selectedStatusDetails
    })
    this.isShowTable(true)

    this.getDataFromServer(this.state.criteria)
  }

  getdealFilters() {
    return (
      <>
        <DealStatistics
          onRef={(ref) => this.dealStatsFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          groupMessage={this.state.groupMessage}
          getFilteredValues={this.getSelectedFilterValues}
          loggedUserName={this.state.loginData.displayName}
          lastScoredDate={this.state.lastScoredDate}
          getErrorMessage={this.getErrorMessage}
          activeTab={this.state.activeTab}
          selectedViewType={this.state.selectedViewType}
          getTableFields={this.props.getTableFields()}
          tableFieldsToShow={this.state.tablefieldsToShow}
          originalTableFields={this.state.originalTableFields}
          getDataFromServer={this.getDataFromServer}
        />

      </>
    )
  }

  getFactSetFilters() {
    return (
      <>
        <FactSetFilter
          onRef={(ref) => this.FactSetFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          groupMessage={this.state.groupMessage}
          getFilteredValues={this.getSelectedFilterValuesFactSet}
          loggedUserName={this.state.loginData.displayName}
          lastScoredDate={this.state.lastScoredDate}
          getErrorMessage={this.getErrorMessage}
          activeTab={this.state.activeTab}
          selectedViewType={this.state.selectedViewType}
          getTableFields={this.props.getTableFields()}
          tableFieldsToShow={this.state.tablefieldsToShow}
          originalTableFields={this.state.originalTableFields}
          getDataFromServer={this.getDataFromServer}
          FactSetCompanyDetailsData={this.state.FactSetCompanyDetailsData}
          getDataToExport={this.getDataToExport}
          allUsersData={this.state.allUsersData}
          ParentId={this.state.tabNo}
          type={this.props.type}
          updateDateFormat={this.updateDateFormat}
        />

      </>
    )
  }

  getOpenCorporateFilters() {

    return (
      <>
        <OpenCorporateFilter
          onRef={(ref) => this.OpenCorporateFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          groupMessage={this.state.groupMessage}
          getFilteredValues={this.getSelectedFilterValuesOpenCorporate}
          loggedUserName={this.state.loginData.displayName}
          lastScoredDate={this.state.lastScoredDate}
          getErrorMessage={this.getErrorMessage}
          activeTab={this.state.activeTab}
          selectedViewType={this.state.selectedViewType}
          getTableFields={this.props.getTableFields()}
          tableFieldsToShow={this.state.tablefieldsToShow}
          originalTableFields={this.state.originalTableFields}
          displayErrorMessage={this.state.displayErrorMessage}
          errorMessage={this.state.errorMessage}
          getDataFromServer={this.getDataFromServer}
          getDataToExport={this.getDataToExport}
          allUsersData={this.state.allUsersData}
          ParentId={this.state.tabNo}
          type={this.props.type}
          updateDateFormat={this.updateDateFormat}
        />

      </>
    )
  }

  getNamesLookupFilters() {
    return (
      <>
        <NamesLookupFilter
          onRef={(ref) => this.NamesLookupFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          groupMessage={this.state.groupMessage}
          getFilteredValues={this.getSelectedFilterValuesNamesLookup}
          loggedUserName={this.state.loginData.displayName}
          lastScoredDate={this.state.lastScoredDate}
          getErrorMessage={this.getErrorMessage}
          activeTab={this.state.activeTab}
          selectedViewType={this.state.selectedViewType}
          getTableFields={this.props.getTableFields()}
          tableFieldsToShow={this.state.tablefieldsToShow}
          originalTableFields={this.state.originalTableFields}
          getDataFromServer={this.getDataFromServer}
          NamesLookupCompanyDetailsData={this.state.NamesLookupCompanyDetailsData}
          getDataToExport={this.getDataToExport}
          allUsersData={this.state.allUsersData}
          ParentId={this.state.tabNo}
          type={this.props.type}
          updateDateFormat={this.updateDateFormat}
        />

      </>
    )
  }

  searchTermOnSite() {
    // this.setState({ isShowTable: false })
    return (
      <>
        <SearchTermOnSiteFilter
          onRef={(ref) => this.propertyFilterRef = ref}
          filterCriteria={this.state.filterCriteria}
          getFilteredValues={this.getFilteredValues}
          clearOnsitemessage={this.clearOnsitemessage}
          sendUpPropConfirmation={this.sendUpPropConfirmation}
          loggedUserName={this.state.loginData.displayName}
          clearData={this.clearData}
          lastScoredDate={this.state.lastScoredDate}
          getErrorMessage={this.getErrorMessage}
          type={this.props.type}
          getFileOptions={this.getFileOptions}
          settingsData={this.props.settingsData}
          getErrorResponseFromServer={this.getErrorResponseFromServer}
          uniqueAddressLines={this.state.uniqueAddressLines}
          uniquePropertyStatuses={this.state.uniquePropertyStatuses}
          uniqueStates={this.state.uniqueStates}
          getDataFromServer={this.getDataFromServer}
          IssueCodes={this.state.IssueCodes} />

      </>
    )
  }
  getConfirmaionModelforSave() {
    return (
      <ConfirmaionModelorSave
        openConfirmationModal={this.state.isopenConfirmaionModelforSave}
        closeConfirmationModal={this.closeConfirmationModalforSave}
        shownText={"Are you sure you would like to undo the updated changes for claims?"}
      // confirm={this.handleCheckValidations}
      />
    )
  }

  getManuallyLoadedProperties() {
    return (
      <ManuallyLoaded
        onRef={(ref) => this.ManuallyLoadedRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        getErrorMessage={this.getErrorMessage}
        getDataFromServer={this.getDataFromServer}
      />
    )
  }
  setDataCriteriaforOpportunity = async (criteria, obj, clear) => {
    await this.setState({
      postBody: obj,
      postBodyFile: obj,
      filterCriteria: criteria,
      rows: criteria.limit,
      page: criteria.page,
      first: 0
    })
    if (clear && clear === "clear") {
      this.setState({ postBody: undefined })
    }
    if (this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State") {
      this.setState({ setPage: true, first: 0, })
    }
    this.getDataFromServer(criteria, "", "", this.state.postBody)
  }
  getRequiredEvidenceFilters() {

    return (
      <EvidenceSummary
        // onRef={(ref) => this.propertyFilterRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        getDataFromServer={this.getDataFromServer} />
    )
  }
  HighValuePropertyFilters() {

    return (
      <HighValuePropertyFilters
        // onRef={(ref) => this.propertyFilterRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        getDataFromServer={this.getDataFromServer} />
    )
  }
  getpropertySummaryFilters() {

    return (
      <Summary
        // onRef={(ref) => this.propertyFilterRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        getDataFromServer={this.getDataFromServer} />
    )
  }
  getAvailableToMapFilters() {

    return (
      <AvailableToMapFilters
        // onRef={(ref) => this.propertyFilterRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        onChangeDepartment={this.onChangeDepartmentForMap}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        getDataFromServer={this.getDataFromServer} />
    )
  }
  getAvailableToReMapFilters() {

    return (
      <AvailableToReMapFilters
        // onRef={(ref) => this.propertyFilterRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        onChangeDepartment={this.onChangeDepartmentForMap}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        getDataFromServer={this.getDataFromServer} />
    )
  }
  getPropertyLoaderFilters() {

    return (
      <PropertyLoaderFilters
        onRef={(ref) => this.propertyLoaderRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        onChangeDepartment={this.onChangeDepartmentForMap}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        getDataFromServer={this.getDataFromServer} />
    )
  }
  getPropertySearchV2Filters() {
    return (
      <PropertySearchV2Filters
        onRef={(ref) => this.propertyFilterv2Ref = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        closeDataTable={this.closeDataTable}
        onChangeDepartment={this.onChangeDepartmentForMap}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        getDataFromServer={this.getDataFromServer} />
    )
  }
  getAvailableFileFilters() {

    return (
      <AvailableFile
        onRef={(ref) => this.availableFileRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        setTeam={this.setTeam}
        getDataFromServer={this.getDataFromServer} />
    )
  }
  getAvailableFileFiltersConfirmOnSite() {

    return (
      <AvailableFileConfirmOnSite
        onRef={(ref) => this.availableFileConfirmOnSiteRef = ref}
        filterCriteria={this.state.filterCriteria}
        type={this.props.type}
        tabDescriptionArray={this.props && this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0] && this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value ? this.props.tabDescriptionArray[this.state.tabNo ? this.state.tabNo : 0].value["description"] : ""}
        tabNo={this.state.tabNo ? this.state.tabNo : 0}
        getErrorMessage={this.getErrorMessage}
        tabType={this.state.activeTab}
        getUsersSummaryData={this.getUsersSummaryData}
        setDataCriteriaforOpportunity={this.setDataCriteriaforOpportunity}
        getDataFromServer={this.getDataFromServer} />
    )
  }


  submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          this.setState({ sessionExpiryModal: true })
        }
        await this.setState({
          openDeleteModal: false,
          actions: '',
          selectedRows: '',
          selectedRowsId: ''
        });
        if (response && response.respCode) {
          // showToasterMessage(response.respMessage, 'success');
          this.getDataFromServer();
        } else if (response && response.errorMessage) {
          //showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  handleActions = async (apiType) => {
    let apiUrl = this.state.apiUrl;
    let method, url, body = {}
    if (this.state.confirmType === 'Delete' || this.state.confirmType === 'Block' || this.state.confirmType === 'UnBlock') {
      url = `${apiUrl}/${apiType}`;
      method = 'POST';
      body = {
        selectedIds: this.state.selectedRowsId
      }
    }
    this.submitActionsData(method, url, body)
  }

  // delete selected row
  deleteSelectedRow = async () => {
    let apiUrl = this.state.apiUrl;
    let method, url, body = {}
    let id = this.state.deleteRowDataId._id ? this.state.deleteRowDataId._id : this.state.deleteRowDataId
    url = `${apiUrl}/${id}`;
    // hari why this crud
    if (this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue") {
      method = "PUT"
      this.state.deleteRowDataId.status = "Cancel"
      body = this.state.deleteRowDataId
    } else {
      method = 'DELETE';
      body = ''
    }
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          this.setState({ sessionExpiryModal: true })
        }
        await this.setState({
          openDeleteModal: false
        });
        if (response && response.respCode) {
          //showToasterMessage(response.respMessage, 'success');
          this.getDataFromServer(this.state.filterCriteria);
        } else if (response && response.errorMessage) {
          // showToasterMessage(response.errorMessage, 'error');
          this.getErrorMessage(response.errorMessage, "", "error")
        }
      }).catch((err) => {
        return err;
      });
  }

  updateMails = async () => {
    let apiUrl = apiCalls.queueMails;
    let method, url, body = {}

    let id = this.state.selectedIndexItem._id

    const arrayOfUserNames = this.state.SelectedMails.map(obj => obj.userName);

    url = `${apiUrl}/${id}?flag=true`;
    method = "PUT"
    body = { mails: arrayOfUserNames }

    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          this.setState({ sessionExpiryModal: true })
        }
        await this.setState({
          SelectedMails: []
        });
        if (response && response.respCode) {
          //showToasterMessage(response.respMessage, 'success');
          this.getDataFromServer(this.state.filterCriteria);
        } else if (response && response.errorMessage) {
          // showToasterMessage(response.errorMessage, 'error');
          this.getErrorMessage(response.errorMessage, "", "error")
        }
      }).catch((err) => {
        return err;
      });
  }

  //close delete modal
  closeDeleteModal = async () => {
    this.setState({
      openDeleteModal: false,
      actions: ''
    })
  }

  // conformation for delete item
  deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    let selectedRows = [...this.state.selectedRows]
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })
      await this.setState({
        selectedRowsId: selectedRowsId,
        openDeleteModal: true,
        selectActions: selectActions,
      })
    }
    if (rowData) {
      this.setState({
        deleteRowDataId: rowData,
        openDeleteModal: true,
        selectActions: selectActions,
      });
    }
  }

  /**
   * return column obj
   */
  tablefieldsforGrid = async (columnObj) => {
    if (columnObj) {
      await this.setState({
        isLoading: true,
      });
      this.getTableFieldsOrder()
    } else {
      return this.state.tableFieldsToShow
    }
  }

  //change dropdown elememt
  changeDropDownElement = (event) => {
    this.dt.filter(event.target.value, event.target.name, 'equals');
    this.setState({
      [event.target.name]: event.value
    });
  }

  getTableFieldItem(field) {
    for (let i = 0; i < this.state.tablefieldsToShow.length; i++) {
      if (this.state.tablefieldsToShow[i].field === field) {
        return this.state.tablefieldsToShow[i];
      }
      if (field === "Payments") {
        return { fieldType: "PaymentArray" }
      }
      // if (field === "PaymentBtn") {
      //   return { fieldType: "PaymentBtn" }
      // }
      //condition to match fieldType
      if (this.state.tablefieldsToShow[i].fieldType === field) {
        return this.state.tablefieldsToShow[i]['field'];
      }
    }
    return null;
  }

  getColorFromOptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].color;
        }
      }
    }
    return 'success';
  }

  getUserData(_id, type) {
    let data = this.state.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  getIconValue(rowData, labelKey) {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  async getAUserData(_id, type) {
    let filterCriteria = { "limit": 20, "page": "1", "criteria": [], "sortfield": "CompanyName", "direction": "asc", "globalSearch": { "value": _id, "type": "user" } }
    let apiUrl = `${apiCalls.Companies}?filter=${JSON.stringify(filterCriteria)}`
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        await this.setState({ filterData: response.companies[0] })
      })
  }

  openViewModal = async (rowData, type, screen) => {
    if (screen && screen === "dashboard") {
      await this.getAUserData(rowData['_id'], '_id')
      rowData = this.state.filterData
    }
    if (this.props.type === "Company") {
      window.history.pushState(null, null, `${this.props.routeTo}`);
    }
    // if (rowData) {
    //   // window.history.replaceState(null, '', "http://localhost:3001/tree")
    //   // screen && screen == "dashboard" ? rowData = this.getAUserData(rowData['_id'], '_id') : null;

    //   let rowDataIndex = this.getUserData(rowData['_id'], '_id');
    //   // let rowDataIndex = this.getUserData(rowData['_id'], '_id');
    //   let _id = rowData['_id'] ? rowData['_id'] : "";
    //   let status = "Active"
    //   let keys = Object.keys(rowData);
    //   let formFields = [];
    //   if (formFields) {
    //     if (this.props.type) {
    //       if (rowData) {
    //         let values, self = this, icon = false;
    //         // hari get all the labels from 
    //         keys.forEach(async function (key) {
    //           let labelKey;
    //           labelKey = await self.getTableFieldItem(key);
    //           if (labelKey === null) {
    //             labelKey = key;
    //           } else {
    //             let val = rowData[key];
    //             if (labelKey.fieldType === 'icon') {
    //               val = self.getIconValue(rowData, labelKey);
    //               icon = true;
    //             }
    //             labelKey = labelKey.header
    //             if (val) {
    //               values = {
    //                 label: labelKey,
    //                 value: icon ? val : rowData[key]
    //               }
    //               formFields.push(values);
    //               icon = false;
    //             }

    //           }
    //           // if (key==='fromTime' || key==='toTime') {
    //           //   let date = dateFormats.addDaysToDate(rowData[key], 1);
    //           //   date = dateFormats.formatDate(date, config.timeFormat);
    //           //   values = {
    //           //     label: key,
    //           //     value: date
    //           //   }
    //           //   formFields.push(values);
    //           // }
    //         });

    //       }
    //     }

    //     if (this.state.displayViewOfForm === 'modal') {
    //       await this.setState({
    //         // openViewModal: true
    //         isOpenFormModal: true,
    //         userStatus: status,
    //         formType: type,
    //       });

    //       // await this.viewModalRef.getRowData(formFields);
    //       await this.formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id, 'investors');
    //     } else if (this.state.displayViewOfForm === 'screen') {
    //       let displayBreadCrumbField = this.getTableFieldItem('Link');

    //       await this.setState({
    //         isOpenFormModal: true,
    //         formType: type,
    //         displayBreadCrumbValue: rowData[displayBreadCrumbField],
    //         displayBreadCrumbField: displayBreadCrumbField,
    //         userStatus: status
    //       });

    //       await this.formModalRef.handleViewDisplay(rowData);
    //     }
    //   }
    // } else {
    //   if (this.state.displayViewOfForm === 'screen') {
    //     let displayBreadCrumbField = this.getTableFieldItem('Link');
    //     let rowData = [];
    //     await this.setState({
    //       isOpenFormModal: true,
    //       formType: type,
    //       // displayBreadCrumbValue: rowData[displayBreadCrumbField],
    //       // displayBreadCrumbField: displayBreadCrumbField,
    //       // userStatus: status
    //     });

    //     await this.formModalRef.handleViewDisplay(rowData);
    //   }
    // }
    // await this.getDataFromServer();
    if (rowData) {
      await this.setState({
        redirectCompanyId: rowData["CompanyID"] ? rowData["CompanyID"] : rowData["CIK"],
        CompanyDetailView: true
      })
    }
    else {
      await this.setState({
        // redirectCompanyId: id,
        CompanyDetailView: false
      })
    }
  }

  closeViewModal = async () => {
    this.setState({
      openViewModal: false
    });
  }

  //on changing pagination
  onPageChange = async (event) => {
    let newArray = this.checkDataisEdited()
    if (newArray && newArray.length > 0) {
      this.OpenConfirmaionModelorSave()
    } else {
      let filterCriteria = this.state.filterCriteria;
      if (event && event.rows) {
        let currentPage = event.page + 1;
        filterCriteria['limit'] = event.rows;
        filterCriteria['page'] = currentPage;
        this.setState({
          rows: event.rows,
          page: event.page,
          first: event.first,
          filterCriteria: filterCriteria,
          isLoading: true
        })
      }
      this.handlePercentage("1")
      if (this.props.isMatchproperties) {
        this.props.getResponseforSelectedPropertyData(filterCriteria)

      } else if (this.props.type === "State Attributes") {
        await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)

      } else {
        this.getDataFromServer(filterCriteria)
      }
    }
    if ((this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State") && this.state.setPage) {
      this.setState({ setPage: false, })
    }
  }

  scrollWin() {
    if (this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Properties" || this.props.type === "Deal Sizing Property Editor") {
      let filterCriteria = this.state.filterCriteria;
      if (filterCriteria.limit > 30)
        window.scrollTo(0, 600);
    }
  }

  onPageChangeAccordion = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    let id = this.state.openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      this.setState({
        [`rows${id}`]: event.rows,
        page: event.page,
        [`first${id}`]: event.first
      })
    }
    // this.getDataFromServer(filterCriteria)
  }

  //sorting fields
  sortChange = (event) => {
    let data = this.props.type === "Labels" || this.props.type === "Departments" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" ?
      'asc'
      : this.props.sortDirection ? this.props.sortDirection : "desc";
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField
      // if (this.state.activeTab === "SP") {
      //   sortField = `${event['sortField']}, ${this.state.filterCriteria.sortfield}`
      // } else {
      //   sortField = `${event['sortField']}`;
      // }
      if (this.state.activeTab === "SP") {
        sortField = `ParentPropertyID, PropertyReviewedFlag, ${event['sortField']}`
      } else {
        sortField = event['sortField'];
      }
      let filterCriteria = {
        direction: event.selectedOption === "unsort" ? data : event.selectedOption,
        sortfield: event.selectedOption === "unsort" ? this.props.sortField ? this.props.sortField : "created" : sortField,
        limit: this.state.filterCriteria.limit,
        page: this.state.filterCriteria.page,
        criteria: this.state.filterCriteria['criteria']
      }
      if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
        filterCriteria.globalSearch = this.state.filterCriteria.globalSearch
      }
      this.setState({
        sortCount: sortCount,
        isSortField: sortField,
        isLoading: true,
        filterCriteria: filterCriteria
      });
      this.handlePercentage('1')
      if (this.props.type === "State Attributes") {
        this.getStateFIlingValues(filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
      } else {
        this.getDataFromServer(filterCriteria)
      }
    }
  }

  selectedOptionData = async (e, key) => {
    if (this.props.type === "Site Confirmation Editor") {
      key = "pendingConfirmation"
    }
    if (e && key) {
      let type, value;
      await this.setState({
        selectedvalueforStatus: e
      })
      let criteria = this.state.filterCriteria
      if (e.length === 3) {
        type = "nin"
      } else if (e.length === 2) {
        type = "in"
      } else {
        type = "eq"
      }

      value = e
      let index = criteria["criteria"].findIndex(obj => obj.key === key)
      if (index !== -1) {
        criteria["criteria"][index].value = value
        criteria["criteria"][index].type = type
      } else {
        criteria["criteria"].push({ key: key, value: value, type: type, add_Filter: true })
      }
      let filterCriteria = {
        sortfield:
          e && e.length === 3 ? "endTime" : e && e.length === 2 ? "priority, status" : this.props.sortField,
        criteria: criteria["criteria"],
        limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : this.state.limit,
        direction: (e && e.length === 3 ? "desc" : e && e.length === 2 ? "asc" : this.props.sortDirection ? this.props.sortDirection : "desc")
      }
      await this.setState({
        filterCriteria: filterCriteria
      })

      this.getDataFromServer(this.state.filterCriteria)
    }
    this.changeTableFieldsbasedonOptionQueue(e)
    return e;
  }

  changeTableFieldsbasedonOptionQueue = async (value) => {

    let tablefieldsToShow = this.props.getTableFields()
    if (value && value.length === 3 && this.props.type === "Regular Scoring Queue") {
      tablefieldsToShow.splice(0, 2)
    }
    await this.setState({
      tablefieldsToShow: tablefieldsToShow
    })

  }

  changeTableFieldsbasedonOption = async () => {
    let selectedOption = this.state.selectedvalueforStatus
    let tablefieldsToShow = this.state.tablefieldsToShow
    let index = tablefieldsToShow.findIndex(obj => obj.field === "reOrder")
    if (index !== -1) {
      tablefieldsToShow.splice(index, 1)
    }
    let timeField = tablefieldsToShow.findIndex(obj => obj.field === "indexType")
    if (timeField !== -1) {
      tablefieldsToShow.splice(timeField, 3)
    }
    let delindex = tablefieldsToShow.findIndex(obj => obj.field === "Actions")
    if (delindex !== -1) {
      tablefieldsToShow.splice(delindex, 1)
    }

    if (selectedOption.length === 2 || selectedOption.length === 3) {
      tablefieldsToShow.push(


        {
          "show": true,
          "textAlign": "left",
          "width": 70,
          "field": "indexType",
          "header": "Index Type",
          "filter": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "mobile": true,
          "field": "startTime",
          "fieldType": 'number',
          "header": "Start Time",
          "filterType": "num",
          "dateFormat": "MM/DD/YYYY hh:mm a",
          "fieldType": 'Date',
          "filter": true,
          "displayInSettings": true,
          "displayInFilter": false,
          "sortable": true,
        }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "mobile": true,
        "field": "endTime",
        "fieldType": 'number',
        "header": "End Time",
        "filterType": "num",
        "dateFormat": "MM/DD/YYYY hh:mm a",
        "fieldType": 'Date',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      })

    } else {
      tablefieldsToShow.unshift(
        {
          "show": true,
          textAlign: 'left',
          width: 16,
          field: 'reOrder',
        },
      )
      if (this.state.screenPermissions === "Edit") {
        tablefieldsToShow.push(

          {
            "show": true,
            "textAlign": "left",
            "width": 60,
            "fieldType": 'Actions',
            "field": "Actions",
            "header": "Actions",
            filter: true,
            "displayInSettings": true,
            "sortable": false
          },
        )
      }
    }
    await this.setState({
      tablefieldsToShow: tablefieldsToShow
    })
  }

  confirmActionType = async (type) => {
    if (type === 'Delete') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Delete',
      })
      this.deleteConfirmAction()
    } else if (type === 'Block') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Block',
      })
      this.deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Reset Password',
        openUserPasswordResetModal: true,
        openDeleteModal: false,
        selectedRows: this.state.selectedRows
      })
      // this.deleteConfirmAction()
    }
  }

  //onActionsChange
  onActionsChange = async (event, type) => {
    if (type === 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value,
      })
      for (let i in this.state.actionsTypes) {
        if (this.state.actionsTypes[i].value === event.target.value) {
          if (this.state.actionsTypes[i].action) {
            this.state.actionsTypes[i].action(this.state.selectedRows)
          }
        }
      }
      this.confirmActionType(event.target.value)
    }
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }


  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getDataFromServer}
        filterCriteria={this.state.filterCriteria}

      />
    )
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }

  onMultiSelectFilterChange = async (key, value, type, index) => {
    let filterCriteria = this.state.filterCriteria;
    if (filterCriteria && type === 'push') {
      let v = [], length = '';
      if (filterCriteria['criteria'] && filterCriteria['criteria'][0] && filterCriteria['criteria'][0]["value"]) {
        v = [...filterCriteria['criteria'][0]["value"]];
        length = v.length;
        v[length] = value;
        filterCriteria['criteria'][0]["value"] = v;
      } else {
        v[0] = value
        if (filterCriteria && filterCriteria['criteria']) {
          filterCriteria['criteria'].push({
            key: key,
            value: v,
            type: 'in'
          });
        } else {
          filterCriteria['criteria'] = []
          filterCriteria['criteria'].push({
            key: key,
            value: v,
            type: 'in'
          });
        }
      }
      await this.setState({
        filterCriteria: filterCriteria
      })

      await this.getDataFromServer(this.state.filterCriteria)
    } else if (type === 'pop') {
      filterCriteria['criteria'][0]["value"].splice(filterCriteria['criteria'][0]["value"].indexOf(value), 1);
      if (filterCriteria['criteria'][0]["value"].length === 0) {
        await this.setState({
          filterCriteria: { criteria: [], sortfield: filterCriteria.sortfield ? filterCriteria.sortfield : 'created', direction: filterCriteria.direction ? filterCriteria.direction : 'desc' },
        })
      } else {
        await this.setState({
          filterCriteria: filterCriteria
        })
      }
      this.getDataFromServer(this.state.filterCriteria)
    }
  }

  toggleActions(id, name) {
    this.setState(prevState => (
      {
        dropdownOpen: !prevState.dropdownOpen
        , rowName: name
      }));
  }

  isConfirmFilter = async (val, type) => {

    if (val && val.filters) {
      let fieldName = Object.keys(val.filters)[0];
      if ((val.filters[fieldName].value.length === 0 || val.filters[fieldName].value.length === undefined) && this.props.type !== "OpenCorporateSearch") {
        await this.setState({ isApplyFilter: true, filteredCol: val })
        this.getConfirmaionModel()
      } else {
        let ocFilters = this.state.ocFilters;
        let index = ocFilters.findIndex(u => u.fieldName == fieldName);
        if (this.props.type === "OpenCorporateSearch" && this.state.responsesaveData && this.state.responsesaveData.length) {
          let data = this.state.ocFilters && this.state.ocFilters.length ? this.state.allUsersData : this.state.responsesaveData, data2 = [];
          if (val.filters[fieldName].value.length !== 0 && val.filters[fieldName].value.length !== undefined) {
            for (let obj of data) {
              if (obj[fieldName] && obj[fieldName].toLowerCase().includes(val.filters[fieldName].value.toLowerCase())) {
                data2.push(obj);
              }
            }
            if (index == -1) {
              ocFilters.push({ fieldName: fieldName, value: val.filters[fieldName].value.toLowerCase() });
            } else {
              ocFilters[index].value = val.filters[fieldName].value.toLowerCase();
            }
          } else {
            let details = await this.getFilteredData(fieldName);
            ocFilters = details.ocFilters ? details.ocFilters : [];
            data2 = details.filteredData ? details.filteredData : [];
          }

          this.setState({
            progress: 100,
            ocFilters: ocFilters,
            allUsersData: data2,
            isLoading: false,

          })
        } else {
          await this.onFilterChange(val)
        }
      }
    } else {
      await this.setState({ isLoading: false, isApplyFilter: false, })
      if (type) {
        this.onSeletFilterType(val, type)
        //  this.handleFilterInputField(val, "clear")
      }
    }
  }

  // on search get data from server
  onFilterChange = async (event, type, screen) => {
    if (event && event.persist) {
      event.persist();
    }
    this.setState({
      isLoading: true,
      selectedRows: '',
      selectedKey: event.key === "Enter" || event.filters ? true : false,
      isDisabledTab: true,
      isChangeHit: true,
    })
    let fieldName = '', filterCriteria = this.state.filterCriteria ? this.state.filterCriteria : { criteria: [] },
      selectedFilterValue, selectedFilter, selecterFilterType, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        selecterFilterType = this.state.addedMatchforCompany ? selectedFilter.matchMode : document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0] ? document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0].value :
          "eq"
        selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : selectedFilter ? selectedFilter : null;
        if (selecterFilterType === "multi") {//for multiSelect encode is not needed
          selectedFilterValue = selectedFilter.value
        }

        if (selecterFilterType === "nin" || selecterFilterType === "in") {
          selectedFilterValue = "nexists"
        }
        if (event && event.filters && event.filters[fieldName].matchMode === "clear") {//for clearing column filter menu
          selectedFilterValue = null
        }
        if ((fieldName === 'date' || fieldName === 'LastUpdated' || fieldName === 'updated' || fieldName === 'TimestampUTC' || fieldName === 'MostRecent10KFormDate' || fieldName === 'created' || fieldName === 'updated' || fieldName.includes("Date") && fieldName !== "nextActionDateDaysDefault" || fieldName === "DWDateInserted" || (fieldName.includes("date") && fieldName !== "validatedBy" && field !== "updatedBy" && fieldName !== "lastUpdatedBy") || fieldName.includes('Time')) && fieldName !== 'UpdatedBy') {
          if (selecterFilterType !== "nin" && selecterFilterType !== "in") {
            selecterFilterType = "date" + selecterFilterType
          }
          isDateFilter = true;

        }
        if ((this.props.isSql === "yes" && this.props.isSql !== undefined) || this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
          if (selecterFilterType === "in") {
            selecterFilterType = "nexists"
          } else if (selecterFilterType === "nin") {
            selecterFilterType = "exists"
          }
        }

      } else {
        fieldName = event && event.target && event.target.name ? event.target.name : "globalSearch";
        selectedFilterValue = event
        type = "eq"
      }
      if (selectedFilterValue && (selecterFilterType === "fuzzyregexOr" || (selecterFilterType === "regexOr" && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor")))) {
        selectedFilterValue = await this.removeSpecialCharactersAndWhitespace(selectedFilterValue)
      }
      if (selectedFilterValue === null) {
        let index
        index = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
        if (index !== -1) filterCriteria.criteria.splice(index, 1);
        filterCriteria.page = "1"
        await this.setState({
          filterCriteria: filterCriteria,
          first: 0,
          page: 1,
          selectFilterValue: "",
        });
        if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
          let index;
          index = filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
          if (index !== -1) filterCriteria["criteria"].splice(index, 1)
          filterCriteria["criteria"].push({
            key: "bucket",
            value: this.state.activeTab,
            type: "eq",
            // type: "eq",
            add_Filter: true
          })
        }
        // if (this.state.selectedKey) {
        if (this.props.type !== "") {
          if (this.props.type === "State Attributes") {
            await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
          } else {
            await this.getDataFromServer(filterCriteria, "column_filter", "", fieldName)
          }
        } else { this.getUsersSummaryData(this.state.summaryObject, true) }
        // }
      } else if ((selectedFilterValue && selectedFilterValue.length && ((fieldName === "globalSearch" && selectedFilterValue.length > 0) || (fieldName !== "globalSearch" && selectedFilterValue.length >= 1)))) {
        if (fieldName === 'globalSearch') {
          await this.setState({
            selectFilterValue: event
          })
          if (filterCriteria) {
            filterCriteria.page = "1"
          }
          this.setState({ first: 0 })
          filterCriteria.globalSearch = {
            value: encodeURIComponent(selectedFilterValue),
            type: this.props.globalSearchFieldName
          };
          if (this.props.type === "Company") {
            localStorage.setItem("SearchedCompany", selectedFilterValue)
          };
          if (event && event.target && event.target.value && event.target.value.length === 0) {
            delete filterCriteria.globalSearch;
          };
        } else {
          let valdat
          if (this.props.isSql === "yes" && this.props.isSql !== undefined && isDateFilter) {
            valdat = ["", null, 0]
          } else if (this.props.isSql === undefined && !isDateFilter && event.filters[fieldName].matchMode === "num" && this.props.type !== "Property Editor") {
            valdat = ["", null, "0"]
          } else if (this.props.isSql === undefined && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor")) {
            valdat = [""]
          } else if (this.props.isSql === undefined && (this.props.type === "ClaimSummary" || this.props.type === "ClaimsManagement")) {
            valdat = [""]
          }
          if (filterCriteria && filterCriteria.page) {
            filterCriteria.page = 1
          }

          if (filterCriteria['criteria'].length === 0) {
            filterCriteria['criteria'].push({
              key: fieldName === "propertyNote" ? fieldName + ".note" : fieldName,//for claims we need add .note
              value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" ? this.trimmedValue(decodeURIComponent(selectedFilterValue)) : selectedFilterValue === "nexists" ? ["", null] : selectedFilterValue,
              // value: [""],
              type: selecterFilterType === "multi" ? "in" : selecterFilterType,
              add_Filter: this.state.addedMatchforCompany ? true : false
            });
          } else {
            let obj, index;
            index = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
            if (index !== -1) {
              filterCriteria['criteria'].splice(index, 1)
            }
            filterCriteria['criteria'].push({
              key: fieldName === "propertyNote" ? fieldName + ".note" : fieldName,
              value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" ? this.trimmedValue(decodeURIComponent(selectedFilterValue)) : selectedFilterValue === "nexists" && this.props.type === "State Attributes" ? ["", null] : selectedFilterValue === "nexists" && this.props.type !== "State Attributes" ? [""] : selectedFilterValue,
              // value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" && selectedFilterValue !== "exists" ? parseInt(selectedFilterValue) : [""],
              type: selecterFilterType === "multi" ? "in" : selecterFilterType,
              add_Filter: this.state.addedMatchforCompany ? true : false
            });
            if (this.props.type === "RFI View" && isDateFilter && (selecterFilterType === "nexists" || selecterFilterType === "exists")) {
              let dateindex = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
              if (dateindex !== -1) {
                filterCriteria['criteria'].splice(index, 1)
              }
              filterCriteria['criteria'].push({ "key": fieldName, "value": null, "type": selecterFilterType === "nexists" ? "eq" : "ne", "add_Filter": false })

            }
            // }
          }

          await this.setState({
            filterCriteria: filterCriteria,
            page: 1,
            first: 0
          });
        }
        // if (this.state.selectedKey) {
        if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
          let index;
          index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
          if (index !== -1) this.state.filterCriteria["criteria"].splice(index, 1)
          this.state.filterCriteria["criteria"].push({
            key: "bucket",
            value: this.state.activeTab,
            type: "eq",
            // type: "eq",
            add_Filter: true
          })
        }
        this.state.filterCriteria["criteria"] = [...this.state.filterCriteria["criteria"], ...this.state.criteriaData["criteria"]]
        if (this.props.type !== "UP Opportunity" || this.props.type !== "UP Opportunity – V2") {
          if (this.props.type === "State Attributes") {
            await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
          } else {
            await this.getDataFromServer(filterCriteria, "column_filter", "")
          }
        } else { this.getUsersSummaryData(this.state.summaryObject, true) }
        if (screen === "DetailView") {
          this.formModalRef.closeFormModal();
        }
        // }
      } else {

        if (selectedFilterValue.length === undefined && !isDateFilter) {
          let obj = filterCriteria.criteria.find(x => x.key === fieldName && x.add_Filter === false);
          let index = filterCriteria.criteria.indexOf(obj);
          filterCriteria.criteria.splice(index, 1);
          // }
          if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor") {
            let index;
            index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
            if (index !== -1) this.state.filterCriteria["criteria"].splice(index, 1)
            this.state.filterCriteria["criteria"].push({
              key: "bucket",
              value: this.state.activeTab,
              type: "eq",
              // type: "eq",
              add_Filter: true
            })
          }
          filterCriteria.page = "1"
          await this.setState({
            filterCriteria: filterCriteria,
            page: "1",
            first: 0,
            selectFilterValue: ""
          });
          // if (this.state.selectedKey) {
          if (this.props.type === "State Attributes") {
            await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
          } else {
            await this.getDataFromServer(filterCriteria, "column_filter", "", fieldName)
          }
        }
        // }

      }
      this.setState({
        isLoading: false,
        progress: 0
      })
    }
  }

  removeSpecialCharactersAndWhitespace = async (inputString, type) => {
    inputString = decodeURIComponent(inputString)

    inputString = inputString.split("").join(".*")

    // Remove special characters using a regular expression
    const withoutSpecialChars = inputString.replace(/[^\w\s]/gi, '');

    // Remove extra whitespace using another regular expression
    const withoutExtraWhitespace = withoutSpecialChars.replace(/\s+/g, ' ').trim();
    if (!type) {
      return encodeURIComponent(withoutExtraWhitespace);
    } else {
      return withoutExtraWhitespace;

    }
  }

  applydefaultTemplateForSelectedRows = async () => {
    let loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'))
    const shortName = new Date().toLocaleString('en-US', { month: 'short' });
    const DateName = new Date().getDate();
    let selectednote
    if (this.props.type === "ClaimsManagement") {
      selectednote = `Seller: \nSeller Proof:\nName Proof:\nAddress Proof:\nFEIN Proof:\nHolder Proof:`
    } else {
      selectednote = null
    }
    return selectednote;
  }

  getNotesUpdate = async (event) => {
    var optFields = true
    if (event && event.length > 1) {
      // optFields = event.every((x) => x.fileFor === event[0].fileFor && x.relevantSellerName === event[0].relevantSellerName && x.sourceState === event[0].sourceState);

    }


    let statePropertyId = [], minValue = 0, opraPropertyId = [], states = [], previousTaskId = [];
    let unknownPropertyCount = []
    if (this.props.type) {
      let selectedRows = event
      this.setState({ selectedRows: selectedRows, totalSelectedRows: selectedRows.length })
      let z = selectedRows.find(elem => (elem.propertyStatus === "FILING-CLM FILED" ||
        elem.propertyStatus === "RESULT-APPROVED" ||
        elem.propertyStatus === "RESULT-OPP WITHDRAWN" ||
        elem.propertyStatus === "RESULT-NOT APPROVED" ||
        elem.propertyStatus === "POST-FILING-RFI-REFER TO SLRCOMMS/LGL" || elem.propertyStatus === "POST-FILING-RFI-RSRCH RQST"
      ))
      // if (z && (this.state.loginRole !== "Pod Lead" && this.state.loginRole !== "Admin" && this.state.loginRole !== config.roleNames.coreOpsManager)) {
      //   this.editorComponentRef.setFiledStatus("true")
      // } else {
      //   this.editorComponentRef.setFiledStatus("false")
      // }
      let propertyDisable = selectedRows.find(elem => (elem.propertyStatus === "FILING-CLM FILED" ||
        elem.propertyStatus === "RESULT-APPROVED"
        || elem.propertyStatus === "RESULT-OPP WITHDRAWN" ||
        elem.propertyStatus === "RESULT-NOT APPROVED" ||
        elem.propertyStatus === "POST-FILING-RFI-REFER TO SLRCOMMS/LGL" ||
        elem.propertyStatus === "POST-FILING-RFI-RSRCH RQST"))
      if (propertyDisable) {
        this.editorComponentRef.setFiledNote("true")
      } else if (this.props.type !== "HighValueProperties") {
        this.editorComponentRef.setFiledNote("false")
      }
      this.setState({ selectedRows: selectedRows, totalSelectedRows: selectedRows.length, optFields: optFields })
      // this.setState({ selectedRows: event.value })
      let selectednote = await this.applydefaultTemplateForSelectedRows()
      if (event && event.value && event.value.length === 0 && this.props.type !== "ClaimsManagement") {
        this.editorComponentRef.handlePropertiesEditChange("", "Cancel")
      }
      if (this.props.type === "HighValueProperties") {
        const unique = [...new Set(selectedRows.map((item) => item.companyID))];
        if (unique && unique.length > 1) {
          await this.setState({
            errorResponseHighPropertyMessage: "Unable to Create Task with Current Selection: More than 1 Deal is Selected",
            errorResponseHighProperty: true,
            disableCreateTask: true

          })
          return
        } else {
          await this.setState({
            errorResponseHighPropertyMessage: "",
            errorResponseHighProperty: false,
            disableCreateTask: false

          })
        }
      }
      // if (selectedRows.length === 1 && (selectedRows[0].matchStatusNotes && selectedRows[0].matchStatusNotes.length > 0 || selectedRows[0].PropertyNote && selectedRows[0].PropertyNote.length > 0)) {
      if (selectedRows.length === 1) {

        if (this.props.type !== "Negative Notice List Viewer" && selectedRows[0].matchStatusNotes && selectedRows[0].matchStatusNotes.length > 0) {
          selectednote = selectedRows[0].matchStatusNotes[0].note
          await this.setState({
            selectednote: selectednote
          })
          if (this.editorComponentRef) {
            this.editorComponentRef.updatedNote(selectednote, "note")
          }
        }
        if (this.props.type === "Negative Notice List Viewer") {
          if (selectedRows[0] && selectedRows[0].inclusionExclusionNote) {
            selectednote = selectedRows[0].inclusionExclusionNote
            await this.setState({
              selectednote: selectednote
            })
          }
          if (selectedRows[0] && selectedRows[0].includedExcluded) {
            let includedExcluded = selectedRows[0].includedExcluded
            this.editorComponentRef.updatedselectedExclude(includedExcluded, "note")
          }

        }
        if (this.editorComponentRef) {
          if (selectedRows[0].relevantSellerName && selectedRows[0].fileFor.length > 0) {
            this.editorComponentRef.updatedrelevantSellerName(selectedRows[0].fileFor, "relevantSellerName")
          }
          if (selectedRows[0].relevantSellerName && selectedRows[0].relevantSellerName.length > 0) {
            this.editorComponentRef.updatedfileFor(selectedRows[0].relevantSellerName, "fileFor")
          }
          if (selectedRows[0].claimID && selectedRows[0].claimID.length > 0) {
            this.editorComponentRef.updateClaimID(selectedRows[0].claimID, "claimID")
          }
          if (selectedRows[0].propertyStatus && selectedRows[0].propertyStatus.length > 0 && !selectedRows[0].propertyStatus.includes("MATCHING")) {
            this.editorComponentRef.updateStatus(selectedRows[0].propertyStatus, "status")
          }
          if (selectedRows[0].propertyStatusReason && selectedRows[0].propertyStatusReason.length > 0 && !selectedRows[0].propertyStatus.includes("MATCHING")) {
            this.editorComponentRef.updateReason(selectedRows[0].propertyStatusReason, "reason")
          }
          if (selectedRows[0].addressProof && selectedRows[0].addressProof.length > 0) {
            this.editorComponentRef.updateAddressProof(selectedRows[0].addressProof, "AddressProof")
          }
          if (selectedRows[0].feinProof && selectedRows[0].feinProof.length > 0) {
            this.editorComponentRef.updateAddressProof(selectedRows[0].feinProof, "FeinProof")
          }
          if (selectedRows[0].holderProof && selectedRows[0].holderProof.length > 0) {
            this.editorComponentRef.updateAddressProof(selectedRows[0].holderProof, "HolderProof")
          }
          if (selectedRows[0].nameProof && selectedRows[0].nameProof.length > 0) {
            this.editorComponentRef.updateAddressProof(selectedRows[0].nameProof, "NameProof")
          } if (selectedRows[0].sellerProof && selectedRows[0].sellerProof.length > 0) {
            this.editorComponentRef.updateAddressProof(selectedRows[0].sellerProof, "SellerProof")
          }
        }
        for (let selected of selectedRows) {
          if (selected.statePropertyId !== 0) {
            statePropertyId.push(selected.statePropertyId)
          }
          minValue = minValue + selected.propertyMinValueAmount
          states.push(selected.sourceState)
          opraPropertyId.push(selected.propertyID)
          if (selected.connectedTaskID && selected.connectedTaskID.length > 0) {
            previousTaskId = [...previousTaskId, ...selected.connectedTaskID]
          }
          if (selected.propertyMaxValueAmount === null || selected.propertyMaxValueAmount === "") {
            unknownPropertyCount.push(selected.propertyID)
          }
        }
        await this.setState({ unknownPropertyCount: unknownPropertyCount, taskstatePropertyId: statePropertyId, taskminValue: minValue, taskstates: states, taskopraPropertyId: opraPropertyId, previousTaskId: previousTaskId })

        if (this.props.type !== "HighValueProperties") {
          this.editorComponentRef.enableCopyProof("true")
        } else {
          this.setState({ HighValueSeller: { EstateID: selectedRows[0].companyID } })
        }
      } else {
        await this.setState({
          selectednote: selectednote
        })
        if (this.props.type === "Properties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "ClaimsManagement" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "HighValueProperties") {
          if (this.props.type !== "HighValueProperties") {
            this.editorComponentRef.updatedNote(selectednote, "noteEmpty")
            this.editorComponentRef.updatedselectedExclude("", "noteEmpty")

            this.editorComponentRef.updatedfileFor("", "noteEmpty");
            if (optFields && selectedRows && selectedRows[0]) {
              this.editorComponentRef.updatedrelevantSellerName(selectedRows[0].fileFor, "relevantSellerName")
              this.editorComponentRef.updatedfileFor(selectedRows[0].relevantSellerName, "fileFor")
            } else {
              this.editorComponentRef.updatedfileFor("", "noteEmpty");
              this.editorComponentRef.updatedrelevantSellerName("", "noteEmpty")
            }

            this.editorComponentRef.enableCopyProof("false")
            if (selectedRows.length === 0) {
              this.editorComponentRef.removeCopiedFields()
              this.editorComponentRef.updatedNote(selectednote, "noteEmpty")
              this.editorComponentRef.updatedselectedExclude("", "noteEmpty")
            }

            this.editorComponentRef.updateAddressProof("empty", "AddressProof")
            this.editorComponentRef.updateAddressProof("empty", "FeinProof")
            this.editorComponentRef.updateAddressProof("empty", "HolderProof")
            this.editorComponentRef.updateAddressProof("empty", "NameProof")
            this.editorComponentRef.updateAddressProof("empty", "SellerProof")
            this.editorComponentRef.updateStatus('', "empty")
            this.editorComponentRef.updateReason('', "reason")
            this.editorComponentRef.updateClaimID()
          }
          for (let selected of selectedRows) {
            if (selected.statePropertyId !== 0) {
              statePropertyId.push(selected.statePropertyId)
            }
            minValue = minValue + selected.propertyMinValueAmount
            states.push(selected.sourceState)
            opraPropertyId.push(selected.propertyID)
            if (selected.connectedTaskID && selected.connectedTaskID.length > 0) {
              previousTaskId = [...previousTaskId, ...selected.connectedTaskID]
            }
            if (selected.propertyMaxValueAmount === null || selected.propertyMaxValueAmount === "") {
              unknownPropertyCount.push(selected.propertyID)
            }

          }
          await this.setState({ unknownPropertyCount: unknownPropertyCount, taskstatePropertyId: statePropertyId, taskminValue: minValue, taskstates: states, taskopraPropertyId: opraPropertyId, previousTaskId: previousTaskId })

        }
      }
      if (this.props.type === "ClaimsManagement" && this.state.selectedRows.length === 0) {
        this.editorComponentRef.emptyStatus()
        this.editorComponentRef.enableCopyProof("false")
        if (selectedRows.length === 0) {
          this.editorComponentRef.removeCopiedFields()
        }
        this.editorComponentRef.updateAddressProof("empty", "AddressProof")
        this.editorComponentRef.updateAddressProof("empty", "FeinProof")
        this.editorComponentRef.updateAddressProof("empty", "HolderProof")
        this.editorComponentRef.updateAddressProof("empty", "NameProof")
        this.editorComponentRef.updateAddressProof("empty", "SellerProof")
      }
    }
  }
  getreturnSelectedRows = (event) => {
    if (event) {
      this.setState({
        selectedRows: []
      })
    } else {
      return this.state.selectedRows
    }
  }
  checkDataisEdited = () => {
    var newArray = this.state.allUsersData.filter(function (el) {
      return el.edited === "yes"
    });
    return newArray
  }
  disableInlineEdit = (selectedRows) => {
    let editIcon = document.getElementsByClassName("p-row-editor-init")
    if (editIcon && editIcon.length > 0 && selectedRows.length > 0) {
      for (var i = 0; i < editIcon.length; i++) {
        document.getElementsByClassName("p-row-editor-init")[i].disabled = true
        document.getElementsByClassName("p-row-editor-init")[i].style.color = "lightgray"
        document.getElementsByClassName("p-row-editor-init")[i].style.pointerEvents = "none"

      }
    } else if (editIcon && editIcon.length > 0 && selectedRows.length === 0) {
      for (var i = 0; i < editIcon.length; i++) {
        document.getElementsByClassName("p-row-editor-init")[i].disabled = false
        document.getElementsByClassName("p-row-editor-init")[i].style.color = "black"
        document.getElementsByClassName("p-row-editor-init")[i].style.pointerEvents = "visible"

      }

    }
  }
  //select multiple rows to delete
  onSelectRowsUpdate = async (event) => {
    let selectedRows = event.value

    selectedRows = selectedRows
    let newArray2 = this.checkDataisEdited()
    if (this.props.type == "ClaimsManagement" && newArray2.length > 0) {
      this.OpenConfirmaionModelorSave()
    } else {
      this.setState({
        selectedRows: selectedRows,
        totalSelectedRows: selectedRows && selectedRows.length ? selectedRows.length : 0
      })
      if (this.props.type) {
        if (this.props.type === "Properties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "ClaimsManagement" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Claims" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "HighValueProperties") {
          this.getNotesUpdate(selectedRows)
        }
      } else {
        this.props.sendSelctedRecordtoDb(selectedRows)
      }
    }
    this.disableInlineEdit(selectedRows)
    // let addressArr = []
    // for (var obj of event.value) {
    //   let index = addressArr.findIndex(addressArr => addressArr === obj.businessAddressLine1)
    //   if (index === -1) {
    //     addressArr.push(obj.businessAddressLine1)
    //   };
    // }
    // if (addressArr && addressArr.length === 1 && this.state.validatedArray && this.state.validatedArray.length === 0) {
    //   await this.setState({
    //     addressArr: addressArr,
    //     showSelectederror: false,
    //     errormsg: ""
    //   })
    // }

    // await this.setState({
    //   addressArr: addressArr,
    // })
    // await this.getActions()
  }

  //openConfirmationModal
  openConfirmationModal = async (rowData, status, type) => {
    this.setState({
      isOpenFormModal: false
    }, async () => {
      this.setState({
        isOpenFormModal: false,
        openConfirmationModal: true,
        leaveStatus: status,
        item: rowData,
        confirmText: type
      })
    })
  }

  closeConfirmationModal() {
    this.setState({
      openConfirmationModal: false
    })
  }
  changeNote = (Comments) => {
    this.setState({
      isnotesModal: true,
      Comments: Comments
    })
  }
  changeNoteClaims = (Comments, sno, item) => {
    if (sno) {
      this.setState({ claimNotesEdit: true })
    } else {
      this.setState({ claimNotesEdit: false })
    }

    let commentArr = []
    if (Comments) {
      commentArr.push({ note: Comments, created: null })
    }
    this.setState({
      isnotesModal: true,
      Comments: Comments,
      notesSno: item
    })
  }
  getSavedData = async (id) => {
    let Data = []
    Data = id.split(/\r?\n/)
    let filterCriteria = { limit: 1, page: 1, sortfield: "OppClaimID", direction: "desc" }
    filterCriteria.criteria = [{ key: "CompanyID", value: this.state.PropertyData.selectedClaims.EstateID, type: "eq" }, { key: "OppClaimID", value: Data, type: "in" }]
    let Url = `nsClaim/childClaims?filter=${JSON.stringify(filterCriteria)}`;
    return fetchMethodRequest('GET', Url)
      .then(async (response) => {
        if (response && response.claims && response.claims[0]) {
          return response.claims[0]
        } else if (response && response.errorMessage) {
          this.getErrorResponseFromServer(response.errorMessage)
        }
      }).catch((err) => {
        return err;
      });
  }
  cancelNotes = async (type, id) => {
    this.setState({
      isnotesModal: false,
      openpaymentsModal: false,
      selectedRowPayments: ""
    })
    let responsesaveData = JSON.parse(JSON.stringify(this.state.responsesaveData))
    if (type === "Payments" && id) {
      let recordedData = await this.getSavedData(id)
      let allData = this.state.allUsersData
      let index = allData.findIndex(obj => obj["_id"].OppClaimID === id)
      allData[index].Payments = recordedData.Payments
      await this.setState({
        allUsersData: allData,
      })
    }
  }
  saveClaimNotes = (comment, item, Data, editComment, edited) => {
    let allUsersData = this.state.allUsersData
    let z = allUsersData.find(elem => elem.OppClaimID === item.OppClaimID)
    let claimNotes = Data && Data.length > 0 ? Data : []
    if (editComment !== undefined) {
      // claimNotes.shift()
      // claimNotes.unshift({ note: editComment })
      claimNotes[0].note = editComment.note
    }
    if (comment !== undefined) {
      claimNotes.unshift({ note: comment })
    }

    if (z) {
      //with z values hit the api call
      z.ClaimNotes = claimNotes
      if (edited) {
        z.edited = "yes"
        localStorage.setItem("ISEdited", "true")
      }
    }
    // allUsersData[this.state.notesSno]["ClaimNotes"] = comment ? comment : ""
    this.setState({
      isnotesModal: false,
      claimNotesEdit: false,
      allUsersData: allUsersData
    })
  }
  getNotesDetails(val, column, data, index) {
    return (
      <div style={{ display: "flex", width: column.bodyStyle.width }}>
        <div style={val.length > 0 ? { display: "flex", width: column.bodyStyle.width - 50 } : {}}>
          <span className="kanban_title" data-toggle="tool-tip"
            title={val}
          >
            {val}
          </ span >
        </div>
        <div className="d-flex" style={{ width: 50 }}>
          {val.length > 0 ?
            <span className="text-right" style={{ display: "flex" }}>
              <FontAwesomeIcon icon={faCopy}
                className='pl-1'
                data-toggle="tool-tip"
                // validate={[required]}
                title="click to copy this Notes"
                style={{ width: 20, color: "red" }}
                onClick={() => this.clipToCopyBoard(val[0].props.title)}
              />
              <FontAwesomeIcon icon={faExternalLinkAlt}
                className='pl-1'
                data-toggle="tool-tip"
                // validate={[required]}
                title="click to see all Notes"
                style={{ width: 20, color: "grey" }}
                onClick={() => this.changeNote(data)}
              />
            </span>
            : null}
        </div>
      </div>)
  }
  getNotesDetailsClaim(val, column, data, item) {
    return (
      <div style={{ display: "flex", width: column.bodyStyle.width }}>
        <div style={val.length > 0 ? { display: "flex", width: column.bodyStyle.width - 30 } : {}}>
          <span className="kanban_title" data-toggle="tool-tip"
            title={val}
          >
            {val}
          </ span >
        </div>
        <div>

          {val != null || val.length > 0 || column.editing ?
            <span className="text-right" style={{ display: "grid" }}>
              <FontAwesomeIcon icon={faExternalLinkAlt}
                className='pl-1'
                data-toggle="tool-tip"
                // validate={[required]}
                title="click to see all Notes"
                style={{ width: 20, color: "grey" }}
                onClick={() => this.changeNoteClaims(data, column.editing, item)}
              />
            </span>
            : null}
        </div>
      </div>)
  }

  getNotesModal() {
    return (
      <NotesModal
        openNotesModal={this.state.isnotesModal}
        cancelReset={this.cancelNotes}
        saveClaimNotes={this.saveClaimNotes}
        claimNotesEdit={this.state.claimNotesEdit}
        notesSno={this.state.notesSno}
        Comments={this.state.Comments}
        type={this.props.type === "ClaimsManagement" ? "Notes1" : "Notes"}
      />
    )
  }

  getPaymentsModal(item) {
    return (
      <PaymentsModal
        onRef={(ref) => this.paymentsModalRef = ref}
        PaymentsArray={this.state.selectedRowPayments}
        openpaymentsModal={this.state.openpaymentsModal}
        openFormModal={this.openFormModal}
        closeFormModal={this.closeFormModal}
        cancelReset={this.cancelNotes}
        handleUsersData={this.handleUsersData}
      />
    )
  }
  setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = this.state.filterCriteria;
    filterCriteria['criteria'].push(
      { key: 'contextId', value: editRowDataID, type: 'eq' }
    )
    await this.setState({
      filterCriteria: filterCriteria
    })
  }

  getTooltipFromOtptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }

  viewSellerModal = async (id, lookup) => {
    if (lookup) {
      if (this.props.type === "Address Lookup") {
        await this.setState({
          isSellerModalAddress: true,
          sellerID: id,
        });
      } else {
        await this.setState({
          isSellerModalNames: true,
          sellerID: id,
        });
      }
    } else {
      await this.setState({
        issellerModalView: true,
        sellerID: id,
        sellerEditType: "view"
      });
    }
  }

  getCompanyDetails = async (id) => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: "CompanyID", value: id, type: 'eq' }];
    fetchMethodRequest('GET', `businessNames/companies?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response) {
          let data = response.companies[0];
          this.openViewModal(data, 'view')
        } else if (response && response['errorMessage']) {
          this.getErrorResponseFromServer(response)
        }
      }).catch((err) => {
        return err;
      });
  }
  openLink = async (item, type) => {
    await this.setState({ openLinked: true, openCompanyId: item.CompanyID })
    // return <Link to={(`/companies/${JSON.stringify(item.CompanyID)}`)}></Link>
  }
  //Copy Function
  clipToCopyBoard(data) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(data)

    }
  }
  getcollectedData = (item) => {
    let initialValue = 0
    if (item && item["Payments"] && item["Payments"].length > 0) {
      let sum = item["Payments"].reduce(function (accumulator, curValue) {
        return accumulator + curValue.checkAmount
      }, initialValue)


    }
    let dollarUS = this.getFormattedCurrencyValue()
    if (item["PaymentsData"] && item["PaymentsData"][0]) {
      let flatArray = [].concat(...item["PaymentsData"][0]);
      var paymentAmount = flatArray.reduce(function (a, b) { return a + b; }, 0);
      if (paymentAmount !== 0) {
        return dollarUS.format(paymentAmount)
      } else { return "" }
    } else {
      return ""
    }

  }
  customCount(item, field, checkType) {
    let dollarUS = this.getFormattedCurrencyValue()
    if (checkType === "maxCount" && field === "greenMax" && item.green !== undefined && item.green["PropertyMaxValueAmountSum"]) {
      return dollarUS.format(parseInt(item.green["PropertyMaxValueAmountSum"])).slice(0, -3)
    } else if (checkType === "maxCount" && field === "blueMax" && item.blue !== undefined && item.blue["PropertyMaxValueAmountSum"]) {
      return dollarUS.format(parseInt(item.blue["PropertyMaxValueAmountSum"])).slice(0, -3)
    } if (checkType === "minCount" && field === "greenMin" && item.green !== undefined && item.green["PropertyMinValueAmountSum"]) {
      return dollarUS.format(parseInt(item.green["PropertyMinValueAmountSum"])).slice(0, -3)
    } else if (checkType === "minCount" && field === "blueMin" && item.blue !== undefined && item.blue["PropertyMinValueAmountSum"]) {
      return dollarUS.format(parseInt(item.blue["PropertyMinValueAmountSum"])).slice(0, -3)
    } else {
      return ""
    }
  }

  handleUsersData = async (indexeddata, type) => {
    let allData = this.state.allUsersData
    let responsesaveData = JSON.parse(JSON.stringify(this.state.responsesaveData))
    let index = allData.findIndex(obj => obj["_id"].OppClaimID === indexeddata["_id"].OppClaimID)
    allData[index] = indexeddata;
    responsesaveData[index].Payments = indexeddata.Payments
    allData[index].edited = "yes"
    localStorage.setItem("ISEdited", "true")
    await this.setState({
      allUsersData: allData,
      selectedRowPayments: indexeddata,
      responsesaveData: JSON.parse(JSON.stringify(responsesaveData)),

    })
    if (!type && this.paymentsModalRef) {
      this.paymentsModalRef.getUpdatedData(indexeddata)
    }
    this.closeFormModal()
  }
  handleHourstominutes = (totalMinutes) => {
    // var days = Math.floor(item.durationInMinutes / 24 / 60);
    // const hours = Math.floor(totalMinutes / 60);
    // const minutes = totalMinutes % 60;
    if (totalMinutes) {
      return totalMinutes.toFixed(1);
    } else {
      return ""
    }
  }

  padToTwoDigits(num) {
    return num.toString().padStart(2, "0");
  }

  getMultiselectData = (i) => {
    return <div>
      <div style={{}}>
        <MultiSelect
          style={{ width: "-webkit-fill-available" }}
          value={this.state.SelectedMails}
          options={this.state.filteredAnalystsEmails}
          onChange={(e) => this.updateSelectedMails(e, i)}
        />
      </div>
      <Button color="primary" outline onClick={() => this.updateMails()}
        className='deleteModalBtn'>{'Save'}</Button>
    </div>
  }

  updateSelectedMails = (e, item) => {
    this.setState({ SelectedMails: e.value })
  }

  setColumnIndex = (e, item) => {
    this.op.toggle(e)
    this.setState({ selectedIndexItem: item })
  }

  mailsOverPanel = (item) => {
    let returnIconColor = "#354f6e"
    return <div >
      <div class="btn" style={{ margin: "0px", height: "30px" }} onClick={(e) => this.setColumnIndex(e, item)} data-toggle="tool-tip"
        title="click to set Mails" >

        <span><FontAwesomeIcon
          style={{ fontSize: "20px", height: "20px" }}
          icon={faEnvelope}
          color={returnIconColor}
          data-toggle="tool-tip"
          title={"Click to send Mails"}
        /></span>

      </div>
      <OverlayPanel
        className="overlaypanelEmails"
        // style={{ left: "1234px", width: "500px" }}
        style={{
          width: "500px",
          display: "flex !important",
          flexWrap: "wrap !important",
          alignContent: "stretch !important",
          justifyContent: "space-between !important",
          alignItems: "baseline !important"
        }}
        appendTo={document.body}
        ref={(el) => this.op = el}
        showCloseIcon={true}
        dismissable={true}
      >

        {this.getMultiselectData(item)}

      </OverlayPanel>
    </div >
  }

  //change table body values//
  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    var propType = this.props.type
    let self = this, tableItem;
    tableItem = self.getTableFieldItem(column.field);
    if (tableItem && tableItem.fieldType) {
      if (tableItem.fieldType === "Link") {
        if (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") {
          if (this.state.screenPermissions === "Edit") {
            return item[column.field]
          } else if (this.state.screenPermissions === "View") {
            return <div className='textElipses'>
              <span
                onClick={(this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? () => this.viewSellerModal(item.DealID) : () => this.openViewModal(item, 'view')}>
                {item[column.field]}</span>
            </div >
          }

        } else if (this.props.type === "Name Lookup" || this.props.type === "Address Lookup") {
          return <div className='textElipses'>
            <span
              onClick={() => this.viewSellerModal(item.DealID, true)}>
              {item[column.field]}</span>
          </div >
        }
        else {
          return <div className='textElipses'>
            <span
              onClick={this.props.type === "Investor" ? () => this.getCompanyDetails(item.InvestorID, 'view') : () => this.openViewModal(item, 'view')}>
              {item[column.field]}</span>
          </div >
        }
        // }
      } else if (tableItem.fieldType === "fileSize") {
        let size = this.props.getSize(item[column.field])
        return <span style={{ cursor: 'pointer' }}
        >
          {size}
        </span>
      } else if (tableItem.fieldType === "showParentData") {
        return <div>
          <div class="tooltipClaims" onClick={(event) => { this.showParentModal(column.field, item, event); this.openData.toggle(event); }} data-toggle="tool-tip"
            title="click to see ParentData" >
            {item[column.field]}
          </div>
        </div>
      } else if (tableItem.fieldType === "resetCache") {
        return (
          <span style={{ textTransform: "capitalize" }}>
            <Button
              className="mr-1 mb-0"
              color="primary"
              outline
              onClick={() => this.props.resetCacheApi(item)}>
              {"Refresh"}
            </Button>
          </span>
        );
      } else if (tableItem.fieldType === "tabRedirect") {
        return <span
          style={{
            color: '#0e4768',
            cursor: 'pointer'
          }}
          onClick={() => this.getSelectedTabTypeInfo(
            column.field === "CompanyName" ? "FactSet Entity Tree" : "FactSet Names-Address List For Scoring",
            column.field === "CompanyName" ? item["CompanyID"] : item["FactSet Entity ID"],
            null,
            true)}>
          {item[column.field]}
        </span>
      } else if (tableItem.fieldType === "badgePill") {
        return <div className='d-flex float-right'>
          <Badge
            color={item[column.field] === "In Progress" ? 'warning' : item[column.field] === "Failed" ? 'danger' : 'success'}
          >
            <FontAwesomeIcon icon={item[column.field] === "In Progress" ? faHistory : item[column.field] === "Failed" ? faTimes : faCheck}
              className='pl-1 mt-1'
              // color={item[column.field] === "InProgress"  ? 'green' : 'red'}
              style={{ width: 30, }}
            // onClick={() => this.clearSelectedFile()}
            />
            {item[column.field]}
          </Badge>

        </div >
      } else if (tableItem.fieldType === "progress") {
        return <div className=''>
          <ProgressBar value={item["progress"] === "In Progress" ? 50 : item["progress"] === "Failed" ? 0 : 100} style={{ height: '15px', fontSize: 12 }}></ProgressBar>
        </div >
      } else if (tableItem.fieldType === "fileName") {
        let val = item[column.field] ? item[column.field].slice(16) : item[column.field]
        // return <span>{val}</span>
        return <>{this.props.type === "Queue" ?
          <a href={`${config.multipleimgUrl}bulk/${item.fileName}`} id="names_error_file" download>{val}</a> :
          <span> {val}</span>}</>
      } else if (tableItem.fieldType === "openWindow") {
        return <div className='textElipses'>
          <a href="#"> <span
            onClick={() => this.openFormModal(item, 'edit')}>
            {item[column.field]}</span></a>
        </div >

      } else if (tableItem.fieldType === "openConnectedTasks") {
        let returnIconColor = this.props.getColorbasedonProp ? this.props.getColorbasedonProp(item) : "#354f6e"

        return <div className='textElipses'>
          {item.connectedTaskID && item.connectedTaskID.length > 0
            || item.connectedClaimID && item.connectedClaimID.length > 0
            ? <span><FontAwesomeIcon
              style={{ fontSize: "20px", float: "left", marginRight: 5, marginTop: 4, marginLeft: 4 }}
              icon={faClipboardList}
              onClick={() => this.props.openTableModel(item, this.state.selectedSeller)}
              color={returnIconColor}
              data-toggle="tool-tip"
              title={"Click to see table"}
            /></span> : null}
        </div >

      } else if (tableItem.fieldType === "SendMails") {

        return <div className='textElipses'>
          {this.mailsOverPanel(item)}
        </div>

      } else if (tableItem.fieldType === "openErrorIcon") {
        let returnIconColor = this.props.getColorbasedonProp ? this.props.getColorbasedonProp(item) : "#354f6e"
        return <div className='textElipses'>
          {item.importResults && item.importResults.erroredFiles && item.importResults.erroredFiles.length > 0

            ? <span>
              {/* <FontAwesomeIcon
                style={{ fontSize: "20px", float: "left", marginRight: 5, marginTop: 4, marginLeft: 4 }}
                icon={faClipboardList}
                onClick={() => this.props.openTableModel(item, this.state.selectedSeller)}
                color={returnIconColor}
                data-toggle="tool-tip"
                title={"Click to see table"}
              /> */}
              < Button
                color="primary"
                size="sm"
                outline
                className="mb-0 m-1 ml-0 p-1"
                style={{ width: "100%" }}
                onClick={() => this.openErrorIcon(item)}>
                <div style={{ alignSelf: "center" }}>
                  {'View Errors'}
                </div>
              </Button>

            </span> : null}
        </div >

      } else if (tableItem.fieldType === "CityField") {
        return <span>
          <span>{item.HQCity}</span> {item.HQCity && item.HQState_Province ? "," : ""}<span>{item.HQState_Province}</span>
        </span>
      } else if (tableItem.fieldType === "hrsField") {
        let valdata = this.handleHourstominutes(item[column.field] ? item[column.field] : 0)
        return <span>
          <span>{valdata}</span>
        </span>
      } else if (tableItem.fieldType === "completedCount") {
        return <span>
          <span>{`${item["completedCount"]} / ${item["totalCount"]}`}</span>
        </span>
      } else if (tableItem.fieldType === "StrArray") {
        let val = item[column.field]
        return <span data-toggle="tool-tip"
          title={val ? val.join(" , ") : ""}>
          {val ? val.join(" , ") : ""}
        </span >
      } else if (tableItem.fieldType === "fileDownlod") {
        let val = item["progress"]
        return <span>{val === "Failed" ? <FontAwesomeIcon
          icon='download'
          onClick={() => this.props.openModaltodownloadFailedRecords(item)}
        /> : null}</span >
      } else if (tableItem.fieldType === "Download") {
        let val = item["sheetGenerated"]
        return <span>{val && item["filePath"] ? <FontAwesomeIcon
          icon='download'
          onClick={() => this.props.downloadFile(`${apiCalls.openCorporateFileDownload}?path=${item["filePath"]}`, item["fileName"])}
        /> : null}</span >
      }
      else if (tableItem.fieldType === "thirdlevel") {
        let result
        let data = item && item.items.length > 0 ? item.items : ""
        result = data ? data.map(a => a.label) : ""
        return <span>
          {result ? result.join(" , ") : []}
        </span >
      }
      else if (tableItem.fieldType === "profile") {
        return <span>
          <img
            src={item[column.field] ? `${config.imgUrl}/${item[column.field]} ` : configImages.defaultImg}
            alt=""
            className="appLogo" />
        </span >
      } else if (tableItem.fieldType === "nameIssue") {
        let val = item[column.field]
        return <span>
          {val === null ? "N/A" : val}
        </span >
      } else if (tableItem.fieldType === "ShowData") {
        return <div className='row ml-0'><span
          onClick={() => this.showNotesData(item)}
          style={{ color: "#354f6e", cursor: "pointer" }}
          data-toggle="tool-tip"
          title={this.state.getNotesresponse && this.state.getNotesresponse.length > 0 && this.state.getNotesresponse[0] && this.state.getNotesresponse[0].propertyid && this.state.getNotesresponse[0].propertyid === item.propertyID ? "Status:  " + this.state.getNotesresponse[0].MatchStatus + "\n\nNotes:  " + this.state.getNotesresponse[0].StatusNotes :
            this.state.isValidData && this.state.getNotesresponse && this.state.getNotesresponse.length === 0 && this.state.validatedNotesId === item.propertyID ? "This reccord has no Data" :
              "Please click before to see the status"}>
          {item[column.field]}
        </span>
          {
            this.state.getNotesresponse && this.state.getNotesresponse.length > 0 && this.state.getNotesresponse[0] && this.state.getNotesresponse[0].propertyid === item.propertyID ?
              <span><FontAwesomeIcon
                style={{ width: "14px", float: "left", marginRight: 5, marginTop: 4, marginLeft: 4 }}
                icon={faCopy}
                onClick={() => this.clipToCopyBoard(this.state.getNotesresponse[0].StatusNotes)}
                color={'red'}
                data-toggle="tool-tip"
                title={"Click to copy the notes"}
              /></span> : null
          }
        </div >

      } else if (tableItem.fieldType === "ActiveIndicator") {
        if (item[column.field] === "Yes" || item[column.field] === true) {
          return <span>
            {"True"}
          </span >
        } else if (item[column.field] === "No" || item[column.field] === false) {
          return <span>
            {"False"}
          </span >
        }
      } else if (tableItem.fieldType === "Boolean") {
        if (item[column.field] === true) {
          return <span>
            {"True"}
          </span >
        } else if (item[column.field] === false) {
          return <span>
            {"False"}
          </span >
        }
      } else if (tableItem.fieldType === "onlyTime") {
        if (item[column.field] && item[column.field].length > 0) {
          let d = new Date(); // Creates a Date Object using the clients current time
          let [hours, minutes, seconds] = item[column.field].split(':')
          d.setHours(+hours); // Set the hours, using implicit type coercion
          d.setMinutes(minutes); // can pass Number or String - doesn't really matter
          d.setSeconds(seconds);
          // If needed, you could also adjust date and time zone
          let rowValue = d.toString()
          let newhours = new Date(rowValue);
          var now = moment().hour(hours).minute(minutes)
          now = now.format("hh:mm A")
        } else {
          var now = ""
        }
        return <span>
          {now}
        </span >
      }
      else if (tableItem.fieldType === "Percentage") {
        let val = Math.round(item[column.field] * 100)
        return <span>
          {val}
        </span >
      } else if (tableItem.fieldType === "maxCount" || tableItem.fieldType === "minCount") {
        let val;
        val = this.customCount(item, tableItem.field, tableItem.fieldType)
        return <span>
          {val}
        </span >
      } else if (tableItem.fieldType === "StatesArray") {
        let val = item[column.field].length
        return <span>
          {val}
        </span >
      }
      else if (tableItem.fieldType === "StatesArray1") {
        let val = item[column.field] === 0 ? 0 : item[column.field]
        return <span>

          {val}
        </span >
      } else if (tableItem.fieldType === "ShowData1") {
        let val = item[column.field]
        return <span>
          {val === 0 ? 0 : val}
        </span >
      } else if (tableItem.fieldType === "formatCurrency") {
        let dollarUS = this.getFormattedCurrencyValue()
        let val
        if (column.field === "PropertyKnownMaxValueAmountSum" || column.field === "PropertyEstimatedMaxValueAmountSum") {
          val = item[column.field].toFixed(0)
          val = dollarUS.format(val)
          val = val.split(".")[0]
        } else {
          val = dollarUS.format(item[column.field])
        }
        // let val = item[column.field]
        return <span style={(column.field === "PropertyKnownMaxValueAmountSum" || column.field === "PropertyEstimatedMaxValueAmountSum") && this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total" ? { fontWeight: "bold" } : {}}>
          {item[column.field] !== undefined && item[column.field] !== null && item[column.field] !== "" && val !== "$NaN" ? val : "N/A"}
        </span >
      }
      else if (tableItem.fieldType === "redirectFormatCurrency") {
        let dollarUS = this.getFormattedCurrencyValue()
        let formatVal = Math.ceil(item[column.field])

        let val = dollarUS.format(formatVal)

        let value = val.split(".")
        value = value[0]
        // ?sellerName = ${item[column.field] }& sellerId=${ this.state.PropertyData.selectedSeller.EstateID }
        if (value === "$NaN") {
          return 0
        } else {
          return <span>
            {/* {this.props.type === "Deal Sizing Report For Companies" ? <a className="pl-0" onClick={() => this.getUrlbyParams(type === "US Opportunity" ?'summary':'deals',item)} href={`${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}`}> {item[column.field]}</a> : */}
            {value}
          </span>
        }
      }
      else if (tableItem.fieldType === "currency") {
        return <span>
          {item[column.field] > 0 ?
            <span>{item[column.field].toFixed(1)}</span> : <span>{column.field.includes("Hours") ? "" : 0}</span>}
        </span>
      }
      else if (tableItem.fieldType === "dateTime") {
        return <span>
          {item && item[column.field] ?
            <span>{dateFormats.formatDate(item[column.field], "MM/DD/YYYY")}</span> : ""}
        </span>
      }
      else if (tableItem.fieldType === "dateTimeFormat") {
        return <span>
          {item && item[column.field] ?
            <span>{dateFormats.formatDate(item[column.field], "MM/DD/YYYY hh:mm a")}</span> : ""}
        </span>
      }

      else if (tableItem.fieldType === "redirect") {
        var screenType = ["Ready To Review", "UP Opportunity", "UP Opportunity – V2", "AvailableToMap", "AvailableToReMap", "Ready To File", "On Hold", "Seller Negative Notice", "CA-N Properties", "Resource Needed", "Required Evidence", "HighValueProperties", "Grouped By Deal", "Grouped By Deal/State"]
        if ((this.props.type === "UP Opportunity – V2" || this.props.type === "AvailableToReMap") && item["EstateName"] === "Total") {
          return <span style={{ marginLeft: "19px", fontWeight: "bold" }
          }> {item[column.field]}</span >
        }
        // ?sellerName = ${item[column.field] }& sellerId=${ this.state.PropertyData.selectedSeller.EstateID }
        return <span>
          {/* {/ {this.props.type === "Deal Sizing Report For Companies" ? <a className="pl-0" onClick={() => this.getUrlbyParams(type === "US Opportunity" ?'summary':'deals',item)} href={`${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}`}> {item[column.field]}</a> : /} */}
          <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={this.state.PropertySharebody} onClick={() => this.getUrlbyParams(screenType.includes(propType) ? 'summary' : 'deals', item, column.field)} >{item[column.field]}</a>

        </span>
      } else if (tableItem.fieldType === "website") {
        return <span>
          {(tableItem.url && item["fileUrl"] && item["fileUrl"] !== null) || (!tableItem.url && item[column.field] !== null) ?
            <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={tableItem.url ? `${item["fileUrl"]}` : `http://${item[column.field]}`}>{item[column.field]}</a>
            : < span style={{ paddingLeft: 20, paddingRight: 20 }}>{item[column.field]}</span>}
        </span >

      } else if (tableItem.fieldType === "downloadBtn") {
        return <span>
          <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={item["fileUrl"] ? `${item["fileUrl"]}` : `http://${item["fileUrl"]}`}>Download</a>
        </span >

      } else if (tableItem.isLink === "yes") {
        return <span>
          <span>
            <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`http://${item["MostRecent10KFormUrl"]}`}>{item[column.field]}</a>
          </span >
        </span >
      }

      else if (tableItem.fieldType === "bucketCount") {
        let val = this.getvalfromObj(item[column.field], "COUNT(ns᎐CompanyID)")
        return <span>
          <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={this.state.PropertySharebody} onClick={() => this.getUrlbyParams(propType === "UP Opportunity" || this.props.type === "UP Opportunity – V2" || this.props.type === "AvailableToReMap" || this.props.type === "AvailableToMap" || propType === "Ready To File" || propType === "On Hold" || propType === "Seller Negative Notice" || propType === "CA-N Properties" || this.props.type === "Resource Needed" || this.props.type === "Required Evidence" || this.props.type === "HighValueProperties" || this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" ? 'summary' : 'deals', item, column.field)} >{val}</a>
        </span >
      }
      else if (tableItem.fieldType === "Badge") {
        let mcolor = item.status && item.status.includes("Paused") ? 'warning' : self.getColorFromOptions(tableItem.options, item.status);

        return <div className="row ml-0 mr-0" style={{ width: '100%', display: 'contents' }}>
          <span style={{ width: '10px', marginRight: 5 }}
            data-toggle="tool-tip"
            title={item.status}>
            <FontAwesomeIcon
              style={{ width: "9px", float: "left", marginRight: 5, marginTop: 4 }}
              icon={faCircle}
              color={mcolor === 'success' ? '#5cb85c' : mcolor === 'warning' ? 'red' : mcolor === 'danger' ? 'red' : ""}
              data-toggle="tool-tip"
              title={item.status}
            />
          </span>
          <span className="datatable_status_field">{item[column.field]}</span>
        </ div >
      } else if (tableItem.fieldType === "Role") {
        let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
        return <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>
      } else if (tableItem.fieldType === "icon") {
        let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
        let tooltip = self.getTooltipFromOtptions(tableItem.options, item[column.field]);
        return <FontAwesomeIcon
          style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
          color='white'
          icon={tableItem.iconName}
          data-toggle="tool-tip"
          title={tooltip}
        />
      } else if (tableItem.fieldType === "Array") {
        let val;
        val = this.flattenArray(item[column.field]);
        if (tableItem.openNotes) {
          let data = this.getNotesDetails(val, column, item[column.field])
          return <>{data}</>
        } else {
          return <span style={tableItem.style} data-toggle="tool-tip"
            title={val}
          >
            {val}
          </ span >
        }

      } else if (tableItem.fieldType === "Object") {

        if (item && item.importResults && item.importResults[column.field]) {
          return <span style={tableItem.style} data-toggle="tool-tip"
            title={item.importResults[column.field]}
          >
            {item.importResults[column.field]}
          </ span >
        }

        if (item && item.uploadFileTracking && item.uploadFileTracking[column.field]) {
          return <span style={tableItem.style} data-toggle="tool-tip"
            title={item.uploadFileTracking[column.field]}
          >
            {item.uploadFileTracking[column.field]}
          </ span >
        }


      } else if (tableItem.fieldType === "ArrayNote") {
        let val = item[column.field]
        val = this.flattenArray(item[column.field]);
        if (tableItem.openNotes1) {
          let data = this.getNotesDetailsClaim(val, column, item[column.field], item)
          return <>{data}</>
        } else {
          return <span style={tableItem.style} data-toggle="tool-tip"
            title={val}
          >
            {val}
          </ span >
        }

      } else if (tableItem.fieldType === "slicedArray") {
        let val;
        val = this.slicedArray(item[column.field], column.field);
        return <span style={tableItem.style} data-toggle="tool-tip"
          title={item[column.field]}
        >
          {val}
        </ span >
      }
      else if (tableItem.fieldType === "PaymentArray") {
        let val;
        val = this.flattenArray(item[column.field], item);
        return <div className='row ml-0' style={item && !item.ChildCount ? { textAlign: "end" } : { justifyContent: "end", marginRight: 10 }}
        >
          <div style={{
            alignSelf: "center", width: "85%"
          }} >
            {val}
          </div>

        </ div >
      } else if (tableItem.fieldType === "PaymentBtn") {
        return <div className='' style={{ alignSelf: "center" }}>
          {item && item.ChildCount ?
            <div className='d-flex' style={{ justifyContent: "space-around" }}>
              < Button
                color="primary"
                size="sm"
                outline
                // disabled={true/*this.props.type === "crMatrix" && item.Answers > 0 ? true : false*/}
                className="mb-0 m-1 ml-0 p-1"
                style={{ width: "50px" }}
                onClick={() => this.openFormModal(item, 'edit', "Payments")}>
                <div style={{ alignSelf: "center" }}>
                  {'+'}
                </div>
              </Button>
            </div>
            : null}
        </div>
      }
      else if (tableItem.fieldType === "SumAmount") {
        let val = this.getcollectedData(item);
        return <div>
          {item && item.ChildCount ?
            <>
              <span className="mr-2">
                {val}
              </span>
              {val !== "" ?
                <span>
                  <FontAwesomeIcon
                    style={{ color: "grey", cursor: 'pointer' }}
                    color='lightgrey'
                    icon={faExternalLinkAlt}
                    data-toggle="tool-tip"
                    title={"click to see payment details"}
                    onClick={() => this.setState({
                      openpaymentsModal: true,
                      selectedRowPayments: item
                    })}
                  />
                </span>
                : null}
            </>
            : ""}
        </div>
      }
      else if (tableItem.fieldType === "ClaimReason") {
        // let flatArray = [].concat(...item["PaymentsData"]);
        // var paymentAmount = flatArray.reduce(function (a, b) { return a + b; }, 0);
        let val = item.ClaimReason;
        // if (paymentAmount === 0) {
        return <span>

          {val ? val : null}
        </span>
      }
      else if (tableItem.fieldType === "Analyst") {

        // let flatArray = [].concat(...item["PaymentsData"]);
        // var paymentAmount = flatArray.reduce(function (a, b) { return a + b; }, 0);
        let val = item.Analyst;
        // if (paymentAmount === 0) {
        return <span>

          {val ? val : null}
        </span>
      }
      else if (tableItem.fieldType === "ClaimStatus") {

        // let flatArray = [].concat(...item["PaymentsData"]);
        // var paymentAmount = flatArray.reduce(function (a, b) { return a + b; }, 0);
        let val = item.ClaimStatus;
        // this.setClaimReason(val)
        // if (paymentAmount === 0) {
        return <span>

          {val ? val : "Claim-Pending"}
        </span>

      } else if (tableItem.fieldType === "splitVal") {
        let val = item[column.field].split("-");
        return <span style={tableItem.style} >
          <span>{val[0]}</span>
        </span>
        // }
      } else if (tableItem.fieldType === "label" && tableItem.field === "company") {
        // let val = item[column.field].split("-");
        return <span style={tableItem.style} >
          <span data-toggle="tool-tip" title={item[column.field]}><b style={{ fontSize: 14 }} >{item[column.field]}</b></span>
          {item.round ? <span> ({item.round}) </span> : null}
        </span>
        // }
      } else if (tableItem.fieldType === "label") {
        // let val = item[column.field].split("-");
        return <span style={tableItem.style} >
          <span data-toggle="tool-tip" title={item[column.field]}><b style={{ fontSize: 14 }} >{item[column.field]}</b></span>
        </span>
        // }
      }
      else if (tableItem.fieldType === "number") {
        let val = item[column.field]
        return <span >
          {val ? val : 0}
        </span>
      } else if (tableItem.fieldType === "BoolText") {
        let val = item.activeIndicator ? item.activeIndicator : item.ActiveIndicator ? item.ActiveIndicator : item.DWRowIsCurrent ? item.DWRowIsCurrent : false
        const value = val.toString()
        if (value) {
          return <div>< FontAwesomeIcon
            className="status_logo"
            color={value === "true" || value === true ? '#5cb85c' : 'red'}
            icon={faCircle}
            data-toggle="tool-tip"
            title={value === "true" ? 'Active' : 'Inactive'} />
            <span >{item[column.field]}</span></div>
        }
      } else if (tableItem.fieldType === "Complex") {
        let data = this.ObjectbyString(item, column.field)
        return <span>
          {data}
        </span>
      } else if (tableItem.fieldType === "hasPropertyCoOwner" || tableItem.fieldType === "confirmedOnSite" || tableItem.fieldType === "reportedMissing") {
        if (item[column.field]) {
          return <span>
            {"Yes"}
          </span>
        } else {

          return <span>
            {"No"}
          </span>
        }
      } else if (tableItem.fieldType === "Actions") {
        return (
          <div
            style={{ justifyContent: 'center' }}>
            {(this.props.editRequired && this.props.type !== "Weekly Recovery Plan") ?
              <>
                < Button
                  color="primary"
                  size="sm"
                  outline
                  disabled={this.props.type === "crMatrix" && item.Answers > 0 ? true : false}
                  className="mb-0 m-1 ml-0 p-1"
                  onClick={() => this.props.type === 'State Attributes' ? this.props.toggleSideBar(true, 'Edit', this.state.filterCriteria, item) : this.openFormModal(item, 'edit')}>
                  Edit
                </Button>
                {tableItem.showDelete &&
                  < Button
                    color="danger"
                    size="sm"
                    outline
                    className="mb-0 m-1 ml-0 p-1"
                    onClick={() => this.props.type === 'State Attributes' ? this.props.toggleSideBar(false, 'Delete', this.state.filterCriteria, item) : this.openFormModal(item, 'delete')}>
                    Delete
                  </Button>} </>
              : ''
            }
            {
              (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? <a href={`${window.location.protocol}//${window.location.host}/sellersEdit/deal/${item.DealID}`} target="_blank"> <FontAwesomeIcon icon={faExternalLinkAlt}
                className='pl-1'
                data-toggle="tool-tip"
                // validate={[required]}
                title="click to open in next tab"
                style={{ width: 20, color: "grey", marginLeft: "-20px" }}
              // onClick={() => this.openSellerInNewTab(item)}
              /> </a>
                : ''
            }
            {
              (this.props.deleteRequired) ?
                this.props.type === "Weekly Recovery Plan" ?
                  <FontAwesomeIcon
                    className="status_logo"
                    // color={value === "true" ? '#5cb85c' : 'red'}
                    style={{ cursor: "pointer" }}
                    icon={faMinus}
                    data-toggle="tool-tip"
                    // title={value === "true" ? 'Active' : 'Inactive'}
                    onClick={() => this.deleteConfirmAction(item._id, "Delete")}
                  />
                  :
                  < Button
                    color="danger"
                    size="sm"
                    outline
                    className="mb-0 m-1 p-1"
                    onClick={() => this.deleteConfirmAction(this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue" ? item : item._id, "Delete")}>
                    {this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue" ? "Cancel" : "Delete"}
                  </Button>
                : null

            }
          </div >
        )
      } else if (tableItem.fieldType === "Date") {
        let val = item[column.field]
        return <span >
          {val}
        </span>
      } else if (tableItem.fieldType === "Time") {
        let val = item[column.field]
        return <span >
          {val}
        </span>
      }
    } else {
      if ((item && item[column.field]) && typeof item[column.field] !== 'object') {
        if (column.field === "APAType") {
          let z = this.state.Apa.find(elem => elem.value === item[column.field])

          if (z) {
            //with z values hit the api call
            return z.label
          }
        } else {
          if (this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total") {
            return <span style={{ fontWeight: "bold" }
            }> {item[column.field]}</span >
          }
          return <span data-toggle="tool-tip" title={item[column.field]} >{item[column.field]}</span>;
        }
      }
    }
  }

  clickConfirm() {
    this.closeConfirmationModal();
  }

  ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  rowClassName(item) {

    if (item && item.status) {
      if (item.status === 'Blocked') {
        return (
          { 'p-highlightRow': (item.status === 'Blocked') }
        )
      }
    } else if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && item && item.ScoringStatus && item.ScoringStatus !== null) {
      return {
        'p-disabledRow': (item.ScoringStatus !== null)
      }
    } else if (this.props.custumisedRowHighlights) {
      return this.props.custumisedRowHighlights(item)
    }
  }

  handleDateInterval(startDate, endDate) {
    if (startDate) {
      this.setState({
        startDate: startDate
      })
    }
    if (endDate) {
      this.setState({
        endDate: endDate
      })
    }
  }
  // ShowHideColumns Button Click Handler
  //Settings Modal open
  openShowHideColumnsModal = () => {
    this.setState({
      isOpenShowHideColumnsModal: true,
    })
  }

  getSettings = async () => {
    this.props.getSettings();
  }

  searchInDateRange() {
    if (this.state.startDate && this.state.endDate) {
      let filterCriteria = {};
      let startDate = (moment(this.state.startDate).format(config.dateDayMonthFormat));
      let endDate = (moment(this.state.endDate).format(config.dateDayMonthFormat));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        {
          'key': 'date',
          'value': startDate,
          'type': 'gte'
        },
        {
          'key': 'date',
          'value': endDate,
          'type': 'eq'
        }];
      this.setState({ filterCriteria: filterCriteria });
      this.getDataFromServer(filterCriteria);
    } else {
      alert('no dates selected');
    }
  }
  toggle = async (id) => {
    let openId = this.state.openId;
    this.setState({ openId: openId === id ? '' : id });
  }

  print = () => {
    window.print();
  }

  toogleMultiSelect = async () => {
    await this.setState({
      showMultiSelectDropDown: !this.state.showMultiSelectDropDown
    })
  }

  onGlobalSearchChange = async (e) => {
    let suggestions = this.state.suggestions;
    suggestions[0] = { "label": `Search By First Name ${e.query}` }
    await this.setState({ suggestions: suggestions })
  }


  getScreenHeader() {
    const { t } = this.props;
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12 row ' style={{ width: "100%", justifyContent: "space-between" }}>
            <h4><span className='postionRelative pt-2'>
              {this.props.fontAwesome ?
                <FontAwesomeIcon
                  className={'tableheadericon'}
                  color="#354f6e"
                  icon={this.props.type === "Status" ? faToggleOff : this.props.type === "Properties" ? faCity : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? faUsers : this.props.type === "Process Documents" ? faFolder : ""} /> :
                <span className={`tableheadericon lnr lnr-${this.props.icon}`} />}
              <b
              ><Link to={(this.props.routeTo)} onClick={this.closeFormModal} className="tableType" >
                  {t(this.props.type)}
                </Link>{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
              </b>
            </span></h4>
            {this.props.type === "Company" ? this.chooseCompanyType() : null}
            <span className='float-right pl-3 ' style={{ display: "inline-flex" }}>
              {!this.state.isOpenFormModal && <div>
                <div
                  className="p-inputgroup searchField"
                >{this.props.isClearFilters ?
                  <span>
                    <Button color="primary"
                      size="sm"
                      outline
                      style={{ height: 36 }}
                      className="py-2 px-5 mb-0 mx-1"
                      onClick={() => this.getremoveFilters()}>Clear Column Filters
                    </Button>
                  </span> : null}
                  {this.props.globalSearch ?
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" style={{ display: "block", fontSize: "14px" }} />
                      <span className="p-input-icon-right">
                        <InputText
                          type="search"
                          name='globalSearch'
                          id='globalSearch'
                          // value={this.state.selectFilterValue}
                          style={{ height: 37, paddingLeft: "2.5rem" }}
                          onKeyPress={(e) => this.onFilterGlobalChange(e)}
                          onChange={(e) => this.onFilterGlobalChange(e, 'globalSearch')}
                          placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                          size={(this.props.type === "Company" || this.props.type === "Investor") ? "25" : "20"} />
                      </span>
                    </span> : null}

                </div>
              </div>}
              {this.props.addRequried ?
                <Button color="primary"
                  style={{ padding: "7px 25px" }}
                  outline
                  className="mb-0 ml-1 mr-0"
                  onClick={(e) => this.openFormModal('add')}>
                  <FontAwesomeIcon
                    icon='plus'
                    className='Addbtn' size='sm'
                    data-toggle="tool-tip" title={t("Add")}
                    onClick={(e) => this.openFormModal('add')}
                  />
                  {this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" || this.props.type === "Recovery Planner V2" ? 'Create Task' : 'Add'}
                </Button>
                : null}

            </span>

          </div >
          <div className=' pb-1'>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {this.props.filterRequired ? <span className='mr-3' ref={this.closeMultiSelectDropdown}>
                  <span className='multiSelectDropDownCard '>
                    <span onClick={this.toogleMultiSelect}>
                      {t('Filters')}
                    </span>
                    <span onClick={this.toogleMultiSelect}>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className='ml-1'
                        color='grey'
                      />
                    </span>
                    {this.state.showMultiSelectDropDown ?
                      <Card className=' itemsWarperCard' >
                        {
                          this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0 ? this.state.tablefieldsToShow.map((item, index) => {
                            return (
                              item.displayInFilter && item.show && < div >
                                <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                <ul className='pl-0'>
                                  {
                                    item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                      return (
                                        <div className='multiSelectWrapperItems' onClick={() => this.changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                          <span className='chekcIconAdjust'>
                                            <Checkbox
                                              // onChange={e => setChecked(e.checked)}
                                              checked={optionsItem.checkIcon}>
                                            </Checkbox>
                                            {/* <FontAwesomeIcon
                                                icon='check'
                                                color={optionsItem.checkIcon ? 'grey' : 'white'}
                                              /> */}
                                          </span>
                                          <span className='chekcIconAdjustLabel'>
                                            {t(optionsItem.label)}
                                          </span>
                                        </div>
                                      )
                                    }) : null
                                  }
                                </ul>
                                <hr className='my-0'></hr>
                              </div>
                            )
                          }) : null
                        }
                      </Card>
                      : null}
                  </span>

                </span> : null}

                {!this.state.isOpenFormModal && this.state.actionsTypes && this.state.actionsTypes.length > 0 ? <DropDown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  // appendTo={document.body}
                  name='actions'
                  value={this.state.actions}
                  disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                  options={this.state.actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => this.onActionsChange(e, 'dropdownFilter')}
                /> : null}

                {!this.state.isOpenFormModal && this.props.printRequried ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto  mb-0 mt-1"
                    onClick={() => this.print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                      onClick={() => this.print()}
                    />
                  </Button>
                  : null}
                {this.props.excelDownload ? <span><Button color="primary"
                  className='p-1 py-0 mb-0 mt-1 ml-1 mr-1' style={{ height: "38px" }}
                  size={'sm'} onClick={this.handleSelectedAction}
                  outline
                  disabled={!this.props.apidataforExport && !this.state.apiUrlforExport ? true : false}
                >
                  <FontAwesomeIcon
                    icon='download'
                    data-toggle="tool-tip" title={"Export To excel"}
                    className='pl-1' size='lg' />
                </Button>
                  {this.getExcelDownload()}</span>
                  : null}
                {!this.state.isOpenFormModal && this.props.exportRequried && <Button color="primary"
                  className='px-2 ml-2 mb-0 mt-1'
                  size={'sm'} onClick={this.getDataToExport}>
                  <FontAwesomeIcon
                    icon='file'
                    data-toggle="tool-tip" title={t("Export To CSV")}
                    className='p-1' size='lg' />
                </Button>}
                {!this.state.isOpenFormModal && this.props.exportRequried &&
                  <CSVLink
                    data={this.state.exportData}
                    // style={{ textDecoration: "wrap" }}
                    headers={this.state.exportHeaders}
                    filename={this.props.tabOptions ? `${this.state.activeTab}-${this.props.type}.csv` : `${this.props.type}.csv`}
                    newLineSeparator="\r\n"
                    className="hidden text-gray py-0 px-1"
                    ref={(r) => this.csvLinkRef = r}
                    target="_blank" >
                  </CSVLink>}
                {!this.state.isOpenFormModal && this.props.sample ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 mt-1 mb-0"
                    onClick={() => this.bulkUploadMoadal()}>
                    <FontAwesomeIcon
                      icon='upload'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Bulk Upload")}
                      onClick={() => this.bulkUploadMoadal()} />
                  </Button>
                  : null}

                {!this.state.isOpenFormModal && this.props.gridRequried ? <ButtonGroup className='mb-0 mr-3'>
                  <Button color="primary"
                    outline={this.state.viewType === 'grid' ? false : true}
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => this.changeViewType('grid')}
                  >
                    <FontAwesomeIcon
                      icon='grip-horizontal'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Grid")}
                      onClick={() => this.changeViewType('grid')}
                    />
                  </Button>
                  <Button color="primary"
                    size="sm"
                    outline={this.state.viewType === 'list' ? false : true}
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => this.changeViewType('list')}
                  >
                    <FontAwesomeIcon
                      icon='list'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("List")}
                      onClick={() => this.changeViewType('list')}
                    />
                  </Button>
                </ButtonGroup> : null}

                {!this.state.isOpenFormModal && this.props.settingsRequired ? <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => this.openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Settings")}
                    onClick={() => this.openShowHideColumnsModal()}
                  />
                </Button> : null}

                {/* Add button */}
                {/* {this.props.addRequried ?
                  <Button color="primary"
                    // size="sm"
                    //className='p-1 m-1'
                    outline
                    className="mb-0 ml-1 mt-1 mr-0"
                    onClick={(e) => this.openFormModal('add')}>
                    <FontAwesomeIcon
                      icon='plus'
                      className='Addbtn' size='sm'
                      data-toggle="tool-tip" title={t("Add")}
                      onClick={(e) => this.openFormModal('add')}
                    />
                    {this.props.type==="List View" ? 'Add Task' : 'Add'}
                  </Button>
                  : null} */}

                {/* priority  in Issues*/}
                {/* {!this.state.isOpenFormModal && <Button
                  color="secondary"
                  size="sm"
                  // className="p-1 ml-auto"
                  className={this.props.addRequried ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                  onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    size='lg'
                    data-toggle="tool-tip" title={t("Refresh")}
                    color={config.templateColor}
                    className='refreshIcon pl-1' />
                </Button>} */}
              </span>
            </span>
          </div>
        </div >
      </div >
    )
  }
  chooseCompanyType = () => {
    let type = localStorage.getItem("CompanyType")
    return (
      <div className='row mb-2'>
        {/* <div className="field-radiobutton mt-2">
          <Checkbox value="Private" onChange={() => this.onShowPrivateCompanies()} checked={this.state.isTopFilter} />
          {/* <label htmlFor="city1" className='mb-0'>Pitchbook</label> */}
        {/* </div> */}
        <div className="p-input-icon-left mr-2">
          <i className="pi pi-search" />
          <span className="p-input-icon-right">
            <InputText
              type="search"
              name='globalSearch'
              id='globalSearch'
              style={this.props.type ? { height: 35, paddingLeft: "2.5rem", marginTop: 2 } : { display: "none" }}
              onKeyPress={(e) => this.onFilterGlobalChange(e)}
              onChange={(e) => this.onFilterGlobalChange(e, 'globalSearch')}
              // value={this.state.selectFilterValue}
              placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
              size={"35"} />
          </span>
        </div>
        <div className='row mt-2 float-right'>
          <div className="field-radiobutton mr-2">
            <RadioButton inputId="city1" className="ml-2 mr-2" name="city" value="Private" onChange={() => this.onShowPrivateCompanies("Private", 'results')} checked={type === 'Private'} />
            <label htmlFor="city1" className='mb-0'>Pitchbook</label>
          </div>
          <div className="field-radiobutton mr-2">
            <RadioButton inputId="city2" className="ml-2 mr-2" name="city" value="Public" onChange={() => this.onShowPrivateCompanies("Public", 'results')} checked={type === 'Public'} />
            <label htmlFor="city2" className='mb-0'>SEC</label>
          </div>
        </div>
      </div>
    )
  }

  // chooseCompanyType = () => {
  //   return (
  //     <div className='row mb-2'>
  //       <div className="field-radiobutton mt-2 d-block">
  //         {/* <label className="mr-1 kanban_filter_label mr-2" style={{ marginTop: 4 }}>
  //           Parent Entities Only
  //         </label> */}
  //         <Dropdown
  //           value={this.state.selectedCountrie}
  //           appendTo={document.body}
  //           options={countriesSF}
  //           // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
  //           onChange={(e) => this.setState({ selectedCountrie: e.value })}
  //           style={{ height: 35 }}
  //         // filter={true}
  //         // disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


  //         // placeholder={item.header}
  //         />
  //         {/* <label htmlFor="city1" className='mb-0'>Pitchbook</label> */}
  //       </div>
  //       <div className='mt-2 mx-2'>
  //         <Dropdown
  //           value={this.state.companyFilterType}
  //           appendTo={document.body}
  //           options={[{ label: "Starts With", value: "sw" }, { label: "Contains", value: "regexOr" }]}
  //           // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
  //           onChange={(e) => this.setState({ companyFilterType: e.value, addedMatchforCompany: true })}
  //           style={{ height: 35 }}
  //         // filter={true}
  //         // disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


  //         // placeholder={item.header}
  //         />

  //       </div>
  //       <div className="p-input-icon-left mt-1">
  //         <i className="pi pi-search" />
  //         <span className="p-input-icon-right">
  //           <InputText
  //             type="search"
  //             name='companySearch'
  //             id='companySearch'
  //             style={{ height: 36, paddingLeft: "2.5rem", marginTop: 2 }}
  //             // onKeyPress={(e) => this.onFilterGlobalChange(e)}
  //             // onChange={(e) => this.setState({ companySearch: e.target.value })}
  //             // value={this.state.companySearch}
  //             placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
  //             size={"35"} />

  //         </span>

  //       </div>
  //       <Button color="primary" outline className="mx-2 my-2"
  //         onClick={() => this.onFilterdateChange(document.getElementById("companySearch").value, "CompanyName", "", this.state.companyFilterType)}>
  //         Go</Button>
  //       <Button color="primary" outline
  //         className='p-1 mx-2 my-2'
  //         // disabled={this.props.type ==="Manually Loaded Properties"}
  //         size={'sm'} onClick={() => this.onFilterdateChange("", "CompanyName", "", this.state.companyFilterType)}>
  //         Clear
  //       </Button>
  //     </div>
  //   )
  // }

  onShowPrivateCompanies = async (event) => {
    await this.setState({
      isTopFilter: this.state.isTopFilter === true ? false : true,
      city: event
      // allUsersData: []
    })
    await this.setState({
      isRedirectTo: true
    })
    localStorage.setItem("CompanyType", event)
    this.getTableFieldsOrder();
    this.getDataFromServer(this.state.filterCriteria)
  }


  toggle = async (e) => {
    let element = document.getElementById(e);
    element.classList.add("active");
  }

  showActive = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      showActive: !prevState.showActive
    }));
    this.onMultiSelectFilterChange('status', 'Active', this.state.showActive ? 'pop' : 'push')
  }

  showInactive = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      showInactive: !prevState.showInactive
    }));
    this.onMultiSelectFilterChange('status', 'Inactive', this.state.showInactive ? 'pop' : 'push')
  }

  showPending = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      showPending: !prevState.showPending
    }));
    this.onMultiSelectFilterChange('status', 'Pending', this.state.showPending ? 'pop' : 'push')
  }

  onFilterGlobalChange = (event) => {
    if (event.key === "Enter") {
      if (event.target.value.length > 0) {
        this.onFilterChange(event.target.value)
      } else {
        let filterCriteria = this.state.filterCriteria
        if (this.props.type === "Company") {
          localStorage.setItem('SearchedCompany', "")
        }
        filterCriteria.globalSearch = {}
        delete filterCriteria.globalSearch
        this.setState({
          filterCriteria: filterCriteria,
          page: "1",
          first: 0,
        });
        this.getDataFromServer(filterCriteria)
      }
    }
  }



  getHeader() {
    // let screenPermissions = this.state.screenPermissions;
    const { t, handleSubmit } = this.props;
    let SelectedForOptions = [{ label: `Pending-${this.state.PendingCount}`, value: 'Pending' },
    { label: `Confirmed-${this.state.ConfirmedCount}`, value: "Confirmed" },
    { label: `Missing-${this.state.MissingCount}`, value: "Missing" }]
    return (
      <div>
        <div>
          <div className="row ml-0 mr-0" style={this.props.type === "SearchTerms" || this.props.type === "searchTermOnSite" ? { display: "none" } : null} >
            <div className="col-12 px-0 pb-2 d-flex justify-content-between"
              style={this.props.dontShowTitle && this.props.type !== "Site Confirmation Editor" && this.props.type !== "Ready To Review" && this.props.type !== "Property Editor" && this.props.type !== "Deal Statistics" && this.props.type !== "Negative Notice List Viewer" ? { height: "20px" } : this.props.type === "Property Editor" ? { height: "0px", display: "none" } : { height: "auto" }}
            >
              <span className="float-left">
                <p className="d-flex">
                  {/* {this.props.type === "Status" ? <span className="tableheadericon">
                  <FontAwesomeIcon icon={faToggleOff} /></span>
                  :
                  <span className={this.props.type !== "crMatrix" ? `tableheadericon lnr lnr-${this.props.icon}` : "tableheadericon ml-3 lnr lnr-layers"} />} */}
                  {!this.props.dontShowTitle ?
                    this.props.fontAwesome ?
                      <FontAwesomeIcon
                        className={'tableheadericon mt-2'}
                        color="#354f6e"
                        icon={this.props.type === "Status" ? faToggleOff : this.props.type === "Properties" ? faCity : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? faUsers : this.props.type === "Process Documents" ? faFolder : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor" ? faBuilding : ""} /> :
                      this.props.dontShowTitle ? null : <span className={`tableheadericon mt-2 lnr lnr-${this.props.icon}`} />
                    : null}<b
                    >{this.props.dontShowTitle ? null : <Link to={(this.props.routeTo)} style={this.props.type === "Legal Entity Suffix" || this.props.type === "APA Type Configuration" || this.props.type === "Available To File - Statuses" || this.props.type === "State-UP Filing Sub Category" || this.props.type === "State-UP Filing Category" || this.props.type === "Legal Entity Abbreviation" || this.props.type === "Claim Status" || this.props.type === "Claim Reason" || this.props.type === "Property Status" || this.props.type === "Property Reason" || this.props.type === "Business Name Issue Code" ? { fontSize: "18px", marginRight: "auto", position: "initial" } : { fontSize: "18px", marginRight: "auto" }} onClick={this.closeFormModal} className="tableType" >
                      {t(this.props.type === "crMatrix" ? "CR Matrix" : this.props.type === "Departments" ? "Teams" : this.props.type === "Business Name Issue Code" ? "New Name Issue Code" : this.props.type === "ClaimsManagement" ? "Claims Management" : this.props.type === "Up Prop Confirmation" ? "UP Property – Site confirmation" : this.props.type === "HighValueProperties" ? "High Value Properties" : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? "Deal Mgmt" : this.props.type)}
                    </Link>
                    }{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
                  </b>
                  {this.props.type === "ClaimSummary" ? this.getBackButton() : null}
                  {this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue"  ? this.props.getHeaderforSchedule() : this.props.updatedDate ? <div className='mt-3'><b >{`(As of Date & Time(ct)) :`}</b> <span>{dateFormats.formatDate(moment(this.props.updatedDate).utcOffset('-06:00'), "MM/DD/YYYY hh:mm a")}</span></div> : null}
                </p>
              </span>
              <span className={!this.props.dontShowTitle ? 'mx-2' : "mx-auto"} style={{ maxWidth: "630px" }} >
                {this.props.type !== "Property Editor" ? <p className="topbar__center topBarImageAlignment sdfsds" style={{ color: this.state.errorcolor === "green" ? "green" : "red" }} >{this.state.errorResponse ? this.state.errorMessage : null}</p> : ""}
                <p className="topbar__centerresponse topBarImageAlignment" style={{ color: "green" }}>{this.state.successResponse ? this.state.successMessage : null}</p>
                <p className="topbar__centerresponse topBarImageAlignment" style={{ color: "red" }}>{this.state.errorResponseHighProperty ? this.state.errorResponseHighPropertyMessage : null}</p>
                <p className="topbar__info topBarImageAlignment" style={{ color: "blue" }}>{!this.state.errorResponse && this.props.type !== "Property Editor"&&this.props.type !== "Deal Sizing Property Editor" && this.state.propertyStatus && this.state.propertyStatus.length > 0 ? `The results are sorted by ${this.state.propertyStatus}` : null}</p>
                <p className="topbar__info topBarImageAlignment" style={{ color: "blue" }} >{this.state.softWarning ? this.state.softMessage : null}</p>
              </span>
              <span

                className={this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap" ? "d-flex" : ""}
              >

                <span className="float-right d-flex" style={this.props.type === "Activity Details Reports" || this.props.type === "Required Evidence" ? { marginLeft: "4px" } : {}}>
                  <span className='d-flex'>
                    {/* {this.props.type==="Users"&&this.state.loginRole==="admin" */}
                    {this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" ?
                      < Button color="primary"
                        outline
                        size="sm"
                        style={{ height: 37 }}
                        className={this.props.type === "Users" ? "py-2 px-5 mb-0 mx-1 mr-2" : "py-2 px-5 mb-0 mx-1"}
                        onClick={(e) => this.changeCreateTaskFromReport({ value: "Site Confirmation" }, "add")}
                      >Create Task
                      </Button> : null}
                    {this.props.type === "HighValueProperties" ?
                      <div>
                        < Button color="primary"
                          outline
                          size="sm"
                          style={{ height: 37 }}
                          className={this.props.type === "Users" ? "py-2 px-5 mb-0 mx-1 mr-2" : "py-2 px-5 mb-0 mx-1"}
                          disabled={this.state.disableCreateTask || this.state.selectedRows.length === 0 ? true : false}
                          onClick={(e) => this.changeCreateTask({ value: "General Task" }, "add")}
                        >Create Task
                        </Button>
                        < Button color="primary"
                          outline
                          size="sm"
                          style={{ height: 37 }}
                          disabled={this.state.disableCreateTask || this.state.selectedRows.length === 0 ? true : false}
                          className={this.props.type === "Users" ? "py-2 px-5 mb-0 mx-1 mr-2" : "py-2 px-5 mb-0 mx-1"}
                          onClick={(e) => this.changeCreateTask({ value: "General Task" }, "addToTask")}
                        >Add To Task
                        </Button>
                      </div>
                      : null}



                    {this.props.excelDownload ? <span><Button color="primary"
                      className='p-1 py-0 mb-0 ml-1' style={{ height: "38px" }}
                      size={'sm'} onClick={this.handleSelectedAction}
                      outline
                      disabled={!this.props.apidataforExport && !this.state.apiUrlforExport ? true : false}
                    >
                      <FontAwesomeIcon
                        icon='download'
                        data-toggle="tool-tip" title={"Export To excel"}
                        className='pl-1' size='lg' />
                    </Button>
                      {this.getExcelDownload()}</span>
                      : null}
                    {this.props.exportRequried &&
                      <Button color="primary" outline
                        className='px-2 ml-2 mb-0 mr-0'
                        style={{ height: 37 }}
                        // disabled={this.props.type ==="Manually Loaded Properties"}
                        size={'sm'} onClick={this.getDataToExport}>
                        <FontAwesomeIcon
                          icon='download'
                          data-toggle="tool-tip" title={t("Export To CSV")}
                          className='pl-1' size='lg' />
                      </Button>}
                    {this.props.exportRequried && <CSVLink
                      data={this.state.exportData}
                      headers={this.state.exportHeaders}
                      filename={this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties" || this.props.type === "Resource Needed" ? `Available To File-${this.props.type}.csv` : this.props.tabOptions ? `${this.state.activeTab}-${this.props.type}.csv` : `${this.props.type}.csv`}
                      className="hidden text-white p-0"
                      ref={(r) => this.csvLinkRef = r}
                      target="_blank" >
                    </CSVLink>}
                  </span>
                  {this.props.type === "PropertyLoader" ?
                    <FontAwesomeIcon
                      icon={faSync}
                      className='Addbtn mt-2 mr-2' size='lg'
                      // style={{marginTop:""}}
                      color='#354f6e'
                      data-toggle="tool-tip" title={t("Refresh")}
                      onClick={(e) => this.refreshPropertyLoaderData()}
                    /> : null}
                  {this.props.isClearFilters && this.props.type !== "ClaimsManagement" ?
                    < Button color="primary"
                      outline
                      size="sm"
                      style={{ height: 37 }}
                      className={this.props.type === "Users" ? "py-2 px-5 mb-0 mx-1 mr-2" : "py-2 px-5 mb-0 mx-1"}
                      onClick={() => this.getremoveFilters()}>Clear Column Filters
                    </Button> : null}
                  {this.props.sample ? (
                    <Button
                      color="primary"
                      size="sm"
                      className="p-1 ml-auto mt-1"
                      onClick={() => this.bulkUploadMoadal()}
                    >
                      <FontAwesomeIcon
                        icon="upload"
                        className="pl-1"
                        size="lg"
                        data-toggle="tool-tip"
                        title="Bulk Upload"
                        onClick={() => this.bulkUploadMoadal()}
                      />
                    </Button>
                  ) : null}
                  {this.props.settingsRequired ?
                    <Button color="primary"
                      size="sm"
                      className="p-1 ml-4 mb-0 mt-1"
                      onClick={() => this.openShowHideColumnsModal()}
                    >
                      <FontAwesomeIcon
                        icon='cog'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title="Settings"
                        onClick={() => this.openShowHideColumnsModal()}
                      />
                    </Button>
                    : null}
                  {/* deal */}
                  <div className={this.props.isShownnFieldType ? "d-flex mt-n4 ml-2" : ""} style={this.props.isShownnFieldType ? { display: "block" } : { display: "none" }}>
                    <span >
                      <div
                        className={"mr-2 mt-2"}
                        style={this.props.isShownnFieldType ? { display: "grid" } : { display: "none" }}
                      >
                        <span>View By</span>
                        <Dropdown
                          value={this.state.selectedViewType}
                          // appendTo={document.body}
                          style={{ borderLeft: "1px solid lightgray", height: 34 }}
                          options={config.viewTypes}
                          onChange={(e) => this.selectedViewType(e.target.value)}
                        />
                      </div>
                    </span>
                    <div className="mb-0" >
                      {/* <span style={{ color: "white" }}>Group By</span> */}
                      <Button color="primary" outline
                        className="mb-0" style={this.props.isShownnFieldType ? { cursor: "pointer", marginTop: 29, marginLeft: 5 } : { display: "none" }}
                        onClick={() => this.openShowHideColumnsModal()}
                      >
                        Column Order
                      </Button>
                    </div>
                  </div>

                  {this.props.statusFilter ?
                    this.props.type === "Users" ?
                      <span className="m-n2" >
                        <div className="btn-group col-xs-12" data-toggle="buttons">
                          <label className={`btnlabel btn btn-outline-primary button_season${this.state.showActive ? ' active' : ''}`}
                            id="active">
                            <input type="checkbox" className="filterButton" name="season_revenue_1" id="season_revenue_1" value="Januar" onClick={e => this.showActive(e)} />Active
                          </label>
                          <label className={`btnlabel btn btn-outline-primary  button_season${this.state.showInactive ? ' active' : ''}`}
                          >
                            <input type="checkbox" className="filterButton" name="season_revenue_2" id="season_revenue_2" value="Februar" onClick={e => this.showInactive(e)} />Inactive
                          </label>
                          <label className={`btnlabel btn btn-outline-primary  button_season${this.state.showPending ? ' active' : ''}`}>
                            <input type="checkbox" className="filterButton" name="season_revenue_3" id="season_revenue_3" value="März" onClick={e => this.showPending(e)} />Pending
                          </label>
                        </div>
                      </span>
                      : this.props.type === "Site Confirmation Editor" ? <span>
                        <SelectButton className='ml-2' value={this.state.selectedvalueforStatus} options={this.props.SelectedForOptions ? this.props.SelectedForOptions : SelectedForOptions} onChange={(e) => this.selectedOptionData(e.value, "status")} />
                      </span> : ""
                    : ""}
                  {/* Add button */}
                  {this.props.addRequried && this.state.showAddButton && this.state.screenPermissions === "Edit" && !this.state.isPreviousWeek ?
                    <Button color="primary"
                      outline
                      style={{ height: 37 }}
                      // className="mb-0 mr-0 ml-2"
                      className={(this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? "mb-0 mr-0" : "mb-0 mr-0 ml-2"}
                      onClick={(e) => this.props.type === "Weekly Recovery Plan" ? this.addRow(this.state.allUsersData.length) : this.openFormModal('add')}
                    >
                      <FontAwesomeIcon
                        icon='plus'
                        className='Addbtn' size='sm'
                        data-toggle="tool-tip" title={t("Add")}
                        onClick={(e) => this.props.type === "Weekly Recovery Plan" ? this.addRow(this.state.allUsersData.length) : this.openFormModal('add')}
                      />
                      {this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" || this.props.type === "Recovery Planner V2" ? 'Create Task' : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? 'Add New Deal' : this.props.type === "crMatrix" ? 'Add New CR Matrix ' : 'Add'}
                      {/* {this.props.type === "Seller View" ? 'Add New Seller' : 'Add'} */}
                    </Button>

                    : null}
                  {(this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && this.state.screenPermissions === "Edit" ? <Button color="primary"
                    outline
                    style={this.props.type === "List View" && this.props.type === "Research Request Viewer" && this.props.type === "Seller Negative Notice Viewer" && this.state.userRole ? { display: "block", padding: "6px 25px", marginTop: "2px" } :
                      this.props.type === "Users" ? { padding: "5px 25px" } :
                        this.props.type !== "List View" || this.props.type !== "Research Request Viewer" || this.props.type !== "Seller Negative Notice Viewer" ? { padding: "6px 25px" } :
                          { display: "none" }}
                    className="mb-0 ml-1 mr-0"
                    onClick={() => this.ValidateDealSizingRecords()}
                  >
                    Deal Sizing
                  </Button> : null}
                </span>

                <span className="float-right pl-3">
                  <>
                    <div
                      className={`${this.props.type === "Users" ? `p-inputgroup searchField mt-1 ` : `p-inputgroup searchField`}`}
                    >
                      {this.props.globalSearch ? <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <span className="p-input-icon-right">
                          <InputText
                            type="search"
                            name='globalSearch'
                            id='globalSearch'
                            style={this.props.type ? { height: 35, paddingLeft: "2.5rem", marginTop: 2 } : { display: "none" }}
                            onKeyPress={(e) => this.onFilterGlobalChange(e)}
                            onChange={(e) => this.onFilterGlobalChange(e, 'globalSearch')}
                            // value={this.state.selectFilterValue}
                            placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                            size={(this.props.type === "Company" || this.props.type === "Investor" || this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View") ? "25" : "20"} />

                        </span>
                        {/* {this.props.type === "Investor" || this.props.type === "Company" ?
                        <Button color="primary"
                          // size="sm"
                          // className='p-1 mt-1'
                          outlines
                          className="mb-0 ml-1 mr-0"
                          onClick={() => this.onFilterChange(this.state.selectFilterValue)}>Search
                        </Button>
                        : null} */}
                      </span> : null}
                      {this.props.isEdit && !this.state.isPreviousWeek && this.props.type !== "ClaimsManagement" || this.props.type === "Recovery Planner V2" ? this.getSubmitBtnforEditedTable() : null}

                    </div>
                  </>
                </span>

                {this.props.type === "Activity Details Reports" &&
                  <div
                    className="p-inputgroup searchField ml-auto mt-1" style={{ display: "grid", width: 200 }}
                  >
                    <MultiSelect
                      className={"hideheader"}
                      style={{ width: "100%", height: 36 }}
                      appendTo={document.body}
                      placeholder={"Team"}
                      options={this.state.taskDepartmentItems}
                      value={this.state.selectedDepartment}
                      onChange={(e) => this.onChangeDepartmentForReports(e, 'department', 'eq')} />
                  </div>

                }
                {this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap" ?
                  this.setOverlayPanelDescription()


                  : null}
                {this.props.type === "Resource Needed" &&
                  <div
                    className="p-inputgroup searchField ml-auto mt-1" style={{ display: "grid", width: 200 }}
                  >
                    <MultiSelect
                      className={"hideheader"}
                      style={{ width: "100%", height: 36 }}
                      appendTo={document.body}
                      placeholder={"Team"}
                      options={this.state.taskDepartmentItems}
                      value={this.state.resourceNeededDepartment}
                      onChange={(e) => this.onChangeDepartmentForReports(e, 'department', 'eq')} />
                  </div>

                }


              </span>
            </div>
          </div>
          {this.props.type === "Manually Loaded Properties" ? this.getManuallyLoadedProperties() : null}
          {
            this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Ready To Review" || this.props.type === "Seller Negative Notice Viewer" || (this.props && this.props.gettaskFilters) ? this.props.gettaskFilters(handleSubmit)
              : this.props.type === "RFI View" ? this.props.gettaskFilters(handleSubmit) :
                this.props.type === "UP Opportunity" ? this.getpropertySummaryFilters()
                  : this.props.type === "Required Evidence" ? this.getRequiredEvidenceFilters()
                    : this.props.type === "HighValueProperties" ? this.HighValuePropertyFilters()
                      : this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" ||
                        this.props.type === "CA-N Properties" ? this.getAvailableFileFilters() :
                        this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" ? this.getAvailableFileFiltersConfirmOnSite() : null
          }
          {this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Claims" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "ClaimsManagement" || this.props.type === "Deal Sizing Report For Private Equity Portfolio" || this.props.type === "Up Prop Confirmation" || this.props.type === "SearchTerms" || this.props.type === "Site Confirmation Editor" ? this.getpropertyFilters() : null}
          {this.props.type === "Deal Sizing Property Editor" ? this.getDealSizingpropertyFilters() : null}
          {this.props.type === "State Attributes" ? this.getStateUpFiling() : null}
          {this.props.type === "searchTermOnSite" ? this.searchTermOnSite() : null}
          {this.props.type === "ClaimSummary" ? this.getClaimSummary() : null}
          {/* {this.props.type === "Claims" ? this.getClaimField() : null} */}
          {/* {this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties" ? this.getFilerField() : null} */}
          {
            this.state.uppropConfirmationMessage ? <div className='my-5' ><p><h5 style={{ color: "green" }}>{"Results:"}</h5></p><p><h5 style={{ color: "green" }}>{` Out of ${this.state.upconfirmProperties.totalPropertyCount} properties, ${this.state.upconfirmProperties.updated} were updated as Confirmed Onsite.`}</h5></p>
              {/* <p><h5 style={{ color: "green" }}>{`${this.state.upconfirmProperties.alreadyValidated} Properties were already Validated/Confirmed  in the OPRA2.`}</h5></p> */}
              <p><h5 style={{ color: "green" }}>{`${this.state.upconfirmProperties.notUpdated} Properties were NOT found in the OPRA, below are those properties:`}</h5></p><p></p><p><h5 style={{ color: "green" }}>{"Please load these properties in OPRA manually."}</h5></p></div> : null
          }
          {
            this.state.uppropPropertyIds && this.state.uppropPropertyIds.length > 0 ?
              <Row className='ml-0 mr-0 d-flex justify-content-end mb-2' style={{ width: "863px" }} >
                <label>Copy All</label>
                <FontAwesomeIcon icon={faCopy}
                  className='ml-2'
                  color='grey'
                  data-toggle="tool-tip"
                  // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                  onClick={() => { this.copyToClipboardProperties() }}
                  title={"Copy All"}
                  style={{ width: 21, marginTop: "3px" }}
                />
              </Row>
              : null
          }
          {/* {this.state.uppropPropertyIds ?
            // this.state.uppropPropertyIds.map(element => {
            //   // return <h4 style={{ color: "green" }}>{element}</h4>
            // })
            //  return (
            <div className="App">
              <table>
                <tr>
                  <th>Properties Inserted In Opra</th>
                  <th>Property Id</th>
                  <th>Copy</th>
                </tr>
                {this.state.uppropPropertyIds.map((element, index) => {
                  return (
                    <tr key={element}>
                      <td>{<Checkbox
                        label='check'
                        type='checkbox'
                        // component={renderCheckBoxField}
                        name={'validated'}
                        className=" ml-2"
                        checked={this.state[`element${index}`]}
                        onChange={(e) => this.setState({ [`element${index}`]: this.state[`element${index}`] ? false : true })}
                      // onChange={(e) => this.setState(e, "isexcludeValidated")}
                      />}</td>
                      <td>{element}</td>
                      <td>{<FontAwesomeIcon icon={faCopy}
                        className='ml-2'
                        color='grey'
                        data-toggle="tool-tip"
                        // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                        onClick={() => { this.copyToClipboardIndividual(element) }}
                        title={"Copy"}
                        style={{ width: 18 }}
                      />}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
            // );
            : null} */}
          {
            this.state.uppropPropertyIds && this.state.uppropPropertyIds.length > 0 ?
              <div className="disabledSelection">
                <DataTable value={this.state.uppropPropertyIds} style={{ width: "873px" }}
                  selection={this.state.selectedProperyId} onSelectionChange={e => this.setState({ selectedProperyId: e.value })}

                >
                  <Column header="Property Inserted In OPRA" selectionMode="multiple" style={{ textAlign: 'center', width: "109px" }} />

                  <Column header="Property Id" body={this.PropertyIdDisplay} style={{ textAlign: 'center' }} />
                  <Column header="Copy" body={this.copyTemplate} style={{ textAlign: 'center', width: "109px" }} />

                </DataTable>
              </div> : null
          }


          {this.props.type === "Properties" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "ClaimsManagement" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Deal Sizing Report For Companies" ? this.getFilerField() : null}
          {this.props.type === "ClaimSummary" ? this.getClaimSummaryField() : null}

          {
            this.props.type === "Deal Sizing Report For Private Equity Portfolio" || this.props.type === "Weekly Recovery Plan" || this.props.type === "Recovery Planner V2" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? <div className='row' style={this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" || this.props.type === "Recovery Planner V2" ? { justifyContent: "space-between", height: 70, marginRight: 10 } : { justifyContent: "space-between" }}>

              {this.props.type !== "Deal Sizing Report For Private Equity Portfolio" ?
                <div style={{ paddingLeft: 16 }}>
                  <span className='mr-2'>
                    <FontAwesomeIcon
                      color={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report") && this.state.disableIcon ? 'lightgrey' : 'blue'}
                      icon={faArrowCircleLeft}
                      style={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report") && this.state.disableIcon ? { pointerEvents: "none" } : { cursor: "pointer" }}
                      data-toggle="tool-tip"
                      onClick={() => this.getPreviousMonday(this.state.startDateReports)}
                    />
                  </span>
                  <u style={{ fontSize: 14 }}>{this.state.startDateReports} - {this.state.lastDateReports}</u>
                  <span className='ml-2'>
                    <FontAwesomeIcon
                      color={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report") && this.state.disableIcon ? 'lightgrey' : 'blue'}
                      // color={"blue"}
                      icon={faArrowCircleRight}
                      style={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Plan") && this.state.disableIcon ? { pointerEvents: "none" } : { cursor: "pointer" }}
                      data-toggle="tool-tip"
                      onClick={() => this.getNextMondayasync(this.state.startDateReports)}
                    />
                  </span>
                </div>
                : null}
              {!this.state.isOpenFormModal && this.props.type === "Utilization - US Team" ?
                <div className='topbar__center '>
                  {this.getUtilizationFilters()}
                </div>
                : null}
              {this.props.type !== "Deal Sizing Report For Private Equity Portfolio" && this.props.type !== "Recovery Planner V2" && this.props.type !== "Utilization - Integrity" ?
                <div
                  className="p-inputgroup searchField ml-auto mt-1 mr-2" style={{ display: "grid", width: 200 }}
                >
                  <h5 className="mr-2 mt-2">Team</h5>
                  <MultiSelect
                    className={"hideheader"}
                    style={{ width: "100%", height: 36 }}
                    appendTo={document.body}
                    placeholder={"Team"}
                    options={this.state.taskDepartmentItems}
                    value={this.state.selectedDepartment}
                    onChange={(e) => this.onChangeDepartmentForReports(e, 'department', 'eq')} />
                </div> : null

              }
              {this.props.type !== "Deal Sizing Report For Private Equity Portfolio" && this.props.type !== "Weekly Recovery Plan" && this.props.type !== "Recovery Planner V2" && this.props.type !== "Weekly Recovery Report" ?
                <div className='' style={{ display: "grid" }}>
                  <span className="pr-3" style={{ paddingRight: "25", fontSize: 14 }}>(Time Spent in <b>hours</b>) Company</span>
                  <p style={{ textAlign: "center", fontSize: 14 }}>  <span style={{ color: "brown" }}>BK</span>  <span>CR</span></p>
                </div>
                : null}


              {this.props.type === "Weekly Recovery Report" ?
                <div style={{ display: "contents" }}>
                  <div
                    className="p-inputgroup searchField ml-auto" style={{ display: "grid", width: 200 }}
                  >
                    <span>Sort By</span>
                    <MultiSelect
                      className={"hideheader"}
                      style={{ width: "100%", height: 32 }}
                      appendTo={document.body}
                      value={this.state.propertyStatus}
                      maxSelectedLabels={1}
                      disabled={this.state.allUsersData.length === 0 ? true : false}
                      options={this.state.FilterSortOptions}
                      onChange={(e) => this.onpropertiessortBy(e.value)} />
                  </div>
                  <div className="mb-0 ml-2" >
                    {/* <span style={{ color: "white" }}>Group By</span> */}
                    <Button color="primary" outline
                      className="mb-0" style={{ cursor: "pointer", marginTop: 21 }}
                      onClick={() => this.submitSort()}
                    >
                      Go
                    </Button>
                  </div></div> : this.props.type === "Recovery Planner V2" ?
                  <div
                    className="p-inputgroup searchField ml-auto" style={{ display: "grid", width: 200 }}
                  >
                    <span>Team : </span>
                    {/* <MultiSelect
                      className={"hideheader"}
                      style={{ width: "100%", height: 32 }}
                      appendTo={document.body}
                      options={this.state.taskDepartmentItems}
                      value={this.state.selectedDepartment}
                      onChange={(e) => this.onChangeDepartment(e, 'department', 'eq')} /> */}
                    <MultiSelect
                      className={"hideheader"}
                      style={{ width: "100%", height: 36 }}
                      appendTo={document.body}
                      placeholder={"Team"}
                      options={this.state.taskDepartmentItems}
                      value={this.state.Department}
                      onChange={(e) => this.onChangeDepartmentForReports(e, 'department', 'eq')} />
                  </div> : null}
            </div> : null
          }
          {
            this.props.statusFilter &&
              this.props.type !== "Users" && this.props.type !== "Site Confirmation Editor" ?
              <div className='d-flex'>
                <SelectButton className='mb-1' value={this.state.selectedvalueforStatus} options={this.props.SelectedForOptions ? this.props.SelectedForOptions : SelectedForOptions} onChange={(e) => this.selectedOptionData(e.value, "status")} />

              </div> : null
          }
          {
            this.props.type === "Site Confirmation Editor" ? <>
              <label><b>Pending Confirmation: </b></label>
              <Dropdown
                value={this.state.isPendingConfirmation}
                onKeyPress={(e) => this.getFilteredReportsselectedKey(e, 'Seller')}
                appendTo={document.body}
                options={[{ label: "Confirmed", value: "Confirmed" }, { label: "Missing", value: "Missing" }]}
                onChange={(e) => this.setState({ isPendingConfirmation: e.value })}
                style={{ width: 200, height: 35, marginTop: 5, marginLeft: 5 }}
              /></>
              : null
          }
          {this.props.type === "OpenCorporateBranchBatch" && !this.state.isOpenFormModal && <Button
            color="secondary"
            size="sm"
            // className="p-1 ml-auto"
            className={'p-1 ml-auto mt-1 mb-0 d-flex'}
            onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
            <FontAwesomeIcon
              icon='sync-alt'
              size='lg'
              data-toggle="tool-tip" title={t("Refresh")}
              color={config.templateColor}
              className='refreshIcon pl-1' />
          </Button>}
        </div >

      </div >

    );
  }

  getCardHeader() {
    return (
      // <CardHeader className='cardHeader'>
      <div className="row m-0">
        {/* table name */}
        <div className='col-3 pl-0 pt-2'>
          <h4><b>{this.props.type}{this.props.type === 'Activiti' ? 'es' : ''}</b></h4>
        </div>
        <span className='col-9 text-right'>
          <Row>
            {/* Export to csv */}
            {/* <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={this.getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title="Export To CSV"
                      className='pl-1' size='lg' />
                  </Button>
                  <CSVLink
                    data={this.state.exportData}
                    filename={`${this.props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => this.csvLinkRef = r}
                    target="_blank" >
                  </CSVLink> */}
            {/* {this.props.sample ?
              <Button color="primary"
                size="sm"
                className="p-1"
                onClick={this.bulkUploadMoadal}>
                <FontAwesomeIcon
                  icon='upload'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Bulk Upload"
                  onClick={this.bulkUploadMoadal} />
              </Button>
              : null} */}
            {/* Add Settings Button */}
            {this.props.settingsRequired ?
              <Button color="primary"
                size="sm"
                className="p-1 ml-auto mt-1"
                onClick={() => this.openShowHideColumnsModal()}
              >
                <FontAwesomeIcon
                  icon='cog'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Settings"
                  onClick={() => this.openShowHideColumnsModal()}
                />
              </Button>
              : null}
            {/* Add button */}
            {this.props.addRequried && this.state.showAddButton ?
              <Button color="primary"
                size="sm"
                className="p-1 ml-auto"
                onClick={() => this.openFormModal('add')}>
                <FontAwesomeIcon
                  icon='plus'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Add"
                  onClick={() => this.openFormModal('add')}
                />
              </Button>
              : null}
            {/* priority  in Issues*/}
            <Button
              outline
              color="secondary"
              size="sm"
              // className="p-1 ml-auto"
              className={this.props.addRequried ? "p-1" : 'p-1 ml-auto'}
              onClick={() => this.getDataFromServer(this.state.filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title="Refresh"
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button>
          </Row>
        </span>

      </div>
      // </CardHeader>
    )
  }

  cancelUserPwdResetModal = async () => {
    await this.setState({
      openUserPasswordResetModal: false,
      actions: ''
    })
  }

  rowEditorValidator(rowData) {

  }

  openAdUnitsCollapse(rowData) {
    let body = rowData.data
    if (this.props.type === "ClaimsManagement") {
      return null
    } else {
      fetchMethodRequest('POST', 'NsScoredProperties/dealSizingstats', body)
        .then(async (response) => {
          if (response && response.stats) {
            let allUsersData = this.state.allUsersData
            allUsersData[rowData.data.Sno - 1].Stats = response.stats
            await this.setState({
              allUsersData: allUsersData,
              SnoData: rowData.data.Sno
            })
          } else if (response && response.errorMessage) {
            this.getErrorMessage(response.errorMessage, "", "error")
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  getFormattedCurrencyValue = () => {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: config.currency,
    });
    return dollarUS
  }


  //getRow Expand collapse for Deal Sizing in Sellers
  rowExpansionTemplate = (data) => {
    let dollarUS = this.getFormattedCurrencyValue()
    return (
      data && data.Stats && data.Stats.Green ?
        <div style={{ padding: '1em 1em 1em 1em' }}>
          <table className='' style={{ marginLeft: "5%", width: 800 }}>
            <thead>
              <tr >
                <th style={{ color: "green" }}>Green ({data.Stats.Green.count})</th>
                <th style={{ color: "blue" }}>Blue({data.Stats.Blue.count})</th>
                <th style={{ color: "#ff9700" }}>Yellow({data.Stats.Yellow.count})</th>
                <th style={{ color: "brown" }}>Brown({data.Stats.Brown.count})</th>
                <th style={{ color: "black" }}>Black({data.Stats.Black.count})</th>
                <th style={{ color: "#008080" }}>Teal({data.Stats.Teal.count})</th>
              </tr></thead><tbody>
              <tr >
                <td>{dollarUS.format(Math.round(data.Stats.Green.total))}</td>
                <td>{dollarUS.format(Math.round(data.Stats.Blue.total))}</td>
                <td>{dollarUS.format(Math.round(data.Stats.Yellow.total))}</td>
                <td>{dollarUS.format(Math.round(data.Stats.Brown.total))}</td>
                <td>{dollarUS.format(Math.round(data.Stats.Black.total))}</td>
                <td>{dollarUS.format(Math.round(data.Stats.Teal.total))}</td>
              </tr></tbody>
          </table>
        </div>
        :
        <div className="p-md-10 ml-4" style={{ fontWeight: 'bold' }}>No Records are found</div>

    );
  }

  handleFormattingData(allUsersData) {
    for (var record of allUsersData) {
      if (record.analysts && typeof record.analysts === "object" && record.analysts[0] === undefined) {
        let analystArr = []
        analystArr.push(record.analysts)
        record.analysts = analystArr
      } if (record.validationTarget) {
        record.validationTarget = parseFloat(record.validationTarget)
      }
      if (record.fileTarget) {
        record.fileTarget = parseFloat(record.fileTarget)
      } if (record.transitionTarget) {
        record.transitionTarget = parseFloat(record.transitionTarget)
      } if (record.searchTarget) {
        record.searchTarget = parseFloat(record.searchTarget)
      } if (record.forecastSearchHours) {
        record.forecastSearchHours = parseFloat(record.forecastSearchHours)
      } if (record.forecastValidationHours) {
        record.forecastValidationHours = parseFloat(record.forecastValidationHours)
      } if (record.forecastFileHours) {
        record.forecastFileHours = parseFloat(record.forecastFileHours)
      } if (record.forecastTransitionHours) {
        record.forecastTransitionHours = parseFloat(record.forecastTransitionHours)
      }
    }
    return allUsersData;
  }

  handleCheckValidations = async () => {
    var allUsersData = this.state.allUsersData
    if (this.props.type === "Weekly Recovery Plan") {
      allUsersData = await this.handleFormattingData(allUsersData)
      //for changing dataType
      if (window.location.pathname === "/nonSiteDiscoveryPlan") {
        var newArray = allUsersData.filter(function (el) {
          return el.dealType === "" ||
            el.company === "" ||
            // (el.dealType === "CR - New" || el.dealType === "CR - Refresh") && el.crRound === "" ||
            el.analysts === [] ||
            el.validationTarget === "" ||
            // el.searchTarget === "" ||
            el.fileTarget === "" ||
            el.transitionTarget === "" ||
            // el.forecastSearchHours === "" ||
            el.forecastTransitionHours === "" ||
            el.forecastValidationHours === "" ||
            el.forecastFileHours === ""
        });
        var targetLimit = allUsersData.filter(function (el) {
          return el.validationTarget > 100 ||
            // el.searchTarget > 100 ||
            el.fileTarget > 100 ||
            el.transitionTarget > 100
        });
      } else {
        var newArray = allUsersData.filter(function (el) {
          return el.dealType === "" ||
            el.company === "" ||
            el.analysts === [] ||
            // (el.dealType === "CR - New" || el.dealType === "CR - Refresh") && el.crRound === "" ||
            // el.validationTarget === "" ||
            el.searchTarget === "" ||
            // el.fileTarget === "" ||
            // el.transitionTarget === "" ||
            el.forecastSearchHours === ""
          // el.forecastTransitionHours === "" ||
          // el.forecastValidationHours === "" ||
          // el.forecastFileHours === ""
        });
        var targetLimit = allUsersData.filter(function (el) {
          return el.searchTarget > 100
          // el.validationTarget > 100 ||

          // el.fileTarget > 100 ||
          // el.transitionTarget > 100
        });
      }
      var usersDataArr = [];
      allUsersData.filter(function (el) {
        if (usersDataArr && usersDataArr.length !== 0) {
          let index = usersDataArr.findIndex(filterarr => filterarr === el.analysts[0].userName)
          if (index === -1) {
            usersDataArr.push(el.analysts[0].userName)
          };
        } else {
          usersDataArr.push(el.analysts[0].userName)
        }
      })
      var totalUserHours = {}
      for (var user of usersDataArr) {
        var userforecasrHours;
        allUsersData.filter(function (el) {
          if (user === el.analysts[0].userName) {
            userforecasrHours = el.forecastSearchHours + el.forecastValidationHours + el.forecastFileHours + el.forecastTransitionHours
            if (totalUserHours[user]) {
              totalUserHours[user] = totalUserHours[user] + userforecasrHours
            } else {
              totalUserHours[user] = userforecasrHours
            }
          }
        });
      }
      let userNameArr = [];
      for (var obj in totalUserHours) {
        if (totalUserHours[obj] > 40 || totalUserHours[obj] < 32)
          userNameArr.push(obj + "  ")
        // userNameArr.push(" ")
      }
      if (newArray && newArray.length === 0 && targetLimit && targetLimit.length === 0) {
        if (userNameArr && userNameArr.length > 0) {
          this.setState({
            softMessage: `${userNameArr} has total forecasted hours of less than 32 hrs Or  more than 40 hrs`,
            softWarning: true
          })
        }

        this.sendmultiRecords(allUsersData)
      } else {
        await this.setState({
          isSubmitted: true
        })
      }
    }
    else if (this.props.type === "Recovery Planner V2") {
      allUsersData = allUsersData.filter(function (el) {
        return el.edited === "yes"
      });
      for (var obj of allUsersData) {
        obj.assignToId = typeof obj.assignTo === "string" ? obj.assignToId : obj.assignTo.userId
        obj.assignTo = typeof obj.assignTo === "string" ? obj.assignTo : obj.assignTo.userName
      }
      this.sendmultiRecords(allUsersData)
    }
    else {
      var datatoFormat = allUsersData
      if (this.props.isOnlyEditted) {//only for edited records
        allUsersData = allUsersData.filter(function (el) {
          return el.edited === "yes"
        });
      }
      if (this.props.onlyIdswithchangedValues) {//only for edited values with id
        if (this.state.selectedRows && this.state.selectedRows.length > 0) {
          allUsersData = this.state.selectedRows
          // let selectedRows = this.state.selectedRows
          // for (var item of this.state.allUsersData) {
          //   let index = selectedRows.findIndex(obj => obj._id === item._id)
          //   if (index !== -1) {
          //     item.edited = "yes"
          //     item.pendingConfirmation = this.state.isPendingConfirmation
          //   }
          // }
        }
        let data = []
        for (var obj of allUsersData) {
          data.push({ id: obj._id, pendingConfirmation: this.state.selectedRows && this.state.selectedRows.length > 0 ? this.state.isPendingConfirmation : obj.pendingConfirmation })
        }

        allUsersData = data
      }
      allUsersData = this.props.handleFieldValues ? await this.props.handleFieldValues(allUsersData) : allUsersData
      this.sendmultiRecords(allUsersData)
    }
  }
  sendStateMultiRecords = async () => {
    let allUserData = this.state.allUsersData
    let OverAllStates = {}
    OverAllStates.models = []
    for (let allUser of allUserData) {
      if (allUser.edited === "yes") {
        if (allUser.effectiveFromDate) {
          allUser.effectiveFromDate = new Date(allUser.effectiveFromDate.setTime(allUser.effectiveFromDate.getTime() - (allUser.effectiveFromDate.getTimezoneOffset() * 60 * 1000)))
        }
        OverAllStates.models.push(allUser)
      }
    }
    let userBody, Method
    userBody = OverAllStates
    Method = "PUT"
    this.setState({
      isLoading: true, reasonByRow: false

    })
    return fetchMethodRequest(Method, `${this.props.multiApi}`, userBody)
      .then(async (response) => {
        if (response && response.respMessage && response.respMessage) {
          this.getErrorMessage(response.respMessage, "", "success")
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage, "", "error")
        }

        // await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
        this.CancelMultiLineStates("isCancel")
        await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
        this.CancelMultiLineStates("isCancel")
        this.setState({
          isLoading: false, reasonByRow: false

        })
      }).catch((err) => {
        return err;
      });
  }

  sendmultiRecords = async (allUsersData) => {
    let userBody, multiMethod
    if (this.props.type === "ClaimsManagement") {
      userBody = allUsersData
      multiMethod = "PUT"
    } else {
      multiMethod = this.props.multiMethod
      let multiApiKey = this.props.multiApiKey
      userBody = {}
      userBody[multiApiKey] = allUsersData
    }
    this.setState({
      isLoading: true
    })

    return fetchMethodRequest(multiMethod, `${this.props.multiApi}`, userBody)
      .then(async (response) => {
        if (response && response.respMessage && response.respMessage) {
          this.getErrorMessage(response.respMessage, "", "success")
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage, "", "error")
        }
        await this.getDataFromServer(this.state.filterCriteria)
        this.CancelMultiLine()

        this.setState({
          isLoading: false, reasonByRow: false

        })
      }).catch((err) => {
        return err;
      });

  }

  CancelMultiLine = (type) => {
    let k = document.getElementsByClassName("p-row-editor-cancel-icon").length
    let element = document.getElementsByClassName("p-row-editor-cancel-icon")
    if (k > 0) {
      for (let i = 0; i <= k; i++) {
        if (element[i]) {
          element[i].click()
        }
        if (element[0]) {
          element[0].click()
        }
      }
    }
    localStorage.removeItem("ISEdited")
    if (type) {
      this.getDataFromServer(this.state.filterCriteria)
    }
  }
  CancelMultiLineStates = (type) => {
    let k = document.getElementsByClassName("p-row-editor-cancel-icon").length
    let element = document.getElementsByClassName("p-row-editor-cancel-icon")
    if (k > 0) {
      for (let i = 0; i <= k; i++) {
        if (element[i]) {
          element[i].click()
        }
        if (element[0]) {
          element[0].click()
        }
      }
    }
    if (type) {
      this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
    }
  }


  getisLoading = async (type) => {
    await this.setState({
      isLoading: type === "false" ? false : true,
      progress: 0
    })
    await this.handlePercentage("1")
  }

  getSubmitBtnforEditedTable() {
    return (
      <div>

        <div className='topBarImageAlignment'>
          <Button color="primary" outline
            className='p-1 mx-1 mb-0'
            size={'sm'}
            style={{ height: 37 }}
            onClick={() => this.handleCheckValidations()}>
            <FontAwesomeIcon
              icon={faSave}
              data-toggle="tool-tip" title={"Save"}
              className='pl-1' size='lg' />Save
          </Button>
          <Button color="danger" outline
            className='p-1 mx-1 mb-0'
            size={'sm'}
            style={{ height: 37 }}
            onClick={() => this.CancelMultiLine('isCancel')}>
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              data-toggle="tool-tip" title={"Cancel"}
              className='pl-1' size='lg' />Cancel
          </Button>
        </div></div >
    )

  }
  addRowForStateAttributes() {
    let data

    data = {
      "category": this.state.stateCategory ? this.state.stateCategory : "",
      "subCategory": this.state.stateSubCategory ? this.state.stateSubCategory : "",
      activeIndicator: "",
      note: "",
      "effectiveFromDate": "",
      state: this.state.UpState,
      dealType: this.state.stateDealType

    }

    let allUsersData = this.state.allUsersData
    allUsersData.push(data)
    this.setState({
      allUsersData: JSON.parse(JSON.stringify(allUsersData)),
      addRow: true,
      isSubmitted: false,
    })
    let k = document.getElementsByClassName("p-row-editor-init-icon").length
    setTimeout(() => {
      if (document.getElementsByClassName("p-row-editor-init-icon")[k])
        document.getElementsByClassName("p-row-editor-init-icon")[k].click();
    }, 30)
  }

  addRow() {
    let data
    if (window.location.pathname === "/nonSiteDiscoveryPlan") {
      data = {
        "dealType": "",
        "company": "",
        "analysts": [],
        "validationTarget": "",
        // "reviewTarget": "",
        "fileTarget": "",
        "transitionTarget": "",
        "searchTarget": 0,
        "crRound": "",
        "forecastSearchHours": 0,
        "forecastValidationHours": "",
        // "forecastReviewHours": "",
        "forecastFileHours": "",
        "forecastTransitionHours": "",
        // "transitionHours": "",
        "comments": "",
        "startDate": moment(this.state.startDateReports).format(config.dbOnlyDateFormat),
        "stopDate": moment(this.state.lastDateReports).format(config.dbOnlyDateFormat),
      }
    } else {
      data = {
        "dealType": "",
        "company": "",
        "analysts": [],
        "crRound": "",
        "validationTarget": 0,
        // "reviewTarget": "",
        "fileTarget": 0,
        "transitionTarget": 0,
        "searchTarget": "",
        "forecastSearchHours": "",
        "forecastValidationHours": 0,
        // "forecastReviewHours": "",
        "forecastFileHours": 0,
        "forecastTransitionHours": 0,
        "transitionHours": 0,
        "comments": "",
        "startDate": moment(this.state.startDateReports).format(config.dbOnlyDateFormat),
        "stopDate": moment(this.state.lastDateReports).format(config.dbOnlyDateFormat),
      }
    }
    let allUsersData = this.state.allUsersData
    allUsersData.push(data)
    this.setState({
      allUsersData: allUsersData,
      addRow: true,
      isSubmitted: false,
    })
    let k = document.getElementsByClassName("p-row-editor-init-icon").length
    setTimeout(() => {
      if (document.getElementsByClassName("p-row-editor-init-icon")[k])
        document.getElementsByClassName("p-row-editor-init-icon")[k].click();
    }, 30)
  }

  //when the row is saved
  onRowEditSave = async (e) => {

    this.handleCheckValidations()
  }

  onRowEditCancel = async (e) => {
    let allUsersData = this.state.allUsersData
    if (!e.data._id || e.data._id === undefined) {
      allUsersData.splice(e.index, 1)
      let k = document.getElementsByClassName("p-row-editor-init-icon").length
      setTimeout(() => {
        if (document.getElementsByClassName("p-row-editor-init-icon")[k]) {
          document.getElementsByClassName("p-row-editor-init-icon")[k].click();
        }
      }, 100)
    } else if (e.data._id && e.data._id.length > 0) {
      let responsesaveData = JSON.parse(JSON.stringify(this.state.responsesaveData))
      allUsersData[e.index] = responsesaveData[e.index]
    }
    await this.setState({
      allUsersData: allUsersData
    })
  }

  getValues(props) {
    let data = []
    if (props && props.rowData && props.rowData["analysts"]) {
      for (var analyst of props.rowData["analysts"]) {
        data.push(analyst)
      }
    }
    return data;
  }
  setClaimReason = async (value, allUsersData, index) => {
    let ReasonsArray = [];
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'Status', value: value, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
    return fetchMethodRequest('GET', `${apiCalls.ClaimReasons}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["claimReasons"].length > 0) {
          let claimReasons = response["claimReasons"]
          for (let reason of claimReasons) {
            ReasonsArray.push({ label: reason.reason, value: reason.reason })
          }
          allUsersData[index]["filterClaimReason"] = ReasonsArray
          for (let allUserData of allUsersData) {
            allUserData.filterClaimReason = allUserData.filterClaimReason ? allUserData.filterClaimReason : allUserData.claimReasonsRow
          }
          await this.setState({ filterClaimReason: ReasonsArray, reasonByRow: true, allUsersData: allUsersData })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  onEditorValueChange = async (props, value, item) => {

    if (props) {
      let allUsersData = [...props.value];
      if (props.field === "company" && typeof value === "object") {
        value = value.estateName
      }
      allUsersData[props.rowIndex]["edited"] = "yes"
      localStorage.setItem("ISEdited", "true")
      if (item && item.field && item.field !== "comments" && value) {
        if (item.keyfilter === "pint") {
          allUsersData[props.rowIndex][props.field] = parseInt(value ? value : 0)
        } else {
          allUsersData[props.rowIndex][props.field] = value ? value : ""
        }
      } else {
        if (props.field === "dealType" && (value === "BK - Refresh" || value === "BK - New")) {
          allUsersData[props.rowIndex]["crRound"] = ""
        }
        allUsersData[props.rowIndex][props.field] = value ? value : ""
      }
      if (value && !props.field.includes("Target")) {
        await this.setState({ allUsersData: allUsersData, showerrorfield: false });
      } else if (value && props.field.includes("Target") && value <= "100") {
        await this.setState({ allUsersData: allUsersData, showerrorfield: false });
      } else {
        await this.setState({
          validfield: item ? item.field : "",
          showerrorfield: true
        })
      }
      if (props.field === "ClaimStatus" && this.props.type === "ClaimsManagement") {
        this.setClaimReason(value, this.state.allUsersData, props.rowIndex)
        // await this.setState({ filteredAnalysts: [{ label: "yes", value: "no" }] })
      }
    }
  }

  textEditor(options, item) {
    return (
      <div >
        <div>
          <InputText
            type="text"
            name={options.rowData[item.field]}
            value={options.rowData[item.field]}
            onChange={(e) => this.onEditorValueChange(options, e.target.value, item)}
            // placeholder={item.header}
            keyfilter={item.keyfilter ? item.keyfilter : null}
          // validateOnly={true}
          />
        </div>
        {
          ((this.state.isSubmitted && item.field !== "comments" && options.rowData[item.field] === "") || (this.state.showerrorfield && item.field !== "comments" && item.keyfilter && options.rowData[item.field] > 100)) ?
            <div className="mb-1" style={{ height: '10px' }}>
              {<span className="form__form-group-error">{item.keyfilter && item.field.includes("Target") ? "Pls enter 0 to 100" : "Pls fill the field"}</span>}
            </div>
            : null
        }
      </div >
    )
  }

  textAreaEditor(options, item) {
    return (
      <div >
        <div>
          <InputTextarea
            type="textArea"
            name={options.rowData[item.field]}
            value={options.rowData[item.field]}
            onChange={(e) => this.onEditorValueChange(options, e.target.value, item)}
            // placeholder={item.header}
            keyfilter={item.keyfilter ? item.keyfilter : null}
          // validateOnly={true}
          />
        </div>
        {
          ((this.state.isSubmitted && item.field !== "comments" && options.rowData[item.field] === "") || (this.state.showerrorfield && item.field !== "comments" && item.keyfilter && options.rowData[item.field] > 100)) ?
            <div className="mb-1" style={{ height: '10px' }}>
              {<span className="form__form-group-error">{item.keyfilter && item.field.includes("Target") ? "Pls enter 0 to 100" : "Pls fill the field"}</span>}
            </div>
            : null
        }
      </div >
    )
  }

  multiSelectEditor(props) {
    let analysts = this.getValues(props)
    return (
      <div >
        <div style={{ height: 30 }}>
          <MultiSelect
            value={analysts}
            appendTo={document.body}
            options={this.state.filteredAnalysts}
            onChange={(e) => this.onEditorValueChange(props, e.value)}

          />
        </div>
        {
          this.state.isSubmitted && analysts.length === 0 ?
            <div className="mb-1" style={{ height: '10px' }}>
              {<span className="form__form-group-error">{"Pls fill the field"}</span>}
            </div>
            : null
        }
      </div>
    );
  }

  dropDownEditor(props, item) {
    let val;
    if (item.field === "analysts" && props.rowData[item.field][0]) {
      val = props.rowData[item.field][0]
    } if (item.field === "assignTo" && typeof props.rowData[item.field] === "string") {
      val = { userName: props.rowData[item.field], userId: props.rowData["assignToId"] }
    }
    return (
      <div >
        <Dropdown
          value={val ? val : props.rowData[item.field]}
          appendTo={document.body}
          options={item.field === "analysts" || item.field === "assignTo" || (item.field === "CreatedBy" && this.props.type === "ClaimsManagement") ? this.state.filteredAnalysts : item.field === "ClaimStatus" && this.state.reasonByRow ? this.state.claimStatusArray : item.field === "ClaimReason" && this.state.reasonByRow ? props.rowData["filterClaimReason"] : item.field === "ClaimReason" ? props.rowData["claimReasonsRow"] : (this.props.type === "State Attributes" && item.field === "subCategory") ? this.state.stateSubCategories : item.options ? item.options : []}
          // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
          onChange={(e) => this.onEditorValueChange(props, e.value)}
          // style={{ width: 150 }}
          filter={true}
          disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


        // placeholder={item.header}
        />
        {/* <Select /> */}
        <div>
          {this.props.type === "ClaimsManagement" && this.state.isSubmitted && item.field !== "Analyst" && props.rowData[item.field] === null ?
            < div className="mb-1" style={{ height: '10px' }}>
              {<span className="form__form-group-error">{"Please fill the field"}</span>}
            </div>
            :
            this.props.type !== "ClaimsManagement" && this.state.isSubmitted && (((item.field !== "crRound") && (props.rowData[item.field] === null || (props.rowData[item.field].length === 0 || item.field === "analysts" && props.rowData[item.field] && props.rowData[item.field][0] && props.rowData[item.field][0].length === 0))))
              ?
              < div className="mb-1" style={{ height: '10px' }}>
                {<span className="form__form-group-error">{"Please fill the field"}</span>}
              </div>
              : null
          }
        </div>
      </div>)
  }

  // searchItems = async (event) => {
  //   let userBody = {
  //     str: event ? event.query : ""
  //   }
  //   return fetchMethodRequest('POST', "businessnames/estateNames", userBody)
  //     .then(async (response) => {
  //       if (response) {
  //         let dropdownData = [];
  //         if (response["estatesOwnedFrom2021"] && response["estatesOwnedFrom2021"].length && response["estatesOwnedFrom2021"].length > 0) {
  //           dropdownData = response["estatesOwnedFrom2021"]
  //         } else if (response && response.errorMessage) {
  //           this.getErrorResponseFromServer(response)
  //         }
  //         if (dropdownData && dropdownData.length === 0) {
  //           this.setState({
  //             filteredSuggestions: [],
  //             noData: true
  //           });
  //         } else {
  //           dropdownData = dropdownData;
  //         }
  //         await this.setState({
  //           filteredSuggestions: dropdownData
  //         });
  //       }
  //     }).catch((err) => {
  //       return err;
  //     });
  // }
  searchItems = async (event) => {
    let userBody = {
      str: event ? event.query : ""
    }
    let url = `properties/DealsDropdown?str=${event ? event.query : ""}`;
    return fetchMethodRequest('GET', url, userBody)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          } else if (response && response.errorMessage) {
            this.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownData;
          }
          dropdownData = dropdownData.map((e) => { return { ...e, custLabel: `${e.DealType} - ${e.SellerNickName} (${e.DealYear ? e.DealYear : ""})`, estateName: e.EstateName, estateID: e.EstateID } })

          await this.setState({
            filteredSuggestions: dropdownData
          });
        }
      }).catch((err) => {
        return err;
      });
  }
  // getDatePicker(props, item) {
  //   return (
  //     < >
  //       <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
  //         appendTo={document.body}
  //         monthNavigator={true}
  //         yearNavigator={true}
  //         yearRange="1940:2530"
  //         placeholder='mm/dd/yy'
  //         // onChange={(e) => this.setState({ [item.field]: e.value })}
  //         // inputClassName={`p-column-filter p-column-${item.field}`}
  //         name={item.field}
  //         dateFormat="mm/dd/yy"
  //         value={props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] ? new Date(props.value[props.rowIndex][item.field]) : null}
  //         onChange={(e) => this.onEditorValueChange(props, e.value)}
  //       // onSelect={(e) => this.onEditorValueChange(props, e)}
  //       />
  //     </>
  //   )
  // }
  onDateChange = (props, e, type) => {
    if (!type) {
      this.onEditorValueChange(props, e.value)
    } else {
      let allUsersData = [...props.value];
      if (type === "time") {
        allUsersData[props.rowIndex][props.field] = e.format('HH:mm:ss.SS')
      } else {
        allUsersData[props.rowIndex][props.field] = e ? e.value : ""
      }
      // allUsersData[props.rowIndex][props.field] = e ? e.value : ""
      allUsersData[props.rowIndex]["edited"] = "yes"
      localStorage.setItem("ISEdited", "true")
      this.setState({
        allUsersData: allUsersData
      })
    }
  }

  getDatePicker(props, item) {
    return (
      < >
        <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
          appendTo={document.body}
          monthNavigator={true}
          yearNavigator={true}
          yearRange="1940:2530"
          minDate={this.props.type.includes("Scheduler") ? new Date() : ""}
          name={item.field}
          placeholder='mm/dd/yyyy'
          dateFormat="mm/dd/yy"
          value={props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] && (props.value[props.rowIndex][item.field].length >= 10 || props.value[props.rowIndex][item.field].length === undefined) ? new Date(props.value[props.rowIndex][item.field]) : props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] && props.value[props.rowIndex][item.field].length < 11 ? props.value[props.rowIndex][item.field] : null}
          onChange={(e) => this.onDateChange(props, e, 'type')}
          onSelect={(e) => this.onDateChange(props, e)}
        />
      </>
    )
  }


  onChangedata(value, props, item) {

  }
  formatTime(props, item) {
    let d = new Date(); // Creates a Date Object using the clients current time
    let [hours, minutes, seconds] = props.value[props.rowIndex][item.field].split(':')
    d.setHours(+hours); // Set the hours, using implicit type coercion
    d.setMinutes(minutes); // can pass Number or String - doesn't really matter
    d.setSeconds(seconds);
    // If needed, you could also adjust date and time zone
    let rowValue = d.toString()
    let newhours = new Date(rowValue);

    return newhours
  }
  getTimePicker(props, item) {
    const format = 'h:mm a';
    let hours = props.value[props.rowIndex][item.field] ? this.formatTime(props, item).getHours() : null
    let minutes = props.value[props.rowIndex][item.field] ? this.formatTime(props, item).getMinutes() : null
    const now = moment().hour(hours ? hours : 0).minute(minutes ? minutes : 0)
    return (
      < >
        <TimePicker
          showSecond={false}
          name={item.field}
          defaultValue={now}
          className="xxx"
          onChange={(e) => this.onDateChange(props, e, 'time')}
          format={format}
          use12Hours
          inputReadOnly
        />

      </>
    )
  }
  getAutoComplete(props, item) {
    return (
      <div >
        <div className='pl-0'>
          <AutoComplete
            value={props.value[props.rowIndex]["company"]}
            suggestions={this.state.filteredSuggestions}
            completeMethod={this.searchItems}
            minLength={1}
            field="estateName"
            dropdown={true}
            onChange={(e) => this.onEditorValueChange(props, e.value)}
            appendTo={document.body} />
        </div>
        <div>
          {
            this.state.isSubmitted && props.rowData[item.field].length === 0 ?
              <div className="mb-1" style={{ height: '10px' }}>
                {<span className="form__form-group-error">{"Please fill the field"}</span>}
              </div>
              : null
          }
        </div>
      </div >
    )
  }

  getSortedData(field, isAsc) {
    let allUsersData = this.props.getSortedData(this.state.allUsersData, field, isAsc)
    this.setState({
      allUsersData: allUsersData
    })
  }

  removeFrozenRecords = async (e, type) => {
    let columnsData = JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));
    let columns
    if (columnsData && columnsData.details) {
      columns = columnsData.details[this.state.selectedViewType]
    } else {
      columns = columnsData
    }

    for (let i = 0; i < columns.length; i++) {
      columns[i].frozen = false
    }
    await localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columnsData))
    await this.selectedViewType(e, "Frozen")
  }

  handleDataChangingOption = async (e) => {
    if (e && e.selectedOption) {
      if (e.selectedOption === "unsort" || e.selectedOption === "asc" || e.selectedOption === "desc") {
        if (this.props.type && this.props.type !== "UP Opportunity") {
          await this.sortChange(e)
        } else {
          await this.getSortedData(e.sortField, e.selectedOption)
        }
      } else if (e.selectedOption === "hide") {
        await this.columnHandlingfromHeader(e.selectedOption, e.sortField)
      } else if (e.selectedOption === "show") {
        this.openShowHideColumnsModal()
      } else if (e.selectedOption === "filter") {
        await this.handleFilterInputField(e.sortField)
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "clear_filter") {
        this.onColumnResizeEnd()
        await this.handleFilterInputField(e.sortField, "clear")
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "freeze") {
        await this.removeFrozenRecords("freeze", "clear")
        this.onColumnResizeEnd()
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "unfreeze") {
        await this.removeFrozenRecords("unfreeze")
        this.onColumnResizeEnd()
        // this.AddingFilterInput(e.selectedOption, field)
      }
    }
  }
  getFilteredData = async (fieldName) => {
    let ocFilters = this.state.ocFilters, data = this.state.responsesaveData, filteredData = [];
    let index = ocFilters.findIndex(u => u.fieldName == fieldName);
    if (index != -1) {
      ocFilters.splice(index, 1);
    }
    for (let obj in ocFilters) {
      for (let obj1 of data) {
        if (ocFilters[obj].fieldName) {
          if (obj1[ocFilters[obj].fieldName] && obj1[ocFilters[obj].fieldName].toLowerCase().includes(ocFilters[obj].value)) {
            filteredData.push(obj1);
          }
        }
      }
      if (ocFilters.length - 1 != obj) {
        data = [...filteredData];
        filteredData = [];
      }
    }
    return {
      ocFilters, filteredData
    }
  }

  async handleFilterInputField(val, type) {
    let filters = {}, newFil = {}, filteredObj = {};
    if (type) {
      newFil[val] = {
        value: "",
        matchMode: "clear"
      };
      filters[val] = newFil[val]
      filteredObj.filters = filters
      let value = this.props.isSettings ? (this.props.isSettings + val) : this.state.activeTab ? this.state.activeTab + val : val
      let data = document.getElementsByClassName(`p-column-filter-${value}`)
      if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].value) {

        data[0].getElementsByClassName(`p-column-filter`)[0].value = ""
      }
      if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].name) {

        let getName = data[0].getElementsByClassName(`p-column-filter`)[0].name
        this.setState({
          [getName]: ""
        })
      }
      // if (val === "hasPropertyCoOwner") {
      this.setState({
        [val]: ""
      })
      // }
      document.getElementsByClassName(`p-column-filterMode-${val}`)[0].selectedIndex = 0
      document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
      if (this.props.type === "OpenCorporateSearch" && this.state.responsesaveData && this.state.responsesaveData.length) {
        let details = await this.getFilteredData(val);
        this.setState({
          progress: 100,
          allUsersData: details.filteredData ? details.filteredData : [],
          isLoading: false,
          ocFilters: details.ocFilters ? details.ocFilters : [],
        })
      } else {
        this.onFilterChange(filteredObj);
      }
    } else {
      let value = this.props.isSettings ? (this.props.isSettings + val) : this.state.activeTab ? (this.state.activeTab + val) : val
      if ((document.getElementsByClassName(`p-column-filter-${value}`) && document.getElementsByClassName(`p-column-filter-${value}`)[0]) !== undefined) {
        if (document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display === "none") {
          document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "block"
        } else {
          document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
        }
      }

    }
    this.onColumnResizeEnd()
  }

  columnHandlingfromHeader(val, field) {
    let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    if (columns && columns.details) {
      columns = columns.details[this.state.selectedViewType];

    } else {
      columns = columns
    }
    let index = columns.findIndex(obj => obj.field === field)
    if (val === "hide") {
      if (index !== -1) columns[index].show = columns[index].show === true ? false : true;
    }
    if (columns && columns.details) {
      columns.details[this.state.selectedViewType] = columns;
    }
    localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columns))

    this.getTableFieldsOrder()
    this.setState({
      isLoading: false,
    })
  }

  getAdditionalFilter = async () => {
    if (this.props.type === "Company") {
      let counindex = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "HQCountry")
      if (counindex !== -1) this.state.filterCriteria["criteria"].splice(counindex, 1)
      this.state.filterCriteria["criteria"].push({
        key: "HQCountry",
        value: this.state.selectedCountrie,
        type: "eq",
        add_Filter: true
      })
      await this.setState({ filterCriteria: this.state.filterCriteria })
    }
  }


  // //Custum Date Column Filter Onchange
  onFilterdateChange = async (e, field, type, dropdown) => {
    let value
    if (!type && !dropdown && e.value !== null) {
      value = e.value.toLocaleDateString('zh-Hans-CN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    } else {
      value = e
    }
    if (e && !type) {
      let filters = {}, newFil = {}, filteredObj = {};
      newFil[field] = {
        value: value,
        matchMode: dropdown ? dropdown : "string"
      };

      filters[field] = newFil[field]
      filteredObj.filters = filters
      await this.setState({
        [field]: e.value ? e.value : value ? value : ""
      })
      if (value && value.length === 0) {
        this.handleFilterInputField(field, "clear")

      } else {
        if (this.props.type === "Company" && field === "CompanyName") {
          let index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyName" && obj.add_Filter === true)
          this.state.filterCriteria["criteria"].splice(index, 1)
          await this.setState({
            addedMatchforCompany: true
          })

        }
        //          this.getAdditionalFilter()
        await this.onFilterChange(filteredObj)
        await this.setState({
          addedMatchforCompany: false
        })
      }
    } else {
      await this.setState({
        [field]: e.value !== null ? e.value : e === "string" ? e : ""
      })
      if (this.props.type === "Company" && value.length === 0) {
        let index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "companyName" && obj.add_Filter === true)
        this.state.filterCriteria["criteria"].splice(index, 1)
        let counindex = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "HQCountry" && obj.add_Filter === true)
        this.state.filterCriteria["criteria"].splice(counindex, 1)
        document.getElementById("companySearch").value = ""
        //          this.getAdditionalFilter()
        this.getDataFromServer(this.state.filterCriteria)
      }
    }
  }

  CalenderComponent(item) {
    return (
      < >
        <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
          appendTo={document.body}
          monthNavigator={true}
          yearNavigator={true}
          yearRange="1940:2530"
          // onChange={(e) => this.setState({ [item.field]: e.value })}
          inputClassName={`p-column-filter p-column-${item.field}`}
          name={item.field}
          dateFormat="mm/dd/yy"
          value={this.state[item.field] ? this.state[item.field] : document.getElementsByClassName(`p-column-filter p-column-${item.field}`).value}
          onChange={(e) => this.onFilterdateChange(e, item.field, "change")}
          onSelect={(e) => this.onFilterdateChange(e, item.field)} />
      </>
    )
  }
  DropDownComponent(item) {
    return (
      <div >
        <Dropdown
          value={this.state[item.field]}
          appendTo={document.body}
          // options={[{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
          options={item.field === "confirmedOnsite" || item.field === "reportedMissing" ? [{ label: "Yes", value: "true" }, { label: "No", value: "false" }] : [{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
          // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
          onChange={(e) => this.onFilterdateChange(e.value, item.field, "", "num")}
        // style={{ width: 150 }}
        // filter={true}
        // disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


        // placeholder={item.header}
        />
        {/* <Select /> */}
        <div>
        </div>
      </div>)
  }

  MultiSelectComponent(item, delta) {
    return (
      <div >
        <MultiSelect
          value={this.state[item.field]}
          appendTo={document.body}
          options={item.field === "matchMatchStatus" ? this.props.settingsData :
            item.field === "matchStatusReason" ? this.props.shownreasons :
              item.field === "propertyStatus" ? this.props.propertyStatusArray :
                item.field === "propertyStatusReason" ? this.props.propertyStatusReasonArray :
                  item.filterOptions}
          style={{ width: item.width - 55, marginRight: 3 }}
          maxSelectedLabels={2}
          filter={true}
          // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
          onChange={(e) => this.setState({ [item.field]: e.value })}
        />

        <FontAwesomeIcon
          color='green'
          icon={faCheckCircle}
          data-toggle="tool-tip"
          className='my-2 mx-0'
          onClick={(e) => this.onFilterdateChange(this.state[item.field], item.field, "", "string")}
        />
        {/* <Select /> */}
        <div>
        </div>
      </div>)
  }

  //after selecting type 
  onSeletFilterType(value, type) {
    value = this.props.isSettings ? (this.props.isSettings + value) : this.props.activeTab ? this.state.activeTab.toString() + value : value
    let selectedcolumn = document.getElementsByClassName(`p-column-filter-${value}`)
    if (selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0]) {
      if (!type) {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].focus()
      } else {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].blur()
      }
    }
  }
  getFilterOptionValues(column) {
    if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
      if (this.state.activeTab === "SP") {
        if (column.filter) {
          return this.state.dataChangeOptionswithoutSortwithFilter
        } if (!column.filter) {
          return this.state.dataChangeOptionswithoutSortwithoutFilter
        }
      } else {
        if (column.filter && !column.sorted) {
          return this.state.dataChangeOptionswithFilterPE
        } if (!column.filter && !column.sorted) {
          return this.state.dataChangeOptionswithoutFilterPE
        } if (column.sorted) {
          return this.state.dataChangeOptionswithoutSortwithoutFilter

        }
      }
    } else {
      if (column.filter) {
        return this.state.dataChangeOptionswithFilter
      } if (!column.filter) {
        return this.state.dataChangeOptionswithoutFilter
      }
    }

  }

  /**
   *   invokes when the column drag and drop happens 
   * @param {Object} e 
   */
  onColReorder = (e) => {
    let tablefieldsToShow = this.state.tablefieldsToShow
    let removeObj = tablefieldsToShow[e.dragIndex]
    tablefieldsToShow.splice(e.dragIndex, 1);
    tablefieldsToShow.splice(e.dropIndex, 0, removeObj);
    tablefieldsToShow = tablefieldsToShow
    this.setState({
      tablefieldsToShow: tablefieldsToShow
    })

  }

  getColumns(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    if (this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0) {
      return this.state.tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            rowReorder={item.field === "reOrder" ? true : false}
            expander={item.fieldType === "expander" ? true : false}
            rowEditor={item.fieldType === "rowEditor" ? true : false}
            editor={item.editField === "text" ?
              (options) => this.textEditor(options, item) :
              item.editField === "textArea" ?
                (options) => this.textAreaEditor(options, item) :
                // item.editField === "MultidropDown" ?
                //   (options) => this.multiSelectEditor(options, item) :
                item.editField === "dropDown" ?
                  (options) => this.dropDownEditor(options, item) :
                  item.editField === "autoComplete" ?
                    (options) => this.getAutoComplete(options, item) :
                    item.editField === "date" ?
                      (options) => this.getDatePicker(options, item) :
                      item.editField === "calenderTime" ?
                        (options) => this.getTimePicker(options, item) :
                        null}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: item.field === "image" || item.field === 'reOrder' ? 'none' : 'ellipsis',
              overflow: item.header === "Actions" ? 'initial' : 'hidden',
              whiteSpace: 'nowrap',
              width: item.width,
              fontSize: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 14 : this.props.type === "Recovery Planner V2" ? 10 : 12,
              textAlign: item.fieldType === "Badge" ? "left" : item.textAlign,
              verticalAlign: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? "baseline" : "inherit",
              // display: item.fieldType === "rowEditor" ? "inline-flex" : "block"
            }
            }
            onColReorder={this.onColReorder}
            filterMatchMode={item.filterType === "num" ? "num" : "string"}
            isAllowInt={item.allowInt ? true : false}
            field={item.field}
            getID={item && item.getID ? item.getID : null
            }
            activeTab={this.props.isSettings ? this.props.isSettings : this.state.activeTab}
            editRequired={self.e}
            deleteRequired={self.d}
            shownField={this.state.shownFieldTab}
            dataChangeOptions={this.getFilterOptionValues(item)}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={self.changeFieldValues}
            headerStyle={{
              height: this.state.colunHeight ? this.state.colunHeight : "auto",
              padding: "6px 15px",
              width: item.width,
              fontSize: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 16 : 13,
              fontWeight: this.props.type === "Utilization - US Team" || this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - Integrity" ? 'bold' : '500',
              color: item.header === "Green" ? "green" : item.header === "Blue" ? "blue" : item.header === "SP1 Group Count" ? "#12853C" : item.header === "SP2 Group Count" ? "#F4A61E" : item.header === "SP3 Group Count" ? "#256BD1" : item.header === "Add Payment(+)" || item.header === "Blue" ? "white" : config.blackColor,
              backgroundColor: config.templateColor,
              textAlign: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 'center' : 'left'
            }}
            frozen={
              item.frozen ? true : false
            }
            filter={item.filter ? item.filter : false}
            filterElementnonText={item.filterType === "dropdown" ? this.DropDownComponent(item) : item.filterType === "multiSelect" ? this.MultiSelectComponent(item) : item.fieldType === 'Date' || item.fieldType === 'Time' || item.fieldType === "scheduledTime" || item.fieldType === "dateTime" ? this.CalenderComponent(item) : null}
            filterElement={item.filter && this.props.type === "OpenCorporateSearch" ? <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
              <option value="regexOr">Includes</option>
            </select> : item.filter && item.filterType === "num" ?
              <select className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} name="viewroom" id="viewroom" onChange={(e) => this.onSeletFilterType(item.field)}>
                <option value="eq">Equals</option>
                <option value="lt">Less Than</option>
                <option value="gt">Greater Than</option>
                <option value="lte">Less Than or Equals</option>
                <option value="gte">Greater Than or Equals</option>
                <option value="nin">Exists</option>
                <option value="in">Not Exists</option>
              </select> : item.filter && item.filterType === "Equals" ?
                <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                  <option value="eq">Equals</option>
                </select>
                : item.filter && item.filterType !== "num" ?
                  this.props.type !== "Property Editor"&&this.props.type !== "Deal Sizing Property Editor" && this.props.type !== "Deal Statistics" ?
                    <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                      <option value="regexOr">Includes</option>
                      <option value="eq">Equals</option>
                      <option value="sw">Starts With</option>
                      <option value="ew">Ends With</option>
                      <option value="nregexOr">Excludes</option>
                      <option value="nin">Exists</option>
                      <option value="in">Not Exists</option>
                    </select>
                    : item.filterType === "dropdown" || item.filterType === "multiSelect" ?
                      <select name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                        {item.filterType === "dropdown" ? <option value="eq">Equals</option> : <option value="multi">Exists</option>}
                      </select>
                      : <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                        {item.isFuzzy ? <option value="fuzzyregexOr">Includes Fuzzy</option> : null}
                        <option value="regexOr">Includes</option>

                        <option value="eq">Equals</option>
                        <option value="nregexOr">Excludes</option>
                        <option value="nin">Exists</option>
                        <option value="in">Not Exists</option>
                      </select>

                  : null
            }
            sortable={item.sortable ? true : false}
            // sortable={false}
            filterPlaceholder={item.placeholder ? item.placeholder : ''}
            selectionMode={this.state.activeTab !== "Teal" && !this.state.isEditedClicked ? item.selectionMode : ""}
          />
        )

        return column;
      })
    }
  }


  toggleFilterDropdown = async (field) => {
    await this.setState({ collapsefilter: this.state.collapsefilter === true ? false : true, openedField: field });
  }

  //Drag and drop rows
  onRowReorder = (e, l) => {
    const { allUsersData } = this.state
    allUsersData[e.dragIndex][this.props.reorderkey] = allUsersData[e.dropIndex][this.props.reorderkey]
    let userBody = allUsersData[e.dragIndex]
    // if (this.props.type === "Departments") {
    //   userBody.sequence = e.dropIndex
    // }
    let url
    if (l) {
      url = l
    } else {
      url = this.getAPIUrl();
    }
    return fetchMethodRequest('PUT', `${url}/${e.value[e.dropIndex]._id}`, userBody)
      .then(async (response) => {
        if (response && response.respCode) {
          this.getDataFromServer(this.state.filterCriteria)
        } else if (response && response.errorMessage) {
          this.getErrorResponseFromServer(response)
        }
        this.setState({
          isLoading: false
        })
      }).catch((err) => {
        return err;
      });

  }


  getEditSiteDiscoveryFooter() {
    return <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={5} />
        <Column footer={this.state.totalForcastSiteDiscovery ? this.state.totalForcastSiteDiscovery.toFixed(1) : null} />
        < Column footer={this.state.totalActualSiteDiscovery ? this.state.totalActualSiteDiscovery.toFixed(1) : null} />
        <Column footer="" />
        <Column footer="" />
        <Column footer="" />
      </Row>
      <Row>
        <Column footer="Averages(%):" colSpan={5} />
        <Column colSpan={2} footer={this.state.totalForcastSiteDiscovery ? ((this.state.totalActualSiteDiscovery / this.state.totalForcastSiteDiscovery) * 100).toFixed(1) : null} />
        <Column footer="" />
        <Column footer="" />
        <Column footer="" />
      </Row>
    </ColumnGroup>;
  }

  getSiteDiscoveryFooter() {
    return <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={5} />
        <Column footer={this.state.totalForcastSiteDiscovery ? this.state.totalForcastSiteDiscovery.toFixed(1) : null} />
        < Column footer={this.state.totalActualSiteDiscovery ? this.state.totalActualSiteDiscovery.toFixed(1) : null} />
        <Column footer="" />
      </Row>
      <Row>
        <Column footer="Averages(%):" colSpan={5} />
        <Column colSpan={2} footer={this.state.totalForcastSiteDiscovery ? ((this.state.totalActualSiteDiscovery / this.state.totalForcastSiteDiscovery) * 100).toFixed(1) : null} />
        <Column footer="" />
      </Row>
    </ColumnGroup>;
  }

  handleColumnresize = async (e, type) => {
    if (this.props.type === "ClaimsManagement") {
      let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
      colOrder = JSON.parse(colOrder)
      let details = colOrder.details
      let Defaults
      // let Defaults = details["Default"]
      if (details) {
        Defaults = details[this.state.selectedViewType]
      } else {
        Defaults = colOrder
      }

      let z = Defaults.find(elem => elem.field === e.column.field)
      if (z) {
        z.width = z.width + e.delta

      }
      localStorage.setItem(
        `${this.props.type}_column_order`,
        JSON.stringify(colOrder)
      );
      await this.setState({ resized: true })
      this.setDynamicColumns()
    } if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
      if (e.column.frozen === true) {

      }
    }
    this.onColumnResizeEnd(e)
  }
  onColumnResizeEnd = (e) => {
    let colunHeight, columnHeight1, largest;
    if (this.props.frozen && document.getElementsByClassName("p-datatable-thead").length === 2) {
      colunHeight = document.getElementsByClassName("p-datatable-thead")[1].clientHeight
      columnHeight1 = document.getElementsByClassName("p-datatable-thead")[0].clientHeight
      if (colunHeight >= columnHeight1) {
        largest = colunHeight;
      }
      else {
        largest = columnHeight1;
      }
    } else {
      largest = document.getElementsByClassName("p-datatable-thead")[0].clientHeight

    }
    if (this.state.columnHeight !== largest) {
      this.setState({
        colunHeight: largest,
      })
    }
    if (e && e.column.frozen) {
      let frozenwidth = this.state.frozenWidth
      this.setState({
        frozenwidth: frozenwidth + e.delta
      })
      document.getElementsByClassName("p-datatable-scrollable-view p-datatable-frozen-view")[0].style.width = this.state.frozenWidth
    }
  }
  onRowEditInit = async (e) => {
    let k = document.getElementsByClassName("p-row-editor-cancel").length

    if (k >= 0) {
      await this.setState({
        isEditedClicked: true
      })

      // document.getElementsByClassName("p-checkbox p-component").style.pointerEvents = "none";
    } else {
      await this.setState({
        isEditedClicked: false
      })
    }

  }

  getReturnPreviousweek = () => {
    return this.state.isPreviousWeek
  }

  getDataTable() {
    let self = this;
    self.editRequired = this.props.editRequried;
    self.deleteRequired = this.props.deleteRequried;
    let footerGroup = !this.state.isPreviousWeek && this.state.screenPermissions === "Edit" && window.location.pathname === "/siteDiscoveryPlan" ? this.getEditSiteDiscoveryFooter()
      : (this.state.isPreviousWeek || this.state.screenPermissions !== "Edit") && window.location.pathname === "/siteDiscoveryPlan" ?
        this.getSiteDiscoveryFooter() :
        // !this.state.isPreviousWeek
        //   && this.state.screenPermissions === "Edit" && window.location.pathname === "/nonSiteDiscoveryPlan" ? this.getEditNonDiscoveryFooter()
        //   : (this.state.isPreviousWeek || this.state.screenPermissions !== "Edit") && window.location.pathname === "/nonSiteDiscoveryPlan" ?
        //     this.getNonDiscoveryFooter()
        //     :
        // window.location.pathname === "/reportPlan" ?
        //   this.getReportsFooter() :
        this.state.footer ? this.state.footer : null
    let headerGroup =
      !this.state.isPreviousWeek && this.state.screenPermissions === "Edit" && window.location.pathname === "/siteDiscoveryPlan" ? this.props.getEditSiteDiscoveryColumns
        : (this.state.isPreviousWeek || this.state.screenPermissions !== "Edit") && window.location.pathname === "/siteDiscoveryPlan" ?
          this.props.getSiteDiscoveryColumns : !this.state.isPreviousWeek && this.state.screenPermissions === "Edit" && window.location.pathname === "/nonSiteDiscoveryPlan" ? this.props.getEditNonDiscoveryColumns
            : (this.state.isPreviousWeek || this.state.screenPermissions !== "Edit") && window.location.pathname === "/nonSiteDiscoveryPlan" ?
              this.props.getNonDiscoveryColumns :
              window.location.pathname === "/reportPlan" ?
                this.props.getReportColumns : null
    const rowSpanrequired = ["Deal Sizing", "Claims", "Deal Sizing Queue", "Site Confirmation Editor", "Regular Scoring Queue", "Deal Sizing Scheduler", "Regular Scoring Scheduler", "ClaimsManagement", "State Attributes", "Recovery Planner V2"]
    return (
      <div
        className={
          this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity"
            ? "stickyHeader"
            : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") || this.props.type === "Claims"
              ? "disabledSelection" : this.props.inlineSave ? "isSaveNeeded" : ''
        } >
        {this.props.type === "OpenCorporateSearch" && this.state.allUsersData && this.state.allUsersData.length > 0 ?
          <h5 style={{ paddingTop: "5px", marginTop: "20px", textAlignLast: "right" }}> Showing {this.state.allUsersData.length} results.To further narrow down the results, add additional search terms.</h5> : ""
        }
        <DataTable

          onColumnResizeEnd={(e) => this.handleColumnresize(e, this.props.type)}
          reorderableColumns={true}
          // onColumnResizeEnd={(e) => this.onColumnResizeEnd(e)}
          rowGroupMode={!rowSpanrequired.includes(this.props.type) ? "rowspan" : ""}
          // this.props.type !== "Deal Sizing" && this.props.type !== "Claims" && this.props.type !== "Deal Sizing Queue" && this.props.type !== "Regular Scoring Queue" && this.props.type !== "Deal Sizing Scheduler" && this.props.type !== "Regular Scoring Scheduler" && this.props.type !== "ClaimsManagement" && this.props.type !== "State Attributes" && this.props.type !== "Recovery Planner V2" ? "rowspan" : ""}
          groupField={this.props.groupField}
          headerColumnGroup={this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" ? headerGroup : null}
          footerColumnGroup={(this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" || this.props.tableFooter) && this.state.allUsersData && this.state.allUsersData.length > 0 ? footerGroup : ""}
          groupRowSpan="2"
          //Column Grop Props
          //edit props
          editMode="row"
          onColReorder={this.onColReorder}
          onRowEditInit={(e) => this.onRowEditInit(e)}
          rowEditorValidator={this.onRowEditorValidator}
          onRowEditInit={this.onRowEditInit}
          onRowEditSave={this.onRowEditSave}
          onRowEditCancel={this.onRowEditCancel}

          // dataKey={this.props.type !== "Property Editor" && this.props.type !== "Sellers" && this.props.type !== "Deal Sizing" && this.props.type !== "Claims" ? "id" : this.props.type === "Sellers" ? "DimSellerID" : this.props.type === "Deal Sizing" ? "Sno" : this.props.type === "Claims" ? "propertyID" : "_id"}
          dataKey={this.props.dataKey ? this.props.dataKey : "_id"}
          sortField={this.props.sortedField}
          frozenWidth={this.props.frozen && this.state.isFrozenColumns ? this.state.frozenWidth : ""}
          sortOrder={1}
          responsiveLayout="scroll"
          rowClassName={(e) => this.rowClassName(e)}
          ref={(el) => this.dt = el}
          value={this.state.allUsersData}
          expandedRows={this.state.expandedRows}
          onRowToggle={(e) => this.setState({ expandedRows: e.data })}
          onRowExpand={(data) => this.openAdUnitsCollapse(data)}
          rowExpansionTemplate={(e) => this.rowExpansionTemplate(e)}
          onRowReorder={this.onRowReorder}
          // header={this.getHeader()}
          totalRecords={this.state.totalRecordsLength}
          paginator={false}
          lazy={true}
          resizableColumns={true}
          columnResizeMode="expand"
          onSort={this.handleDataChangingOption}
          globalFilter={this.state.globalFilter}
          onFilter={this.isConfirmFilter}
          scrollable={true}
          // selection={false}
          selectionMode={this.props.isSelectMode}
          onSelectionChange={(e) => this.onSelectRowsUpdate(e)}
          // onSelectionChange={(e) => this.setState({ selectedRows: e.value })} 
          selection={this.state.selectedRows}
          scrollHeight={this.props.scrollHeight ? this.props.scrollHeight : "1000px"}
          emptyMessage={this.state.errorResponse ? this.state.errorMessage : configMessages.noRecords}
          sortMode="single"
          metaKeySelection={false}
          loading={this.state.isLoading}
          style={this.state.allUsersData && this.state.allUsersData.length === 0 ?
            { textAlign: 'center', marginTop: 24 }
            : { marginTop: 30 }}
        >
          {self.getColumns(self.editRequired, self.deleteRequired)}
        </DataTable>
        {this.props.type === "FactSet" ?
          <h5 style={{ paddingTop: "5px", textAlignLast: "right" }}>Total Count: {this.state.FactSetCount}</h5> : ""
        }
      </div >


    )
  }

  getTabInfo() {
    return null;
  }

  getPaginator() {
    let paginationNotneededScreens = ["UP Opportunity – V2", "AvailableToMap", "AvailableToReMap"]
    if (this.state.totalRecordsLength > 10 && this.state.isShowTable && !paginationNotneededScreens.includes(this.props.type))
      return (
        <PaginatorComponent
          totalRecords={this.state.totalRecordsLength}
          first={this.state.first}
          rows={this.state.rows}
          onPageChange={this.onPageChange}
          isWeb={true}
        />

      )


  }

  //getGridView
  getGridView() {
    return (
      <div className='row   ml-lg-0 pr-1'>
        {
          this.state.allUsersData && this.state.allUsersData.length > 0 ?
            this.state.allUsersData.map((item, i) => {
              return (
                <div className='col-sm-6 col-md-4 col-lg-3 px-1' key={i}>
                  <Card className='pb-2' >
                    <CardBody className={this.props.type ? 'tableCardBody' : 'modalTablePadding'} style={{ borderRadius: "0px" }}>
                      {
                        this.state.tablefieldsToShow && this.state.tablefieldsToShow.length && this.state.tablefieldsToShow.length > 0 ?
                          this.state.tablefieldsToShow.map((elememt, index) => {
                            return (
                              <div className={(elememt.displayInSettings === true) ? `col-12  ` : 'd-none'} key={index}>
                                <div >
                                  {/* {this.changeFieldValues(item, element)} */}
                                  {
                                    elememt.fieldType === 'Link' ?
                                      <div className='d-flex'>
                                        <span
                                          style={elememt.style}
                                          onClick={() => this.openViewModal(item, 'view')}>
                                          <b> {item[elememt.field]}</b>
                                        </span>
                                      </div>
                                      :
                                      elememt.fieldType === 'Badge' ?
                                        <div style={elememt.style}>
                                          <Badge color={item.status === 'Active' ? 'success' : item.status === 'Inactive' ? 'warning' : item.status === 'Pending' ? 'danger' : item.status === 'Reject' ? 'error' : item.status === 'Completed' ? 'primary' : 'info'}>
                                            {item[elememt.field]}
                                          </Badge>
                                        </div> :
                                        elememt.fieldType === 'Role' ?
                                          <div style={elememt.style}>
                                            <Badge pill
                                              color='success'
                                            >
                                              {item[elememt.field]}
                                            </Badge>
                                          </div>
                                          : elememt.fieldType === 'Date' ?
                                            <div>
                                              {dateFormats.formatDate(item[elememt.field], config.dateDayMonthFormat)}
                                            </div>
                                            : elememt.fieldType === 'Time' ?
                                              <div>
                                                {dateFormats.formatDate(item[elememt.field], config.timeFormat)} - ${dateFormats.formatDate(item['toTime'], config.timeFormat)}
                                              </div>
                                              : elememt.fieldType === 'Array' ?
                                                <span style={element.style}>
                                                  {this.flattenArray(item[elememt.field])}
                                                </span>
                                                : <div style={elememt.style}>
                                                  <span
                                                  >{item[elememt.field]}</span></div>
                                  }
                                </div>
                              </div>
                            )
                          }) : null
                      }
                    </CardBody>
                  </Card>
                </div>

              )
            }) : null
        }
      </div>
    )
  }

  getClaimStatus = async () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    return fetchMethodRequest('GET', `${apiCalls.ClaimStatuses}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["claimStatuses"] && response["claimStatuses"].length > 0) {
        let propertyStatuses = response["claimStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        this.setState({ claimStatusArray: StatusArray })
      }
    })

  }

  getAnalysts = async () => {
    let filterCriteria = {}
    if (this.props.type === "Weekly Recovery Plan") {
      if (window.location.pathname === "/nonSiteDiscoveryPlan") {
        filterCriteria['criteria'] = [{
          key: "role", value: ["Recovery Analyst", config.roleNames.coreOpsAnalyst, config.roleNames.coreOpsManager, "Recovery Manager", "Filer", "Reviewer", "Transition Manager", "Transition Analyst"], type: 'in'
        }];
      } else {
        filterCriteria['criteria'] = [{
          key: "role", value: ["Search Manager", config.roleNames.coreOpsAnalyst, config.roleNames.coreOpsManager, "Search Analyst"], type: 'in'
        }];
      }
    } else if (this.props.type === "ClaimsManagement") {
      filterCriteria['criteria'] = [];
    } else {
      if (this.props.type !== "Recovery Planner V2") {
        filterCriteria['criteria'] = [{
          key: "role", value: ["Transition Manager", config.roleNames.coreOpsAnalyst, config.roleNames.coreOpsManager, "Pod Lead", "Recovery Manager", "Transition Analyst", "Recovery Analyst", "Filer", "Reviewer"], type: 'in'
        }];
      }
    }
    return fetchMethodRequest('GET', `${apiCalls.Users}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          let data = response.users, tempData = [];
          for (let i = 0; i < data.length; i++) {
            tempData.push({ 'label': data[i]['display_name'], "value": { userName: data[i]['display_name'], userId: data[i]['_id'] } })
          }
          tempData.sort(this.compare)
          await this.setState({
            filteredAnalysts: tempData
          })
        } else if (response && response['errorMessage']) {
          this.getErrorResponseFromServer(response)
        }
      }).catch((err) => {
        return err;
      })
  }

  getEmails = async () => {
    let filterCriteria = {}

    filterCriteria['criteria'] = [{
      key: "role", value: ["CoreOps Analyst", "CoreOps Manager", "Admin"], type: 'in'
    }];

    return fetchMethodRequest('GET', `${apiCalls.Users}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          let data = response.users, tempData = [];
          for (let i = 0; i < data.length; i++) {
            tempData.push({ 'label': data[i]['email'], "value": { userName: data[i]['email'], userId: data[i]['_id'] } })
          }
          tempData.sort(this.compare)
          await this.setState({
            filteredAnalystsEmails: tempData
          })
        } else if (response && response['errorMessage']) {
          this.getErrorResponseFromServer(response)
        }
      }).catch((err) => {
        return err;
      })
  }

  //for Sorting the Menu lists in Roles
  compare(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  getFilteredReports = async (val, type) => {
    let filterCriteria = this.state.filterCriteria
    if (type === "analyst") {
      await this.setState({
        analysttype: val
      })
      if (typeof val === "object") {
        val = val.userName
      } else {
        val = val
      }
      let index = filterCriteria["criteria"].findIndex(item => item.key === "username");
      if (index === -1) {
        filterCriteria["criteria"].push({ key: "username", value: val, type: "eq" })
      } else {
        filterCriteria["criteria"][index].value = val
      }
    }
    if (type === "Seller") {
      await this.setState({
        sellertype: val,
      })
      let data;
      if (typeof val === "object") {
        data = val.estateName
      } else {
        data = val
      }
      await this.setState({
        seletedSellerType: data
      })
      if (typeof val === "object") {
        this.getDataFromServer(this.state.filterCriteria)
      }
    }
    if (type === "clear") {
      filterCriteria["criteria"] = [];
      await this.setState({
        seletedSellerType: null,
        sellertype: null,
        analysttype: null
      })
    }
    await this.setState({
      filterCriteria: filterCriteria
    })
    if (type !== 'Seller') {
      this.getDataFromServer(this.state.filterCriteria)
    }
  }

  getFilteredReportsselectedKey = async (v) => {
    if (v.key === "Enter") {
      this.getDataFromServer(this.state.filterCriteria)
    }
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }
  getUtilizationFilters() {
    return (
      <div className="d-flex">
        <div className='d-flex '>  <h5 className="mr-2 mt-2">Analyst</h5>

          <Dropdown
            value={this.state.analysttype}
            onKeyPress={(e) => this.getFilteredReportsselectedKey(e, 'Seller')}
            appendTo={document.body}
            options={this.state.filteredAnalysts}
            onChange={(e) => this.getFilteredReports(e.value, 'analyst')}
            style={{ width: 200, height: 35 }}
          />
        </div >
        <div className="ml-3 d-flex">
          <h5 className="mr-2 mt-2">Deal</h5>
          <AutoComplete
            // value={this.state.sellertype}
            value={this.state.sellertype && this.state.sellertype.EstateName ? `${this.state.sellertype.DealType} - ${this.state.sellertype.SellerNickName} (${this.state.sellertype && this.state.sellertype.DealYear ? this.state.sellertype.DealYear : ""})` : this.state.sellertype}
            suggestions={this.state.filteredSuggestions}
            completeMethod={this.searchItems}
            minLength={1}
            style={{ height: 35, width: "227px" }}
            field="estateName"
            dropdown={true}
            itemTemplate={this.editorTemplateForDeal}
            // onKeyUp={(e) => this.getFilteredReports(e, 'Seller')}
            onChange={(e) => this.getFilteredReports(e.value, 'Seller')}
            onKeyUp={(e) => this.getFilteredReportsselectedKey(e, 'Seller')}
            appendTo={document.body} />
        </div>
        <div className="ml-2 mb-0">
          <Button color="primary" className="ml-2 mb-0" outline
            onClick={(e) => this.getFilteredReports(e, "clear")}>
            <FontAwesomeIcon
              color='red'
              icon={faTimes}
              data-toggle="tool-tip"
              onClick={(e) => this.getFilteredReports(e, "clear")}
            />Clear
          </Button>
        </div>
      </div >
    )
  }

  onpropertiessortBy = async (e, type, submit) => {
    let filterCriteria = this.state.filterCriteria
    if (e === "confirmation" && type) {
      await this.setState({
        confirmation: !this.state.confirmation
      })

    } if (e === "Properties" && type) {
      await this.setState({
        Properties: !this.state.Properties
      })
    } else if (e && !type) {
      let val = this.getArrayVal(JSON.stringify(e))
      val = val.replace(/\s*,\s*/g, ", ");
      let filterCriteria = this.state.filterCriteria
      if (val && (this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report")) {
        val = "dealType, " + val
      }
      filterCriteria["sortfield"] = val ? val : this.props.sortField
      await this.setState({
        sortField: val ? val : this.props.sortField,
        filterCriteria: filterCriteria
      });
      // let index;
      await this.setState({
        propertyStatus: e
      })

    }
  }

  //submit groupby
  submitSort = async () => {
    await this.getDataFromServer(this.state.filterCriteria)
  }

  selectedViewType = async (e, type) => {
    if (type) {
      await this.setState({
        isFrozenColumns: e == "unfreeze" ? false : true,
        frozenWidth: e == "unfreeze" ? 0 : this.state.frozenWidth
      })
    } else {
      await this.setState({
        selectedViewType: e,
      })
    }

    // if (e === "All Validated") { this.getremoveFilters() }
    this.getTableFieldsOrder();
    this.scrollWin()
    this.setDynamicColumns()
  }

  //DealSizing Api Call
  ValidateDealSizingRecords = async => {
    let selectedRowsId = [];
    let selectedRows = [...this.state.selectedRows]
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item.DealID)
      })
    }
    let body = {
      url: `${config.dealUrl}&estateIds=[${selectedRowsId}]`
    }
    return fetchMethodRequest('POST', apiCalls.Deals, body)
      .then(async (response) => {
        if (response) {
          this.getSuccessMessage(response.respMessage, "", "success")
          this.getDataFromServer(this.state.filterCriteria)
          await this.setState({
            selectedRows: [],
            selectedRowsId: [],

          })
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage, "", "error")
        }
      }).catch((err) => {
        return err;
      });
  }

  toggletab(tab, setGoButton) {
    let tagSwitch = false,tableFieldsToShow=[]
    let filterCriteria = this.state.filterCriteria
    if (this.props.custumisedFieldsforParticularTab) {
      tableFieldsToShow =  this.props.custumisedFieldsforParticularTab(this.state.tablefieldsToShow, tab)
    }
    if (this.state.activeTab !== tab) {
      tagSwitch = true
      this.setState({
        activeTab: tab,
        page: 1,
        first: 0,
        allUsersData: [],
        showSelectederror: false,
        validatedArray: [],
        errormsg: "",
        selectedReason: "",
        selectednote: "",
        claimID: "",
        selectedStatus: "",
        isDisabledTab: true,
        // tablefieldsToShow:tableFieldsToShow
      });

    }
    if (tab === "SP" && this.state.PropertyData && this.state.PropertyData.groupFlag) {
      let flag
      if (!this.state.PropertyData.groupFlag.includes("ReMap")) {
        flag = this.state.PropertyData.groupFlag.slice(0, 6);
      } else if (this.state.PropertyData.groupFlag === "ReMapSPGroup3Flag") {
        flag = "reMapGroup3";
      } else if (this.state.PropertyData.groupFlag === "ReMapSPGroup2Flag") {
        flag = "reMapGroup2";
      } else if (this.state.PropertyData.groupFlag === "ReMapSPGroup1Flag") {
        flag = "reMapGroup1";
      }
      filterCriteria.sortfield = `${flag}ParentPropertyID, PropertyReviewedFlag, ${this.state.PropertyData.groupFlag}, PropertyName, PropertyAddressLine1`
      filterCriteria.direction = "desc"
    } else if (tab === "SP" && this.state.PropertyData && !this.state.PropertyData.groupFlag) {
      filterCriteria.sortfield = "ParentPropertyID, PropertyName, PropertyAddressLine1"
      filterCriteria.direction = "desc"
    } else if (tab === "All") {
      filterCriteria.sortfield = "updatedDate"
    } else {
      filterCriteria.sortfield = this.props.sortField
    }

    // if (tab === "SP") {
    //   filterCriteria.sortfield = "ParentPropertyID, PropertyReviewedFlag, Group1Flag, Group2Flag, Group3Flag, Group4Flag, Group5Flag, Group6Flag, Group7Flag, Group8Flag, Group9Flag, Group10Flag, PropertyName, PropertyAddressLine1"
    // } else {
    //   filterCriteria.sortfield = this.props.sortField
    // }

    filterCriteria["page"] = 1
    if (this.editorComponentRef) {
      this.editorComponentRef.updatedfileFor()
      this.editorComponentRef.updateClaimID()
      this.editorComponentRef.updatedrelevantSellerName()

      this.editorComponentRef.handlePropertiesEditChange("", "Cancel")
    }
   
    // this.getTableFieldsOrder(tab)
    
    let index = filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
    if (index !== -1) filterCriteria["criteria"].splice(index, 1)
    filterCriteria.criteria.push({
      key: "bucket",
      value: tab,
      type: "eq",
      add_Filter: true
    })
    let body = {}
    if (this.state.PropertyData.selectedProperty_Id||this.state.PropertyData.selectedOpraStId) {
      body = {
        propertyId: this.state.PropertyData.selectedProperty_Id ? this.state.PropertyData.selectedProperty_Id.toString() : null,
        statePropertyId: this.state.PropertyData.selectedOpraStId ? this.state.PropertyData.selectedOpraStId.toString() : null
      }
    }
    if (this.state.Counts && tab != "Green" && tagSwitch && this.state.Counts[tab] > 10000) {
      this.setState({ isDisabledTab: true, totalRecordsLength: this.state.Counts[tab], isChangeHit: false })
      this.getDataFromServer(filterCriteria, null, setGoButton,body)
    } else {
      this.setState({ showErrorMessage: false })
      if (this.state.PropertyData) {
        // this.getUrlbyParams()
        // this.getTableFieldsOrder(tab)
        // if(tab==="Teal"){
        //   document.getElementsByClassName("")
        // }
        this.getDataFromServer(filterCriteria, null, setGoButton, body)
      }
    }

  }

  getshowSelectederrormsg = async (errormsg, showSelectederror, color) => {
    await this.setState({
      errormsg: errormsg,
      showSelectederror: showSelectederror,
      errorcolor: color === "error" ? "red" : "green"
    })
  }
  changeCreateTask = async (e, type) => {
    if (type === "addToTask") {
      await this.setState({ createTaskAdd: e.value })
    } else {
      await this.setState({ createTask: e.value })
    }
    if (e && e.value.includes("Claim")) {
      let stateArray = [], connectedClaimIDArray = []
      await this.setState({ createclaimfromPE: true, Claimtype: type })
      for (var obj of this.state.selectedRows) {
        if (!stateArray.includes(obj.sourceState)) {
          stateArray.push(obj.sourceState)
        }
        if (obj.connectedClaimID !== null) {
          connectedClaimIDArray.push(obj.connectedClaimID)
        }
      }
      if (stateArray.length === 1) {
        if (connectedClaimIDArray.length === 0) {
          this.getErrorMessage("", false, "")
          this.props.getClaimsDetails(this.state.selectedSeller, this.state.selectedRows, type)
        } else {
          this.getErrorMessage("Few or all selected properties that you are trying to create a claim or add to claims are already exists", true, "error")
          this.setState({
            createTask: "",
            createTaskAdd: ""
          })

        }
      } else {
        this.setState({
          createTask: "",
          createTaskAdd: ""
        })
        this.getErrorMessage("The selected properties have different Source states. Properties with the same Source State must be selected to create or add to claims.", true, "error")
      }


    } else {
      if (this.state.createTask === "General Task" || this.state.createTaskAdd === "General Task") {
        await this.setState({ iskanbanModal: true, formType: type })
      } else {
        await this.setState({ createTaskModal: true, formType: type })
      }
    }
  }


  // changeCreateTaskForHighProperties = async (e, type) => {
  //   if (type === "addToTask") {
  //     await this.setState({ createTaskAdd: e.value })
  //   } else {
  //     await this.setState({ createTask: e.value })
  //   }
  //   if (this.state.createTask === "General Task" || this.state.createTaskAdd === "General Task") {
  //     await this.setState({ iskanbanModalForHighProperties: true, formType: type })
  //   }
  // }
  changeCreateTaskFromReport = async (e, type) => {

    await this.setState({ createTask: e.value })
    await this.setState({ iskanbanModal: true, formType: type })

  }

  // createTask = async () => {
  //   if (this.state.createTask === "General Task") {
  //     await this.setState({ iskanbanModal: true, formType: "add" })
  //   } else {
  //     await this.setState({ createTaskModal: true, formType: "add" })
  //   }
  // }
  // addToTask = async () => {
  //   if (this.state.createTask === "General Task") {
  //     await this.setState({ iskanbanModal: true, formType: "addToTask" })

  //   } else {
  //     await this.setState({ createTaskModal: true, formType: "addToTask" })
  //   }
  // }
  getUrlbyParams = async (type, item, clickedField) => {
    if ((this.props.type === "Grouped By Deal" && this.state.activeTab === "Grouped By Deal") || (this.props.type === "Grouped By Deal/State" && this.state.activeTab === "Grouped By Deal/State")) {
      await this.setState({ removeDataSource: false })

    } else {
      await this.setState({ removeDataSource: true })
    }
    // if (item && item.FileFor) {
    //   await this.setState({ redirectFileFor: item.FileFor })
    // }
    let filtercriteria = this.state.filterCriteria
    let url, index, Subject, matchMatchStatusIndex, matchMatchReasonIndex;
    // if (this.props.type === "Deal Sizing-Properties") {
    // index = filtercriteria["criteria"].findIndex(obj => obj.key === "state")
    // } else {
    index = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState") : null
    matchMatchStatusIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus") : null
    matchMatchReasonIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason") : null

    // }

    if (this.props.type === "UP Opportunity – V2") {
      url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${encodeURIComponent(item.EstateName)}&sellerId=${item.CompanyID}&bucket=${"SP"}&GroupFlag=${this.state.flagName ? this.state.flagName : this.props.tabOptions && this.props.tabOptions.length > 0 ? this.props.tabOptions[0].flagName : ''}${clickedField && clickedField === "Count10kTo50k" ? `&10kto50k=` + "true" : ""}${clickedField && clickedField === "CountAbove50k" ? `&50k=` + "true" : ""}${clickedField && clickedField === "CountPropertyUnknown" ? `&unknown=` + "true" : ""}${this.state.postBody && this.state.postBody.states ? `&state=` + `${this.state.postBody.states}` : ''}&reportType=UP_OPP_V2 `
    } else if (this.props.type === "Required Evidence") {
      url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${encodeURIComponent(item.EstateName)}&bucket=${"Green"}&sellerId=${item.CompanyID}${clickedField && clickedField === "SellerProof" ? `&SellerProof=` + "true" : ""}&FileFor=${encodeURIComponent(item.FileFor)}&selectedPropertyStatus=${"MAPPING-READY TO FILE"}${clickedField && clickedField === "NameProof" ? `&NameProof=` + "true" : ""}${clickedField && clickedField === "TotalProps" ? `&TotalProps=` + "true" : ""}${clickedField && clickedField === "AddressProof" ? `&AddressProof=` + "true" : ""}${clickedField && clickedField === "FEINProof" ? `&FEINProof=` + "true" : ""}${clickedField && clickedField === "HolderProof" ? `&HolderProof=` + "true" : ""}${index !== -1 ? `&state=` + `${encodeURIComponent(filtercriteria.criteria[index].value)}` : ''}`
    } else if (this.props.type === "Grouped By Deal/State" && this.state.activeTab === "Grouped By Deal/State") {
      url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"].CompanyID}${this.state.StateDataSource && this.state.StateDataSource.length > 0 ? `&dataSource=` + encodeURIComponent(this.state.StateDataSource) : ""}${clickedField && clickedField === "Group1Count" ? `&GroupFlag=` + "Group1Flag" : clickedField && clickedField === "Group3Count" ? `&GroupFlag=` + "Group3Flag" : ""}${clickedField && clickedField === "Group1Count" ? `&reportType=` + "SiteConfirmationReport_Group1" : clickedField && clickedField === "Group3Count" ? `&reportType=` + "SiteConfirmationReport_Group3" : clickedField && clickedField === "TotalProperties" ? `&reportType=` + "SiteConfirmationReport_Total" : clickedField && clickedField === "GreenBucketCount" ? `&reportType=` + "SiteConfirmationReport_Green" : ""}&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}${clickedField && clickedField === "Group1Count" ? `&bucket=` + "SP" : clickedField && clickedField === "Group3Count" ? `&bucket=` + "SP" : "&bucket=Green"}`
    } else if (this.props.type === "AvailableToMap" || this.props.redirecteddUrl) {
      let redirectUrl = await this.props.setRedirectUrl(item, clickedField, this.state.postBodyFile)
      url = redirectUrl
    }
    else if (this.props.type === "HighValueProperties") {
      let data = await this.props.getDefaultStatusArray()
      url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item.companyID}${clickedField && clickedField === "propertyID" ? `&propertyId=` + encodeURIComponent(item.propertyID) : clickedField && clickedField === "statePropertyId" ? `&statePropertyId=` + encodeURIComponent(item.statePropertyId) : ""}${clickedField && clickedField === "Group1Count" ? `&bucket=` + "SP" : clickedField && clickedField === "Group3Count" ? `&bucket=` + "SP" : "&bucket=Green"}&reportType=HighValueProperty${`&selectedPropertyStatus=` + encodeURIComponent(this.state.postBodyFile.propertyStatus ? this.state.postBodyFile.propertyStatus : data.statuses)}${this.state.postBodyFile.dataSourceArrayNew && this.state.postBodyFile.dataSourceArrayNew.length > 0 ? `&dataSourceArrayNew=` + encodeURIComponent(this.state.postBodyFile.dataSourceArrayNew) : ""}${this.state.postBodyFile.dataSource && this.state.postBodyFile.dataSource.length > 0 ? `&dataSource=` + encodeURIComponent(this.state.postBodyFile.dataSource) : ""}&maxValueAmount=${this.state.postBodyFile.propertyValue && this.state.postBodyFile.propertyValue.length > 0 ? encodeURIComponent(this.state.postBodyFile.propertyValue) : 50000}&nameMatchScore=${this.state.postBodyFile.propertyValue && this.state.postBodyFile.propertyValue.length > 0 ? encodeURIComponent(this.state.postBodyFile.propertyValue) : 90}${this.state.postBodyFile.propertyReason && this.state.postBodyFile.propertyReason.length > 0 ? `&selectedPropertyReasons=${encodeURIComponent(this.state.postBodyFile.propertyReason ? this.state.postBodyFile.propertyReason : data.reasons)}` : ""}`
    }
    else if (this.props.type === "Grouped By Deal" && this.state.activeTab === "Grouped By Deal") {
      url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}${clickedField && clickedField === "Group1Count" ? `&GroupFlag=` + "Group1Flag" : clickedField && clickedField === "Group3Count" ? `&GroupFlag=` + "Group3Flag" : ""}&sellerId=${item["_id"].CompanyID}${clickedField && clickedField === "Group1Count" ? `&reportType=` + "SiteConfirmationReport_Group1" : clickedField && clickedField === "Group3Count" ? `&reportType=` + "SiteConfirmationReport_Group3" : clickedField && clickedField === "TotalProperties" ? `&reportType=` + "SiteConfirmationReport_Total" : clickedField && clickedField === "GreenBucketCount" ? `&reportType=` + "SiteConfirmationReport_Green" : ""}${this.state.StateDataSource && this.state.StateDataSource.length > 0 ? `&dataSource=` + encodeURIComponent(this.state.StateDataSource) : ""}${clickedField && clickedField === "Group1Count" ? `&bucket=` + "SP" : clickedField && clickedField === "Group3Count" ? `&bucket=` + "SP" : "&bucket=Green"}`
    } else {
      if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
        if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Negative Notice List Viewer") {
          if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
            // url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&bucket=${this.state.activeTab ? this.state.activeTab : "Green"}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + this.state.PropertyData.isexcludeProperties : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.selectedProperty_Id && this.state.PropertyData.selectedProperty_Id.length > 0 ? `&propertyId=` + encodeURIComponent(this.state.PropertyData.selectedProperty_Id) : ""}${this.state.PropertyData.selectedOpraStId && this.state.PropertyData.selectedOpraStId.length > 0 ? `&missingStatePropertyIds&statePropertyId=` + encodeURIComponent(this.state.PropertyData.selectedOpraStId) : ""}${this.state.PropertyData.isexcludeRoyalties ? `&excludeRoyalties=` + "true" : ""}`
            url = `${window.location.protocol}//${window.location.host}/${this.props.type === "Deal Sizing Property Editor" ? "dealproperties" : "nsproperties"}?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&bucket=${this.state.activeTab ? this.state.activeTab : "Green"}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + this.state.PropertyData.isexcludeProperties : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.isexcludeRoyalties ? `&excludeRoyalties=` + "true" : ""}`
            if (this.state.PropertyData.selectedName) {
              url = `${url}&nameId=${encodeURIComponent(this.state.PropertyData.selectedName)}`
            }
            if (filtercriteria.criteria[index] && filtercriteria.criteria[index].value) {
              url = `${url}&state=${encodeURIComponent(filtercriteria.criteria[index].value)}`
            }
            if (filtercriteria.criteria[matchMatchStatusIndex] && filtercriteria.criteria[matchMatchStatusIndex].value) {
              url = `${url}&selectedPropertyStatus=${encodeURIComponent(filtercriteria.criteria[matchMatchStatusIndex].value)}`
            }
            if (filtercriteria.criteria[matchMatchReasonIndex] && filtercriteria.criteria[matchMatchReasonIndex].value) {
              url = `${url}&selectedPropertyReasons=${encodeURIComponent(filtercriteria.criteria[matchMatchReasonIndex].value)}`
            }
            if (this.state.PropertyData.excludeNotConfirmedOnSite) {
              url = `${url}&excludeNotConfirmedOnSite=true`
            }
            if (this.state.PropertyData.taskID) {
              url = `${url}&taskID=${this.state.PropertyData.taskID}`
            }
          } else if (this.state.PropertyData.selectedProperty_Id) {
            url = `${window.location.protocol}://${window.location.host}/nsproperties?propertyId=${this.state.PropertyData.selectedProperty_Id}`
          } else {
            url = `${window.location.protocol}//${window.location.host}/nsproperties`
          }
        } else {
          if (type === "deals") {
            url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}&bucket=${this.state.activeTab}`
          } else {
            url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(this.state.PropertyData.selectedName)}&businessState=${filtercriteria.criteria[index] ? encodeURIComponent(filtercriteria.criteria[index].value) : null}&bucket=${this.state.activeTab}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + this.state.PropertyData.isexcludeProperties : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.selectedProperty_Id && this.state.PropertyData.selectedProperty_Id.length > 0 ? `&propertyId=` + encodeURIComponent(this.state.PropertyData.selectedProperty_Id) : ""}${this.state.PropertyData.selectedOpraStId && this.state.PropertyData.selectedOpraStId.length > 0 ? `&statePropertyId=` + encodeURIComponent(this.state.PropertyData.selectedOpraStId) : ""}`

          }
        }
        Subject = `Properties Sharing for Review`
      } else {//for redirecting to properties from other tables
        if (type === "summary" && item) {
          if (this.props.type === "UP Opportunity") {
            url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"]}&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}&bucket=${clickedField ? clickedField === "green" ? "Green" : "Blue" : "Green"}&ranges=${encodeURIComponent(this.state.summarySelectedranges)}&reportType=${clickedField === "green" ? "UP_OPP_Green" : "UP_OPP_Blue"}`
          } else if (this.props.type === "Ready To File" && this.state.activeTab === "Ready To File") {
            this.setState({ addFilters: [] })
            url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"].CompanyID}&FileFor=${encodeURIComponent(item.FileFor)}&reportType=ReadyToFile&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}${this.state.postBodyFile && this.state.postBodyFile.ConfirmedOnsite ? `&confirmedOnsite=` + encodeURIComponent(this.state.postBodyFile.ConfirmedOnsite) : ""}&bucket=${"All"}&selectedPropertyStatus=${this.props.type === "Ready To File" && this.state.activeTab === "Ready To File" && this.props.ReadyToFileStatus && this.props.ReadyToFileStatus.length > 0 ? encodeURIComponent(this.props.ReadyToFileStatus) : this.props.type === "On Hold" && this.state.activeTab === "On Hold" && this.props.OnHoldStatus && this.props.OnHoldStatus.length > 0 ? encodeURIComponent(this.props.OnHoldStatus) : this.props.type === "Resource Needed" && this.state.activeTab === "Resource Needed" && this.props.ResourceNeededStatus && this.props.ResourceNeededStatus.length > 0 ? encodeURIComponent(this.props.ResourceNeededStatus) : null}${this.state.statusReasonArrayReadyFile && this.state.statusReasonArrayReadyFile.length > 0 ? `&selectedPropertyReasons=${encodeURIComponent(this.state.statusReasonArrayReadyFile)}` : ""}`
          }
          else if (this.props.type === "On Hold" && this.state.activeTab === "On Hold") {
            this.setState({ addFilters: [] })
            url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"].CompanyID}&FileFor=${encodeURIComponent(item.FileFor)}&reportType=OnHold&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}${this.state.postBodyFile && this.state.postBodyFile.ConfirmedOnsite ? `&confirmedOnsite=` + encodeURIComponent(this.state.postBodyFile.ConfirmedOnsite) : ""}&bucket=${"All"}&selectedPropertyStatus=${this.props.type === "Ready To File" && this.state.activeTab === "Ready To File" && this.props.ReadyToFileStatus && this.props.ReadyToFileStatus.length > 0 ? encodeURIComponent(this.props.ReadyToFileStatus) : this.props.type === "On Hold" && this.state.activeTab === "On Hold" && this.props.OnHoldStatus && this.props.OnHoldStatus.length > 0 ? encodeURIComponent(this.props.OnHoldStatus) : this.props.type === "Resource Needed" && this.state.activeTab === "Resource Needed" && this.props.ResourceNeededStatus && this.props.ResourceNeededStatus.length > 0 ? encodeURIComponent(this.props.ResourceNeededStatus) : null}${this.props.statusReasonArrayOnHold && this.props.statusReasonArrayOnHold.length > 0 ? `&selectedPropertyReasons=${encodeURIComponent(this.props.statusReasonArrayOnHold)}` : ""}`
          }
          else if (this.props.type === "CA-N Properties" && this.state.activeTab === "CA-N Properties") {
            this.setState({ addFilters: [] })
            url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"].CompanyID}&HolderName=${item.HolderName}&reportType=CA-N&bucket=${"All"}${this.state.postBodyFile && this.state.postBodyFile.ConfirmedOnsite ? `&confirmedOnsite=` + encodeURIComponent(this.state.postBodyFile.ConfirmedOnsite) : ""}&selectedPropertyStatus=${this.props.type === "CA-N Properties" && this.state.activeTab === "CA-N Properties" && this.props.CANProperties && this.props.CANProperties.length > 0 ? encodeURIComponent(this.props.CANProperties) : this.props.type === "On Hold" && this.state.activeTab === "On Hold" && this.props.OnHoldStatus && this.props.OnHoldStatus.length > 0 ? encodeURIComponent(this.props.OnHoldStatus) : this.props.type === "Resource Needed" && this.state.activeTab === "Resource Needed" && this.props.ResourceNeededStatus && this.props.ResourceNeededStatus.length > 0 ? encodeURIComponent(this.props.ResourceNeededStatus) : null}${this.props.statusReasonArrayCAN && this.props.statusReasonArrayCAN.length > 0 ? `&selectedPropertyReasons=${encodeURIComponent(this.props.statusReasonArrayCAN)}` : ""}`
          }
          else if (this.props.type === "Seller Negative Notice" && this.state.activeTab === "Seller Negative Notice") {
            url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"].CompanyID}&FileFor=${encodeURIComponent(item.FileFor)}&reportType=SellerNegativeNotice&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}${this.state.postBodyFile && this.state.postBodyFile.ConfirmedOnsite ? `&confirmedOnsite=` + encodeURIComponent(this.state.postBodyFile.ConfirmedOnsite) : ""}&bucket=${"All"}&selectedPropertyStatus=${this.props.type === "Seller Negative Notice" && this.state.activeTab === "Seller Negative Notice" && this.props.SellerNegativeNoticeStatus && this.props.SellerNegativeNoticeStatus.length > 0 ? encodeURIComponent(this.props.SellerNegativeNoticeStatus) : this.props.type === "On Hold" && this.state.activeTab === "On Hold" && this.props.OnHoldStatus && this.props.OnHoldStatus.length > 0 ? encodeURIComponent(this.props.OnHoldStatus) : this.props.type === "Resource Needed" && this.state.activeTab === "Resource Needed" && this.props.ResourceNeededStatus && this.props.ResourceNeededStatus.length > 0 ? encodeURIComponent(this.props.ResourceNeededStatus) : null}${this.props.statusReasonArraySellerNegative && this.props.statusReasonArraySellerNegative.length > 0 ? `&selectedPropertyReasons=${encodeURIComponent(this.props.statusReasonArraySellerNegative)}` : ""}`
          }
          else if (this.props.type === "Resource Needed" && this.state.activeTab === "Resource Needed") {
            this.setState({ addFilters: [] })
            url = `${window.location.protocol}//${window.location.host}/nsproperties?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item["_id"].CompanyID}&state=${this.state.summaryObject && this.state.summaryObject.states ? this.state.summaryObject.states : item.SourceState ? item.SourceState : null}&bucket=${"All"}&selectedPropertyStatus=${this.props.type === "Ready To File" && this.state.activeTab === "Ready To File" && this.props.ReadyToFileStatus && this.props.ReadyToFileStatus.length > 0 ? encodeURIComponent(this.props.ReadyToFileStatus) : this.props.type === "On Hold" && this.state.activeTab === "On Hold" && this.props.OnHoldStatus && this.props.OnHoldStatus.length > 0 ? encodeURIComponent(this.props.OnHoldStatus) : this.props.type === "Resource Needed" && this.state.activeTab === "Resource Needed" && this.props.ResourceNeededStatus && this.props.ResourceNeededStatus.length > 0 ? encodeURIComponent(this.props.ResourceNeededStatus) : null}`
          }
          else if (this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap") {
            if (this.props.type === "AvailableToReMap") {
              this.setState({ addFilters: [] })
            }
            let redirectUrl = await this.props.setRedirectUrl(item, clickedField, this.state.postBodyFile)
            url = redirectUrl
          }
          let addFilters = this.state.addFilters
          if (addFilters.length > 0) {
            if (addFilters.includes("isExcludeRoyalties")) {
              url = `${url}&excludeRoyalties=true`
            } if (addFilters.includes("excludeCoOwners")) {
              url = `${url}&excludeCoOwners=true`
            } if (addFilters.includes("isexcludePossiblyPaid")) {
              url = `${url}&isexcludePossiblyPaid=true`
            }
          }
        } else if (type === "deals") {
          url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}`
        }
      }
    }
    await this.setState({
      PropertySharebody: url,
      ProertyShareSubject: Subject,
      customerEmail: ""
    })
  }


  copyToClipboardProperties = async () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(this.state.uppropPropertyIds.join("\n"))
    }

  }
  copyToClipboardIndividual = async (id) => {
    let notUpArray = this.state.notUpdateArray
    let z = notUpArray.find(elem => elem.id === id)
    if (z) {
      z.clicked = true
    }
    await this.setState({ notUpdateArray: notUpArray })
    return (navigator && navigator.clipboard ? navigator.clipboard.writeText(id) : null)

  }
  openClaimSummary = async () => {
    let newArray = this.checkDataisEdited()
    if (newArray && newArray.length > 0) {
      this.OpenConfirmaionModelorSave()
    } else {
      await this.setState({ openClaimSummary: true })
    }
  }

  copyToClipboard = async () => {
    let filtercriteria = this.state.filterCriteria
    let url, index, matchMatchStatusIndex, matchMatchReasonIndex;
    matchMatchStatusIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
    matchMatchReasonIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")

    // }
    if (this.props.type === "Deal Sizing-Properties") {
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState")
    } else {
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState")
    }
    if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor") {
      await this.getUrlbyParams()
      url = this.state.PropertySharebody
    } else {
      if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
        url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&bucket=${this.state.activeTab}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + this.state.PropertyData.isexcludeProperties : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.selectedProperty_Id && this.state.PropertyData.selectedProperty_Id.length > 0 ? `&propertyId=` + encodeURIComponent(this.state.PropertyData.selectedProperty_Id) : ""}${this.state.PropertyData.selectedOpraStId && this.state.PropertyData.selectedOpraStId.length > 0 ? `&statePropertyId=` + encodeURIComponent(this.state.PropertyData.selectedOpraStId) : ""}`
        if (filtercriteria.criteria[index] && filtercriteria.criteria[index].value) {
          url = `${url}&state=${encodeURIComponent(filtercriteria.criteria[index].value)}`
        }
        if (filtercriteria.criteria[matchMatchStatusIndex] && filtercriteria.criteria[matchMatchStatusIndex].value) {
          url = `${url}&selectedPropertyStatus=${encodeURIComponent(filtercriteria.criteria[matchMatchStatusIndex].value)} `
        }
        if (filtercriteria.criteria[matchMatchReasonIndex] && filtercriteria.criteria[matchMatchReasonIndex].value) {
          url = `${url}&selectedPropertyReasons=${encodeURIComponent(filtercriteria.criteria[matchMatchReasonIndex].value)} `
        }
      } else {
        url = `${window.location.protocol}://${window.location.host}/dealproperties?propertyId=${this.state.PropertyData.selectedProperty_Id}`
      }
    }
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(url)
    }
    await this.setState({ copiedA: true })
  }
  PropertyIdDisplay(rowData, column) {

    // var src = "showcase/demo/images/car/" + rowData.brand + ".png";
    return <span>{rowData}</span>
  }
  copyTemplate = (rowData, column) => {
    let z
    let notUpArray = this.state.notUpdateArray
    if (notUpArray) {
      z = notUpArray.find(elem => elem.id === rowData)
    }

    return (
      <div className='' >
        <FontAwesomeIcon
          icon={faCopy}
          className='ml-2'
          data-toggle="tool-tip" title={("Copy")}
          // onClick={() => navigator.clipboard.writeText(rowData)}
          onClick={() => { this.copyToClipboardIndividual(rowData) }}
          style={{ width: 18, color: z && z.clicked ? "blue" : "black" }}
        />
      </div>
    )
  }
  getClaimSummaryField() {
    return (
      <div className='row mb-4 d-flex justify-content-end' >


        <div>
          <Button color="primary"
            className='p-1 py-0 mb-0 mt-1' style={{ height: "38px" }}
            size={'sm'} onClick={this.getDataToExport}
            outline
            disabled={this.state.totalRecordsLength === 0 || this.state.isexported ? true : false}
          >
            <FontAwesomeIcon
              icon='download'
              data-toggle="tool-tip" title={"Export To CSV"}
              className='pl-1' size='lg' />
          </Button>
          <Button color="primary"
            size="sm"
            outline
            style={{ height: 30 }}
            className="p-1 ml-2 mt-2 mb-1"
            onClick={() => this.getremoveFilters()}>Clear Column Filters
          </Button>
          <CSVLink
            data={this.state.exportData}
            headers={this.state.exportHeaders}
            filename={this.props.tabOptions ? `${this.state.activeTab}-${this.props.type}.csv` : `${this.props.type}.csv`}
            className="hidden text-gray"
            ref={(r) => this.csvLinkRef = r}
            target="_blank" >
          </CSVLink>
        </div>

      </div>
    )
  }

  getNegativeNoticeSellers = () => {
    if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
      let url = `NsScoredProperties/GetNegativeNotice?estateName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&estateID=${this.state.PropertyData.selectedSeller.EstateID}`
      fetchMethodRequest('GET', url)
        .then(async (response) => {
          if (response && response.dimSellers && response.negativeNotice !== null) {
            await this.setState({
              redirectSellerId: response.dimSellers[0].dimSellerID,
              negativePeriod: response.negativeNotice.noticePeriod
            })
          } else if (response && response['errorMessage'] === configMessages.warningMessage) {
            await this.setState({
              redirectSellerId: null,
              negativePeriod: null
            })
          } else {
            await this.setState({
              redirectSellerId: null,
              negativePeriod: null
            })
          }
        }).catch((err) => {
          return err;
        });
    }
  }
  getAccordionHeader() {
    let isEditted = localStorage.getItem("ISEdited") ? true : false
    return (

      <span className='d-flex'>
        <div>
          <span>Editor</span>
          <span style={{ marginLeft: "40px", color: this.state.errorcolor }}>{this.state.errorResponse ? this.state.errorMessage : ""}</span>
        </div>
        {/* <div className='d-flex ml-auto'>
          <>{this.state.PropertyData && this.state.PropertyData.selectedSeller ?
            <div className='mt-2 mr-2'>

              <Dropdown
                value={this.state.createTask}
                // appendTo={document.body}
                placeholder={"Create"}
                disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                style={{ borderLeft: "1px solid lightgray", height: 34 }}
                options={[{ label: "New Claim", value: "New Claim" }, { label: "Research Request", value: "Research Request" }, { label: "Seller Negative Notice", value: "Seller Negative Notice" }, { label: "General Task", value: "General Task" }, { label: "Site Confirmation", value: "Site Confirmation" },]}
                onChange={(e) => this.changeCreateTask(e, "add")}
              />
            </div> : null}</>
          <>{this.state.PropertyData && this.state.PropertyData.selectedSeller ?
            <div className='mt-2 mr-2'>

              <Dropdown
                value={this.state.createTaskAdd}
                // appendTo={document.body}
                placeholder={"Add To"}
                disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                style={{ borderLeft: "1px solid lightgray", height: 34 }}
                options={[{ label: "Existing Claim", value: "Existing Claim" }, { label: "Research Request", value: "Research Request" }, { label: "Seller Negative Notice", value: "Seller Negative Notice" }, { label: "General Task", value: "General Task" }, { label: "Site Confirmation", value: "Site Confirmation" }]}
                onChange={(e) => this.changeCreateTask(e, "addToTask")}
              />
            </div> : null}</>
         

          <>{this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.props.getModaltoCreateProperty : null}</>
          {this.props.type === "ClaimsManagement" ? null :
            <a className="lnr lnr-envelope shareIcon" onClick={() => this.getUrlbyParams()} href={`mailto:${this.state.customerEmail}?subject=${this.state.ProertyShareSubject}&body=Hi%0APlease%20review%20these%20properties.%0A%0A${encodeURIComponent(this.state.PropertySharebody)}%0A%0A%0AThanks%0AOPRA2%0Aopra@oakpointpartners.com`} >
            </a>
          }
          {this.props.type === "Property Editor" ? <span><Button color="primary"
            className='p-1 py-0 mb-0 mt-1' style={{ height: "38px" }}
            size={'sm'} onClick={this.handleSelectedAction}
            outline
            disabled={!this.props.apidataforExport && !this.state.apiUrlforExport ? true : false}
          >
            <FontAwesomeIcon
              icon='download'
              data-toggle="tool-tip" title={"Export To excel"}
              className='pl-1' size='lg' />
          </Button>
            {this.getExcelDownload()}</span>
            : null}
          
          <Button color="primary"
            size="sm"
            outline
            style={{ height: 30 }}
            className="p-1 ml-2 mt-2 mb-1"
            disabled={isEditted}
            onClick={() => this.getremoveFilters()}>Clear Col. Filters
          </Button>
          <div>
            <div class="btn-group-vertical tooltipClaims" style={{ marginRight: "0px", height: "30px" }} onClick={(e) => this.op.toggle(e)} data-toggle="tool-tip"
              title="click to see definations" >
              <div className=''
                style={{ display: "block" }}
                data-toggle="tool-tip"
                title={"Click to see info"}
              >
                <span ><FontAwesomeIcon
                  style={{ fontSize: "20px", float: "left" }}
                  icon={faClipboardList}
                  className='mr-1'
                  // onClick={() => this.props.openTableModel(item, this.state.selectedSeller)}
                  color={"#354f6e"}
                /></span>
                <span><FontAwesomeIcon
                  style={{ fontSize: "20px", float: "left" }}
                  icon={faClipboardList}
                  className='mr-1'
                  // onClick={() => this.props.openTableModel(item, this.state.selectedSeller)}
                  color={"brown"}
                /></span>
              </div>
            </div>
            <OverlayPanel appendTo={document.body} ref={(el) => this.op = el} showCloseIcon={true} dismissable={true}>
              <p><span><FontAwesomeIcon
                style={{ fontSize: "20px", float: "left" }}
                icon={faClipboardList}
                className='mr-2'
                color={"brown"}
              /></span>Property has a connected Claim and also may be connected Task</p>
              <p><span><FontAwesomeIcon
                style={{ fontSize: "20px", float: "left" }}
                icon={faClipboardList}
                className='mr-2'
                color={"#354f6e"}
              /></span>Property has only connected task</p>

            </OverlayPanel>
          </div>
          {this.props.type === "ClaimsManagement" ? null :
            <div style={this.state.loginCredentials && this.state.loginCredentials.roleName === "Admin" ? { display: "block" } : { display: "none" }}>
              <img
                src={configImages.ResearchIcon}
                onClick={this.setPropertyTrailViewerModal}
                style={{ margin: 0, height: '40px', width: '40px' }}
              />
            </div>
          }
          {this.state.PropertyData && this.state.PropertyData.selectedSeller ?
            <div style={this.state.loginCredentials && (this.state.loginCredentials.roleName === "Admin" || this.state.loginCredentials.roleName === config.roleNames.coreOpsManager) ? { display: "block" } : { display: "none" }}>
              <Button //color="primary"
                size="sm"
                // outline
                style={{ backgroundColor: "white", margin: 0, padding: "0px!important", border: 0 }}>
                <img
                  src={configImages.ValidatedPropertyUpdaterIcon}
                  onClick={this.setValidatedPropertyUpdaterModal}
                  style={{ borderRadius: 0, margin: 0, height: '40px', width: '40px' }}
                />
              </Button>
            </div> : ""
          }
        </div> */}
      </span>
    )
  }

  isCloseClaimfromPE() {
    this.setState({
      createTask: "",
      createTaskAdd: ""
    })
  }

  getFilerField() {
    let settingsData = this.props.settingsData
    let isEditted = localStorage.getItem("ISEdited") ? true : false
    return (
      <div className='mb-4'>
        {
          this.props.type === "Deal Sizing Report For Companies" ?
            this.props.getTableForDSReport
            : <div className="" style={{ marginTop: 10 }}>
              {/* {this.state.totalRecordsLength > 10000 || this.state.totalRecordsLength === 0 ? */}
              <div className='d-flex'>
                {/* <span>Records:{this.state.totalRecordsLength}</span> */}
                <span className='ml-2 errorColor' style={(this.state.totalRecordsLength > 1000 && this.props.type === "Properties") || this.state.totalRecordsLength > 10000 && (this.props.type === "Property Editor" && this.state.activeTab !== "SP" || this.props.type === "Deal Sizing Property Editor") || this.state.showErrorMessage && this.props.type !== "Negative Notice List Viewer" ? { display: 'flex' } : { display: 'none' }}>
                  Please note the count of records need to be less than {this.props.type === "Properties" ? 1000 : 10000}, for you to see results.  Please select additional filter criteria and click Go.
                </span></div>
              {/* : */}
              <>
                <span className={this.props.type !== "Deal Sizing-Properties" ? "d-block" : "d-none"}>
                  <Accordion multiple={true} activeIndex={[]}>
                    <AccordionTab header={this.getAccordionHeader()}>
                      <div className='row' style={{ display: 'flex' }}>
                        {this.props.type !== "Deal Sizing Property Editor"?this.getEditorComponent():this.getEditorComponentforDealSizing()}

                        {/* : null} */}
                      </div>
                    </AccordionTab>
                  </Accordion></span>
                <div style={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "ClaimsManagement" || this.props.type === "ClaimSummary" ? { display: "block" } : { display: "none" }} >
                  <div style={{ width: "100%" }}>
                    <div className="m-auto ml-0 mb-4" style={{ color: "red", textAlign: "center" }}>{this.state.redirectSellerId !== null && this.state.negativePeriod !== null ? <span>This deal has negative notice period of<a className='px-1'
                      href={config.negativeNoticeUrl} target="_blank"><u>{this.state.negativePeriod}</u></a></span> : null}
                    </div>
                    <Nav tabs >
                      {
                        this.props.type !== "ClaimsManagement" && this.state.propertyEditorTabs && this.state.propertyEditorTabs.length > 0 ? this.state.propertyEditorTabs.map((item, index) => {
                          return (
                            <NavItem key={index}>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === item.name })}
                                onClick={() => { this.toggletab(item.name); }}
                              >
                                <span data-toggle="tool-tip" title={item.tooltip ? item.tooltip : item.name} style={this.state.isDisabledTab ? { color: "lightgray" } : { color: item.color, }}>{item.name}({this.state.Counts ? this.state.Counts[item.name] ? this.state.Counts[item.name] : 0 : 0})</span>
                              </NavLink>
                            </NavItem>
                          )
                        }) : null
                      }
                      <div style={{ margin: "auto", color: this.state.errorcolor }}>
                        {this.state.showSelectederror ? this.state.errormsg : null}
                      </div>
                      <div style={{ margin: "auto", color: "green" }}>
                        {this.state.columnOrderError ? this.state.columnOrderMsg : null}
                      </div>
                      <div style={{ margin: "auto", color: "green" }}>
                        {this.state.copiedA ? "Link Copied Successfully." : null}
                        {/* {this.state.propertyData&&!this.state.propertyData.select ? "Link Copied Successfully." : null} */}
                      </div>
                      <>{this.state.PropertyData && this.state.PropertyData.selectedSeller && this.props.type === "Property Editor" ?
                        <div className='mt-2 mr-2'>

                          <Dropdown
                            value={this.state.createTask}
                            // appendTo={document.body}
                            placeholder={"Create"}
                            disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                            style={{ borderLeft: "1px solid lightgray", height: 34 }}
                            options={this.state.propertyEditorCreateOptions}
                            onChange={(e) => this.changeCreateTask(e, "add")}
                          />
                        </div> : null}</>
                      <>{this.state.PropertyData && this.state.PropertyData.selectedSeller && this.props.type === "Property Editor" ?
                        <div className='mt-2 mr-2'>

                          <Dropdown
                            value={this.state.createTaskAdd}
                            // appendTo={document.body}
                            placeholder={"Add To"}
                            disabled={this.state.selectedRows && this.state.selectedRows.length > 0 ? false : true}
                            style={{ borderLeft: "1px solid lightgray", height: 34 }}
                            options={this.state.propertyEditorExistingOptions}
                            onChange={(e) => this.changeCreateTask(e, "addToTask")}
                          />
                        </div> : null}</>
                      {/* <>
                      {this.state.PropertyData && this.state.PropertyData.selectedSeller ?
                        <div className='mt-2 mr-1'>

                          <Button
                            color='primary'
                            className="mb-0"
                            outline
                            style={{ height: "34px" }}
                            disabled={this.state.selectedRows && this.state.selectedRows.length > 0 && this.state.createTask ? false : true}
                            // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                            onClick={() => this.createTask()}
                          >
                            Create Task
                          </Button>
                        </div> : null}</> */}
                      {/* <>{this.state.PropertyData && this.state.PropertyData.selectedSeller ?
                        <div className='mt-2'>

                          <Button
                            color='primary'
                            className="mb-0"
                            outline
                            style={{ height: "34px" }}
                            disabled={this.state.selectedRows && this.state.selectedRows.length > 0 && this.state.createTask ? false : true}

                            // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                            onClick={() => this.addToTask()}
                          >
                            Add To Task
                          </Button>
                        </div> : null}</> */}


                      <>{this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.props.getModaltoCreateProperty : null}</>

                      {/* {this.state.showSelectederror ? */}
                      {/* {this.props.type === "ClaimsManagement" ? null : <FontAwesomeIcon icon={faCopy}
                        className='ml-4'
                        color='#354f6e'
                        data-toggle="tool-tip"
                        // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                        onClick={() => { this.copyToClipboard() }}
                        title={"Copy"}
                        style={{ width: 18, marginTop: "20px", fontSize: "19px" }}
                      />} */}
                      {this.props.type === "ClaimsManagement" ? null :
                        <a className="lnr lnr-envelope shareIcon" onClick={() => this.getUrlbyParams()} href={`mailto:${this.state.customerEmail}?subject=${this.state.ProertyShareSubject}&body=Hi%0APlease%20review%20these%20properties.%0A%0A${encodeURIComponent(this.state.PropertySharebody)}%0A%0A%0AThanks%0AOPRA2%0Aopra@oakpointpartners.com`} >
                        </a>
                      }
                      {this.props.type === "ClaimsManagement" ?
                        <Button color="primary" outline
                          className='p-1 ml-2 mt-2 mb-1'
                          size={'sm'}
                          style={{ height: 30 }}
                          disabled={isEditted}
                          onClick={() => this.openClaimSummary()}>
                          <FontAwesomeIcon
                            icon=""
                            data-toggle="tool-tip" title={"Save"}
                            className='pl-1' size='lg' />{this.props.type === "ClaimsManagement" ? "Claim Summary" : "Save"}
                        </Button>
                        : null}
                      {this.props.type === "ClaimsManagement" ?
                        <Button color="primary" outline
                          className='p-1 ml-2 mt-2 mb-1'
                          size={'sm'}
                          style={{ height: 30 }}
                          onClick={() => this.handleCheckValidations()}>
                          <FontAwesomeIcon
                            icon={faSave}
                            data-toggle="tool-tip" title={"Save"}
                            className='pl-1' size='lg' />{this.props.type === "ClaimsManagement" ? "Save Claim" : "Save"}
                        </Button>
                        : null}

                      {this.props.type === "ClaimsManagement" ?
                        <Button color="danger" outline
                          className='p-1 ml-2 mt-2 mb-1'
                          size={'sm'}
                          style={{ height: 30 }}
                          onClick={() => this.CancelMultiLine('isCancel')}>
                          <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            data-toggle="tool-tip" title={"Cancel"}
                            className='pl-1' size='lg' />{this.props.type === "ClaimsManagement" ? "Cancel Claim Edit" : "Cancel"}
                        </Button>
                        : null}
                      {this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" ? <span><Button color="primary"
                        className='p-1 py-0 mb-0 mt-1' style={{ height: "38px" }}
                        size={'sm'} onClick={this.handleSelectedAction}
                        outline
                        disabled={!this.props.apidataforExport && !this.state.apiUrlforExport ? true : false}
                      >
                        <FontAwesomeIcon
                          icon='download'
                          data-toggle="tool-tip" title={"Export To excel"}
                          className='pl-1' size='lg' />
                      </Button>
                        {this.getExcelDownload()}</span>
                        : null}
                      {this.props.type === "ClaimsManagement" ? null :
                        <CSVLink
                          data={this.state.exportData}
                          headers={this.state.exportHeaders}
                          filename={`${this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.state.PropertyData.selectedSeller.EstateName : this.props.type}-${this.state.activeTab}.csv`}
                          className="hidden text-gray p-0"
                          ref={(r) => this.csvLinkRef = r}
                          target="_blank" >
                        </CSVLink>}
                      <Button color="primary"
                        size="sm"
                        outline
                        style={{ height: 30 }}
                        className="p-1 ml-2 mt-2 mb-1"
                        disabled={isEditted}
                        onClick={() => this.getremoveFilters()}>Clear Col. Filters
                      </Button>
                      <div>
                        {this.props.type === "Property Editor" ? <div class="btn-group-vertical tooltipClaims" style={{ marginRight: "0px", height: "30px" }} onClick={(e) => this.op.toggle(e)} data-toggle="tool-tip"
                          title="click to see definations" >
                          <div className=''
                            style={{ display: "block" }}
                            data-toggle="tool-tip"
                            title={"Click to see info"}
                          >
                            <span ><FontAwesomeIcon
                              style={{ fontSize: "20px", float: "left" }}
                              icon={faClipboardList}
                              className='mr-1'
                              // onClick={() => this.props.openTableModel(item, this.state.selectedSeller)}
                              color={"#354f6e"}
                            /></span>
                            <span><FontAwesomeIcon
                              style={{ fontSize: "20px", float: "left" }}
                              icon={faClipboardList}
                              className='mr-1'
                              // onClick={() => this.props.openTableModel(item, this.state.selectedSeller)}
                              color={"brown"}
                            /></span>
                          </div>
                        </div> : null}
                        {this.props.type === "Property Editor" ? <OverlayPanel appendTo={document.body} ref={(el) => this.op = el} showCloseIcon={true} dismissable={true}>
                          <p><span><FontAwesomeIcon
                            style={{ fontSize: "20px", float: "left" }}
                            icon={faClipboardList}
                            className='mr-2'
                            color={"brown"}
                          /></span>Property has a connected Claim and also may be connected Task</p>
                          <p><span><FontAwesomeIcon
                            style={{ fontSize: "20px", float: "left" }}
                            icon={faClipboardList}
                            className='mr-2'
                            color={"#354f6e"}
                          /></span>Property has only connected task</p>
                        </OverlayPanel>:null}
                        <OverlayPanel className="overlay-left" style={{ width: "1050px"}} appendTo={document.body} ref={(el) => this.openData = el} showCloseIcon={true} dismissable={true}>
                          {/* <span>{this.state.selectedRecordChildid}</span> */}
                          <DataTable value={this.state.myArray} >
                            {this.state.parentTableFields.map((col, i) => (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                body={this.changeFieldValues}
                                bodyStyle={{
                                  textOverflow:  'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: col.field==="propertyName"||col.field==="propertyAddressLine1"?'Iinitial':'nowrap',
                                  fontSize:  12,
                                  width:col.width,
                                  textAlign: "left",
                                  verticalAlign:  "inherit",
                                  // display: item.fieldType === "rowEditor" ? "inline-flex" : "block"
                                }
                                }
                              />
                            ))}
                          </DataTable>
                        </OverlayPanel>
                      </div>
                      {this.props.type === "ClaimsManagement" ? null :
                        <div style={this.state.loginCredentials && this.state.loginCredentials.roleName === "Admin" ? { display: "block" } : { display: "none" }}>
                          <img
                            src={configImages.ResearchIcon}
                            onClick={this.setPropertyTrailViewerModal}
                            style={{ margin: 0, height: '40px', width: '40px' }}
                          />
                        </div>
                      }
                      {this.state.PropertyData && this.state.PropertyData.selectedSeller ?
                        <div style={this.state.loginCredentials && (this.state.loginCredentials.roleName === "Admin" || this.state.loginCredentials.roleName === config.roleNames.coreOpsManager) ? { display: "block" } : { display: "none" }}>
                          <Button //color="primary"
                            size="sm"
                            // outline
                            style={{ backgroundColor: "white", margin: 0, padding: "0px!important", border: 0 }}>
                            <img
                              src={configImages.ValidatedPropertyUpdaterIcon}
                              onClick={this.setValidatedPropertyUpdaterModal}
                              style={{ borderRadius: 0, margin: 0, height: '40px', width: '40px' }}
                            />
                          </Button>
                        </div> : ""
                      }
                    </Nav>
                  </div>
                </div>
              </>
              {/* } */}
            </div>
        }
      </div >
    )
  }
  //to remove datatable all Filters 
  getremoveFilters = async (type) => {
    let newArray = this.checkDataisEdited()
    if (newArray && newArray.length > 0) {
      this.OpenConfirmaionModelorSave()
    } else {
      await this.setState({
        clearFilters: true,
        page: 1,
        isLoading: this.props.type !== "OpenCorporateSearch" ? true : false,
        first: 0
      })
      let filteredNo = this.props.type === "Cron Job" ? document.getElementsByClassName("p-column-filter").length : this.state.tablefieldsToShow.length
      for (let i = 0; i <= filteredNo; i++) {
        if (document.getElementsByClassName("p-column-filter")[i] && document.getElementsByClassName("p-column-filter")[i].value)
          if (document.getElementsByClassName("p-column-filter")[i].value !== "") {
            let getName = document.getElementsByClassName("p-column-filter")[i].name
            document.getElementsByClassName("p-column-filter")[i].value = ""
            if (document.getElementsByClassName(`p-column-filter p-column-${getName}`).value) {
              document.getElementsByClassName(`p-column-filter p-column-${getName}`).value = ""
            }
            this.setState({
              [getName]: ""
            })
          }
        if (document.getElementsByClassName("p-column-filterMode")[i]) {
          document.getElementsByClassName("p-column-filterMode")[i].selectedIndex = 0
        }
        if (document.getElementsByClassName("p-column-filter p-column-DWDateInserted") && document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0] && document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0].value) {
          document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0].value = ""
        }
        this.setState({
          hasPropertyCoOwner: "",
          propertyStatus: "",
          propertyStatusReason: "",
          confirmedOnsite: "",
          effectiveFromDate: "",
          reportedMissing: "",
          propertyStatus: "",
          matchMatchStatus: "",
          matchStatusReason: "",
          propertyReason: "",
          ocFilters: []
        })
        if (document.getElementsByClassName("p-column-filterComponent")[i] && document.getElementsByClassName("p-column-filterComponent")[i].style.display === "block") {
          document.getElementsByClassName("p-column-filterComponent")[i].style.display = "none"
        }
      }
      // if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt")&&())

      this.setState({
        hasPropertyCoOwner: "",
        confirmedOnsite: ""
      })

      //removing inline filters
      let filterCriteria = this.state.filterCriteria
      var newArr = filterCriteria["criteria"].filter(object => {
        return object.add_Filter !== false;
      });
      filterCriteria["criteria"] = newArr
      filterCriteria["page"] = 1
      if (this.props.type === "ClaimSummary") {
        filterCriteria.sortfield = this.state.clearFiltersGroupBy
      }
      if (filterCriteria.globalSearch && filterCriteria.globalSearch.value && type) {
        delete filterCriteria.globalSearch
      }

      if (this.props.type !== "UP Opportunity" && this.props.type !== "State Attributes" && !type) { this.getDataFromServer(filterCriteria, null, null) } else if (this.props.type === "UP Opportunity" && !type) {
        await this.setState({
          filterCriteria: filterCriteria
        })
        this.getUsersSummaryData(this.state.summaryObject, true)
      }
    }
  }

  setPropertyTrailViewerModal = async () => {
    await this.setState({
      PropertyTrailViewerModal: true
    })
  }

  closePropertyTrailViewerModal = async () => {
    await this.setState({
      PropertyTrailViewerModal: false
    })
  }

  getPropertyTrailViewerModal() {
    return (
      <PropertyTrailViewerModal
        openPropertyTrailViewerModal={this.state.PropertyTrailViewerModal}
        closePropertyTrailViewerModal={this.closePropertyTrailViewerModal} />
    )
  }

  setValidatedPropertyUpdaterModal = async () => {
    await this.setState({
      ValidatedPropertyUpdaterModal: true
    })
  }

  closeValidatedPropertyUpdaterModal = async () => {
    await this.setState({
      ValidatedPropertyUpdaterModal: false
    })
  }

  getValidatedPropertyUpdaterModal() {
    return (
      <ValidatedPropertyUpdaterModal
        estate={this.state.PropertyData.selectedSeller}
        openValidatedPropertyUpdaterModal={this.state.ValidatedPropertyUpdaterModal}
        closeValidatedPropertyUpdaterModal={this.closeValidatedPropertyUpdaterModal} />
    )
  }
  // changeSelectedButtonForAvailableMap = async (selectedButtonForAvailableMap) => {
  //   let filterCriteria = this.state.filterCriteria
  //   let index = filterCriteria["criteria"].findIndex(obj => obj.key === "StatusCategory")
  //   if (index !== -1) {
  //     filterCriteria["criteria"].splice(index, 1)

  //   }

  //   if (selectedButtonForAvailableMap && this.props.type === "AvailableToMap") {
  //     if (filterCriteria && filterCriteria.criteria) {
  //       filterCriteria.criteria.push({ "key": "StatusCategory", "value": [selectedButtonForAvailableMap], "type": "in" })
  //     }
  //     // filterCriteria.criteria = [{ "key": "StatusCategory", "value": [selectedButtonForAvailableMap], "type": "in" }]
  //   }
  //   await this.setState({ selectedButtonForAvailableMap: selectedButtonForAvailableMap, filterCriteria: filterCriteria })
  //   await this.getDataFromServer(filterCriteria, this.state.tabType, "", this.state.postBody);

  // }

  handleOngoing = async () => {
    await this.setState({
      onGoing: true
    })
    this.getDataToExport()
  }

  getTotalCard() {
    if (this.state.viewType === 'list') {
      return (
        <Card style={this.props.type === "ClamisManagement" ? { paddingBottom: "10px", paddingTop: "15px", margin: 0 } : { paddingBottom: "10px", paddingTop: "0px", margin: 0 }} className={!this.props.className ? "customCard cardForListMargin m-0" : "cardForListMargin m-0"}>
          <CardBody className={this.props.type ? 'tableCardBody' : 'modalTablePadding'} style={{ borderRadius: "0px" }}>
            {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal' ? this.getHeader() : null}
            {this.getTabInfo()}
            {this.props.isCustomHeader ? this.props.getHeaderforSchedule() : ""}
            {this.props.type === "Name Lookup" || this.props.type === "Address Lookup" ? this.getNamesLookupFilters() : ""}
            {this.props.type === 'Regular Scoring Queue' ?

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                < Button
                  color="primary"
                  size="sm"
                  outline
                  // disabled={true/*this.props.type === "crMatrix" && item.Answers > 0 ? true : false*/}
                  className="mb-0 m-1 ml-0 p-1"
                  style={{ width: "170px" }}
                  onClick={() => this.handleOngoing()}
                >
                  <div style={{ alignSelf: "center" }}>
                    {'OnGoing Queue List'}
                  </div>
                </Button>
                {<CSVLink
                  data={this.state.exportData}
                  headers={this.state.exportHeaders}
                  filename={this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties" || this.props.type === "Resource Needed" ? `Available To File-${this.props.type}.csv` : this.props.tabOptions ? `${this.state.activeTab}-${this.props.type}.csv` : `${this.props.type}.csv`}
                  className="hidden text-white p-0"
                  ref={(r) => this.csvLinkRef = r}
                  target="_blank" >
                </CSVLink>}
                <span className='float-right pt-2'>
                  <div className='col-12 px-0 pb-1'>
                    {!this.state.isOpenFormModal ? this.getPaginator() : null}
                  </div>
                </span></div> : ""}
            {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen'
              ? this.getDataTable()
              : this.state.displayViewOfForm === 'modal' && this.state.isShowTable ?
                this.getDataTable() : null}
            {/* {this.state.createclaimfromPE ? this.getClaimsDetails() : null} */}
            {this.state.isOpenFormModal && (this.props.type !== "List View" || this.props.type !== "Research Request Viewer" || this.props.type !== "Seller Negative Notice Viewer") ? this.getFormModal() : null}
            {this.props.type !== 'Regular Scoring Queue' ? <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {!this.state.isOpenFormModal ? this.getPaginator() : null}
              </div>
            </span> : ""}
          </CardBody>
        </Card >

      )
    } else if (this.state.viewType === 'grid') {
      return (
        <div>
          <Card
            className={' pb-2 cardForGridMargin'}>
            <CardBody className='tableCardBody pb-0' style={{ borderRadius: "0px" }}>
              {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal' ? this.getHeader() : null}
              {this.getTabInfo()}
              {this.state.isOpenFormModal ? this.getFormModal() : null}
            </CardBody>
          </Card>
          {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getGridView() : this.state.displayViewOfForm === 'modal' ? this.getGridView() : null}
        </div>
      )
    }
  }

  getTabTypeCard() {
    const { tabOptions } = this.props
    let subTabs = []

    if ((this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap") && tabOptions && tabOptions.length > 0) {
      let z = tabOptions.find(elem => elem.name === this.state.activeTab)
      if (z && z.subTabs) {
        //with z values hit the api call
        // subTabs = z.subTabs
        for (let sub of z.subTabs) {
          subTabs.push({ label: sub.label, value: sub.label })
        }
      }
    }

    return (
      <Card style={{ paddingBottom: "10px", paddingTop: "5px", margin: 0 }} className={!this.props.className ? "customCard cardForListMargin m-0" : "cardForListMargin m-0"}>
        <CardBody className='tableCardBody' style={{ borderRadius: "0px", paddingTop: '5px' }}>
          {this.props.type === "Deal Statistics" ? this.getdealFilters() : ""}
          {this.props.type === "FactSet" ? this.getFactSetFilters() : ""}
          {this.props.type === "UP Opportunity – V2" ? this.getpropertySummaryFilters() : ""}
          {this.props.type === "Required Evidence" ? this.getpropertySummaryFilters() : ""}
          {this.props.type === "AvailableToMap" ? this.getAvailableToMapFilters() : ""}
          {this.props.type === "AvailableToReMap" ? this.getAvailableToReMapFilters() : ""}
          {this.props.type === "PropertyLoader" ? this.getPropertyLoaderFilters() : ""}
          {this.props.type === "PropertySearchv2" ? this.getPropertySearchV2Filters() : ""}



          <div style={{ width: "100%" }}>
            <Nav tabs >
              {
                tabOptions && tabOptions.length > 0 ? tabOptions.map((item, index) => {
                  let tabName = item && item.name ? item.name : item
                  return (
                    <NavItem style={{ width: "max-content" }} className='mr-5'>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === tabName })}
                        disabled={this.state.isLoading ? true : false}
                        onClick={() => { this.getSelectedTabTypeInfo(item, index); }}
                      >
                        <h4>{item.name ? item.name : item === "Ready To File" ? `Ready To File (BK > $${this.props.settingsData && this.props.settingsData.availableToFileThreshold && this.props.settingsData.availableToFileThreshold.bk ? this.props.settingsData.availableToFileThreshold.bk : null} , CR > $${this.props.settingsData && this.props.settingsData.availableToFileThreshold && this.props.settingsData.availableToFileThreshold.cr ? this.props.settingsData.availableToFileThreshold.cr : null} )` : item}</h4>
                      </NavLink>
                    </NavItem>
                  )
                }) : null
              }

            </Nav>
            {/* {this.props.type === "AvailableToMap" && this.state.activeTab !== "High Name Score" && this.state.activeTab !== "High Address Score" ?
              <SelectButton className='ml-2 mt-2' value={this.state.selectedButtonForAvailableMap} options={[{ label: "Mapped/Filed/Paid", value: "Mapped/Filed/Paid" }, { label: "Mapping-Filing Hold", value: "Mapped-Filing Hold" }, { label: "Mapping-Filing Block", value: "Mapping-Filing Block" }]}
                onChange={(e) => this.changeSelectedButtonForAvailableMap(e.value)} />
              : null} */}

            <p className="topbar__center topBarImageAlignment sdfsds" style={{ color: this.state.errorcolor === "green" ? "green" : "red" }} >{this.state.errorResponse ? this.state.errorMessage : null}</p>

            {this.state.activeTab === 'Document Upload' ?
              <DocumentUpload
                names={this.props.names}
                relSellerNames={this.props.relSellerNames}
                stateClaimIds={this.props.stateClaimIds}
                setDocumentsDropdownsData={this.props.setDocumentsDropdownsData}
              /> :
              this.state.activeTab === 'Available Documents' ?
                <AvailableDocumentsPage
                  names={this.props.names}
                  relSellerNames={this.props.relSellerNames}
                  stateClaimIds={this.props.stateClaimIds}
                  setDocumentsDropdownsData={this.props.setDocumentsDropdownsData}
                /> : this.getTotalCard()}
          </div>
        </CardBody>
      </Card >
    )
  }


  getMobileCard() {
    return (
      <div>
        <Loader loader={this.state.isLoading} progress={0} />
        {this.state.isOpenFormModal ? null : <div className='row mr-0 ml-0'>
          <div className='col-sm-12 px-0 pb-2'>
            <h4><span className='mobileScreenHaederView'><b >{this.props.type}</b></span>
              <span className='float-right'>
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => this.openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Settings"
                    onClick={() => this.openShowHideColumnsModal()}
                  />
                </Button>
              </span>
            </h4>
          </div>
        </div>}
        {this.state.isOpenFormModal ? this.getMobileForm() : this.getGridView()}
        {!this.state.isOpenFormModal ? <PaginatorComponent
          totalRecords={this.state.totalRecordsLength}
          first={this.state.first}
          rows={this.state.rows}
          onPageChange={this.onPageChange}
          isWeb={false}
        /> : null}
      </div >
    )
  }

  getArrayVal = (val) => {
    if (val) {
      val = val.replace(/\\n/g, ' ')
        .replace(/\\t/g, ' ')
        .replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ')
        .replace(/\\/g, ' ')
        .replace(/"/g, '')
      // .replace(/,/g, ' ');
    }
    return val;
  }

  getvalfromObj = (val, field) => {
    if (val) {
      val = val[field]
    }
    return val;
  }

  slicedArray = (arrayVal) => {
    let arrLength;
    if (arrayVal && arrayVal.length)
      arrLength = arrayVal.length;
    if (arrayVal) {
      // val = 
      let statecp = []
      for (let sine in arrayVal) {
        statecp.push(arrayVal[sine] === "CA-N" ? "CA-N" : arrayVal[sine].slice(-2))
      }
      arrayVal = statecp.join(', ')
    }
    if (arrLength > 3) {
      arrayVal = arrayVal.slice(0, 10);
      arrayVal = arrayVal + "  (" + arrLength + ")";
    } else {
      arrayVal = arrayVal
    }
    return arrayVal;
  }

  flattenArray = (arrayVal, item) => {
    let val = '';
    let arrLength = ""
    if (this.props.type === "List View" && arrayVal && arrayVal.length > 3) {
      arrLength = arrayVal.length
      arrayVal = arrayVal.slice(0, 3);
    }
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      if (this.props.type !== "Weekly Recovery Plan" && this.props.type !== "Weekly Recovery Report"
        && this.props.type !== "Utilization - US Team" && this.props.type !== "Utilization - Integrity" &&
        this.props.type !== "Process Documents") {
        if (this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View") {
          if (arrLength > 3) {
            val = this.getArrayVal(val) + "  (" + arrLength + ")";
          } else {
            val = this.getArrayVal(val)
          }
        } else if (this.props.type === "ClaimsManagement" && arrayVal[0] && arrayVal[0].checkAmount) {
          let dollarUS = this.getFormattedCurrencyValue()
          val = arrayVal.map((arrayItem, index) =>
            <p data-toggle="tool-tip"
              title={arrayItem.checkAmount} onClick={() => this.openFormModal(item, 'edit', "Payments", arrayItem)}>{dollarUS.format(arrayItem.checkAmount)}</p>
          );
        } else if (this.props.type === "ClaimsManagement" || this.props.type === "Claims" || this.props.type === "State Attributes" || this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "HighValueProperties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
          val = arrayVal.map((arrayItem) =>
            arrayItem !== null ?
              <p data-toggle="tool-tip"
                title={arrayItem.note}>{arrayItem.note}</p>
              : null
          );
        } else {
          val = this.getArrayVal(val)
        }
      } else if (this.props.type === "Process Documents") {
        val = arrayVal.map((arrayItem) =>
          <p className='mb-1' style={{ height: 20 }} data-toggle="tool-tip"
            title={arrayItem.documentName}><a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`${arrayItem.documentUrl}`}>{arrayItem.documentName}</a></p>
        );
      } else {
        val = arrayVal.map((arrayItem) => {
          if (this.props.type !== "Weekly Recovery Plan" && this.props.type !== "Weekly Recovery Report") {
            return (
              arrayItem.company === "Total" ?
                <p style={{ color: arrayItem.dealType === "BK" ? "brown" : "black", marginTop: 1 }} data-toggle="tool-tip"
                  title={arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ")"}>{arrayItem.company + ": " + arrayItem.spent.toFixed(1) + " Hrs"}</p>
                :
                <p style={{ color: arrayItem.dealType === "BK" ? "brown" : "black", marginTop: 1 }} data-toggle="tool-tip"
                  title={arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ")"}>{arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ") "}</p>
            )
          } else {
            return (
              <p title={arrayItem.userName}>{arrayItem.userName}</p>
            )
          }
        }
        );
      }
    }

    return val;
  }

  timeConversionString(date) {
    return moment(date).tz(moment.tz.guess()).format(config.dbOnlyDateFormat);
  }

  getPreviousMondayDate(date) {
    var prevMonday = new Date(date);
    prevMonday.setDate(prevMonday.getDate() - 7);
    return prevMonday;
  }
  //to get previous week dates
  getPreviousMonday = async (date) => {
    date = this.timeConversionString(date);
    // const dateCopy = new Date(new Date(date));
    // let addDays
    // if (moment.tz.guess() === "America/Chicago") {
    //   addDays = 7
    // } else if (moment.tz.guess() === "Asia/Calcutta") {
    //   addDays = 6
    // } else {
    //   addDays = 7
    // }

    // const previousMonday = new Date(
    //   dateCopy.setDate(
    //     dateCopy.getDate() - addDays,
    //   ),
    // );


    let datttttta = this.getPreviousMondayDate(date)
    var result = new Date(datttttta);
    result.setDate(result.getDate() + 4);
    let prevstartDateReports = dateFormats.formatDate(datttttta, "MM/DD/YYYY", "NSd");
    let prelastDateReports = dateFormats.formatDate(result, "MM/DD/YYYY", "NSd");
    await this.setState({
      allUsersData: [],
      disableIcon: true,
      startDateReports: prevstartDateReports,
      lastDateReports: prelastDateReports,
      errorResponse: false,
      page: 1,
      first: 0,
      errorMessage: "",
      softMessage: "",
      softWarning: false
    })
    this.state.filterCriteria["page"] = 1
    this.checkWhichWeek();
    this.getDataFromServer(this.state.filterCriteria);
  }

  //to check the selected week is previous or not
  checkWhichWeek = async () => {
    const selected = new Date(this.state.lastDateReports);
    const maxDate = new Date();
    maxDate.setHours(0, 0, 0, 0);
    maxDate.setDate(maxDate.getDate());
    if (selected < maxDate) {
      await this.setState({ isPreviousWeek: true })
      this.getTableFieldsOrder();
    } else {
      await this.setState({ isPreviousWeek: false })
      this.getTableFieldsOrder();
    }
  }

  setHeadersforxlsx = (tabName) => {
    let arrayFormatcolumns = []
    let data = JSON.parse(localStorage.getItem("tableFields_download"))
    let getHeadersfrom = data && data[tabName] ? data[tabName] : this.props.getSellerTableFields()
    for (var obj of getHeadersfrom) {
      if (obj.field !== "Actions" && obj.field !== "") {
        arrayFormatcolumns.push(obj.field)
      }
    }
    return arrayFormatcolumns
  }


  // Get info based on Selected Tab
  getSelectedTabTypeInfo = async (tabType, index, tableData, factSetFlag = false) => {
    if (tabType !== this.state.activeTab && this.props.isTabChanged) {
      this.props.isTabChanged(true)
    } else if (tabType === this.state.activeTab && this.props.isTabChanged) {
      this.props.isTabChanged(false)
    }
    if (tabType === 'Document Upload' || tabType === 'Available Documents') {
      await this.setState({
        previousTab: this.state.activeTab,
        tabNo: index,
        activeTab: tabType.name ? tabType.name : tabType
      })
      return;
    }
    if (tabType === "Resource Needed") {
      await this.setState({ departMentIdArray: [], postBody: {}, departMentIdArray: [], selectedDepartment: [], Department: [], resourceNeededDepartment: [], resourceNeededDepartmentId: [] })
    }
    await this.setState({
      isLoading: true
    })
    this.handlePercentage("1")
    if (document.getElementById("globalSearch") && document.getElementById("globalSearch").value) {
      document.getElementById("globalSearch").value = ""
    }
    var key;
    let filterCriteria = this.state.filterCriteria, bodyData = {};
    console.log(filterCriteria);
    if (filterCriteria && this.props.type !== "Deal Statistics") {
      filterCriteria.page = 1
      filterCriteria.criteria = []
    }
    if (this.props.type === "UP Opportunity – V2" || this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap") {
      key = tabType.flagName
      filterCriteria.criteria = [{ "key": key, "value": true, "type": "eq", add_Filter: true }]
      bodyData = this.state.postBody
    }
    if (this.state.selectedButtonForAvailableMap && (this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap")) {
      if (filterCriteria && filterCriteria.criteria) {
        filterCriteria.criteria.push({ "key": "StatusCategory", "value": [this.state.selectedButtonForAvailableMap], "type": "in" })
      }
      // filterCriteria.criteria = [{ "key": "StatusCategory", "value": [selectedButtonForAvailableMap], "type": "in" }]
    }
    if ((this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap") && (tabType.name === "High Name Score" || tabType.name === "High Address Score")) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "StatusCategory")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
      let highIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "High Name Score")
      if (highIndex !== -1) {
        filterCriteria["criteria"].splice(highIndex, 1)
      }
      let highAddressIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "High Address Score")
      if (highAddressIndex !== -1) {
        filterCriteria["criteria"].splice(highAddressIndex, 1)
      }
    }
    var sellerId;
    let tableFields
    if (this.props.type === "Deal Statistics" && tabType === "Data") {
      let CompanyID = filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyID")
      if (CompanyID !== -1) {
        filterCriteria["criteria"].splice(CompanyID, 1)
      }
    }
    if (this.props.type === "FactSet" && tabType === "FactSet Entity Subsidiary Count") {
      let CompanyID = filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyID")
      if (CompanyID !== -1) {
        filterCriteria["criteria"].splice(CompanyID, 1)
      }
    }
    tableFields = await this.props.getTableFields(tabType.flagTimeStamp ? tabType.flagTimeStamp : tabType);
    if (!tableData) {
      let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
      let columns = JSON.parse(colOrder);
      if (columns) {
        await this.getTableFieldsOrder(tabType);
      } else {
        await this.updateColumnsOrder(null);
      }
    }

    var downloadData = this.state.downloadData
    if (this.props.type !== "FactSet" && this.props.xlsxExport && !tableData) {
      let obj = {
        name: this.state.activeTab,
        headers: this.setHeadersforxlsx(this.state.activeTab),
        data: this.state.allUsersData
      }
      let objId = downloadData && downloadData.length > 0 ? downloadData.findIndex(obj => obj.name === this.state.activeTab) : -1
      if (objId !== -1) {
        downloadData.splice(objId, 1)
      }
      downloadData.push(obj)
    }
    if (tabType === "Summary" && this.state.criteriaStatis) {
      filterCriteria = JSON.parse(this.state.criteriaStatis)
    }
    if (this.props && this.props.criteria && this.props.criteria.length && (this.props.type !== "UP Opportunity – V2" && this.props.type !== "AvailableToMap" && this.props.type === "AvailableToReMap")) {
      filterCriteria.criteria = this.props.criteria
    }
    await this.setState({
      previousTab: this.state.activeTab,
      tableFields: tableFields,
      tabNo: index,
      sellerId: localStorage.getItem("selectedDeal") ? localStorage.getItem("selectedDeal") : "",
      ruleFlag: tabType.flagTimeStamp ? tabType.flagTimeStamp : "",
      // isLoading: false,
      flagName: tabType.flagName ? tabType.flagName : "",
      mobileListFields: tableData ? tableData : tableFields,
      tablefieldsToShow: tableData ? tableData : tableFields,
      filterCriteria: filterCriteria,
      originalTableFields: tableData ? tableData : tableFields,
      apiUrl: this.props.apiUrl,
      page: 1,
      limit: filterCriteria ? filterCriteria.limit : 10,
      first: 0,
      rows: filterCriteria ? filterCriteria.limit : 10,
      totalRecordsLength: 0,
      tabType: tabType.name ? tabType.name : tabType,
      activeTab: tabType.name ? tabType.name : tabType,
      isLoading: false

    })
    this.handlePercentage("1")
    if (filterCriteria) {
      filterCriteria.sortfield = this.props.sortField ? this.props.sortField : "created"
    }
    if (this.props.type !== "FactSet") {
      await this.setState({
        allUsersData: []
      })
    }
    let FactSetList = this.state.allUsersData;
    if (this.state.activeTab === "FactSet Entity Tree") {
      if (!factSetFlag && this.state.FactSetCompanyDetailsData && this.state.FactSetCompanyDetailsData.length > 0) {
        await this.setState({
          allUsersData: this.state.FactSetCompanyDetailsData,
          FactSetCount: this.state.FactSetCompanyDetailsData.length
        })
      }
    } else if (this.state.activeTab === "FactSet Entity Subsidiary Count") {
      if (!factSetFlag && this.state.FactSetCompanyData && this.state.FactSetCompanyData.length > 0) {
        await this.setState({
          allUsersData: this.state.FactSetCompanyData,
          FactSetCount: this.state.FactSetCompanyData.length
        })
      }

    } else if (this.state.activeTab === "FactSet Names-Address List For Scoring") {
      if (!factSetFlag && this.state.FactSetNameAddressData && this.state.FactSetNameAddressData.length > 0) {
        await this.setState({
          allUsersData: this.state.FactSetNameAddressData,
          FactSetCount: this.state.FactSetNameAddressData.length
        })
      }
    }
    if (this.state.previousTab === "FactSet Entity Subsidiary Count") {
      await this.setState({
        FactSetCompanyData: FactSetList
      })
    } else if (this.state.previousTab === "FactSet Entity Tree") {
      await this.setState({
        FactSetCompanyDetailsData: FactSetList
      })
    } else if (this.state.previousTab === "FactSet Names-Address List For Scoring") {
      await this.setState({
        FactSetNameAddressData: FactSetList
      })
    }
    if (this.state.previousTab === "Data" && this.state.activeTab === "Summary") {
      tableFields = await this.dealStatsFilterRef.changeingColumnFieldsOrder()
      await this.setState({
        tablefieldsToShow: tableFields
      })
    }
    // if (this.state.activeTab === "Ready To File" || this.state.activeTab === "On Hold" || this.state.activeTab === "Seller Negative Notice" || this.state.activeTab === "CA-N Properties") {
    //   this.availableFileRef.changeDealType()
    // }
    // if (this.availableFileRef && this.availableFileRef.assignTeamFromResourceNeeded && this.state.resourceNeededDepartment) {
    //   this.availableFileRef.assignTeamFromResourceNeeded(this.state.resourceNeededDepartment, this.state.resourceNeededDepartmentId)
    // }
    if (this.state.activeTab === "Grouped By Deal" || this.state.activeTab === "Grouped By Deal/State") {
      this.availableFileConfirmOnSiteRef.changeDealType()
    }

    if ((this.props.type === "FactSet" && factSetFlag) || (this.props.type !== "FactSet")) {
      await this.getDataFromServer(filterCriteria, tabType, "", bodyData);
      await this.setState({
        isLoading: false
      })
    }
  }
  removeSelectedRows = async () => {
    this.setState({
      selectedRows: []
    })
  }
  //to get next week dates
  getNextMondayasync = async (date) => {
    var d = new Date(date);
    d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7));
    // date = moment(date).tz(moment.tz.guess()).format(config.dbOnlyDateFormat); // est time
    // const dateCopy = new Date(new Date(date).getTime());
    // let addDays
    // if (moment.tz.guess() === "America/Chicago") {
    //   addDays = 7
    // } else if (moment.tz.guess() === "Asia/Calcutta") {
    //   addDays = 8
    // } else {
    //   addDays = 7
    // }
    // const nextMonday = new Date(
    //   dateCopy.setDate(
    //     dateCopy.getDate() + addDays,
    //   ),
    // );
    var result = new Date(d);
    result.setDate(result.getDate() + 4);
    let nextstartDateReports = dateFormats.formatDate(d, "MM/DD/YYYY", "NSd");
    let nextlastDateReports = dateFormats.formatDate(result, "MM/DD/YYYY", "NSd");
    await this.setState({
      allUsersData: [],
      startDateReports: nextstartDateReports,
      lastDateReports: nextlastDateReports,
      disableIcon: true,
      errorResponse: false,
      page: 1,
      first: 0,
      errorMessage: "",
      softMessage: "",
      softWarning: false
    })
    this.state.filterCriteria["page"] = 1
    this.checkWhichWeek();
    this.getDataFromServer(this.state.filterCriteria);
  }


  getMobileForm() {
    return (
      <div className='row'>
        <div className='col-sm-12 px-1'>
          <Card className='pb-0'>
            <CardBody className='tableCardBody pb-0'>
              {/* {this.getScreenHeader()} */}
              {this.getFormModal()}
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }

  getFormFields = () => {
    this.formModalRef.getFormFields()
  }

  //Kanbam Modal in List View Screen
  getFormModal() {
    return (
      <FormModal
        onRef={(ref) => this.formModalRef = ref}
        city={this.state.city}
        handleUsersData={this.handleUsersData}
        getFilteredValues={this.getStateFIlingValues}
        fontAwesome={this.props.fontAwesome}
        onShowPrivateCompanies={this.onShowPrivateCompanies}
        getPublicCompanies={this.props.getPublicCompanies}
        getPublicAffiliateCompanies={this.props.getPublicAffiliateCompanies}
        openFormModal={this.state.isOpenFormModal}
        allUsersData={this.state.allUsersData}
        totalRecords={this.state.totalRecordsLength}
        getTableFields={this.props.getTableFields}
        getAcquisitionFields={this.props.getAcquisitionFields}
        getAffiliatesFields={this.props.getAffiliatesFields}
        getNewsFields={this.props.getNewsFields}
        getContactsFields={this.props.getContactsFields}
        getNamesAddressFields={this.props.getNamesAddressFields}
        getPreAPAFields={this.props.getPreAPAFields}
        first={this.state.first}
        icon={this.props.icon}
        DepartmentArrayForUsers={this.props.DepartmentArrayForUsers ? this.props.DepartmentArrayForUsers : []}
        rows={this.state.rows}
        getErrorMessage={this.getErrorMessage}
        closeFormModal={this.closeFormModal}
        openViewModal={this.openViewModal}
        attributeData={this.state.getAllresponse}
        closeRejectModal={this.closeRejectModal}
        type={this.props.type}
        tablefieldsToShow={this.state.tablefieldsToShow}
        originalTableFields={this.state.originalTableFields}
        formType={this.state.formType}
        formFields={this.state.formFields}
        getDataFromServer={this.getDataFromServer}
        onFilterChange={this.onFilterChange}
        selectFilterValue={this.state.selectFilterValue}
        editRequired={this.props.editRequired}
        getEditInfoKeys={this.props.getEditInfoKeys}
        filterExtension={this.props.filterExtension}
        idNotRequired={this.props.idNotRequired}
        apiUrl={this.props.formUrl ? this.props.formUrl : this.state.apiUrl}
        role={this.state.loginRole}
        tabType={this.state.tabType}
        getDoctorPostingFields={this.props.getDoctorPostingFields}
        categoryNames={this.props.categoryNames}
        companiesList={this.props.companiesList}
        getSettings={this.getSettings}
        filterCriteria={this.state.filterCriteria}
        menuList={this.props.menuList}
        routeTo={this.props.routeTo}
        displayViewOfForm={this.state.displayViewOfForm}
        displayBreadCrumbValue={this.state.displayBreadCrumbValue}
        displayBreadCrumbField={this.state.displayBreadCrumbField}
        userStatus={this.state.userStatus}
        actionsTypes={this.state.actionsTypes}
        entityType={this.props.entityType}
        sessionWarning={this.props.sessionWarning}
        setFilterCriteriaForActivities={this.setFilterCriteriaForActivities}
        selectedInfo={this.state.selectedInfo}
      />
    )
  }

  getOpenShowHideColumnsModal() {
    return (
      <ShowHideColumnsModal
        isOpenShowHideColumnsModal={this.state.isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={this.closeShowHideColumnsModal}
        tableFields={this.state.originalTableFields}
        type={this.props.type}
        viewBy={this.state.selectedViewType}
        originalTableFields={this.state.tableFields}
        tableFieldsToShow={this.state.tablefieldsToShow}
        isShownnFieldType={this.props.type === "Property Editor" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor"
          || this.props.type === "Properties" || this.props.type === "ClaimsManagement"
          || this.props.isShownnFieldType ? false : true}

      />
    )
  }

  getWorkflowModal() {
    return (
      <Workflow
        openUserPasswordResetModal={this.state.iskanbanModal}
        cancelReset={this.closeFormModal}
        filterData={this.state.filterCriteria}
        rowData={this.state.taskrowData}
        type={this.state.formType}
        getKanbanCards={this.getDataFromServer}
      />
    )
  }

  getKanbanModal() {
    return (
      <RulesModal
        openUserPasswordResetModal={this.state.isOpenFormModal}
        cancelReset={this.closeFormModal}
        rowData={this.state.taskrowData}
        type={this.state.formType}
        getKanbanCards={this.getDataFromServer}
      />
    )
  }
  getRulesModalForHighProperties() {
    return (
      <KanbanModal
        openUserPasswordResetModal={this.state.iskanbanModalForHighProperties}
        title={this.props.type === "Seller Negative Notice Viewer" ? "Seller Negative Notice" : this.props.type === "Research Request Viewer" ? "Research Request" : this.props.type === "RFI View" ? "RFI - Request for Information" : this.state.createTask === "Site Confirmation" ? "Site Confirmation" : null}
        cancelReset={this.closeFormModal}
        filterData={this.state.filterCriteria}
        cancelKanbanModal={this.cancelKanbanModal}
        rowData={this.state.formType === "edit" ? this.state.taskrowData : "empty"}
        taskstatePropertyId={this.state.taskstatePropertyId}
        previousTaskId={this.state.previousTaskId}
        // previousTaskId={[12345, 3456]}
        // count={this.state.selectedRows && this.state.selectedRows.length > 0 ? this.state.selectedRows.length : 0}
        count={this.state.taskopraPropertyId ? this.state.taskopraPropertyId.length : 0}
        taskminValue={this.state.taskminValue}
        taskstates={this.state.taskstates}
        estateDetails={this.state.HighValueSeller ? this.state.HighValueSeller : null}
        taskopraPropertyId={this.state.taskopraPropertyId}
        screenType={this.props.type}
        type={this.state.formType}
        columnValue={"Not Started"}
        getKanbanCards={this.getDataFromServer}
      />
    )
  }
  getRulesModal() {
    return (
      <KanbanModal
        openUserPasswordResetModal={this.state.iskanbanModal}
        title={this.props.type === "Seller Negative Notice Viewer" ? "Seller Negative Notice" : this.props.type === "Research Request Viewer" ? "Research Request" : this.props.type === "RFI View" ? "RFI - Request for Information" : this.state.createTask === "Site Confirmation" ? "Site Confirmation" : null}
        cancelReset={this.closeFormModal}
        filterData={this.state.filterCriteria}
        cancelKanbanModal={this.cancelKanbanModal}
        rowData={this.state.formType === "edit" ? this.state.taskrowData : "empty"}
        taskstatePropertyId={this.state.taskstatePropertyId}
        previousTaskId={this.state.previousTaskId}
        // previousTaskId={[12345, 3456]}
        // count={this.state.selectedRows && this.state.selectedRows.length > 0 ? this.state.selectedRows.length : 0}
        count={this.state.taskopraPropertyId ? this.state.taskopraPropertyId.length : 0}
        taskminValue={this.state.taskminValue}
        taskstates={this.state.taskstates}
        estateDetails={this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.state.PropertyData.selectedSeller : this.state.HighValueSeller ? this.state.HighValueSeller : null}
        taskopraPropertyId={this.state.taskopraPropertyId}
        screenType={this.props.type}
        type={this.state.formType}
        columnValue={"Not Started"}
        getKanbanCards={this.getDataFromServer}
        departmentArr={this.state.departmentArr}
        unassignedOnly={this.state.unassignedOnly}
      />
    )
  }
  getCreateTaskModal() {
    return (
      <CreateTaskModel
        openUserPasswordResetModal={this.state.createTaskModal}
        cancelReset={this.closeFormModal}
        cancelCreateTaskModal={this.cancelCreateTaskModal}
        filterData={this.state.filterCriteria}
        createTask={this.state.createTask ? this.state.createTask : this.state.createTaskAdd ? this.state.createTaskAdd : ""}
        taskstatePropertyId={this.state.taskstatePropertyId}
        previousTaskId={this.state.previousTaskId}
        unknownPropertyCount={this.state.unknownPropertyCount && this.state.unknownPropertyCount.length > 0 ? this.state.unknownPropertyCount.length : 0}
        // previousTaskId={[12345, 3456]}

        taskminValue={this.state.taskminValue}
        taskstates={this.state.taskstates}
        estateDetails={this.state.PropertyData.selectedSeller}
        taskopraPropertyId={this.state.taskopraPropertyId}
        selectedRows={this.state.selectedRows}
        count={this.state.taskopraPropertyId ? this.state.taskopraPropertyId.length : 0}

        // count={this.state.selectedRows && this.state.selectedRows.length > 0 ? this.state.selectedRows.length : 0}
        rowData={this.state.formType === "edit" ? this.state.taskrowData : "empty"}
        type={this.state.formType}
        columnValue={"Not Started"}
        getKanbanCards={this.getDataFromServer}
      />
    )
  }


  getSellerModal() {
    return (
      <SellerModal
        openUserPasswordResetModal={this.state.issellerModal}
        cancelReset={this.closeFormModal}
        filterData={this.state.filterCriteria}
        getSuccessMessage={this.getSuccessMessage}
        rowData={this.state.formType === "edit" ? this.state.taskrowData : "empty"}
        type={this.state.formType}
        columnValue={"Not Started"}
        getKanbanCards={this.getDataFromServer}
      />
    )
  }

  //Delete Modal
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openDeleteModal}
        closeDeleteModal={this.closeDeleteModal}
        selectActions={this.state.selectActions}
        deleteSelectedRow={this.deleteSelectedRow}
        confirmModalText={this.state.confirmModalText}
        type={this.props.type}
      />
    )
  }

  //Session Expiry Modal
  getSessionExpiryModal() {
    return (
      <SessionExpiryModal
        SOpen={this.state.sessionExpiryModal}
      />
    )
  }

  getContactNoticeModal() {
    return (
      <ContactNoticeModal
        iscontactNoticeModal={this.state.iscontactNoticeModal}
      />
    )
  }
  cancelTable = async () => {
    await this.setState({
      isOpenTable: false
    })
    await this.getDataFromServer(this.state.filterCriteria)
  }

  getTableModal() {
    return (
      <TableModal
        onRef={(ref) => this.tableModalRef = ref}
        openTableModal={this.state.isOpenTable}
        cancelReset={this.cancelTable}
        rowData={this.state.selectedItemforTableShow}
        type={this.props.type}
        openFormModal={this.openFormModal}
      />
    )
  }
  getEditorComponent() {
    return (
      <EditorComponent
        onRef={(ref) => this.editorComponentRef = ref}
        activeTab={this.state.activeTab}
        checkDataisEdited={this.checkDataisEdited}
        OpenConfirmaionModelorSave={this.OpenConfirmaionModelorSave}
        selectedViewType={this.selectedViewType}
        optFields={this.state.optFields}
        selectedViewType={this.state.selectedViewType}
        filterCriteria={this.state.filterCriteria}
        isDisabledTab={this.state.isDisabledTab}
        openShowHideColumnsModal={this.openShowHideColumnsModal}
        getDataFromServer={this.getDataFromServer}
        getErrorMessage={this.getErrorMessage}
        isLoading={this.isLoading}
        selectedOpra={this.state.selectedOpra}
        fileForOptions={this.state.fileForOptions}
        selectedValidatedStatus={this.state.selectedValidatedStatus}
        settingsData={this.props.settingsData}
        type={this.props.type}
        apiUrl={this.state.apiUrlforExport}
        // selectednote={this.state.selectednote}
        PropertyData={this.state.PropertyData}
        screenPermissions={this.state.screenPermissions}
        totalRecordsLength={this.state.totalRecordsLength}
        selectedRows={this.state.selectedRows}
        getshowSelectederrormsg={this.getshowSelectederrormsg}
        allUsersData={this.state.allUsersData}
        removeSelectedRows={this.removeSelectedRows}
      />

    )
  }

  getEditorComponentforDealSizing() {
    return (
      <EditorComponentDealSizing
        onRef={(ref) => this.editorComponentRef = ref}
        activeTab={this.state.activeTab}
        checkDataisEdited={this.checkDataisEdited}
        OpenConfirmaionModelorSave={this.OpenConfirmaionModelorSave}
        selectedViewType={this.selectedViewType}
        optFields={this.state.optFields}
        selectedViewType={this.state.selectedViewType}
        filterCriteria={this.state.filterCriteria}
        isDisabledTab={this.state.isDisabledTab}
        openShowHideColumnsModal={this.openShowHideColumnsModal}
        getDataFromServer={this.getDataFromServer}
        getErrorMessage={this.getErrorMessage}
        isLoading={this.isLoading}
        selectedOpra={this.state.selectedOpra}
        fileForOptions={this.state.fileForOptions}
        selectedValidatedStatus={this.state.selectedValidatedStatus}
        settingsData={this.props.settingsData}
        type={this.props.type}
        apiUrl={this.state.apiUrlforExport}
        // selectednote={this.state.selectednote}
        PropertyData={this.state.PropertyData}
        screenPermissions={this.state.screenPermissions}
        totalRecordsLength={this.state.totalRecordsLength}
        selectedRows={this.state.selectedRows}
        getshowSelectederrormsg={this.getshowSelectederrormsg}
        allUsersData={this.state.allUsersData}
        removeSelectedRows={this.removeSelectedRows}
      />

    )
  }

  openTableModel = async (item) => {
    await this.setState({
      isOpenTable: true,
      selectedItemforTableShow: item
    })
  }

  isOpenRFIModal = async () => {
    await this.setState({
      isOpenRFIModal: true,
    })
  }

  openRFIModal() {
    return (<RFIModal
      isOpenRFIModal={this.state.isOpenRFIModal}
      selectedObj={this.state.selectedObj}
      screen={this.props.type}
      getErrorResponsefromServer={this.getErrorResponsefromServer}
      isCloseRFIModal={this.closeRFIModal}
      formType={this.state.formType} />
    )

  }
  /**
     * To refresh the property loader data in missing money with refresh icon
     * @param {*} type 
     */
  refreshPropertyLoaderData = async () => {
    this.setDataCriteriaforOpportunity({
      criteria: [], limit: this.props.Limit ? this.props.Limit : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 15,
      page: 1,
    }, {})
  }
  closeRFIModal = () => {
    this.setState({
      isOpenRFIModal: false
    })
    let criteria = this.state.filterCriteria
    let index = criteria["criteria"].findIndex(obj => obj.key === "taskID")
    if (index !== -1) criteria["criteria"].splice(index, 1)
    this.getDataFromServer(criteria)
  }

  render() {
    return (
      localStorage.getItem("loginCredentials") ?
        <div >
          <Loader loader={this.state.isLoading} progress={this.state.progress} className={this.props.isMatchproperties ? "progress-loader_match" : this.props.type === "Legal Entity Suffix" || this.props.type === "Legal Entity Abbreviation" || this.props.type === "State-UP Filing Category" || this.props.type === "State-UP Filing Sub Category" || this.props.type === "Available To File - Statuses" || this.props.type === "APA Type Configuration" ? "configuration" : "progress-loader"} />
          {/* {this.props.windowLocation&&this.state.acti ? this.opennewComponentDidMount() : null} */}
          {this.props.type === "OpenCorporateBranch" || this.props.type === "OpenCorporateBranchBatch" || this.props.type === "OpenCorporateSearch" ? this.getOpenCorporateFilters() : ""}
          {this.state.width <= 576 && this.getMobileCard()}
          {this.state.isOpenRFIModal ? this.openRFIModal() : null}
          {/* {this.state.width >= 577 && this.getTotalCard()} */}
          {this.props.tabType && this.state.width >= 577 ? this.getTabTypeCard() : this.state.width >= 577 && this.getTotalCard()}
          {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
          {this.state.isOpenTable ? this.getTableModal() : null}
          {this.state.iskanbanModal && this.props.type !== "Rules Configuration Workflow" ? this.getRulesModal() : null}
          {/* {this.state.iskanbanModalForHighProperties && this.props.type !== "Rules Configuration Workflow" ? this.getRulesModalForHighProperties() : null} */}
          {this.state.createTaskModal ? this.getCreateTaskModal() : null}
          {this.state.issellerModal ? <Redirect to="/sellersAdd" /> : null}
          {this.state.iscrMatrixModal ? <Redirect to="/crMatrixAdd" /> : null}
          {this.state.issellerModalEdit ? <Redirect to={`/sellersEdit/deal/${this.state.sellerID}`} /> : null}
          {this.state.isSellerModalNames ? <Redirect to={`/sellersEdit/name/${this.state.sellerID}`} /> : null}
          {this.state.isSellerModalAddress ? <Redirect to={`/sellersEdit/address/${this.state.sellerID}`} /> : null}
          {this.state.issellerModalView ? <Redirect to={`/sellersEdit/deal/${this.state.sellerID}`} /> : null}
          {this.state.iscrMatrixModalEdit ? <Redirect to={`/crMatrixEdit/${this.state.crMatrixId}`} /> : null}
          {this.state.isOpenShowHideColumnsModal ? this.getOpenShowHideColumnsModal() : null}
          {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}
          {this.state.sessionWarning ? this.getSessionWraningModal() : null}
          {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
          {/* {this.state.forceLogout ? <Redirect to="/log_in" /> : ""} */}
          {this.state.isCompanyScreen ? <Redirect to="/company" /> : ""}
          {this.state.PropertyTrailViewerModal ? this.getPropertyTrailViewerModal() : null}
          {this.state.ValidatedPropertyUpdaterModal ? this.getValidatedPropertyUpdaterModal() : null}
          {this.state.isnotesModal ? this.getNotesModal() : null}
          {this.state.openClaimSummary ? <Redirect to="/claimsummary" /> : null}
          {this.state.goBackToClaims ? <Redirect to="/claims" /> : null}
          {this.state.isApplyFilter ? this.getConfirmaionModel() : null}
          {this.state.openpaymentsModal ? this.getPaymentsModal() : null}
          {this.state.isopenConfirmaionModelforSave ? this.getConfirmaionModelforSave() : null}
          {
            this.state.CompanyDetailView ?
              <Redirect
                to={{
                  pathname: `companyView/${this.state.redirectCompanyId}`,
                  state: { companyId: this.state.redirectCompanyId }
                }} />
              : null
          }
          {
            this.state.iskanbanModal && this.props.type === "Rules Configuration Workflow" && this.state.formType !== "edit" ?
              <Redirect
                to={`/ruleConfigurationadd`} />
              : this.state.iskanbanModal && this.props.type === "Rules Configuration Workflow" && this.state.formType === "edit" ?
                <Redirect
                  to={{
                    pathname: `ruleConfigurationadd/${this.state.taskrowData._id}`,
                    state: { companyId: this.state.taskrowData._id }
                  }} />
                : null
          }
        </div > :
        <div></div>
    );
  }
}



// const mapStateToProps = state => {
//   return {articles: state.articles };
// };
// const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);