import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';
import store from '../../../App/store';
// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class DataSourceGroupings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Data Source Groupings'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    await this.getStatusOptions()
    await this.getRoles()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }
  getRoles = async () => {
    return fetch('GET', apiCalls.Roles)
      .then(async (response) => {
        if (response && response.roles && response.roles.length > 0) {
          let data = response.roles, tempData = [];
          for (let i = 0; i < data.length; i++) {
            tempData.push({ 'label': data[i]['role'], "value": data[i]['role'] })
          }

          await this.setState({
            roleOptions: tempData,


          })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        } else {
          await this.setState({
            roleOptions: []
          })
        }
      }).catch((err) => {
        return err;
      });
  }
  getStatusOptions = () => {
    fetch('GET', apiCalls.PropertyStatuses)
      .then(async (response) => {
        if (response) {
          let statuses = response.propertyStatuses;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            })
          }
          await this.setState({
            Statuses: modifiedStatuses
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }

  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 6,
      //   field: 'reOrder',
      //   // "displayInSettings": true,
      //   // "displayInFilter": true,
      //   // sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'group',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Data Source Grouping',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'dataSourceName',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Data Source',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'roles',
        fieldType: 'StrArray',
        "mobile": true,
        header: 'Role',
        // filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },


      {
        "show": true,
        "textAlign": "left",
        // "width": 20,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "group",
        "label": "Data Source Grouping",
        "id": "name",
        "placeholder": "Data Source Grouping",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "dataSourceName",
        "label": "Data Source",
        "id": "name",
        "placeholder": "Data Source",
        "required": true
      },
      {
        //'show': false,
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "roles",
        "label": "Role",
        "id": "Role",
        "options": this.state.roleOptions,
        "placeholder": "Role",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "status",
        "placeholder": "status",
        // "required": true
      },
      // {
      //   //'show': false,
      //   'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "screenType",
      //   "label": "Screen",
      //   "id": "name",
      //   "options": [{ label: "Property Editor", value: "Property Editor" }, { label: "Claims", value: "Claims" }],
      //   "placeholder": "Screen",
      //   "required": true
      // },
    ]);
  }


  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          sortField={"sequence"}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Data Source Groupings'
          displayright={true}
          icon='tag'
          routeTo='statusReason'
          displayViewOfForm='modal'
          apiResponseKey={"dataSources"}
          apiUrl={apiCalls.DataSource}
          entityType='employee'
        />

      </span>
    );
  }
}