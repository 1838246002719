import React from 'react';
import { withTranslation } from 'react-i18next';

class DefaultInput extends React.Component {

    static isEditedShow = false;

    onChange = (event, type) => {
        if (event && event.target) {
            const { onChange } = this.props.input;
            if (type) {
                if (type === 'nocaptalize') {
                    onChange(event.target.value);
                } else if (type === 'firstcaptalize') {
                    let value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
                    onChange(value);
                } else if (type === 'captalize') {
                    let value = event.target.value.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
                    onChange(value);
                }
            } else {
                onChange(event.target.value);
            }
            this.isEditedShow = this.props.isEditedShow && event.target.value ? true : false
        }
    }


    render() {
        const { input, placeholder, type, isDisable, meta: { touched, error }, className, t } = this.props;
        return (
            <div className="form__form-group-input-wrap">
                <div className="form__form-group-field " >
                    <input style={isDisable ? { background: '#d3d3d338', border: "none" } : { background: this.isEditedShow && !this.props.showNull ? "#f8ac8bab" : 'white' }}
                        {...input}
                        disabled={isDisable}
                        placeholder={placeholder}
                        type={type}
                        value={this.props.def_value ? this.props.def_value : this.props.input.value ? this.props.input.value : type === "number" ? "0" : ""}
                        onChange={input &&
                            ((input.name === "fname" ||
                                input.name === "lname"))
                            ? (e) => {
                                this.onChange(e, "captalize");
                            } : (e) => this.onChange(e)}
                        maxLength={this.props.maxLength ? this.props.maxLength : null}
                        className={className ? `${className} mb-0` : "mb-0"}
                        id={this.props.id}
                    />
                </div>
                {className !== "key_table" ?
                    <div className="mb-1" style={{ height: '10px' }}>
                        {touched && error && <span className="form__form-group-error">{t(error)}</span>}
                    </div>
                    : null}
            </div>
        );
    }
}
export default withTranslation('common')(DefaultInput);
