import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
// import {settingsAction} from '../../../../redux/actions/settingsAction'
import { settings } from '../../../../redux/actions/settingsAction'
import PropTypes from 'prop-types';

import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

// Validate input
import validate from '../../../Validations/validate';

// Input field
import DefaultInput from '../../../../shared/components/form/DefaultInput';

// Config
import configMessage from '../../../../config/configMessages';
import fetchRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';

// Toaster message
// import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// Loader
import Loader from '../../../App/Loader';
import Row from 'reactstrap/lib/Row';

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      isLoginDisabled: false,
      loginRememberInfo: null,
      captchaValue: null,
      isRobot: false,
      isLoginSuccess: false,
      isLoading: false,
      loginRole: '',
      isTaskUpdate: false,
      listView: false
    };
  }

  componentDidMount = () => {
    let url = window.location.href
    url = url.substring(1);
    let k = url.split("=")
    if (k.length > 1) {
      let n = k[1].split('&')
      localStorage.setItem("taskId", n[0])
      localStorage.setItem("loggedUserId", k[2])
      let loggeddetails = localStorage.getItem('loginCredentialsId');
      if (k[2] === loggeddetails) {
        this.setState({
          isTaskUpdate: true
        })
      }

    }
    this.getLoginRememberInfo();
  }

  getLoginRememberInfo = async () => {
    // let keysToRemove = ["loginCredentials", "rolePermissions", 'loginBody', 'sessionexpired'];
    let keysToRemove = ['sessionexpired', 'SerchedCompany', 'loginCredentials', "rolePermissions", 'loginBody', 'sessionexpired', 'AutoOff', "CLOSED"];
    let Storage = Object.keys(window.localStorage);
    for (let key of Storage) {
      let result = key.includes("_column_order");
      if (result) {
        keysToRemove.push(key)
      }
    }
    if (window.location.pathname === "/logout") {
      return fetchRequest('POST', apiCalls.logout)
        .then((response) => {
          if (response) {
            if (response.respCode && response.respMessage) {

            } else if (response.errorMessage) {
              // display message
              // showToasterMessage(response.errorMessage, 'error');
            }
            this.setState({ isLoading: false });
          }
        }).catch((err) => {
          return err;
        }
        );

    }
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
    // localStorage.clear();
    let loginRememberInfo = localStorage.getItem('loginRememberInfo');
    loginRememberInfo = loginRememberInfo ? JSON.parse(loginRememberInfo) : null;
    await this.props.load(loginRememberInfo);
    this.setState({
      loginRememberInfo: loginRememberInfo,
      remember_me: loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false
    })
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }
  getTabs = async () => {
    let data = []
    return fetchRequest('GET', 'ruleConfiguration')
      .then(async (response) => {
        if (response && response.ruleConfigurations && response.ruleConfigurations.length > 0) {
          let labels = response.ruleConfigurations;
          let modifiedLabels = []; let tabOptions = [];
          for (let i = 0; i < labels.length; i++) {
            tabOptions.push({ flagName: labels[i].flagName, name: labels[i].name })
            modifiedLabels.push({
              label: labels[i].name,
              value: { flagName: labels[i].flagName, name: labels[i].name, description: labels[i].description, flagTimeStamp: labels[i].flagTimeStamp },
            })
          }

          localStorage.setItem('ruleGroups', JSON.stringify(modifiedLabels))
        } else {

          localStorage.setItem("ruleGroups", JSON.stringify([]))
        }
      }).catch((err) => {
        return err;
      });
  }

  submit = async (values) => {
    // await this.setState({
    //   isLoginSuccess: true,
    // })
    if (values.email && values.password) {
      this.setState({
        remember_me: values.remember_me,
        isLoginDisabled: true,
        isLoading: true
      })
      this.handleLoginUser(values)
    }
  }

  handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: config.entityType,
      offsetTime: new Date().getTimezoneOffset() / 60,
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));
    return fetchRequest('POST', apiCalls.loginEmail, userBody)
      .then(async (response) => {
        if (response && response.respCode && response.respCode === 200) {
          this.setState({
            isLoading: true
          });
          localStorage.setItem('multiTabsOpen', true);
          let userDetails = response.details;
          if (userDetails && userDetails._id) {
            if (response.accessToken) {
              let tokenInfo = {
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
                tokenExpires: response.tokenExpires,
              };
              userDetails = { ...userDetails, ...tokenInfo };
              // this.getSettingsData(userDetails)


              let expiryTime = response.expireTime * 60 * 1000
              localStorage.setItem('loggedTime', expiryTime);
              // save user credentials in storage
              localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
              localStorage.setItem('loginCredentialsId', userDetails._id);
              await this.getTabs()
              console.log("Leader", userDetails.roleName)
              if (userDetails && userDetails.roleName && userDetails.roleName === 'Leadership') {
                this.setState({
                  listView: true
                })
              }
            }
            if (userDetails && userDetails.rolePermissions) {
              let permissions = userDetails.rolePermissions;
              // save user permissions in storage

              localStorage.setItem('rolePermissions', JSON.stringify(permissions));
            }
          }
          // showToasterMessage(response.respMessage, 'success');
          await this.setState({
            isLoginSuccess: true,
          }, () => {
            if (this.state.remember_me) {
              var data = {
                email: values.email,
                password: values.password,
                remember_me: this.state.remember_me
              }
              if (this.state.loginRememberInfo && this.state.loginRememberInfo.email &&
                this.state.loginRememberInfo.email !== values.email) {
                localStorage.setItem('loginRememberInfo', JSON.stringify(data));
              } else if (!this.state.loginRememberInfo) {
                localStorage.setItem('loginRememberInfo', JSON.stringify(data));
              }
            } else {
              localStorage.removeItem('loginRememberInfo');
            };
          });
        } else if (response && response.errorMessage) {
          setTimeout(() => {
            this.setState({
              isLoginDisabled: false,
              isLoading: false
            })
          }, 250);
          // showToasterMessage(response.errorMessage, 'error');
        }
        // settingsAction
      }).catch((err) => {
        this.setState({
          isLoginDisabled: false,
          isLoading: false
        });
      });
  }

  onChange = (value) => {
    // console.log("gggggggggggggggkkkkkkkkkk")
    if (value === 'remember' && this.state.remember_me) {
      this.setState({
        remember_me: false
      })
    }
  }

  getSettingsData = (userDetails) => {
    let settingsData;
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        settingsData = response.settings[0]
        var responseWarningTimeout = response.settings[0].taskTimeoutWarningInMin
        localStorage.setItem("taskTimeoutWarningInMin", responseWarningTimeout)
        localStorage.setItem("taskTimeoutInMin", response.settings[0].taskTimeoutInMin)
        localStorage.setItem("taskTimeoutInactive", parseInt(response.settings[0].taskTimeoutInMin - responseWarningTimeout))

        await this.props.load(settingsData)
        await this.props.settingsLoad(settingsData)
      }

    })
    return settingsData;
  }

  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            {/* <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div> */}
            <Field
              name="email"
              component={DefaultInput}
              type="email"
              placeholder="Email"
              validate={required}
            />
          </div>
        </div>
        <div className="form__form-group mt-2">
          <div className="form__form-group-label">Password</div>
          <div className="form__form-group-field">
            <Field
              name="password"
              component={DefaultInput}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              validate={required}
            />
          </div>

        </div>
        <div className="account__btns " style={{ marginTop: 10, width: "100%" }}>
          <Button className="loginbtn ml-auto btn-block" style={{ width: '100%' }}
            color="primary"
            disabled={this.state.isLoginDisabled}>LOGIN</Button>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
              checked={this.state.remember_me}
              onChange={() => this.onChange('remember')}
            />
          </div>
        </div>
        <Row>
          <div className="loginhr"><hr style={{ borderTop: "red" }} /></div>
        </Row>
        <div className="d-flex justify-content-end mt-4">
          <Link className="p-0" to="/forgot_password"  >Forgot your password?</Link>
        </div>

        {/* change */}
        {/* Navigate to Home */}
        {(this.state.isLoginSuccess || this.state.isTaskUpdate) && !this.state.listView ? <Redirect to="/taskView" />
          : this.state.listView ? <Redirect to="/listView" /> : null}
      </form >
    );
  }
}

LogInForm = reduxForm({
  form: 'log_in_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(LogInForm);

// You have to connect() to any reducers that you wish to connect to yourself
LogInForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings //binding settingsDAta
  }
)(LogInForm);

export default LogInForm;
