

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from 'react-router-dom';
import { faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { AutoComplete } from 'primereact/autocomplete';
import apiCalls from '../../../config/apiCalls';
import fetch from '../../../config/service';
import {
  Button,
  TabContent, TabPane, Nav, NavItem, NavLink, Row
} from 'reactstrap';
import classnames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ValidatedProperties from '../ValidatedProperties/index';
import DetailedPropertyView from '../detailedPropertyView';
import NamesPropCount from '../NamesPropCount/index'

// Toaster message
// import validate from '../Validations/validate';
// Loader
// import Loader from '../App/Loader';
// // Calendar
// //session expiry modal
// import store from '../App/store';
import styled from 'styled-components'
// import DealSizingThreshold from '../DealSizingThreshold/index';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    font-family: 'Inter', sans-serif !important;
    font-size: 13px !important;
    color: #333333;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      :nth-child(even) {
          background-color: #f9f9f9;
          // #eceded
          }
          .p-datatable .p-datatable-tbody > tr:nth-child(even) {
          background-color: #e3e0e0cc;
          // #eceded
        }
        :nth-child(odd) {
          background-color: #ffffff;
          // #eceded
          }
          .p-datatable .p-datatable-tbody > tr:nth-child(even) {
          background-color: #e3e0e0cc;
          // #eceded
        }
    }

    th,
    td {
      margin: 0;
      padding: 0.1rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      width: 50%;

      :last-child {
        border-right: 0;
      }
    }
  }
`

class SettingsForm extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      statuses: [],
      options: [
        { label: 'OPRA Validated Property Lookup', value: 'OPRA Validated Property Lookup' },
        { label: 'OPRA Business Name Issue Lookup', value: 'OPRA Business Name Issue Lookup' }
        // { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
      ],
      filterCriteria: {
        limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10, page: 1, criteria: [],
        sortfield: "created",
        direction: 'desc'
      },
      // isLoading: false,
      isUserUpdated: false,
      statusType: "Only Validated & Filed Properties",
      All: true,
      Paid: true,
      Filed: true,
      Validated: true,
      statuses: ["MAPPING-READY TO FILE", "MAPPING-REFER TO SLRCOMMS/LGL", "RESULT-APPROVED", "FILING-CLM FILED"]
    };
  }

  componentDidMount = async () => {
    // this.searchsellerItems();
    // let apiUrl=''
    // this.setState({
    //   isLoading: true
    // });

    // })
  }
  //send data to server

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  handleScreenChange = async (e) => {
    await this.setState({
      selectedScreen: e
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      // this.callValidateFunction()
    }
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl

    if (event) {
      apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query ? event.query : "")}`
    } else {
      apiUrl = `${apiCalls.propertysellers}`
    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [], dropdownVal = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownVal = response["sellers"]
          } else if (response && response.errorMessage && response.errorMessage === "Session expired please login again.") {
            this.setState({
              sessionExpiryModal: true
            });
          } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
            this.setState({
              SessionWarningModal: true
            })
          }
          if (dropdownVal && dropdownVal.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownVal;
          }
          await this.setState({
            filteredSuggestions: dropdownData
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            this.searchnameItems(criteria)
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }

  handleChange = async (e, type, key) => {
    let index;
    let filtercriteria = this.state.filterCriteria
    if (type === "Seller") {
      await this.setState({
        selectedSeller: e.value,
        // EstateID: e.value.EstateID,
        GetEstateID: e.value.EstateID,
        selectedName: "",
        selectedStatus: "",
        selectedAddress: "",
        selectedState: "",
        EstateID: null,
        isChangeHit: true
      })
      // this.props.getErrorMessage("Please Select Seller", "selectedSeller")
      // this.submit()
      this.setGoButton()
      filtercriteria["criteria"] = []
      this.props.change('Name', '')
    }
    else if (type === "onlyFiled") {
      await this.setState({
        onlyFiled: this.state.onlyFiled === true ? false : true
      })
      this.setGoButton()
    } else if (type === "ValidateAndHold") {
      await this.setState({
        ValidateAndHold: this.state.ValidateAndHold === true ? false : true
      })
      this.setGoButton()
    } else if (type === "ValidateAndFiled") {
      await this.setState({
        ValidateAndFiled: this.state.ValidateAndFiled === true ? false : true
      })
      this.setGoButton()
    } else if (type === "onlyPaid") {
      await this.setState({
        onlyPaid: this.state.onlyPaid === true ? false : true
      })
      this.setGoButton()
    } else if (type === "isexcludePossiblyPaid") {
      await this.setState({
        isexcludePossiblyPaid: this.state.isexcludePossiblyPaid === true ? false : true
      })
      this.setGoButton()
    }
    else if (type === "Delete") {
      if (e === "selectedSeller") {
        await this.setState({
          selectedSeller: "",
          hitApiCalls: false,
          totalPropertyCount: 0,
          EstimatedValueofProperties: 0,
          NumberOfStateswithProperty: 0,
          OpportunitySize: ""

        })
        // this.submit()
        // this.props.getErrorMessage("Please Select Seller", "removingSeller")
      }
    }
    await this.setState({
      filterCriteria: filtercriteria
    })
  }

  summaryTab() {
    return (


      <div className="container-fluid table-bordered" style={{ backgroundColor: "white" }}>
        <div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
          <div className='row' style={{ borderBottomStyle: "inset", textAlign: "center", alignSelf: 'center' }}>
            <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
              <h4><b>{"Pre-Sales"}</b></h4>
            </div>
            <div className='col-sm-8' style={{ borderLeft: "outset", padding: "10px", textAlign: "center", alignSelf: 'center' }}>
              {/* <div className='row mr-0 ml-0 pt-1'> */}
              <b>{"Opportunity Size:"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.OpportunitySize}</b>
              {/* </div> */}
            </div>
          </div>
          <div className='row' style={{ borderBottomStyle: "inset", textAlign: "center", alignSelf: 'center' }}>
            <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
              <h4><b>{"Transition"}</b></h4>
            </div>
            <div className='col-sm-8' style={{ borderLeft: "outset", padding: "10px", textAlign: "center", alignSelf: 'center' }}>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <b>{"Names Added :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.NamesAdded}
                  </div>
                </div>
                <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <b>{"Issue Names :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.IssueNames}
                  </div>
                </div>
                <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <b>{"Addresses :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.Addresses}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row' style={{ borderBottomStyle: "inset", textAlign: "center", alignSelf: 'center' }}>
            <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
              <h4><b>{"Search"}</b></h4>
            </div>
            <div className='col-sm-8' style={{ borderLeft: "outset", padding: "10px", textAlign: "center", alignSelf: 'center' }}>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <b>{"States with results :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.query2Results}
                  </div>
                </div>
                <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <b>{"Manual Properties, Pending validation :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.query3Results}
                  </div>
                </div>
                <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <b>{"Results Loaded :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.query4Results}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row' style={{ borderBottomStyle: "inset", textAlign: "center", alignSelf: 'center' }}>
            <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
              <h4><b>{"Validation"}</b></h4>
            </div>
            <div className='col-sm-8' style={{ borderLeft: "outset", textAlign: "center", alignSelf: 'center' }}>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row' style={{ textAlign: "center", alignSelf: 'center' }}>
                    <b>{"Results Reviewed "}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.NotNull}
                  </div>
                </div>
                <div className='col-sm-8' style={{ borderLeft: "outset", textAlign: "center", alignSelf: 'center' }}>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <b>{"Properties Validated Till Date :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.PropertiesValidatedTillDate}
                    </li>
                    <li class="list-group-item">
                      <b>{"Validated-Ready To File :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.ValidatedReadyToFile}
                    </li>
                    <li class="list-group-item">
                      <b>{"MAPPED-SLR NOTICE RQRD :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.ValidatedSellerNegativeNotice}

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row' style={{ borderBottomStyle: "inset", textAlign: "center", alignSelf: 'center' }}>
            <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
              <h4><b>{"Filing"}</b></h4>
            </div>
            <div className='col-sm-8' style={{ borderLeft: "outset", textAlign: "center", alignSelf: 'center' }}>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <b>{"Properties Filed :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.PropertiesFiled}
                </li>
                <li class="list-group-item">
                  <b>{"Properties In Filing Backlog :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.PropertiesInFilingBacklog}
                </li>
              </ul>
              {/* <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-4' style={{ textAlign: "center", alignSelf: 'center' }}>
                  <div className='row'>
                    <b>{"Properties Filed :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.PropertiesFiled}
                  </div>
                  <div className='row'>
                    <b>{"Properties In Filing Backlog :"}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.PropertiesInFilingBacklog}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  ShowGraph = () => {
    this.setState({ graph: true })
  }

  handleRadioChange = async (e, type) => {
    let statuses = this.state.statuses


    if (type === "Validated") {
      await this.setState({
        Validated: this.state.Validated === true ? false : true
      })
      if (!this.state.Filed || !this.state.Paid || !this.state.Validated) {
        await this.setState({
          All: false
        })
      }
      if (this.state.Filed && this.state.Paid && this.state.Validated) {
        await this.setState({
          All: true
        })
      }
      let index = statuses.findIndex(obj => obj === "MAPPING-READY TO FILE")
      if (index !== -1 && !this.state.All) {
        statuses.splice(index, 2)
      } else if (!this.state.All) {
        statuses.push("MAPPING-READY TO FILE", "MAPPING-REFER TO SLRCOMMS/LGL")
      }


    } else if (type === "Rejected") {
      await this.setState({
        Rejected: this.state.Rejected === true ? false : true
      })
      let index = statuses.findIndex(obj => obj === "UNABLE TO MAP")
      if (index !== -1) {
        statuses.splice(index, 1)
      } else {
        statuses.push("UNABLE TO MAP")
      }
    } else if (type === "Filed") {
      await this.setState({
        Filed: this.state.Filed === true ? false : true
      })
      if (!this.state.Filed || !this.state.Paid || !this.state.Validated) {
        await this.setState({
          All: false
        })
      }
      if (this.state.Filed && this.state.Paid && this.state.Validated) {
        await this.setState({
          All: true
        })
      }
      let index = statuses.findIndex(obj => obj === "FILING-CLM FILED")
      if (index !== -1 && !this.state.All) {
        statuses.splice(index, 1)
      } else if (!this.state.All) {
        statuses.push("FILING-CLM FILED")
      }

    } else if (type === "ValidatedResourceNeeded") {
      await this.setState({
        ValidatedResourceNeeded: this.state.ValidatedResourceNeeded === true ? false : true
      })
      let index = statuses.findIndex(obj => obj === "MAPPING-FILING BLOCK")
      if (index !== -1) {
        statuses.splice(index, 1)
      } else {
        statuses.push("MAPPING-FILING BLOCK")
      }
    } else if (type === "Paid") {
      await this.setState({
        Paid: this.state.Paid === true ? false : true
      })
      if (!this.state.Filed || !this.state.Paid || !this.state.Validated) {
        await this.setState({
          All: false
        })
      }
      if (this.state.Filed && this.state.Paid && this.state.Validated) {
        await this.setState({
          All: true
        })
      }
      let index = statuses.findIndex(obj => obj === "RESULT-APPROVED")
      if (index !== -1 && !this.state.All) {
        statuses.splice(index, 1)
      } else if (!this.state.All) {
        statuses.push("RESULT-APPROVED")
      }

    } else if (type === "All") {
      await this.setState({
        All: this.state.All === true ? false : true,
      })
      if (this.state.All) {
        await this.setState({
          Paid: true,
          Filed: true,
          Validated: true,
        })
      }
      if (!this.state.Validated) {
        statuses = statuses.filter(function (el) {
          return el !== "MAPPING-READY TO FILE" && el !== "MAPPING-REFER TO SLRCOMMS/LGL"
        });
      }
      if (!this.state.Filed) {
        statuses = statuses.filter(function (el) {
          return el !== "FILING-CLM FILED"
        });
      }
      if (!this.state.Paid) {
        statuses = statuses.filter(function (el) {
          return el !== "RESULT-APPROVED"
        });
      }
      if (this.state.All) {
        let validatesindex = statuses.findIndex(obj => obj === "MAPPING-READY TO FILE")
        if (validatesindex !== -1) {
        } else {
          statuses.push("MAPPING-READY TO FILE", "MAPPING-REFER TO SLRCOMMS/LGL")
        }
        let filedindex = statuses.findIndex(obj => obj === "FILING-CLM FILED")
        if (filedindex !== -1) {
        } else {
          statuses.push("FILING-CLM FILED")
        }
        let paidindex = statuses.findIndex(obj => obj === "RESULT-APPROVED")
        if (paidindex !== -1) {
        } else {
          statuses.push("RESULT-APPROVED")
        }
      } else {
        if (!this.state.Filed && !this.state.Paid && !this.state.Validated) {
          statuses = statuses.filter(function (el) {
            return el !== "MAPPING-READY TO FILE" && el !== "MAPPING-REFER TO SLRCOMMS/LGL" && el !== "RESULT-APPROVED" && el !== "FILING-CLM FILED"
          });
        }
      }

      statuses = statuses
    }


    await this.setState({
      statuses: statuses
    })
  };

  // clear input data
  clearInputFields = () => {
    this.props.reset();
  }
  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  callValidateFunction = () => {
    return (
      <ValidatedProperties
        EstateID={this.state.EstateID}
      />
    )
  }

  submit = async (values) => {
    await this.setState({
      hitApiCalls: false,
      EstateID: this.state.GetEstateID
    })
    // let OpportunitySizeQuery = `${apiCalls.execute}?query= 
    //               SELECT
    //                 ES.Name
    //               FROM
    //                 dbo.Estate AS E,
    //                 dbo.EstateSize AS ES
    //               WHERE
    //                 E.SizeId = ES.Id
    //               AND
    //                 E.Id in ('${this.state.EstateID}')
    //               `;
    let OpportunitySizeQuery = `${apiCalls.OpportunitySizeQuery}?EstateID=${this.state.EstateID}`
    fetch("GET", OpportunitySizeQuery)
      .then(async (response) => {
        this.setState({
          OpportunitySize: response.results[0].Name
        })
      }).catch((err) => {
        return err;
      });

    // let query1a = `${apiCalls.execute}?query= 
    //               SELECT 
    //                 AD.AddressdetailID as AddressDetailID, 
    //                 EN.id as 'EstateNameID',
    //                 ea.EstateID as 'ESID',
    //                 nam.text as 'ESNAME',
    //                 en.Issues,
    //                 ad.MailForwardNotesEstate as 'Exhibit',
    //                 A.Line1 as 'ESADDRESS',
    //                 A.City as 'ESCity',
    //                 S.Abbreviation as 'ESSTATE',
    //                 A.Zip as 'ESZip'
    //               FROM
    //                 EstateToAddress(nolock) EA
    //                 inner join Estate(nolock) E on EA.EstateId = E.Id
    //                 Inner Join Address(nolock) A on A.Id = EA.AddressId
    //                 Inner Join AddressDetail(nolock) AD on AD.EstateToAddressID = EA.Id
    //                 inner join estatename EN(nolock) on ea.estatenameid = en.id
    //                 inner join name nam(nolock) on en.nameid = nam.id
    //                 left join issueflags isf(nolock) on en.issues = isf.id
    //                 Left Join State(nolock) S on S.Id = A.StateId
    //               WHERE
    //                 EA.EstateID = ${this.state.EstateID} 
    //               and 
    //                 isf.searchable = 1
    //               `;
    // fetch("GET", query1a)
    //   .then(async (response) => {
    //     const uniqueNames = [...new Set(response.results.map(item => item.ESNAME))];
    //     const uniqueAddress = [...new Set(response.results.map(item => item.ESADDRESS))];
    //     this.setState({
    //       NamesAdded: uniqueNames.length,
    //       Addresses: uniqueAddress.length
    //     })
    //   }).catch((err) => {
    //     return err;
    //   });


    // let query1b = `${apiCalls.execute}?query= 
    //               SELECT
    //                 AD.AddressdetailID as AddressDetailID,
    //                 EN.id as 'EstateNameID',
    //                 ea.EstateID as 'ESID',
    //                 nam.text as 'ESNAME',
    //                 en.Issues,
    //                 ad.MailForwardNotesEstate as 'Exhibit',
    //                 A.Line1 as 'ESADDRESS',
    //                 A.City as 'ESCity',
    //                 S.Abbreviation as 'ESSTATE',
    //                 A.Zip as 'ESZip'
    //               FROM
    //                 EstateToAddress(nolock) EA
    //                 inner join Estate(nolock) E on EA.EstateId = E.Id
    //                 Inner Join Address(nolock) A on A.Id = EA.AddressId
    //                 Inner Join AddressDetail(nolock) AD on AD.EstateToAddressID = EA.Id
    //                 inner join estatename EN(nolock) on ea.estatenameid = en.id
    //                 inner join name nam(nolock) on en.nameid = nam.id
    //                 left join issueflags isf(nolock) on en.issues = isf.id
    //                 Left Join State(nolock) S on S.Id = A.StateId
    //               WHERE
    //                 EA.EstateID = ${this.state.EstateID}
    //               and
    //                 isf.searchable = 1 --1a
    //               And
    //                 en.issues in ('2', '7') --1b
    //               `;
    // fetch("GET", query1b)
    //   .then(async (response) => {
    //     const uniqueIssues = [...new Set(response.results.map(item => item.Issues))];
    //     this.setState({
    //       IssueNames: uniqueIssues.length
    //     })
    //   }).catch((err) => {
    //     return err;
    //   });


    // let query2 = `${apiCalls.execute}?query= 
    //               SELECT 
    //                 distinct count(e2p.StateId) as 'States with Results'
    //               FROM 
    //                 EstateToPropertySearchInfo e2p with (nolock) 
    //               WHERE 
    //                 EstateId = ${this.state.EstateID}  
    //               AND
    //                 e2p.MatchStatusId = 400
    //               `;
    // fetch("GET", query2)
    //   .then(async (response) => {
    //     this.setState({
    //       query2Results: response.results[0]['States with Results']
    //     })
    //   }).catch((err) => {
    //     return err;
    //   });


    // let query3 = `${apiCalls.execute}?query= 
    //             SELECT 
    //               count(*) as 'Manual Properties, Pending Validation'
    //             FROM 
    //               EstateToPropertySearchInfo with (nolock) 
    //             WHERE 
    //               estateid = ${this.state.EstateID}  
    //             and 
    //               MatchStatusId = 400

    //               `;
    // fetch("GET", query3)
    //   .then(async (response) => {
    //     this.setState({
    //       query3Results: response.results[0]["Manual Properties, Pending Validation"]
    //     })
    //   }).catch((err) => {
    //     return err;
    //   });


    // let query4 = `${apiCalls.execute}?query= 
    //             SELECT count (updon.id) as 'Total Loaded by Finac and Integrity'
    //               FROM [dbo].[UploadRow_Done] updon
    //               with (nolock)
    //               left join UploadFile upfile  (nolock) on updon.uploadfileid = upfile.id
    //               left join AzureAdUser adus (nolock) on upfile.UploadedByUserId = adus.id
    //               where UploadedByUserId in ('2931607C-3392-4743-B81B-92B2B3718887',
    //               '54A40BA5-8B7A-40D8-9A30-11F10D6CC245',
    //               '64A6DFE3-EB95-4A6F-AF5D-059A3338A5F4',
    //               '50F93300-F4AE-4D7E-84E3-E9881DDC38BE',
    //               '6f47a37f-2df5-4752-99e2-1aaa73b5a8bd',
    //               '86929db3-7a49-4000-886d-bce09300dfa7',
    //               '93a2f628-768a-49b3-92f5-6f41ba14de85',
    //               'bcceac65-6079-4a8a-baa2-987eccde74c2',
    //               'e4738378-ad32-4c16-849a-1e6a76a75374',
    //               '6c97a2eb-5fd6-4ad7-9756-b91ad69e21f3',
    //               'e0d245f4-c3da-44ec-bcbb-4a8c0b9dc0f9',
    //               'd9020675-b760-43bb-8b6d-c5118f6159e6',
    //               'd5c1bc3f-9034-4b40-8a99-4e54c784de74',
    //               '237A509F-8F04-4829-85AF-9382B7FF8782',
    //               'B2B5B299-F6C2-4EC2-B8D9-7B7256DA623B',
    //               '49FA4118-C9B4-4176-99C9-0CB5938ED6C7',
    //               'F309E951-BB6E-4C13-A378-159342A685EE',
    //               '8AA13B5F-22D3-4CD4-B540-DFA802F96E35',
    //               '85AAC2B7-5BA8-4CB6-86EA-265EDE5582E1',
    //               'BAFF3565-FDED-4EA7-B7F0-BB9775C9964D',
    //               '22921229-78CD-4EED-8985-964280451ADD',
    //               '70545DC6-82F8-4830-B4A1-462EEC3E5E95',
    //               '18ECF6FD-64A8-45CD-BCE4-0836D7638E42',
    //               '32719882-277C-4C17-9DCD-0767AA8E69DB',
    //               'D2C9D0E7-8F0F-4734-AF3D-3E0475F77DE1',
    //               '5553197D-1F45-4EF3-8C20-80583C44B9D5',
    //               'b91191ab-71c6-494b-9ec4-336d2f646423',
    //               '9ab99e48-86f8-413d-8ddb-610819c45a1f',
    //               'C17DA363-95F8-4344-9068-4C599F96D89B'
    //             )  and match_estateid = ${this.state.EstateID}
    //               `;
    // fetch("GET", query4)
    //   .then(async (response) => {
    //     this.setState({
    //       query4Results: response.results.length
    //     })
    //   }).catch((err) => {
    //     return err;
    //   });


    let filterCriteria = {
      limit: 10,
      page: 1,
      criteria: [],
      sortfield: ("created"),
      direction: ('desc')
    }
    let tab3Criteria = []
    if (this.state.EstateID) {

      filterCriteria.criteria.push({ key: "CompanyID", value: this.state.EstateID, type: "eq" })
      tab3Criteria.push({ key: "EstateID", value: this.state.EstateID, type: "eq" })

    }
    let MatchMatchStatus = this.state.statuses
    if (MatchMatchStatus.length > 0) {
      filterCriteria.criteria.push({ key: "propertyStatus", value: MatchMatchStatus, type: "in" })
    }
    this.setState({
      filterCriteria: filterCriteria
    })

    fetch("GET", `${apiCalls.SellerSummary}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response.result.length === 1) {
          let val = Math.round(response.result[0]["EstimatedValueofProperties"]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          this.setState({
            totalPropertyCount: response.result[0]["totalPropertyCount"],
            EstimatedValueofProperties: "$" + val,
            NumberOfStateswithProperty: response.result[0]["NumberOfStateswithProperty"],
          })
        } else if (response.result.length === 0) {
          this.setState({
            totalPropertyCount: 0,
            EstimatedValueofProperties: 0,
            NumberOfStateswithProperty: 0,
          })
        }
      }).catch((err) => {
        return err;
      });

    this.setState({
      activeTab: "1",
      tab3Criteria: tab3Criteria,
      hitApiCalls: true,
    })
  }

  render() {
    const { handleSubmit } = this.props;
    // const { options } = this.state
    // let isSubmitting = false

    //
    return (
      <div>
        <div>
          <div style={{ display: 'block', border: "2px solid lightgrey" }} >
            <form className="form m-4" style={{ backgroundColor: "#ffffff", paddingTop: "20px", paddingBottom: "20px" }} onSubmit={handleSubmit(this.submit)}>
              <div className='row col-sm-12 m-0 p-0 d-flex'>
                <div className='col-sm-8 col-lg-8'>
                  <div className='col-sm-9' style={{ display: "flex" }}>
                    <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                      Seller{<div style={{ width: "40%" }}>
                        <span>
                          <FontAwesomeIcon icon={faTimes}
                            className='pl-1 mr-2'
                            color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            title="Clear Filter"
                            onClick={() => this.handleChange('selectedSeller', "Delete")}
                            style={this.state.selectedSeller ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                          /></span>
                      </div>}
                    </label>
                  </div>
                  <div className='col-sm-6 d-flex' style={{ padding: "initial" }}>
                    <div className='col-sm-9' >
                      <AutoComplete
                        itemTemplate={this.editorTemplateForDeal}
                        value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller&&this.state.selectedSeller.DealYear?this.state.selectedSeller.DealYear:""})` : this.state.selectedSeller}
                        // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                        suggestions={this.state.filteredSuggestions}
                        completeMethod={this.searchsellerItems}
                        minLength={1}
                        field={this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" ? "EntityName" : "EstateName"}
                        style={{ width: "100%", display: "flex" }}
                        dropdown={true}
                        onChange={(e) => this.handleChange(e, 'Seller')}
                        appendTo={document.body} />
                    </div>
                    <div className='col-sm-3 justify-content-center'>
                      <Button color="primary"
                        outline
                        className="mb-0"
                        style={this.state.setGoButton ? { color: "white", backgroundColor: '#a0a2a5', width: 115 } : (this.state.selectedSeller) ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
                        // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                        disabled={this.state.setGoButton ? true : (this.state.selectedSeller) ? false : true}
                        onClick={(e) => this.submit()}>
                        Go
                      </Button>
                    </div>
                  </div>
                  <Row className='ml-0'>
                    <div className='mt-2 col-sm-2 col-lg-2 pl-0 pr-0'>
                      <Checkbox
                        label='Unassigned Only'
                        type='checkbox'
                        // component={renderCheckBoxField}
                        name={'validated'}
                        className=" ml-3"
                        checked={this.state.Validated}
                        onChange={(e) => this.handleRadioChange(e, "Validated")}
                      />
                      <label htmlFor="cb3 col-sm-3" className="p-checkbox-label" style={{ fontSize: 13 }}>{"Validated"}</label>

                    </div>
                    <div className='mt-2  col-sm-3 col-lg-3 pl-0 pr-0'>
                      <Checkbox
                        label='Unassigned Only'
                        type='checkbox'
                        // component={renderCheckBoxField}
                        name={'validated'}
                        className=" ml-2"
                        checked={this.state.ValidatedResourceNeeded}
                        onChange={(e) => this.handleRadioChange(e, "ValidatedResourceNeeded")}
                      />
                      <label htmlFor="cb3 col-sm-3" className="p-checkbox-label ml-2" style={{ fontSize: 13 }}>{"Validated - Resource Needed"}</label>

                    </div>
                    <div className='mt-2  col-sm-2 col-lg-2 pl-0 pr-0'>
                      <Checkbox
                        label='Unassigned Only'
                        type='checkbox'
                        // component={renderCheckBoxField}
                        name={'validated'}
                        className=" ml-2"
                        checked={this.state.Rejected}
                        onChange={(e) => this.handleRadioChange(e, "Rejected")}
                      />
                      <label htmlFor="cb3 " className="p-checkbox-label" style={{ fontSize: 13 }}>{"Rejected"}</label>
                    </div>
                    <div className='mt-2  col-sm-2 col-lg-2'>
                      <Checkbox
                        label='Unassigned Only'
                        type='checkbox'
                        // component={renderCheckBoxField}
                        name={'validated'}
                        className=" ml-2"
                        checked={this.state.Filed}
                        onChange={(e) => this.handleRadioChange(e, "Filed")}
                      />
                      <label htmlFor="cb3  " style={{ fontSize: 13 }} className="p-checkbox-label">{"Filed"}</label>

                    </div>
                    <div className='mt-2  col-sm-2 col-lg-2 pl-0 pr-0'>
                      <Checkbox
                        label='isexcludePossiblyPaid'
                        type='checkbox'
                        // component={renderCheckBoxField}
                        name={'isexcludePossiblyPaid'}
                        className=" ml-2"
                        checked={this.state.Paid}
                        onChange={(e) => this.handleRadioChange(e, "Paid")}
                      />
                      <label htmlFor="cb3 " className="p-checkbox-label" style={{ fontSize: 13 }}>{"Paid"}</label>
                    </div>
                    <div className='mt-2  col-sm-1 col-lg-1 pl-0 pr-0'>
                      <Checkbox
                        label='isexcludePossiblyPaid'
                        type='checkbox'
                        // component={renderCheckBoxField}
                        name={'isexcludePossiblyPaid'}
                        className=" ml-2"
                        checked={this.state.All}
                        onChange={(e) => this.handleRadioChange(e, "All")}
                      />
                      <label htmlFor="cb3" className="p-checkbox-label" style={{ fontSize: 13 }}>{"All"}</label>
                    </div>
                  </Row>
                </div>

                <Styles className="col-sm-4 col-lg-4">
                  <table style={{ width: "90%" }}>
                    <tr>
                      <td>Pre-Deal Sizing</td>
                      <td>{this.state.OpportunitySize}</td>
                    </tr>
                    <tr>
                      <td>Positively Validated Properties
                        <FontAwesomeIcon icon={faQuestionCircle}
                          className='ml-2'
                          color='black' s
                          data-toggle="tool-tip"
                          title={"Properties that are marked “Validated-Ready To File” and “MAPPED-SLR NOTICE RQRD”"}
                          style={{ width: 18 }}
                        />
                      </td>
                      <td>{this.state.totalPropertyCount}</td>
                    </tr>
                    <tr>
                      <td>Estimated value of properties</td>
                      <td>{this.state.EstimatedValueofProperties}</td>
                    </tr>
                    <tr>
                      <td>Number of states with properties</td>
                      <td>{this.state.NumberOfStateswithProperty}</td>
                    </tr>
                  </table>
                </Styles>

              </div>

            </form>
          </div >
        </div >
        {/* <div className='col-sm-3 justify-content-center' style={{ marginTop: "1px", marginLeft: "1000px" }}>
          <Button color="primary"
            outline
            className="mb-0"
            style={this.state.totalPropertyCount ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
            // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
            disabled={this.state.totalPropertyCount ? false : (this.state.totalPropertyCount) ? false : true}
            onClick={(e) => this.ShowGraph()}
          >
            ShowGraph
          </Button>
        </div> */}

        <div style={{ width: "100%", backgroundColor: "white" }}>
          <Nav tabs >
            {/* <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
              >
                Summary
              </NavLink>
            </NavItem> */}
            {/* <NavItem >
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
                disabled={this.state.isTabdisabled || this.state.isChildTrue}
              >
                Validated Prop. By State
              </NavLink>
            </NavItem> */}
            <NavItem >
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
                disabled={this.state.isTabdisabled || this.state.isChildTrue}
              >
                {"Summary View"}
              </NavLink>
            </NavItem>
            <NavItem >
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
                disabled={this.state.isTabdisabled || this.state.isChildTrue}
              >
                {"Detailed Property View"}
              </NavLink>
            </NavItem>
            <NavItem >
              <NavLink
                className={classnames({ active: this.state.activeTab === '3' })}
                onClick={() => { this.toggle('3'); }}
                disabled={this.state.isTabdisabled || this.state.isChildTrue}
              >
                {"Business Names Prop Count View"}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            {/* < TabPane tabId="1">
              {this.summaryTab()}
            </TabPane>
            <TabPane tabId="2">
              {
                this.callValidateFunction()
                // this.state.EstateID ?

                // : null
              }
            </TabPane> */}
            <TabPane tabId="1">
              {
                this.state.hitApiCalls ?
                  <ValidatedProperties
                    EstateID={this.state.EstateID}
                    criteria={this.state.filterCriteria.criteria}
                  /> : null}
            </TabPane>
            <TabPane tabId="2">
              {this.state.hitApiCalls ?
                <DetailedPropertyView
                  EstateID={this.state.EstateID}
                  criteria={this.state.filterCriteria.criteria}
                />
                : null
              }
            </TabPane>
            <TabPane tabId="3">
              {this.state.hitApiCalls ?
                <NamesPropCount
                  EstateID={this.state.EstateID}
                  criteria={this.state.tab3Criteria}
                />
                : null
              }
            </TabPane>

          </TabContent>
          {/* {this.state.graph ? <Redirect to="/charts" /> : ""} */}
          {this.state.graph ? <Redirect to={`/charts/${this.state.EstateID}`} /> : null}

        </div >
      </div >
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SettingsForm = reduxForm({
  form: "Common Form", // a unique identifier for this form
  // validate,
  enableReinitialize: true,
})(SettingsForm);

// You have to connect() to any reducers that you wish to connect to yourself
SettingsForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  // {
  //   load: loadAccount,// bind account loading action creator
  //   settingsLoad: settings,
  // }
)(SettingsForm);

export default SettingsForm;





