
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
// fecth method from service.js file
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../config/apiCalls';
import fetch from '../../config/service';
import dateFormats from '../UI/FormatDate/formatDate';
import { withTranslation } from 'react-i18next';
import { AutoComplete } from 'primereact/autocomplete'
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExcelReact from "../Cruds/CommonDataTable/ExcelReact"
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton';
import { Redirect } from 'react-router';
import configMessages from '../../config/configMessages';
import moment from 'moment-timezone';
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // paddingTop: 2,
  // paddingBottom: 2,
  paddingLeft: 5,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? 'grey' : 'lightgrey',
  border: '1px solid white',
  borderRadius: 10,
  // styles we need to apply on draggables
  ...draggableStyle,

});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  display: 'flex',
  flexWrap: 'wrap',
  width: '200px',
  padding: grid,
  overflow: 'auto',
  border: '1px solid black',
  borderRadius: 10,
  wordBreak: 'break-word',
  height: "auto",
  textOverflow: 'ellipsis',
});
let id2List = {
  droppable: 'selectTableFields',
  droppable2: 'notSelectedTableFields'
};

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class StatisticsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOpenSort: false,
      exportData: [],
      ValidationStatus: "all",
      FilterSortOptions: [
        { label: 'Deal Name', value: "EstateName" },
        { label: 'Business Name', value: "BusinessName" },
        { label: 'Property Name', value: "PropertyName" },
        { label: 'Source State', value: "SourceState" },
        { label: 'Property City', value: "PropertyCity" }
      ],
      propertyStatus: ["EstateName", "BusinessName", "PropertyName", "SourceState", "PropertyCity"]

    };
    localStorage.removeItem("selectedDeal")
  }

  componentDidMount = async () => {
    let loginData = localStorage.getItem("loginCredentials");
    loginData = JSON.parse(loginData);
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.searchSellerItems()

  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getDownloadData = async (data) => {
    await this.setState({
      downloadData: data
    })
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }
  searchSellerItems = async (event, criteria) => {
    let apiUrl
    let filterCriteria = this.state.filterCriteria
    if (event && event.query) {
      apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query)}`
    } else {
      apiUrl = `${apiCalls.propertysellers} `
    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });

        }
      }).catch((err) => {
        return err;
      });
  }

  // clear input data
  clearInputFields = () => {
    this.props.reset();
  }

  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  lastUpdatedDate = (e) => {
    this.setState({ lastUpdatedDate: dateFormats.formatDate(e, "MM/DD/YYYY") })
  }

  orderChange = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!result.destination) {
      return;
    }
    const tableFields = this.orderChange(this.state.propertyStatus, result.source.index, result.destination.index);
    await this.setState({
      propertyStatus: tableFields,
      changedTableFields: tableFields,
      setGoButton: false
    })
  }


  submit = async (values) => {
    if (values === "Go") {
      if (this.state.selectedDeal && (this.state.selectedDeal !== "" || this.state.selectedDeal === null) && this.state.selectedminscore && (this.state.selectedminscore !== "" || this.state.selectedminscore === null) && this.state.selectedminscore >= "93") {
        await this.setState({
          setGoButton: true
        })
        let filterCriteria = this.props.filterCriteria
        let CompanyID = filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyID")
        if (CompanyID !== -1) {
          filterCriteria["criteria"].splice(CompanyID, 1)
        }
        let NameMatchScore = filterCriteria["criteria"].findIndex(obj => obj.key === "NameMatchScore")
        if (NameMatchScore !== -1) {
          filterCriteria["criteria"].splice(NameMatchScore, 1)
        }
        localStorage.setItem("selectedDeal", this.state.selectedDeal.EstateID)
        if (this.state.selectedDeal && (this.state.selectedDeal !== "" || this.state.selectedDeal === null)) {
          filterCriteria.criteria.push({ key: "CompanyID", value: this.state.selectedDeal.EstateID, type: "eq" })
        } if (this.state.selectedminscore && this.state.selectedminscore.length > 0) {
          filterCriteria.criteria.push(
            { key: "NameMatchScore", value: this.state.selectedminscore, type: "gt" })
        }
        let groupBy = this.state.propertyStatus && this.state.propertyStatus.length > 0 ? encodeURIComponent(this.state.propertyStatus.join(", ")) : "CompanyName, BusinessName, PropertyName, SourceState, PropertyCity"
        let data = []
        if (this.props.activeTab === "Summary") {

          data = await this.changeingColumnFieldsOrder()
        }

        this.props.getFilteredValues(filterCriteria, data, this.state.ValidationStatus, groupBy)
      } else if (this.state.selectedminscore && this.state.selectedminscore < "93") {
        this.props.getErrorMessage("Ensure that the Min. Name Match Score is greater than 93.")
      } else {
        this.props.getErrorMessage("Please select deal and Min. Name Match Score")
      }
    }
  }

  changeingColumnFieldsOrder = () => {

    let tableFields = this.props.tableFieldsToShow
    // if (this.props.originalTableFields.length === tableFields.length) {
    //   tableFields = tableFields
    // } else {
    tableFields = this.props.getTableFields
    // }
    if (this.props.activeTab === "Summary") {
      let dataindex
      let tablenotKnownFields = tableFields.filter(function (el) {
        return !el.field.includes("Count") || !el.field.includes("Value")
      });

      for (var item of tablenotKnownFields) {
        if (this.state.propertyStatus.includes(item.field)) {
          dataindex = tableFields.findIndex(obj => obj.field === item.field)
          tableFields[dataindex].show = true
        } else if (!this.state.propertyStatus.includes(item.field) && !item.field.includes("Count") && !item.field.includes("Value")) {
          dataindex = tableFields.findIndex(obj => obj.field === item.field)
          tableFields[dataindex].show = false
        }
      }
      let sortedOrder = this.state.propertyStatus
      let data = [], index
      let knownFields = []
      for (var record of sortedOrder) {
        index = tableFields.findIndex(obj => obj.field === record)
        data.push(tableFields[index])
      }
      knownFields = tableFields.filter(function (el) {
        return el.field.includes("Count") || el.field.includes("Value")
      });
      tableFields = [...data, ...knownFields]
      let xlsx_headers = localStorage.getItem("tableFields_download") ? localStorage.getItem("tableFields_download") : {}
      xlsx_headers = { [this.props.activeTab]: tableFields }
      localStorage.setItem("tableFields_download", JSON.stringify(xlsx_headers))
    }
    return tableFields


  }

  getShowErrorMessage = async (type) => {
    this.props.getErrorMessage("Please select deal and Min. Name Match Score", "removingSeller", "error")
    await this.setState({
      downloadData: "", setGoButton: false, [type]: ""
    })
    if (type === "selectedDeal") {
      await this.setState({
        selectedminscore: ""
      })
      localStorage.removeItem("selectedDeal")

    }
  }
  selectionDeal = async (e) => {
    this.setState({ selectedDeal: e.value, downloadData: "", setGoButton: false })
  }
  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <div style={{ display: 'block', border: "2px solid lightgrey" }} >
          <form className="form" style={{ backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px" }} onSubmit={handleSubmit(this.submit)}>
            <div className='row col-sm-12 m-0 p-0 d-flex'>
              <div className='col-sm-3 col-lg-4'>

                <div style={{ padding: "initial" }}>
                  <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                    Deal Name
                    <span>
                      <FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.selectedDeal ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.getShowErrorMessage("selectedDeal")}
                        style={this.state.selectedDeal ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      /></span>

                  </label>
                  <div >
                    <AutoComplete
                      itemTemplate={this.editorTemplateForDeal}
                      value={this.state.selectedDeal && this.state.selectedDeal.EstateName ? `${this.state.selectedDeal.DealType} - ${this.state.selectedDeal.SellerNickName} (${this.state.selectedDeal&&this.state.selectedDeal.DealYear?this.state.selectedDeal.DealYear:""})` : this.state.selectedDeal}
                      // value={this.state.selectedDeal && this.state.selectedDeal.EstateName ? this.state.selectedDeal.EstateName : this.state.selectedDeal}
                      suggestions={this.state.filteredSuggestions ? this.state.filteredSuggestions : []}
                      completeMethod={this.searchSellerItems}
                      minLength={1}
                      field={"EstateName"}
                      style={{ width: "100%" }}
                      dropdown={true}
                      onChange={(e) => this.selectionDeal(e)}
                      // onChange={(e) => this.setState({ selectedDeal: e.target.value, downloadData: "", setGoButton: false })}
                      appendTo={document.body} />

                  </div>
                  <div className='d-flex mt-2'>
                    <div><b><span>Last Scored Date    </span></b>{this.props.lastScoredDate ? this.props.lastScoredDate : ""}</div>
                  </div>


                </div>
              </div>
              <div className='col-sm-2 col-lg-2'>

                <div style={{ padding: "initial" }}>
                  <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                    Min. Name Match Score
                    <span>
                      <FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.selectedminscore ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.getShowErrorMessage("selectedminscore")}

                        // onClick={() => this.setState({ selectedminscore: "", downloadData: "", setGoButton: false })}
                        style={this.state.selectedminscore ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      /></span>

                  </label>
                  <div >
                    <InputText
                      value={this.state.selectedminscore}
                      keyfilter={"pint"}
                      onChange={(e) => this.setState({ selectedminscore: e.target.value, downloadData: "", setGoButton: false })} />
                  </div>
                </div>
              </div>
              <div className='col-sm-1 justify-content-center ' style={{ margin: "0px 0px", width: "8%" }}>
                <label style={{ marginBottom: 4, width: "100%", display: "flex", color: "white" }}>
                  Min.
                </label>
                <Button color="primary"
                  outline
                  className="mb-0"
                  style={this.state.setGoButton ? { color: "white", backgroundColor: '#a0a2a5' } : { color: "white", backgroundColor: '#354f6e' }}
                  // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                  disabled={this.state.setGoButton ? true : false}
                  onClick={() => this.submit("Go")}>
                  Go
                </Button>
              </div>
              <div
                className="p-inputgroup ml-4" style={{ display: "grid", width: 200 }}
              >
                <span>Group By</span>
                <MultiSelect
                  className={"hideheader"}
                  style={{ width: "100%", height: 32 }}
                  appendTo={document.body}
                  disabled={this.props.activeTab === "Data" ? true : false}
                  value={this.state.propertyStatus}
                  maxSelectedLabels={1}
                  options={this.state.FilterSortOptions}
                  onChange={(e) => this.setState({ propertyStatus: e.value, setGoButton: false })} />
              </div>
              <div style={{ display: "grid", width: 200 }}>
                <div
                  className="p-inputgroup ml-2" style={this.props.activeTab === "Data" ? { display: "none" } : { display: "flex" }}
                >
                  <span>Sort By</span>
                  <span>
                    <FontAwesomeIcon icon={faExternalLinkAlt}
                      className='pl-1'
                      data-toggle="tool-tip"
                      // validate={[required]}
                      title={this.state.propertyStatus && this.state.propertyStatus.length > 0 ? "click to change the sorting order" : "please select the sort by fields before clicking "}
                      style={{ width: 20, color: "grey" }}
                      onClick={() => this.setState({ isOpenSort: !this.state.isOpenSort, setGoButton: false })}
                    />
                  </span>
                </div>
                <div
                  style={this.props.activeTab !== "Data" && this.state.isOpenSort && this.state.propertyStatus && this.state.propertyStatus.length > 0 ? { display: "block" } : { display: "none" }}>
                  <div className=''
                  >
                    <DragDropContext onDragEnd={this.onDragEnd} >

                      <div className='col-12 col-lg-6 col-md-10 col-sm-12 '>
                        <Droppable droppableId="droppable" direction="vertical" >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                              {...provided.droppableProps}
                              className='col_Order'
                            >
                              {this.state.propertyStatus && this.state.propertyStatus.map((item, index) => {
                                if (item) {
                                  return <div className='col-sm-12 px-0 pb-2' >
                                    <Draggable key={item} draggableId={item} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >

                                          <span>{item === "EstateName" ? "Deal Name" : item}</span>
                                        </div>
                                      )}

                                    </Draggable>
                                  </div>
                                }
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>



                    </DragDropContext>
                  </div>
                </div>

              </div>

              {this.state.downloadData && this.state.downloadData.length > 0 ?
                <div className='ml-auto mr-2' data-toggle="tool-tip" title="click here to download data in xlsx format">
                  <ExcelReact
                    type="Deal Statistics"

                    sheets={this.state.downloadData}
                    EstateName={this.state.selectedDeal && this.state.selectedDeal.EstateName ? this.state.selectedDeal.EstateName : "Deal"} />
                </div>
                : null}

            </div>
            <div className='row col-12 d-flex mt-2'>
              <div className='col-2' style={{ position: "relative" }}>
                <b>Validation Status:</b>
              </div>
              <div className='col-1' style={{ position: "relative" }}>
                <RadioButton
                  inputId='all'
                  name='all'
                  value='all'
                  onChange={() => this.setState({ ValidationStatus: "all", setGoButton: false })}
                  checked={this.state.ValidationStatus === 'all' ? true : false}

                // checked={ofcType === 'Company'}
                />
                <label className='p-radiobutton-label'>
                  All
                </label>
              </div>
              <div className='col-4 '>
                <RadioButton
                  inputId='positivelyValidated'
                  name='positivelyValidated'
                  value='positivelyValidated'
                  onChange={() => this.setState({ ValidationStatus: "positivelyValidated", setGoButton: false })}

                  // onChange={(e) => { setOfcType((e).value) }}
                  checked={this.state.ValidationStatus === 'positivelyValidated' ? true : false}
                />
                <label className='p-radiobutton-label'>
                  Only Positively Validated, Filed, Paid
                </label>
              </div>
              <div className='col-3' style={{ position: "relative" }}>
                <RadioButton
                  inputId='onlyFiledandPaid'
                  name='onlyFiledandPaid'
                  value='onlyFiledandPaid'
                  onChange={() => this.setState({ ValidationStatus: "onlyFiledandPaid", setGoButton: false })}
                  // onChange={(e) => { setOfcType((e).value) }}
                  checked={this.state.ValidationStatus === 'onlyFiledandPaid' ? true : false}
                />
                <label className='p-radiobutton-label'>
                  Only Filed or Paid
                </label>
              </div>            </div>
          </form>
        </div >
      </div >
    );
  }
}



StatisticsForm = reduxForm({
  form: "User Password Reset Form", // a unique identifier for this form
  enableReinitialize: true,
})(StatisticsForm);

// You have to connect() to any reducers that you wish to connect to yourself
StatisticsForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(StatisticsForm);

export default withTranslation('common')(StatisticsForm);