import React from 'react';
import moment from 'moment-timezone';
import { FormControl } from 'react-bootstrap';
import dateFormats from '../../../UI/FormatDate/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import config from '../../../../config/config'
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';

// import Select from './dropDownfield';
import { Checkbox } from 'primereact/checkbox';
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';
import configMessages from '../../../../config/configMessages';

import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'

// config file
export default class ResearchRequestViewers extends React.Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    this.applyCallback = this.applyCallback.bind(this);
    // this.closeMultiSelectDropdown = React.createRef();
    let dueStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let dueEndDate = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      type: 'Research Request Viewer',
      businessName: "",
      selectedAnalyst: "",
      start: start,
      end: end,
      dueStartDate: dueStartDate,
      dueEndDate: dueEndDate,
      selectedDepartment: "",
      selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
      filterRange: "",
      dateQuery: "",
      filterRangedueDate: "",
      unassignedOnly: false,


    };
    this.getDepartments()
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getDepartments = () => {
    let apiUrl;
    return fetchMethodRequest('GET', 'departments')
      .then(async (response) => {
        if (response && response.departments) {
          if (response && response.departments) {
            let labels = response.departments;
            let modifiedLabels = [];
            for (let label of labels) {
              modifiedLabels.push({
                label: label.departmentName,
                value: label.departmentName,
                departmentId: label._id

              })
            } const sorted = modifiedLabels.sort((a, b) => {
              let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            await this.setState({
              taskDepartmentItems: sorted
            })
            let user = JSON.parse(localStorage.getItem('loginCredentials'));

            let DepartmentArray = [], departMentIdArray = []
            // let user = JSON.parse(localStorage.getItem('loginCredentials'));
            if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
              DepartmentArray.push(user.department)
              departMentIdArray.push(user.departmentId)
              await this.setState({ selectedDepartment: DepartmentArray, departMentIdArray: departMentIdArray })
              await this.datatableref.setDepartement(DepartmentArray, departMentIdArray)
            }
          }
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  componentDidMount = () => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    let userName = loginCredentials.displayName
    if (userName.includes("FINAC".toLowerCase()) || userName.includes("Integrity".toLowerCase())) {
      this.setState({
        isShowunAssign: true
      })
    }
  }

  /**
 * 
 * @param {Object} e 
 * @param {String} key 
 * @param {String} selectedtype 
 * setting the 
 */
  async ontaskFilterChange(e, key, selectedtype) {
    var val
    var criteriaData
    criteriaData = this.datatableref.getFiltercriteria()
    if (e && e.persist) {
      e.persist();
    }

    if (e && e.target) {
      val = e.target.value
      if (key === "company") {
        await this.setState({
          businessName: val,
        })
      } else if (key === "assignTo") {
        await this.setState({
          selectedAnalyst: val,
        })
      } else if (key === "status") {
        val = e.value
        await this.setState({
          selectedStatus: val,
        })
      }
      let filtercriteria = {
        "key": key,
        "value": key === "status" || key === "department" ? val : encodeURIComponent(val),
        "type": selectedtype,
        "add_Filter": true
      }

      if (criteriaData.criteria &&
        criteriaData.criteria.length > 0) {
        for (let i = 0; i < criteriaData.criteria.length; i++) {
          let crit = criteriaData.criteria
          if ((crit[i].key === filtercriteria.key)) {
            crit.splice(i, 1)
          }
        }
      }
      if (val && val.length >= 1) {
        criteriaData["criteria"].push(filtercriteria)
      }
    }
    if (e.key === "Enter" || key === 'status' || key === "department") {
      this.datatableref.getDataFromServer(criteriaData);
    }

  }

  resettaskFilters = async () => {
    let criteriaData = await this.datatableref.getFiltercriteria()
    await this.setState({
      businessName: "",
      selectedAnalyst: "",
      selectedDepartment: "",
      selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
      filterRange: "",
      dateQuery: "",
      filterRangedueDate: ""
    })
    // criteriaData["criteria"] = []
    var removedObj = ["company", "assignTo", "department", "status"]
    for (var item of removedObj) {
      let obj = criteriaData.criteria.find(x => x.key === item && x.add_Filter === true);
      let index = criteriaData.criteria.indexOf(obj);
      criteriaData.criteria.splice(index, 1);
    }
    // await this.datatableref.onChangeDepartment(null, 'selectedDepartment')
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)

      await this.setState({ selectedDepartment: DepartmentArray, departMentIdArray: departMentIdArray })
      await this.datatableref.onChangeDepartment(DepartmentArray, 'selectedDepartment', departMentIdArray)
    } else {
      this.setState({ selectedDepartment: DepartmentArray.length > 0 ? DepartmentArray : null, departMentIdArray: departMentIdArray.length > 0 ? departMentIdArray : null })
      await this.datatableref.onChangeDepartment(DepartmentArray, 'selectedDepartment', departMentIdArray)

    }
    this.datatableref.getDataFromServer(criteriaData, "resetDateQuery")
  }

  handleunassigned = async (e) => {
    let criteriaData = await this.datatableref.getFiltercriteria()
    await this.setState({
      unassignedOnly: !e
    })
    await this.datatableref.onChangeDepartment(!e, 'unassignedOnly')
    setTimeout(async () =>
      await this.datatableref.getDataFromServer(criteriaData)
    )

  }
  onChangeDepartment = async (e) => {
    let criteriaData = await this.datatableref.getFiltercriteria()
    let DepartmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        DepartmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    this.setState({ departMentIdArray: departmentIdArray, selectedDepartment: e.value, })
    // await this.setState({
    //   selectedDepartment: e.value,
    // })
    await this.datatableref.onChangeDepartment(e.value, 'selectedDepartment', departmentIdArray)
    await this.datatableref.getDataFromServer(criteriaData)

  }

  applyCallbackfordueDate = async (startDate, endDate) => {
    var d1 = moment(startDate).format(config.dbOnlyDateFormat);
    var d2 = moment(endDate).format(config.dbDateFormat);
    await this.setState({
      dueStartDate: startDate,
      dueEndDate: endDate,
      filterRangedueDate: this.state.range === "Overdue" ? `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')}` : `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')} to ${dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY')} `
    })
    let criteria = await this.datatableref.getFiltercriteria()

    let filtercriteria = criteria.criteria.filter(function (el) {
      return el.key !== "dueDate"
    });
    criteria.criteria = filtercriteria
    if (this.state.range !== "Overdue") {
      criteria.criteria.push({
        key: "dueDate",
        value: d1,
        type: "gte",
        add_Filter: true
      })
      criteria.criteria.push({
        key: "dueDate",
        value: d2,
        type: "lt",
        add_Filter: true
      })
    } else {
      criteria.criteria.push({
        key: "dueDate",
        value: d2,
        type: "lt",
        add_Filter: true
      })
    }
    this.datatableref.getDataFromServer(criteria);
  }

  getSelectedStatus=()=> {
    return this.state.selectedStatus
  }

  gettaskFilters = (handleSubmit) => {
    let now = new Date();
    let month = now.getMonth()
    let year = now.getFullYear()
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    //number of days in last month
    let y = new Date(year, month - 1, 0).getDate();
    let dueStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let dueEndDate = moment(start).add(1, "days").subtract(1, "seconds");
    let t = now.getDate() - 1 + y + 2;
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
      "This Week(sun-Today)": [moment(start).subtract(now.getDay(), "days"), moment(end)],
      "Last 7 days": [moment(start).subtract(7, "days"), moment(end).subtract(1, "days")],
      "Last Week(Sun-Sat)": [moment(start).subtract(now.getDay() + 7, "days"), moment(start).subtract(now.getDay() + 1, "days")],
      "Last 14 days": [moment(start).subtract(14, "days"), moment(end).subtract(1, "days")],
      "This month": [moment(start).subtract((now.getDate() - 1), "days"), moment(end)],
      "Last 30 days": [moment(start).subtract(30, "days"), moment(end).subtract(1, "days")],
      "Last month": [moment(start).subtract(t, "days"), moment(start).subtract(now.getDate(), "days")],
      "last 3 Days": [moment(start).subtract(3, "days"), moment(end).subtract(1, "days")],
      "30 Days upto today": [moment(start).subtract(29, "days"), moment(end)],
      "All Time": [moment(start), moment(end)]
    }
    var next = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()));
    var getDate = new Date(next.setDate(next.getDate() + 6));

    var firstDay = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    var lastDay = new Date(now.getFullYear(), now.getMonth() + 2, 0);

    let dueRanges = {
      "Overdue": [moment(dueStartDate), moment(dueEndDate)],
      "Due in the Next Day": [moment(dueStartDate).add(1, "days"), moment(dueEndDate).add(1, "days").subtract(1, "seconds")],
      "Due in the Next Week": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()))), moment(getDate).add(1, "days").subtract(1, "seconds")],
      "Due in the Next Month": [moment(firstDay), moment(lastDay).add(1, "days").subtract(1, "seconds")],
      // "Due in the Next Month": [moment(firstDay), moment()],
    }
    let local = {
      "format": "MM-DD-YYYY HH:mm",
    }
    let maxDate = moment(end);
    return (

      <div className="form kanbanfilterDiv ml-0 mr-0">
        <div className="row filterKanbandiv" style={{ width: "100%" }}>
          <div className=" align-items-center pl-0" style={{ display: "inline-flex", width: "100%" }}>
            <div className="kanban_filter_field col-sm-2" >
              <label className="mr-1 kanban_filter_label" style={{ marginTop: 4 }}>
                Business:
              </label>
              <div className="" >
                <InputText
                  value={this.state.businessName}
                  name='company'
                  id="bussiness"
                  style={{ height: 33 }}
                  onKeyPress={(e) => this.ontaskFilterChange(e, 'company', 'regexOr')}
                  onChange={(e) => this.ontaskFilterChange(e, 'company', 'regexOr')}
                  size={"20"} />

              </div>
            </div>
            <div className="kanban_filter_field col-sm-2">
              <div className="d-flex">
                <label className="mr-auto kanban_filter_label" style={{ marginTop: 4 }}>
                  Analyst:
                </label>
                <div id='Unassigned Only'
                  style={!this.state.isShowunAssign ? { display: "block" } : { display: "none" }}
                >
                  <div className="">
                    <Checkbox
                      label='Unassigned Only'
                      type='checkbox'
                      id='Unassigned Only'
                      // component={renderCheckBoxField}
                      name={'unassigned'}
                      className="unassignCheckbox mb-0"
                      checked={this.state.unassignedOnly}
                      onChange={(e) => this.handleunassigned(this.state.unassignedOnly)}
                    />
                    <label htmlFor="cb3" className="p-checkbox-label">Unassigned Only</label>
                  </div>
                </div>
              </div>
              <div className="" id='assignTo'>
                <InputText
                  value={this.state.selectedAnalyst}
                  name='assignTo'
                  id='assignTo'
                  style={{ height: 33 }}
                  onKeyPress={(e) => this.ontaskFilterChange(e, 'assignTo', 'regexOr')}
                  onChange={(e) => this.ontaskFilterChange(e, 'assignTo', 'regexOr')}
                  size={"20"} />
              </div>
            </div>
            {/* <div className="kanban_filter_field col-sm-2" id='department'>
              <label className="mr-1 kanban_filter_label" id='department' style={{ marginTop: 4 }}>
                Department:
              </label>
              <div className="" style={{ width: "100%" }} >
                <MultiSelect
                  name="department"
                  id='department'
                  style={{ height: 33, width: "100%" }}
                  options={this.state.taskDepartmentItems}
                  value={this.state.selectedDepartment}
                  onChange={(e) => this.onChangeDepartment(e, 'department', 'eq')}
                  size={"20"} />
              </div>
            </div> */}
            <div className="kanban_filter_field col-sm-2" id="task_status">
              <label className="mr-1 kanban_filter_label" id='task_status' style={{ marginTop: 1 }}>
                Task Status:
              </label>
              <div className="form__form-group-input-wrap">
                <MultiSelect
                  id='task_status'
                  className={"form__form-group-field "}
                  style={{ width: "100%", height: 32 }}
                  value={this.state.selectedStatus}
                  options={config.taskFilterItems}
                  onChange={(e) => this.ontaskFilterChange(e, 'status', 'in')}
                  placeholder=''
                />
              </div>
            </div>
            <div className="kanban_filter_field col-sm-2" id='department'>
              <label className="mr-1 kanban_filter_label" id='department' style={{ marginTop: 4 }}>
                Team:
              </label>
              <div className="" style={{ width: "100%" }} >
                <MultiSelect
                  name="department"
                  id='department'
                  style={{ height: 33, width: "100%" }}
                  options={this.state.taskDepartmentItems}
                  value={this.state.selectedDepartment}
                  onChange={(e) => this.onChangeDepartment(e, 'department', 'eq')}
                  size={"20"} />
              </div>
            </div>
            {/* <div className="kanban_filter_field col-sm-2 mt-2">
              <label className="mr-1 kanban_filter_label">
                Task Created Date:
              </label>
              <DateTimeRangeContainer
                ranges={ranges}
                start={this.state.start}
                end={this.state.end}
                local={local}
                maxDate={maxDate}
                // enableButtons={true}
                onClick={this.onClick}
                onChange={this.applyCallback}
                applyCallback={this.applyCallback}
                rangeCallback={this.rangeCallback}
                leftMode
                noMobileMode
                style={{
                  customRangeButtons: { backgroundColor: "#357ABD" },
                  customRangeSelected: { backgroundColor: "#357ABD" },

                }}
              // 
              >
                <FormControl
                  id="formControlscreatedDate"
                  type="text"
                  label="Text"
                  placeholder="Task Created Date"
                  style={{ cursor: "pointer" }}
                  disabled={true}
                  className={"dateFilterForm mb-0"}
                  value={this.state.filterRange}
                />
              </DateTimeRangeContainer>
              <div style={{ height: 10 }}></div>
            </div> */}


            {/* <div className="kanban_filter_field">
              <label className="mr-1 kanban_filter_label">
                Due Date:
              </label>
              <DateTimeRangeContainer
                ranges={dueRanges}
                start={this.state.dueStartDate}
                end={this.state.dueEndDate}
                local={local}
                // maxDate={maxDate}
                // enableButtons={true}

                onClick={this.onClick}
                onChange={this.applyCallbackfordueDate}
                applyCallback={this.applyCallbackfordueDate}
                rangeCallback={this.rangeCallback}
                leftMode
                noMobileMode
                style={{
                  customRangeButtons: { backgroundColor: "#357ABD" },
                  customRangeSelected: { backgroundColor: "#357ABD" },

                }}
              >
                <FormControl
                  id="formControlsTextDuedate"
                  type="text"
                  label="Text"
                  placeholder="Due Date"
                  style={{ cursor: "pointer" }}
                  disabled={true}
                  className={"dateFilterForm mb-0"}
                  value={this.state.filterRangedueDate}
                />
              </DateTimeRangeContainer>

            </div> */}
            <div className="" id="clear">
              <span className=" " id="clear" style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faTimes}
                  id="clear"
                  style={{
                    width: 16,
                    height: 16,
                    margin: 5,
                    marginTop: 15,
                  }}
                  color="red"
                  onClick={(e) => this.resettaskFilters()}
                />
              </span>
            </div>
          </div>
        </div>
      </div >
    )
  }

  /**
   * 
   * @param {Object} startDate 
   * @param {Object} endDate 
   * applying the call back for dates to get data from server
   */

  applyCallback(startDate, endDate) {
    let dateStartRange = startDate.toISOString()
    let dateEndRange = endDate.toISOString()
    var d1 = new Date(dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY'))
    var d2 = new Date(dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY'))
    var startDate1 = moment(d1, "MM-DD-YYYY");
    var endDate2 = moment(d2, "MM-DD-YYYY");
    var result = endDate2.diff(startDate1, 'days');
    let dateQuery = {
      'startDate': dateStartRange, 'endDate': dateEndRange, type: this.state.range
    }
    this.setState({
      start: startDate,
      end: endDate,
      filterRange: result === 0 ? `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')}` : `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')} to ${dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY')} `,
      dateQuery: dateQuery,
      first: 0
    }
    )
    // this.state.filterCriteria.page = 1;
    this.datatableref.getDataFromServer(dateQuery, 'dateQuery');
  }

  getTableFields = () => {

    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": true,
        "displayInSettings": true,
        "sortable": false
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "dealType",
        "mobile": false,
        "header": "Deal",
        // "filterType": "num",
        "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "taskID",
        "mobile": false,
        "header": "Task Id",
        "filterType": "num",
        "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 200,
        "field": "company",
        "mobile": false,
        "header": "Lead Seller",
        "filter": true,
        "fieldType": 'Badge',
        "options": [{ "label": "New", "value": "New", "color": "danger" }, { "label": "Not Started", "value": "Not Started", "color": "danger" }, { "label": "Paused", "value": "Paused", "color": "danger" }, { "label": "In Progress", "value": "In Progress", "color": "success" }, { "label": "Pending", "value": "Pending", "color": "danger", }],
        "filterElement": [{ "label": "All", "value": null }, { "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "priority",
        "mobile": false,
        "header": "Priority",
        // "filterType": "num",
        "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "purpose",
        "mobile": false,
        "header": "Purpose",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "status",
        "mobile": false,
        "header": "Status",
        "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "requestDescription",
        "mobile": false,
        "header": "Request Description",
        "filter": true,
        // "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "researchRequestType",
        "mobile": false,
        "header": "Request Type",
        "fieldType": "Array",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "created",
        "mobile": false,
        "header": "Request Date(CT)",
        "filterType": "num",
        "filter": true,
        "dateFormat": "MM/DD/YYYY",
        "fieldType": 'Date',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "createdByUser",
        "mobile": false,
        "header": "Requestor",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "companyNameToResearch",
        "mobile": false,
        "header": "Company Names to Research",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      },

      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 140,
      //   "field": "subsidiaryEstates",
      //   "mobile": false,
      //   "header": "Subsidiary Estates",
      //   "filter": true,
      //   "fieldType": 'Array',
      //   "displayInSettings": true,
      //   // "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "companyAddressToResearch",
        "mobile": false,
        "header": "Company Addresses to Research",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 200,
        "field": "exhibit",
        "mobile": false,
        "header": "Exhibit (A, B, or Not Exhibited)",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "field": "propertyCount",
        "mobile": false,
        "header": "Property Count",
        "filter": true,
        "filterType": "num",
        "allowInt": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 130,
      //   "field": "unknownPropertyCount",
      //   "mobile": false,
      //   "header": "# of unknown properties",
      //   "filter": true,
      //   "filterType": "num",
      //   "allowInt": true,
      //   // "fieldType": 'Link',
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "field": "unknownPropertyCount",
        "mobile": false,
        "header": "# of unknown properties",
        // "filterType": "num",
        "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 100,
        "field": "totalMinPropertyValue",
        "fieldType": "formatCurrency",
        "mobile": false,
        "filterType": "num",
        "allowInt": true,
        "header": "Total Min Property Value",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 80,
      //   "field": "valueRFI",
      //   "mobile": false,
      //   "header": "Request chgjdhc Details",
      //   "filter": true,
      //   // "fieldType": 'StrArray',
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "assignTo",

        "mobile": false,
        "header": "Assign To",
        "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "notes",
      //   "mobile": false,
      //   "header": "Notes",
      //   "filter": true,
      //   // "fieldType": 'Badge',
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "field": "researchNotes",
        "mobile": false,
        "header": "Research Notes",
        "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },


      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 130,
      //   "field": "percentageDone",
      //   "mobile": false,
      //   "header": "Done",
      //   "filter": true,
      //   "fieldType": 'number',
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "completionDate",
        "mobile": false,
        "header": "Completion Date",
        "filterType": "num",
        "filter": true,
        "dateFormat": "MM/DD/YYYY",
        "fieldType": 'Date',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "sellerInputNeeded",
        "mobile": false,
        "header": "Seller Input Needed",
        // "filterType": "num",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "legalAction",
        "mobile": false,
        "header": "Legal Action",

        "filter": true,

        "displayInSettings": true,
        "sortable": true
      }
    ]
    return data;
  };

  getFormFields = () => {

    return
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          getSelectedStatus={this.getSelectedStatus}
          settingsRequired={false}
          filterRequired={true}
          sortDirection={"desc"}
          sortField={"prioritySequence, totalMinPropertyValue"}
          gridRequried={false}
          sample={false}
          gettaskFilters={this.gettaskFilters}
          // globalSearchFieldName='user'
          // globalSearch={'Search'}
          type='Research Request Viewer'
          isClearFilters={true}
          taskDepartmentItems={this.state.taskDepartmentItems}
          displayright={true}
          icon='paperclip'
          routeTo='ResearchRequestViewers'
          displayViewOfForm='modal'
          apiResponseKey='tasks'
          className={true}
          apiUrl={apiCalls.Tasks}
          entityType='employee'
        />

      </span>
    );
  }
}