
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
// import { CKEditor } from 'ckeditor4-react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Editor } from 'primereact/editor';
import { MultiSelect } from 'primereact/multiselect';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import classNames from 'classnames';
// fecth method from service.js file
import fetch from '../../../config/service';
import configMessage from '../../../config/configMessages';
// show message 
import DefaultInput from '../../../shared/components/form/DefaultInput';
import DatePicker from '../../../shared/components/form/DatePicker'

import CheckBox from '../../../shared/components/form/CheckBox';
// import validate from '../../Validations/validate';
import validate from '../../Form/validate';
import NameIssueCodeModal from '../../../containers/Cruds/CommonModals/NameIssueCodeModal';
import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';
import RenderFileInputField from '../../Form/components/FileUpload';
import Select from '../../../shared/components/form/Select';

import ModalFooter from 'reactstrap/lib/ModalFooter';
import { faArrowCircleLeft, faSave, faStarOfLife, faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiCalls from '../../../config/apiCalls';
import { Row } from 'react-bootstrap';
import { async } from '@firebase/util';
import { thisExpression } from '@babel/types';

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class NameModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: false,
            companyEdit: false,
            keyNameChecked: false,
            initailIssueName: "",
            namesArray: [],
            nameTypeArray: [],
            last2Notes: [],
            issueNameOptions: [],
            BusinessNameIssueCodeId: [],
            colored: false,
            storeProcId: "",
            ConnectedName: "",
            issueName: [
                { label: "No specific guidelines yet - 0", value: "0" },
                { label: "Date guidelines - 1.1", value: "1.1" },
                { label: "Geographic guidelines - 1.2", value: "1.2" },
                { label: "Other guidelines - 1.3", value: "1.3" },
                { label: "Research needed - 1.4", value: "1.4" },
                { label: "Code 7 Contingent - 1.7", value: "1.7" },
                { label: "Do not search or pursue - 2", value: "2" },
                { label: "Company employee - 3", value: "3" },
                { label: "CR OPP APA Modification - 7.1", value: "7.1" },
                { label: "Legal review - 7.2", value: "7.2" },
                { label: "Legal &/or Seller Consult - Signatory - 7.3", value: "7.3" },
                { label: "Legal &/or Seller Consult - Entity - 7.4", value: "7.4" },
                { label: "Legal &/or Seller Consult - JV - 7.5", value: "7.5" },
                { label: "Legal &/or Seller Consult - Foreign - 7.6", value: "7.6" },
                { label: "Blank", value: null }
            ],
            progress: 0,
            header: true,
            BusinessID: ""

        };
    }


    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }
    setNameIssueCode(body, values, details) {
        this.setState({ historyBusinessNameID: details.BusinessNamesID })
        let BusinessNameIssueCode = []
        let urlNames = `${apiCalls.nameIssueCodes}?businessNameID=${details.BusinessNamesID}`
        // return fetch("GET", urlNames)
        //     .then(async (response) => {
        //         if (response && response.nameIssueCodes && response.nameIssueCodes.length > 0) {

        //             for (let BusinessNameIssue of response.nameIssueCodes) {
        //                 BusinessNameIssueCode.push(BusinessNameIssue.BusinessNameIssueCode)
        //             }
        // this.props.change("issueNameName", BusinessNameIssueCode)
        // await this.setState({})
        // this.state.issueNameOptions

        let issueArray = this.props.itemDetails && this.props.itemDetails.IssueName ? this.props.itemDetails.IssueName.split(",") : []
        for (let id of issueArray) {
            let z = this.state.issueNameOptions.findIndex(elem => elem.value === id)
            if (z > -1) {
            } else {
                issueArray.splice(z, 1);
            }
        }
        this.setState({ issueNameName: issueArray ? issueArray : [], initailIssueName: this.props.itemDetails && this.props.itemDetails.IssueName ? this.props.itemDetails.IssueName.split(",") : [] })
        //     }
        // }).catch((err) => {
        //     return err;
        // });
    }
    setCompDetails = async (details) => {
        let z = this.state.namesArray.find(elem => elem.value === details.CompanyID)
        if (z) {
            this.props.change("companyNameName", z.label)
        }
        return true
    }
    setDetails(body, values, details) {
        let fields = ["ConnectionYear", "PBConnectionType", "PBConnectionYear", "ProposedLegalNameSource", "ActiveInactiveNotes",
            "SOSId", "SOSLastFilingDate", "DateCheckedforSOSLastFilingDate", "LegalNotes",
            "SEC10Kex21StartYear", "SEC10Kex21LastYear", "SECRegistrationStartYear",
            "SECRegistrationLastYear", "DatePreSalesUPResearch", "DatePreSalesUPRefresh", "DateIntegrityPreSaleResearch", "DateIntegrityPostSaleResearch",
            "IntegrityNotes", "APAExhibitPlacement", "APAExhibitPlacement", "SellerComments", "UPCountTotal",
            "UPValueTotal", "PaidRecoveries", "JudgmentFees", "JudgmentPreJudgmentInterest", "ConnectionTypeYear", "IncorporationDate", "InactivityDate", "EntityStatus", "EntityStatusSubType", "DateRestrictions", "SOSOfficers", "DateCheckedforSOSOfficers"]
        if (details) {
            for (let field of fields) {
                if (details[field]) {
                    this.props.change(field, details[field])
                }
            }
        } else {
            for (let field of fields) {
                if (values[field]) {
                    body[field] = values[field]
                }
            }
        }


        return body
    }
    IssueCodeTemplate(option) {

        return (
            <div className="p-clearfix">

                <span style={{ paddingRight: "5px" }}>{option.label} - {option.value}</span>
                <FontAwesomeIcon icon={faQuestionCircle} color="blue" title={option.title} />

            </div>
        );
    }
    uploadProgress = async (value) => {
        await this.setState({ isLoading: value })
        if (value && value === true) {
            this.handlePercentage('1')
        }
    }
    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    submit = (values) => {
        let body = {}
        this.setState({
            isLoading: true,
        })
        if (this.props.activeTab === "3" && this.props.itemDetails) {
            body.newModel = {}
            body.existModel = {}
            body.newModel.nameType = values.nameTypeName
            body.newModel.nameConnection = values.nameConnectionName
            if (this.state.companyEdit) {
                body.newModel.companyID = values.companyNameName
            } else {


                let z = this.state.namesArray.find(elem => elem.label === values.companyNameName)
                if (z) {
                    body.newModel.companyID = z.value
                }
            }
            body.newModel.businessName = values.businessNameName
            body.newModel.ConnectionYear = values.ConnectionYear
            body.newModel.nameSource = values.SourceName
            body.newModel.issueName = this.state.issueNameName ? this.state.issueNameName.join(",") : null
            body.newModel.issueCodes = this.state.BusinessNameIssueCodeId ? this.state.BusinessNameIssueCodeId.join(",") : null
            body.newModel.issueNote = this.state.issueNoteHtml
            body.newModel.GeneralRestrictions = this.state.GeneralRestrictions
            body.newModel.ConnectionYear = values.ConnectionYear
            body.newModel.PBConnectionType = values.PBConnectionType
            body.newModel.PBConnectionYear = values.PBConnectionYear
            body.newModel.ProposedLegalNameSource = values.ProposedLegalNameSource
            if (values.NameAIStatus === "Yes") {
                body.newModel.NameAIStatus = true
            } else if (values.NameAIStatus === "No") {
                body.newModel.NameAIStatus = false
            } else {
                body.newModel.NameAIStatus = values.NameAIStatus
            }
            body.newModel.ActiveInactiveNotes = values.ActiveInactiveNotes
            body.newModel.LegalNotes = values.LegalNotes
            body.newModel.SOSId = values.SOSId
            body.newModel.SOSLastFilingDate = values.SOSLastFilingDate
            body.newModel.DateCheckedforSOSLastFilingDate = values.DateCheckedforSOSLastFilingDate
            body.newModel.SOSOfficers = values.SOSOfficers
            body.newModel.DateCheckedforSOSOfficers = values.DateCheckedforSOSOfficers
            body.newModel.SEC10Kex21StartYear = values.SEC10Kex21StartYear
            body.newModel.SEC10Kex21LastYear = values.SEC10Kex21LastYear
            body.newModel.SECRegistrationStartYear = values.SECRegistrationStartYear
            body.newModel.SECRegistrationLastYear = values.SECRegistrationLastYear
            body.newModel.DatePreSalesUPResearch = values.DatePreSalesUPResearch
            body.newModel.DatePreSalesUPRefresh = values.DatePreSalesUPRefresh
            body.newModel.DateIntegrityPreSaleResearch = values.DateIntegrityPreSaleResearch
            body.newModel.DateIntegrityPostSaleResearch = values.DateIntegrityPostSaleResearch
            body.newModel.IntegrityNotes = values.IntegrityNotes
            body.newModel.APAExhibitPlacement = values.APAExhibitPlacement
            body.newModel.SellerComments = values.SellerComments
            body.newModel.UPCountTotal = values.UPCountTotal
            body.newModel.UPValueTotal = values.UPValueTotal
            body.newModel.PaidRecoveries = values.PaidRecoveries
            body.newModel.JudgmentFees = values.JudgmentFees
            body.newModel.JudgmentPreJudgmentInterest = values.JudgmentPreJudgmentInterest
            body.newModel.ConnectionTypeYear = values.ConnectionTypeYear
            body.newModel.IncorporationDate = values.IncorporationDate
            body.newModel.InactivityDate = values.InactivityDate
            body.newModel.EntityStatus = values.EntityStatus
            body.newModel.EntityStatusSubType = values.EntityStatusSubType
            body.newModel.DateRestrictions = values.DateRestrictions
            body.newModel.IsKey = this.state.keyNameChecked
            body.newModel.generalNote = this.state.generalNoteHtml
            body.newModel.BusinessID = JSON.stringify(this.state.BusinessID)
            body.existModel.nameType = this.props.itemDetails.NameType
            body.existModel.nameConnection = this.props.itemDetails.NameConnection
            body.existModel.companyID = this.props.itemDetails.CompanyID
            body.existModel.IsKey = this.props.itemDetails.IsKey
            body.existModel.businessName = this.props.itemDetails.BusinessName
            body.existModel.ConnectionYear = this.props.itemDetails.ConnectionYear
            body.existModel.nameSource = this.props.itemDetails.NameSource
            body.existModel.issueName = this.state.initailIssueName ? this.state.initailIssueName.join(",") : null
            body.existModel.GeneralRestrictions = this.props.itemDetails.GeneralRestrictions
            body.existModel.issueNote = this.props.itemDetails.IssueNote
            body.existModel.generalNote = this.props.itemDetails.GeneralNote
        }
        else if (this.props.activeTab === "3") {
            body.companyID = values.companyNameName
            body.businessName = values.businessNameName
            body.ConnectionYear = values.ConnectionYear
            body.nameConnection = values.nameConnectionName
            body.nameType = values.nameTypeName
            body.nameSource = values.SourceName
            body.ConnectedName = this.state.ConnectedName
            body.IsKey = this.state.keyNameChecked
            body.issueName = this.state.issueNameName ? this.state.issueNameName.join(",") : null
            body.issueCodes = this.state.BusinessNameIssueCodeId ? this.state.BusinessNameIssueCodeId.join(",") : null
            body.issueNote = this.state.issueNoteHtml
            body.GeneralRestrictions = this.state.GeneralRestrictions
            body.generalNote = this.state.generalNoteHtml
            body.BusinessID = JSON.stringify(this.state.BusinessID)
            this.setDetails(body, values)
        }

        let method, apiUrl
        if (this.state.type === 'edit') {
            method = 'PUT'
            apiUrl = `${apiCalls.addNames}`;
        } else {
            method = 'POST'
            apiUrl = this.props.activeTab === "3" ? apiCalls.addNames : apiCalls.addAddress
        }
        this.handlePercentage('1')
        return fetch(method, apiUrl, body)
            .then(async (response) => {
                let strArray = this.props.strArray ? this.props.strArray : null
                if (response && response.respCode && (response.respCode === 204 || response.respCode === 200 || response.respCode === 205)) {
                    let url1 = `${apiCalls.execute}?query=exec mbd.InsertUpdateCompanyNameAndAddress '${strArray}'`;

                    fetch("GET", url1)
                        .then(async (responseStr) => {

                        }).catch((err) => {
                            return err;
                        });
                    // display message
                    // showToasterMessage(response.respMessage, 'success');
                    this.cancelReset('save')
                    this.props.toggleNames(this.props.activeTab)
                    await this.setState({ GeneralRestrictions: "" })
                } else if (response && response.errorMessage) {

                    // display message
                    await this.setState({ apierrorResponse: true, apierrorMessage: response.errorMessage })

                }
                this.setState({
                    isLoading: false,
                })
                // this.props.getKanbanCards()
            }).catch((err) => {
                return err;
            });
    }

    cancelReset = (type) => {

        // this.props.load({})
        // this.props.reset();
        this.props.isCloseModal(type);
    }

    onChange = async (e) => {

    }
    connectedName = async (e) => {
        this.setState({ ConnectedName: e })
    }
    handleBusinessID = async (e) => {
        let z = this.state.namesArray.find(elem => elem.value === e)
        if (z) {
            await this.setState({ BusinessID: z.BusinessID, companyEdit: true, storeProcId: z.value })
        }
    }
    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }
    setText = (e) => {
        this.setState({ issueNoteHtml: e })
    }
    setGeneralNote = (e) => {
        this.setState({ generalNoteHtml: e })
    }

    setGeneralRestriction = (e) => {
        this.setState({ GeneralRestrictions: e })
    }
    keyNameChecked = (e) => {
        this.setState({ keyNameChecked: this.state.keyNameChecked ? false : true })
    }
    getIssueNameCode = async () => {
        let issueNameCodes = []
        let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }] }
        let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
        await fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.businessNameIssueCodeConfigurations) {
                    for (let name of response.businessNameIssueCodeConfigurations) {
                        issueNameCodes.push({ label: name.BusinessNameIssueLabel, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
                    }
                    this.setState({ issueNameOptions: issueNameCodes })
                } else {
                    this.setState({ issueNameOptions: [] })
                }
            }).catch((err) => {
                return err;
            });
        return true
    }
    getHierarchyData = async () => {
        let namesArray = [], nameTypeArray = []
        let urlNames = `${apiCalls.HeirarchyCompany}?str=${this.props.dimSellerID}`
        await fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.companyIDs) {

                    for (let name of response.companyIDs) {
                        namesArray.push({ label: name.CompanyName, value: name.CompanyID, BusinessID: name.BusinessID })
                    }
                    await this.setState({ namesArray: namesArray })
                } else {
                    await this.setState({ namesArray: [] })
                }
            }).catch((err) => {
                return err;
            });
        let url1 = `${apiCalls.execute}?query=Select Distinct NameType From mbd.BusinessNames`;
        await fetch("GET", url1)
            .then(async (response) => {
                if (response && response.results) {
                    for (let result of response.results) {
                        nameTypeArray.push({ label: result.NameType, value: result.NameType })
                    }
                    this.setState({ nameTypeArray: nameTypeArray })
                } else {
                    this.setState({ nameTypeArray: [] })
                }

            }).catch((err) => {
                return err;
            });
        return namesArray
    }
    cancelHistory = async () => {
        await this.setState({ openHistoryModel: false })
    }
    getHistoryModal(item) {
        return (

            <NameIssueCodeModal
                onRef={(ref) => this.paymentsModalRef = ref}
                selectedHistoryFields={this.state.selectedHistoryFields}
                openHistoryModel={this.state.openHistoryModel}
                openFormModal={this.openFormModal}
                closeFormModal={this.closeFormModal}
                cancelReset={this.cancelHistory}
                handleUsersData={this.handleUsersData}
            />
        )
    }
    openHistoryModel = async (e) => {
        // await this.setState({ openHistoryModel: true })
        let historyBusinessNameID = this.state.historyBusinessNameID
        let urlNames = `${apiCalls.NameIssueCodeHistory}?businessNameID=${historyBusinessNameID}`
        return fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.nameIssueCodeHistories && response.nameIssueCodeHistories.length > 0) {
                    this.setState({ selectedHistoryFields: response.nameIssueCodeHistories, openHistoryModel: true })
                } else {
                    this.setState({ selectedHistoryFields: [], openHistoryModel: true })
                }
            }).catch((err) => {
                return err;
            });
    }
    changeIssueNameName = async (e) => {
        let BusinessNameIssueCodeId = []
        await this.setState({ issueNameName: e.value })
        for (let id of e.value) {
            let z = this.state.issueNameOptions.find(elem => elem.value === id)
            if (z) {
                //with z values hit the api call
                BusinessNameIssueCodeId.push(z.BusinessNameIssueCodeId)
            }
        }
        await this.setState({ BusinessNameIssueCodeId: BusinessNameIssueCodeId })
    }
    componentDidMount = async () => {
        if (this.props.itemDetails) {
            await this.setState({ isLoading: true })
        }
        this.handlePercentage('1')
        let heirarchyData = await this.getHierarchyData()
        let issue = await this.getIssueNameCode()
        if (heirarchyData && this.props.itemDetails) {
            this.setState({ type: "edit" })
            this.props.change("nameConnectionName", this.props.itemDetails.NameConnection)
            this.props.change("nameTypeName", this.props.itemDetails.NameType)
            this.props.change("SourceName", this.props.itemDetails.NameSource)

            this.props.change("GeneralRestrictions", this.props.itemDetails.GeneralRestrictions)
            if (this.props.itemDetails && this.props.itemDetails.NameAIStatus) {
                this.props.change("NameAIStatus", "Yes")
            } else {
                this.props.change("NameAIStatus", "No")
            }

            // let htmlString = this.props.itemDetails.IssueNote;
            // this.props.change("issueNoteHtml", <div dangerouslySetInnerHTML={{ __html: htmlString }}>
            // </div>)
            let htmlString1 = this.props.itemDetails.GeneralNote;
            this.props.change("generalNoteHtml", <div dangerouslySetInnerHTML={{ __html: htmlString1 }}>
            </div>)
            // this.props.change("companyNameName", this.props.itemDetails.ConnectedName)
            this.props.change("businessNameName", this.props.itemDetails.BusinessName)
            this.props.change("ConnectionYear", this.props.itemDetails.ConnectionYear)
            await this.setDetails("", "", this.props.itemDetails)
            this.setNameIssueCode("", "", this.props.itemDetails)
            await this.setCompDetails(this.props.itemDetails)
            let z = this.state.namesArray.find(elem => elem.value === this.props.itemDetails.CompanyID)
            if (z) {
                await this.setState({ BusinessID: z.BusinessID })
            } else {
                await this.setState({ BusinessID: this.props.itemDetails.BusinessID })
            }
            let url1 = `${apiCalls.execute}?query=exec mbd.GetHstoryofBusinessName '${this.props.itemDetails.CompanyID}','${this.props.itemDetails.BusinessName}','${this.props.itemDetails.NameType}'`;
            fetch("GET", url1)
                .then(async (response) => {
                    if (response && response.results) {
                        await this.setState({ last2Notes: response.results })
                    }
                }).catch((err) => {
                    return err;
                });
            await this.setState({ keyNameChecked: this.props.itemDetails.IsKey, DataSourceID: this.props.itemDetails.DataSourceID ? this.props.itemDetails.DataSourceID : null, storeProcId: this.props.itemDetails.CompanyID, isLoading: false })
        }


    }
    render() {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const { handleSubmit, t, type } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div>
                {this.state.openHistoryModel ? this.getHistoryModal() : null}

                <Modal
                    fade={false}
                    isOpen={this.props.isOpenAddModal}
                    className={`modal-dialog modal-dialog--primary  ${modalClass}`}
                    style={{ maxWidth: "1000px" }}
                >
                    <form className="form" onSubmit={handleSubmit(this.submit)}>
                        <ModalHeader className={"modal__header kanbanScreen"}>
                            <div className="d-flex">
                                <button className="lnr lnr-cross modal__close-btn" type="button" style={type === "edit" ? { color: "black", right: 40 } : { color: "white" }}
                                    onClick={() => this.cancelReset("")} />
                                <span className={`modallnrIcon pt-1 lnr lnr-list`} />
                                <p className="bold-text  modal__title ml-2">  {this.state.type === "edit" || this.state.Addresstype ? "Edit" : 'Add'} {this.props.activeTab === "3" ? "Name" : "Address"} </p>
                                <Button color='danger' style={{ float: "right", marginLeft: "545px", height: "33px" }} outline type="button" onClick={() => this.cancelReset("")}>
                                    <FontAwesomeIcon
                                        style={{ width: "15px" }}
                                        // color="red"
                                        icon={faArrowCircleLeft}
                                    />
                                    {t('Back') ? t('Cancel') : 'Cancel'} </Button>
                                <Button
                                    style={{ float: "right", height: "33px" }}
                                    color='primary'
                                    className={"mb-0 "}
                                    outline
                                    type="submit"
                                >
                                    <FontAwesomeIcon
                                        style={{ width: "15px" }}
                                        icon={faSave}
                                    />
                                    {type === 'edit' ? 'Update' : 'Save'}
                                </Button>
                            </div>

                        </ModalHeader>

                        <ModalBody className={"modal__body  mb-0 pt-0 "} style={{ marginTop: "-32px" }}>
                            <Card className='pb-0 '>
                                {/* <Loader loader={this.state.isLoading} /> */}
                                <CardBody className=' p-0 mt-3'
                                >
                                    <span className="formError mt-2 mb-2" style={{ textAlign: "center", top: -14, color: "red" }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>

                                    <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />
                                    <Accordion activeIndex={[0]} style={{ marginTop: "41px" }} multiple={true}>
                                        <AccordionTab class="in" header="Basic Information:">
                                            <Row>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Company Name
                                                        {required ? <FontAwesomeIcon icon={faStarOfLife}
                                                            className='pl-1'
                                                            color='red'
                                                            data-toggle="tool-tip"
                                                            title="Mandotary Field"
                                                            style={{ width: 8 }}
                                                        /> : ''}
                                                    </label>
                                                    <Field
                                                        // key={i}
                                                        name={"companyNameName"}
                                                        component={Select}
                                                        validate={required}
                                                        options={this.state.namesArray ? this.state.namesArray : null}
                                                        onChange={(e) => this.handleBusinessID(e)}
                                                        isDisable={this.state.type === "edit" && (this.state.DataSourceID !== this.props.dataSourceId) ? true : false}
                                                        placeholder={"Company Name"}
                                                    />
                                                </div>
                                                {/* <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Connected Name</label>

                                                    <Field
                                                        // key={i}
                                                        name={"connectedNameName"}
                                                        component={Select}
                                                        // validate={required}
                                                        options={this.state.namesArray ? this.state.namesArray : []}
                                                        onChange={(e) => this.connectedName(e)}
                                                        isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder={"Connected Name"}

                                                    />
                                                </div> */}

                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Name Connection
                                                        {required ? <FontAwesomeIcon icon={faStarOfLife}
                                                            className='pl-1'
                                                            color='red'
                                                            data-toggle="tool-tip"
                                                            title="Mandotary Field"
                                                            style={{ width: 8 }}
                                                        /> : ''}</label>
                                                    <Field
                                                        // key={i}
                                                        name={"nameConnectionName"}
                                                        component={Select}
                                                        options={[{ label: "Acquisition (Asset)", value: "Acquisition (Asset)" },
                                                        { label: "Acquisition (Non-Specified)", value: "Acquisition (Non-Specified)" },
                                                        { label: "Acquisition (Stock)", value: "Acquisition (Stock)" },
                                                        { label: "Conversion (Non-Survivor)", value: "Conversion (Non-Survivor)" },
                                                        { label: "Conversion (Survivor)", value: "Conversion (Survivor)" },
                                                        // { label: "Division", value: "Division" },
                                                        { label: "Former Name/SOS Name Change", value: "Former Name/SOS Name Change" },
                                                        { label: "Joint Debtor", value: "Joint Debtor" },
                                                        { label: "Judegement Debtor", value: "Judgement Debtor" },
                                                        { label: "Lead Debtor", value: "Lead Debtor" },
                                                        { label: "Merger (Non-Survivor)", value: "Merger (Non-Survivor)" },
                                                        { label: "Merger (Survivor)", value: "Merger (Survivor)" },
                                                        { label: "Name Variation/Truncation", value: "Name Variation/Truncation" },
                                                        { label: "Oak Point APA Lead Seller", value: "Oak Point APA Lead Seller" },
                                                        { label: "Oak Point APA Seller", value: "Oak Point APA Seller" },
                                                        { label: "Oak Point APA Seller Signatory's Firm", value: "Oak Point APA Seller Signatory's Firm" },
                                                        { label: "Oak Point APA Seller Signatory", value: "Oak Point APA Seller Signatory" },
                                                        { label: "Related Omitted", value: "Related Omitted" },
                                                        // { label: "OPP Sale Contact’s Name", value: "OPP Sale Contact’s Name" },
                                                        { label: "Subsidiary", value: "Subsidiary" },
                                                        { label: "Unrelated", value: "Unrelated" },
                                                        { label: "Trade name/Division/DBA/AKA/Assumed/Fictitious", value: "Trade name/Division/DBA/AKA/Assumed/Fictitious" },
                                                        { label: "Unconfirmed", value: "Unconfirmed" }]}
                                                        placeholder={"Name Connection"}
                                                        validate={required}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Name Type
                                                        {required ? <FontAwesomeIcon icon={faStarOfLife}
                                                            className='pl-1'
                                                            color='red'
                                                            data-toggle="tool-tip"
                                                            title="Mandotary Field"
                                                            style={{ width: 8 }}
                                                        /> : ''}</label>
                                                    <Field
                                                        // key={i}
                                                        name={"nameTypeName"}
                                                        component={Select}
                                                        options={this.state.nameTypeArray}
                                                        placeholder={"Name Type"}
                                                        validate={required}
                                                        isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Business Name
                                                        {required ? <FontAwesomeIcon icon={faStarOfLife}
                                                            className='pl-1'
                                                            color='red'
                                                            data-toggle="tool-tip"
                                                            title="Mandotary Field"
                                                            style={{ width: 8 }}
                                                        /> : ''}</label>

                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="businessNameName"
                                                        component={DefaultInput}
                                                        isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Business Name'
                                                        validate={required}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Source
                                                        {required ? <FontAwesomeIcon icon={faStarOfLife}
                                                            className='pl-1'
                                                            color='red'
                                                            data-toggle="tool-tip"
                                                            title="Mandotary Field"
                                                            style={{ width: 8 }}
                                                        /> : ''}</label>

                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="SourceName"
                                                        component={DefaultInput}
                                                        validate={required}
                                                        placeholder='Source'
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Key Name
                                                    </label>
                                                    <input
                                                        style={{ marginTop: 3, height: 16 }}
                                                        className='col-1 mr-0 pl-0'
                                                        onChange={(e) => this.keyNameChecked()}
                                                        component={CheckBox}
                                                        label={"Key Name"}
                                                        type="checkbox"
                                                        checked={this.state.keyNameChecked}
                                                        defaultChecked={this.state.keyNameChecked}
                                                    />
                                                </div>

                                            </Row>

                                        </AccordionTab>
                                        <AccordionTab header="Attention Recovery Operations:">
                                            <Row>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Issue Code</label>
                                                    <MultiSelect
                                                        name={"issueNameName"}
                                                        value={this.state.issueNameName}
                                                        style={{ width: "100%", height: 32 }}
                                                        itemTemplate={this.IssueCodeTemplate}
                                                        maxSelectedLabels={1}
                                                        placeholder={"Issue Code"}
                                                        options={this.state.issueNameOptions}
                                                        onChange={(e) => this.changeIssueNameName(e)}
                                                    />
                                                </div>
                                                {this.state.type === "edit" ? <div className='col-sm-6 mt-2'>
                                                    <FontAwesomeIcon style={{ marginTop: "20px" }} icon={faInfoCircle} color="blue" title={"Issue Codes & Notes History"} onClick={(e) => this.openHistoryModel(e)} />

                                                </div> : <div className='col-sm-6'></div>}

                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Issue Note</label>
                                                    <Editor style={{ height: '150px' }}
                                                        value={this.props && this.props.itemDetails && this.props.itemDetails.IssueNote && this.state.type === "edit" ? this.props.itemDetails.IssueNote : ""}
                                                        // value={ }
                                                        name="issueNoteName"
                                                        // name="buhisjs"
                                                        onTextChange={(e) => this.setText(e.htmlValue)}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">General Note</label>

                                                    <Editor style={{ height: '150px' }}
                                                        value={this.props && this.props.itemDetails && this.props.itemDetails.GeneralNote && this.state.type === "edit" ? this.props.itemDetails.GeneralNote : ""}
                                                        // value={ }
                                                        name="generalNoteName"
                                                        onTextChange={(e) => this.setGeneralNote(e.htmlValue)}
                                                    />
                                                </div>
                                                {/* <div className='col-sm-6'>
                                                    <label className="form__form-group-label">General Note</label>
                                                    <Editor style={{ height: '150px' }}
                                                        value={this.props && this.props.itemDetails && this.props.itemDetails.GeneralRestrictions && this.state.type === "edit" ? this.props.itemDetails.GeneralRestrictions : ""}
                                                        // value={ }
                                                        name="GeneralRestrictions"
                                                        // name="buhisjs"
                                                        onTextChange={(e) => this.setGeneralRestriction(e.htmlValue)}
                                                    />
                                                </div> */}
                                            </Row>
                                        </AccordionTab>
                                        <AccordionTab header="Entity Research:">
                                            <Row>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">PB Connection Type</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="PBConnectionType"
                                                        component={DefaultInput}
                                                        maxLength={50}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='PB Connection Type'

                                                    // options={this.state.company}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Proposed Legal Name Source</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="ProposedLegalNameSource"
                                                        component={DefaultInput}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Proposed Legal Name Source'
                                                        maxLength={50}

                                                    // options={this.state.company}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Active Inactive Notes</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="ActiveInactiveNotes"
                                                        component={DefaultInput}
                                                        maxLength={1000}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Active Inactive Notes'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Integrity Notes</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="IntegrityNotes"
                                                        component={DefaultInput}
                                                        maxLength={1000}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Integrity Notes'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Legal Notes</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="LegalNotes"
                                                        component={DefaultInput}
                                                        maxLength={1000}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Legal Notes'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">APA Exhibit Placement</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="APAExhibitPlacement"
                                                        component={DefaultInput}
                                                        maxLength={1000}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='APA Exhibit Placement'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Seller Comments</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="SellerComments"
                                                        component={DefaultInput}
                                                        maxLength={4000}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Seller Comments'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Judgment Pre Judgment Interest</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="JudgmentPreJudgmentInterest"
                                                        component={DefaultInput}
                                                        maxLength={50}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Judgment Pre Judgment Interest'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Entity Status</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="EntityStatus"
                                                        component={DefaultInput}
                                                        maxLength={50}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Entity Status'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Entity Status SubType</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="EntityStatusSubType"
                                                        component={DefaultInput}
                                                        maxLength={50}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Entity Status SubType'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Date Restrictions</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="DateRestrictions"
                                                        component={DefaultInput}
                                                        maxLength={1000}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Date Restrictions'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Name AI Status</label>
                                                    <Field
                                                        // key={i}
                                                        name={"NameAIStatus"}
                                                        component={Select}
                                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}

                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                        placeholder={"Name AI Status"}
                                                    // isDisable={item.disable ? item.disable : false}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">SOS Id</label>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="SOSId"
                                                        component={DefaultInput}
                                                        maxLength={50}

                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='SOS Id'

                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">SOS Last Filing Date</label>
                                                    <Field
                                                        // id={true}
                                                        name="SOSLastFilingDate"
                                                        component={DatePicker}
                                                        type={"text"}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="SOS Last Filing Date"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    /> </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Date Pre Sales UP Research</label>
                                                    <Field
                                                        // id={true}
                                                        name="DatePreSalesUPResearch"
                                                        component={DatePicker}
                                                        type={"text"}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="Date Pre Sales UP Research"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    /> </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Date Integrity Pre Sale Research</label>
                                                    <Field
                                                        // id={true}
                                                        name="DateIntegrityPreSaleResearch"
                                                        component={DatePicker}
                                                        type={"text"}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="Date Integrity Pre Sale Research"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    /> </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Date Integrity Post Sale Research</label>
                                                    <Field
                                                        // id={true}
                                                        name="DateIntegrityPostSaleResearch"
                                                        component={DatePicker}
                                                        type={"text"}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="Date Integrity Post Sale Research"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    /> </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Date Checked for SOS Last Filing Date</label>
                                                    <Field
                                                        // id={true}
                                                        name="DateCheckedforSOSLastFilingDate"
                                                        component={DatePicker}
                                                        type={"text"}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="Date Checked for SOS Last Filing Date"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    /> </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Incorporation Date</label>
                                                    <Field
                                                        // id={true}
                                                        name="IncorporationDate"
                                                        component={DatePicker}
                                                        type={"text"}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="Incorporation Date"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Inactivity Date</label>
                                                    <Field
                                                        // id={true}
                                                        name="InactivityDate"
                                                        component={DatePicker}
                                                        type={"text"}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="Inactivity Date"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">SOS Officers</label>
                                                    <Field
                                                        // id={true}
                                                        name="SOSOfficers"
                                                        component={DefaultInput}
                                                        type={"text"}
                                                        maxLength={50}

                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="SOS Officers"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Date Checked for SOS Officers</label>
                                                    <Field
                                                        // id={true}
                                                        name="DateCheckedforSOSOfficers"
                                                        component={DatePicker}
                                                        type={"text"}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // validate={(this.state.dealType ==="CR" && item.dealType ==="BK") || (item.name ==="CaseStatusClosedDate" && !this.state.showCaseClosedDate) ? null : [required]}
                                                        placeholder="Date Checked for SOS Officers"
                                                    // style={item.name ==="CaseStatusClosedDate" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">PB Connection Year</label>
                                                    <Field className='inputLogin'

                                                        name="PBConnectionYear"
                                                        component={DefaultInput}

                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='PB Connection Year'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">UP Count Total</label>
                                                    <Field className='inputLogin'

                                                        name="UPCountTotal"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='UP Count Total'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Connection Type Year</label>
                                                    <Field className='inputLogin'

                                                        name="ConnectionTypeYear"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Connection Type Year'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">UP Value Total</label>
                                                    <Field className='inputLogin'

                                                        name="UPValueTotal"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='UP Value Total'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Paid Recoveries</label>
                                                    <Field className='inputLogin'

                                                        name="PaidRecoveries"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Paid Recoveries'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Judgment Fees</label>
                                                    <Field className='inputLogin'

                                                        name="JudgmentFees"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Judgment Fees'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">SEC 10Kex 21 Start Year</label>
                                                    <Field className='inputLogin'

                                                        name="SEC10Kex21StartYear"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='SEC 10Kex 21 Start Year'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">SEC 10Kex 21 Last Year</label>
                                                    <Field className='inputLogin'

                                                        name="SEC10Kex21LastYear"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='SEC 10Kex 21 Last Year'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">SEC Registration Start Year</label>
                                                    <Field className='inputLogin'

                                                        name="SECRegistrationStartYear"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='SEC Registration Start Year'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">SEC Registration Last Year</label>
                                                    <Field className='inputLogin'

                                                        name="SECRegistrationLastYear"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='SEC Registration Last Year'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div> <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Connection Year</label>
                                                    <Field className='inputLogin'

                                                        name="ConnectionYear"
                                                        component={DefaultInput}
                                                        type={"number"}
                                                        // isDisable={this.state.type === "edit" ? true : false}
                                                        // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                        placeholder='Connection Year'
                                                    // validate={required}
                                                    // options={this.state.company}
                                                    />
                                                </div>
                                                {/* <label>Evidence</label>
                                            <Field
                                                name="imageName"
                                                component={RenderFileInputField}
                                                onRef={(ref) => (this.profileUploadRef = ref)}
                                                url={apiCalls.ProfileImagePath}
                                                // label='Upload photo'
                                                name="file"
                                                type='profile'
                                                multiple={true}
                                                customUpload={true}
                                                onCancelDocuments={this.onCancelDocuments}
                                                // maxFileSize={5000000}
                                                accept="image,application/pdf,application/vnd.ms-excel"
                                                getFileName={this.getFileName}
                                                uploadProgress={this.uploadProgress}
                                            /> */}
                                                {/* {this.state.type === "edit" ?
                                                <div className='col-sm-12 pl-0 pr-0 ' >
                                                    <div className="form__form-group" >
                                                        <div className="d-flex" style={{ alignItems: "center" }}>
                                                            <label className="form__form-group-label mr-auto" >Notes History ({this.state.last2Notes && this.state.last2Notes.length > 0 ? this.state.last2Notes.length : "0"})</label>
                                                        </div>
                                                        <div className='ml-0 mr-0'>
                                                            <div className='usermessagesText d-block' style={{ minHeight: "95px" }}>
                                                                {this.state.last2Notes && this.state.last2Notes.length > 0 ?
                                                                    this.state.last2Notes.map((item, index) => {
                                                                        let noteCreates = new Date(item.DWRowStartDate);
                                                                        let monthNo = noteCreates.getMonth()
                                                                        let date = noteCreates.getDate()
                                                                        let issueName
                                                                        if (item.IssueName) {
                                                                            let z = this.state.issueName.find(elem => elem.value === item.IssueName)
                                                                            if (z) {
                                                                                issueName = z.label
                                                                            }
                                                                        }
                                                                        return <ol className=" mt-1 pl-0" key={index}>
                                                                            <p className="kanban_title notes_details">
                                                                                <span className="mr-2 notesdate_font">{month[monthNo]}</span>
                                                                                <span className="mr-1 notesdate_font">{date}</span>-
                                                                                <span className="ml-1 notesdate_font">{issueName ? issueName : null}</span>
                                                                                {/* <span className="ml-1 notesdate_font">{item.DWRowEndDate ? item.DWRowEndDate : ""}</span> */}
                                                {/* </p>
                                                                            <p className="kanban_title mt-0 pb-1" style={{ wordBreak: "break-all" }}
                                                                            >

                                                                                <div dangerouslySetInnerHTML={{ __html: item.IssueNote }}>
                                                                                </div>
                                                                            </p>
                                                                        </ol>
                                                                    }) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}  */}
                                            </Row>
                                        </AccordionTab>

                                    </Accordion>

                                </CardBody>
                            </Card>
                        </ModalBody>
                        <ModalFooter className="modalFooter " style={{ width: "100%", padding: "10px" }}>
                            {/* <Button color='danger' outline type="button" className="mr-auto ml-1 mb-0 " onClick={() => this.cancelReset("")}>
                            <FontAwesomeIcon
                                style={{ width: "15px" }}
                                // color="red"
                                icon={faArrowCircleLeft}
                            // data-toggle="tool-tip"
                            // title={"save"}
                            />
                            {t('Back') ? t('Cancel') : 'Cancel'} </Button>
                        <Button
                            style={{ float: "right" }}
                            color='primary'
                            className={"mb-0 "}
                            outline
                            type="submit"
                        >
                            <FontAwesomeIcon
                                style={{ width: "15px" }}
                                icon={faSave}
                            />
                            {type === 'edit' ? 'Update' : 'Save'}
                        </Button> */}
                        </ModalFooter>
                    </form >
                </Modal >
            </div>


        );
    }
}


NameModal = reduxForm({
    form: "Rulemodel", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(NameModal);

// You have to connect() to any reducers that you wish to connect to yourself
NameModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(NameModal);

export default withTranslation('common')(NameModal);
