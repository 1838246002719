import React from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Row
} from 'reactstrap';
import classnames from 'classnames';
import { Container } from 'reactstrap';

import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { AutoComplete } from 'primereact/autocomplete'

import { withTranslation } from 'react-i18next';
import DealManagement from './DealManagement';
import fetch from '../../../config/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import SellerModal from '../CommonModals/SellerModal'
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import AddressFields from './DealMgmtFields/Address';
import NamesFields from './DealMgmtFields/Names';
import NotesFields from './DealMgmtFields/Notes';
import apiCalls from '../../../config/apiCalls'
import DocumentMaintenance from '../DocumentMaintenance/components/DocumentMaintenance';

// config file
class DealTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredSuggestions: [],
      routeName: "",
      selectedDealID: "",
      selectedDeal: "",
      dimSellerId: "",
      isRedirecttoDeal: false,
    };
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }

  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let role = await JSON.parse(localStorage.getItem('loginCredentials'))
    if (role && role.roleName) {
      await this.setState({ role: role.roleName })
    }

    if (window.location.pathname.split("/")[2] === "name" && this.state.activeTab !== "3") {
      this.setState({ activeTab: "3", selectedDeal: "", selectedDealID: "" });
    } else if (window.location.pathname.split("/")[2] === "Address" && this.state.activeTab !== "4") {
      this.setState({ activeTab: "4", selectedDeal: "", selectedDealID: "" });
    } else if (window.location.pathname.split("/")[2] === "deal" && this.state.activeTab !== "1") {
      this.setState({ activeTab: "1", selectedDeal: "", selectedDealID: "" });
    } else if (window.location.pathname.split("/")[2] === "business" && this.state.activeTab !== "2") {
      this.setState({ activeTab: "2", selectedDeal: "", selectedDealID: "" });
    }
    if (window.location.pathname.split("/").length === 4) {//get the deal id details when the url is copied or refreshed
      await this.getSellerDetailsfromRoute()
    }
  }


  static getDerivedStateFromProps(props, state) {
    if (window.location.pathname.split("/")[2] === "name" && state.activeTab !== "3") {
      return { activeTab: "3", selectedDeal: "", selectedDealID: "" };
    } else if (window.location.pathname.split("/")[2] === "Address" && state.activeTab !== "4") {
      return { activeTab: "4", selectedDeal: "", selectedDealID: "" };
    } else if (window.location.pathname.split("/")[2] === "deal" && state.activeTab !== "1") {
      return { activeTab: "1", selectedDeal: "", selectedDealID: "" };
    } else {
      return null
    }
  }



  /**
   *  Calling when the tab is clicked
   * @param {String} tab 
   * @param {String} urlName 
   * @param {String} sideMenu 
   */
  toggle = (tab, urlName, sideMenu) => {
    this.setState({
      activeTab: tab,
      routeName: urlName
    })

    //Highlight the sidebar based on Side Selection
    var data = document.getElementsByClassName('categoryTitle')
    for (var i = 0; i < data.length; i++) {
      if (data[i].innerHTML === "Managed Assets") {
        data = document.getElementsByClassName('sidebar__submenu')[i]
        var submenus = data.getElementsByClassName("sidebar__link-title")
        for (var j = 0; j < submenus.length; j++) {
          if (submenus[j].innerHTML === sideMenu) {
            data.children[0].children[j].children[0].click()
          }
        }
      }
    }


    setTimeout(() => this.getDatbasedonComponentSelected(tab), 150);
    if (this.state.selectedDealID !== "") {
      this.changingUrlbasedonTabwithID(urlName)
    } else {
      this.changingUrlbasedonTab(urlName)
    }
  }

  /**
   *   handle inputs for DealManagement based on Selected tab(if it is table)
   * @param {String} tab 
   * @returns 
   */
  getInputforCruds = async (tab) => {
    var obj
    if (tab === "3") {
      await this.namesFieldsref.getHierarchyData(this.state.selectedDealID)
      obj = {
        tableFields: this.namesFieldsref.getnamesFields(),
        multiSaveUrl: `${apiCalls.addNames}/multi?dealID=${this.state.selectedDealID}`,
        multiDeleteUrl: `${apiCalls.addNames}/MultiDelete?dealID=${this.state.selectedDealID}`,
        newRecordObj: this.namesFieldsref.getnewRecord(),
        requiredFields: this.namesFieldsref.getrequiredFieldsforScreen(),
        anyOneField: this.namesFieldsref.getanyOnerequiredFieldsforScreen(),
        type: "Names",
        deleteRestrictionField: "DataSourceID",
        deleteRestrictionFieldValues: [3, 4],
        uniqueDBField: "BusinessNamesID",
        validationType: "or",
        isEditableGrid: true
      }
    } else if (tab === "4") {
      await this.addressFieldsref.getHierarchyData(this.state.selectedDealID)
      obj = {
        tableFields: this.addressFieldsref.getaddressFields(),
        multiSaveUrl: `${apiCalls.addAddress}/multi?dealID=${this.state.selectedDealID}`,
        multiDeleteUrl: `${apiCalls.addAddress}/MultiDelete?dealID=${this.state.selectedDealID}`,
        newRecordObj: this.addressFieldsref.getnewRecord(),
        requiredFields: this.addressFieldsref.getrequiredFieldsforScreen(),
        anyOneField: this.addressFieldsref.getanyOnerequiredFieldsforScreen(),
        type: "Address",
        deleteRestrictionField: "",
        deleteRestrictionFieldValues: ["3", "4"],
        uniqueDBField: "BusinessLocationID",
        validationType: "or",
        isEditableGrid: true
      }
    } else if (tab === "5") {
      obj = {
        tableFields: this.notesFieldsref.getNotesFields(),
        multiSaveUrl: `${apiCalls.SellerNotes}/multi`,
        multiDeleteUrl: `${apiCalls.SellerNotes}/MultiDelete`,
        newRecordObj: this.notesFieldsref.getnewRecord(),
        requiredFields: this.notesFieldsref.getrequiredFieldsforScreen(),
        type: "Notes",
        deleteRestrictionField: "DataSourceID",
        deleteRestrictionFieldValues: ["3", "4"],
        uniqueDBField: "BusinessNamesID",
        isEditableGrid: true

      }
    }
    return obj
  }

  /**
   *  Calling the components when toggle is happened or deal is selected
   * @param {String} tab 
   */
  getDatbasedonComponentSelected = async (tab) => {
    if (this.state.selectedDealID) {
      let deal = {}
      deal.value = this.state.selectedDeal
      if ((tab === "3" || tab === "4" || tab === "5") && this.DealManagementref) {
        let fieldsData = await this.getInputforCruds(tab)
        this.DealManagementref.handlegetDeal(deal, this.state.activeTab, fieldsData)

      } else {
        if ((tab === "1" || tab === "2") && this.SellerModalref) {
          this.SellerModalref.handlegetDeal(deal, this.state.activeTab)
        }
      }
    }
  }


  /**
   *   Changing the tab without DealID 
   * @param {String} screen 
   */
  changingUrlbasedonTab(screen) {
    let pathname2 = `/sellersEdit/`
    window.history.pushState(null, null, pathname2);

    let pathname = `/sellersEdit/${screen}`
    window.history.pushState(null, null, pathname);
  }

  /**
   * Route Change when click on the the tab with DealID 
   * @param {String} urlName 
   */
  changingUrlbasedonTabwithID(urlName) {
    let screen = urlName ? urlName : window.location.pathname.split("/")[2]
    let id = this.state.selectedDealID
    let pathname = `/sellersEdit/${screen}/${id}`
    window.history.pushState(null, null, pathname);
  }

  /**
   * 
   * @returns   Get the SEller details from the id get from the url
   */
  getSellerDetailsfromRoute = async () => {
    let dealId = window.location.pathname.split("/")[3]
    let apiUrl = `seller/${dealId}`;
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response && response.details) {
            dropdownData = response.details
            let responseData = {}
            responseData.value = dropdownData
            await this.handlegetDeal(responseData)
          }
          else if (response && response.errorMessage) {
            // this.props.getErrorResponseFromServer(response)
          }

        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * 
   * @param {Object} e 
   *   invoke when the Deal Name Estate is changes
   */
  handlegetDeal = async (e) => {
    await this.setState({
      selectedDeal: e.value,
      selectedDealID: e.value.DealID,
      dimSellerId: e.value.DealID,
      activeTab: window.location.pathname.includes("name") ? "3" : window.location.pathname.includes("deal") ? "1" : window.location.pathname.includes("business") ? "2" : window.location.pathname.includes("Address") || window.location.pathname.includes("address") ? "4" : window.location.pathname.includes("deal") ? "1" : window.location.pathname.includes("documentMaintenance") ? "6" : "5"
    })
    await this.changingUrlbasedonTabwithID()
    setTimeout(() => this.getDatbasedonComponentSelected(this.state.activeTab), 250);

  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option && option.APADate ? new Date(option.APADate).getFullYear() : ""})`)
  }


  /**
   *  
   * @param {Object} event 
   * @returns  Get the Estate names from the sellers
   */
  EstateItems = (event) => {
    let filterCriteria = { limit: 5, sortfield: "EntityName" };
    filterCriteria["criteria"] = [{
      key: "SellerNickName",
      value: event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : "",
      type: "regexOr"
    }]
    let apiUrl = `seller?filter=${JSON.stringify(filterCriteria)}`
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            // this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }
          await this.setState({
            filteredSuggestions: dropdownData
          });
        }
      }).catch((err) => {
        return err;
      });
  }

  cancelReset = async (type) => {
    await this.setState({ isopenColumnModal: true })
  }
  /**
   * Model to close show hide model
   * @param {String} type 
   */
  closeShowHideColumnsModal = async (type) => {
    await this.setState({
      isopenColumnModal: false,
    })
    if (type === 'close') {
      await this.setState({
        isRedirecttoDeal: true
      })
    }

  }

  getConfirmaionModel() {
    return (
      <ConfirmationModal
        openConfirmationModal={this.state.isopenColumnModal}
        closeConfirmationModal={this.closeShowHideColumnsModal}
        shownText={"Are you sure you want to close before Saving?"}
      // confirm={this.submit}
      />
    )
  }

  render() {
    const { t } = this.props
    return (
      <Container className="container">
        <div>
          <Card className="cardForListMargin m-0 card">

            <CardBody className="tableCardBody">
              <Row className="col-sm-12">

                <h4><span className='postionRelative pt-2 ml-1'>
                  <FontAwesomeIcon
                    className={'tableheadericon'}
                    color="#354f6e"
                    icon={faUsers} />
                  <b

                  ><Link to={"#"} onClick={this.cancelReset} className="tableType pr-0" >
                      {window.location.pathname.split("/").length === 4 ? `Edit Deal` : window.location.href.includes("sellersView") ? "View Deal" : "Deal"}
                    </Link>{this.state.selectedDealID ? ` - ${this.state.selectedDeal.DealType} - ${this.state.selectedDeal.SellerNickName} (${this.state.selectedDeal.APADate ? new Date(this.state.selectedDeal.APADate).getFullYear() : ""}) - ${this.state.selectedDealID}` : null}</b>

                </span>
                </h4>

              </Row>
              <div >
                < div style={{ width: "100%", overflow: "hidden", display: "inline" }}>
                  <Nav tabs >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1', 'deal', 'Deal'); }}
                      >
                        Deal
                      </NavLink>
                    </NavItem>
                    {this.state.role && this.state.role === "Admin" ?
                      <NavItem >
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2', 'business', 'Business'); }}
                          disabled={this.state.isTabdisabled || this.state.isChildTrue}
                        >
                          Business
                        </NavLink>
                      </NavItem> : null
                    }
                    <NavItem >
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3', 'name', 'Name'); }}
                      // disabled={true}
                      >
                        Name
                      </NavLink>
                    </NavItem>
                    <NavItem >
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggle('4', 'address', 'Address'); }}
                      // disabled={true}
                      >
                        Address
                      </NavLink>
                    </NavItem>
                    <NavItem >
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '6' })}
                        onClick={() => { this.toggle('6', 'documentMaintenance', 'Document Maintenance'); }}
                      // disabled={true}
                      >
                        Document Maintenance
                      </NavLink>
                    </NavItem>
                    {/* <NavItem >
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '5' })}
                    onClick={() => { this.toggle('5', 'notes'); }}
                    disabled={true}
                  >
                    Notes
                  </NavLink>
                </NavItem> */}
                    <span className="topbar__centerresponse topBarImageAlignment" >{this.state.successResponse ? this.state.successMessage : null}</span>
                    <div className='mr-5' >
                      <p>Deal Name</p>
                      <AutoComplete
                        value={this.state.selectedDeal.SellerNickName ? `${this.state.selectedDeal.DealType} - ${this.state.selectedDeal.SellerNickName} (${this.state.selectedDeal.APADate ? new Date(this.state.selectedDeal.APADate).getFullYear() : ""})` : this.state.selectedDeal}
                        suggestions={this.state.filteredSuggestions ? this.state.filteredSuggestions : []}
                        completeMethod={this.EstateItems}
                        minLength={1}
                        id="Estate_Search"
                        field={"EntityName"}
                        placeholder={"Deal Name"}
                        style={{ width: "130%" }}
                        itemTemplate={this.editorTemplateForDeal}
                        dropdown={true}
                        onChange={(e) => this.handlegetDeal(e, 'sellectedDeak')}
                        appendTo={document.body} /></div>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    {this.state.activeTab === "1" || this.state.activeTab === "2" ?
                      <TabPane tabId={this.state.activeTab} >
                        <SellerModal
                          onRef={(ref) => this.SellerModalref = ref}
                          activeTab={this.state.activeTab}
                        />
                      </TabPane>
                      :
                      null
                    }
                    <TabPane tabId="2">
                      {/* {this.getTab2Body()} */}
                    </TabPane>
                    {(this.state.activeTab === "3" || this.state.activeTab === "4" || this.state.activeTab === "5") && this.state.selectedDealID ?
                      <TabPane tabId={this.state.activeTab} className="deal_managements" >
                        <DealManagement
                          onRef={(ref) => this.DealManagementref = ref}
                          activeTab={this.state.activeTab}
                        />
                        {this.state.selectedDealID ? <>
                          <AddressFields
                            onRef={(ref) => this.addressFieldsref = ref}

                          />
                          <NamesFields
                            onRef={(ref) => this.namesFieldsref = ref}
                            dimSellerId={this.state.selectedDealID}
                          />
                          <NotesFields
                            onRef={(ref) => this.notesFieldsref = ref}
                          /> </> : null}
                      </TabPane>
                      :
                      null
                    }
                    {
                      this.state.activeTab === "6" ?
                        <DocumentMaintenance
                          onRef={(ref) => this.docMaintainref = ref}
                          selectedDealID={this.state.selectedDealID}
                          selectedDeal={this.state.selectedDeal}
                        /> : null
                    }
                  </TabContent>

                </div>
                {this.state.isopenColumnModal ?
                  this.getConfirmaionModel() : null
                }
                {
                  this.state.isRedirecttoDeal ?
                    <Redirect to="/sellers" />
                    : null
                }
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}
export default withTranslation('common')(DealTabs);
