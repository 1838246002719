import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';

// config file
export default class DealSizingReportForPE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'OPRA2 Property Editor',
      propertyFilterSortOptions: [
        { label: 'Property Name', value: "propertyName" },
        { label: 'Prop.Address', value: "propertyAddressLine1" },
        { label: 'Business Name', value: "businessName" },
        { label: 'Property Zip Code', value: "propertyZipCode" },
        { label: 'Property City', value: "propertyCity" },
        { label: 'Property State', value: "propertyState" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    // this.getSettingsData()
  }




  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "field": "businessName",
        "mobile": true,
        "fieldType": "redirect",
        "header": "Business Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "minProperties",
        "allowInt": true,
        filterType: "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "No. of Properties",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "states",
        "mobile": true,
        "fieldType": "StatesArray1",
        "header": "States",
        "displayInSettings": true,
        "filter": false,
        // "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "minDealOpportunity",
        "mobile": true,
        "fieldType": "formatCurrency",
        "allowInt": true,
        filterType: "num",
        "header": "Minimum Deal Opportunity In Dollars",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "totalEntities",
        "mobile": true,
        "fieldType": "ShowData1",
        "allowInt": true,
        filterType: "num",
        "header": "Total Entities in Corporate Tree",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "lastUpdatedDate",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": "MM/DD/YYYY",
        "allowInt": true,
        filterType: "num",
        "header": "Last Deal Sizing Date",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },


    ]
    return data;
  };
  getFormFields = () => {

    return
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          sortField={"BusinessName"}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          isClearFilters={true}
          propertyFilterSortOptions={this.state.propertyFilterSortOptions}
          globalSearchFieldName='user'
          scrollHeight={"580px"}
          globalSearch={""}
          type={"Deal Sizing Report For Private Equity Portfolio"}
          displayright={true}
          icon='faCity'
          settingsData={this.state.settingsData}
          fontAwesome={true}
          routeTo='reports'
          displayViewOfForm='modal'
          activeTab={"Green"}
          apiResponseKey='privateEquitySummaries'
          className={true}
          apiUrl={"NsScoredProperties/PrivateEquitySummary"}
          entityType='employee'
        />

      </span>
    );
  }
}