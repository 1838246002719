
import React from 'react';
import { Badge, Card, CardBody, ButtonGroup, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Table } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Row, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
// fecth method from service.js file
import fetchMethodRequest from '../../config/service';
import { CSVLink } from "react-csv";
import fetch from '../../config/service';
import configMessage from '../../config/configMessages';
import dateFormats from '../UI/FormatDate/formatDate';
import { DataTable } from 'primereact/datatable';
import renderRadioButtonField from '../../shared/components/form/RadioButton'
import { RadioButton } from 'primereact/radiobutton';

import { Column } from 'primereact/column';
import { withTranslation } from 'react-i18next';
import { faTimes, faStarOfLife, faCopy, faMinus, faAngleDoubleRight, faAngleDoubleLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import apiCalls from '../../config/apiCalls';
import configImages from '../../config/configImages';

import { AutoComplete } from 'primereact/autocomplete'
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect'
import ConfirmaionModels from '../Cruds/CommonModals/isApplyFilter';
import { Checkbox } from 'primereact/checkbox'
import config from '../../config/config';
import { Redirect } from 'react-router';
import configMessages from '../../config/configMessages';
import moment from 'moment-timezone';
import Loader from '../App/Loader';

import DefaultInput from '../../shared/components/form/DefaultInput';
import { async } from '@firebase/util';
// import { async } from '@firebase/util';

const bodyStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "left",
  verticalAlign: "inherit"
}
const bodyStyleRight = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "center",
  verticalAlign: "inherit"
}


const headerStyle = {
  width: 150,
  textAlign: "center",

}
const headerStyleCity = {
  width: 300,
  textAlign: "center",
  // height: document.getElementsByClassName("p-datatable-thead") && document.getElementsByClassName("p-datatable-thead")[1] && document.getElementsByClassName("p-datatable-thead")[1].clientHeight ? document.getElementsByClassName("p-datatable-thead")[1].clientHeight : "67.17px"
  height: "69.17px"
}

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class SearchTermOnSiteFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sellers: [],
      filterCriteria: {},
      lastUpdatedDate: "",
      isChangeHit: false,
      filterednameSuggestions: [],
      sellerName: "",
      setGoButton: false,
      isSharing: false,
      modifiedAddress: [],
      totalRecordsCount: null,
      searchTermsRecords: [],
      exportData: [],
      dataChangeOptionswithFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' },
      ],
      dataChangeOptionswithoutFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
      ],
      tabOptions: ["Existing Data", "Search Terms", "Confirm On Site", "Data Upload in OPRA"],
      searchTabOptions: ["Active", "Inactive/Deleted"],
      kelmerGroups: [{ label: "Kelmar-1", value: "Kelmar-1" }, { label: "Kelmar-2", value: "Kelmar-2" }, { label: "Group B", value: "Group B" }, { label: "Group C", value: "Group C" }, { label: "Group D", value: "Group D" }],
      activeTab: "Existing Data",
      isexcludeProperties: false,
      SessionWarningModal: false,
      cities: [{ name: "new", manc1: 5, manc2: 10, manc3: 7 }],
      selectedAnalyst: [],
      isexcludeRoyalties: true,
      isredirtRanges: false,
      StatePropertyIdArray: [],
      selectedState: [],
      page: 1,
      selectedState: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'DC', 'WA', 'WV', 'WI', 'WY'],
      searchmodifiedState: [
        { label: "AL (Alabama)", value: "AL" },
        { label: "AK (Alaska)", value: "AK" },
        { label: "AZ (Arizona)", value: "AZ" },
        { label: "AR (Arkansas)", value: "AR" },
        { label: "CA (California)", value: "CA" },
        { label: "CO (Colorado)", value: "CO" },
        { label: "CT (Connecticut)", value: "CT" },
        { label: "DE (Delaware)", value: "DE" },
        { label: "FL (Florida)", value: "FL" },
        { label: "GA (Georgia)", value: "GA" },
        { label: "HI (Hawaii)", value: "HI" },
        { label: "ID (Idaho)", value: "ID" },
        { label: "IL (Illinois)", value: "IL" },
        { label: "IN (Indiana)", value: "IN" },
        { label: "IA (Iowa)", value: "IA" },
        { label: "KS (Kansas)", value: "KS" },
        { label: "KY (Kentucky)", value: "KY" },
        { label: "LA (Louisiana)", value: "LA" },
        { label: "ME (Maine)", value: "ME" },
        { label: "MD (Maryland)", value: "MD" },
        { label: "MA (Massachusetts)", value: "MA" },
        { label: "MI (Michigan)", value: "MI" },
        { label: "MN (Minnesota)", value: "MN" },
        { label: "MS (Mississippi)", value: "MS" },
        { label: "MO (Missouri)", value: "MO" },
        { label: "MT (Montana)", value: "MT" },
        { label: "NE (Nebraska)", value: "NE" },
        { label: "NV (Nevada)", value: "NV" },
        { label: "NH (New Hampshire)", value: "NH" },
        { label: "NJ (New Jersey)", value: "NJ" },
        { label: "NM (New Mexico)", value: "NM" },
        { label: "NY (New York)", value: "NY" },
        { label: "NC (North Carolina)", value: "NC" },
        { label: "ND (North Dakota)", value: "ND" },
        { label: "OH (Ohio)", value: "OH" },
        { label: "OK (Oklahoma)", value: "OK" },
        { label: "OR (Oregon)", value: "OR" },
        { label: "PA (Pennsylvania)", value: "PA" },
        { label: "PR (Puerto Rico)", value: "PR" },
        { label: "RI (Rhode Island)", value: "RI" },
        { label: "SC (South Carolina)", value: "SC" },
        { label: "SD (South Dakota)", value: "SD" },
        { label: "TN (Tennessee)", value: "TN" },
        { label: "TX (Texas)", value: "TX" },
        { label: "UT (Utah)", value: "UT" },
        { label: "VT (Vermont)", value: "VT" },
        { label: "VA (Virginia)", value: "VA" },
        { label: "DC (Washington - DC)", value: "DC" },
        { label: "WA (Washington - WA)", value: "WA" },
        { label: "WV (West Virginia)", value: "WV" },
        { label: "WI (Wisconsin)", value: "WI" },
        { label: "WY (Wyoming)", value: "WY" },
      ],
      // searchmodifiedState: [
      //   { label: "AL (Alabama)", value: "AL" },
      //   { label: "AK (Alaska)", value: "AK" },
      //   { label: "AZ (Arizona)", value: "AZ" },
      //   { label: "AR (Arkansas)", value: "AR" },
      //   { label: "CO (Colorado)", value: "CO" },
      //   { label: "CT (Connecticut)", value: "CT" },
      //   { label: "DE (Delaware)", value: "DE" },
      //   { label: "ID (Idaho)", value: "ID" },
      //   { label: "IL (Illinois)", value: "IL" },
      //   { label: "IN (Indiana)", value: "IN" },
      //   { label: "IA (Iowa)", value: "IA" },
      //   { label: "LA (Louisiana)", value: "LA" },
      //   { label: "ME (Maine)", value: "ME" },
      //   { label: "MA (Massachusetts)", value: "MA" },
      //   { label: "MI (Michigan)", value: "MI" },
      //   { label: "MN (Minnesota)", value: "MN" },
      //   { label: "MS (Mississippi)", value: "MS" },
      //   { label: "NE (Nebraska)", value: "NE" },
      //   { label: "NH (New Hampshire)", value: "NH" },
      //   { label: "NJ (New Jersey)", value: "NJ" },
      //   { label: "NC (North Carolina)", value: "NC" },
      //   { label: "ND (North Dakota)", value: "ND" },
      //   { label: "OH (Ohio)", value: "OH" },
      //   { label: "OR (Oregon)", value: "OR" },
      //   { label: "PR (Puerto Rico)", value: "PR" },
      //   { label: "RI (Rhode Island)", value: "RI" },
      //   { label: "SC (South Carolina)", value: "SC" },
      //   { label: "SD (South Dakota)", value: "SD" },
      //   { label: "TX (Texas)", value: "TX" },
      //   { label: "UT (Utah)", value: "UT" },
      //   { label: "VA (Virginia)", value: "VA" },
      //   { label: "DC (Washington - DC)", value: "DC" },
      //   { label: "WA (Washington - WA)", value: "WA" },
      //   { label: "WV (West Virginia)", value: "WV" },
      //   { label: "WY (Wyoming)", value: "WY" },
      // ],
      upmodifiedState: [
        { label: "AL (Alabama)", value: "AL" },
        { label: "AK (Alaska)", value: "AK" },
        { label: "AZ (Arizona)", value: "AZ" },
        { label: "AR (Arkansas)", value: "AR" },
        { label: "CA (California)", value: "CA" },
        { label: "CA-N (California N)", value: "CA-N" },
        { label: "CO (Colorado)", value: "CO" },
        { label: "CT (Connecticut)", value: "CT" },
        { label: "DE (Delaware)", value: "DE" },
        { label: "FL (Florida)", value: "FL" },
        { label: "GA (Georgia)", value: "GA" },
        { label: "HI (Hawaii)", value: "HI" },
        { label: "ID (Idaho)", value: "ID" },
        { label: "IL (Illinois)", value: "IL" },
        { label: "IN (Indiana)", value: "IN" },
        { label: "IA (Iowa)", value: "IA" },
        { label: "KS (Kansas)", value: "KS" },
        { label: "KY (Kentucky)", value: "KY" },
        { label: "LA (Louisiana)", value: "LA" },
        { label: "ME (Maine)", value: "ME" },
        { label: "MD (Maryland)", value: "MD" },
        { label: "MA (Massachusetts)", value: "MA" },
        { label: "MI (Michigan)", value: "MI" },
        { label: "MN (Minnesota)", value: "MN" },
        { label: "MS (Mississippi)", value: "MS" },
        { label: "MO (Missouri)", value: "MO" },
        { label: "MT (Montana)", value: "MT" },
        { label: "NE (Nebraska)", value: "NE" },
        { label: "NV (Nevada)", value: "NV" },
        { label: "NH (New Hampshire)", value: "NH" },
        { label: "NJ (New Jersey)", value: "NJ" },
        { label: "NM (New Mexico)", value: "NM" },
        { label: "NY (New York)", value: "NY" },
        { label: "NC (North Carolina)", value: "NC" },
        { label: "ND (North Dakota)", value: "ND" },
        { label: "OH (Ohio)", value: "OH" },
        { label: "OK (Oklahoma)", value: "OK" },
        { label: "OR (Oregon)", value: "OR" },
        { label: "PA (Pennsylvania)", value: "PA" },
        { label: "PR (Puerto Rico)", value: "PR" },
        { label: "RI (Rhode Island)", value: "RI" },
        { label: "SC (South Carolina)", value: "SC" },
        { label: "SD (South Dakota)", value: "SD" },
        { label: "TN (Tennessee)", value: "TN" },
        { label: "TX (Texas)", value: "TX" },
        { label: "UT (Utah)", value: "UT" },
        { label: "VT (Vermont)", value: "VT" },
        { label: "VA (Virginia)", value: "VA" },
        { label: "DC (Washington - DC)", value: "DC" },
        { label: "WA (Washington - WA)", value: "WA" },
        { label: "WV (West Virginia)", value: "WV" },
        { label: "WI (Wisconsin)", value: "WI" },
        { label: "WY (Wyoming)", value: "WY" },
      ],
      modifiedStates: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      isValidate: false,
      excludeCoOwners: false,
      excludeNotConfirmedOnSite: false,
      isexcludePossiblyPaid: false,
      modifiedStatus: [
        { label: "Possible Confirmation", value: "Possible Confirmation" },
        { label: "Waiting for Validation", value: "Waiting for Validation" },
      ],
      matchBuckets: [
        { label: "Overall Near Exact", value: "Overall Near Exact" },
        { label: "Overall Tier-1", value: "Overall Tier-1" },
        { label: "Overall Tier-2", value: "Overall Tier-2" },
        { label: "Overall Tier-3", value: "Overall Tier-3" },
        { label: "Name Near Exact", value: "Name Near Exact" },
        { label: "Name Tier-1", value: "Name Tier-1" },
        { label: "Name Tier-2", value: "Name Tier-2" },
        { label: "Name Tier-3", value: "Name Tier-3" },
        { label: "Address Near Exact", value: "Address Near Exact" },
        { label: "Address Tier-1", value: "Address Tier-1" },
        { label: "Address Tier-2", value: "Address Tier-2" },
        { label: "Address Tier-3", value: "Address Tier-3" },
      ],
      dropdownOpen: false
    };
    // onEditorValueChange(this)
  }
  getClaimStatus = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    fetchMethodRequest('GET', `${apiCalls.ClaimStatuses}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["claimStatuses"] && response["claimStatuses"].length > 0) {
        let propertyStatuses = response["claimStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        this.setState({ claimStatus: StatusArray })
      }
    })
    return this.state.settingsData;
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  componentDidMount = async () => {
    let loginData = localStorage.getItem("loginCredentials");
    loginData = JSON.parse(loginData);
    let defanalyst = []
    defanalyst.push(loginData.displayName)
    await this.setState({ roleName: loginData.roleName })
    this.setState({ isLoading: false, selectedAnalyst: defanalyst, defanalyst: defanalyst });
    this.searchsellerItems();
    this.getClaimStatus()
    // this.getReasonForEditor()
    this.clearInputFields();
    let hitback = localStorage.getItem('hitback');
    let claimSummaryId = localStorage.getItem('claimSummaryID')
    let claimSummaryName = localStorage.getItem('claimSummaryName')
    if (this.props.type === "ClaimsManagement" && hitback === "yes" && claimSummaryId) {
      let estateObj = { EstateName: claimSummaryName, EstateID: claimSummaryId }
      let filterCriteria = {}
      filterCriteria.criteria = []
      filterCriteria.page = "1"; filterCriteria.limit = 10; filterCriteria.direction = "desc"; filterCriteria.sortfield = "OppClaimID"
      filterCriteria.criteria.push({ key: 'CompanyID', value: claimSummaryId, type: 'eq', add_Filter: true })
      await this.setState({ EstateID: claimSummaryId, filterCriteria: filterCriteria, selectedSeller: estateObj })
      this.submit()
    }
    let taskProperties = JSON.parse(localStorage.getItem("TaskProperties"))

    if (taskProperties) {
      let selectedStates = []
      this.handleChange(taskProperties.PropertiesSellerName, 'Seller')
      if (taskProperties.States && taskProperties.States.length > 0) {
        this.handleChange(taskProperties.States, 'State')
        for (var sine of taskProperties.States) {
          selectedStates.push(
            sine.slice(-2),
          )
        }
      }
      await this.setState({
        selectedSeller: taskProperties.PropertiesSellerName,
        selectedState: selectedStates,
        taskProperties: taskProperties,

      })
      await this.searchnameItems()
      this.submit()
      localStorage.removeItem("TaskProperties")
    }
    if (this.props.type === "Properties") {
      await this.setState({
        selectedBucket: "Overall Near Exact"
      })
    }

    if (this.props.onRef) {
      this.props.onRef(this);
    }

    let userArray = []
    return fetch('GET', `${apiCalls.Users}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          for (let user of response.users) {
            userArray.push({ label: user.display_name, value: user.display_name })
          }
          await this.setState({ userArray: userArray })
        }
      }).catch((err) => {
        return err;
      });


  }
  deleteSearchItem = async (item) => {
    item.activeIndicator = false
    let apiUrl = apiCalls.SearchTerms;
    let url
    let body = item
    url = `${apiUrl}/${item._id}`;
    await this.setState({ isLoading: true })
    return fetch('PUT', url, body)
      .then(async (response) => {
        if (response && response.respMessage) {
          await this.setState({ errorResponse: true, errorMessage: response.respMessage, errorcolor: "green" })
          await this.getSearchTermsfiltered()

        } else if (response && response.errorMessage) {
          await this.setState({ errorResponse: true, errorMessage: response.errorMessage, errorcolor: "red" })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
          })
        }
        await this.setState({ isLoading: false })
      }).catch((err) => {
        return err;
      });
  }
  getSelectedTabTypeInfo = async (tabType) => {
    await this.setState({
      activeTab: tabType,
    })

    if (tabType === "Search Terms" || tabType === "Confirm On Site" || tabType === "Data Upload in OPRA") {
      // this.getSearchTerms()
      // await this.setState({ StatePropertyIdArray: [], isByCity: false, addedProperties: [] })
      await this.setState({ isByCity: false })

    }
    this.props.clearOnsitemessage()
    this.searchnameItems()
    // this.getSearchTerms(tabType)
  }
  getsearchSelectedTabTypeInfo = async (tabType) => {
    await this.setState({ searchactiveTab: tabType })
    this.getRemoveFilters()
    this.getSearchTerms(tabType)
  }
  dateTemplate = (rowData, column) => {
    var m = rowData["updatedAtUtc"] ? moment.utc(rowData["updatedAtUtc"]) : null;
    let x = m ? m.tz('America/Chicago') : null;
    var s = x ? x.format("MM-DD-YYYY hh:mm A") : null;
    return <span className='d-flex'>
      {rowData["updatedAtUtc"] ? s : "N/A"}
      {/* {rowData["DWDateLastModified"] ? moment(rowData["DWDateLastModified"]).format("MM-DD-YYYY") : "N/A"} */}
    </span >
  }
  textEditor = (options, item) => {
    if (options && options.rowData && (options.rowData.isAdded || options.rowData.newlyAdded)) {
      return (

        <div>
          <InputText
            type="text"
            name={"propertyName"}
            value={options && options.rowData && options.rowData.propertyName ? options.rowData.propertyName : ""}
            onChange={(e) => this.onEditorValueChangeName(options, e.target.value, "propertyName")}
          // placeholder={item.header}
          // keyfilter={item.keyfilter ? item.keyfilter : null}
          // validateOnly={true}
          />
        </div>


      )
    } else {
      return <span className='' data-toggle="tool-tip"
        title={options && options.rowData && options.rowData.propertyName ? options.rowData.propertyName : ""} style={{ textOverflow: "ellipsis" }}>
        {options && options.rowData && options.rowData.propertyName ? options.rowData.propertyName : ""}
      </span >
    }
  }
  getConfirmaionModel() {
    return (
      <ConfirmaionModels
        openConfirmationModal={this.state.isApplyFilter}
        closeConfirmationModal={this.isConfirmFilter}
        onFilterChange={this.onFilterChange}
        filterInput={this.state.filteredCol}
        handleFilterInputField={this.handleFilterInputField}

      />
    )
  }
  onFilterChange = async (event, type, screen) => {
    if (event && event.persist) {
      event.persist();
    }
    this.setState({
      isLoading: true,
      selectedRows: '',
      selectedKey: event.key === "Enter" || event.filters ? true : false,
      isDisabledTab: true,
      isChangeHit: true,
    })
    let fieldName = '', field,
      selectedFilterValue, selectedFilter, selecterFilterType, isDateFilter = false;

    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        field = event.filters;
        selectedFilter = field[fieldName];
        selecterFilterType = this.state.addedMatchforCompany ? selectedFilter.matchMode : document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0] ? document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0].value :
          "eq"
        selectedFilterValue = selectedFilter && selectedFilter.value ? encodeURIComponent(selectedFilter.value) : selectedFilter ? selectedFilter : null;

      }
      this.setState({ selecterFilterType: selecterFilterType, selectedFilterValue: selectedFilterValue })
      this.getDataFromFilters("filter", selecterFilterType, selectedFilterValue)

    }




  }
  onFilterChangeSearch = async (event, type, screen) => {
    if (event && event.persist) {
      event.persist();
    }
    this.setState({
      isLoading: true,
      selectedRows: '',
      selectedKey: event.key === "Enter" || event.filters ? true : false,
      isDisabledTab: true,
      isChangeHit: true,
    })
    let fieldName = '', field,
      selectedFilterValue, selectedFilter, selecterFilterType, isDateFilter = false;

    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        field = event.filters;
        selectedFilter = field[fieldName];
        selecterFilterType = this.state.addedMatchforCompany ? selectedFilter.matchMode : document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0] ? document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0].value :
          "eq"
        selectedFilterValue = selectedFilter && selectedFilter.value ? encodeURIComponent(selectedFilter.value) : selectedFilter ? selectedFilter : null;

      }
      this.setState({ selecterFilterType: selecterFilterType, selectedFilterValue: selectedFilterValue })
      this.getDataFromFiltersSearch("filter", selecterFilterType, selectedFilterValue)


    }
  }
  onFilterChangeAdded = async (event, type, screen) => {
    if (event && event.persist) {
      event.persist();
    }
    this.setState({
      isLoading: true,
      selectedRows: '',
      selectedKey: event.key === "Enter" || event.filters ? true : false,
      isDisabledTab: true,
      isChangeHit: true,
    })
    let fieldName = '', field,
      selectedFilterValue, selectedFilter, selecterFilterType, isDateFilter = false;

    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        field = event.filters;
        selectedFilter = field[fieldName];
        selecterFilterType = this.state.addedMatchforCompany ? selectedFilter.matchMode : document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[1] ? document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[1].value :
          "eq"
        selectedFilterValue = selectedFilter && selectedFilter.value ? encodeURIComponent(selectedFilter.value) : selectedFilter ? selectedFilter : null;

      }

      this.setState({ selecterFilterTypeAdded: selecterFilterType, selectedFilterValueAdded: selectedFilterValue })
      this.getDataFromFiltersAdded("filter", selecterFilterType, selectedFilterValue)

    }



  }
  getDataFromFilters = async (type, selecterFilterType, selectedFilterValue, filterCriteria1) => {
    let filterCriteria = {}
    filterCriteria.criteria = []
    if (type === "filter") {
      filterCriteria.page = "1"; filterCriteria.limit = 1000;
      filterCriteria.direction = "desc"; filterCriteria.sortfield = "statePropertyIdCount"

      filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" }, { key: "PropertyName", value: selectedFilterValue, type: selecterFilterType })
    } else if (filterCriteria1 === "clear") {
      filterCriteria.page = "1"; filterCriteria.limit = 1000;
      filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" })

    } else {
      filterCriteria = filterCriteria1
      if (this.state.selecterFilterType && this.state.selectedFilterValue) {
        filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" }, { key: "PropertyName", value: this.state.selectedFilterValue, type: this.state.selecterFilterType })
      } else {
        filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" })

      }
    }
    await this.setState({ filterCriteria: filterCriteria })
    // await this.setState({ selectedSearchTerm: "", showSearchTable: true })
    this.setState({ errorResponse: false, errorMessage: '' })
    let apiUrl = `${apiCalls.GetStatePropertyIDSummary}?filter=${JSON.stringify(filterCriteria)}`

    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        if (response && response.result && response.result.length > 0) {
          for (let resp of response.result) {
            if (!resp.isInclude) {
              resp.isInclude = "Yes"
            }
          }
          await this.setState({ StatePropertyIdArray: response.result, isLoading: false, cities: response.result, headers: response.headers })
        } else {
          await this.setState({ StatePropertyIdArray: [], isLoading: false, cities: [], headers: [] })

        }
      }).catch((err) => {
        return err;
      });
  }
  getDataFromFiltersSearch = async (type, selecterFilterType, selectedFilterValue, filterCriteria1) => {
    await this.setState({ searchTermsRecords: [], isLoading: true })
    let filterCriteria = {}
    filterCriteria.criteria = []
    if (type === "filter") {
      filterCriteria.page = "1"; filterCriteria.limit = 1000;
      filterCriteria.direction = "asc"; filterCriteria.sortfield = "searchTerm"
      if (this.state.searchactiveTab === "Inactive/Deleted") {
        await this.setState({ searchactiveTab: "Inactive/Deleted" })
        filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "ne" }, { key: "searchTerm", value: selectedFilterValue, type: selecterFilterType })
      } else {
        await this.setState({ searchactiveTab: "Active" })
        filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "eq" }, { key: "searchTerm", value: selectedFilterValue, type: selecterFilterType })
      }
    } else if (filterCriteria1 === "clear") {
      filterCriteria.page = "1"; filterCriteria.limit = 1000;
      filterCriteria.direction = "asc"; filterCriteria.sortfield = "searchTerm"
      if (this.state.searchactiveTab === "Inactive/Deleted") {
        await this.setState({ searchactiveTab: "Inactive/Deleted" })
        filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "ne" },)
      } else {
        await this.setState({ searchactiveTab: "Active" })
        filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "eq" },)
      }
    } else {
      filterCriteria = filterCriteria1
      if (this.state.selecterFilterType && this.state.selectedFilterValue) {
        if (this.state.searchactiveTab === "Inactive/Deleted") {
          await this.setState({ searchactiveTab: "Inactive/Deleted" })
          filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "ne" }, { key: "searchTerm", value: selectedFilterValue, type: selecterFilterType })
        } else {
          await this.setState({ searchactiveTab: "Active" })
          filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "eq" }, { key: "searchTerm", value: selectedFilterValue, type: selecterFilterType })
        }
      } else {
        if (this.state.searchactiveTab === "Active") {
          filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "eq" },)
        } else {
          filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "ne" },)
        }
      }
    }
    await this.setState({ filterCriteria: filterCriteria, isfiltered: true })
    // await this.setState({ selectedSearchTerm: "", showSearchTable: true })
    await this.setState({ selectedSearchTerm: "", showSearchTable: true, isByCity: false })
    this.setState({ errorResponse: false, errorMessage: '' })
    return fetchMethodRequest('GET', `${apiCalls.SearchTerms}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.dealSearchTerms && response.dealSearchTerms.length > 0) {
          await this.setState({
            searchTermsRecords: response.dealSearchTerms,
            isLoading: false
          })
        } else if (response && response.dealSearchTerms && response.dealSearchTerms.length == 0) {
          await this.setState({
            searchTermsRecords: [], noRecords: true, isLoading: false
          })
        }
      }).catch((err) => {
        return err;
      });
    // await this.setState({ searchTermsRecords: [], isLoading: true })
    // let filterCriteria = {}
    // filterCriteria.criteria = []
    // filterCriteria.page = "1"; filterCriteria.limit = 100; filterCriteria.direction = "asc"; filterCriteria.sortfield = "searchTerm"
    // if (tabType === "Inactive/Deleted") {
    //   await this.setState({ searchactiveTab: "Inactive/Deleted" })
    //   filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "ne" })
    // } else {
    //   await this.setState({ searchactiveTab: "Active" })
    //   filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "eq" })
    // }
    // await this.setState({ selectedSearchTerm: "", showSearchTable: true, isByCity: false })
    // this.setState({ errorResponse: false, errorMessage: '' })
    // return fetchMethodRequest('GET', `${apiCalls.SearchTerms}?filter=${JSON.stringify(filterCriteria)}`)
    //   .then(async (response) => {
    //     if (response && response.dealSearchTerms && response.dealSearchTerms.length > 0) {
    //       await this.setState({
    //         searchTermsRecords: response.dealSearchTerms,
    //         isLoading: false
    //       })
    //     } else if (response && response.dealSearchTerms && response.dealSearchTerms.length == 0) {
    //       await this.setState({
    //         searchTermsRecords: [], noRecords: true, isLoading: false
    //       })
    //     }
    //   }).catch((err) => {
    //     return err;
    //   });

  }
  getDataFromFiltersAdded = async (type, selecterFilterType, selectedFilterValue, filterCriteria1) => {
    let filterCriteria = {}
    filterCriteria.criteria = []
    if (type === "filter") {
      filterCriteria.page = "1"; filterCriteria.limit = 1000;
      filterCriteria.direction = "desc"; filterCriteria.sortfield = "statePropertyIdCount"

      filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" }, { key: "PropertyName", value: selectedFilterValue, type: selecterFilterType })
    } else if (filterCriteria1 === "clear") {
      filterCriteria.page = "1"; filterCriteria.limit = 1000;
      filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" })

    } else {
      filterCriteria = filterCriteria1
      if (this.state.selecterFilterType && this.state.selectedFilterValue) {
        filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" }, { key: "PropertyName", value: this.state.selectedFilterValueAdded, type: this.state.selecterFilterTypeAdded })
      } else {
        filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" })

      }
    }
    await this.setState({ filterCriteria: filterCriteria })
    // await this.setState({ selectedSearchTerm: "", showSearchTable: true })
    this.setState({ errorResponse: false, errorMessage: '' })
    return fetchMethodRequest('GET', `${apiCalls.ReferencePropertyName}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.referencePropertyNames && response.referencePropertyNames.length > 0) {
          for (let resp of response.referencePropertyNames) {
            if (!resp.isInclude) {
              resp.isInclude = "Yes"
            }
          }
          await this.setState({ showMoveButton: false, isLoading: false, statePropertyIdRight: true, addedProperties: response.referencePropertyNames })
        } else if (response && response.referencePropertyNames && response.referencePropertyNames.length === 0) {
          await this.setState({ isLoading: false, addedProperties: [] })

        }
      }).catch((err) => {
        return err;
      });
  }
  // onFilterChange = async (event, type, screen) => {
  //   if (event && event.persist) {
  //     event.persist();
  //   }
  //   this.setState({
  //     isLoading: true,
  //     selectedRows: '',
  //     selectedKey: event.key === "Enter" || event.filters ? true : false,
  //     isDisabledTab: true,
  //     isChangeHit: true,
  //   })
  //   let fieldName = '', filterCriteria = this.state.filterCriteria,
  //     selectedFilterValue, selectedFilter, selecterFilterType, isDateFilter = false;
  //   if (event) {
  //     if (event.filters && !type) {
  //       if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
  //         fieldName = Object.keys(event.filters)[0];
  //       }
  //       let field = event.filters;
  //       selectedFilter = field[fieldName];
  //       selecterFilterType = this.state.addedMatchforCompany ? selectedFilter.matchMode : document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0] ? document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0].value :
  //         "eq"
  //       selectedFilterValue = selectedFilter && selectedFilter.value ? encodeURIComponent(selectedFilter.value) : selectedFilter ? selectedFilter : null;
  //       if (selecterFilterType === "multi") {//for multiSelect encode is not needed
  //         selectedFilterValue = selectedFilter.value
  //       }
  //       if (selecterFilterType === "fuzzyregexOr") {//for wild acrd in query 
  //         await this.setState({
  //           isWildCard: true
  //         })
  //       } else {
  //         await this.setState({
  //           isWildCard: false
  //         })
  //       }
  //       if (selecterFilterType === "nin" || selecterFilterType === "in") {
  //         selectedFilterValue = "nexists"
  //       }
  //       if (event && event.filters && event.filters[fieldName].matchMode === "clear") {//for clearing column filter menu
  //         selectedFilterValue = null
  //       }
  //       if (fieldName === 'date' || fieldName === 'LastUpdated' || fieldName === 'updated' || fieldName === 'MostRecent10KFormDate' || fieldName === 'created' || fieldName === 'updated' || fieldName.includes("Date") || fieldName.includes("date") && fieldName !== "validatedBy" || fieldName.includes('Time')) {
  //         if (selecterFilterType !== "nin" && selecterFilterType !== "in") {
  //           selecterFilterType = "date" + selecterFilterType
  //         }
  //         isDateFilter = true;
  //       }
  //       if ((this.props.isSql === "yes" && this.props.isSql !== undefined) || this.props.type === "Property Editor") {
  //         if (selecterFilterType === "in") {
  //           selecterFilterType = "nexists"
  //         } else if (selecterFilterType === "nin") {
  //           selecterFilterType = "exists"
  //         }
  //       }
  //     } else {
  //       fieldName = event && event.target && event.target.name ? event.target.name : "globalSearch";
  //       selectedFilterValue = event
  //       type = "eq"
  //     }
  //     if (selectedFilterValue === null) {
  //       let index
  //       index = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
  //       if (index !== -1) filterCriteria.criteria.splice(index, 1);
  //       filterCriteria.page = "1"
  //       await this.setState({
  //         filterCriteria: filterCriteria,
  //         first: 0,
  //         page: 1,
  //         selectFilterValue: "",
  //       });
  //       if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties") {
  //         let index;
  //         index = filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
  //         if (index !== -1) filterCriteria["criteria"].splice(index, 1)
  //         filterCriteria["criteria"].push({
  //           key: "bucket",
  //           value: this.state.activeTab === "SP" ? ["Green", "Blue", "Yellow"] : this.state.activeTab,
  //           type: this.state.activeTab === "SP" ? "in" : "eq",
  //           // type: "eq",
  //           add_Filter: true
  //         })
  //       }
  //       // if (this.state.selectedKey) {
  //       if (this.props.type !== "") {
  //         if (this.props.type === "State Attributes") {
  //           await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
  //         } else {
  //           await this.getDataFromServer(filterCriteria, "column_filter", "", fieldName)
  //         }
  //       } else { this.getUsersSummaryData(this.state.summaryObject, true) }
  //       // }
  //     } else if ((selectedFilterValue && selectedFilterValue.length && ((fieldName === "globalSearch" && selectedFilterValue.length > 0) || (fieldName !== "globalSearch" && selectedFilterValue.length >= 1)))) {
  //       if (fieldName === 'globalSearch') {
  //         await this.setState({
  //           selectFilterValue: event
  //         })
  //         filterCriteria.page = "1"
  //         this.setState({ first: 0 })
  //         filterCriteria.globalSearch = {
  //           value: encodeURIComponent(selectedFilterValue),
  //           type: this.props.globalSearchFieldName
  //         };
  //         if (this.props.type === "Company") {
  //           localStorage.setItem("SearchedCompany", selectedFilterValue)
  //         };
  //         if (event && event.target && event.target.value && event.target.value.length === 0) {
  //           delete filterCriteria.globalSearch;
  //         };
  //       } else {
  //         let valdat
  //         if (this.props.isSql === "yes" && this.props.isSql !== undefined && isDateFilter) {
  //           valdat = ["", null, 0]
  //         } else if (this.props.isSql === undefined && !isDateFilter && event.filters[fieldName].matchMode === "num" && this.props.type !== "Property Editor") {
  //           valdat = ["", null, "0"]
  //         } else if (this.props.isSql === undefined && this.props.type === "Property Editor") {
  //           valdat = [""]
  //         } else if (this.props.isSql === undefined && (this.props.type === "ClaimSummary" || this.props.type === "ClaimsManagement")) {
  //           valdat = [""]
  //         }
  //         filterCriteria.page = 1;
  //         if (filterCriteria['criteria'].length === 0) {
  //           filterCriteria['criteria'].push({
  //             key: fieldName === "propertyNote" ? fieldName + ".note" : fieldName,//for claims we need add .note
  //             value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" ? this.trimmedValue(decodeURIComponent(selectedFilterValue)) : selectedFilterValue === "nexists" ? [""] : selectedFilterValue,
  //             // value: [""],
  //             type: selecterFilterType === "multi" ? "in" : selecterFilterType,
  //             add_Filter: this.state.addedMatchforCompany ? true : false
  //           });
  //         } else {
  //           let obj, index;
  //           index = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
  //           if (index !== -1) {
  //             filterCriteria['criteria'].splice(index, 1)
  //           }
  //           filterCriteria['criteria'].push({
  //             key: fieldName === "propertyNote" ? fieldName + ".note" : fieldName,
  //             value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" ? this.trimmedValue(decodeURIComponent(selectedFilterValue)) : selectedFilterValue === "nexists" ? [""] : selectedFilterValue,
  //             // value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" && selectedFilterValue !== "exists" ? parseInt(selectedFilterValue) : [""],
  //             type: selecterFilterType === "multi" ? "in" : selecterFilterType,
  //             add_Filter: this.state.addedMatchforCompany ? true : false
  //           });

  //           // }
  //         }
  //         await this.setState({
  //           filterCriteria: filterCriteria,
  //           page: 1,
  //           first: 0
  //         });
  //       }
  //       // if (this.state.selectedKey) {
  //       if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties") {
  //         let index;
  //         index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
  //         if (index !== -1) this.state.filterCriteria["criteria"].splice(index, 1)
  //         this.state.filterCriteria["criteria"].push({
  //           key: "bucket",
  //           value: this.state.activeTab === "SP" ? ["Green", "Blue", "Yellow"] : this.state.activeTab,
  //           type: this.state.activeTab === "SP" ? "in" : "eq",
  //           // type: "eq",
  //           add_Filter: true
  //         })
  //       }
  //       this.state.filterCriteria["criteria"] = [...this.state.filterCriteria["criteria"], ...this.state.criteriaData["criteria"]]
  //       if (this.props.type !== "UP Opportunity") {
  //         if (this.props.type === "State Attributes") {
  //           await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
  //         } else {
  //           await this.getDataFromServer(filterCriteria, "column_filter", "")
  //         }
  //       } else { this.getUsersSummaryData(this.state.summaryObject, true) }
  //       if (screen === "DetailView") {
  //         this.formModalRef.closeFormModal();
  //       }
  //       // }
  //     } else {

  //       if (selectedFilterValue.length === undefined && !isDateFilter) {
  //         let obj = filterCriteria.criteria.find(x => x.key === fieldName && x.add_Filter === false);
  //         let index = filterCriteria.criteria.indexOf(obj);
  //         filterCriteria.criteria.splice(index, 1);
  //         // }
  //         if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") {
  //           let index;
  //           index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "bucket")
  //           if (index !== -1) this.state.filterCriteria["criteria"].splice(index, 1)
  //           this.state.filterCriteria["criteria"].push({
  //             key: "bucket",
  //             value: this.state.activeTab === "SP" ? ["Green", "Blue", "Yellow"] : this.state.activeTab,
  //             type: this.state.activeTab === "SP" ? "in" : "eq",
  //             // type: "eq",
  //             add_Filter: true
  //           })
  //         }
  //         filterCriteria.page = "1"
  //         await this.setState({
  //           filterCriteria: filterCriteria,
  //           page: "1",
  //           first: 0,
  //           selectFilterValue: ""
  //         });
  //         // if (this.state.selectedKey) {
  //         if (this.props.type === "State Attributes") {
  //           await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
  //         } else {
  //           await this.getDataFromServer(filterCriteria, "column_filter", "", fieldName)
  //         }
  //       }
  //       // }

  //     }
  //     this.setState({
  //       isLoading: false,
  //       progress: 0
  //     })
  //   }
  // }
  onSeletFilterType(value, type) {

    value = this.props.activeTab ? this.state.activeTab.toString() + value : value
    let selectedcolumn = document.getElementsByClassName(`p-column-filter-${value}`)
    if (selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0]) {
      if (!type) {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].focus()
      } else {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].blur()
      }
    }
  }
  onSeletFilterTypeSearch(value, type) {

    value = this.props.activeTab ? this.state.activeTab.toString() + value : value
    let selectedcolumn = document.getElementsByClassName(`p-column-filter-${value}`)
    if (selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0]) {
      if (!type) {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].focus()
      } else {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].blur()
      }
    }
  }

  onSeletFilterTypeAdded(value, type) {

    value = this.props.activeTab ? this.state.activeTab.toString() + value : value
    let selectedcolumn = document.getElementsByClassName(`p-column-filter-${value}`)
    if (selectedcolumn[1].getElementsByClassName(`p-column-filter`)[1]) {
      if (!type) {
        selectedcolumn[1].getElementsByClassName(`p-column-filter`)[1].focus()
      } else {
        selectedcolumn[1].getElementsByClassName(`p-column-filter`)[1].blur()
      }
    }
  }
  sortChange = (event) => {
    let data = this.props.type === "Labels" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ?
      'asc'
      : this.props.sortDirection ? this.props.sortDirection : "desc";
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = {
        direction: event.selectedOption === "unsort" ? data : event.selectedOption,
        sortfield: event.selectedOption === "unsort" ? this.props.sortField ? this.props.sortField : "statePropertyIdCount" : sortField,
        limit: 1000,
        page: 1,
        criteria: []
      }
      if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
        filterCriteria.globalSearch = this.state.filterCriteria.globalSearch
      }
      this.setState({
        sortCount: sortCount,
        isLoading: true,
        filterCriteria: filterCriteria
      });
      this.getDataFromFilters("Sorting", "", "", filterCriteria)

    }
  }
  sortChangeSearch = (event) => {
    let data = this.props.type === "Labels" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ?
      'asc'
      : this.props.sortDirection ? this.props.sortDirection : "asc";
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = {
        direction: event.selectedOption === "unsort" ? data : event.selectedOption,
        sortfield: event.selectedOption === "unsort" ? this.props.sortField ? this.props.sortField : "searchTerm" : sortField,
        limit: 1000,
        page: 1,
        criteria: []
      }
      if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
        filterCriteria.globalSearch = this.state.filterCriteria.globalSearch
      }
      this.setState({
        sortCount: sortCount,
        isLoading: true,
        filterCriteria: filterCriteria
      });
      this.getDataFromFiltersSearch("Sorting", "", "", filterCriteria)

    }
  }
  sortChangeAdded = (event) => {
    let data = this.props.type === "Labels" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ?
      'asc'
      : this.props.sortDirection ? this.props.sortDirection : "desc";
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = {
        direction: event.selectedOption === "unsort" ? data : event.selectedOption,
        sortfield: event.selectedOption === "unsort" ? this.props.sortField ? this.props.sortField : "created" : sortField,
        limit: 1000,
        page: 1,
        criteria: []
      }
      if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
        filterCriteria.globalSearch = this.state.filterCriteria.globalSearch
      }
      this.setState({
        sortCount: sortCount,
        isLoading: true,
        filterCriteria: filterCriteria
      });
      this.getDataFromFiltersAdded("Sorting", "", "", filterCriteria)

    }
  }

  handleFilterInputField(val, type) {
    let filters = {}, newFil = {}, filteredObj = {};
    if (type) {
      newFil[val] = {
        value: "",
        matchMode: "clear"
      };
      filters[val] = newFil[val]
      filteredObj.filters = filters
      let value = this.state.activeTab ? this.state.activeTab + val : val
      let data = document.getElementsByClassName(`p-column-filterComponent p-column-filter-propertyName`)
      if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].value) {

        data[0].getElementsByClassName(`p-column-filter`)[0].value = ""
      }
      if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].name) {

        let getName = data[0].getElementsByClassName(`p-column-filter`)[0].name
        this.setState({
          [getName]: ""
        })
      }
      // if (val === "hasPropertyCoOwner") {
      this.setState({
        [val]: ""
      })
      document.getElementsByClassName(`p-column-filterComponent p-column-filter-propertyName`)[0].selectedIndex = 0
      document.getElementsByClassName(`p-column-filterComponent p-column-filter-propertyName`)[0].style.display = "none"

      this.setState({ selectedFilterValue: "", selecterFilterType: "" })
      this.getDataFromFilters(filteredObj, "", "", "clear")
    } else {
      let value = this.state.activeTab ? (this.state.activeTab + val) : val
      if ((document.getElementsByClassName(`p-column-filter-propertyName`) && document.getElementsByClassName(`p-column-filter-propertyName`)[0]) !== undefined) {
        if (document.getElementsByClassName(`p-column-filter-propertyName`)[0].style.display === "none") {
          document.getElementsByClassName(`p-column-filter-propertyName`)[0].style.display = "block"
        } else {
          document.getElementsByClassName(`p-column-filter-propertyName`)[0].style.display = "none"
        }
      }

    }
  }
  getRemoveFilters = async () => {

    await this.setState({
      clearFilters: true,
      page: 1,
      first: 0
    })
    let filteredNo = 3
    for (let i = 0; i <= filteredNo; i++) {
      if (document.getElementsByClassName("p-column-filter")[i] && document.getElementsByClassName("p-column-filter")[i].value)
        if (document.getElementsByClassName("p-column-filter")[i].value !== "") {
          let getName = document.getElementsByClassName("p-column-filter")[i].name
          document.getElementsByClassName("p-column-filter")[i].value = ""
          if (document.getElementsByClassName(`p-column-filter p-column-${getName}`).value) {
            document.getElementsByClassName(`p-column-filter p-column-${getName}`).value = ""
          }
          this.setState({
            [getName]: ""
          })
        }
      if (document.getElementsByClassName("p-column-filterMode")[i]) {
        document.getElementsByClassName("p-column-filterMode")[i].selectedIndex = 0
      }
      if (document.getElementsByClassName("p-column-filter p-column-DWDateInserted") && document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0] && document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0].value) {
        document.getElementsByClassName("p-column-filter p-column-DWDateInserted")[0].value = ""
      }

      if (document.getElementsByClassName("p-column-filterComponent")[i] && document.getElementsByClassName("p-column-filterComponent")[i].style.display === "block") {
        document.getElementsByClassName("p-column-filterComponent")[i].style.display = "none"
      }
    }
    // if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt")&&())



    //removing inline filters
    let filterCriteria = this.state.filterCriteria
    var newArr = filterCriteria["criteria"].filter(object => {
      return object.add_Filter !== false;
    });
    filterCriteria["criteria"] = newArr
    filterCriteria["page"] = 1



  }

  handleFilterInputFieldSearch(val, type) {
    let filters = {}, newFil = {}, filteredObj = {};
    if (type) {
      newFil[val] = {
        value: "",
        matchMode: "clear"
      };
      filters[val] = newFil[val]
      filteredObj.filters = filters
      let value = this.state.activeTab ? this.state.activeTab + val : val
      let data = document.getElementsByClassName(`p-column-filterComponent p-column-filter-searchTerm`)
      if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].value) {

        data[0].getElementsByClassName(`p-column-filter`)[0].value = ""
      }
      if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].name) {

        let getName = data[0].getElementsByClassName(`p-column-filter`)[0].name
        this.setState({
          [getName]: ""
        })
      }
      // if (val === "hasPropertyCoOwner") {
      this.setState({
        [val]: ""
      })
      document.getElementsByClassName(`p-column-filterComponent p-column-filter-searchTerm`)[0].selectedIndex = 0
      document.getElementsByClassName(`p-column-filterComponent p-column-filter-searchTerm`)[0].style.display = "none"

      this.setState({ selectedFilterValue: "", selecterFilterType: "" })
      this.getDataFromFiltersSearch(filteredObj, "", "", "clear")
    } else {
      let value = this.state.activeTab ? (this.state.activeTab + val) : val
      if ((document.getElementsByClassName(`p-column-filter-searchTerm`) && document.getElementsByClassName(`p-column-filter-searchTerm`)[0]) !== undefined) {
        if (document.getElementsByClassName(`p-column-filter-searchTerm`)[0].style.display === "none") {
          document.getElementsByClassName(`p-column-filter-searchTerm`)[0].style.display = "block"
        } else {
          document.getElementsByClassName(`p-column-filter-searchTerm`)[0].style.display = "none"
        }
      }

    }
  }
  handleFilterInputFieldAdded(val, type) {
    let filters = {}, newFil = {}, filteredObj = {};
    if (type) {
      newFil[val] = {
        value: "",
        matchMode: "clear"
      };
      filters[val] = newFil[val]
      filteredObj.filters = filters
      let value = this.state.activeTab ? this.state.activeTab + val : val
      let data = document.getElementsByClassName(`p-column-filterComponent p-column-filter-propertyName`)
      if (data && data[1] && data[1].getElementsByClassName(`p-column-filter`) && data[1].getElementsByClassName(`p-column-filter`)[0] && data[1].getElementsByClassName(`p-column-filter`)[0].value) {

        data[1].getElementsByClassName(`p-column-filter`)[0].value = ""
      }
      if (data && data[1] && data[1].getElementsByClassName(`p-column-filter`) && data[1].getElementsByClassName(`p-column-filter`)[0] && data[1].getElementsByClassName(`p-column-filter`)[0].name) {

        let getName = data[1].getElementsByClassName(`p-column-filter`)[0].name

        this.setState({
          [getName]: ""
        })
      }
      // if (val === "hasPropertyCoOwner") {
      this.setState({
        [val]: ""
      })
      document.getElementsByClassName(`p-column-filterComponent p-column-filter-propertyName`)[1].selectedIndex = 0
      document.getElementsByClassName(`p-column-filterComponent p-column-filter-propertyName`)[1].style.display = "none"

      this.setState({ selectedFilterValue: "", selecterFilterType: "" })
      this.getDataFromFiltersAdded(filteredObj, "", "", "clear")
    } else {
      let value = this.state.activeTab ? (this.state.activeTab + val) : val
      if ((document.getElementsByClassName(`p-column-filter-propertyName`) && document.getElementsByClassName(`p-column-filter-propertyName`)[1]) !== undefined) {
        if (document.getElementsByClassName(`p-column-filter-propertyName`)[1].style.display === "none") {
          document.getElementsByClassName(`p-column-filter-propertyName`)[1].style.display = "block"
        } else {
          document.getElementsByClassName(`p-column-filter-propertyName`)[1].style.display = "none"
        }
      }

    }
  }

  handleDataChangingOption = async (e) => {
    if (e && e.selectedOption) {
      if (e.selectedOption === "unsort" || e.selectedOption === "asc" || e.selectedOption === "desc") {
        await this.sortChange(e)
      } else if (e.selectedOption === "filter") {
        await this.handleFilterInputField(e.sortField)
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "clear_filter") {
        await this.handleFilterInputField(e.sortField, "clear")
        // this.AddingFilterInput(e.selectedOption, field)
      }
    }
  }
  handleDataChangingOptionAdded = async (e) => {
    if (e && e.selectedOption) {
      if (e.selectedOption === "unsort" || e.selectedOption === "asc" || e.selectedOption === "desc") {
        await this.sortChangeAdded(e)
      } else if (e.selectedOption === "filter") {
        await this.handleFilterInputFieldAdded(e.sortField)
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "clear_filter") {
        await this.handleFilterInputFieldAdded(e.sortField, "clear")
        // this.AddingFilterInput(e.selectedOption, field)
      }
    }
  }
  handleDataChangingOptionSearch = async (e) => {
    if (e && e.selectedOption) {
      if (e.selectedOption === "unsort" || e.selectedOption === "asc" || e.selectedOption === "desc") {
        await this.sortChangeSearch(e)
      } else if (e.selectedOption === "filter") {
        await this.handleFilterInputFieldSearch(e.sortField)
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "clear_filter") {
        await this.handleFilterInputFieldSearch(e.sortField, "clear")
        // this.AddingFilterInput(e.selectedOption, field)
      }
    }
  }

  isConfirmFilter = async (val, type) => {
    if (val && val.filters) {
      let fieldName = Object.keys(val.filters)[0];
      if (val.filters[fieldName].value.length === 0 || val.filters[fieldName].value.length === undefined) {
        await this.setState({ isApplyFilter: true, filteredCol: val })
        this.getConfirmaionModel()
      } else {
        await this.onFilterChange(val)
      }
    } else {
      this.setState({ isLoading: false, isApplyFilter: false, })
      if (type) {
        this.onSeletFilterType(val, type)
        //  this.handleFilterInputField(val, "clear")
      }
    }
  }
  isConfirmFilterSearch = async (val, type) => {
    if (val && val.filters) {
      let fieldName = Object.keys(val.filters)[0];
      if (val.filters[fieldName].value.length === 0 || val.filters[fieldName].value.length === undefined) {
        await this.setState({ isApplyFilter: true, filteredCol: val })
        this.getConfirmaionModel()
      } else {
        await this.onFilterChangeSearch(val)
      }
    } else {
      this.setState({ isLoading: false, isApplyFilter: false, })
      if (type) {
        this.onSeletFilterTypeSearch(val, type)
        //  this.handleFilterInputField(val, "clear")
      }
    }
  }
  isConfirmFilterAdded = async (val, type) => {
    if (val && val.filters) {
      let fieldName = Object.keys(val.filters)[0];
      if (val.filters[fieldName].value.length === 0 || val.filters[fieldName].value.length === undefined) {
        await this.setState({ isApplyFilter: true, filteredCol: val })
        this.getConfirmaionModel()
      } else {
        await this.onFilterChangeAdded(val)
      }
    } else {
      this.setState({ isLoading: false, isApplyFilter: false, })
      if (type) {
        this.onSeletFilterTypeAdded(val, type)
        //  this.handleFilterInputField(val, "clear")
      }
    }
  }
  hoverTemplate = (rowData, column) => {
    if (column.field === "isInclude") {
      return <span className='' data-toggle="tool-tip"
        title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
        {rowData[column.field] ? rowData[column.field] : "Yes"}
      </span >
    } else {
      return <span className='' data-toggle="tool-tip"
        title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
        {rowData[column.field] ? rowData[column.field] : ""}
      </span >
    }
  }
  editTemplateNew = (rowData) => {

    return <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "2rem" }}>
      <span >
        {/* <Button color='primary' className="btnRadius" onClick={() => this.openSignatoryModal(rowData)}>
            Edit
        </Button> */}
        {/* <Button color="primary"
        style={{ padding: "7px 25px" }}
        outline
        className="mb-0 ml-0 mr-2"
        onClick={() => this.openSignatoryModal(rowData)}
      >
        Edit
      </Button> */}
        <FontAwesomeIcon icon={faCopy}
          className='ml-4'
          color='blue'
          data-toggle="tool-tip"
          // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
          onClick={() => { navigator.clipboard.writeText(rowData.searchTerm) }}
          title={"Copy"}
          style={{ width: 18 }}
        />
      </span>
      <span >
        < FontAwesomeIcon
          className="status_logo"
          // color={value === "true" ? '#5cb85c' : 'red'}
          style={{ cursor: "pointer", color: "red", width: "5%" }}
          icon={faMinus}
          data-toggle="tool-tip"
          // title={value === "true" ? 'Active' : 'Inactive'}
          onClick={() => this.deleteSearchItem(rowData, "Delete")}
        />


      </span >
    </div>
  }
  dateTemplateNew = (rowData, column) => {
    return <span className='' data-toggle="tool-tip"
      title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
      {rowData[column.field] ? dateFormats.formatDate(rowData[column.field], "MM/DD/YYYY") : ""}
    </span >

  }
  stateTemplate = (rowData, column) => {

    return <span className='' data-toggle="tool-tip"
      title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
      {rowData[column.field] ? rowData[column.field].join(",") : ""}
    </span >

  }
  moveLeftToRight = () => {
    this.setState({ statePropertyIdRight: true })
  }
  getColumns = () => {

    if (this.state.cities && this.state.cities.length > 0) {
      // let headers = ["manc1", "manc2", "manc3", "manc5", "manc5", "manc5", "manc5", "manc5", "manc5"]
      let headers = this.state.headers
      return headers && headers.length > 0 && headers.map((item, i) => {
        let column = <Column field={item} header={item === "" ? "Blank" : item} bodyStyle={bodyStyleRight} headerStyle={headerStyle} body={this.hoverTemplate} />
        return column
      }

      )



      // return true
    }
  }
  clearData = async () => {
    await this.getExistData("Existing Data")
  }
  AddProperty() {
    let data

    data = {
      "propertyName": "",
      "isInclude": "Yes",
      companyID: this.state.EstateID,
      businessNameID: JSON.parse(this.state.searchtermId),
      sourceState: this.state.selectedState,
      estateName: this.state.selectedSeller.EstateName,
      businessName: this.state.searchTermName.labelName,
      isAdded: true

    }

    let allUsersData = this.state.addedProperties && this.state.addedProperties.length > 0 ? this.state.addedProperties : this.state.StatePropertyIdArray
    allUsersData.push(data)
    // if (this.state.addedProperties && this.state.addedProperties.length > 0) {
    if (this.state.showMoveButton) {
      this.setState({
        // addedProperties: allUsersData,
        StatePropertyIdArray: allUsersData,
        addRow: true,
        isSubmitted: false,
      })
    } else {
      this.setState({
        addedProperties: allUsersData,
        // StatePropertyIdArray: allUsersData,
        addRow: true,
        isSubmitted: false,
      })
    }

    // }


  }
  savePropertyData = () => {
    let allrecords = this.state.addedProperties && this.state.addedProperties.length > 0 ? this.state.addedProperties : this.state.StatePropertyIdArray
    let body = {}, editedRecords = []
    if (!this.state.showMoveButton) {
      for (let allrecord of allrecords) {
        if (allrecord.isAdded) {
          allrecord.newlyAdded = true
        }
        if (allrecord.isedited === "yes") {
          editedRecords.push(allrecord)
        }

      }
      body.allRecords = editedRecords
    } else {
      // for (let allrecord of allrecords) {
      //   if (allrecord.isAdded) {
      //     allrecord.newlyAdded = true
      //   }
      // }
      body.allRecords = allrecords
    }

    this.setState({ isLoading: true })
    return fetch('POST', `${apiCalls.ReferencePropertyName}/multi`, body)
      .then(async (response) => {
        if (response && response.respMessage) {
          await this.setState({ errorResponse: true, errorMessage: response.respMessage, errorcolor: "green" })
          this.showMoveButton()

        } else if (response && response.errorMessage) {
          await this.setState({ errorResponse: true, errorMessage: response.errorMessage, errorcolor: "red" })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
          })
        }
        await this.setState({ isLoading: false })
      }).catch((err) => {
        return err;
      });
  }
  getExistData = async (tabType) => {
    await this.setState({ activeTab: tabType, isLoading: true })
    if (tabType === "Existing Data") {
      await this.setState({ addedProperties: [] })
      if (this.state.byCity) {
        await this.setState({ isByCity: true })
      } else {
        await this.setState({ isByCity: false })
      }
      this.getExistDataShow()
      this.showMoveButton()
    } else if (tabType === "Search Terms") {
      this.getSearchTerms()

    }
  }
  showMoveButton = async () => {
    let filterCriteria = {}
    filterCriteria.criteria = []
    filterCriteria.page = "1"; filterCriteria.limit = 1000;
    // filterCriteria.direction = "desc"; filterCriteria.sortfield = "StatePropertyIdCount"

    filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" })
    if (this.state.roleName === "Search Analyst") {
      filterCriteria.criteria.push({ key: "isInclude", type: "eq", value: "No" })

    }
    // await this.setState({ selectedSearchTerm: "", showSearchTable: true })
    this.setState({ errorResponse: false, errorMessage: '' })
    return fetchMethodRequest('GET', `${apiCalls.ReferencePropertyName}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.referencePropertyNames && response.referencePropertyNames.length > 0) {
          for (let resp of response.referencePropertyNames) {
            if (!resp.isInclude) {
              resp.isInclude = "Yes"
            }
          }
          await this.setState({ showMoveButton: false, isLoading: false, statePropertyIdRight: true, addedProperties: response.referencePropertyNames })
        } else if (response && response.referencePropertyNames && response.referencePropertyNames.length === 0) {
          await this.setState({ showMoveButton: true, isLoading: false })

        }
      }).catch((err) => {
        return err;
      });
  }
  getExistDataShow = async () => {
    let filterCriteria = {}
    filterCriteria.criteria = []
    filterCriteria.page = "1"; filterCriteria.limit = 1000;
    filterCriteria.direction = "desc"; filterCriteria.sortfield = "statePropertyIdCount"

    filterCriteria.criteria.push({ key: "CompanyID", type: "eq", value: this.state.EstateID }, { key: "SourceState", value: this.state.selectedState, type: "in" }, { key: "BusinessNameID", value: this.state.selectedNameNumberArray, type: "in" })

    // await this.setState({ selectedSearchTerm: "", showSearchTable: true })
    this.setState({ errorResponse: false, errorMessage: '' })
    let apiUrl = `${apiCalls.GetStatePropertyIDSummary}?filter=${JSON.stringify(filterCriteria)}`
    if (this.state.byCity) {
      apiUrl = apiUrl + `&isGroupByCity=${true}`
    }
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        if (response && response.result && response.result.length > 0) {
          for (let resp of response.result) {
            if (!resp.isInclude) {
              resp.isInclude = "Yes"
            }
          }
          await this.setState({ StatePropertyIdArray: response.result, isLoading: false, cities: response.result, headers: response.headers, noProp: false })
        } else {
          await this.setState({ StatePropertyIdArray: [], isLoading: false, cities: [], headers: [], noProp: true })

        }
      }).catch((err) => {
        return err;
      });
  }
  sendSearchTermData = async (e, type) => {
    if (e && e.persist) {
      e.persist();
    }
    await this.setState({ errorResponse: false, errorMessage: "", errorcolor: "red", selectedSearchTerm: e.target.value })
    if (e && e.target && e.key === "Enter") {
      if (!this.state.EstateID || !this.state.searchtermId || !this.state.selectedStateGroup) {
        await this.setState({ errorResponse: true, errorMessage: "Please Select Deal,State and Name", errorcolor: "red" })
      }
      else if (e.target.value.length === 1) {
        await this.setState({ errorResponse: true, errorMessage: "Just one letter as a search term is not allowed.", errorcolor: "red" })
      } else {
        let body = {}
        body.searchTerm = e.target.value
        body.estateID = this.state.EstateID
        body.estateName = this.state.selectedSeller.EstateName
        body.nameSearchedID = JSON.parse(this.state.searchtermId)
        body.nameSearched = this.state.searchTermName.labelName
        body.activeIndicator = true
        body.stateGroup = this.state.selectedStateGroup
        await this.setState({ isLoading: true })
        return fetch('POST', apiCalls.SearchTerms, body)
          .then(async (response) => {
            if (response && response.respMessage) {
              await this.setState({ errorResponse: true, errorMessage: response.respMessage, errorcolor: "green" })
              this.getRemoveFilters()
              await this.getSearchTerms()

            } else if (response && response.errorMessage) {
              await this.setState({ errorResponse: true, errorMessage: response.errorMessage, errorcolor: "red" })
            } else if (response && response['errorMessage'] === configMessages.warningMessage) {
              await this.setState({
                sessionWarning: true,
              })
            }
            await this.setState({ isLoading: false })
          }).catch((err) => {
            return err;
          });
      }


    }
  }
  getSearchTerms = async (tabType) => {
    await this.setState({ searchTermsRecords: [], isLoading: true })
    let filterCriteria = {}
    filterCriteria.criteria = []
    filterCriteria.page = "1"; filterCriteria.limit = 100; filterCriteria.direction = "asc"; filterCriteria.sortfield = "searchTerm"
    if (tabType === "Inactive/Deleted") {
      await this.setState({ searchactiveTab: "Inactive/Deleted" })
      filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "ne" })
    } else {
      await this.setState({ searchactiveTab: "Active" })
      filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "eq" })
    }
    await this.setState({ selectedSearchTerm: "", showSearchTable: true, isByCity: false })
    this.setState({ errorResponse: false, errorMessage: '' })
    return fetchMethodRequest('GET', `${apiCalls.SearchTerms}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.dealSearchTerms && response.dealSearchTerms.length > 0) {
          await this.setState({
            searchTermsRecords: response.dealSearchTerms,
            isLoading: false
          })
        } else if (response && response.dealSearchTerms && response.dealSearchTerms.length == 0) {
          await this.setState({
            searchTermsRecords: [], noRecords: true, isLoading: false
          })
        }
      }).catch((err) => {
        return err;
      });


  }
  getSearchTermsfiltered = async (tabType) => {
    let filterCriteria
    await this.setState({ searchTermsRecords: [], isLoading: true })

    if (this.state.isfiltered) {
      filterCriteria = this.state.filterCriteria
    } else {
      filterCriteria = {}
      filterCriteria.criteria = []
      filterCriteria.page = "1"; filterCriteria.limit = 1000; filterCriteria.direction = "asc"; filterCriteria.sortfield = "searchTerm"
      if (tabType === "Inactive/Deleted") {
        await this.setState({ searchactiveTab: "Inactive/Deleted" })
        filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "ne" })
      } else {
        await this.setState({ searchactiveTab: "Active" })
        filterCriteria.criteria.push({ key: "estateID", type: "eq", value: this.state.EstateID }, { key: "stateGroup", value: this.state.selectedStateGroup, type: "eq" }, { key: "nameSearchedID", value: JSON.parse(this.state.searchtermId), type: "eq" }, { key: "ActiveIndicator", value: true, type: "eq" })
      }
    }
    await this.setState({ selectedSearchTerm: "", showSearchTable: true, isByCity: false })
    this.setState({ errorResponse: false, errorMessage: '' })
    return fetchMethodRequest('GET', `${apiCalls.SearchTerms}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.dealSearchTerms && response.dealSearchTerms.length > 0) {
          await this.setState({
            searchTermsRecords: response.dealSearchTerms,
            isLoading: false
          })
        } else if (response && response.dealSearchTerms && response.dealSearchTerms.length == 0) {
          await this.setState({
            searchTermsRecords: [], noRecords: true, isLoading: false
          })
        }
      }).catch((err) => {
        return err;
      });


  }
  SearchBehavior = async (rule, name) => {
    if (rule) {
      await this.setState({ rule: rule, rulename: name })
    }
  }
  CloseSearchBehavior = async () => {
    await this.setState({ rule: "" })
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
    }
  }

  getData = async (obj) => {
    if (obj && obj.selectedRanges && this.props.type === "Property Editor") {
      await this.setState({
        isredirtRanges: true
      })
    }
    if (obj.isValidate) {
      await this.setState({
        isValidate: true,
      })
    }
    await this.setState({
      isSharing: true,
      sharedobj: obj
    })
    if (obj.selectedProperty_Id) {
      await this.setState({
        Property_Id: obj.selectedProperty_Id
      })
    }
    if (obj.selectedOpraStId) {
      await this.setState({
        OpraStId: obj.selectedOpraStId
      })
    }
    if (obj.isexcludeValidated && obj.isexcludeValidated === "true") {
      await this.setState({
        isexcludeValidated: true,
      })
    } else {
      await this.setState({
        isexcludeValidated: false,
      })
    }
    if (obj.excludeNotConfirmedOnSite && obj.excludeNotConfirmedOnSite === "true") {
      await this.setState({
        excludeNotConfirmedOnSite: true,
      })
    } else {
      await this.setState({
        excludeNotConfirmedOnSite: false,
      })
    }
    if (obj.isexcludeProperties && obj.isexcludeProperties === "true") {
      await this.setState({
        isexcludeProperties: true,
      })
    } else {
      await this.setState({
        isexcludeProperties: false,
      })
    }
    if (obj.isexcludePossiblyPaid && obj.isexcludePossiblyPaid === "true") {
      await this.setState({
        isexcludePossiblyPaid: true,
      })
    } else {
      await this.setState({
        isexcludePossiblyPaid: false,
      })
    }
    if (obj.isexcludeRoyalties) {
      await this.setState({
        isexcludeRoyalties: true
      })
    } else {
      await this.setState({
        isexcludeRoyalties: false
      })
    }
    if (obj.excludeCoOwners && obj.excludeCoOwners === "true") {
      await this.setState({
        excludeCoOwners: true,
      })
    } else {
      await this.setState({
        excludeCoOwners: false,
      })
    }

    if (obj.states && obj.states !== "null") {
      let decodedStates = obj.states.split(",")
      await this.setState({
        selectedStates: decodedStates
      })
    }
    if (obj.selectedPropertyStatus) {
      let decodedStatus = obj.selectedPropertyStatus.split(",")
      let eventt = {}
      eventt.value = decodedStatus
      // await this.setState({ selectedPropertyStatus: decodedStatus })
      this.handleChange(eventt, "selectedPropertyStatus")
    }
    // if (!obj.selectedProperty_Id) {
    this.handleChange(this.state.selectedStates, "State")
    this.searchsellerItems(obj.selectedSeller.EstateName, obj)
    // }
  }

  // clear input data
  clearInputFields = () => {
    this.props.reset();
  }

  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  lastUpdatedDate = (e) => {
    this.setState({ lastUpdatedDate: dateFormats.formatDate(e, "MM/DD/YYYY") })
  }
  getReasonForEditor = async (val) => {
    let ReasonsArray = [];
    return fetch('GET', `${apiCalls.P}`)
      .then(async (response) => {
        if (response && response["propertyReasons"].length > 0) {
          let propertyReasons = response["propertyReasons"]
          for (let reason of propertyReasons) {
            ReasonsArray.push({ label: reason.reason, value: reason.reason })
          }
          await this.setState({ ReasonsArray: ReasonsArray })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  ClearValues = () => {
    this.setState({ coutntValue: "", propCount: 0, selectedState: "", ClaimName: "", selectedName: "", selectedSeller: "", searchTermName: "", selectedStateGroup: "", rowData: {}, selectedaddressLine: "", selectedDate: "", totalRecordsCount: null, StatePropertyIdArray: [], addedProperties: [], statePropertyIdRight: false, showSearchTable: false, selectedNamearr: [], selectedNameNumberArray: [] })
    this.props.clearData()
  }
  getDataToExport = async () => {
    await this.setState({ isLoading: true, isexported: true })
    let url = "results";
    let apiUrl, headers;
    let filterCriteriaData = { limit: this.state.totalRecordsCount, criteria: [] }
    let date = moment(this.state.selectedDate).format("MM/DD/YYYY");
    apiUrl = `NsScoredProperties/checkProperties?filter=${JSON.stringify(filterCriteriaData)}&sellerID=${this.state.selectedSeller.EstateID}&state=${this.state.selectedState}&name=${this.state.selectedName}${this.state.selectedaddressLine ? `&addressLine1=${this.state.selectedaddressLine}` : ""}${this.state.selectedDate ? `&escheatmentDate=${date}` : ""}&type=exportToCsv`

    // apiUrl = `NsScoredProperties/checkProperties?filter=${JSON.stringify(filterCriteriaData)}&sellerID=${this.state.selectedSeller.EstateID}&state=${this.state.selectedState}&name=${this.state.selectedName}&addressLine1=${this.state.selectedaddressLine}&type=exportToCsv`;
    if (apiUrl) {
      return fetchMethodRequest('GET', apiUrl)
        .then(async (response) => {
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
          }
          if (response && response[url] && response[url].length > 0) {
            let exportData = response[url];
            exportData.forEach((item) => {
              for (let key in item) {
                if (item[key] && item[key].name) {
                  item[key] = item[key].name;
                }
                if (typeof item[key] == "object") {
                  // item[key] = this.flattenArray(item[key]);
                }
              }
            });
            this.setState(
              {
                exportData: exportData,
                // exportHeaders: headers,
                isLoading: false,
              },
              () => {
                // click the CSVLink component to trigger the CSV download
                this.csvLinkRef.link.click();
              }
            );
          }
          // arrayFormatcolumns = ["Accepted", "EntityEntitlementExhibitNo", "EntityEntitlementExhibitNo", "FEIN", "FEINEvidenceExhibitNo", "AddressExhibitNo", "ClaimID", "PropertyID", "ClaimStatus", "ReportedName", "ReportedAddress1", "ReportedAddress2", "ReportedCity", "ReportedState", "ReportedZipCode"]

        }).catch((err) => {
          this.setState({
            isLoading: false
          });
          return err;
        });
    }

  }
  getCANData = async (type, hitGo) => {
    let page;
    if (type === "increment" && hitGo) {
      page = this.state.page + 1;
    } else if (type === "increment" && hitGo === undefined) {
      page = 1;
    } else {
      page = this.state.page - 1;
    }
    let date = moment(this.state.selectedDate).format("MM/DD/YYYY");

    if (this.state.selectedSeller && this.state.selectedName && this.state.selectedState) {
      await this.setState({
        page: page,
        isLoading: true,
        rowData: {}
      })
      let filterCriteria = { page: this.state.page, limit: 1 }
      filterCriteria['criteria'] = [];
      fetchMethodRequest('GET', `NsScoredProperties/checkProperties?filter=${JSON.stringify(filterCriteria)}&sellerID=${this.state.selectedSeller.EstateID}&state=${this.state.selectedState}&name=${this.state.selectedName}${this.state.selectedaddressLine ? `&addressLine1=${this.state.selectedaddressLine}` : ""}${this.state.selectedDate ? `&escheatmentDate=${date}` : ""}`).then(async (response) => {
        if (response && response["results"] && response["results"].length > 0) {
          this.getFormatData(response["results"][0])
          await this.setState({
            totalRecordsCount: response["pagination"].totalCount
          })
        } else {
          await this.setState({
            totalRecordsCount: 0,
            isLoading: false
          })
        }
      })
    } else {
      this.props.getErrorMessage("Please select all mandatory fields", "", "error")
    }
    // return this.state.settingsData;
  }

  getFormatData = async (data) => {
    let getLabels = [
      { label: "Accepted", value: "Accepted" },
      { label: "Estate ID", value: "Match_EstateId" },
      { label: "OPRA Prop. ID", value: "OPRAPropertyID" },
      { label: "City", value: "Owner Address - City" },
      { label: "Address Line1", value: "Owner Address - Line1" },
      { label: "Address Line2", value: "Owner Address - Line2" },
      { label: "St. Id", value: "Owner Address - StateId" },
      { label: "Zip", value: "Owner Address - Zip" },
      { label: "Alt ID", value: "Property - AlternateID" },
      { label: "Datasrc Id", value: "Property - DatasourceId" },
      { label: "Escheated Dt", value: "Property - DateEscheated" },
      { label: "Dt Of Last Contact", value: "Property - DateOfLastContact" },
      { label: "Holder Extra Info", value: "Property - HolderExtraInfo" },
      { label: "Holder Name", value: "Property - HolderName" },
      { label: "Owner Name", value: "Property - OwnerName" },
      { label: "Description", value: "Property - PropertyDescription" },
      { label: "Value Max", value: "Property - PropertyValueMax" },
      { label: "Value Min", value: "Property - PropertyValueMin" },
      { label: "Prop Shares", value: "Property - Shares" },
      { label: "Prop State ID", value: "Property - StateId" },
      { label: "State Prop ID", value: "Property - StatePropertyId" },
      { label: "Updated Date", value: "UpdatedDateUTC" },
      { label: "Upload File ID", value: "UploadFileId" },
      { label: "Upload RowDone ID", value: "UploadRowDoneID" },
      { label: "Uploaded By", value: "UploadedByUser" },

    ]
    let rowData = data
    let formFields = [];
    if (formFields) {
      let keys = Object.keys(rowData);
      if (this.props.type) {
        if (rowData) {
          let values, self = this, icon = false;
          keys.forEach(async function (key) {
            let labelKey;
            let index = getLabels.findIndex(obj => obj.value === key)
            if (index !== -1) {
              labelKey = getLabels[index].label
            } else {
              labelKey = key
            }
            let val = rowData[key];
            labelKey = labelKey
            if (val) {
              values = {
                label: labelKey,
                value: labelKey === "Escheated Dt" || labelKey === "Dt Of Last Contact" || labelKey === "Updated Date" ? moment(val).format("MM/DD/YYYY") : val
              }
              formFields.push(values);
              icon = false;
            }

          });
          const sorted = formFields.sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          await this.setState({
            rowData: sorted,
            isLoading: false
          })
        }
      }
    }

  }

  showRecordDetails() {
    return (
      <>
        <Loader loader={this.state.isLoading} progress={0} />
        {this.state.rowData && Object.keys(this.state.rowData).length > 0 ?
          <>
            <div className='row mx-3 mt-3 p-0 d-flex col-sm-11' style={{ justifyContent: "space-between" }}>
              <div className='ml-2' style={{ fontSize: 14 }}>Total <b style={{ fontSize: 15 }}>{this.state.totalRecordsCount}</b> properties found. </div>
              <div className='d-flex'>
                {/* <Button color="primary" outline
                  className='ml-auto mb-0 mr-0 form_exportbtn'
                  size={'sm'} onClick={this.getDataToExport}>
                  <FontAwesomeIcon
                    icon='download'
                    data-toggle="tool-tip" title={"Export To CSV"}
                    className='prdx -1' size='lg' />
                </Button>

                <CSVLink
                  data={this.state.exportData}
                  // style={{ textDecoration: "wrap" }}
                  headers={this.state.exportHeaders}
                  filename={`${this.props.type}.csv`}
                  className="hidden text-gray py-0 px-1"
                  ref={(r) => this.csvLinkRef = r}
                  target="_blank" >
                </CSVLink> */}
                <span className=' mr-2'><FontAwesomeIcon icon={faAngleDoubleLeft}
                  className='pl-1'
                  color={this.state.page !== 1 ? 'red' : 'lightgrey'}
                  data-toggle="tool-tip"
                  title="Click to See Previous Record"
                  onClick={() => this.getCANData()}
                  style={{ width: 25, height: 25 }}
                  disabled={this.state.page === 1 ? true : false}
                  style={this.state.page !== 1 ? { width: 25, height: 25, cursor: "pointer", pointerEvents: "visible" } : { width: 25, height: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}

                /></span>
                <span className=' mt-1' style={{ width: "100%" }}>Showing Result <b>{this.state.page ? this.state.page : 0}</b> </span>
                <span className=' ml-2'><FontAwesomeIcon icon={faAngleDoubleRight}
                  className='pl-1'
                  color={this.state.page !== this.state.totalRecordsCount ? 'red' : 'lightgrey'}
                  data-toggle="tool-tip"
                  title="Click to See Next Record"
                  disabled={this.state.page === this.state.totalRecordsCount ? true : false}
                  onClick={() => this.getCANData("increment", "hitgo")}
                  style={{ width: 25, height: 25 }}
                  style={this.state.page !== this.state.totalRecordsCount ? { width: 25, height: 25, cursor: "pointer", pointerEvents: "visible" } : { width: 25, height: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}

                /></span>

              </div>

            </div>
            <div className="row form mt-2 mx-0 col-sm-12"
              style={{ border: "2px solid lightgrey" }}
            >
              {this.state.rowData.map((item, i) => {
                return (
                  item.label !== 'Sno' ?
                    <div className="col-sm-6 col-6" key={i}>
                      <div className="row" style={{ margin: "auto" }}>
                        <div
                          className="col-3 paddingRowDataCol"
                        >
                          <span className='fontWeight'>
                            {item.label}
                          </span>
                        </div>

                        <div className="col-8 paddingOfRowData"
                        >
                          {item.label === "Parent Company" ?
                            <a href="#" style={{ color: '#0e4768', cursor: 'pointer' }} className="pl-0" onClick={() => this.getchildCompanyDetails(this.state.userData, '', 'Investor', 'ParentCompanyID')}>{item.value}</a> :
                            item.label === "Website" ?
                              <a style={{ color: '#0e4768', cursor: 'pointer' }} className="pl-0" target="_blank" href={`http://${item.value}`}>{item.value}</a>
                              : <span>{item.value}</span>
                          }
                        </div>
                      </div>
                    </div> : null
                );
              })}
            </div>
          </>
          : this.state.totalRecordsCount === 0 ? <span style={{ fontSize: 12, color: "red" }}>No Records Found</span> : null}

      </>
    )
  }
  submitValues = (values) => {
    let Object = {
      // selectedSeller: {},
      selectedName: this.state.selectedNamearr ? this.state.selectedNamearr : "",
      selectedStatus: this.state.selectedStatus,
      selectedAddress: this.state.selectedAddress,
      selectedState: this.state.selectedState,
      selectedBucket: this.state.selectedBucket,
      // selectedProperty_Id: this.state.Property_Id,
    }
    if (this.state.activeTab === "Confirm On Site") {
      let data = {
        EstateName: this.state.selectedSeller.EstateName,
        EstateID: this.state.selectedSeller.EstateID
      }

      Object.selectedSeller = data
    }
    if (this.state.coutntValue) {
      // let propertyId = this.state.serverCount.replaceAll("\n", ",");
      let propertyId = this.state.serverCount.map(Number);

      Object.coutntValue = propertyId
    }
    if (this.state.selectedState) {
      Object.selectedState = this.state.selectedState
    }

    this.props.sendUpPropConfirmation(Object)
  }
  showRecordsCount = (type, field) => {
    let newNameIssueCodes = this.props.IssueCodes.newNameIssueCodes
    let nameIssueCodes = this.props.IssueCodes.nameIssueCodes
    if (type === "show" && field === "nameIssueCodes" && nameIssueCodes && nameIssueCodes.length === 0) {
      this.setState({
        isShownameIssueCodes: true
      })
    } else if (type === "hide" && field === "nameIssueCodes") {
      this.setState({
        isShownameIssueCodes: false
      })
    } else if (type === "show" && field === "newNameIssueCodes" && newNameIssueCodes.length === 0) {
      this.setState({
        isShownewNameIssueCodes: true
      })
    } else if (type === "hide" && field === "newNameIssueCodes") {
      this.setState({
        isShownewNameIssueCodes: false
      })
    }
  }
  submit = (values) => {
    if (this.state.selectedAnalyst && this.props.type === "ClaimsManagement") {
      let filtercriteria = this.state.filterCriteria
      if (filtercriteria["criteria"].length > 0) {
        let index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst" && obj.type === "in")
        if (index === -1)
          this.handleChange(this.state.selectedAnalyst, "Analyst")
      } else {
        this.handleChange(this.state.selectedAnalyst, "Analyst")
      }
    }
    if (!this.state.nameIssueCode && this.props.IssueCodes && this.props.IssueCodes.defaultIssueNames) {
      this.handleChange(this.props.IssueCodes.defaultIssueNames, "nameIssueCode")
    }
    const str = this.state.OpraStId ? this.state.OpraStId : null;
    var result = str ? str.split(/\r?\n/) : null;
    const str2 = this.state.StatePropertyID ? this.state.StatePropertyID : null;
    var result2 = str2 ? str2.split(/\r?\n/) : null;
    const opraClaimIdsstr = this.state.OpraClaimId ? this.state.OpraClaimId : null;
    var OpraclaimIdsresult = opraClaimIdsstr ? opraClaimIdsstr.split(/\r?\n/) : null;
    const opraStClaimIdsstr = this.state.stClaimId ? this.state.stClaimId : null;
    var OprastclaimIdsresult = opraStClaimIdsstr ? opraStClaimIdsstr.split(/\r?\n/) : null;
    const str1 = this.state.Property_Id ? this.state.Property_Id : null;
    var result1 = str1 ? str1.split(/\r?\n/) : null;
    let Object = {
      // selectedSeller: {},
      selectedName: this.state.selectedNamearr ? this.state.selectedNamearr : "",
      selectedStatus: this.state.selectedStatus,
      selectedAddress: this.state.selectedAddress,
      selectedState: this.state.selectedState,
      selectedBucket: this.state.selectedBucket,
      // selectedProperty_Id: this.state.Property_Id,
      selectedProperty_Id: result1 ? result1 : null,
      // selectedOpraStId: this.state.OpraStId
      selectedOpraStId: result ? result : null,
      setectedStOpraId: result2 ? result2 : null,
      nameIssueCode: this.state.isDeleteNameIssue ? "delete" : this.state.nameIssueCode
    }
    this.setState({ setGoButton: true, isredirtRanges: false })
    if (this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies") {
      let data = {
        EstateName: this.state.selectedSeller.EntityName,
        EstateID: this.state.selectedSeller.DealID
      }
      Object.selectedSeller = data
    }
    else if (this.props.type === "Claims" || this.props.type === "ClaimsManagement") {
      let data = {
        EstateID: this.state.EstateID,
        selectedOpraStClaimId: OprastclaimIdsresult ? OprastclaimIdsresult : null,
        EstateName: this.state.selectedSeller ? this.state.selectedSeller.EstateName : null,
        selectedOpraClaimIds: OpraclaimIdsresult ? OpraclaimIdsresult : null,
        selectedOpraStId: result ? result : null,
        setectedStOpraId: result2 ? result2 : null
      }
      if (this.state.ClaimName && this.state.ClaimName.nameID) {
        data.BusinessNameID = this.state.ClaimName.nameID
      }
      Object.selectedClaims = data
    }
    else if (this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
      let data = {
        EstateID: this.state.selectedSeller.CompanyID
      }
      Object.selectedSeller = data
    }
    else {
      Object.selectedSeller = this.state.selectedSeller
    }
    if (this.state.isexcludeProperties) {
      Object.isexcludeProperties = true
    }
    if (this.state.isexcludePossiblyPaid) {
      Object.isexcludePossiblyPaid = true
    }
    if (this.state.isexcludeRoyalties) {
      Object.isexcludeRoyalties = true
    }
    if (this.state.excludeCoOwners) {
      Object.excludeCoOwners = true
    }
    if (this.state.excludeNotConfirmedOnSite) {
      Object.excludeNotConfirmedOnSite = true
    }
    if (this.state.isexcludeValidated) {
      Object.isexcludeValidated = true
    }
    if (this.state.isValidate) {
      Object.isValidate = true
    }

    this.props.getFilteredValues(Object, this.state.filterCriteria, this.state.isChangeHit, "setGoButton", this.props.type === "ClaimsManagement" ? this.state.filteredclaimSuggestions : this.state.filterednameSuggestions)
  }
  handlePEFirmChange = async (e, type, key) => {
    let index;
    let filtercriteria = this.props.filterCriteria
    if (type === "pefirm") {
      await this.setState({
        selectedSeller: e.value,
        // EstateID: e.value.EstateID,
        isChangeHit: true
      })
    }
    index = filtercriteria["criteria"].findIndex(obj => obj.key === key && obj.type === type)
    if (index !== -1) {
      filtercriteria["criteria"].splice(index, 1)
    }
    this.setGoButton()
    await this.setState({
      filterCriteria: filtercriteria
    })
  }
  showCOunt = async (e, type) => {
    var val = JSON.stringify(e.target.value)
    val = val.replace(/\\n/g, '-')
    val = val.replace(/\,/g, '-')
    val = val.replace(/\"/g, '')
    let count = val.split("-")
    // let count = val.split("-").length
    // if (count2[count - 1].length === 0) {
    //   count2.pop()
    // }
    count = count.filter(u => u != "")


    await this.setState({ coutntValue: e.target.value, propCount: count.length, serverCount: count })
  }
  byCity = async () => {
    await this.setState({
      byCity: this.state.byCity === true ? false : true
    })
  }
  handleChange = async (e, type, key) => {
    await this.setState({
      isDeleteNameIssue: false
    })
    let index;
    let filtercriteria = this.props.filterCriteria
    if (type === "Seller") {
      await this.setState({
        selectedSeller: e.value,
        EstateID: e.value.EstateID,
        selectedName: "",
        selectedStatus: "",
        selectedAddress: "",
        searchTermName: "",
        selectedState: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'DC', 'WA', 'WV', 'WI', 'WY'],
        selectedNamearr: [],
        selectedNameNumberArray: [],
        rowData: {}, totalRecordsCount: null,
        selectedSearchTerm: "",
        addedProperties: [],
        StatePropertyIdArray: [],
        statePropertyIdRight: false,
        showSearchTable: false,
        errorResponse: false,
        errorMessage: '',
        setGoButtonClaims: false,
        isChangeHit: true
      })
      if (this.props.type === "ClaimsManagement" && this.state.EstateID) {
        this.searchClaimNamesItems()
      }
      this.props.getErrorMessage("Please Select Seller", "selectedSeller")
      if (this.props.type === "Properties") {
        await this.setState({
          selectedBucket: "Overall Near Exact"
        })
      }
      this.searchnameItems()
      this.setGoButton()
      filtercriteria["criteria"] = []
      this.props.change('Name', '')
    } if (type === "ClaimName") {
      await this.setState({
        ClaimName: e.value,
        selectedName: "",
        selectedStatus: "",
        selectedAddress: "",
        selectedState: "",
        isChangeHit: true
      })
      // this.props.getErrorMessage("Please Select claimID", "claimID")
      // this.searchnameItems()
      this.setGoButton()
      // filtercriteria["criteria"] = []
      this.props.change('Name', '')
    }
    else if (type === "Name") {
      let selectedNamearr = []
      if (e && e.target && e.target.value) {
        for (var arrName of e.target.value)
          selectedNamearr.push(arrName.id)
      } else {
        for (var arr of e)
          selectedNamearr.push(arr.id)
      }
      // var stringArray = selectedNamearr
      // var numberArray = stringArray.map(Number);
      this.setGoButton()
      await this.setState({
        // selectedName: e.value
        selectedName: e && e.target && e.target.value ? e.target.value : e,
        selectedNamearr: selectedNamearr,
        selectedNameNumberArray: selectedNamearr,
        addedProperties: [],
        StatePropertyIdArray: [],
        statePropertyIdRight: false,

      })
    } else if (type === "searchTermName") {
      await this.setState({
        searchTermName: e.value,
        searchtermId: e.value.id,
        selectedSearchTerm: "",
        showSearchTable: false,
        // statePropertyIdRight: false,
        // StatePropertyIdArray: [],
        // addedProperties: [],
        errorResponse: false, errorMessage: ''

      })
    } else if (type === "Status") {
      await this.setState({
        selectedStatus: e.value
      })
      this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "matchMatchStatusId")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      filtercriteria["criteria"].push({
        key: "matchMatchStatusId",
        value: this.state.selectedStatus,
        type: "in",
        add_Filter: true
      })
    } else if (type === "newNameIssueCode") {
      await this.setState({
        newNameIssueCode: e.value
      })
      // this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "newNameIssueCode")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      filtercriteria["criteria"].push({
        key: "newNameIssueCode",
        value: this.state.newNameIssueCode,
        type: "in",
        add_Filter: true
      })
    } else if (type === "nameIssueCode") {
      await this.setState({
        nameIssueCode: e.value ? e.value : e
      })
      // this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "nameIssueCode")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.nameIssueCode.length > 0) {
        filtercriteria["criteria"].push({
          key: "nameIssueCode",
          value: this.state.nameIssueCode,
          type: "in",
          add_Filter: true
        })
      }
    } else if (type === "MatchBucket") {
      await this.setState({
        selectedBucket: e.value
      })
      this.setGoButton()
    } else if (type === "Address") {
      await this.setState({
        selectedAddress: e.value
      })
      this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "matchingPropertyOwnerAddressLine1")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      filtercriteria["criteria"].push({
        key: "matchingPropertyOwnerAddressLine1",
        value: encodeURIComponent(this.state.selectedAddress),
        type: "eq",
        add_Filter: true
      })
      // this.submit()
    } else if (type === "selectedPropertyStatus") {
      await this.setState({
        selectedPropertyStatus: e.value
      })
      this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "MatchMatchStatus")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0)
        filtercriteria["criteria"].push({
          key: "MatchMatchStatus",
          value: this.state.selectedPropertyStatus,
          type: "in",
          add_Filter: true
        })
      // this.submit()
    } else if (type === "State") {

      if (e && e.value) {
        await this.setState({
          selectedState: e.value,
          selectedSearchTerm: "",
          showSearchTable: false,
          selectedaddressLine: "", selectedDate: "",
          selectedName: "",
          totalRecordsCount: null,
          rowData: {},
          errorResponse: false, errorMessage: ''

        })
      } else {
        await this.setState({
          selectedState: e,
          selectedSearchTerm: "",
          showSearchTable: false,

          errorResponse: false, errorMessage: ''
        })
      }
      if (!this.state.selectedSeller && this.state.selectedState.length !== 0) {
        this.props.getErrorMessage("Please Select Seller", "selectedSeller")
      }
      this.setGoButton()
      if (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement") {
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
      } else {
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "property_StateId" && obj.type === "in")
      }
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedState && this.state.selectedState.length > 0)
        filtercriteria["criteria"].push({
          key: this.props.type === "Property Editor" ? "SourceState" : this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? "SourceState" : "property_StateId",
          value: this.state.selectedState,
          type: "in",
          add_Filter: true
        })
      await this.setState({
        addedProperties: [],
        StatePropertyIdArray: [],
        statePropertyIdRight: false,
      })
      if (e && e.value && e.value === "CA-N") {
        this.props.clearOnsitemessage()
      }
    } else if (type === "StateGroup") {
      await this.setState({ searchTermsRecords: [] })
      if (e && e.value) {
        await this.setState({
          selectedStateGroup: e.value,
          showSearchTable: false,
        })
      } else {
        await this.setState({
          selectedStateGroup: e,
          showSearchTable: false,
        })
      }
      if (this.props.type === "SearchTerms") {
        await this.setState({
          // search
          selectedSearchTerm: "",
          showSearchTable: false,
          errorResponse: false,
          errorMessage: ''
        })

      }
    }
    else if (type === "Analyst") {
      if (e && e.value) {
        await this.setState({
          selectedAnalyst: e.value,
        })
      } else {
        await this.setState({
          selectedAnalyst: e,
          setGoButton: true
        })
      }
      if (this.state.selectedSeller !== "" && this.state.selectedState.length !== 0) {
        this.setGoButton()
      }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst" && obj.type === "in")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedAnalyst && this.state.selectedAnalyst.length > 0)
        filtercriteria["criteria"].push({
          key: "Analyst",
          value: this.state.selectedAnalyst,
          type: "in",
          add_Filter: true
        })      // this.submit()
    }
    else if (type === "ClaimStatus") {
      if (e && e.value) {
        await this.setState({
          selectedClaimStatus: e.value
        })
      } else {
        await this.setState({
          selectedClaimStatus: e
        })
      }
      this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus" && obj.type === "in")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedClaimStatus && this.state.selectedClaimStatus.length > 0)
        filtercriteria["criteria"].push({
          key: "ClaimStatus",
          value: this.state.selectedClaimStatus,
          type: "in",
          add_Filter: true
        })      // this.submit()

    }
    else if (type === "isexcludeProperties") {
      await this.setState({
        isexcludeProperties: this.state.isexcludeProperties === true ? false : true
      })
      this.setGoButton()
    } else if (type === "isexcludeValidated") {
      await this.setState({
        isexcludeValidated: this.state.isexcludeValidated === true ? false : true
      })
      this.setGoButton()
    } else if (type === "Validate") {
      await this.setState({
        isValidate: this.state.isValidate === true ? false : true
      })
      this.setGoButton()
    } else if (type === "excludeCoOwners") {
      await this.setState({
        excludeCoOwners: this.state.excludeCoOwners === true ? false : true
      })
      this.setGoButton()
    }
    else if (type === "excludeNotConfirmedOnSite") {
      await this.setState({
        excludeNotConfirmedOnSite: this.state.excludeNotConfirmedOnSite === true ? false : true
      })
      this.setGoButton()
    }
    else if (type === "isexcludePossiblyPaid") {
      await this.setState({
        isexcludePossiblyPaid: this.state.isexcludePossiblyPaid === true ? false : true
      })
      this.setGoButton()
    } else if (type === "isexcludeRoyalties") {
      await this.setState({
        isexcludeRoyalties: this.state.isexcludeRoyalties === true ? false : true
      })
      this.setGoButton()
    }
    else if (type === "Property_Id") {
      await this.setState({
        Property_Id: e && e.target && e.target.value ? e.target.value : null
      })
      this.setGoButton()
    } else if (type === "OpraStId") {
      await this.setState({
        OpraStId: e && e.target && e.target.value ? e.target.value : null
      })
      this.setGoButton()
    } else if (type === "StatePropertyID") {
      await this.setState({
        StatePropertyID: e && e.target && e.target.value ? e.target.value : null
      })
      this.setGoButton()
    } else if (type === "OpraClaimId") {
      await this.setState({
        OpraClaimId: e && e.target && e.target.value ? e.target.value : null
      })
      this.setGoButton()
    } else if (type === "stClaimId") {
      await this.setState({
        stClaimId: e && e.target && e.target.value ? e.target.value : null
      })
      this.setGoButton()
    } else if (type === "Delete") {
      if (e === "selectedSeller") {
        await this.setState({
          selectedName: "",
          selectedStatus: "",
          selectedAddress: "",
          selectedNamearr: "",
          selectedState: [],
          selectedState: "",
          selectedSeller: "",
          modifiedAddress: [],
          setGoButtonClaims: true,
          selectedBucket: "",
          selectedSeller: "",
          EstateID: "",
          stClaimId: "",
          selectedPropertyStatus: "",
          OpraClaimId: "",
          StatePropertyID: "",
          ClaimName: "",
          OpraStId: "",
          Property_Id: "",
          isexcludeRoyalties: false,
          isexcludePossiblyPaid: false,
          isexcludeRoyalties: true,
          excludeCoOwners: false,
          isexcludeProperties: false,
          excludeNotConfirmedOnSite: false,
          excludeCoOwners: false,
          selectedClaimStatus: "",
          newNameIssueCode: "",
          nameIssueCode: "",
        })
        filtercriteria["criteria"] = []
        // this.submit()
        if (this.props.type === "ClaimsManagement") {
          this.props.getErrorMessage("Please Select Seller or State", "removingSeller", "error")
        } else {
          this.props.getErrorMessage("Please Select Seller", "removingSeller", "error")
        }
        this.props.getFileOptions([])
      } else if (e === "selectedName") {
        await this.setState({
          selectedName: "",
          selectedNamearr: "",
        })
      } else if (e === "ClaimName") {
        await this.setState({
          ClaimName: "",
        })
      } else {
        await this.setState({
          [e]: "",
        })
        if (e === "nameIssueCode") {
          await this.setState({
            nameIssueCode: [],
            isDeleteNameIssue: true
          })
        }
        if (!this.state.selectedSeller && this.state.selectedState.length === 0 && this.props.type === "ClaimsManagement") {
          this.props.getErrorMessage("Please Select Seller or State", "removingSeller", "error")
        }
        let type;
        if (e === "OpraClaimId" || e === "selectedState" || e === "selectedStatus" || e === "StatePropertyID" || e === "selectedAnalyst" || e === "selectedClaimStatus" || e === "newNameIssueCode" || e === "nameIssueCode") {
          type = "in"
        } else {
          type = "eq"
        }
        if (this.props.type === "Property Editor" && key === "property_StateId") {
          let kindex = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState" && obj.type === "in")
          if (kindex !== -1) {
            filtercriteria["criteria"].splice(kindex, 1)
          }
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
        } else if (key === "ClaimStatus") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus")
        } else if (this.props.type === "Deal Sizing-Properties" || this.props.type === "ClaimsManagement" && key === "property_StateId") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
        } else if (key === "newNameIssueCode") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "newNameIssueCode")
        } else if (key === "nameIssueCode") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "nameIssueCode")
        } else if (key === "selectedPropertyStatus") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "MatchMatchStatus")
        } else if (key === "Analyst") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst")
        } else {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === key && obj.type === type)
        }
        if (index !== -1) {
          filtercriteria["criteria"].splice(index, 1)
        }
      }

      this.setGoButton()
    }
    await this.setState({
      isChangeHit: true,
      filterCriteria: filtercriteria
    })
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.submit}
      />
    )
  }
  CompanyItems = async (event, criteria) => {
    let apiUrl
    let filterCriteria = this.state.filterCriteria
    filterCriteria["criteria"] = [{
      key: "CompanyName",
      value: event && event.query ? event.query : "",
      type: "sw",
      add_Filter: true
    }]
    // filterCriteria.globalSearch = { value: event && event.query ? event.query : "", "type": "user" }
    filterCriteria.sortfield = "CompanyName"
    filterCriteria.direction = "asc"
    if (event) {
      apiUrl = `${apiCalls.companiesdropdown}?filter=${JSON.stringify(filterCriteria)}`
    } else {
      apiUrl = `${apiCalls.companiesdropdown}?filter=${JSON.stringify(filterCriteria)}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["companies"] && response["companies"].length && response["companies"].length > 0) {
            dropdownData = response["companies"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)

          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownData;
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            // this.searchnameItems(criteria)
          }

        }
      }).catch((err) => {
        return err;
      });
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl
    let filterCriteria = this.state.filterCriteria
    if (event) {
      if (this.props.type !== "Deal Sizing-Properties" && this.props.type !== "OPRA2 Property Editor" && this.props.type !== "Deal Sizing Report For Companies") {
        apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query ? event.query : event)}`
      } else {
        filterCriteria["criteria"] = [{
          key: "EntityName",
          value: event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : "",
          type: "regexOr"
        }]
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "EntityName")
        if (this.props.type === "Deal Sizing Report For Companies") {
          filterCriteria.sortfield = "EntityName"
          filterCriteria.page = "1"
        }
        if (filterCriteria["criteria"][index].value.length > 0) {
          apiUrl = `${apiCalls.Seller}?filter=${JSON.stringify(filterCriteria)}`
        } else {
          apiUrl = `${apiCalls.Seller}`
        }
      }
    } else {
      if (this.props.type !== "Deal Sizing-Properties" && this.props.type !== "OPRA2 Property Editor" && event) {
        apiUrl = `${apiCalls.propertysellers}?str = ${encodeURIComponent(event.query ? event.query : event)} `
      } else {
        apiUrl = `${apiCalls.Seller} `
      }
    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })

            this.searchnameItems(criteria)
          }
          if (this.props.type === "ClaimsManagement") {
            this.searchClaimNamesItems()
          }

        }
      }).catch((err) => {
        return err;
      });
  }

  searchClaimNamesItems = async (event, criteria) => {
    let apiUrl

    // businessNames?searchCompany=435&str="erwin"  
    if (this.state.EstateID) {
      apiUrl = `${apiCalls.Claims}/businessNames?searchCompany=${this.state.EstateID}&str=${event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : ""} `
    }
    if (apiUrl) {
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let dropdownData = [];
            if (response["sellernames"] && response["sellernames"].length && response["sellernames"].length > 0) {
              dropdownData = response["sellernames"]
            } else if (response && response.errorMessage) {
              this.props.getErrorResponseFromServer(response)
            }
            if (dropdownData && dropdownData.length === 0) {
              this.setState({
                filteredclaimSuggestions: [],
                noData: true
              });
            } else {
              dropdownData = dropdownData;
            }
            await this.setState({
              filteredclaimSuggestions: dropdownData
            });
            if (criteria) {
              await this.setState({
                ClaimName: dropdownData[0]
              })
              this.props.getFileOptions(dropdownData)
              // this.searchnameItems(criteria)
            }
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  searchnameItems = async (event) => {
    let apiUrl
    if (this.state.activeTab !== "Search Terms") {
      if (this.state.selectedSeller && this.state.selectedSeller.EstateID && (this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "SearchTerms" || this.props.type === "searchTermOnSite")) {
        apiUrl = `${apiCalls.propertynames}?searchCompany=${this.state.selectedSeller.EstateID} `
      } else if (this.state.selectedSeller && this.state.selectedSeller.DealID && (this.props.type === "Deal Sizing-Properties")) {
        apiUrl = `${apiCalls.dealSizingNames}?searchCompany=${this.state.selectedSeller.DealID} `
      }
    } else {
      if (this.state.selectedSeller && this.state.selectedSeller.EstateID) {
        apiUrl = `businessnames/filefor?estateId=${this.state.selectedSeller.EstateID}`
      }
    }
    if (apiUrl) {
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let namedropdownData = [];
            if (response["sellernames"] && response["sellernames"].length && response["sellernames"].length > 0) {
              namedropdownData = response["sellernames"]

            } else if (response && response.errorMessage) {
              this.props.getErrorResponseFromServer(response)

            }
            if (namedropdownData && namedropdownData.length === 0) {
              this.setState({
                filterednameSuggestions: [],
                noData: true
              });
            } else {
              let modifiedLabels = []
              for (let name of namedropdownData) {
                if (name.companyName !== null || name.companyName.length > 0 || name.nameID !== null || name.nameID.length > 0) {
                  modifiedLabels.push({
                    label: name.companyName,
                    value: {
                      labelName: name.companyName,
                      id: name.nameID,
                    },
                  })
                }
              }
              namedropdownData = modifiedLabels;
            }
            if (this.state.taskProperties) {
              let data = this.state.taskProperties.PropertiesSellerName.Names // for (var name of this.state.taskProperties.PropertiesSellerName.Names) {
              var newArray = [];
              for (var item of data) {
                for (var name of namedropdownData) {
                  if (name.label === item) {
                    newArray.push(name)
                  }
                }
              }
              let ids = []
              for (var itemData of newArray) {
                ids.push(itemData.value)
              }
              this.handleChange(ids, "Name")
            }
            if (event && this.state.isSharing && this.state.sharedobj.nameId) {
              let selectedValues = []
              const arr = event.nameId.split(',');
              for (var itemArr of arr) {
                for (var named of namedropdownData) {
                  if (named.value && named.value.id === itemArr) {
                    selectedValues.push(named.value)
                  }
                }
              }
              this.handleChange(selectedValues, "Name")
            }
            this.props.getFileOptions(namedropdownData)
            await this.setState({
              filterednameSuggestions: namedropdownData
            });
          }
        }).catch((err) => {
          return err;
        });
    }
  }
  handleCheck = async (dealType) => {

    await this.setState({
      dealType: dealType
    })
  }
  onEditorValueChange = async (props, value) => {
    let updatedProperties = [...props.value];
    updatedProperties[props.rowIndex]["isInclude"] = value.target.value;
    updatedProperties[props.rowIndex]["isedited"] = "yes";

    if (this.state.addedProperties && this.state.addedProperties.length > 0) {
      await this.setState({ addedProperties: updatedProperties });
    } else {
      await this.setState({ StatePropertyIdArray: updatedProperties });

    }
  }

  onEditorValueChangeName = async (props, value, item) => {
    if (props) {
      let allUsersData = [...props.value];
      allUsersData[props.rowIndex]["propertyName"] = value ? value : ""
      allUsersData[props.rowIndex]["isedited"] = "yes"
      await this.setState({ addedProperties: allUsersData })

    }
  }

  brandEditor = (props) => {
    return (
      <div className='row mt-2 float-right'>
        <div className="field-radiobutton" style={{ marginRight: "150px" }}>
          {/* <RadioButton inputId="isInclude" className="ml-2 mr-2" style={{ width: 15 }} name='isInclude' label="Yes" value="Yes" onChange={(e) => this.onEditorValueChange(props, e)}
            defaultChecked={props && props.rowData && props.rowData.isInclude && (props.rowData.isInclude === "Yes" || props.rowData.isInclude === null) ? true : false}
            checked={props && props.rowData && props.rowData.isInclude && (props.rowData.isInclude === "Yes" || props.rowData.isInclude === null) ? true : false}
          /> */}
          <input type="radio" id="html" name="isInclude" value="Yes" onChange={(e) => this.onEditorValueChange(props, e)}
            defaultChecked={props && props.rowData && props.rowData.isInclude && (props.rowData.isInclude === "Yes" || props.rowData.isInclude === null) ? true : false}
            checked={props && props.rowData && props.rowData.isInclude && (props.rowData.isInclude === "Yes" || props.rowData.isInclude === null) ? true : false}
          />
          <label htmlFor="city1" className='mb-0'>Yes</label>
        </div>
        <div className="field-radiobutton" style={{ marginRight: "39px" }}>
          {/* <RadioButton inputId="isInclude" className="ml-2 mr-2" style={{ width: 15 }} name='isInclude' label="No" value="No" onChange={(e) => this.onEditorValueChange(props, e)}
            defaultChecked={props && props.rowData && props.rowData.isInclude && (props.rowData.isInclude === "No" || props.rowData.isInclude === null) ? true : false}
            checked={props && props.rowData && props.rowData.isInclude && (props.rowData.isInclude === "No" || props.rowData.isInclude === null) ? true : false}
          /> */}
          <input type="radio" id="css" name="isInclude" value="No" onChange={(e) => this.onEditorValueChange(props, e)}
            defaultChecked={props && props.rowData && props.rowData.isInclude && (props.rowData.isInclude === "No" || props.rowData.isInclude === null) ? true : false}
            checked={props && props.rowData && props.rowData.isInclude && (props.rowData.isInclude === "No" || props.rowData.isInclude === null) ? true : false} />

          <label htmlFor="city2" className='mb-0'>No</label>
        </div>
      </div>
    );
  }


  render() {
    const { handleSubmit, settingsData, IssueCodes } = this.props;
    const { modifiedStates, modifiedStatus, matchBuckets } = this.state
    return (
      // <Accordion multiple={true} activeIndex={[0]}>
      //   <AccordionTab header="Filters" >
      this.props.type === "searchTermOnSite" ?
        <div>



          <div>


            <Nav tabs >
              {
                this.state.tabOptions && this.state.tabOptions.length > 0 ? this.state.tabOptions.map((item, index) => {
                  return (
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === item })}
                        onClick={() => { this.getSelectedTabTypeInfo(item); }}
                      >
                        <h4>{item}</h4>

                      </NavLink>
                    </NavItem>
                  )
                }) : null
              }

            </Nav>
            <Loader loader={this.state.isLoading} />
            <form className="form m-2" onSubmit={handleSubmit(this.submit)}>
              {this.state.activeTab === "Confirm On Site" ? <div style={{ display: 'block', border: "2px solid lightgrey" }} >
                <form className="form m-2" onSubmit={handleSubmit(this.submit)}>
                  <div className='row m-0 p-0 d-flex col-sm-12' style={{ width: "100%" }}>
                    <div className='col-sm-6 co-lg-6'>

                      <div className='row m-0 p-0 d-flex col-sm-12' >
                        <div className="pl-0 col-sm-8 col-lg-8">
                          <div className={"pb-2"} >
                            <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>{"Deal"}{<div style={{ width: "87%" }}>
                              <span >
                                <FontAwesomeIcon icon={faStarOfLife}
                                  className='pl-1'
                                  color='red'
                                  data-toggle="tool-tip"
                                  title="Mandotary Field"
                                  style={{ width: 11 }}
                                /></span>
                              {/* <span>
                            <FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2'
                              color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              title="Clear Filter"
                              onClick={() => this.handleChange('selectedSeller', "Delete")}
                              style={this.state.selectedSeller ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            /></span> */}

                            </div>}</label>
                            <div >
                              <AutoComplete
                                value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller&&this.state.selectedSeller.DealYear?this.state.selectedSeller.DealYear:""})` : this.state.selectedSeller}
                                // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                                suggestions={this.state.filteredSuggestions}
                                completeMethod={this.searchsellerItems}
                                minLength={1}
                                itemTemplate={this.editorTemplateForDeal}
                                field={this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies" ? "EntityName" : "EstateName"}
                                style={{ width: "100%" }}
                                dropdown={true}
                                onChange={(e) => this.handleChange(e, 'Seller')}
                                appendTo={document.body} />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 col-lg-4 pl-0">
                          {/* <div style={this.props.type === "ClaimsManagement" ? { width: "18%" } : this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "18%", marginLeft: 30 } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { width: "13%", marginLeft: 3 } : this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "30%", marginLeft: 10 }}> */}
                          <div className=" pb-1">
                            <label style={{ marginBottom: 4, width: "100%" }}>State
                              <span >
                                <FontAwesomeIcon icon={faStarOfLife}
                                  className='pl-1'
                                  color='red'
                                  data-toggle="tool-tip"
                                  title="Mandotary Field"
                                  style={{ width: 11 }}
                                /></span>
                              {/* {<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2'
                              color={this.state.selectedState ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              onClick={() => this.handleChange("selectedState", "Delete", 'property_StateId')}
                              title="Clear Filter"
                              style={this.state.selectedState ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />} */}

                            </label>
                            <div className=''>
                              {/* <MultiSelect
                              name={"State"}
                              filter={true}
                              value={this.state.selectedState}
                              style={{ width: "100%", height: 32 }}
                              appendTo={document.body}
                              disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                              maxSelectedLabels={1}
                              options={modifiedStates}
                              onChange={(e) => this.handleChange(e, "State")}

                            /> */}
                              <Dropdown className='State'
                                type={'text'}
                                // filter={modifiedStates.length > 6 ? true : false}
                                value={this.state.selectedState}
                                placeholder={""}
                                name="State"
                                style={{
                                  width: "100%",
                                  height: 32
                                }}
                                // disabled={this.state.selectedSeller ? false : true}
                                options={this.state.upmodifiedState}
                                onChange={(e) => this.handleChange(e, "State")}
                              />
                            </div></div></div>
                      </div>
                      <div className='row m-0 p-0 d-flex col-sm-12' >

                        {/* </div> */}
                      </div>
                      {this.state.activeTab === "Confirm On Site" && this.state.selectedState !== "CA-N" ?
                        <div className='row m-0 p-0 d-flex col-sm-12' >

                          <div className="">
                            {/* <div style={this.props.type === "ClaimsManagement" ? { width: "18%" } : this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "18%", marginLeft: 30 } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { width: "13%", marginLeft: 3 } : this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "30%", marginLeft: 10 }}> */}
                            <label style={{ marginBottom: 4, width: "100%" }}>State Property ID
                              {/* {<FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          color={this.state.OpraStId ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          title="Clear Filter"
                          onClick={() => this.handleChange("OpraStId", "Delete", 'matchMatchStatusId')}
                          style={this.state.OpraStId ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        />} */}
                            </label>
                            <div className='f'>
                              <textarea
                                // type="search"
                                name='OpraStId'
                                // id='estateIdsValue'
                                value={this.state.coutntValue}
                                // onChange={(e) => this.handleChange(e, "OpraStId")}
                                onChange={(e) => this.showCOunt(e, "OpraStId")}
                                placeholder={'State Property ID'}
                                // size="10"
                                // height="100px"
                                cols="100"
                                rows="8"
                              />
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                        : null}
                      {this.state.activeTab === "Confirm On Site" &&
                        this.state.selectedState !== "CA-N" ? <div className='row m-0 p-0 d-flex col-sm-12' >
                        <label style={{ marginBottom: 4, width: "100%" }}>Count : {this.state.propCount ? this.state.propCount : 0}</label>
                      </div>

                        : null}

                    </div>
                    {this.state.selectedState !== "CA-N" ?

                      <div class="col-sm-12 col-lg-12 d-flex align-items-end">
                        <div className='col-sm-1 col-lg-3 d-flex align-items-end'>
                          <Button color="primary"
                            outline
                            className="mb-0"
                            style={(this.state.selectedSeller && this.state.selectedState) ? { color: "white", backgroundColor: '#354f6e' } : { color: "white", backgroundColor: '#a0a2a5' }}
                            disabled={(this.state.selectedSeller && this.state.selectedState) ? false : true}
                            // disabled={false}
                            onClick={(e) => this.submitValues()}>
                            {" Verify in OPRA & Update in OPRA2 "}
                          </Button>
                        </div>
                        <div className='col-sm-1 col-lg-2 d-flex align-items-end'>
                          <Button color="primary"
                            outline
                            className="mb-0"
                            style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                            // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                            disabled={false}
                            onClick={(e) => this.ClearValues()}>
                            Clear
                          </Button>
                        </div>
                      </div> : null
                    }
                    {this.state.selectedState === "CA-N" ? <div className="col-sm-12 d-flex">
                      <div className="col-sm-3 pl-0">
                        <label style={{ marginBottom: 4, width: "100%" }}>Name
                          <span >
                            <FontAwesomeIcon icon={faStarOfLife}
                              className='pl-1'
                              color='red'
                              data-toggle="tool-tip"
                              title="Mandotary Field"
                              style={{ width: 11 }}
                            /></span>
                        </label>
                        <div className='col-sm-12 pl-0'>
                          <InputText
                            value={this.state.selectedName}
                            onChange={(e) => this.setState({ selectedName: e.target.value })}
                          // monthNavigator={true} yearNavigator={true} yearRange="2010:2030"
                          />
                        </div>
                      </div>

                      <div className="col-sm-3 pl-0">
                        <label style={{ marginBottom: 4, width: "100%" }}>Date Reported
                        </label>
                        <div className="col-sm-12 pl-0">
                          <Calendar value={this.state.selectedDate} style={{ width: "100%" }} onChange={(e) => this.setState({ selectedDate: e.value })} monthNavigator={true} yearNavigator={true} yearRange="2010:2030" />
                        </div>
                      </div>

                      <div className="col-sm-3 pl-0">
                        <label style={{ marginBottom: 4, width: "100%" }}>Prop. Address Line1
                        </label>
                        <div className="col-sm-12 pl-0">
                          <InputText
                            value={this.state.selectedaddressLine}
                            onChange={(e) => this.setState({ selectedaddressLine: e.target.value })}
                          // monthNavigator={true} yearNavigator={true} yearRange="2010:2030"
                          />
                        </div>
                      </div>
                      <div className='d-flex align-items-end mt-3'>
                        <Button color="primary"
                          outline
                          className="mb-0"
                          // style={(this.state.selectedSeller && this.state.selectedState) ? { color: "white", backgroundColor: '#354f6e' } : { color: "white", backgroundColor: '#a0a2a5' }}
                          // disabled={(this.state.selectedSeller && this.state.selectedState) ? false : true}
                          // disabled={false}
                          onClick={(e) => this.getCANData("increment")}>
                          {" GO "}
                        </Button>
                      </div>
                    </div>
                      : null}
                    {this.state.selectedState === "CA-N" ? this.showRecordDetails() : null}
                  </div>
                </form >
                {this.state.SessionWarningModal ? this.getSessionWraningModal() : null}
                {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}
              </div > : this.state.activeTab === "Data Upload in OPRA" ?
                <div>
                  <a className=' btn btn-outline-primary p-1 ml-2 mr-2 mt-2 mb-0' href={config.opraMultiPropertyCreationUrl} target="blank">Add Multiple Property</a>

                </div>
                : <div className='row m-0 p-0 d-flex col-sm-12' style={{ width: "100%" }}>
                  {/* <div className='col-sm-12 co-lg-12'> */}

                  <div className="pl-0 col-sm-3 col-lg-3" style={this.props.type === "ClaimsManagement" ? { width: "32%" } : { width: "30%" }}>
                    <div className={"pb-2"} >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>{"Deal"}{<div style={{ width: "87%" }}>
                        <span >
                          <FontAwesomeIcon icon={faStarOfLife}
                            className='pl-1'
                            color='red'
                            data-toggle="tool-tip"
                            title="Mandotary Field"
                            style={{ width: 11 }}
                          /></span>
                        {/* <span>
                            <FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2'
                              color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              title="Clear Filter"
                              onClick={() => this.handleChange('selectedSeller', "Delete")}
                              style={this.state.selectedSeller ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            /></span> */}

                      </div>}</label>
                      <div >
                        <AutoComplete
                          itemTemplate={this.editorTemplateForDeal}
                          value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller&&this.state.selectedSeller.DealYear?this.state.selectedSeller.DealYear:""})` : this.state.selectedSeller}
                          // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                          suggestions={this.state.filteredSuggestions}
                          completeMethod={this.searchsellerItems}
                          minLength={1}
                          field={this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies" ? "EntityName" : "EstateName"}
                          style={{ width: "100%" }}
                          dropdown={true}
                          onChange={(e) => this.handleChange(e, 'Seller')}
                          appendTo={document.body} />
                      </div>
                    </div>
                  </div>


                  {this.state.activeTab === "Search Terms" ?
                    <div className="col-sm-1 col-lg-2 pl-0 mr-3">
                      {/* <div style={this.props.type === "ClaimsManagement" ? { width: "18%" } : this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "18%", marginLeft: 30 } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { width: "13%", marginLeft: 3 } : this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "30%", marginLeft: 10 }}> */}
                      <div className=" pb-1">
                        <label style={{ marginBottom: 4, width: "100%" }}>State Group
                          <span >
                            <FontAwesomeIcon icon={faStarOfLife}
                              className='pl-1'
                              color='red'
                              data-toggle="tool-tip"
                              title="Mandotary Field"
                              style={{ width: 11 }}
                            /></span>

                        </label>
                        <div className=''>
                          <Dropdown className='StateGroup'
                            type={'text'}
                            // filter={modifiedStates.length > 6 ? true : false}
                            value={this.state.selectedStateGroup}
                            placeholder={""}
                            name="StateGroup"
                            style={{
                              width: "100%",
                              height: 32
                            }}
                            // disabled={this.state.selectedSeller ? false : true}
                            options={this.state.kelmerGroups}
                            onChange={(e) => this.handleChange(e, "StateGroup")}
                          />
                        </div></div></div> : <div className="col-sm-1 col-lg-2 pl-0 mr-3">
                      {/* <div style={this.props.type === "ClaimsManagement" ? { width: "18%" } : this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "18%", marginLeft: 30 } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { width: "13%", marginLeft: 3 } : this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "30%", marginLeft: 10 }}> */}
                      <div className=" pb-1">
                        {/* <label style={{ marginBottom: 4, width: "100%" }}>State
                          <span >
                            <FontAwesomeIcon icon={faStarOfLife}
                              className='pl-1'
                              color='red'
                              data-toggle="tool-tip"
                              title="Mandotary Field"
                              style={{ width: 11 }}
                            /></span>

                        </label>
                        <div className=''>
                          <Dropdown className='State'
                            type={'text'}
                            // filter={modifiedStates.length > 6 ? true : false}
                            value={this.state.selectedState}
                            placeholder={""}
                            name="State"
                            style={{
                              width: "100%",
                              height: 32
                            }}
                            // disabled={this.state.selectedSeller ? false : true}
                            options={this.state.searchmodifiedState}
                            onChange={(e) => this.handleChange(e, "State")}
                          />
                        </div> */}
                        <label style={{ marginBottom: 4, width: "100%" }}>State  <span >
                          <FontAwesomeIcon icon={faStarOfLife}
                            className='pl-1'
                            color='red'
                            data-toggle="tool-tip"
                            title="Mandotary Field"
                            style={{ width: 11 }}
                          /></span></label>
                        <div className=''>
                          <MultiSelect
                            name={"State"}
                            filter={true}
                            value={this.state.selectedState}
                            style={{ width: "100%", height: 32 }}
                            appendTo={document.body}
                            // disabled={this.props.type === "ClaimsManagement" && !isEditted
                            //   ? false : this.state.selectedSeller && !isEditted ? false : true}
                            maxSelectedLabels={1}
                            options={this.state.searchmodifiedState}
                            onChange={(e) => this.handleChange(e, "State")}

                          />
                        </div>

                      </div></div>}


                  {/* </div> */}
                  {this.state.activeTab === "Existing Data" ?
                    <div className="pl-0 col-sm-2 col-lg-2" style={{ width: "32%" }}>
                      <label style={{ marginBottom: 4, width: "100%" }}>Business Name <span >
                        <FontAwesomeIcon icon={faStarOfLife}
                          className='pl-1'
                          color='red'
                          data-toggle="tool-tip"
                          title="Mandotary Field"
                          style={{ width: 11 }}
                        /></span></label>
                      <div >
                        <MultiSelect
                          className={"form__form-group-field "}
                          style={{ width: "100%", height: 32 }}
                          filter={true}
                          name={"Name"}
                          options={this.state.filterednameSuggestions}
                          value={this.state.selectedName && this.state.selectedName.CompanyName ? this.state.selectedName.CompanyName : this.state.selectedName}
                          onChange={(e) => this.handleChange(e, "Name")}
                          maxSelectedLabels={1}
                        />
                      </div>


                    </div> : <div className="pl-0 col-sm-2 col-lg-2" style={{ width: "32%" }}>
                      <label style={{ marginBottom: 4, width: "100%" }}> Name
                        <span >
                          <FontAwesomeIcon icon={faStarOfLife}
                            className='pl-1'
                            color='red'
                            data-toggle="tool-tip"
                            title="Mandotary Field"
                            style={{ width: 11 }}
                          /></span>
                      </label>
                      <div >

                        <Dropdown className={"form__form-group-field "}
                          type={'text'}
                          // filter={modifiedStates.length > 6 ? true : false}
                          value={this.state.searchTermName ? this.state.searchTermName : null}
                          // placeholder={""}
                          name="Name"
                          style={{
                            width: "100%",
                            height: 32
                          }}
                          // disabled={this.state.selectedSeller ? false : true}
                          options={this.state.filterednameSuggestions}
                          onChange={(e) => this.handleChange(e, "searchTermName")}
                        />
                      </div>


                    </div>}

                  <div style={this.state.activeTab === "Existing Data" ? { display: "grid" } : { display: "none" }}>
                    <label htmlFor="cb3  col-sm-2" className="p-checkbox-label ml-2" style={{ marginRight: "50px" }}>{"By City"}</label>
                    <Checkbox
                      label='byCity'
                      type='checkbox'
                      // component={renderCheckBoxField}
                      name={'byCity'}
                      className=" ml-2"
                      checked={this.state.byCity}
                      onChange={(e) => this.byCity(e)}
                    />
                  </div>
                  <div className="pl-0 mr-5 mt-4">
                    <Button color="primary"
                      outline
                      className="mb-0"
                      style={(this.state.EstateID && (this.state.activeTab === "Search Terms" ? this.state.selectedStateGroup : this.state.selectedState) && (this.state.activeTab === "Existing Data" ? this.state.selectedName : this.state.searchtermId)) ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
                      // // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                      disabled={(this.state.EstateID && (this.state.activeTab === "Search Terms" ? this.state.selectedStateGroup : this.state.selectedState) && (this.state.activeTab === "Existing Data" ? this.state.selectedName : this.state.searchtermId)) ? false : true}
                      onClick={(e) => this.getExistData(this.state.activeTab)}>
                      Go
                    </Button>

                  </div>
                  {/* {this.state.statePropertyIdRight && (this.state.roleName === config.roleNames.coreOpsManager || this.state.roleName === "Admin") && !this.state.isByCity && this.state.activeTab === "Existing Data" ?
                    <div style={{ display: "contents" }}>
                      <div className="pl-0" style={{ marginTop: "32px" }}>
                        <Button color="primary"
                          outline
                          className="mb-0"
                          onClick={(e) => this.AddProperty()}
                        >
                          Add
                        </Button>

                      </div>
                      <div className="pl-0 ml-2 mr-2" style={{ marginTop: "32px" }}>
                        <Button color="primary"
                          outline
                          className="mb-0"
                           onClick={(e) => this.savePropertyData()}
                        >
                          Save
                        </Button>

                      </div>
                      <div className="pl-0 " style={{ marginTop: "32px" }}>
                        <Button color="primary"
                          outline
                          className="mb-0"
                           onClick={(e) => this.clearData(this.state.activeTab)}
                        >
                          Clear
                        </Button>

                      </div>
                    </div> : null} */}

                  {/* </div> */}
                </div>}

            </form >
            {this.state.isByCity && this.state.activeTab === "Existing Data" ?
              <DataTable value={this.state.cities} scrollable={true} scrollHeight="1000px" frozenWidth={"300px"} frozenHeight={"67.17px"} resizableColumns={true} columnResizeMode="expand" style={{ marginTop: "14px", width: "1500px" }}
              >
                <Column field="propertyName" frozen={true} header="Property Name" bodyStyle={bodyStyle} headerStyle={headerStyleCity} body={this.hoverTemplate} />

                {this.getColumns()}

              </DataTable>
              : null}
            {this.state.StatePropertyIdArray && this.state.StatePropertyIdArray.length > 0 && !this.state.isByCity && this.state.activeTab === "Existing Data" ?
              // <div>
              <div className="col-sm-12 row" style={{ width: "100%" }}>
                <div className="col-sm-4 pl-0 pr-0" style={{ marginRight: "-51px" }}>
                  <DataTable value={this.state.StatePropertyIdArray} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ marginTop: "14px", width: "770px" }} onSort={(e) => this.handleDataChangingOption(e)}
                    globalFilter={this.state.globalFilter}
                    onFilter={this.isConfirmFilter}
                  >
                    <Column field="propertyName" header="Property Name" bodyStyle={bodyStyle} headerStyle={{
                      width: 460,
                      textAlign: "center",
                    }} body={this.hoverTemplate} sortable={true} filter={true} dataChangeOptions={this.state.dataChangeOptionswithFilter}
                      filterElement={
                        <select onChange={(e) => this.onSeletFilterType("propertyName")} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${"propertyName"}`} style={{ width: "100%" }} >
                          <option value="regexOr">Includes</option>
                          <option value="eq">Equals</option>
                          <option value="nregexOr">Excludes</option>

                        </select>} />
                    <Column field="statePropertyIdCount" header="Count of St. Prop. ID" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} sortable={true} body={this.hoverTemplate} headerStyle={{
                      width: 150,
                      textAlign: "center",
                    }} />
                    {/* <Column field="states" header="States" bodyStyle={bodyStyle} body={this.stateTemplate} headerStyle={{
                      width: 150,
                      textAlign: "center",
                    }} /> */}

                  </DataTable>
                </div>


                {/* {(this.state.roleName === config.roleNames.coreOpsManager || this.state.roleName === "Admin") && this.state.StatePropertyIdArray && this.state.StatePropertyIdArray.length > 0 && this.state.showMoveButton ? <div className="col-sm-1 mt-4">
                  <span style={{ width: "100%", marginLeft: "43%" }}>
                    <p className="ml-auto mr-auto"><img
                      src={configImages.leftIcon}
                      alt=""
                      className="mt-2 mb-2"
                      style={{ width: "30px", height: "30px" }}
                      data-toggle="tool-tip"
                      title="click to move selected Property Names to right"
                      onClick={() => this.moveLeftToRight()} /></p>

                  </span>
                </div> : null} */}


                {/* {this.state.statePropertyIdRight && (this.state.roleName ===config.roleNames.coreOpsManager || this.state.roleName === "Admin") ?
                  <div className="col-sm-5 pl-0 pr-0">
                    <DataTable value={this.state.addedProperties && this.state.addedProperties.length > 0 ? this.state.addedProperties : this.state.StatePropertyIdArray} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ marginTop: "14px", width: "1000px" }}
                      onSort={(e) => this.handleDataChangingOptionAdded(e)}
                      globalFilter={this.state.globalFilter}
                      onFilter={this.isConfirmFilterAdded}
                    >
                      <Column field="propertyName" header="Names" bodyStyle={bodyStyle} headerStyle={headerStyle} body={this.hoverTemplate} editor={this.textEditor}
                        sortable={true} filter={true} dataChangeOptions={this.state.dataChangeOptionswithFilter}
                        filterElement={
                          <select onChange={(e) => this.onSeletFilterTypeAdded("propertyName")} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${"propertyName"}`} style={{ width: "100%" }} >
                            <option value="regexOr">Includes</option>
                            <option value="eq">Equals</option>
                            <option value="nregexOr">Excludes</option>
                          </select>} />
                      <Column field="isInclude" header="Is Included" bodyStyle={bodyStyleRight} body={this.hoverTemplate} editor={this.brandEditor} headerStyle={headerStyle} />

                      <Column field="updatedBy" header="Updated By" bodyStyle={bodyStyleRight} body={this.hoverTemplate} headerStyle={headerStyle} />
                      <Column field="updatedAtUtc" header="Updated On Date" bodyStyle={bodyStyleRight} body={this.dateTemplate} headerStyle={headerStyle} />

                    </DataTable>
                  </div> : null} */}
                {/* {this.state.roleName === "Search Analyst" && this.state.addedProperties.length > 0 ?
                  <div className="col-sm-5 pl-0 pr-0">
                    <DataTable value={this.state.addedProperties && this.state.addedProperties.length > 0 ? this.state.addedProperties : []} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ marginTop: "14px", width: "1000px" }}
                    >
                      <Column field="propertyName" header="Excluded Property Names" bodyStyle={bodyStyle} headerStyle={headerStyle} body={this.hoverTemplate} />
                      <Column field="updatedBy" header="Excluded By" bodyStyle={bodyStyleRight} body={this.hoverTemplate} headerStyle={headerStyle} />
                      <Column field="updatedAtUtc" header="Excluded On Date" bodyStyle={bodyStyleRight} body={this.dateTemplate} headerStyle={headerStyle} />

                    </DataTable>
                  </div> : this.state.roleName === "Search Analyst" && this.state.addedProperties.length === 0 ? <div className='mt-4'>
                    No Excluded Property Names
                  </div> : null} */}
                {/* </div> */}

              </div>
              : this.state.activeTab === "Existing Data" && this.state.StatePropertyIdArray.length === 0 && this.state.selectedSeller && this.state.noProp ? "No properties Names Found" : null}

            {this.state.activeTab === "Search Terms" ?
              <div>
                <div style={{ display: 'block', border: "2px solid lightgrey" }} >
                  <div className='ml-2'>
                    <p style={{ fontSize: "initial" }}>{"Kelmar-1:  AL, AK, AR, CO, CT, DC, ID, IL, IN, IA, LA, ME, MA, MI, MN, MS, NE, NH, NJ, NC, ND, OR, RI, SC, SD, TX, UT, VA, WA, WV, WY"} <FontAwesomeIcon style={{ marginTop: "20px" }} icon={faInfoCircle} color="blue" title={"Search Behavior"} onClick={(e) => this.SearchBehavior("1", "Kelmar-1")} /></p>
                    <p style={{ marginTop: "4px", fontSize: "initial" }}>{"Kelmar-2:  AZ, DE, OH, PR"}<FontAwesomeIcon style={{ marginTop: "4px", marginLeft: "7px" }} icon={faInfoCircle} color="blue" title={"Search Behavior"} onClick={(e) => this.SearchBehavior("2", "Kelmar-2")} /></p>
                    <p style={{ marginTop: "4px", fontSize: "initial" }}>{"Group B :  CA, GA, MO, KY, MT, NM, NV, PA, TN, VT"}<FontAwesomeIcon style={{ marginTop: "4px", marginLeft: "7px" }} icon={faInfoCircle} color="blue" title={"Search Behavior"} onClick={(e) => this.SearchBehavior("3", "Group B")} /></p>
                    <p style={{ marginTop: "4px", fontSize: "initial" }}>{"Group C :  FL, HI, KS, MD, NY, WI"}<FontAwesomeIcon style={{ marginTop: "4px", marginLeft: "7px" }} icon={faInfoCircle} color="blue" title={"Search Behavior"} onClick={(e) => this.SearchBehavior("4", "Group C")} /></p>
                    <p style={{ marginTop: "4px", fontSize: "initial" }}>{"Group D : OK"}<FontAwesomeIcon style={{ marginTop: "4px", marginLeft: "7px" }} icon={faInfoCircle} color="blue" title={"Search Behavior"} onClick={(e) => this.SearchBehavior("5", "Group D")} /></p>

                    {/* <p style={{ marginTop: "20px", fontSize: "initial", color: "dodgerblue" }}>{"Bring all results with [First Word] anywhere in the property owner name. +  Bring all results with [Second Word] anywhere in the result. + Bring all results with [Third Word] anywhere in the result."}</p> */}
                    {/* <p style={{ marginTop: "0px", fontSize: "initial", color: "dodgerblue" }}>{"Plus"}</p>
                  <p style={{ marginTop: "0px", fontSize: "initial", color: "dodgerblue" }}>{"Bring all results with [Second Word] any where in the result."}</p>
                  <p style={{ marginTop: "0px", fontSize: "initial", color: "dodgerblue" }}>{"Plus"}</p>
                  <p style={{ marginTop: "0px", fontSize: "initial", color: "dodgerblue" }}>{"Bring all results with [Third Word] any where in the result."}</p> */}
                  </div>
                </div >
                {this.state.rule === "1" || this.state.rule === "2" ? <div style={{ display: 'block', border: "2px solid lightgrey" }} >
                  <div className='ml-2'>
                    <p style={{ fontSize: "initial" }}>{this.state.rulename} <FontAwesomeIcon style={{ marginTop: "6px", float: "right", marginRight: "38px" }} icon={faTimes} color="blue" title={"Close"} onClick={(e) => this.CloseSearchBehavior("1")} /></p>
                    <p style={{ fontSize: "initial" }}>{"Example for this state:  ABC PHYSICAL THERAPY"} </p>
                    <p style={{ marginTop: "4px", fontSize: "initial" }}>{"[First Word] [Second Word] [Third Word]"}</p>
                    <p style={{ marginTop: "20px", fontSize: "initial", color: "dodgerblue" }}>{"Bring all results with [First Word] anywhere in the property owner name. +  Bring all results with [Second Word] anywhere in the result. + Bring all results with [Third Word] anywhere in the result."}</p>
                    {/* <p style={{ marginTop: "0px", fontSize: "initial", color: "dodgerblue" }}>{"Plus"}</p>
                  <p style={{ marginTop: "0px", fontSize: "initial", color: "dodgerblue" }}>{"Bring all results with [Second Word] any where in the result."}</p>
                  <p style={{ marginTop: "0px", fontSize: "initial", color: "dodgerblue" }}>{"Plus"}</p>
                  <p style={{ marginTop: "0px", fontSize: "initial", color: "dodgerblue" }}>{"Bring all results with [Third Word] any where in the result."}</p> */}
                  </div>
                </div > : this.state.rule === "3" ?
                  <div style={{ display: 'block', border: "2px solid lightgrey" }} >
                    <div className='ml-2'>
                      <p style={{ fontSize: "initial" }}>{this.state.rulename} <FontAwesomeIcon style={{ marginTop: "6px", float: "right", marginRight: "38px" }} icon={faTimes} color="blue" title={"Close"} onClick={(e) => this.CloseSearchBehavior("1")} /></p>
                      <p style={{ fontSize: "initial" }}>{"Name To Search:  ABC Physical Therapy"} </p>
                      <p style={{ marginTop: "4px", fontSize: "initial" }}>{"Search Term       :  ABC Physical"}</p>
                      <p style={{ marginTop: "6px", fontSize: "initial", color: "dodgerblue" }}>{"Site Behavior:    The site works like the search terms entered are like wildcards. In this example, it will bring all results that has ABC Physical XXXX  OR  Physical ABC xxx  OR  XXX ABC XX Physical XX ."}</p>
                    </div>
                  </div> : this.state.rule === "4" ?
                    <div style={{ display: 'block', border: "2px solid lightgrey" }} >
                      <div className='ml-2'>
                        <p style={{ fontSize: "initial" }}>{this.state.rulename} <FontAwesomeIcon style={{ marginTop: "6px", float: "right", marginRight: "38px" }} icon={faTimes} color="blue" title={"Close"} onClick={(e) => this.CloseSearchBehavior("1")} /></p>
                        <p style={{ fontSize: "initial" }}>{"Name To Search:  ABC Physical Therapy"} </p>
                        <p style={{ marginTop: "4px", fontSize: "initial" }}>{"Search Term       :  ABC Physical"}</p>
                        <p style={{ marginTop: "6px", fontSize: "initial", color: "dodgerblue" }}>{"Site Behavior:    The site works like the search terms returns all results that begins with search term. In this example, it will bring all results that has ABC Physical XXXX ."}</p>
                      </div>
                    </div> : this.state.rule === "5" ?
                      <div style={{ display: 'block', border: "2px solid lightgrey" }} >
                        <div className='ml-2'>
                          <p style={{ fontSize: "initial" }}>{this.state.rulename} <FontAwesomeIcon style={{ marginTop: "6px", float: "right", marginRight: "38px" }} icon={faTimes} color="blue" title={"Close"} onClick={(e) => this.CloseSearchBehavior("1")} /></p>
                          <p style={{ fontSize: "initial" }}>{"Name To Search:  ABC Physical Therapy"} </p>
                          <p style={{ marginTop: "4px", fontSize: "initial" }}>{"Search Term       :  ABC Physical"}</p>
                          <p style={{ marginTop: "6px", fontSize: "initial", color: "dodgerblue" }}>{`Site Behavior:    The site works like the search terms returns all results that begins with search term. In this example, it will bring all results that has ABC Physical XXXX .`}</p>
                        </div>
                      </div> : null}
                {this.state.showSearchTable ?
                  <div>
                    <div className='mt-2'>
                      <h5>Enter New Search Term</h5>
                    </div>
                    <div className='mt-1'>
                      <InputText
                        type="search"
                        name='globalSearch'
                        id='globalSearch'
                        value={this.state.selectedSearchTerm}
                        style={{ height: 37, paddingLeft: "1rem", width: 400 }}
                        onKeyPress={(e) => this.sendSearchTermData(e, "searchItem")}
                        onChange={(e) => this.sendSearchTermData(e, "searchItem")}
                      // placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                      // size={25}
                      //  disabled={true}
                      />
                    </div>
                    <p className="" style={{ color: this.state.errorcolor === "green" ? "green" : "red" }} >{this.state.errorResponse ? this.state.errorMessage : null}</p>

                    <Nav tabs >
                      {
                        this.state.searchTabOptions && this.state.searchTabOptions.length > 0 ? this.state.searchTabOptions.map((item, index) => {
                          return (
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.searchactiveTab === item })}
                                onClick={() => { this.getsearchSelectedTabTypeInfo(item); }}
                              >
                                <h4>{item}</h4>

                              </NavLink>
                            </NavItem>
                          )
                        }) : null
                      }

                    </Nav>
                  </div> : null}
                <Loader loader={this.state.isLoading} />
                {this.state.showSearchTable ?
                  <div>
                    <p style={{ fontSize: "initial", marginTop: "5px" }}>{this.state.searchactiveTab === "Active" ? "Approved Search Terms" : "Deleted and Inactive search terms"}</p>

                    <DataTable value={this.state.searchTermsRecords} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ marginTop: "14px", width: "1000px" }} onSort={(e) => this.handleDataChangingOptionSearch(e)}
                      globalFilter={this.state.globalFilter}
                      onFilter={this.isConfirmFilterSearch}
                    >
                      <Column field="searchTerm" header="Search Term" bodyStyle={bodyStyle} headerStyle={{
                        width: 460,
                        textAlign: "center",
                      }} body={this.hoverTemplate} sortable={true} filter={true} dataChangeOptions={this.state.dataChangeOptionswithFilter}
                        filterElement={
                          <select onChange={(e) => this.onSeletFilterTypeSearch("searchTerm")} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${"searchTerm"}`} style={{ width: "100%" }} >
                            <option value="regexOr">Includes</option>
                            <option value="eq">Equals</option>
                            <option value="nregexOr">Excludes</option>

                          </select>} />
                      <Column field="created" header="Created" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} sortable={true} body={this.dateTemplateNew} headerStyle={{
                        width: 150,
                        textAlign: "center",
                      }} />
                      <Column field="createdBy" header="Created By" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} sortable={true} body={this.hoverTemplate} headerStyle={{
                        width: 150,
                        textAlign: "center",
                      }} />
                      {this.state.searchactiveTab === "Active" ? <Column field="" header="" bodyStyle={bodyStyleRight} body={this.editTemplateNew} headerStyle={{
                        width: 90,
                        textAlign: "center",
                      }} /> : null}

                      {/* <Column field="states" header="States" bodyStyle={bodyStyle} body={this.stateTemplate} headerStyle={{
                      width: 150,
                      textAlign: "center",
                    }} /> */}

                    </DataTable>
                  </div>
                  : this.state.noRecords && this.state.showSearchTable ?
                    <p style={{ color: "red" }}>{"No SearchTerms Found"}</p> : null
                }
              </div>
              : null}
          </div>

        </div>

        : <div style={{ display: 'block', border: "2px solid lightgrey" }} >
          <form className="form m-2" onSubmit={handleSubmit(this.submit)}>
            <div className='row m-0 p-0 d-flex' style={{ width: "100%" }}>

              <div className={this.props.type === "Properties" || this.props.type === "ClaimsManagement" ? "w-100" : "col-sm-9"}>
                <div className='d-flex'>
                  {/* {this.props.type === "Deal Sizing Report For Companies" ? <div className="pl-0" style={{ width: "30%" }}>
                  <div className={"pb-2"} >
                    <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>Company{<div style={{ width: "100%" }}>
                      <span >
                        <FontAwesomeIcon icon={faStarOfLife}
                          className='pl-1'
                          color='red'
                          data-toggle="tool-tip"
                          title="Mandotary Field"
                          style={{ width: 11 }}
                        /></span>
                      <span>
                        <FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          title="Clear Filter"
                          onClick={() => this.handleChange('selectedSeller', "Delete")}
                          style={this.state.selectedSeller ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        /></span>
                    </div>}</label>
                    <div >
                      <AutoComplete
                        value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                        suggestions={this.state.filteredSuggestions}
                        completeMethod={this.searchsellerItems}
                        minLength={1}
                        field={"CompanyName"}
                        style={{ width: "100%" }}
                        dropdown={true}
                        onChange={(e) => this.handleChange(e, 'Seller')}
                        appendTo={document.body} />
                    </div>
                  </div>
                </div> : */}

                  <div className="pl-0" style={this.props.type === "ClaimsManagement" ? { width: "32%" } : { width: "30%" }}>
                    <div className={"pb-2"} >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>{this.props.type === "Deal Sizing Report For Companies" ? "Company" : "Seller"}{<div style={{ width: "100%" }}>
                        <span >
                          <FontAwesomeIcon icon={faStarOfLife}
                            className='pl-1'
                            color='red'
                            data-toggle="tool-tip"
                            title="Mandotary Field"
                            style={{ width: 11 }}
                          /></span>
                        <span>
                          <FontAwesomeIcon icon={faTimes}
                            className='pl-1 mr-2'
                            color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            title="Clear Filter"
                            onClick={() => this.handleChange('selectedSeller', "Delete")}
                            style={this.state.selectedSeller ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                          /></span>
                      </div>}</label>
                      <div >
                        <AutoComplete
                          itemTemplate={this.editorTemplateForDeal}
                          value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller&&this.state.selectedSeller.DealYear?this.state.selectedSeller.DealYear:""})` : this.state.selectedSeller}
                          // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                          suggestions={this.state.filteredSuggestions}
                          completeMethod={this.searchsellerItems}
                          minLength={1}
                          field={this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies" ? "EntityName" : "EstateName"}
                          style={{ width: "100%" }}
                          dropdown={true}
                          onChange={(e) => this.handleChange(e, 'Seller')}
                          appendTo={document.body} />
                      </div>
                    </div>

                  </div>

                  {/* } */}

                  <div className="pl-0" style={this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? { width: "32%" } : { display: "none" }}>
                    <div className={"pb-2"} >
                      <label style={{ marginBottom: 4, width: "100%" }}>Name{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.ClaimName ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.handleChange("ClaimName", "Delete")}
                        style={this.state.ClaimName ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div >
                        <AutoComplete
                          value={this.state.ClaimName && this.state.ClaimName.companyName ? this.state.ClaimName.companyName : this.state.ClaimName}
                          suggestions={this.state.filteredclaimSuggestions}
                          completeMethod={this.searchClaimNamesItems}
                          minLength={1}
                          field={this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? "companyName" : ""}
                          style={{ width: "100%" }}
                          dropdown={true}
                          disabled={this.state.selectedSeller ? false : true}
                          onChange={(e) => this.handleChange(e, 'ClaimName')}
                          appendTo={document.body} />
                      </div>
                    </div>
                  </div>
                  <div style={this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "25%" } : this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "30%" }}>
                    <div>
                      <label style={{ marginBottom: 4, width: "100%" }}>Business Name{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.selectedName ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.handleChange("selectedName", "Delete")}
                        style={this.state.selectedName ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div >
                        <MultiSelect
                          className={"form__form-group-field "}
                          style={{ width: "100%", height: 32 }}
                          filter={true}
                          name={"Name"}
                          options={this.state.filterednameSuggestions}
                          value={this.state.selectedName && this.state.selectedName.CompanyName ? this.state.selectedName.CompanyName : this.state.selectedName}
                          onChange={(e) => this.handleChange(e, "Name")}
                          maxSelectedLabels={2}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mx-2' style={this.props.type === "Properties" ? { width: "20%", display: "Block" } : { width: "18%", display: "none" }}>
                    <div className="pb-1">
                      <label style={{ marginBottom: 4, width: "100%" }}>Match Status{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.selectedStatus ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.handleChange("selectedStatus", "Delete", 'matchMatchStatusId')}
                        style={this.state.selectedStatus ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div className='f'>
                        <MultiSelect
                          value={this.state.selectedStatus}
                          name={"Match_Status"}
                          filter={modifiedStatus.length > 6 ? true : false}
                          style={{ width: "100%", height: 32 }}
                          appendTo={document.body}
                          disabled={this.state.selectedSeller ? false : true}
                          maxSelectedLabels={1}
                          options={modifiedStatus}
                          onChange={(e) => this.handleChange(e, "Status")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='mx-2' style={this.props.type === "Properties" ? { width: "20%", display: "Block" } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { display: "grid", width: "19%" } : { display: "none" }}>
                    {this.props.type === "Properties" ? <div className=" pb-1">
                      <label style={{ marginBottom: 4, width: "100%" }}>Match bucket{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.selectedBucket ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.handleChange("selectedBucket", "Delete", 'masterbucket')}
                        style={this.state.selectedBucket ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div className=''>

                        <Dropdown className='State'
                          type={'text'}
                          // filter={modifiedStates.length > 6 ? true : false}
                          value={this.state.selectedBucket}
                          placeholder={""}
                          name="selectedBucket"
                          style={{
                            width: "100%",
                            height: 32
                          }}
                          disabled={this.state.selectedSeller ? false : true}
                          options={matchBuckets}
                          onChange={(e) => this.handleChange(e, "MatchBucket")}
                        />

                      </div>
                    </div> :
                      <>
                        <label style={{ marginBottom: 4, width: "100%" }}>Property Status{<FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          color={this.state.selectedPropertyStatus ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          onClick={() => this.handleChange("selectedPropertyStatus", "Delete", 'selectedPropertyStatus')}
                          title="Clear Filter"
                          style={this.state.selectedPropertyStatus ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        />}</label>
                        <span className=''>
                          <MultiSelect
                            name={"State"}
                            value={this.state.selectedPropertyStatus}
                            style={{ width: "100%", height: 32 }}
                            appendTo={document.body}
                            filter={true}
                            disabled={this.state.selectedSeller ? false : true}
                            maxSelectedLabels={1}
                            options={settingsData && settingsData.length > 0 ? settingsData : []}
                            onChange={(e) => this.handleChange(e, "selectedPropertyStatus")}

                          />
                        </span> </>}
                  </div>
                  <div style={this.props.type === "ClaimsManagement" ? { width: "18%" } : this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "18%", marginLeft: 30 } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { width: "13%", marginLeft: 3 } : this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "30%", marginLeft: 10 }}>
                    <div className=" pb-1">
                      <label style={{ marginBottom: 4, width: "100%" }}>Source State{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.selectedState ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        onClick={() => this.handleChange("selectedState", "Delete", 'property_StateId')}
                        title="Clear Filter"
                        style={this.state.selectedState ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div className=''>
                        <MultiSelect
                          name={"State"}
                          filter={true}
                          value={this.state.selectedState}
                          style={{ width: "100%", height: 32 }}
                          appendTo={document.body}
                          disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                          maxSelectedLabels={1}
                          options={modifiedStates}
                          onChange={(e) => this.handleChange(e, "State")}

                        />
                      </div></div></div>



                  {/* <div style={this.props.type === "ClaimsManagement" ? { width: "18%", marginLeft: 10 } : { display: "none" }}>
                  <div className=" pb-1">
                    <label style={{ marginBottom: 4, width: "100%" }}>Claim Status{<FontAwesomeIcon icon={faTimes}
                      className='pl-1 mr-2'
                      color={this.state.ClaimStatus ? 'red' : 'lightgrey'}
                      data-toggle="tool-tip"
                      onClick={() => this.handleChange("ClaimStatus", "Delete", 'ClaimStatus')}
                      title="Clear Filter"
                      style={this.state.ClaimStatus ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                    />}</label>
                    <div className=''>
                      <MultiSelect
                        name={"ClaimStatus"}
                        value={this.state.ClaimStatus}
                        style={{ width: "100%", height: 32 }}
                        appendTo={document.body}
                        disabled={this.state.selectedSeller ? false : true}
                        maxSelectedLabels={1}
                        options={modifiedStates}
                        onChange={(e) => this.handleChange(e, "ClaimStatus")}

                      />
                    </div>
                  </div>
                </div> */}
                  {/* <div style={this.props.type === "ClaimsManagement" ? { width: "30%", marginLeft: 10 } : { display: "none" }}>
                  <div className=" pb-1">
                    <label style={{ marginBottom: 4, width: "100%" }}>Asset Type{<FontAwesomeIcon icon={faTimes}
                      className='pl-1 mr-2'
                      color={this.state.selectedState ? 'red' : 'lightgrey'}
                      data-toggle="tool-tip"
                      onClick={() => this.handleChange("selectedState", "Delete", 'property_StateId')}
                      title="Clear Filter"
                      style={this.state.selectedState ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                    />}</label>
                    <div className=''>
                      <MultiSelect
                        name={"State"}
                        value={this.state.selectedState}
                        style={{ width: "100%", height: 32 }}
                        appendTo={document.body}
                        disabled={this.state.selectedSeller ? false : true}
                        maxSelectedLabels={1}
                        options={modifiedStates}
                        onChange={(e) => this.handleChange(e, "State")}

                      />
                    </div>
                  </div>
                </div> */}
                  <div className='mx-2' style={this.props.type === "ClaimsManagement" ? { display: "flex", width: '275px' } : { display: "none" }}>
                    <div className="pb-1">
                      <label style={{ marginBottom: 4, width: "100%" }}>Property Id{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.OpraStId ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.handleChange("OpraStId", "Delete", 'OpraStId')}
                        style={this.state.OpraStId ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div className='f'>
                        <textarea
                          // type="search"
                          name='OpraStId'
                          // id='estateIdsValue'
                          value={this.state.OpraStId}
                          onChange={(e) => this.handleChange(e, "OpraStId")}
                          placeholder={'Property Id'}
                          // size="10"
                          // height="100px"
                          rows="8"
                        />
                      </div>
                    </div>

                  </div>
                  <div className='mx-2' style={this.props.type === "ClaimsManagement" ? { display: "flex", width: '275px' } : { display: "none" }}>
                    <div className="pb-1">
                      <label style={{ marginBottom: 4, width: "100%" }}>State Claim Id{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.stClaimId ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.handleChange("stClaimId", "Delete", 'stClaimId')}
                        style={this.state.stClaimId ? { width: 75, cursor: "pointer", float: "right", pointerEvents: "visible" } : this.props.type === "ClaimsManagement" ? { width: 14, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 75, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div className='f'>
                        <textarea
                          // type="search"
                          name='stClaimId'
                          // id='estateIdsValue'
                          value={this.state.stClaimId}
                          onChange={(e) => this.handleChange(e, "stClaimId")}
                          placeholder={'State Claim Id'}
                          // size="10"
                          // height="100px"
                          rows="8"
                        />
                      </div>
                    </div>

                  </div>
                  <div className='mx-2' style={this.props.type === "ClaimsManagement" ? { display: "flex", width: '275px' } : { display: "none" }}>
                    <div className="pb-1">
                      <label style={{ marginBottom: 4, width: "100%" }}>Opp Claim Id{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2'
                        color={this.state.OpraClaimId ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        title="Clear Filter"
                        onClick={() => this.handleChange("OpraClaimId", "Delete", 'OpraClaimId')}
                        style={this.state.OpraClaimId ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div className='f'>
                        <textarea
                          // type="search"
                          name='OpraClaimId'
                          // id='estateIdsValue'
                          value={this.state.OpraClaimId}
                          onChange={(e) => this.handleChange(e, "OpraClaimId")}
                          placeholder={'Opp Claim Id'}
                          // size="10"
                          // height="100px"
                          rows="8"
                        />
                      </div>
                    </div>

                  </div>
                  {this.props.type === "ClaimsManagement" ?
                    <div className="col-sm-1 pl-0">
                      <label style={{ marginBottom: 4, width: "100%" }}>Analyst
                        {<FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          color={this.state.selectedAnalyst ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          onClick={() => this.handleChange("selectedAnalyst", "Delete", 'Analyst')}
                          title="Clear Filter"
                          style={this.state.selectedAnalyst ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        />}
                      </label>
                      <div className=''>
                        <MultiSelect
                          name={"Analyst"}
                          filter={true}
                          value={this.state.selectedAnalyst}
                          style={{ width: "100%", height: 32 }}
                          appendTo={document.body}
                          // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                          maxSelectedLabels={1}
                          options={this.state.userArray}
                          onChange={(e) => this.handleChange(e, "Analyst")}

                        />
                      </div></div> : null}

                  <div className='mx-2'>
                    <div >
                      {this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ?
                        <label style={{ color: "white", marginBottom: 4 }}>
                          Button
                        </label> : <label style={{ marginLeft: "px", marginBottom: 4 }}>

                        </label>}
                      <div className='justify-content-center'>
                        <Button color="primary"
                          outline
                          className="mb-0"
                          style={this.state.setGoButton ? { color: "white", backgroundColor: '#a0a2a5', width: 115 } : (this.state.selectedSeller || (this.props.type === "ClaimsManagement" && (this.state.selectedState || this.state.OpraClaimId || this.state.OpraStId))) ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
                          // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                          disabled={this.state.setGoButton ? true : (this.state.selectedSeller) ? false : true}
                          onClick={(e) => this.submit()}>
                          Go
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <Row className='mt-2 ml-0'>
                  {/* <div style={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                  <label htmlFor="cb3 col-sm-3" className="p-checkbox-label" style={{ marginRight: "50px", marginLeft: "16px" }}>Excl. OPRA2 Validated</label>
                  <Checkbox
                    label='Unassigned Only'
                    type='checkbox'

                    // component={renderCheckBoxField}
                    name={'validated'}
                    className=" ml-3"
                    checked={this.state.isValidate}
                    onChange={(e) => this.handleChange(e, "Validate")}
                  />
                </div> */}

                  {/* <div style={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                        <label htmlFor="cb3 col-sm-3" className="p-checkbox-label ml-2" style={{ marginRight: "50px" }}>Excl. OPRA Validated Prop.</label>
                        <Checkbox
                          label='Unassigned Only'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'validated'}
                          className=" ml-2"
                          checked={this.state.isexcludeValidated}
                          onChange={(e) => this.handleChange(e, "isexcludeValidated")}
                        />
                      </div> */}
                  <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                    <label htmlFor="cb3  col-sm-3" className="p-checkbox-label" style={{ marginRight: "50px" }}>{"Excl. <$100  Prop"}</label>
                    <Checkbox
                      label='Unassigned Only'
                      type='checkbox'
                      // component={renderCheckBoxField}
                      name={'validated'}
                      className=" ml-2"
                      checked={this.state.isexcludeProperties}
                      onChange={(e) => this.handleChange(e, "isexcludeProperties")}
                    />
                  </div>
                  <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                    <label htmlFor="cb3  col-sm-3" style={{ marginRight: "50px" }} className="p-checkbox-label"> Excl. Co Owners</label>
                    <Checkbox
                      label='Unassigned Only'
                      type='checkbox'
                      // component={renderCheckBoxField}
                      name={'validated'}
                      className=" ml-2"
                      checked={this.state.excludeCoOwners}
                      onChange={(e) => this.handleChange(e, "excludeCoOwners")}
                    />
                  </div>
                  <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                    <label htmlFor="cb3  col-sm-3" style={{ marginRight: "50px" }} className="p-checkbox-label">Excl. Not Confirmed Onsite</label>
                    <Checkbox
                      label='Unassigned Only'
                      type='checkbox'
                      // component={renderCheckBoxField}
                      name={'validated'}
                      className=" ml-2"
                      checked={this.state.excludeNotConfirmedOnSite}
                      onChange={(e) => this.handleChange(e, "excludeNotConfirmedOnSite")}
                    />
                  </div>
                  <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                    <label htmlFor="cb3  col-sm-3" className="p-checkbox-label ml-2" style={{ marginRight: "50px" }}>{"Excl.Possibly Paid"}</label>
                    <Checkbox
                      label='isexcludePossiblyPaid'
                      type='checkbox'
                      // component={renderCheckBoxField}
                      name={'isexcludePossiblyPaid'}
                      className=" ml-2"
                      checked={this.state.isexcludePossiblyPaid}
                      onChange={(e) => this.handleChange(e, "isexcludePossiblyPaid")}
                    />
                  </div>
                  <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                    <label htmlFor="cb3  col-sm-2" className="p-checkbox-label ml-2" style={{ marginRight: "50px" }}>{"Excl. Royalties"}</label>
                    <Checkbox
                      label='isexcludeRoyalties'
                      type='checkbox'
                      // component={renderCheckBoxField}
                      name={'isexcludeRoyalties'}
                      className=" ml-2"
                      checked={this.state.isexcludeRoyalties}
                      onChange={(e) => this.handleChange(e, "isexcludeRoyalties")}
                    />
                  </div>
                  {this.props.type === "Property Editor" ?
                    <div className='mx-2' style={{ width: "12%", display: "Block" }}>
                      <div className="pb-1">
                        <label style={{ marginBottom: 4, width: "100%" }}>Name Issue{<FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          color={'red'}
                          data-toggle="tool-tip"
                          title="Clear Filter"
                          onClick={() => this.handleChange("nameIssueCode", "Delete", 'nameIssueCode')}
                          style={{ width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" }}
                        />}</label>
                        <div className='f' data-toggle="tool-tip" title={IssueCodes.nameIssueCodes ? "" : "No options available"}>
                          <MultiSelect
                            value={this.state.nameIssueCode ? this.state.nameIssueCode : this.props.IssueCodes ? this.props.IssueCodes.defaultIssueNames : []}
                            name={"Match_Status"}
                            // filter={modifiedStatus.length > 6 ? true : false}
                            style={{ width: "100%", height: 32 }}
                            appendTo={document.body}
                            disabled={this.state.selectedSeller ? false : true}
                            maxSelectedLabels={1}
                            onFocus={() => this.showRecordsCount("show", "nameIssueCodes")}
                            onBlur={() => this.showRecordsCount("hide", "nameIssueCodes")}
                            options={IssueCodes ? IssueCodes.nameIssueCodes : []}
                            onChange={(e) => this.handleChange(e, "nameIssueCode")}
                          />
                          <span>{this.state.isShownameIssueCodes ? "No options available" : null}</span>
                        </div>
                      </div>
                    </div> : null}
                  {this.props.type === "Property Editor" ?
                    <div className='mx-2' style={{ width: "12%", display: "Block" }} data-toggle="tool-tip" title={IssueCodes.newNameIssueCodes ? "" : "No options available"}>
                      <div className="pb-1">
                        <label style={{ marginBottom: 4, width: "100%" }}>New Name Issue{<FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          color={this.state.newNameIssueCode ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          title="Clear Filter"
                          onClick={() => this.handleChange("newNameIssueCode", "Delete", 'newNameIssueCode')}
                          style={this.state.newNameIssueCode ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        />}</label>
                        <div className='f' data-toggle="tool-tip" title={IssueCodes.newNameIssueCodes ? "" : "No options available"}>
                          <MultiSelect
                            value={this.state.newNameIssueCode}
                            name={"Match_Status"}
                            // filter={modifiedStatus.length > 6 ? true : false}
                            style={{ width: "100%", height: 32 }}
                            appendTo={document.body}
                            disabled={this.state.selectedSeller ? false : true}
                            maxSelectedLabels={1}
                            onFocus={() => this.showRecordsCount("show", "newNameIssueCodes")}
                            onBlur={() => this.showRecordsCount("hide", "newNameIssueCodes")}
                            options={IssueCodes ? IssueCodes.newNameIssueCodes : []}
                            onChange={(e) => this.handleChange(e, "newNameIssueCode")}
                          />
                          <span>{this.state.isShownewNameIssueCodes ? "No options available" : null}</span>
                        </div>
                      </div>
                    </div> : null}

                </Row>
              </div>
              {this.props.type === "Deal Sizing Report For Companies" ?

                <div className='col-sm-3'>
                  <div className='d-flex'>
                    <div className='col-sm-5 pl-0' style={{ fontWeight: 'bold' }}>Last Update Date</div>
                    {":"}
                    <div className='col-sm-4' >{this.state.hidePropValues ? "" : this.state.lastUpdatedDate}</div>
                  </div>

                </div>

                : null
              }


              <div className='col-sm-3 d-flex'>
                <div className='mx-2' style={this.props.type === "Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? { display: "none" } : { display: "block" }}>
                  <div className="pb-1">
                    <label style={{ marginBottom: 4, width: "100%" }}>OPRA Property ID{<FontAwesomeIcon icon={faTimes}
                      className='pl-1 mr-2'
                      color={this.state.Property_Id ? 'red' : 'lightgrey'}
                      data-toggle="tool-tip"
                      title="Clear Filter"
                      onClick={() => this.handleChange("Property_Id", "Delete", 'matchMatchStatusId')}
                      style={this.state.Property_Id ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                    />}</label>
                    <div className='f'>
                      <textarea
                        // type="search"
                        name='Property_Id'
                        // id='estateIdsValue'
                        value={this.state.Property_Id}
                        onChange={(e) => this.handleChange(e, "Property_Id")}
                        placeholder={'Opra Property Id'}
                        // size="10"
                        // height="100px"
                        rows="8"
                      />
                    </div>
                  </div>
                </div>
                <div className='mx-2' style={this.props.type === "Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? { display: "none" } : { display: "flex" }}>
                  <div className="pb-1">
                    <label style={{ marginBottom: 4, width: "100%" }}>State Property Id{<FontAwesomeIcon icon={faTimes}
                      className='pl-1 mr-2'
                      color={this.state.OpraStId ? 'red' : 'lightgrey'}
                      data-toggle="tool-tip"
                      title="Clear Filter"
                      onClick={() => this.handleChange("OpraStId", "Delete", 'matchMatchStatusId')}
                      style={this.state.OpraStId ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                    />}</label>
                    <div className='f'>
                      <textarea
                        // type="search"
                        name='OpraStId'
                        // id='estateIdsValue'
                        value={this.state.OpraStId}
                        onChange={(e) => this.handleChange(e, "OpraStId")}
                        placeholder={'State Property Id'}
                        // size="10"
                        // height="100px"
                        rows="8"
                      />
                    </div>
                  </div>

                </div>
              </div>


            </div>
            <Row className={this.state.isredirtRanges ? "d-flex" : "d-none"} >
              <b><h5 htmlFor="cb3 col-sm-4 mr-4" className="p-checkbox-label my-2" >Exclusions :</h5></b><span className="my-2">
                {configMessages.ExclusionMessage}
              </span></Row>
          </form >
          {this.state.SessionWarningModal ? this.getSessionWraningModal() : null}
          {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}

        </div >
      //   </AccordionTab>
      // </Accordion>




    );
  }
}


SearchTermOnSiteFilter = reduxForm({
  form: "User Password Reset Form", // a unique identifier for this form
  enableReinitialize: true,
})(SearchTermOnSiteFilter);

// You have to connect() to any reducers that you wish to connect to yourself
SearchTermOnSiteFilter = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(SearchTermOnSiteFilter);

export default withTranslation('common')(SearchTermOnSiteFilter);