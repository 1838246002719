import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import fetchRequest from '../../../../config/service';
// config file
export default class PropertyLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tabType: "",
      type: 'PropertyLoader',
      selectedCR: true,
      tabDescriptionArray: [],
      selectedBK: true,
      criteria: [],
      //  activeTab: null,
      apiUrl: ""
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.getSettings()


  }
  getSettings = async () => {
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    // let apiUrl=''
    this.setState({
      isLoading: true
    });
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        this.setState({
          isLoading: false,
          settingsData: settingsData,
          // excludePossiblyDefaultEnable: settingsData.excludePossiblyDefaultEnable,
          // excludePossiblyPaid: settingsData.excludePossiblyDefaultEnable
        });
      }
    })
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  //user Task label options

  getTableFieldsForTabs = (tabType) => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        width: 150,
        field: 'dateAddedUtc',
        fieldType: 'dateTimeFormat',
        "dateFormat": "MM/DD/YYYY hh:mm a",
        "mobile": true,
        header: 'Date',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 150,
        field: 'originalFileName',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Original Filename',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 150,
        field: 'filename',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'File',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 150,
        field: 'dataSource',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Data Source',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 150,
        field: 'propertyQueueCollection',
        // "fieldType": "redirect",
        "allowInt": true,
        // "fieldType": " num",
        "filterType": 'num',
        "mobile": true,
        header: 'Property Queue Collection',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'status',
        // fieldType: "formatCurrency",
        "mobile": true,
        header: 'Status',
        // "filter": true,
        "displayInSettings": true,
        "filterType": 'num',
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'uploadedByUsername',
        "allowInt": true,
        // "fieldType": " num",
        "filterType": 'num',
        "mobile": true,
        header: 'User Name',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 100,
        field: 'initialized',
        "allowInt": true,
        // "fieldType": "redirect",
        "filterType": 'num',
        "mobile": true,
        header: 'Initialized',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 100,
        field: 'priority',
        // fieldType: "formatCurrency",
        "mobile": true,
        "filterType": 'num',
        header: 'Priority',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'numFilesErrored',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Files Errored',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 160,
        field: 'numConfirmedProperties',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Properties Confirmed OnSite',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'numNewProperties',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'New Properties',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'numIgnoredNewYorkProperties',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Num Ignored NewYork Properties',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'numIgnoredCaliforniaProperties',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Num Ignored California Properties',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'uploadFileId',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Upload File Id (In OPRA)',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'filesParsed',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Files Parsed',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'numberOfDuplicateProperties',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Number Of Duplicate Properties',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'numberOfPropertiesAfterDedup',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Number Of Properties After Dedup',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 150,
        field: 'numberOfPropertiesBeforeDedup',
        fieldType: "Object",
        "mobile": true,
        // "filterType": 'num',
        header: 'Number Of Properties Before Dedup',
        // "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      },

    ]
    return data;
  };

  getFormFields = () => {
    return ([
    ]);
  }


  getTableFields = (tabType) => {
    let data
    if (tabType) {
      this.setState({
        tabType: tabType,

      })
    }
    data = this.getTableFieldsForTabs(tabType);


    return data;
  };

  submit = async (item) => {
  }




  render() {
    let tabData = JSON.parse(localStorage.getItem("ruleGroups"))
    return (
      <span>
        {/* <Loader loader={this.state.isLoading} /> */}
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          getTableFieldsForTabsHigh={this.getTableFieldsForTabsHigh}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={false}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          dontShowTitle={true}
          customisedExport={true}
          settingsRequired={false}
          filterRequired={true}
          modifiedLabels={this.state.modifiedLabels}
          gridRequried={false}
          sample={false}
          isClearFilters={false}
          globalSearchFieldName='user'
          globalSearch={""}
          type={"PropertyLoader"}
          displayright={true}
          icon='menu-circle'
          settingsData={this.state.settingsData}
          sortField={"dateAddedUtc"}
          tabType={true}
          routeTo='sellers'
          displayViewOfForm='modal'
          apiResponseKey={"data"}
          className={true}
          apiUrl={"fileImportQueue/getAll"}
          entityType='employee'
        />
      </span>
    );
  }
}