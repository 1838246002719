import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";

// config file
export default class ManualMatchTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  getTableFields = () => {

    let data = [
      {
        textAlign: "left",
        width: 150,
        field: "Match_EstateId",
        mobile: true,
        header: "Estate ID",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      // {
      //   textAlign: "left",
      //   width: 180,
      //   field: "BusinessName",
      //   mobile: true,
      //   header: "Business Name",
      //   "filter": true,
      //   sortable: true,
      //   show: true,
      //   textCapitalize: true,
      // },

      {
        textAlign: "left",
        width: 100,
        field: "UploadRowDoneID",
        mobile: true,
        header: "Upload Row Done ID",
        "filterType": "num",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 100,
        field: "PropertyDatasourceId",
        mobile: true,
        header: "Prop.-Data Src  Id",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },

      {
        textAlign: "left",
        width: 150,
        field: "PropertyStatePropertyId",
        mobile: true,
        header: "Prop-St. Prop Id",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        //  fieldType: "Time",
        field: "PropertyStateId",
        // "filterType": "num",
        // "allowInt": true,
        mobile: true,
        header: "Prop - StateId",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "PropertyOwnerName",
        // "filterType": "num",
        mobile: true,
        header: "Prop. OwnerName",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "OwnerAddressLine1",
        // "filterType": "num",
        mobile: true,
        header: "Owner Address-Line1",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        field: "OwnerAddressLine2",
        mobile: true,
        header: "OwnerAddressLine2",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        field: "OwnerAddressCity",
        mobile: true,
        header: "Owner Address City",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 180,
        field: "OwnerAddressStateId",
        mobile: true,
        header: "Owner Add St.Id",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },

      {
        textAlign: "left",
        width: 100,
        field: "OwnerAddressZip",
        mobile: true,
        header: "Owner Address Zip",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 100,
        field: "PropertyPropertyDescription",
        mobile: true,
        header: "Prop Prop Description",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },

      {
        textAlign: "left",
        width: 150,
        field: "PropertyHolderName",
        // "filterType": "num",
        // "allowInt": true,
        mobile: true,
        header: "Prop.Holder Name",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        //  fieldType: "Time",
        field: "PropertyPropertyValueMin",
        // "filterType": "num",
        // "allowInt": true,
        mobile: true,
        header: "Prop.Value Min",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "PropertyPropertyValueMax",
        // "filterType": "num",
        mobile: true,
        header: "Prop.Value Max",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "PropertyDateEscheated",
        // "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        mobile: true,
        header: "Prop. Dt Escheated",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "PropertyDateOfLastContact",
        // "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        mobile: true,
        header: "Prop.Dt Of Last Contact",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "PropertyAlternateID",
        // "filterType": "num",
        mobile: true,
        header: "Prop. Alternate ID",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "PropertyHolderExtraInfo",
        // "filterType": "num",
        mobile: true,
        header: "Prop. Holder Extra Info",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "PropertyShares",
        // "filterType": "num",
        mobile: true,
        header: "Prop. Shares",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "UploadFileId",
        // "filterType": "num",
        mobile: true,
        header: "Upload File Id",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "UploadedByUser",
        // "filterType": "num",
        mobile: true,
        header: "Uploaded By User",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "Accepted",
        // "filterType": "num",
        mobile: true,
        header: "Accepted",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        // fieldType: "Time",
        field: "OPRAPropertyID",
        // "filterType": "num",
        mobile: true,
        header: "OPRA Prop ID",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 150,
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        field: "UpdatedDateUTC",
        mobile: true,
        header: "Updated Dt UTC",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },


    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "user_name",
        label: "User Name",
        id: "user_name",
        placeholder: "User Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },
    ];
  };



  render() {
    return (
      <DataTables
        onRef={(ref) => (this.datatableref = ref)}
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        statusFilter={false}
        className={true}
        sortField={"updon.Match_EstateId"}
        isClearFilters={true}
        exportRequried={true}
        printRequried={false}
        addRequried={false}
        // selectedSeller={this.props.selectedSeller}
        editRequired={false}
        // isContinue={this.props.isContinue ? this.datatableref.getDataFromServer() : null}
        deleteRequired={false}
        viewRequired={false}
        settingsRequired={false}
        filterRequired={false}
        gridRequried={false}
        loading={true}
        sample={false}
        dontShowTitle={false}
        globalSearchFieldName='user'
        isShowTable={true}
        // globalSearch={'Search'}
        type='Manually Loaded Properties'
        displayright={true}
        icon='apartment'
        routeTo='manuallyLoaded'
        displayViewOfForm='modal'
        apiResponseKey='results'
        apiUrl={"NsScoredProperties/ManuallyLoadedProperties"}
        entityType='employee'
      />
    );
  }
}
