// import fontAwsome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFolder } from '@fortawesome/free-regular-svg-icons';

import {
  faAngleDown, faBan, faBars,
  faBookOpen, faCalendar, faCertificate, faCheck,
  faCheckCircle, faChevronDown, faChevronLeft,
  faChevronRight, faCog, faComments, faDollarSign,
  faDownload, faEdit, faFemale, faFile, faGlobeAsia,
  faGripHorizontal, faHandPaper, faInfoCircle, faList,
  faMale, faMoneyBillAlt, faPaperPlane, faPlus, faPlusCircle,
  faPrint, faShoppingCart, faStethoscope, faSyncAlt, faTable, faTachometerAlt,
  faThumbsUp, faTimesCircle, faTrashAlt, faUpload, faUser, faUserFriends, faUsers,
  faCity,
} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.css';
import i18next from 'i18next';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import fetchMethodRequest from '../../config/service';
import { settings } from '../../redux/actions/settingsAction';
import '../../scss/app.scss';
import RouterComponent from './RouterComponent';
import ScrollToTop from './ScrollToTop';
import store from './store';

// fontawsome icons library
library.add(
  faTachometerAlt,
  faCheck,
  faCity,
  faFolder,
  faPlusCircle,
  faChevronDown,
  faList,
  faAngleDown,
  faPrint,
  faChevronLeft,
  faChevronRight,
  faGripHorizontal,
  faUsers,
  faCog,
  faStethoscope,
  faPaperPlane,
  faUserFriends,
  faPlus,
  faFile,
  faEdit,
  faTrashAlt,
  faThumbsUp,
  faShoppingCart,
  faComments,
  faDownload,
  faMoneyBillAlt,
  faGlobeAsia,
  faUser,
  faDollarSign,
  faCertificate,
  faSyncAlt,
  faUpload,
  faBookOpen, faFemale, faMale,
  faBan, faCheckCircle, faTimesCircle, faCalendar, faTable, faInfoCircle, faBars,
  faHandPaper,

)
const languageConfig = {
  "interpolation": {
    "escapeValue": false
  },
  "lng": "en",
  "resources": {
    "en": {
      "common": {}
    },
    "fr": {
      "common": {}
    },
  }
}

i18next.init(languageConfig);
// i18next.init(i18nextConfig);

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
      langResources: {
        en: {}
      }
    };
  }

  componentDidMount = async () => {
    // await this.loadSettingsData();
  }


  loadSettingsData = async () => {
    // let filterCriteria = {};
    // filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchMethodRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        let settingsData = response.settings[0]
        await store.dispatch(settings(settingsData))
        if (settingsData && settingsData.languageResources) {
          let langKeys = Object.keys(settingsData.languageResources)
          localStorage.setItem('langKeys', JSON.stringify(langKeys));
          await this.setState({
            langResources: settingsData.languageResources
          })
          let lanConfig = languageConfig;
          let currentLang = localStorage.getItem('currentLang');
          currentLang = currentLang ? currentLang : 'en';

          lanConfig.lng = currentLang;
          lanConfig.resources = this.state.langResources;
          await i18next.init(lanConfig);
          this.setState({
            loading: false,
            loaded: true
          })
        }
      }
    })
  }
  render() {

    return (
      <Provider store={store}>
        <BrowserRouter basename="/">
          <I18nextProvider i18n={i18next}>
            <ScrollToTop>
              <Fragment>
                {/* {!loaded
                  && (
                    <div className={`load${loading ? '' : ' loaded'}`}>
                      <div className="load__icon-wrap">
                        <svg className="load__icon">
                          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                      </div>
                    </div>
                  )
                } */}
                <div>
                  <RouterComponent />
                </div>
              </Fragment>
            </ScrollToTop>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
