
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
// fecth method from service.js file
import apiCalls from '../../config/apiCalls';
import ExcelReact from '../Cruds/CommonDataTable/ExcelReact';
import fetch from '../../config/service';
import dateFormats from '../UI/FormatDate/formatDate';
import { withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext'
import { CSVLink } from "react-csv";
import fetchMethodRequest from '../../config/service';
import Loader from '../App/Loader';

class FactSetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      exportData: [],
      propertyStatus: ["EstateName", "BusinessName", "PropertyName", "SourceState", "PropertyCity"]

    };
    localStorage.removeItem("searchName")
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  submit = async (values) => {
    if (values === "Go") {
      if (this.state.searchName && (this.state.searchName !== "" || this.state.searchName === null)) {
        await this.setState({
          setGoButton: true
        })
        let filterCriteria = this.props.filterCriteria
        localStorage.setItem("searchName", this.state.searchName.EstateID)
        let data = []
        if (this.props.activeTab === "FactSet Entity Subsidiary Count") {
          data = await this.changeingColumnFieldsOrder()
        }

        this.props.getFilteredValues(filterCriteria, data, this.state.searchName)
      } else {
        this.props.getErrorMessage("Please search for a company")
      }
    }
  }

  changeingColumnFieldsOrder = () => {

    let tableFields = this.props.tableFieldsToShow
    tableFields = this.props.getTableFields
    if (this.props.activeTab === "FactSet Entity Subsidiary Count") {
      let dataindex
      let tablenotKnownFields = tableFields.filter(function (el) {
        return !el.field.includes("Count") || !el.field.includes("Value")
      });

      for (var item of tablenotKnownFields) {
        if (this.state.propertyStatus.includes(item.field)) {
          dataindex = tableFields.findIndex(obj => obj.field === item.field)
          tableFields[dataindex].show = true
        } else if (!this.state.propertyStatus.includes(item.field) && !item.field.includes("Count") && !item.field.includes("Value")) {
          dataindex = tableFields.findIndex(obj => obj.field === item.field)
          tableFields[dataindex].show = false
        }
      }
      let sortedOrder = this.state.propertyStatus
      let data = [], index
      let knownFields = []
      for (var record of sortedOrder) {
        index = tableFields.findIndex(obj => obj.field === record)
        data.push(tableFields[index])
      }
      knownFields = tableFields.filter(function (el) {
        return el.field.includes("Count") || el.field.includes("Value")
      });
      tableFields = [...data, ...knownFields]
      let xlsx_headers = localStorage.getItem("tableFields_download") ? localStorage.getItem("tableFields_download") : {}
      xlsx_headers = { [this.props.activeTab]: tableFields }
      localStorage.setItem("tableFields_download", JSON.stringify(xlsx_headers))
    }
    return tableFields


  }

  onSearchNameChange = async (e) => {
    this.setState({
      searchName: e.target.value,
      setGoButton: false
    })
  }

  //Get all data of current screen with filters applied from server to export to CSV
  getDataToExport = async () => {

    await this.setState({ isLoading: true, isexported: true })
    let filterCriteriaData = this.state.filterCriteria;
    // delete filterCriteriaData['limit'];
    // delete filterCriteriaData['page'];
    let url;

    let apiUrl;
    if (this.props.activeTab === "FactSet Names-Address List For Scoring") {
      url = "factSetNameAddress"
      apiUrl = `${apiCalls.FactSetNamesAddress}?FactSetEntityID=${this.props.ParentId}`
    } else {
      url = "factSetDetails"
      apiUrl = `${apiCalls.FactSetDetails}?ParentId=${this.props.ParentId}`;
    }
    if (apiUrl) {
      return fetchMethodRequest('GET', apiUrl)
        .then(async (response) => {
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
          }
          let headers = [], item2 = {}, data = []
          let arrayFormatcolumns = []
          if (response[url] && response[url].length > 0) {
            arrayFormatcolumns = Object.keys(response[url][0])
          }
          if (this.props.activeTab === "FactSet Entity Tree") {
            arrayFormatcolumns = ["Ultimate Parent Entity Name", "Immediate Parent Entity Name", "FactSet Entity Name", "FactSet Entity ID", "Depth", "HQAddressLine1", "HQCity", "HQState_Province", "HQPostCode", "Transaction Comments", "Previous Parent Entity", "New Parent Entity", "Parent Ownership Start Date", "Parent Ownership End Date"]
          }
          if (this.props.activeTab === "FactSet Names-Address List For Scoring") {
            arrayFormatcolumns = ['Ultimate Parent Entity Name', 'Immediate Parent Entity Name', 'FactSet Entity Name', 'BusinessName', 'FactSet Entity ID', 'Name Type', 'Depth', 'HQAddressLine1', 'HQCity', 'HQState_Province', 'HQPostCode', 'Transaction Comments', 'Previous Parent Entity', 'New Parent Entity', 'Parent Ownership Start Date', 'Parent Ownership End Date', 'Address Line1', 'City', 'Zip']
          }

          if (response && response[url] && response[url].length && response[url].length > 0) {
            let exportData = response[url]
            let item = exportData[0]
            for (var key of arrayFormatcolumns) {

              item2[key] = item[key]

            }
            for (let key in item2) {
              let index = headers.findIndex(obj => obj.key === key)
              if (index === -1) {
                headers.push(
                  { label: key.charAt(0).toUpperCase() + key.slice(1), key: key }
                )
              }
            }
            exportData[0] = item2
            exportData.forEach((item) => {
              for (let key in item) {
                if (item[key] && item[key].name) {
                  item[key] = item[key].name;
                }
              }
              for (let key in item) {
                if (item[key] && item[key].name) {
                  item[key] = item[key].name;
                }
              }
              item = exportData[0]
            });
            this.setState({
              exportData: exportData,
              exportHeaders: headers,
              isLoading: false,
            }, () => {
              // click the CSVLink component to trigger the CSV download
              this.csvLinkRef.link.click();
              this.setState({
                exportData: [],
                isexported: false
              })
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        }).catch((err) => {
          this.setState({
            isLoading: false
          });
          return err;
        });
    }
  }

  handleSelectedAction = async () => {
    var exportedData,url,apiUrl
    if (this.props.activeTab === "FactSet Names-Address List For Scoring") {
      url = "factSetNameAddress"
      apiUrl = `${apiCalls.FactSetNamesAddress}?FactSetEntityID=${this.props.ParentId}`
    } else {
      url = "factSetDetails"
      apiUrl = `${apiCalls.FactSetDetails}?ParentId=${this.props.ParentId}`;
    }
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        exportedData = response[url]
        await this.formatforExcel(exportedData)
      })


  }

  formatforExcel = async (exportedData, headersData) => {
    if (exportedData && exportedData.length > 0) {
      let headers = []
      let columns = this.props.tableFieldsToShow

      for (let key of columns) {
        let index = headers.findIndex(obj => obj.key === key.header)
        if (index === -1 && key.field.length > 0 && key.field !== "downloadBtn") {
          headers.push(
            { label: key.header ? key.header.charAt(0).toUpperCase() + key.header.slice(1) : key.field, field: key.field }
          )
        }
      }
      // exportedData = this.updateDateFormat(exportedData, "MM/DD/yyyy");
      let obj = [{
        name: "FactSet",
        headers: headersData ? headersData : headers,
        data: await this.formatChange(exportedData)
      }]
      await this.setState({
        sheets: obj,
        isLoading: false
      })
      document.getElementsByClassName("excel-React")[0].children[0].click()
    } else {
      await this.setState({
        sheets: [],
        isLoading: false
      })
    }
  }

  /**
   * 
   * @returns Component that is used for Download excel sheet
   */
  getExcelDownload = () => {
    let todaydate = dateFormats.formatDate(
      new Date(),
      "MM/DD/YYYY", "nhg")
    return (
      <span className='excel-React d-none'>
        <ExcelReact
          EstateName={this.props.tabOptions && this.state.activeTab !== this.props.type ? `${this.state.activeTab}-${this.props.type}` : `${this.props.type}`}
          sheets={this.state.sheets}
          type="Sellers"
        />
      </span>
    )
  }
  getArrayVal = (val) => {
    if (val) {
      val = val.replace(/\\n/g, ' ')
        .replace(/\\t/g, ' ')
        .replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ')
        .replace(/\\/g, ' ')
        .replace(/"/g, '')
      // .replace(/,/g, ' ');
    }
    return val;
  }
  /**
  * 
  * @param {Array} data 
  * @returns Changing the data 
  * for change the format for the downloadig data through export
  */
  formatChange = (data) => {
    data.forEach((item) => {
      var notearry = []
      for (let key in item) {
        if (item[key] && item[key]) {
        } else {
          item[key] = ""
        }
        if (item[key] && item[key].name) {
          item[key] = item[key].name;
        }
        if (typeof item[key] == "object") {
          item[key] = this.getArrayVal(JSON.stringify(item[key]))
        }
      }
    });
    return data
  }

  EnterEvent = async (e) => {
    if (e.key === "Enter" && this.state.setGoButton === false) {
      this.submit("Go");
    }
  }

  render() {
    const { handleSubmit } = this.props;
    console.log("this.props.activeTab", this.props.activeTab)
    console.log("this.props.FactSetCompanyDetailsData", this.props.FactSetCompanyDetailsData)
    return (
      <div>
        <Loader
          loader={this.state.isLoading}
          progress={this.state.progress}
          className={"progress-loader"} />
        <div style={{ display: 'block', border: "2px solid lightgrey" }} >
          <form className="form" style={{ backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px" }} onSubmit={handleSubmit(this.submit)}>
            <div className='row col-sm-12 m-0 p-0 d-flex'>
              <div className='col-sm-3 col-lg-4'>

                <div style={{ padding: "initial" }}>

                  <div >
                    <InputText
                      type="search"
                      name='searchName'
                      id='searchName'
                      value={this.state['searchName']}
                      onChange={(e) => this.onSearchNameChange(e)}
                      placeholder={'Search Company'}
                      size="100"
                      onKeyDown={(e) => this.EnterEvent(e)}
                    />
                  </div>

                </div>

              </div>
              <div className='col-sm-1 justify-content-center ' style={{ margin: "0px 0px", width: "8%" }}>

                <Button color="primary"
                  outline
                  className="mb-0"
                  style={this.state.setGoButton ? { color: "white", backgroundColor: '#a0a2a5' } : { color: "white", backgroundColor: '#354f6e' }}
                  // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                  disabled={this.state.setGoButton ? true : false}
                  onClick={() => this.submit("Go")}>
                  Go
                </Button>
              </div>
              {/* {this.props.ParentId !== 1 && this.props.ParentId !== 2 && (this.props.activeTab === "FactSet Entity Tree" || this.props.activeTab === "FactSet Names-Address List For Scoring") && this.props.allUsersData && this.props.allUsersData.length > 0 &&
                <Button color="primary" outline
                  className='px-2 ml-2 mb-0 mr-0'
                  style={{ height: 37 }}
                  // disabled={this.props.type ==="Manually Loaded Properties"}
                  size={'sm'} onClick={this.getDataToExport}>
                  <FontAwesomeIcon
                    icon='download'
                    data-toggle="tool-tip" title={"Export To CSV"}
                    className='pl-1' size='lg' />
                </Button>} */}
              {this.props.ParentId !== 1 && this.props.ParentId !== 2 && (this.props.activeTab === "FactSet Entity Tree" || this.props.activeTab === "FactSet Names-Address List For Scoring") && this.props.allUsersData && this.props.allUsersData.length > 0 &&
                // <CSVLink
                //   data={this.state.exportData}
                //   headers={this.state.exportHeaders}
                //   filename={`FactSet.csv`}
                //   className="hidden text-white p-0"
                //   ref={(r) => this.csvLinkRef = r}
                //   target="_blank" >
                // </CSVLink>
                <span><Button color="primary"
                  className='p-1 py-0 mb-0 mt-1 ml-1 mr-1' style={{ height: "38px" }}
                  size={'sm'} onClick={this.handleSelectedAction}
                  outline
                   disabled={this.props.ParentId !== 1 && this.props.ParentId !== 2 && (this.props.activeTab === "FactSet Entity Tree" || this.props.activeTab === "FactSet Names-Address List For Scoring") && this.props.allUsersData && this.props.allUsersData.length > 0 ? false : true}
                >
                  <FontAwesomeIcon
                    icon='download'
                    data-toggle="tool-tip" title={"Export To excel"}
                    className='pl-1' size='lg' />
                </Button>
                  {this.getExcelDownload()}</span>

              }
            </div>
          </form>
        </div >
      </div >
    );
  }
}



FactSetForm = reduxForm({
  form: "User Password Reset Form", // a unique identifier for this form
  enableReinitialize: true,
})(FactSetForm);

// You have to connect() to any reducers that you wish to connect to yourself
FactSetForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(FactSetForm);

export default withTranslation('common')(FactSetForm);