import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    Button, Card, CardBody, Modal, ModalHeader, ModalBody,
    TabContent, TabPane, Nav, NavItem, NavLink, Row, Table
} from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import classNames from 'classnames';
import { CSVLink } from "react-csv";
// fecth method from service.js file
import fetch from '../../../config/service';
import fetchMethodRequest from '../../../config/service';

import configMessage from '../../../config/configMessages';
import configImages from '../../../config/configImages';

// show message 
import validate from '../../Validations/validate';
import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { faArrowCircleLeft, faSave, faStarOfLife, faExternalLinkAlt, faProjectDiagram, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultiSelect from '../../Form/components/MultiSelect'
import DefaultInput from '../../../shared/components/form/DefaultInput';
import AutoComplete from '../../Form/components/AutoComplete'
import Select from '../../../shared/components/form/Select';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import dateFormats from '../../UI/FormatDate/formatDate';
import RenderFileInputField from '../../Form/components/FileUpload';

import SessionWarningModal from './SessionWarningModal'
import ExpiredSessionModal from '../CommonModals/ExpiredSessionModal';
import TextAutoComplete from '../../../shared/components/form/TextAutocomplete';
import renderRadioButtonField from '../../../shared/components/form/RadioButton'
import DefaultTextArea from '../../../shared/components/form/DefaultTextArea'
import Switch from '@material-ui/core/Switch';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import NotesModal from './NotesModal'
import DatePicker from '../../../shared/components/form/DatePicker'
//config
import apiCalls from '../../../config/apiCalls';
import configMessages from '../../../config/configMessages';
import config from '../../../config/config';
import { async } from '@firebase/util';

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)
const theme = createTheme({
    overrides: {
        MuiSwitch: {
            thumb: {
                height: "17px!important",
                width: 17
            },
            track: {
                left: "51%!important"
            },
            root: {
                "&$checked": {
                    color: "white!important",
                    "& + $track": {
                        backgroundColor: "green !important",
                        color: "white!important",
                        height: 15,
                        left: "46%!important"
                    }
                }
            }
        },
    },
});
const normalizenumber = (value) => {
    const onlyNums = value.replace(/[^\d]/g, '')
    return `${onlyNums}`
}
let timeout

class CreateTaskModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: false,
            ishidesave: false,
            colored: false,
            header: true,
            rowData: {},
            progress: 0,
            hideSplitBtn: false,
            timer: false,
            sessionExpiryModal: false,
            checkedListPurpose: "",
            filterCriteria: { criteria: [] },
            checklists: [],
            key: 0,
            selectedLabel: "",
            tempCheckList: [],
            isSplittedData: [],
            redirectSellerId: null,
            negativePeriod: null,
            activeTab: "1",
            IsNewDeal: false,
            documents: [],
            errorResponse: "",
            isShowChecklistError: false,
            isSplittedStates: [],
            ismoveAll: false,
            additionalselectedStates: [],
            statestab2Data: [],
            selectedValues2: [],
            downtoleft: [],
            getValues1: [],
            getValues: [],
            ismoveAlltoleft: false,
            upCount: 0,
            downCount: 0,
            leftCount: 0,
            rightCount: 0,
            actions: [],
            isSelectAll: false,
            isShowisClaimed: false,
            priority: [
                { label: "Critical", value: "Critical" },
                { label: "High", value: "High" },
                { label: "Medium", value: "Medium" },
                { label: "Low", value: "Low" }],
            selectRoundOptions: [
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" }],
            selectOppSizeOptions: [
                { label: "Very Low", value: "Very Low" },
                { label: "Low", value: "Low" },
                { label: "Medium", value: "Medium" },
                { label: "High", value: "High" }],
            states: [
                { label: "Alabama - AL", value: "Alabama - AL", show: false, shortCode: "AL" },
                { label: "Alaska - AK", value: "Alaska - AK", show: false, shortCode: "AK" },
                { label: "Arizona - AZ", value: "Arizona - AZ", show: false, shortCode: "AZ" },
                { label: "Arkansas - AR", value: "Arkansas - AR", show: false, shortCode: "AR" },
                { label: "California - CA", value: "California - CA", show: false, shortCode: "CA" },
                { label: "Colorado - CO", value: "Colorado - CO", show: false, shortCode: "CO" },
                { label: "Connecticut - CT", value: "Connecticut - CT", show: false, shortCode: "CT" },
                { label: "Delaware - DE", value: "Delaware - DE", show: false, shortCode: "DE" },
                { label: "Florida - FL", value: "Florida - FL", show: false, shortCode: "FL" },
                { label: "Georgia - GA", value: "Georgia - GA", show: false, shortCode: "GA" },
                { label: "Hawaii - HI", value: "Hawaii - HI", show: false, shortCode: "HI" },
                { label: "Idaho - ID", value: "Idaho - ID", show: false, shortCode: "ID" },
                { label: "Illinois - IL", value: "Illinois - IL", show: false, shortCode: "IL" },
                { label: "Indiana - IN", value: "Indiana - IN", show: false, shortCode: "IN" },
                { label: "Iowa - IA", value: "Iowa - IA", show: false, shortCode: "IA" },
                { label: "Kansas - KS", value: "Kansas - KS", show: false, shortCode: "KS" },
                { label: "Kentucky - KY", value: "Kentucky - KY", show: false, shortCode: "KY" },
                { label: "Louisiana - LA", value: "Louisiana - LA", show: false, shortCode: "LA" },
                { label: "Maine - ME", value: "Maine - ME", show: false, shortCode: "ME" },
                { label: "Maryland - MD", value: "Maryland - MD", show: false, shortCode: "MD" },
                { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false, shortCode: "MA" },
                { label: "Michigan - MI", value: "Michigan - MI", show: false, shortCode: "MI" },
                { label: "Minnesota - MN", value: "Minnesota - MN", show: false, shortCode: "MN" },
                { label: "Mississippi - MS", value: "Mississippi - MS", show: false, shortCode: "MS" },
                { label: "Missouri - MO", value: "Missouri - MO", show: false, shortCode: "MO" },
                { label: "Montana - MT", value: "Montana - MT", show: false, shortCode: "MT" },
                { label: "Nebraska - NE", value: "Nebraska - NE", show: false, shortCode: "NE" },
                { label: "Nevada - NV", value: "Nevada - NV", show: false, shortCode: "NV" },
                { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false, shortCode: "NH" },
                { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false, shortCode: "NJ" },
                { label: "New Mexico - NM", value: "New Mexico - NM", show: false, shortCode: "NM" },
                { label: "New York - NY", value: "New York - NY", show: false, shortCode: "NY" },
                { label: "North Carolina - NC", value: "North Carolina - NC", show: false, shortCode: "NC" },
                { label: "North Dakota - ND", value: "North Dakota - ND", show: false, shortCode: "ND" },
                { label: "Ohio - OH", value: "Ohio - OH", show: false, shortCode: "OH" },
                { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false, shortCode: "OK" },
                { label: "Oregon - OR", value: "Oregon - OR", show: false, shortCode: "OR" },
                { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false, shortCode: "PA" },
                { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false, shortCode: "PR" },
                { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false, shortCode: "RI" },
                { label: "South Carolina - SC", value: "South Carolina - SC", show: false, shortCode: "SC" },
                { label: "South Dakota - SD", value: "South Dakota - SD", show: false, shortCode: "SD" },
                { label: "Tennessee - TN", value: "Tennessee - TN", show: false, shortCode: "TN" },
                { label: "Texas - TX", value: "Texas - TX", show: false, shortCode: "TX" },
                { label: "Utah - UT", value: "Utah - UT", show: false, shortCode: "UT" },
                { label: "Vermont - VT", value: "Vermont - VT", show: false, shortCode: "VT" },
                { label: "Virginia - VA", value: "Virginia - VA", show: false, shortCode: "VA" },
                { label: "Washington - DC", value: "Washington - DC", show: false, shortCode: "DC" },
                { label: "Washington - WA", value: "Washington - WA", show: false, shortCode: "WA" },
                { label: "West Virginia - WV", value: "West Virginia - WV", show: false, shortCode: "WV" },
                { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false, shortCode: "WI" },
                { label: "Wyoming - WY", value: "Wyoming - WY", show: false, shortCode: "WY" },
            ],
            comments: [],
            removedFiles: [],
            isSplitData: [],
            lengthNote: 0,
            taskNote: "",
            last2Notes: [],
            showFields: [],
            otherStates: [],
            issplitAll: false,
            tableFields: '',
            selectedValues: [],
            totalFields: [],
            exportData: [],
            apierrorResponse: false,
            disabledField: false,
            assignedValue: false,
            issplitbyStatus: false,
            IsClaimed: false,
            isSelecteddefinedStates: false,
            minDatefortask: new Date()
        };

    }

    componentDidUpdate() {
        if (this.state.apierrorResponse) {
            setTimeout(() => this.setState({ apierrorResponse: false, apierrorMessage: '' }), 200000);
        }
        // if (localStorage.getItem('loggedTime')) {
        //     let loggedTime = localStorage.getItem('loggedTime')
        //     if (timeout) {
        //         clearTimeout(timeout)
        //     }
        //     timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
        // }
    }
    cancelexpiredSessionModal = async () => {
        await this.setState({
            expiredSessionModal: false
        })
    }
    expiredSessionModal() {
        return (
            <ExpiredSessionModal
                openConfirmationModal={this.state.expiredSessionModal}
                cancelSessionWarningModal={this.cancelexpiredSessionModal}
                getData={this.getDataFromServer}

            />
        )
    }

    getDataToExport = async () => {
        this.setState({ isLoading: true })
        this.handlePercentage('1')
        let apiUrl;
        let str = this.state.rowData.company;
        let data = encodeURIComponent(str);
        let exportData = [];
        if (!this.state.rowData.opraEstateID) {
            apiUrl = `businessNames/estatesforsplit?str=${data}&type=exportToCsv`;
        } else {
            apiUrl = `businessNames/estatesforsplit?str=${data}&type=exportToCsv&estateID=${this.state.rowData.opraEstateID}`;
        }
        return fetch('GET', apiUrl)
            .then(async (response) => {
                let sessionexpired = await localStorage.getItem('sessionexpired')
                if (sessionexpired === "true") {
                    await this.setState({ sessionExpiryModal: true })
                }
                let url = "estateNames"
                if (response && response[url] && response[url].length > 0) {
                    exportData = response[url];
                    exportData.forEach((item) => {
                        for (let key in item) {
                            if (item[key] && item[key].name) {
                                item[key] = item[key].name;
                            }
                        }
                    });
                    this.setState({
                        exportData: exportData,
                        isLoading: false
                    }, () => {
                        this.csvLinkRef.link.click();
                    });
                } else if (response && response.errorMessage) {
                    this.setState({
                        exportData: exportData,
                        isLoading: false
                    })
                    this.getErrorMessage(response.errorMessage)
                } else {
                    this.setState({
                        exportData: [],
                        apierrorMessage: "No subsidiary estates are available for this estate",
                        apierrorResponse: true,
                        isLoading: false
                    })
                }
            }).catch((err) => {
                this.setState({
                    isLoading: false
                });
                return err;
            });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
        if (tab === '3') {
            this.getTaskHistoryData();
        }
    }

    componentDidMount = async () => {
        // this.props.onRef(this);
        await this.getoppsize(this.props.estateDetails)
        await this.getResearchRequestTypes()
        let data = document.getElementsByClassName("p-fileupload-buttonbar")[0]
        // data.getElementsByTagName("button")[0].style.display = "none"
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
        }
        if (this.props.createTask === "Research Request" && this.props.type === "addToTask") {
            this.getTasksAssociated("Research Request")
        }
        if (this.props.createTask === "Seller Negative Notice" && this.props.type === "addToTask") {
            this.getTasksAssociated("Seller Negative Notice")
        }
        if (this.props.createTask === "Site Confirmation" && this.props.type === "addToTask") {
            this.getTasksAssociated("Site Confirmation")
        }
        // await this.props.change("taskLabel", this.props.createTask)
        // await this.props.change("propertyCount", this.props.count)
        // await this.props.change("totalMinPropertyValue", this.props.taskminValue)
        let val = (Math.round(this.props.taskminValue * 100) / 100).toFixed(2);

        let obj = { taskLabel: this.props.createTask, propertyCount: this.props.count, unknownPropertyCount: this.props.unknownPropertyCount, totalMinPropertyValue: val, statePropertyIDs: this.props.taskstatePropertyId && this.props.taskstatePropertyId.length > 0 ? this.props.taskstatePropertyId.join("\n") : [], connectedProperties: this.props.taskopraPropertyId && this.props.taskopraPropertyId.length > 0 ? this.props.taskopraPropertyId.join("\n") : [], states: this.props.taskstates && this.props.taskstates.length > 0 ? [...new Set(this.props.taskstates)].join(",") : [], company: this.state.taskestateName }

        await this.props.load(obj)
        // await this.props.change("statePropertyids", this.props.taskstatePropertyId.join("\n"))
        // await this.props.change("connectedProperties", this.props.taskopraPropertyId.join("\n"))

        // await this.setState({ selectedStates: this.props.taskstates })

        this.getBusinessNamePurposes(this.props.createTask)
        await this.getUsersOptions()
        // this.getUserBasicData(this.props.rowData);
    }

    getUserBasicData = async (rowData) => {
        if (rowData && typeof rowData === "object") {
            let notes2records = rowData.notes && rowData.notes !== null && rowData.notes.length > 0 ? rowData.notes : []
            let last2Elements = notes2records.slice(-2)
            var reverselast2Elements = [...last2Elements].reverse();
            let user = JSON.parse(localStorage.getItem('loginCredentials'));
            let subTask = rowData.taskID.split("-")
            let subTaskSubsidory = rowData.taskID.split("-")
            if (subTask[1]) {
                await this.setState({ isChildTrue: true })
            } else {
                await this.setState({ isChildTrue: false })
            }
            if (subTaskSubsidory[1] && subTaskSubsidory[1].includes("S")) {
                await this.setState({ nameChild: true })
            } else {
                await this.setState({ nameChild: false })
            }


            await this.setState({
                assignedValue: false
            })
            await this.setState({
                timer2: rowData.timer,
                user: JSON.parse(localStorage.getItem('loginCredentials')),
                done: rowData.percentageDone,
                comments: rowData.notes && rowData.notes !== null && rowData.notes.length > 0 ? rowData.notes : [],
                last2Notes: reverselast2Elements,
                purposeList: rowData.checkLists,
                dealType: rowData.dealType,
                IsNewDeal: rowData.isNewDeal === "Yes" ? true : false,
                checklistStatus: rowData.status,
                selectedLabel: rowData.title,
                isShowisClaimed: rowData.title === "Transition" ? true : false,
                assignedValue: rowData.assignTo === "Unassigned" || !rowData.assignTo ? true : false,
                isTabdisabled: rowData.title !== "Recovery Planning" ? true : false,
                IsClaimed: rowData.isClaimed === "No" ? false : true,
                // selectedStates: selectedStates,
                claimID: rowData.claimID,
                rowData: rowData,
                propertyCountRFI: rowData.propertyCountRFI,
                documents: rowData.documents,
                taskNote: rowData.notes && rowData.notes.length > 0 ? rowData.notes[rowData.notes.length - 1].note : ""
            })
            if ((user && user._id !== rowData.assignToId) || this.props.view === "loggedThroughmail") {
                await this.setState({
                    showTimer: true
                })
            } else {
                await this.setState({
                    showTimer: false
                })
            }
            if ((!this.state.isTabdisabled || rowData.title !== "Site Discovery" || rowData.title !== "Validation" || rowData.title !== "File") && user.roleName === config.roleNames.coreOpsManager && (rowData.status === "Not Started" || rowData.status === "Paused")) {
                await this.setState({
                    disabledField: true
                })
            }
            await this.props.load(rowData)
            this.getNegativeNoticeSellers()
            this.props.change('dealType', this.state.dealType)
            let shownCompany
            if (rowData.opraEstateID) {
                shownCompany = "(" + rowData.opraEstateID + ")  " + rowData.company
            } else {
                shownCompany = rowData.company
            }
            // if (this.props.createTask === "Research Request") {
            //     this.props.change('states', rowData.states.join(","))
            // }
            this.props.change('company', shownCompany)
            this.getStatusOptions();
        } else {
            await this.props.load({})
            this.props.change('status', 'Not Started')
        }
        if (this.props.view === 'loggedThroughmail') {
            localStorage.removeItem('taskId')
        }
        this.highestSubArray();
        if (this.props.type === "add" || (this.props.type === "edit" && this.state.disabledField)) {
            this.getLabelOptions();
            // this.getBusinessNamePurposes();
        }
        if (!this.state.isChildTrue && rowData && rowData.subTaskCount > 0) {
            this.getsubTasksDataResponse()
        }
        this.getUsersOptions();
    }

    gettaskData = async (taskId) => {
        this.setState({
            isLoading: true
        })
        this.handlePercentage('1')
        return fetch('GET', `tasks/${taskId}`)
            .then(async (response) => {
                if (response && response.details) {
                    // localStorage.removeItem('taskId')
                    this.getUserBasicData(response.details)
                    this.toggle("1")
                } else if (response && response.errorMessage) {
                    this.getErrorMessage(response.errorMessage)
                }
                this.setState({ isLoading: false })
            }).catch((err) => {
                return err;
            });
    }

    getTaskHistoryData = async () => {
        this.setState({
            isLoading: true
        })
        this.handlePercentage('1')
        fetch('GET', `${apiCalls.TaskHistoryEffort}/${this.state.rowData.taskID}`)
            .then(async (response) => {
                if (response && response.durations) {
                    await this.setState({
                        activities: response.durations,
                        isLoading: false
                    })
                } else if (response && response['errorMessage'] && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                        activities: [],
                    })
                } else if ((response && response['errorMessage'] && response['errorMessage'] === configMessages.sessionExpired) || response['errorMessage'] === configMessages.tokenMessage) {
                    await this.setState({
                        sessionExpired: true,
                        activities: [],
                    })
                } else {
                    await this.setState({
                        activities: [],
                    })
                }
            }).catch((err) => {
                return err;
            });
    }

    getsubTasksDataResponse = async () => {
        fetch('GET', `tasks/getsubtasks/${this.state.rowData._id}`)
            .then(async (response) => {
                if (response && response.tasks) {
                    this.setState({
                        isLoading: false,
                        subTasksArray: response.tasks
                    })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                        subTasksArray: []
                    })
                } else if (response && (response['errorMessage'] === configMessages.sessionExpired || response['errorMessage'] === configMessages.tokenMessage)) {
                    await this.setState({
                        sessionExpiryModal: true,
                        subTasksArray: []
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        subTasksArray: []
                    })
                }
            })
            .catch((err) => {
                return err;
            });
    }

    getNegativeNoticeSellers = () => {
        let url = `NsScoredProperties/GetNegativeNotice?estateName=${encodeURIComponent(this.state.rowData.company)}&estateID=${this.state.rowData.opraEstateID}`
        fetch('GET', url)
            .then(async (response) => {
                if (response && response.dimSellers && response.negativeNotice !== null) {
                    await this.setState({
                        redirectSellerId: response.dimSellers[0].dimSellerID,
                        negativePeriod: response.negativeNotice.noticePeriod
                    })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        redirectSellerId: null,
                        negativePeriod: null
                    })
                } else {
                    await this.setState({
                        redirectSellerId: null,
                        negativePeriod: null
                    })
                }
            }).catch((err) => {
                return err;
            });
    }

    //user field options
    getUsersOptions = () => {
        this.setState({
            isLoading: true
        })
        this.handlePercentage('1')
        fetch('GET', apiCalls.Users)
            .then(async (response) => {
                if (response && response.users) {
                    let apps = response.users;
                    let modifiedApps = [];
                    let textAutoAreaSuggestions = [];
                    for (let app of apps) {
                        modifiedApps.push({
                            label: app.display_name,
                            value: {
                                name: app.display_name,
                                id: app._id,
                                image: app.image
                            },
                            app_id: app._id,
                        })
                        textAutoAreaSuggestions.push(app.name);
                    }
                    const sorted = modifiedApps.sort((a, b) => {
                        let fa = a.label.toLowerCase(),
                            fb = b.label.toLowerCase();
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    modifiedApps.unshift({
                        label: "Unassigned", value: { name: "Unassigned" }

                    })

                    // this.setState({ sorted: sorted })
                    await this.setState({
                        assignedUsers: sorted,
                        textAutoAreaSuggestions: textAutoAreaSuggestions,
                        textAutoAreaSuggestionsLength: response.pagination.totalCount + 1,
                        isLoading: false
                    })


                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                        assignedUsers: [],
                        textAutoAreaSuggestions: [],
                        textAutoAreaSuggestionsLength: 0
                    })
                }
            }).catch((err) => {
                return err;
            });
    }

    //user Task label options
    getLabelOptions = () => {
        this.setState({
            isLoading: true
        })
        this.handlePercentage('1');
        let filterCriteria = {}
        filterCriteria['criteria'] = [
            { key: "activeIndicator", value: true, type: "eq" }
        ];
        fetch('GET', `${apiCalls.Labels}?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response.labels) {
                    let labels = response.labels;
                    let modifiedLabels = [];
                    for (let label of labels) {
                        modifiedLabels.push({
                            label: label.labelName,
                            value: label.labelName,
                        })
                    }
                    await this.setState({
                        company: modifiedLabels,
                        isLoading: false
                    })
                    // this.setUnitName()
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                        isLoading: false
                    })
                }
            }).catch((err) => {
                return err;
            });
    }

    cancelSessionWarningModal = async () => {
        await this.setState({
            sessionWarning: false
        })
    }

    //stay signed in Modal
    getSessionWraningModal() {
        return (
            <SessionWarningModal
                openConfirmationModal={this.state.sessionWarning}
                cancelSessionWarningModal={this.cancelSessionWarningModal}
                getData={this.getKanbanCards}

            />
        )
    }

    //user status options
    getStatusOptions = async (e) => {
        let filterCriteria = {}
        if (e) {
            filterCriteria['criteria'] = [{ key: "purposeName", value: e, type: 'eq' }];
        } else {
            filterCriteria['criteria'] = [
                { key: "purposeName", value: this.state.rowData.purpose, type: 'eq' }
            ];
            let val = [];
            if (this.state.rowData.status === "Not Started" && this.state.user.isHold === "No") {
                val = ["Paused", "Completed", "Hold"]
            } else if (this.state.rowData.status === "Not Started" && this.state.user.isHold === "Yes") {
                val = ["Paused", "Completed"]
            } else if (this.state.rowData.status !== "Not Started" && this.state.user.isHold === "No") {
                val = ["Hold"]
            }
            if (val && val.length > 0) {
                let notStartStatus = {
                    key: "statusName", value: val, type: 'nin'
                }
                filterCriteria['criteria'].push(notStartStatus)
            }
        }
        await this.setState({
            isLoading: true,
            selectedPurpose: e
        })

        if (e && this.state.isShowChecklistError && this.state.errorResponse === "Please Select the purpose for the respective label") {
            await this.setState({
                isShowChecklistError: false,
                errorResponse: ""
            })

        }
        this.handlePercentage('1')
        fetch('GET', `${apiCalls.Status}?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response.statuss) {
                    let statuses = response.statuss;
                    let modifiedStatus = [];
                    for (let status of statuses) {
                        modifiedStatus.push({
                            label: status.statusName,
                            value: status.statusName,
                        })
                    }
                    await this.setState({
                        column: modifiedStatus,
                        isLoading: false
                    })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                        isLoading: false
                    })
                } else if (response && (response['errorMessage'] === configMessages.sessionWarning || response['errorMessage'] === configMessage.tokenMessage)) {
                    await this.setState({
                        sessionExpiryModal: true,
                    })
                }
            }).catch((err) => {
                return err;
            });
        if (this.props.type === 'edit') {
            filterCriteria = this.state.filterCriteria
            filterCriteria['criteria'] = [
                {
                    "key": 'labelName',
                    "value": this.state.rowData.title,
                    "type": 'eq'
                }, {
                    "key": 'dealTypeName',
                    "value": this.state.dealType,
                    "type": 'eq'
                }
            ]
            fetch('GET', `${apiCalls.TaskTemplates}?filter=${JSON.stringify(filterCriteria)}`)
                .then(async (response) => {
                    if (response && response.taskTemplates && response.taskTemplates[0]) {
                        await this.setState({
                            showFields: response.taskTemplates[0]
                        })
                        if (this.state.rowData.checkLists === null || (this.state.rowData.checkLists && this.state.rowData.checkLists.length === 0)) {
                            await this.setState({
                                checkedListPurposes: response.taskTemplates[0].purposeCheckLists
                            })
                            let purposeData = e ? e : this.state.rowData.purpose
                            await this.setState({
                                purposeList: this.state.checkedListPurposes[purposeData],
                            })
                        } else {
                            await this.setState({
                                purposeList: this.state.rowData.checkLists
                            })
                        }
                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        await this.setState({
                            sessionWarning: true,
                        })
                    } else {
                        await this.setState({
                            showFields: []
                        })
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }
    getResearchRequestType = async (e) => {
        this.setState({ researchRequestType: e })
    }

    getResearchRequestTypes = async () => {
        let StatusArray = [];
        let filterCriteria = {}
        filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
        await fetchMethodRequest('GET', `${apiCalls.ResearchRequestType}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
            if (response && response["researchRequestTypes"] && response["researchRequestTypes"].length > 0) {
                let propertyStatuses = response["researchRequestTypes"]
                for (let status of propertyStatuses) {
                    StatusArray.push({ label: status.researchRequestTypeName, value: status.researchRequestTypeName })
                }
                this.setState({ researchRequestTypes: StatusArray })
            }
        })
        return this.state.settingsData;
    }
    highestSubArray = () => {
        let k = [1, 2, 3, [1, 2, 22, 23, 32, 3211], [4, 4, 32], [3, 4, 232]]
        let high = 0;
        // let index
        for (let i in k) {
            if (typeof k[i] === 'number' && k[i] > high) {
                high = k[i]
                // index = i
            } else if (typeof k[i] === 'object') {
                let high2 = 0
                for (let item in k[i]) {
                    high2 = high2 + k[i][item]
                }
                if (high < high2) {
                    high = high2
                    // index = i
                }
            }

        }

    }

    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }

    changeNote = () => {
        this.setState({
            isnotesModal: true
        })
    }

    onShowStatus = async (e) => {
        if (e) {
            await this.setState({
                assignedValue: false,
                selectedStatus: e
            })
        }
        // if (this.state.isBussinessContain && this.props.type === "add") {
        //     this.props.change('company', '')
        // }
    }

    cancelNotes = () => {
        this.setState({
            isnotesModal: false
        })
    }

    getNotesModal() {
        return (
            <NotesModal
                openNotesModal={this.state.isnotesModal}
                cancelReset={this.cancelNotes}
                Comments={this.state.rowData.notes}
            />
        )
    }

    onChangeevent = async (value) => {
        await this.setState(prevState => ({
            timer: !prevState.timer,
            timer2: this.state.timer2 === "start" ? "stop" : "start"
        }));
        // if (this.state.isBussinessContain && this.props.type === "add") {
        //     this.props.change('company', '')
        // }
    }

    submit = async (values) => {
        await this.setState({
            ishidesave: true
        })
        let apiUrl, userBody
        if (typeof values.states === "string") {
            values.states = values.states.split(",")
        }
        let uniqueStates = [...new Set(values.states)];
        uniqueStates = this.state.states.filter(u => uniqueStates.includes(u.shortCode)).map(u => u.value)
        if ((this.props.createTask === "Research Request" && this.props.type === "add") || (this.props.createTask === "Seller Negative Notice" && this.props.type === "add") || (this.props.createTask === "Site Confirmation" && this.props.type === "add")) {
            userBody = {
                purpose: values.purpose,
                title: this.props.createTask,
                status: values.status,
                states: uniqueStates,
                company: this.state.taskestateName,
                percentageDone: this.state.done,
                timer: this.state.timer2,
                priority: values.priority,
                dealType: this.state.dealType,
                oppSize: this.state.taskoppSize,
                researchRequestType: this.state.researchRequestType,
                round: values.round,
                dueDate: values.dueDate,
                startDate: values.startDate,
                propertyCountToFile: values.propertyCountToFile,
                companyNameToResearch: values.companyNameToResearch,
                legalAction: values.legalAction,
                sellerInputNeeded: values.sellerInputNeeded,
                companyAddressToResearch: values.companyAddressToResearch,
                exhibit: values.exhibit,
                requestDescription: values.requestDescription,
                researchNotes: values.researchNotes,
                IsNewDeal: this.state.IsNewDeal === true ? "Yes" : "No",
                propertyCount: this.props.count,
                unknownPropertyCount: this.props.unknownPropertyCount,
                totalMinPropertyValue: this.props.taskminValue,
                statePropertyIDs: this.props.taskstatePropertyId,
                connectedProperties: this.props.taskopraPropertyId,
                plannedHours: values.plannedHours,
                oPRAEstateID: this.state.taskestateID,
            }
            if (values.assignTo) {
                userBody.assignTo = values.assignTo.name ? values.assignTo.name : values.assignTo
                userBody.assignImage = values.assignTo.image
                userBody.assignToId = values.assignTo.id
            }
            apiUrl = apiCalls.Tasks
        } else if ((this.props.createTask === "Research Request" && this.props.type === "addToTask") || (this.props.createTask === "Seller Negative Notice" && this.props.type === "addToTask") || (this.props.createTask === "Site Confirmation" && this.props.type === "addToTask")) {
            userBody = {
                title: this.props.createTask,
                states: uniqueStates,
                company: this.state.taskestateName,
                oppSize: this.state.taskoppSize,
                propertyCount: this.props.count,
                unknownPropertyCount: this.props.unknownPropertyCount,
                taskID: this.state.taskIdAssociated,
                totalMinPropertyValue: this.props.taskminValue,
                statePropertyIDs: this.props.taskstatePropertyId,
                connectedProperties: this.props.taskopraPropertyId,
                oPRAEstateID: this.state.taskestateID,
            }

            apiUrl = apiCalls.AddToConnectedTasks
        }
        this.sendDataToServer("POST", apiUrl, userBody)


    }
    getsubmitedFormValues = async (values) => {
        await this.setState({
            submitedValues: values,
            // ishidesave: true,
        })
    }


    saveDataFormat = async (values) => {
        let notesArr, mailedUsers = []
        let text = this.state.noteString
        if (values.notesadd && this.state.textAutoAreaSuggestions && this.state.textAutoAreaSuggestions.length) {
            for (var i = 0; i < this.state.textAutoAreaSuggestions.length; i++) {
                let result = text.includes(this.state.textAutoAreaSuggestions[i]);
                if (result === true) {
                    mailedUsers.push(this.state.textAutoAreaSuggestions[i])
                }
            }
        }
        if (this.props.type === 'edit') {
            notesArr = this.state.rowData.notes
        } else {
            notesArr = []
        }
        if (this.state.noteString !== undefined) {
            let notesObj = {
                note: this.state.noteString,
                mentions: mailedUsers
            }
            notesArr.push(notesObj)
        }
        let userBody = {
            purpose: values.purpose,
            title: values.title,
            status: values.status,
            notes: notesArr,
            states: values.states,
            company: typeof values.company === "object" && values.company.estateName ? values.company.estateName : values.company && this.props.type === "edit" ? this.state.rowData.company : values.company,
            percentageDone: this.state.done,
            timer: this.state.timer2,
            priority: values.priority,
            dealType: this.state.dealType,
            oppSize: values.oppSize,
            round: values.round,
            dueDate: values.dueDate,
            startDate: values.startDate,
            propertyCountToFile: values.propertyCountToFile,
            plannedHours: values.plannedHours,
            oPRAEstateID: this.props.type === "add" ? this.state.opraEstateID : this.state.rowData.opraEstateID ? this.state.rowData.opraEstateID : null,
        }
        if (this.props.type === "edit") {
            userBody._id = values._id
        }
        if (values.holdReason) {
            userBody.holdReason = values.holdReason
        }
        let selectedLabel = values.title
        let documents;
        if (this.state.responsesdocuments && this.state.responsesdocuments.length > 0) {
            if (this.state.documents === null) {
                this.state.documents = []
            }
            documents = [...this.state.documents, ...this.state.responsesdocuments]
        } else if (this.state.documents && this.state.documents.length > 0) {
            documents = this.state.documents
        } else {
            documents = []
        }
        userBody.documents = documents

        if (selectedLabel.includes("RFI")) {

            let stateArr = []
            if (typeof values.states === "string") {
                stateArr.push(values.states)
                userBody.states = stateArr
            } else {
                userBody.states = values.states
            }
            userBody.claimID = values.claimID
            userBody.reasons = values.reasons
            userBody.valueRFI = values.valueRFI
            userBody.propertyCountRFI = values.propertyCountRFI
        }
        userBody.isClaimed = this.state.IsClaimed === true ? "Yes" : "No"
        if (this.props.type === "add") {
            userBody.IsNewDeal = this.state.IsNewDeal === true ? "Yes" : "No"
        }

        if (this.state.tempCheckList && this.state.tempCheckList.length > 0) {
            userBody.checkLists = JSON.parse(this.state.tempCheckList)
        } else {
            if (this.state.checklistStatus !== "Not Started")
                userBody.checkLists = this.state.purposeList
        }
        let method, apiUrl
        if (this.props.type === 'edit' && this.state.activeTab !== '3') {
            method = 'PUT'
            apiUrl = `tasks/${this.state.rowData._id}`;
        } else {
            method = 'POST'
            apiUrl = apiCalls.TasksGrid
        }



    }


    successSubmit = async (method, apiUrl, userBody) => {
        // if (userBody.title === "" || userBody.purpose === "" || userBody.priority === "" || userBody.company === "" || userBody.dealType === "" || userBody.status === "" || userBody.assignTo === "" || userBody.oppSize === "") {
        //     await this.setState({
        //         isShowChecklistError: true,
        //         errorResponse: "Please fill the Mandatory fields before saving the record"
        //     })

        // } else if (userBody.title.includes("RFI") && userBody.states === "") {
        //     await this.setState({
        //         isShowChecklistError: true,
        //         errorResponse: "Please fill the Mandatory fields before saving the record"
        //     })
        // } else {
        await this.setState({
            issplitbyStatus: false,
            isShowChecklistError: this.props.type === "add" ? true : false,
        })

        this.sendDataToServer(method, apiUrl, userBody)
        // }
    }

    sendDataToServer = async (method, url, body) => {
        await this.setState({
            method: method,
            apiUrl: url,
            userBody: body,
        })

        this.handlePercentage('1')
        return fetch(method, url, body)
            .then(async (response) => {

                if (response && response.respCode && (response.respCode === 204 || response.respCode === 200 || response.respCode === 205)) {
                    // display message
                    // showToasterMessage(response.respMessage, 'success');
                    if (method === "PUT" && this.state.rowData.subTaskCount === 0 && this.state.rowData.status === "Not Started" && body.status === "In Progress" && body.title === "Recovery Planning" && !this.state.isChildTrue) {
                        this.getErrorMessage(response.respMessage)
                        await this.setState({
                            hideUpdatebtn: true
                        })
                    } else {
                        this.props.reset();
                        this.props.cancelReset();
                    }
                } else if (response && (response.errorMessage && response.errorMessage === config.tokenMessage || response.errorMessage === config.sessionExpired)) {
                    await this.setState({
                        sessionExpiryModal: true
                    })
                } else if (response && response.errorMessage) {
                    await this.setState({
                        ishidesave: false,
                    })
                    this.getErrorMessage(response.errorMessage)
                }
                this.setState({
                    isLoading: false,
                })
                if (this.props.filterData) {
                    this.props.getKanbanCards(this.props.filterData)
                } else {
                    this.props.getKanbanCards()
                }
            }).catch((err) => {
                return err;
            });

    }

    cancelReset = async () => {
        this.props.load({})
        await this.props.reset();
        await this.props.cancelReset();
        if (this.props.filterData) {
            this.props.getKanbanCards(this.props.filterData)
        } else {
            this.props.getKanbanCards()
        }
    }

    handleComments = (e) => {
        this.setState({
            noteString: e
        })
        // if (this.state.isBussinessContain && this.props.type === "add") {
        //     this.props.change('company', '')
        // }
    }

    //changing PurposeList
    onChangeCheckListStatus = async (e, item, index) => {
        // if (this.state.checklistStatus !== "Not Started")
        let purposeList = JSON.parse(JSON.stringify(this.state.purposeList))
        for (let obj of purposeList) {
            if (obj.processName === item.processName) {
                obj.status = item.status ? false : true
            }
        }
        await this.setState({
            purposeList: purposeList,
            tempCheckList: JSON.stringify(purposeList),
        })
    }

    showdonepercentbtn = async (e, done) => {
        e.preventDefault();
        await this.setState(prevState => ({
            show25percentbtn: !prevState.show25percentbtn,
            done: done
        }));
        if (this.state.show25percentbtn === true) {
            await this.setState({
                done: done
            })
        }
    }

    handleCheck = async (dealType) => {
        await this.setState({
            dealType: dealType
        })
        // if (this.state.isBussinessContain && this.props.type === "add") {
        //     this.props.change('company', '')
        // }
    }

    onchangeStatus = async (e) => {
        await this.setState({
            checklistStatus: e
        })
        // if (this.state.isBussinessContain && this.props.type === "add") {
        //     this.props.change('company', '')
        // }
    }
    setSelectedTask = async (e) => {
        // e = 12345
        let previousTaskId = this.props.previousTaskId
        if (previousTaskId.includes(e)) {

            this.props.cancelCreateTaskModal(e)
        } else {
            await this.setState({ taskIdAssociated: e })
        }
    }
    getTasksAssociated = async (e) => {
        let tasksAssociated = []
        let body = {
            label: e,
            EstateID: this.props.estateDetails.EstateID
        }
        fetch('POST', `${apiCalls.ConnectedTasks}`, body)
            .then(async (response) => {
                if (response && response.tasks && response.tasks.length > 0) {
                    for (let task of response.tasks) {
                        if (this.props.createTask === "Research Request") {
                            tasksAssociated.push({ label: task.taskID + " - " + task.companyNameToResearch, value: task.taskID })
                        } else if (this.props.createTask === "Site Confirmation") {
                            tasksAssociated.push({ label: `${task.taskID} - ${dateFormats.formatDate(task.created, "MM/DD/YYYY")}`, value: task.taskID })
                        } else {
                            tasksAssociated.push({ label: `${task.taskID} - ${dateFormats.formatDate(task.created, "MM/DD/YYYY")}`, value: task.taskID })


                        }
                    }
                    await this.setState({ tasksAssociated: tasksAssociated })
                }
            }).catch((err) => {
                return err;
            });

    }
    //get Purpose Options  and Task Template call
    getBusinessNamePurposes = async (e) => {
        // if (this.props.type === "edit") {
        //     await this.setState({
        //         isTabdisabled: (e && e !== "Recovery Planning") ? true : false,
        //         isShowisClaimed: (e === "Transition") ? true : false
        //     })
        // }
        let filterCriteria = {}
        // if (e) {
        //     await this.setState({
        //         selectedLabel: e,
        //     })
        filterCriteria['criteria'] = [{ key: "labelName", value: e, type: 'eq' }, { key: "activeIndicator", value: true, type: "eq" }];
        // } else {
        //     if (this.props.type === "edit" && this.state.rowData && this.state.rowData.title) {
        //         filterCriteria['criteria'] = [{ key: "labelName", value: this.state.rowData.title, type: 'eq' }, { key: "activeIndicator", value: true, type: "eq" }];
        //     }
        // }
        // if (this.props.type === "add" && e && e === "Transition") {
        //     this.onChangeIsNewDeal();
        // }
        // if (this.state.isBussinessContain && this.props.type === "add") {
        //     this.props.change('company', '')
        // }
        // this.props.change('purpose', '')
        fetch('GET', `${apiCalls.Purposes}?filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response.purposes) {
                    let apps = response.purposes;
                    let modifiedApps = [];
                    for (let app of apps) {
                        modifiedApps.push({
                            label: app.purposeName,
                            value: app.purposeName,
                        })
                    }
                    this.props.change("purpose", apps[0].purposeName)
                    await this.setState({
                        Purpose: modifiedApps,
                        isShowisClaimed: e === "Transition" ? true : false
                    })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                    })
                } else if (response && response['errorMessage'] === "Session expired please login again.") {
                    await this.setState({
                        sessionExpired: true,
                    })
                }
            }).catch((err) => {
                return err;
            });

    }

    handletab2State = async (e) => {
        let selectedStates = []
        if (e === "States") {
            if (this.state.rowData.states && this.state.rowData.states.length > 0) {
                this.state.rowData.states.forEach(item => {
                    selectedStates.push({
                        label: item,
                        value: item,
                        // show: true
                    })
                })
            }
            let datastates = this.state.states
            selectedStates.forEach(item => {
                for (var i = 0; i < datastates.length; i++) {
                    if (datastates[i].label === item.label) {
                        datastates.splice(i, 1);
                        break;
                    }
                    this.setState({
                        states: datastates
                    })
                }
            })
            await this.setState({
                statestab2Data: datastates,
                hideSplitBtn: false,
                isSplittedData: [],
            })
        } else {
            this.setState({
                isLoading: true,
                selectedStates: [],
            })
            selectedStates.push({
                label: this.state.rowData.company,
                value: this.state.rowData.company,
                show: false
            })
            this.handlePercentage('1')
            let str = this.state.rowData.company
            let data = encodeURIComponent(str)
            fetch('GET', `businessNames/estatesforsplit?str=${data}`)
                .then(async (response) => {
                    if (response && response.estateNames) {
                        let bussinessNames = response.estateNames;
                        let modifiedNames = [];
                        for (let app of bussinessNames) {
                            if (app.BusinessName_Text !== this.state.rowData.company) {
                                modifiedNames.push({
                                    label: app.BusinessName_Text,
                                    value: app.BusinessName_Text,
                                    show: false
                                })
                            }
                        }
                        await this.setState({
                            statestab2Data: modifiedNames,
                            isLoading: false,
                            hideSplitBtn: false,
                            isSplittedData: [],
                            getValues10: []
                        })
                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        await this.setState({
                            sessionWarning: true,

                        })
                    }
                }).catch((err) => {
                    return err;
                });
        }
        let states2 = this.state.states;
        await this.setState({
            isSplitBy: e,
            selectedStates, selectedStates,
            states: states2,
            isSplittedData: [],
            isSelectAll: false,
            issplitAll: false,
            ismoveAll: false,
            ismoveAlltoleft: false,
        })
    }

    submitsplitTask = async () => {
        let userBody = {
            splitBy: this.state.isSplitBy === "States" ? "state" : "name",
            isSplitAll: this.state.issplitAll
        }
        let unSelectedStates = [];
        let selectedStates = [];
        let unselect_list = [];
        let select_list = [];
        let data = this.state.isSplittedData
        if (this.state.isSplittedData && this.state.isSplittedData.length > 0) {
            unSelectedStates = data.filter(function (el) {
                return el.show === false
            });
            selectedStates = data.filter(function (el) {
                return el.show === true
            });
            for (var obj of unSelectedStates) {
                unselect_list.push(obj.label)
            }
            for (var obj2 of selectedStates) {
                select_list.push(obj2.label)
            }
            if (this.state.isSplitBy === "States") {
                userBody.unSelectedStates = unselect_list;
                userBody.selectedStates = select_list;
            } else {
                userBody.unSelectedNames = unselect_list;
                userBody.selectedNames = select_list;
            }
        }
        await this.setState({ splitBody: userBody })
        if (this.state.issplitAll) {
            await this.setState({ splitbyeachCard: true, apierrorResponse: false, spliterrorMessage: `Are you sure want to split one task per ${this.state.isSplitBy === "States" ? "State" : "Name"}?`, hideSplitBtn: false })
        }
        else if (userBody && select_list.length > 0 && unselect_list.length === 0) {
            await this.setState({ splitbyeachCard: true, apierrorResponse: false, spliterrorMessage: `Are you sure want to create one task with all Selected ${this.state.isSplitBy === "States" ? "States" : "Names"} ?`, hideSplitBtn: false })
        }
        else if (userBody && unselect_list.length > 0 && select_list.length === 0) {
            await this.setState({ splitbyeachCard: true, apierrorResponse: false, spliterrorMessage: `Are you sure want to create one task with all Unselected ${this.state.isSplitBy === "States" ? "States" : "Names"} ?`, hideSplitBtn: false })
        }
        else if (userBody && (select_list.length > 0 && unselect_list.length > 0)) {
            await this.setState({ splitbyeachCard: true, apierrorResponse: false, spliterrorMessage: `Are you sure want to create one task with Selected ${this.state.isSplitBy === "States" ? "States" : "Names"} and one with Unselected ${this.state.isSplitBy === "States" ? "States" : "Names"}?`, hideSplitBtn: false })
        }
        else {
            await this.setState({ hideSplitBtn: false })
            this.issplitSucces()
        }
    }

    issplitSucces = async () => {
        await this.setState({ isLoading: true, splitbyeachCard: false })
        this.handlePercentage('1')
        return fetch('POST', `tasks/split/${this.state.rowData._id}`, this.state.splitBody)
            .then(async (response) => {
                if (response && response.respMessage) {
                    // this.cancelReset()
                    await this.setState({ splitbyeachCard: false })
                    this.getErrorMessage(response.respMessage)
                } else if (response && response.errorMessage) {
                    await this.setState({ splitbyeachCard: false })
                    this.getErrorMessage(response.errorMessage)
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                    })
                }
                await this.setState({ isLoading: false })
            }).catch((err) => {
                return err;
            });
    }

    getErrorMessage = (errorMessage) => {
        this.setState({
            apierrorMessage: errorMessage.replace("\r\n", ""),
            apierrorResponse: true
        })

    }
    // getoppsize = async (e) => {
    //     let url = `businessnames/estateNames`;
    //     let userBody = {
    //         estateID: typeof e.EstateID === "string" ? e.EstateID : JSON.stringify(e.EstateID)
    //     }
    //     return fetch('POST', url, userBody)
    //         .then((response) => {
    //             if (response) {
    //                 this.setState({ taskestateID: response.estatesOwnedFrom2021[0].estateID, taskestateName: response.estatesOwnedFrom2021[0].estateName, taskoppSize: response.estatesOwnedFrom2021[0].oppSize })
    //             }
    //         }).catch((err) => {
    //             return err;
    //         });
    // }
    getoppsize = async (e) => {
        let url = `properties/DealsDropdown?dealID=${typeof e.EstateID === "string" ? e.EstateID : JSON.stringify(e.EstateID)}`;
        let userBody = {
            estateID:
                typeof e.EstateID === "string"
                    ? e.EstateID
                    : JSON.stringify(e.EstateID),
        };
        return fetch("GET", url)
            .then((response) => {
                if (response) {
                    this.setState({
                        taskestateID: response.sellers[0].EstateID,
                        taskestateName: `${response.sellers[0].DealType} - ${response.sellers[0].SellerNickName} (${response.sellers[0].DealYear ? response.sellers[0].DealYear : ""})`,
                        taskoppSize: response.sellers[0].oppSize,
                    });
                }
            })
            .catch((err) => {
                return err;
            });
    };

    handleEstateNames = async (e, name, validate) => {
        let filterCriteria = {}
        let index
        if (e && typeof e === "string") {
            index = validate.findIndex(a1 => a1.estateName === e)
            if (index !== -1) e = validate[index]
            this.props.change('oppSize', "")
            await this.setState({
                oppSize: false
            })
        }
        if (e && e.oppSize) {
            this.props.change('oppSize', e.oppSize)
            await this.setState({
                opraEstateID: e.estateID,
                oppSize: true
            })
        }
        if (e && e.estateName) {
            let val = encodeURIComponent(e.estateName)
            filterCriteria['criteria'] = [{ key: "company", value: val, type: 'eq' }];
            let apiUrl;
            if (this.props.unassignedOnly) {
                apiUrl = `${apiCalls.Tasks}?filter=${JSON.stringify(filterCriteria)}&check=true`
            } else {
                apiUrl = `${apiCalls.Tasks}?filter=${JSON.stringify(filterCriteria)}`
            }

            fetch('GET', apiUrl)
                .then(async (response) => {
                    if (response) {
                        let estate = response.tasks;
                        if (estate && estate.length > 0) {
                            await this.setState({
                                isShowChecklistError: true,
                                errorResponse: "Task already assigned for this Estate",
                                isBussinessContain: false
                            })
                        } else {
                            await this.setState({
                                isShowChecklistError: false,
                                errorResponse: "",
                                isBussinessContain: false
                            })
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        } else {
            if (index === -1) {
                await this.setState({
                    isBussinessContain: true
                })
            }
        }
    }

    onChangeClaimed = (e) => {
        this.setState({ IsClaimed: this.state.IsClaimed === true ? false : true })
    }
    onChangeIsNewDeal = (e) => {
        this.setState({ IsNewDeal: this.state.IsNewDeal === true ? false : true })
    }

    getFileName = async (responses, type) => {
        var responsesdocuments = [];
        for (var file of responses) {
            responsesdocuments.push(file.fileName)
        }
        await this.setState({
            responsesdocuments: responsesdocuments,
            isDisableUpload: false,
            isLoading: false
        });
        if (this.state.submitedValues && Object.keys(this.state.submitedValues).length > 0) {
            this.saveDataFormat(this.state.submitedValues)
        }
    }

    uploadProgress = async (value) => {
        await this.setState({ isLoading: value })
        if (value && value === true) {
            this.handlePercentage('1')
        }
    }
    deleteImagesCall = async (values, type) => {
        await this.setState({ isLoading: true })
        this.handlePercentage('1')
        let body = {}
        if (type) {
            body.fileNames = values
            await this.setState({ isLoading: false, responsesdocuments: [] })
        } else {
            body.fileNames = this.state.removedFiles
        }

        fetch('POST', 'uploads/delete?uploadPath=task', body)
            .then(async (response) => {
                if (response && response.respCode) {
                    if (!type) this.saveDataFormat(values)
                } else if (response && response.errorMessage) {
                    this.getErrorMessage(response.errorMessage)
                }
            }).catch((err) => {
                return err;
            });
    }

    onCancelDocuments = async () => {
        let responseDocuments = this.state.responsesdocuments
        if (responseDocuments && responseDocuments.length > 0) {
            this.deleteImagesCall(responseDocuments, 'cancelDocuments')
        }
    }

    handleDueDateMin = async (e) => {
        await this.setState({ minDatefortask: e })
    }

    removeMultipleUploadedImages = async (item, index) => {
        var removedFiles = this.state.removedFiles;
        var documents = this.state.documents;
        removedFiles.push(item);
        documents.splice(index, 1);
        await this.setState({
            documents: documents,
            removedFiles: removedFiles,
        })

    }
    getModalBody(handleSubmit) {
        const { type, view } = this.props
        const { showFields, assignedValue, rowData } = this.state
        const user = JSON.parse(localStorage.getItem('loginCredentials'));
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return (
            <div>
                <form className="form" onSubmit={handleSubmit(this.submit)}>
                    <ModalBody className={type === 'edit' ? "modal__body edit_modal" : "modal__body kanbanmodalCardBody mb-0 pt-0 "}>
                        <Card className='pb-0 '>
                            <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />
                            <CardBody className=' p-0'
                            >
                                <div className='row mx-1 mt-3 col-sm-12 pl-0 pr-0'>
                                    {this.state.isShowChecklistError ?
                                        <div className="m-auto ml-0 mb-4" style={{ color: "red", textAlign: "center" }}>{this.state.errorResponse}
                                        </div> : ""}

                                    <div className="m-auto ml-0 mb-4" style={{ color: "red", textAlign: "center" }}>{this.state.redirectSellerId !== null && this.state.negativePeriod !== null ? <span>This deal has negative notice period of <u><a className='px-1' target="_blank" style={{ textDecoration: "underline" }}
                                        href={config.negativeNoticeUrl}>{this.state.negativePeriod}</a></u></span> : null}
                                    </div>
                                </div>
                                {(this.props.createTask === "Research Request" && this.props.type === "add") || (this.props.createTask === "Seller Negative Notice" && this.props.type === "add") || (this.props.createTask === "Site Confirmation" && this.props.type === "add") ?
                                    <div className='row mx-1 mt-3 col-sm-12 pl-0 pr-0'>
                                        <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.dealType === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <span className="d-flex">
                                                    <label className="form__form-group-label mr-auto" style={{ width: "100%" }}>Deal Type{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>

                                                    <label className='d-flex mb-0' style={{ width: "100%", justifyContent: "end" }}>Is New Deal
                                                        <input
                                                            type="checkbox"
                                                            style={{ marginTop: 3, height: 13 }}
                                                            className='col-1 mr-0 ml-2'
                                                            name="IsNewDeal"
                                                            data-toggle="tool-tip"
                                                            label="IsNewDeal"
                                                            disabled={this.props.type === "edit" ? true : false}
                                                            title={"Is New Deal"}
                                                            component={renderCheckBoxField}
                                                            checked={this.state.IsNewDeal}
                                                            onChange={(e) => this.onChangeIsNewDeal(e)}
                                                        /></label>

                                                </span></div>
                                            <div className=" col-sm-12 d-flex pl-0">
                                                <Field
                                                    id='BK'
                                                    name='dealType'
                                                    label="BK"
                                                    value="BK"
                                                    radioValue="BK"
                                                    onChange={() => this.handleCheck('BK')}
                                                    component={renderRadioButtonField}
                                                    validate={required}
                                                    showError={true}
                                                    defaultChecked={this.state.rowData && this.state.rowData.dealType === "BK" ? true : false}
                                                    checked={this.state.rowData && this.state.rowData.dealType === "BK" ? true : false}
                                                    disabled={view === "loggedThroughmail" || type === "edit" ? true : false}

                                                />
                                                <Field
                                                    id='CR'
                                                    name='dealType'
                                                    label="CR"
                                                    value="CR"
                                                    radioValue="CR"
                                                    validate={required}
                                                    onChange={() => this.handleCheck('CR')}
                                                    component={renderRadioButtonField}
                                                    defaultChecked={this.state.rowData && this.state.rowData.dealType === "CR" ? true : false}
                                                    checked={this.state.rowData && this.state.rowData.dealType === "CR" ? true : false}
                                                    disabled={view === "loggedThroughmail" || type === "edit" ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.title === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-2">
                                                <label className="form__form-group-label">Task Label{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className=' form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="taskLabel"
                                                        component={DefaultInput}
                                                        placeholder='Task Label'
                                                        value={this.props.createTask}
                                                        // validate={[required]}
                                                        isDisable={true}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.props.createTask === "Research Request" ? <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.purpose === "show")) ? { display: "block" } : { display: "none" }}>
                                                <div className="form__form-group pb-1">
                                                    <span className="d-flex">
                                                        <label className="form__form-group-label mr-auto">Research Request Type</label>

                                                    </span>
                                                    <div className='form__form-group-field'>
                                                        <Field className='inputLogin'
                                                            type={'dropDown'}
                                                            name="researchRequestType"
                                                            component={MultiSelect}
                                                            filterElement={this.state.researchRequestTypes}
                                                            filter={true}
                                                            placeholder='Research RequestType'
                                                            onChange={(e) => this.getResearchRequestType(e)}

                                                        // isDisable={(view === "loggedThroughmail") || (rowData && rowData.assignTo === "Unassigned") || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin")) ? true : false}

                                                        />
                                                        {/* <Field className='inputLogin'
                                                            type={'text'}
                                                            name="researchRequestType"
                                                            // defaultValue={this.state.selectedPurpose}
                                                            component={Select}

                                                            options={this.state.researchRequestTypes}
                                                            placeholder='Research RequestType'
                                                            onChange={(e) => this.getResearchRequestType(e)}
                                                        /> */}
                                                    </div>
                                                </div>
                                            </div>
                                                : null
                                        }
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.purpose === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <span className="d-flex">
                                                    <label className="form__form-group-label mr-auto">Purpose{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>

                                                </span>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="purpose"
                                                        // defaultValue={this.state.selectedPurpose}
                                                        component={Select}
                                                        validate={[required]}
                                                        options={this.state.Purpose}
                                                        placeholder='Purpose'
                                                        onChange={(e) => this.getStatusOptions(e)}
                                                        isDisable={view === "loggedThroughmail" || rowData && rowData.assignTo === "Unassigned" || (type === "edit" && !this.state.disabledField) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.company === "show")) ? { display: "block" } : { display: "none" }}>
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Business/Estate{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                className='pl-1'
                                                color='red'
                                                data-toggle="tool-tip"
                                                title="Mandotary Field"
                                                style={{ width: 8 }}
                                            /> : ''}</label>
                                            <div className='form__form-group-field'>
                                                <Field className={this.props.type !== "edit" ? 'EstateName' : ""}
                                                    type={'text'}
                                                    name="company"
                                                    component={AutoComplete}
                                                    // onChange={(e) => this.handleEstateNames(e)}
                                                    handleEstateNames={this.handleEstateNames}
                                                    validate={required}
                                                    placeholder='Business/Estate Name'
                                                    disabled={view === "loggedThroughmail" || type === "edit" ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.title === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-2">
                                                <label className="form__form-group-label">Deal{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className=' form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="company"
                                                        component={DefaultInput}
                                                        placeholder='Deal'
                                                        value={this.props.createTask}
                                                        // validate={[required]}
                                                        isDisable={true}

                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.states === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">State Reported{this.state.selectedLabel.includes("RFI") === true ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className='form__form-group-field '>

                                                    <Field className='States'
                                                        type={'dropDown'}
                                                        name="states"
                                                        validate={[required]}
                                                        component={Select}
                                                        options={this.state.states}
                                                        // value={this.state.selectedStates}
                                                        // filter={true}
                                                        placeholder='Select State'
                                                        isDisable={true}

                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.status === "show")) ? { display: "block" } : { display: "none" }}>
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Status{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                className='pl-1'
                                                color='red'
                                                data-toggle="tool-tip"
                                                title="Mandotary Field"
                                                style={{ width: 8 }}
                                            /> : ''}</label>
                                            <div className='form__form-group-field'>
                                                <Field
                                                    name="status"
                                                    component={Select}
                                                    options={this.state.column}
                                                    validate={[required]}
                                                    placeholder='Select Status'
                                                    onChange={(e) => this.onchangeStatus(e)}
                                                    isDisable={view === "loggedThroughmail" || rowData && rowData.assignTo === "Unassigned" || type === "add" || (type === "edit" && (assignedValue) || (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                />

                                            </div>
                                        </div>
                                    </div> */}

                                        <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.priority === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Priority{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className='form__form-group-field '>
                                                    <Field className='States'
                                                        type={'dropDown'}
                                                        name="priority"
                                                        component={Select}
                                                        validate={[required]}
                                                        options={this.state.priority}
                                                        placeholder='Select Priority'
                                                        isDisable={view === "loggedThroughmail" || (rowData && rowData.assignTo === "Unassigned") || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* {type === 'edit' && this.state.checklistStatus === "Hold" ?
                                        <div className={"col-sm-6"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Hold Reason{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className='form__form-group-field'>
                                                    <Field
                                                        name="holdReason"
                                                        component={Select}
                                                        options={config.holdReasonOptions}
                                                        validate={[required]}
                                                        placeholder='Select Hold Reason'
                                                        // onChange={(e) => this.onchangeStatus(e)}
                                                        isDisable={view === "loggedThroughmail" || rowData && rowData.assignTo === "Unassigned" || type === "add" || (type === "edit" && (assignedValue) || (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        : null} */}
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.assignTo === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Assign To{type === "edit" ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="assignTo"
                                                        component={Select}
                                                        validate={type === "edit" ? [required] : null}
                                                        onChange={(e) => this.onShowStatus(e)}
                                                        options={this.state.assignedUsers}
                                                        placeholder='Select User'
                                                        isDisable={view === "loggedThroughmail" || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.props.createTask === "Research Request" ?
                                                <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} >
                                                    <div className="form__form-group pb-2">
                                                        <label className="form__form-group-label">Company Name to Research{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                            className='pl-1'
                                                            color='red'
                                                            data-toggle="tool-tip"
                                                            title="Mandotary Field"
                                                            style={{ width: 8 }}
                                                        /> : ''}</label>
                                                        <div className=' form__form-group-field'>
                                                            <Field className='inputLogin'
                                                                name="companyNameToResearch"
                                                                component={DefaultInput}
                                                                placeholder='Company Name to Research'
                                                                validate={[required]}
                                                            // isDisable={true}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        {
                                            this.props.createTask === "Research Request" ?
                                                <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} >
                                                    <div className="form__form-group pb-2">
                                                        <label className="form__form-group-label">Company Addresses to Research</label>
                                                        <div className=' form__form-group-field'>
                                                            <Field className='inputLogin'
                                                                name="companyAddressToResearch"
                                                                component={DefaultInput}
                                                                placeholder='Company Addresses to Research'
                                                            // validate={[required]}
                                                            // isDisable={true}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}



                                        {
                                            this.props.createTask === "Research Request" ?
                                                <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} >
                                                    <div className="form__form-group pb-2">
                                                        <label className="form__form-group-label">Exhibit{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                            className='pl-1'
                                                            color='red'
                                                            data-toggle="tool-tip"
                                                            title="Mandotary Field"
                                                            style={{ width: 8 }}
                                                        /> : ''}</label>
                                                        <div className=' form__form-group-field'>
                                                            <Field className='inputLogin'
                                                                name="exhibit"
                                                                component={DefaultInput}
                                                                placeholder="Exhibit"
                                                            // validate={[required]}
                                                            // isDisable={true}

                                                            />
                                                        </div>
                                                    </div>
                                                </div> : null}

                                        {/* <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" && this.state.dealType === "CR" || (type === "edit" && this.state.rowData.dealType === "CR") ? { display: "block" } : { display: "none" }}>
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Round</label>
                                            <div className='form__form-group-field'>
                                                <Field className='inputLogin'
                                                    name="round"
                                                    component={Select}
                                                    options={this.state.selectRoundOptions}
                                                    placeholder='Select Round'
                                                    isDisable={view === "loggedThroughmail" || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.oppSize === "show")) ? { display: "block" } : { display: "none" }}>
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Opp Size{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                className='pl-1'
                                                color='red'
                                                data-toggle="tool-tip"
                                                title="Mandotary Field"
                                                style={{ width: 8 }}
                                            /> : ''}</label>
                                            <div className='form__form-group-field'>
                                                <Field className='inputLogin'
                                                    name="oppSize"
                                                    component={Select}
                                                    validate={[required]}
                                                    // onChange={(e) => this.onShowStatus(e)}
                                                    options={this.state.selectOppSizeOptions}
                                                    placeholder='Select Opp Size'
                                                    isDisable={view === "loggedThroughmail" || this.state.oppSize || rowData.oppSize || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.startDate === "show")) ? { display: "block" } : { display: "none" }}>
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Start Date</label>
                                            <div className='form__form-group-field'>
                                                <Field
                                                    id={true}
                                                    name={"startDate"}
                                                    component={DatePicker}
                                                    type={"text"}
                                                    value={rowData.startDate && rowData.startDate !== null ? rowData.startDate : new Date()}
                                                // minDate={new Date()}
                                                // onChange={() => this.handleDueDateMin()}
                                                // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                // placeholder={item.placeholder}
                                                // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.dueDate === "show")) ? { display: "block" } : { display: "none" }}>
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Due Date</label>
                                            <div className='form__form-group-field'>
                                                <Field
                                                    id={true}
                                                    name={"dueDate"}
                                                    component={DatePicker}
                                                    type={"text"}
                                                    value={rowData.dueDate && rowData.dueDate !== null ? rowData.dueDate : new Date()}
                                                // minDate={this.state.minDatefortask ? this.state.minDatefortask : new Date()}
                                                // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                // placeholder={item.placeholder}
                                                // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.plannedHours === "show")) ? { display: "block" } : { display: "none" }}>
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Planned Hours</label>
                                            <div className='form__form-group-field'>
                                                <Field
                                                    id={true}
                                                    name={"plannedHours"}
                                                    component={DefaultInput}
                                                    type={"text"}
                                                    value={rowData.plannedHours && rowData.plannedHours !== null ? rowData.plannedHours : ""}
                                                    minDate={new Date()}
                                                // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                // placeholder={item.placeholder}
                                                // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Property Count</label>
                                                <div className='form__form-group-field'>
                                                    <Field
                                                        id={true}
                                                        name={"propertyCount"}
                                                        component={DefaultInput}
                                                        type={"text"}
                                                        isDisable={true}

                                                    // value={rowData.propertyCountToFile && rowData.propertyCountToFile !== null ? rowData.propertyCountToFile : ""}
                                                    // minDate={new Date()}
                                                    // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                    // placeholder={item.placeholder}
                                                    // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.props.createTask === "Research Request" ?
                                                <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                                    <div className="form__form-group pb-1">
                                                        <label className="form__form-group-label">Count of Unknown Properties</label>
                                                        <div className='form__form-group-field'>
                                                            <Field
                                                                id={true}
                                                                name={"unknownPropertyCount"}
                                                                component={DefaultInput}
                                                                type={"text"}
                                                                isDisable={true}

                                                            // value={rowData.propertyCountToFile && rowData.propertyCountToFile !== null ? rowData.propertyCountToFile : ""}
                                                            // minDate={new Date()}
                                                            // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                            // placeholder={item.placeholder}
                                                            // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> : null}

                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">State Property IDs</label>
                                                <div className='form__form-group-field'>
                                                    <Field
                                                        id={true}
                                                        name={"statePropertyIDs"}
                                                        component={DefaultTextArea}
                                                        type={"text"}
                                                        isDisable={true}

                                                    // value={rowData.propertyCountToFile && rowData.propertyCountToFile !== null ? rowData.propertyCountToFile : ""}
                                                    // minDate={new Date()}
                                                    // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                    // placeholder={item.placeholder}
                                                    // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Total Min Property Value </label>
                                                <div className='form__form-group-field'>
                                                    <Field
                                                        id={true}
                                                        name={"totalMinPropertyValue"}
                                                        component={DefaultInput}
                                                        isDisable={true}

                                                        type={"text"}
                                                    // value={rowData.propertyCountToFile && rowData.propertyCountToFile !== null ? rowData.propertyCountToFile : ""}
                                                    // minDate={new Date()}
                                                    // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                    // placeholder={item.placeholder}
                                                    // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.props.createTask === "Research Request" ?
                                                <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                                    <div className="form__form-group pb-1">
                                                        <label className="form__form-group-label">Request Description </label>
                                                        <div className='form__form-group-field'>
                                                            <Field
                                                                id={true}
                                                                name={"requestDescription"}
                                                                component={DefaultTextArea}
                                                                type={"textarea"}
                                                            // value={rowData.propertyCountToFile && rowData.propertyCountToFile !== null ? rowData.propertyCountToFile : ""}
                                                            // minDate={new Date()}
                                                            // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                            // placeholder={item.placeholder}
                                                            // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        {
                                            this.props.createTask === "Research Request" ?
                                                <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                                    <div className="form__form-group pb-1">
                                                        <label className="form__form-group-label">Request Notes </label>
                                                        <div className='form__form-group-field'>
                                                            <Field
                                                                id={true}
                                                                name={"researchNotes"}
                                                                component={DefaultTextArea}
                                                                type={"textarea"}
                                                            // value={rowData.propertyCountToFile && rowData.propertyCountToFile !== null ? rowData.propertyCountToFile : ""}
                                                            // minDate={new Date()}
                                                            // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                            // placeholder={item.placeholder}
                                                            // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> : null}
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Connected OPRA Property IDs</label>
                                                <div className='form__form-group-field'>
                                                    <Field
                                                        id={true}
                                                        name={"connectedProperties"}
                                                        component={DefaultTextArea}
                                                        isDisable={true}
                                                        type={"text"}
                                                    // value={rowData.propertyCountToFile && rowData.propertyCountToFile !== null ? rowData.propertyCountToFile : ""}
                                                    // minDate={new Date()}
                                                    // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                    // placeholder={item.placeholder}
                                                    // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* {this.state.selectedLabel.includes("RFI") === true ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Claim ID
                                                    <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /></label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="claimID"
                                                        component={DefaultInput}
                                                        placeholder='Claim ID'
                                                        validate={[required]}
                                                        isDisable={view === "loggedThroughmail" || rowData.claimID || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}

                                                    />
                                                </div>
                                            </div>
                                        </div> : null} */}
                                        {/* {this.state.selectedLabel.includes("RFI") === true ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Number of properties in RFI<FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /></label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="propertyCountRFI"
                                                        component={DefaultInput}
                                                        placeholder='Number of Properties'
                                                        isDisable={view === "loggedThroughmail" || (rowData && rowData.assignTo === "Unassigned") || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}

                                                        validate={[required]}
                                                        normalize={normalizenumber}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null} */}
                                        {/* {this.state.selectedLabel.includes("RFI") === true ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Value
                                                </label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="valueRFI"
                                                        component={DefaultInput}
                                                        isDisable={view === "loggedThroughmail" || (rowData && rowData.assignTo === "Unassigned") || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                        placeholder='RFI Value'
                                                    // validate={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null} */}
                                        {/* {this.state.selectedLabel.includes("RFI") === true ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Reason(s)<FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /></label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="reasons"
                                                        type={'dropDown'}
                                                        component={MultiSelect}
                                                        filterElement={config.RFIReasons}
                                                        placeholder='RFI Reason'
                                                        filter={true}
                                                        disabled={view === "loggedThroughmail" || (rowData && rowData.assignTo === "Unassigned") || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                        validate={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null} */}

                                        {/* <div className={type === 'edit' ? "col-12 pr-0 row" : "col-12 pl-0 pr-0"} >
                                        {type === 'edit' ?
                                            <div className='col-sm-6 ' style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.notesHistory === "show")) ? { display: "block" } : { display: "none" }}>
                                                <div className="form__form-group" >
                                                    <div className="d-flex" style={{ alignItems: "center" }}>
                                                        <label className="form__form-group-label mr-auto">Notes History ({this.state.comments && this.state.comments.length > 0 ? this.state.comments.length : "0"})</label>
                                                        {this.state.comments.length > 0 ?
                                                            <span className="text-right" style={{ display: "grid" }}>
                                                                <FontAwesomeIcon icon={faExternalLinkAlt}
                                                                    className='pl-1'
                                                                    data-toggle="tool-tip"
                                                                    validate={[required]}
                                                                    title="click to see all Notes"
                                                                    style={{ width: 20, color: "grey" }}
                                                                    onClick={this.changeNote}
                                                                />
                                                            </span>
                                                            : null}
                                                    </div>
                                                    <div className='ml-0 mr-0'>
                                                        <div className='usermessagesText d-block' style={{ minHeight: "95px" }}>
                                                            {this.state.last2Notes && this.state.last2Notes.length > 0 ?
                                                                this.state.last2Notes.map((item, index) => {
                                                                    let noteCreates = new Date(item.created);
                                                                    let monthNo = noteCreates.getMonth()
                                                                    let date = noteCreates.getDate()
                                                                    return <ol className=" mt-1 pl-0" key={index}>
                                                                        <p className="kanban_title notes_details">
                                                                            <span className="mr-2 notesdate_font">{month[monthNo]}</span>
                                                                            <span className="mr-1 notesdate_font">{date}</span>-
                                                                            <span className="ml-1 notesdate_font">{item.createdBy}</span>
                                                                            <span className="ml-1 notesdate_font">{item.subTaskID ? item.subTaskID : ""}</span>
                                                                        </p>
                                                                        <p className="kanban_title mt-0 pb-1" style={{ wordBreak: "break-all" }}
                                                                        >
                                                                            {item.note}</p>
                                                                    </ol>
                                                                }) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        <div className={type === 'edit' ? "col-sm-6 mb-4 pr-0" : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.notes === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Notes</label>
                                                <div className='form__form-group-field kanban_notesfield'>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="notesadd"
                                                        options={this.state.textAutoAreaSuggestions}
                                                        component={TextAutoComplete}
                                                        maxOptions={this.state.textAutoAreaSuggestionsLength}
                                                        placeholder={'Enter Notes'}
                                                        onChange={(e) => this.handleComments(e)}
                                                    // isDisable={type === 'edit' ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* <div className='row ml-0 col-sm-6  mr-0' style={this.state.nameChild ? { display: "block" } : { display: "none" }}>
                                        <label className="form__form-group-label">Subsidiary Estates</label>
                                        <div className='usermessagesText subsidary d-block' style={{ height: "90px", overflow: "auto", border: "1px solid lightgray" }}>
                                            <ul style={{ borderBottom: "none" }} className="mr-0 pl-0">
                                                {this.state.rowData && this.state.rowData.subsidiaryEstates && this.state.rowData.subsidiaryEstates.map(function (name, index) {
                                                    return <li style={{ listStyle: "none" }}>{name}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div> */}
                                        {/* {type === 'edit' && rowData.purpose === 'Post Filing' ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.recoveryChannel === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <div className="form__form-group pb-1">
                                                    <label className="form__form-group-label">Recovery Channel</label>
                                                    <div className='form__form-group-field'>
                                                        <Field className='inputLogin'
                                                            name="recoveryChannel"
                                                            component={DefaultInput}
                                                            placeholder='Recovery Channel'
                                                            isDisable={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null} */}


                                    </div> : null

                                }
                                {(this.props.createTask === "Research Request" && this.props.type === "addToTask") || (this.props.createTask === "Seller Negative Notice" && this.props.type === "addToTask") || (this.props.createTask === "Site Confirmation" && this.props.type === "addToTask") ?
                                    <div className='row mx-1 mt-3 col-sm-12 pl-0 pr-0'>

                                        <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-2">
                                                <label className="form__form-group-label">Deal{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className=' form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="company"
                                                        component={DefaultInput}
                                                        placeholder='Deal'
                                                        value={this.props.createTask}
                                                        // validate={[required]}
                                                        isDisable={true}

                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <span className="d-flex">
                                                    <label className="form__form-group-label mr-auto">{this.props.createTask === "Research Request" ? "Research Request Task" : this.props.createTask === "Site Confirmation" ? "Site Confirmation Task" : "Seller Negative Notice Task"}{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>

                                                </span>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="researchRequestTask"
                                                        // defaultValue={this.state.selectedPurpose}
                                                        component={Select}
                                                        validate={[required]}
                                                        options={this.state.tasksAssociated}
                                                        placeholder={this.props.createTask === "Research Request" ? "Research Request Task" : this.props.createTask === "Site Confirmation" ? "Site Confirmation Task" : "Seller Negative Notice Task"}
                                                        onChange={(e) => this.setSelectedTask(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Connected OPRA Property IDs </label>
                                                <div className='form__form-group-field'>
                                                    <Field
                                                        id={true}
                                                        name={"connectedProperties"}
                                                        component={DefaultTextArea}
                                                        isDisable={true}
                                                        type={"text"}
                                                    // value={rowData.propertyCountToFile && rowData.propertyCountToFile !== null ? rowData.propertyCountToFile : ""}
                                                    // minDate={new Date()}
                                                    // validate={(this.state.dealType === "CR" && item.dealType === "BK") || (item.name === "CaseStatusClosedDate" && !this.state.showCaseClosedDate) || !item.required ? null : [required]}
                                                    // placeholder={item.placeholder}
                                                    // style={item.name === "CaseStatusClosedDate" || item.include === "false" || item.include1 === "false" ? { display: "none" } : { display: "none" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* {this.state.selectedLabel.includes("RFI") === true ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Claim ID
                                                    <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /></label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="claimID"
                                                        component={DefaultInput}
                                                        placeholder='Claim ID'
                                                        validate={[required]}
                                                        isDisable={view === "loggedThroughmail" || rowData.claimID || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}

                                                    />
                                                </div>
                                            </div>
                                        </div> : null} */}
                                        {/* {this.state.selectedLabel.includes("RFI") === true ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Number of properties in RFI<FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /></label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="propertyCountRFI"
                                                        component={DefaultInput}
                                                        placeholder='Number of Properties'
                                                        isDisable={view === "loggedThroughmail" || (rowData && rowData.assignTo === "Unassigned") || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}

                                                        validate={[required]}
                                                        normalize={normalizenumber}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null} */}
                                        {/* {this.state.selectedLabel.includes("RFI") === true ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Value
                                                </label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="valueRFI"
                                                        component={DefaultInput}
                                                        isDisable={view === "loggedThroughmail" || (rowData && rowData.assignTo === "Unassigned") || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                        placeholder='RFI Value'
                                                    // validate={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null} */}
                                        {/* {this.state.selectedLabel.includes("RFI") === true ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} >
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Reason(s)<FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /></label>
                                                <div className='form__form-group-field'>
                                                    <Field className='inputLogin'
                                                        name="reasons"
                                                        type={'dropDown'}
                                                        component={MultiSelect}
                                                        filterElement={config.RFIReasons}
                                                        placeholder='RFI Reason'
                                                        filter={true}
                                                        disabled={view === "loggedThroughmail" || (rowData && rowData.assignTo === "Unassigned") || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                                        validate={[required]}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null} */}

                                        {/* <div className={type === 'edit' ? "col-12 pr-0 row" : "col-12 pl-0 pr-0"} >
                                        {type === 'edit' ?
                                            <div className='col-sm-6 ' style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.notesHistory === "show")) ? { display: "block" } : { display: "none" }}>
                                                <div className="form__form-group" >
                                                    <div className="d-flex" style={{ alignItems: "center" }}>
                                                        <label className="form__form-group-label mr-auto">Notes History ({this.state.comments && this.state.comments.length > 0 ? this.state.comments.length : "0"})</label>
                                                        {this.state.comments.length > 0 ?
                                                            <span className="text-right" style={{ display: "grid" }}>
                                                                <FontAwesomeIcon icon={faExternalLinkAlt}
                                                                    className='pl-1'
                                                                    data-toggle="tool-tip"
                                                                    validate={[required]}
                                                                    title="click to see all Notes"
                                                                    style={{ width: 20, color: "grey" }}
                                                                    onClick={this.changeNote}
                                                                />
                                                            </span>
                                                            : null}
                                                    </div>
                                                    <div className='ml-0 mr-0'>
                                                        <div className='usermessagesText d-block' style={{ minHeight: "95px" }}>
                                                            {this.state.last2Notes && this.state.last2Notes.length > 0 ?
                                                                this.state.last2Notes.map((item, index) => {
                                                                    let noteCreates = new Date(item.created);
                                                                    let monthNo = noteCreates.getMonth()
                                                                    let date = noteCreates.getDate()
                                                                    return <ol className=" mt-1 pl-0" key={index}>
                                                                        <p className="kanban_title notes_details">
                                                                            <span className="mr-2 notesdate_font">{month[monthNo]}</span>
                                                                            <span className="mr-1 notesdate_font">{date}</span>-
                                                                            <span className="ml-1 notesdate_font">{item.createdBy}</span>
                                                                            <span className="ml-1 notesdate_font">{item.subTaskID ? item.subTaskID : ""}</span>
                                                                        </p>
                                                                        <p className="kanban_title mt-0 pb-1" style={{ wordBreak: "break-all" }}
                                                                        >
                                                                            {item.note}</p>
                                                                    </ol>
                                                                }) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                        <div className={type === 'edit' ? "col-sm-6 mb-4 pr-0" : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.notes === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Notes</label>
                                                <div className='form__form-group-field kanban_notesfield'>
                                                    <Field className='inputLogin'
                                                        type={'text'}
                                                        name="notesadd"
                                                        options={this.state.textAutoAreaSuggestions}
                                                        component={TextAutoComplete}
                                                        maxOptions={this.state.textAutoAreaSuggestionsLength}
                                                        placeholder={'Enter Notes'}
                                                        onChange={(e) => this.handleComments(e)}
                                                    // isDisable={type === 'edit' ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* <div className='row ml-0 col-sm-6  mr-0' style={this.state.nameChild ? { display: "block" } : { display: "none" }}>
                                        <label className="form__form-group-label">Subsidiary Estates</label>
                                        <div className='usermessagesText subsidary d-block' style={{ height: "90px", overflow: "auto", border: "1px solid lightgray" }}>
                                            <ul style={{ borderBottom: "none" }} className="mr-0 pl-0">
                                                {this.state.rowData && this.state.rowData.subsidiaryEstates && this.state.rowData.subsidiaryEstates.map(function (name, index) {
                                                    return <li style={{ listStyle: "none" }}>{name}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div> */}
                                        {/* {type === 'edit' && rowData.purpose === 'Post Filing' ?
                                        <div className={type === 'edit' ? "col-sm-6 " : "col-sm-12 pl-0 pr-0"} style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.recoveryChannel === "show")) ? { display: "block" } : { display: "none" }}>
                                            <div className="form__form-group pb-1">
                                                <div className="form__form-group pb-1">
                                                    <label className="form__form-group-label">Recovery Channel</label>
                                                    <div className='form__form-group-field'>
                                                        <Field className='inputLogin'
                                                            name="recoveryChannel"
                                                            component={DefaultInput}
                                                            placeholder='Recovery Channel'
                                                            isDisable={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null} */}


                                    </div> : null

                                }


                            </CardBody>
                        </Card>
                        <span className="formError m-2 mt-4">{this.state.apierrorResponse ? <span style={{ height: 30, }}>{this.state.apierrorMessage}</span> : null}</span>
                        {/* <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: 0 }}>{this.state.issplitbyStatus ? <span>{this.state.apierrorMessage}
                            <p> <Button
                                color='primary'
                                outline
                                type="button"
                                className=" ml-1 mb-0 mt-0" onClick={() => this.issplitSucces()}>Yes
                            </Button><Button
                                color='primary'
                                outline
                                type="button"
                                className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                                </Button></p>
                        </span> : null}</span> */}
                    </ModalBody>
                </form >
            </div >);
    }

    getSplitedStates = async () => {
        this.setState({
            leftCount: this.state.leftCount + 1
        })

        let selectt = this.state.selectedStates;
        let split = this.state.statestab2Data;

        let selectt1 = JSON.parse(JSON.stringify(this.state.selectedStates));
        let split_arr = this.state.isSplittedData;

        if (this.state.isSelecteddefinedStates) {
            for (var obj of split) {
                var obj1 = Object.assign({}, obj)
                if (obj.show === true) {
                    obj1.show = false
                    split_arr.push(obj1)
                    split.splice(split.findIndex(a => a.label === obj.label), 1)
                }
            }
        }
        var newArray = selectt.filter(function (el) {
            return el.show === true
        });
        if (newArray && newArray.length > 0) {
            for (var obj of selectt) {
                var obj1 = Object.assign({}, obj)
                if (obj.show === true) {
                    obj1.show = false
                    split_arr.push(obj1)
                    selectt1.splice(selectt1.findIndex(a => a.label === obj.label), 1)
                }
            }
            this.setState({ selectedStates: selectt1, isSplittedData: split_arr, hideSplitBtn: true })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select ${this.state.isSplitBy === "States" ? 'states' : 'names'} under Selected ${this.state.isSplitBy} before clicking` })
        }
        if (this.state.ismoveAlltoleft) {
            await this.setState({ ismoveAlltoleft: false });
        }
    }

    onChangesplitAll = async (e) => {
        this.setState(prevState => ({ issplitAll: !prevState.issplitAll }))
    }

    onChangeMoveAll = async () => {
        // this.setState(prevState => ({ ismoveAll: !prevState.ismoveAll }));
        if (this.state.ismoveAll) {
            this.setState({ ismoveAll: false });
        } else {
            this.setState({ ismoveAll: true, apierrorMessage: "", apierrorResponse: false });
        }
        if (!this.state.ismoveAll) {
            let select_list = this.state.statestab2Data
            for (var obj of select_list) {
                obj.show = true
            }
            this.setState({ statestab2Data: select_list })
        } else {
            let select_list = this.state.statestab2Data
            for (var obj2 of select_list) {
                obj2.show = false
            }
            this.setState({ statestab2Data: select_list })
        }
    }

    newSelectedStates = async () => {
        this.setState({
            upCount: this.state.upCount + 1
        })

        let selectt5 = this.state.statestab2Data;
        let selectt6 = JSON.parse(JSON.stringify(this.state.statestab2Data));
        let split_arr3 = this.state.selectedStates;
        var newArray = selectt5.filter(function (el) {
            return el.show === true
        });
        if (newArray && newArray.length > 0) {
            for (var obj2 of selectt5) {
                var obj3 = Object.assign({}, obj2)
                if (obj2.show === true) {
                    obj3.show = false
                    split_arr3.push(obj3)
                    selectt6.splice(selectt6.findIndex(a1 => a1.label === obj2.label), 1)
                }
            }
            this.setState({ selectedStates: split_arr3, statestab2Data: selectt6 })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select ${this.state.isSplitBy === "States" ? 'states' : 'names'} under All Unselected ${this.state.isSplitBy} before clicking` })
        }
        if (this.state.ismoveAll) {
            await this.setState({ ismoveAll: false });
        }
        // if(this.state.onChangeMoveAlltoleft){
        // if (this.state.isSelecteddefinedStates) {
        //     this.onChangeMoveAlltoleft("ismoveAlltoleft")
        // }
        // }
    }

    getValues = async (cardtype, value) => {
        let selectTab2_States = this.state.statestab2Data
        if (value && value.label && selectTab2_States && selectTab2_States.length > 0) {
            selectTab2_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }
        this.setState({
            statestab2Data: selectTab2_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })
    }

    getValues1 = async (cardtype, value) => {
        let select_States = this.state.selectedStates
        if (value && value.label && select_States && select_States.length > 0) {
            select_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }
        this.setState({
            selectedStates: select_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })
    }

    getnewExistingValues = async (cardtype, value, data, dataType) => {
        let isSplitt_States = this.state.isSplittedData
        if (value && value.label && isSplitt_States && isSplitt_States.length > 0) {
            isSplitt_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }
        this.setState({
            isSplittedData: isSplitt_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })

    }

    removedStates = async () => {
        this.setState({
            downCount: this.state.downCount + 1
        })
        let selectt4 = this.state.selectedStates;
        let selectt5 = JSON.parse(JSON.stringify(this.state.selectedStates));
        let split_arr2 = this.state.statestab2Data;
        var newArray = selectt4.filter(function (el) {
            return el.show === true
        });
        if (newArray && newArray.length > 0) {
            for (var obj2 of selectt4) {
                var obj3 = Object.assign({}, obj2)
                if (obj2.show === true) {
                    obj3.show = false
                    split_arr2.push(obj3)
                    selectt5.splice(selectt5.findIndex(a1 => a1.label === obj2.label), 1)
                }
            }
            this.setState({ selectedStates: selectt5, statestab2Data: split_arr2 })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select ${this.state.isSplitBy === "States" ? 'states' : 'names'} under Selected ${this.state.isSplitBy} before clicking` })
        }
        if (this.state.ismoveAlltoleft) {
            await this.setState({ ismoveAlltoleft: false });
        }
        // }

    }

    moveRighttoLeft = async () => {
        this.setState({
            upCount: this.state.upCount + 1
        })
        let selectt2 = this.state.isSplittedData;
        let selectt3 = JSON.parse(JSON.stringify(this.state.isSplittedData));
        let split_arr1 = this.state.selectedStates;
        var newArray = selectt2.filter(function (el) {
            return el.show === true
        });
        if (newArray && newArray.length > 0) {
            for (var obj2 of selectt2) {
                var obj3 = Object.assign({}, obj2)
                if (obj2.show === true) {
                    obj3.show = false
                    split_arr1.push(obj3)
                    selectt3.splice(selectt3.findIndex(a1 => a1.label === obj2.label), 1)
                }
            }
            this.setState({ selectedStates: split_arr1, isSplittedData: selectt3, hideSplitBtn: selectt3.length > 0 ? true : false })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select ${this.state.isSplitBy === "States" ? 'states' : 'names'} under  ${this.state.isSplitBy} For Splitting before clicking` })
        }
        if (this.state.isSelectAll) {
            await this.setState({ isSelectAll: false });
        }
        if (this.state.isSelecteddefinedStates) {
            this.onChangedefinedStates()
        }


    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    isSelectNo = () => {
        if (this.props.type === "add" || this.props.type === "edit") {
            this.setState({
                issplitbyStatus: false,
                // hideSplitBtn: true
            })
        }
        this.setState({
            splitbyeachCard: false,
            hideSplitBtn: true
        })
    }

    flattenArray = (arrayVal) => {
        let val = '';
        arrayVal = arrayVal.slice(0, 3);
        if (arrayVal) {
            val = JSON.stringify(arrayVal);
            val = val.replace(/"/g, '')
                .replace(/\[/g, '')
                .replace(/]/g, '');
        }
        val = val + "..."
        return val;
    }

    onChangeMoveAlltoleft = () => {
        if (this.state.ismoveAlltoleft) {
            this.setState({ ismoveAlltoleft: false });
        } else {
            this.setState({ ismoveAlltoleft: true, apierrorMessage: "", apierrorResponse: false });
        }
        if (!this.state.ismoveAlltoleft) {
            let select_list = this.state.selectedStates
            for (var obj of select_list) {
                obj.show = true
            }
            this.setState({ selectedStates: select_list })
        } else {
            let select_list = this.state.selectedStates
            for (var obj2 of select_list) {
                obj2.show = false
            }
            this.setState({ selectedStates: select_list })
        }
        // if (this.state.isSelecteddefinedStates) {
        //     this.moveRighttoLeft()
        // }
    }

    onChangeselectAll() {
        if (this.state.isSelectAll) {
            this.setState({ isSelectAll: false });
        } else {
            this.setState({ isSelectAll: true, apierrorMessage: "", apierrorResponse: false });
        }
        if (!this.state.isSelectAll) {
            let select_list = this.state.isSplittedData
            for (var obj of select_list) {
                obj.show = true
            }
            this.setState({ isSplittedData: select_list })
        } else {
            let select_list = this.state.isSplittedData
            for (var obj2 of select_list) {
                obj2.show = false
            }
            this.setState({ isSplittedData: select_list })
        }
    }

    onChangedefinedStates = async (type) => {
        if (type) {
            await this.setState({
                isSelecteddefinedStates: this.state.isSelecteddefinedStates ? false : true
            })
        }// statestab2Data

        let states = this.state.statestab2Data
        let select_list = this.state.selectedStates
        var select_desiredStates = ["Alabama - AL",
            "Arizona - AZ", "California - CA", "Colorado - CO", "Washington - DC", "Delaware - DE", "Iowa - IA", "Idaho - ID", "Illinois - IL", "Kansas - KS", "Kentucky - KY",
            "Maine - ME",
            "Michigan - MI",
            "Mississippi - MS",
            "Montana - MT",
            "New Hampshire - NH",
            "Nevada - NV",
            "Puerto Rico - PR",
            "South Dakota - SD",
            "Utah - UT",
            "Virginia - VA",
            "Vermont - VT",
            "Washington - WA",
            "Wyoming - WY",
        ]
        if (this.state.isSelecteddefinedStates) {
            for (var obj of select_desiredStates) {
                for (var item of states) {
                    if (item.label === obj) {
                        item.show = true
                    }
                }
                for (var item of select_list) {
                    if (item.label === obj) {
                        item.show = true
                    }
                }
                await this.setState({ selectedStates: select_list, statestab2Data: states })
            }

        } else {
            for (var obj of select_desiredStates) {
                for (var item of states) {
                    if (item.label === obj) {
                        item.show = false
                    }
                }
                for (var item of select_list) {
                    if (item.label === obj) {
                        item.show = false
                    }
                }
                await this.setState({ selectedStates: select_list, statestab2Data: states })
            }
        }

        // let selectt5 = this.state.statestab2Data;
        // let selectt6 = JSON.parse(JSON.stringify(this.state.statestab2Data));
        // let split_arr3 = this.state.selectedStates;
    }

    getTab2Body() {
        if (this.state.rowData.subTaskCount > 0) {
            return (
                <div className='col-12 px-0 splitTasks m-3' style={{ height: 400, width: "96%", overflow: "auto" }}>
                    <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />
                    <Table bordered={true} className={this.state.isLoading ? "mt-2" : ""} >
                        <thead>
                            <tr>
                                <th>Split CardId</th>
                                <th>Label</th>
                                <th>Purpose</th>
                                <th>Priority</th>
                                <th>Assign To</th>
                                <th>Status</th>
                                <th>States/Names</th>
                            </tr>
                        </thead>
                        <tbody style={{ maxHeight: 80 }}>
                            {this.state.subTasksArray && this.state.subTasksArray.length > 0 ?
                                this.state.subTasksArray.map((item, i) => {
                                    let val = this.flattenArray(item.subsidiaryEstates ? item.subsidiaryEstates : item.states);
                                    return <tr key={i}>
                                        <td onClick={() => this.gettaskData(item._id)} style={{ color: "#354f6e", cursor: "pointer" }} data-toggle="tool-tip"
                                            title={"click to see the details of child card"}>{item.taskID}</td>
                                        <td> {item.title}</td>
                                        <td> {item.purpose}</td>
                                        <td> {item.priority}</td>
                                        <td> {item.assignTo}</td>
                                        <td >{item.status}</td>
                                        <td >{val}</td>
                                    </tr>
                                }) : ""}
                        </tbody>
                    </Table>
                </div>
            )
        } else {
            return (
                <div>
                    <ModalBody className='modal__body edit_modal' style={{
                        padding: 10,
                        // overflowX: "hidden",
                        // overflowY: "clip",
                        height: 580
                    }}>
                        <Card className='pb-0 ml-4'>
                            <CardBody className='row p-0 d-block'>
                                <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />
                                <div className={this.state.isLoading ? " row m-0 mt-3" : "row m-0"} >
                                    <div className='col-sm-5 px-2 pb-2 d-flex'>
                                        <Field
                                            id='States'
                                            name='states2'
                                            label="States"
                                            value="States"
                                            radioValue="States"
                                            onChange={() => this.handletab2State('States')}
                                            component={renderRadioButtonField}
                                        // disabled={this.state.isSplitBy ? true : false}
                                        />
                                        <Field
                                            id='Names'
                                            name='states2'
                                            label="Names"
                                            value="Names"
                                            radioValue="Names"
                                            onChange={() => this.handletab2State('Names')}
                                            component={renderRadioButtonField}
                                        // disabled={this.state.isSplitBy ? true : false}
                                        />
                                    </div>
                                    <div className="col-sm-2"></div>
                                    <div className='col-sm-5 px-0 pb-2' >

                                    </div>
                                </div>
                                <div style={this.state.isSplitBy ? { display: "block" } : { display: "none" }}>
                                    <div className="col-sm-12 row" style={{ width: "100%" }}>
                                        <div className="col-sm-5 pl-0 pr-0">
                                            <Row className=" ml-0 mr-0" >
                                                <label><b>{this.state.isSplitBy === "States" ? "Selected States" : "Selected Names"}</b></label>
                                                <span className='d-flex  split ml-auto' >
                                                    <label className='d-flex float-right' ><input type="checkbox"
                                                        checked={this.state.ismoveAlltoleft}
                                                        onChange={(e) => this.onChangeMoveAlltoleft("ismoveAlltoleft")}
                                                    /> Select All</label>

                                                </span>
                                                {this.state.selectedStates && this.state.selectedStates.length > 40 ?
                                                    <span className='d-flex float-right split ml-auto' >
                                                        <label className='d-flex float-right' ><input type="checkbox"
                                                            checked={this.state.isSelecteddefinedStates}
                                                            onChange={(e) => this.onChangedefinedStates("isSelecteddefinedStates")}
                                                        /> Select Defined States</label>

                                                    </span> : null}
                                                <Card className="pb-0" style={{ height: '170px', overflow: 'auto', border: "1px solid grey" }}>
                                                    {this.state.selectedStates && this.state.selectedStates.length > 0 ? this.state.selectedStates.map((item, index) => {
                                                        return <div className='d-flex m-1'>
                                                            <input key={`up-${index}`}
                                                                style={{ marginTop: 3, height: 16 }}
                                                                className='col-1 mr-0 pl-0'
                                                                onChange={(e) => this.getValues1("existing", item, item.label, this.state.isSplittedStates)}
                                                                name={`up-${this.state.upCount}-${index}`}
                                                                id={`up-${this.state.upCount}-${index}`}
                                                                label={item.label}
                                                                type="checkbox"
                                                                //value={item.show}
                                                                checked={item.show}
                                                                defaultChecked={item.show}
                                                            /><label className='mb-0'>{item.label}</label>
                                                        </div>
                                                    }) : null}
                                                </Card>
                                            </Row>
                                            <span className="d-flex mt-3">
                                                <span style={{ marginLeft: "42%" }}><img
                                                    src={configImages.downIcon}
                                                    alt=""
                                                    className="mt-2 mb-2"
                                                    style={{ width: "20px", height: "18px" }}
                                                    onClick={() => this.removedStates()}
                                                    data-toggle="tool-tip"
                                                    title={`click to move selected ${this.state.isSplitBy === "States" ? "States" : "Names"} to down`} /></span>
                                                <span style={{ marginLeft: "5px" }}><img
                                                    src={configImages.upIcon}
                                                    alt=""
                                                    className="mt-2 mb-2"
                                                    style={{ width: "15px", height: "15px" }}
                                                    data-toggle="tool-tip"
                                                    title={`click to move selected ${this.state.isSplitBy === "States" ? "States" : "Names"} to top`}
                                                    onClick={() => this.newSelectedStates()} /></span>
                                            </span>
                                            <Row className="ml-0 mr-0" >
                                                <label><b>{this.state.isSplitBy === "States" ? "All Unselected States" : "All UnSelected Names"}</b></label>
                                                <span className='d-flex float-right split ml-auto' >
                                                    <label className='d-flex float-right' ><input type="checkbox"
                                                        checked={this.state.ismoveAll}
                                                        onChange={(e) => this.onChangeMoveAll("ismoveAll")}
                                                    />Select All</label>
                                                </span>
                                                <Card className="pb-0" style={{ border: "1px solid grey", height: '170px', overflow: 'auto' }}>
                                                    {this.state.statestab2Data && this.state.statestab2Data.length > 0 ? this.state.statestab2Data.map((item1, index1) => {
                                                        return <div className='d-flex m-1'>
                                                            <input key={`down-${index1}`}
                                                                style={{ marginTop: 3, height: 16 }}
                                                                className='col-1 mr-0 pl-0'
                                                                // name={`down-${this.state.downCount}-${index1}`}
                                                                id={`down-${this.state.downCount}-${index1}`}
                                                                type="checkbox"
                                                                label={item1.label}
                                                                onChange={(e) => this.getValues("additional", item1, item1.label)}
                                                                checked={item1.show}
                                                                // value={item1.show}
                                                                defaultChecked={item1.show}
                                                            /><label className='mb-0'>{item1.label}</label>
                                                        </div>
                                                    }) : null}
                                                </Card>
                                            </Row>
                                        </div>
                                        <div className="col-sm-2 mt-5">
                                            <Row className="">
                                                <span style={{ width: "100%", marginLeft: "43%" }}>
                                                    <p className="ml-auto mr-auto"><img
                                                        src={configImages.leftIcon}
                                                        alt=""
                                                        className="mt-2 mb-2"
                                                        style={{ width: "15px", height: "15px" }}
                                                        data-toggle="tool-tip"
                                                        title={`click to move selected ${this.state.isSplitBy === "States" ? "States" : "Names"} to right`}
                                                        onClick={() => this.getSplitedStates()} /></p>
                                                    <p className="ml-auto mr-auto mt-0"><img
                                                        src={configImages.rightIcon}
                                                        alt=""
                                                        className="mb-2"
                                                        style={{ width: "15px", height: "15px" }}
                                                        data-toggle="tool-tip"
                                                        title={`click to move selected ${this.state.isSplitBy === "States" ? "States" : "Names"} to left`}
                                                        onClick={() => this.moveRighttoLeft()} /></p>
                                                </span>
                                            </Row>
                                        </div>
                                        <div className="col-sm-5 pl-0 pr-0">
                                            <div className="d-flex">
                                                <label className="split_label mb-0"><b>{this.state.isSplitBy === "States" ? "States For Splitting" : "Names For Splitting"}</b></label>
                                                <span className='d-flex float-right split ml-auto' >
                                                    <label className='d-flex float-right' ><input type="checkbox"
                                                        checked={this.state.isSelectAll}
                                                        onChange={() => this.onChangeselectAll('remember')}
                                                    /> Select All</label>

                                                </span>
                                                <span className='d-flex float-right split ml-auto' >
                                                    <label className='d-flex float-right' ><input type="checkbox"
                                                        checked={this.state.issplitAll}
                                                        onChange={() => this.onChangesplitAll('remember')}
                                                    /> Split All</label>

                                                </span>
                                            </div>
                                            <Card style={{ minHeight: "160px", height: '427px', overflow: 'auto', border: "1px solid grey" }}>
                                                {this.state.isSplittedData && this.state.isSplittedData.length > 0 ? this.state.isSplittedData.map((item2, index2) => {
                                                    return <div className='d-flex m-1'>
                                                        <input key={`left-${index2}`}
                                                            className='col-1 mr-0 pl-0'
                                                            style={{ marginTop: 3, height: 16 }}
                                                            // name={`left-${this.state.leftCount}-${index2}`}
                                                            id={`left-${this.state.leftCount}-${index2}`}
                                                            type="checkbox"
                                                            label={item2.label}
                                                            //value={item2.show}
                                                            checked={item2.show}
                                                            defaultChecked={item2.show}
                                                            onChange={(e) => this.getnewExistingValues("newExisting", item2, this.state.isSplittedData)}

                                                        /><label className='mb-0'>{item2.label}</label>

                                                    </div>

                                                }) : null}
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>
                        <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.splitbyeachCard ?
                            <span>
                                <span>{this.state.spliterrorMessage}</span>
                                <p> <Button
                                    color='primary'
                                    outline
                                    type="button"
                                    className=" ml-1 mb-0 mt-0" onClick={() => this.issplitSucces()}>Yes
                                </Button><Button
                                    color='primary'
                                    outline
                                    type="button"
                                    className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                                    </Button></p>
                            </span> : null}</span>

                    </ModalBody>
                </div >
            );

        }
    }

    TaskhistoryTab() {
        return (
            <div className='col-12 px-0 splitTasks m-3' style={{ height: 400, width: "96%", overflow: "auto" }}>
                <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader mb-2" />
                <Table bordered={true} className={this.state.isLoading ? "mt-4" : ""}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Label/Purpose</th>
                            <th>Assigned To</th>
                            <th>Effort</th>
                            <th>Duration</th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: 80 }}>
                        {this.state.activities && this.state.activities.length > 0 ?
                            this.state.activities.map((item, i) => {
                                var hours = Math.floor(item.activeSpentInMinutes / 60);
                                var minutes = item.activeSpentInMinutes % 60;
                                var hoursDuration = Math.floor(item.durationInMinutes / 60 % 24);
                                var minutesDuration = item.durationInMinutes % 60;
                                var days = Math.floor(item.durationInMinutes / 24 / 60);

                                // return hours + ":" + minutes;     
                                return <tr key={i}>
                                    <td >{dateFormats.formatDate(item.from, config.basicDateFromat)}</td>
                                    <td> {item.label}/{item.purpose}</td>
                                    <td> {item.assignTo}</td>
                                    <td> {hours + " Hrs " + Math.round(minutes.toFixed(2)) + " Mins"}</td>
                                    <td> {days + " Days " + hoursDuration + " Hrs " + Math.round(minutesDuration.toFixed(2)) + " Mins"}</td>

                                </tr>
                            }) : ""}
                    </tbody>

                </Table>
            </div>
        )

    }

    redirectToProperties = async () => {
        window.history.pushState(null, null, `${window.location.pathname}`);
        let propertySeller = {
            EstateName: this.state.rowData.company,
            EstateID: this.state.rowData.opraEstateID,
        }
        if (this.state.rowData.subsidiaryEstates && this.state.rowData.subsidiaryEstates.length > 0) {
            propertySeller.Names = this.state.rowData.subsidiaryEstates
        }
        let TaskPropertiesFilters = {
            PropertiesSellerName: propertySeller,
            States: this.state.rowData.states,
        }
        localStorage.setItem("TaskProperties", JSON.stringify(TaskPropertiesFilters))
        this.setState({
            isRedirectProperties: true
        });
    }
    render() {
        const { handleSubmit, t, type } = this.props;
        const { rowData } = this.state;
        const { activeTab } = this.state;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });

        return (
            <div className={this.state.activeTab === '2' ? "split_screen" : ""}>
                <Modal
                    fade={type === 'edit' ? false : true}
                    isOpen={this.props.openUserPasswordResetModal}
                    className={`right ${this.props.type === "add" ? "task" : null} modal-dialog-centered modal-dialog--primary  ${modalClass}`}
                    style={this.props.type === "edit" ? { width: 800 } : {}}
                >
                    <ModalHeader className={type === 'edit' ? "modal__header kanbanScreen justify-content-center" : "modal__header kanbanScreen"}>
                        <button className="lnr lnr-cross modal__close-btn" type="button" style={type === "edit" ? { color: "black", right: 40 } : { color: "white" }}
                            onClick={this.cancelReset} />
                        <span className=" modal__identifier-btn" type="button" style={type === "edit" ? { color: "rgb(143, 170, 220)", left: 40 } : { display: "none" }}
                        >{rowData ? rowData.taskID : ""}</span>
                        <span className={rowData ? "" : `modallnrIcon lnr lnr-layers`} />
                        <span className="bold-text  modal__title"> {type === 'add' && this.props.createTask === "Site Confirmation" ? "Create Task - Site Confirmation" : type === 'addToTask' && this.props.createTask === "Site Confirmation" ? "Add to Task – Site Confirmation" : type === 'add' && this.props.createTask === "Research Request" ? "Create Task - Research Request" : type === 'addToTask' && this.props.createTask === "Research Request" ? "Add to Task – Research Request" : type === 'add' && this.props.createTask === "Seller Negative Notice" ? "Create Task - Seller Negative Notice" : type === 'addToTask' && this.props.createTask === "Seller Negative Notice" ? "Add to Task – Seller Negative Notice" : "Edit"}  </span>
                    </ModalHeader>
                    <form className="form" onSubmit={handleSubmit(this.submit)}>
                        {this.props.type === "edit" ?
                            <div style={{ width: "100%" }}>
                                <Nav tabs >
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggle('1'); }}
                                        >
                                            Edit Task
                                        </NavLink>
                                    </NavItem>
                                    <NavItem >
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggle('2'); }}
                                            disabled={this.state.isTabdisabled || this.state.isChildTrue}
                                        >
                                            Split Task
                                        </NavLink>
                                    </NavItem>
                                    <NavItem >
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '3' })}
                                            onClick={() => { this.toggle('3'); }}
                                        >
                                            History
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            // className={classnames({ active: this.state.activeTab === '' })}
                                            onClick={() => this.redirectToProperties()}
                                        >
                                            Properties
                                        </NavLink>
                                    </NavItem>
                                    {this.state.activeTab === "1" ?
                                        <span className='ml-auto'>
                                            <Button color="primary" outline
                                                className='p-1 ml-auto mb-2 mt-1'
                                                onClick={this.getDataToExport}
                                                size={'sm'} >
                                                <FontAwesomeIcon
                                                    icon='download'
                                                    data-toggle="tool-tip" title={t("Export To CSV")}
                                                    className='pl-1' size='lg' />
                                            </Button>
                                            <CSVLink
                                                data={this.state.exportData}
                                                filename={`BusinessNames.csv`}
                                                className="hidden text-white"
                                                ref={(r) => this.csvLinkRef = r}
                                                target="_blank" >
                                            </CSVLink> </span> : null}
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    {/* <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" /> */}

                                    <TabPane tabId="1">
                                        {this.getModalBody(handleSubmit)}
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {this.getTab2Body()}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {this.TaskhistoryTab()}
                                    </TabPane>
                                </TabContent>
                            </div>
                            : this.getModalBody(handleSubmit)}

                        <ModalFooter className={`${this.state.issplitbyStatus && type === "add" ? `modalFooter showAssignedError` : 'modalFooter'}`} style={type === 'edit' || this.state.issplitbyStatus ? { width: "100%", padding: "16px", paddingRight: "40px", backgroundColor: "white", borderTop: "none" } :
                            { width: "100%", padding: "10px" }}>

                            {!this.state.issplitbyStatus ?
                                <>
                                    {!this.state.splitbyeachCard ? <Button color='danger' outline type="button" className={type === 'edit' ? " ml-1 mb-0 mt-0" : "mr-auto ml-1 mb-0 "} onClick={this.cancelReset}>
                                        <FontAwesomeIcon
                                            style={{ width: "15px" }}
                                            icon={faArrowCircleLeft}
                                        />
                                        {t('Back') ? t('Cancel') : 'Cancel'} </Button> : null}
                                    {activeTab !== "2" && activeTab !== "3" && !this.state.hideUpdatebtn ?
                                        <Button
                                            style={{ float: "right" }}
                                            color='primary'
                                            className={type === "edit" ? "mb-0 mt-0" : "mb-0 "}
                                            outline
                                            type="submit"
                                            disabled={this.state.ishidesave}
                                        >
                                            <FontAwesomeIcon
                                                style={{ width: "15px" }}
                                                icon={activeTab === "3" ? faProjectDiagram : faSave}
                                            />
                                            {type === 'edit' && activeTab === "1"
                                                ?
                                                'Update'
                                                : 'Save'
                                            }
                                        </Button> :
                                        activeTab === "2" && this.state.hideSplitBtn && !this.state.hideUpdatebtn ?
                                            <Button
                                                color='primary'
                                                outline
                                                type="button"
                                                className=" ml-1 mb-0 mt-0" onClick={this.submitsplitTask}>
                                                <img

                                                    src={configImages.splitIcon}
                                                    alt=""
                                                    className="mt-0"
                                                    style={{ width: "20px", height: "10px" }} />
                                                {'Split'}
                                            </Button> : null
                                    }
                                </>
                                : <span className=" mt-2 mb-2 ml-auto mr-auto" style={{ textAlign: "center", bottom: 0 }}>{this.state.issplitbyStatus ? <span>{this.state.apierrorMessage}
                                    <p> <Button
                                        color='primary'
                                        outline
                                        type="button"
                                        className=" ml-1 mb-0 mt-0" onClick={() => this.successSubmit(this.state.method, this.state.apiUrl, this.state.userBody)}>Yes
                                    </Button><Button
                                        color='primary'
                                        outline
                                        type="button"
                                        className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                                        </Button></p>
                                </span> : null}</span>}
                        </ModalFooter>
                    </form>
                </Modal >
                {this.state.isnotesModal ? this.getNotesModal() : null}
                {
                    this.state.sessionExpiryModal ?
                        <Redirect to="/log_in" />
                        : null
                }
                {this.state.forceLogout ? <Redirect to="/log_in" /> : ""}
                {this.state.sessionWarning ? this.getSessionWraningModal() : null}
                {this.state.expiredSessionModal ? this.expiredSessionModal() : null}

                {/* You can pass data with Redirect like this: */}
                {this.state.isRedirectProperties ?
                    <Redirect to={{
                        pathname: "/nsproperties",
                    }}

                    /> : null}
            </div>
        )
    }
}


CreateTaskModal = reduxForm({
    form: "User Reset Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(CreateTaskModal);

// You have to connect() to any reducers that you wish to connect to yourself
CreateTaskModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(CreateTaskModal);

export default withTranslation('common')(CreateTaskModal);
