import React from 'react';
import { Button } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox'
import { withTranslation } from 'react-i18next';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { faStarOfLife, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import fetchMethodRequest from '../../../config/service';
import configMessages from '../../../config/configMessages'
import apiCalls from '../../../config/apiCalls';
import ConfirmationModal from '../../Cruds/CommonModals/ConfirmationModal';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import config from "../../../config/config"
import Select from 'react-select';

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      background: isSelected ? "rgba(28, 160, 181, 0.29)" : "",
      color: 'black',
      textAlign: 'left',
      //display: 'inline'

    };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    textTransform: 'capitalize',
    width: "max-content"
  }),
}



class EditorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiledBusinessName: "",
      selectedViewType: "Default",
      claimID: "",
      value: true,
      proof: ["Notes", "Proofs"],
      proofTab: "Notes",
      copySellerProof: false,
      copiedSellerProof: "",
      copiedAddressProof: "",
      copiedNameProof: "",
      copiedFeinProof: "",
      copiedHolderProof: "",
      copyNameProof: false,
      copyAddressProof: false,
      copyFeinProof: false,
      copyHolderProof: false,
      blockFileFields: false,
      filterCriteria: this.props.filterCriteria,
      isOpenConfirmationModal: false,
      fileForOptions: [],
      mappedNames: [],
      downloadData: [],
      screenNames: ["ns_scored_propertys", "tab2Data", "tab3Data"],
      propertyStatusArray: [],
      propertyReasonArray: [],
      isSelectedRowValuemappedName: "",
      isSelectedRowValuerelevantSellerName: ""
    };
  }
  componentDidMount = async () => {
    let downloadData = []
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.getNewStatusReasonsForEditor()
  }

  componentDidUpdate = () => {

    // if (this.state.showSelectederror) {
    //   setTimeout(() => this.setState({ showSelectederror: false, errormsg: '' }), 30000);
    // }
  }
  closeConfirmationModal = async (type) => {
    if (type === "close") {
      this.saveValidatedRecord()
      await this.setState({
        isOpenConfirmationModal: false,
        isDisabledTab: false
      })
    } else {
      await this.setState({
        isOpenConfirmationModal: false,
        isDisabledTab: false
      })
    }
  }

  flattenArray = (arrayVal) => {
    let val = "";
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val
        .replace(/"/g, "")
        .replace(/\[/g, "")
        .replace(/]/g, "")
        .replace(/{/g, "")
        .replace(/}/g, "")
        .replace(/,/g, " , ")
        .replace(/:/g, " : ");
    }
    return val;
  };

  formatChange = (data) => {
    data.forEach((item) => {
      for (let key in item) {
        if (item[key] && item[key].name) {
          item[key] = item[key].name;
        }
        if (typeof item[key] == "object") {
          delete item[key]
        }
      }
    });
    return data
  }

  downloadData = async (apiUrl, tableFieldsToShow) => {
    if (this.props.sheets) {
      await this.setState({
        sheets: this.props.sheets
      })
    } else {
      if (this.props.type === "Negative Notice List Viewer") {
        return fetchMethodRequest('GET', `${apiUrl}&type=exportToCsv`)
          .then(async (response) => {
            if (response && (response[this.state.screenNames[0]] && response[this.state.screenNames[0]].length > 0)) {
              let downloadData = []
              var headers_1 = []
              for (var obj of tableFieldsToShow) {
                if (obj.field) {
                  headers_1.push({ field: obj.field, label: obj.header })
                }
              }
              for (var i = 0; i < this.state.screenNames.length; i++) {
                let screenName = this.state.screenNames[i]
                let headers = screenName == "ns_scored_propertys" ? headers_1 : response[this.state.screenNames[i]]
                let obj = {
                  name: screenName === "ns_scored_propertys" ? "Full Details" : screenName === "tab2Data" ? "High Level" : "Pivot ",
                  headers: screenName == "ns_scored_propertys" ? headers_1 : Object.keys(headers[0]),
                  data: await this.formatChange(response[this.state.screenNames[i]])
                }

                downloadData.push(obj)
                await this.setState({
                  sheets: downloadData
                })

              }
            } else if (response && response['errorMessage'] === configMessages.warningMessage) {
              await this.setState({
                sessionWarning: true,

              })
            } 
          }).catch((err) => {
            return err;
          })
      }
    }
  }

  getConfirmaionModel() {
    return (
      <ConfirmationModal
        openConfirmationModal={this.state.isOpenConfirmationModal}
        closeConfirmationModal={this.closeConfirmationModal}
        shownText={this.state.proofTab === "Proofs" ? "Are you sure you want to update proofs for the Existing Record?" : "Are you sure you want to update notes for the Existing Record?"}
        confirm={this.saveValidatedRecord}
      />
    )
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getNewStatusReasonsForEditor = async (val) => {
    var propertyStatusArray = this.state.propertyStatusArray
    let filterCriteria = {}
    if (val) {
      filterCriteria['criteria'] = [{ key: 'Status', value: val, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
       { key: 'propertyStatusUsage', value: 'Deal Sizing Property Editor', type: 'eq', add_Filter: true }
      ];

    } else {
      filterCriteria['criteria'] =
        [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
        // { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
        { key: 'propertyStatusUsage', value: 'Deal Sizing Property Editor', type: 'eq', add_Filter: true }
      ]

    }
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            propertyStatusArray.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
          }
          await this.setState({ propertyStatusArray: propertyStatusArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ propertyStatusArray: [], propertyReasonArray: [] })
        }  else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  getReasonForEditor = async (val) => {
    let selectednote = this.state.selectednote
    if ((this.props.type === "Property Editor"||this.props.type === "Deal Sizing Property Editor")
      && !this.state.fixedNote
      // val === "MAPPING-READY TO FILE"
    ) {
      selectednote = await this.applyDefaultTemplateForSelectedRows()
    } else {
      selectednote = this.state.fixedNote
    }
    this.setState({
      selectedValidatedStatus: val,
      selectedReason: "",
      // shownreasons: this.state.ReasonsArray
    })
    if (this.props.type === "Property Editor"||this.props.type === "Deal Sizing Property Editor") {
      this.setState({ selectednote: selectednote })
    } else {
      this.setState({ selectednote: "" })
    }
    let ReasonsArray = this.state.propertyReasonArray;
    let filterCriteria = {};
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"

    filterCriteria['criteria'] = [{ key: 'Status', value: val, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }, 
     { key: 'propertyStatusUsage', value: 'Deal Sizing Property Editor', type: 'eq', add_Filter: true }
  ];
    if (val && val.length > 0) {
      return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${"reason"}`)
        .then(async (response) => {
          if (response && response["propertyStatusReasons"].length > 0) {
            let propertyReasons = response["propertyStatusReasons"]
            for (let reason of propertyReasons) {
              let index = ReasonsArray.findIndex(obj => obj.label === reason.reason)
              if (index === -1) {
                ReasonsArray.push({ label: reason.reason, value: { reason: reason.reason, tooltip: reason.statusReasonDefinition } })
              }
              if ((val === config.autoSelectStatus && reason.reason === config.autoSelectReason)) {
                this.setState({
                  newselectedReason: { reason: reason.reason, tooltip: reason.statusReasonDefinition }
                })
              }
            }
            if (ReasonsArray.length === 1) {
              this.setState({
                newselectedReason: ReasonsArray[0].value
              })
            }
            await this.setState({ propertyReasonArray: ReasonsArray })
          } else if (response && response["propertyStatusReasons"].length == 0) {
            await this.setState({ propertyReasonArray: [] })
          }  else if (response && response['errorMessage'] === configMessages.warningMessage) {
            await this.setState({
              sessionWarning: true,

            })
          }
        }).catch((err) => {
          return err;
        })
    }
  }

  getFileForEditor = async (val) => {
    let mappedNames = [];
    let url
    url = `seller/sellerRelevanceNames?estateId=${val}&nameType=mappedName`
    return fetchMethodRequest('GET', url)
      .then(async (response) => {
        if (response && response["sellerRelevanceNames"].length > 0) {
          let propertyReasons = response["sellerRelevanceNames"]
          for (let reason of propertyReasons) {
            // fileForOptions.push({ label: reason.BusinessName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
            mappedNames.push({ label: reason.CombinedName, value: reason.CombinedName, BusinessName: reason.BusinessName, nameID: reason.BusinessNamesID })
          }
          await this.setState({ mappedNames: mappedNames })
        } else if (response && response["sellerRelevanceNames"].length === 0) {
          await this.setState({ mappedNames: [] })
        }  else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
            mappedNames: [] 
          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getFileForEditorForSellerRelevanceName = async (val) => {
    let fileForOptions = [];
    // let mappedNames = [];
    let url

    url = `seller/sellerRelevanceNames?estateId=${val}&nameType=sellerRelevanceName`

    return fetchMethodRequest('GET', url)
      .then(async (response) => {
        // if (this.props.selectedOpra == "OPRA2") {
        if (response && response["sellerRelevanceNames"].length > 0) {
          let propertyReasons = response["sellerRelevanceNames"]
          for (let reason of propertyReasons) {
            // fileForOptions.push({ label: reason.BusinessName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
            fileForOptions.push({ label: reason.CombinedName, value: reason.CombinedName, BusinessName: reason.BusinessName, nameID: reason.BusinessNamesID })
          }
          await this.setState({ fileForOptions: fileForOptions })
        } else if (response && response["sellerRelevanceNames"].length === 0) {
          await this.setState({ fileForOptions: [] })
        } 
      }).catch((err) => {
        return err;
      })

  }

  clipToCopyBoard(data) {

    navigator.clipboard.writeText(data)
  }

  updateStatus = async (value, type) => {
    if (type === "status" && value.length > 0) {

      var getStatus = this.state.propertyStatusArray.filter(function (el) {
        return el.label === value
      });
      if (getStatus[0]) {
        this.setState({
          newselectedStatus: getStatus[0].value
        })
      }
    } else {
      this.setState({
        newselectedStatus: ""
      })
    }
    this.setState({
      propertyReasonArray: [],
      showSelectederror:false
    })
  }

  updateReason = async (value, type) => {
    if (type === "reason" && value.length > 0) {
      await this.getReasonForEditor(this.state.newselectedStatus.status);
      var getReason = this.state.propertyReasonArray.filter(function (el) {
        return el.label === value
      });
      if (getReason[0]) {

        this.setState({
          newselectedReason: getReason[0].value
        })
      }
    } else {
      this.setState({
        newselectedReason: ""
      })
    }
  }

  updatedNote = async (selectednote, type) => {
    if (type === "note") {
      if (this.props.type === "Negative Notice List Viewer") {
        this.setState({
          selectedExcludeNote: selectednote,

        })
      } else {
        this.setState({
          selectednote: selectednote,
          fixedNote: selectednote,
          selectedFiledBusinessName: "",
        })
      }
    } else if (type === "noteEmpty") {
      this.setState({
        selectednote: "",
        fixedNote: ""
      })
      if (this.props.type === "Negative Notice List Viewer") {
        this.setState({
          selectedExcludeNote: "",
        })

      }
    } else {
      this.setState({
        selectedViewType: selectednote
      })
    }

  }

  updatedselectedExclude = async (selectednote, type) => {
    if (type === "note") {
      this.setState({
        selectedExclude: selectednote,
      })
    } else if (type === "noteEmpty") {
      this.setState({
        selectedExclude: "",
      })
    }
  }

  removeCopiedFields = async () => {
    await this.setState({
      AddressProof: "",
      FeinProof: "",
      HolderProof: "",
      NameProof: "",
      SellerProof: "",
      copiedSellerProof: "",
      copiedAddressProof: "",
      copiedNameProof: "",
      copiedFeinProof: "",
      copiedHolderProof: "",
      copiedOnceSellerProof: false,
      copiedOnceAddressProof: false,
      copiedOnceNameProof: false,
      copiedOnceFeinProof: false,
      copiedOnceHolderProof: false,
    })
  }

  enableCopyProof = async (type) => {
    if (type === "true") {
      await this.setState({ enableCopyProof: true })
    } else {
      await this.setState({
        enableCopyProof: false,

      })
    }
  }

  updatedrelevantSellerName = async (selectednote, type) => {
    if (selectednote && this.state.mappedNames.length > 0) {
      let index2 = this.state.mappedNames.findIndex(obj =>
        obj.BusinessName === selectednote
      )
      if (index2 !== -1) {
        await this.setState({
          selectedFiledBusinessName: this.state.mappedNames[index2],
          isSelectedRowValuemappedName: selectednote
        })
      }
    } else {
      await this.setState({
        selectedFiledBusinessName: "",
        isSelectedRowValuemappedName: ""
      })
    }
  }

  updatedfileFor = async (selectednote, type) => {
    if (selectednote && this.state.fileForOptions && this.state.fileForOptions.length > 0) {
      let index = this.state.fileForOptions.findIndex(obj => obj.BusinessName === selectednote)
      if (index !== -1 && type !== "relevantSellerName") {
        await this.setState({
          relevantSellerName: this.state.fileForOptions[index],
          isSelectedRowValuerelevantSellerName: selectednote
        })
      }
    } else {
      await this.setState({
        relevantSellerName: "",
        isSelectedRowValuerelevantSellerName: ""
        // fileForOptions: []
      })
    }
  }

  updateAddressProof = async (proof, type) => {
    if (proof != "empty") {
      await this.setState({ [type]: proof })
    } else {
      if (type === "SellerProof" && this.state.copiedSellerProof) {
        await this.setState({ [type]: this.state.copiedSellerProof })
      } else
        if (type === "NameProof" && this.state.copiedNameProof) {

          await this.setState({ [type]: this.state.copiedNameProof })

        } else
          if (type === "HolderProof" && this.state.copiedHolderProof) {
            await this.setState({ [type]: this.state.copiedHolderProof })
          } else if (type === "FeinProof" && this.state.copiedFeinProof) {
            await this.setState({ [type]: this.state.copiedFeinProof })
          } else if (type === "AddressProof" && this.state.copiedAddressProof) {
            await this.setState({ [type]: this.state.copiedAddressProof })
          } else {

            await this.setState({ [type]: "" })
          }

    }
  }
  emptyStatus = async () => {
    await this.setState({ selectedValidatedStatus: "" })
  }
  updateClaimID = async (selectednote, type) => {

    if (selectednote) {
      await this.setState({
        claimID: selectednote
      })
    } else {
      await this.setState({
        claimID: ""
      })
    }
  }

  saveValidatedRecord = () => {
    let url
      url = `NsScoredProperties/validate?screen=NSD`;
    return fetchMethodRequest('POST', url, this.state.editObj)
      .then(async (response) => {
        if (response && response.respCode) {
          this.props.getshowSelectederrormsg(response.respMessage, true, "success")
          this.props.removeSelectedRows()
          let body = {
            propertyId: this.props.PropertyData.selectedProperty_Id ? this.props.PropertyData.selectedProperty_Id.toString() : null,
            statePropertyId: this.props.PropertyData.selectedOpraStId ? this.props.PropertyData.selectedOpraStId.toString() : null
          }
          this.props.getDataFromServer(this.props.filterCriteria, null, false, body)
            await this.setState({
            showSelectederror: false,
            errormsg: "",
            selectedRows: [],
            isDisabledTab: false,
            showSelectederror: false,
            selectedFiledBusinessName: "",
            errorColor: true,
            selectednote: "",
            claimID: "",
            AddressProof: "",
            FeinProof: "",
            HolderProof: "",
            NameProof: "",
            relevantSellerName: "",
            newselectedStatus: "",
            newselectedReason: "",
            SellerProof: "",
            copySellerProof: false,
            copyAddressProof: false,
            copyFeinProof: false,
            copyHolderProof: false,
            copyNameProof: false,
            copiedSellerProof: "",
            copiedAddressProof: "",
            copiedNameProof: "",
            copiedFeinProof: "",
            copiedHolderProof: "",
            copiedOnceAddressProof: false,
            copiedOnceFeinProof: false,
            copiedOnceHolderProof: false,
            copiedOnceNameProof: false,
            copiedOnceSellerProof: false,
            selectedReason: "",
            selectedValidatedStatus: "",
            errormsg: response.respMessage
          })
        } else if (response && response.errorMessage) {
          this.props.getErrorMessage(response.errorMessage, "", "error")
          if (response.invalidID) {
            let index, validatedArray = []
            let data = this.props.allUsersData
            for (var id of response.invalidID) {
              index = data.findIndex(x => x._id === id);
              validatedArray.push(this.state.allUsersData[index].propertyID)
            }
            await this.setState({
              ValidatedIds: response.invalidID,
              showSelectederror: true,
              isDisabledTab: false,
              validatedArray: validatedArray,
              errormsg: `Records with the following Property Ids are already Validated ${validatedArray}`
            })
            this.props.getshowSelectederrormsg(`Records with the following Property Ids are already Validated ${validatedArray}`, true, "error")

          }
          await this.setState({ isDisabledTab: false, })
        }
      }).catch((err) => {
        return err;
      });
  }

  applyDefaultTemplateForSelectedRows = async () => {
    let loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'))
    const shortName = new Date().toLocaleString('en-US', { month: 'short' });
    const DateName = new Date().getDate();
    let selectednote
    selectednote = ""
    // selectednote = `Seller: \nSeller Proof:\nName Proof:\nAddress Proof:\nFEIN Proof:\nHolder Proof:`
    return selectednote;
  }

  setProofTab = async (tabType) => {
    await this.setState({ proofTab: tabType })
  }

  sellerProofExpand = async (type) => {
    let expand = type + "Expand"
    await this.setState({ [expand]: this.state[expand] ? false : true })
  }
  copySellerProof = async (type) => {
    let expand = "copy" + type
    let expanded = "copied" + type
    let copiedOnce = "copiedOnce" + type
    if (!this.state[expand]) {
      this.setState({ [expanded]: this.state[type] })
    }
    await this.setState({ [expand]: this.state[expand] ? false : true, [copiedOnce]: this.state[expand] ? false : true })
  }

  handlePropertiesEditChangeProof = async (value, type, isSeller) => {
    let isEdited = this.props.checkDataisEdited()
    if (isEdited && isEdited.length > 0) {
      this.props.OpenConfirmaionModelorSave()
    } else {
      if (type === "save") {
        this.setState({
          isDisabledTab: true
        })
        let selectedRowsId = [];
        let selectedRows = [...this.props.selectedRows]

        if (selectedRows && selectedRows.length && selectedRows.length > 0) {
          selectedRows.forEach((item, index) => {
            selectedRowsId.push(item._id)
          })
        }
        if (selectedRowsId && selectedRowsId.length > 1) {
          let selectedRowsIdArr = selectedRowsId.shift();
        }
        let editObj = {
          addressProof: this.state.AddressProof ? this.state.AddressProof : "",
          feinProof: this.state.FeinProof ? this.state.FeinProof : "",
          holderProof: this.state.HolderProof ? this.state.HolderProof : "",
          nameProof: this.state.NameProof ? this.state.NameProof : "",
          sellerProof: this.state.SellerProof ? this.state.SellerProof : "",

          ids: selectedRowsId,
        }
        let uniqueState = [...new Set(selectedRows.map((item) => item.sourceState))];
        let uniquefileFor = [...new Set(selectedRows.map((item) => item.fileFor))];
        if (selectedRowsId && selectedRowsId.length === 0) {
          await this.setState({
            showSelectederror: true,
            isDisabledTab: false,
            errormsg: "Please select records to update proofs"
          })
          this.props.getshowSelectederrormsg("Please select records to update proofs", true, "error")
        }
        else if (this.state.newselectedStatus && this.state.newselectedStatus.status === "Filed" && (uniqueState.length > 1 || uniquefileFor.length > 1)) {
          await this.setState({
            showSelectederror: true,
            isDisabledTab: false,
            errormsg: "Please select the records of same filefor and source state  for status Filed."
          })
          this.props.getshowSelectederrormsg("Please select the records of same filefor and source state for status Filed.", true, "error")
        }

        else {
          await this.setState({
            editObj: editObj
          })
          if ((this.state.newselectedStatus === null || this.state.newselectedStatus === undefined || this.state.newselectedStatus === "") && this.props.selectedRows && this.props.selectedRows.length === 1 && this.state.selectednote && this.state.selectednote.length > 0 && this.state.selectednote && this.state.selectednote.length > 0) {
            await this.setState({
              isOpenConfirmationModal: true,
            })
          } else {
            this.saveValidatedRecord()
          }
        }

      }
    }
  }

  handlePropertiesEditChange = async (value, type, isSeller) => {
    let isEdited = this.props.checkDataisEdited()
    if (isEdited && isEdited.length > 0) {
      this.props.OpenConfirmaionModelorSave()
    } else {
      if (type && type === "Reason") {
        this.setState({
          selectedReason: value
        })
        this.props.getshowSelectederrormsg("", false)
      } if (type && type === "newReason") {
        this.setState({
          newselectedReason: value
        })
        this.props.getshowSelectederrormsg("", false)
      } if (type && type === "newStatus") {
        await this.setState({
          showSelectederror: false,
          errormsg: "",
          propertyReasonArray: [],
          newselectedStatus: value,
          // selectedFiledBusinessName: this.props.selectedRows.length > 1 && this.state.selectedFiledBusinessName ? this.state.selectedFiledBusinessName : "", // commented out for not to clear the dropdown values for now. 
          newselectedReason: "",
          isSelectedRowValuerelevantSellerName: this.props.selectedRows.length > 1 && this.state.isSelectedRowValuerelevantSellerName ? this.state.isSelectedRowValuerelevantSellerName : "",
          // relevantSellerName: this.props.selectedRows.length > 1 && this.state.relevantSellerName ? this.state.relevantSellerName : "", // commented out for not to clear the dropdown values for now.
          isSelectedRowValuemappedName: this.props.selectedRows.length > 1 && this.state.isSelectedRowValuemappedName ? this.state.isSelectedRowValuemappedName : "",
        })
        // if (data.status === "PENDING-CLM PENDING") {
        //   this.props.change("relevantSellerName", null)
        //   this.props.change("relevantSellerNameID", '')
        //   this.setState({
        //     selectedFiledBusinessName: ""
        //   })
        // }
        await this.getReasonForEditor(value.status);
        this.props.getshowSelectederrormsg("", false)
      } if (type && type === "selectedFiledBusinessName") {
        await this.setState({
          selectedFiledBusinessName: value,
          showSelectederror: false,
          errormsg: ""
        })
      } if (type && type === "relevantSellerName") {
        this.setState({
          relevantSellerName: value,
          showSelectederror: false,
          errormsg: ""
        })
      } else if (type === "Status") {
        await this.getReasonForEditor(value);
      } else if (type === "note") {

        this.setState({
          selectednote: value,
          showSelectederror: false,
          errormsg: ""
        })
      } else if (type === "claimID") {
        this.setState({
          claimID: value,
          showSelectederror: false,
          errormsg: ""
        })
        // this.props.getshowSelectederrormsg("", false)
      }
      else if (type === "SellerProof") {
        this.setState({
          SellerProof: value,
        })
        // this.props.getshowSelectederrormsg("", false)
      }
      else if (type === "NameProof") {
        this.setState({
          NameProof: value,
        })
        // this.props.getshowSelectederrormsg("", false)
      }
      else if (type === "AddressProof") {
        this.setState({
          AddressProof: value,
        })
        // this.props.getshowSelectederrormsg("", false)
      }
      else if (type === "FeinProof") {
        this.setState({
          FeinProof: value,
        })
        // this.props.getshowSelectederrormsg("", false)
      }
      else if (type === "HolderProof") {
        this.setState({
          HolderProof: value,
        })
        // this.props.getshowSelectederrormsg("", false)
      }
      else if (type === "Cancel") {
        this.setState({
          showSelectederror: false,
          errormsg: "",
          selectedValidatedStatus: "",
          newselectedStatus: "",
          newselectedReason: "",
          selectednote: "",
          claimID: "",
          AddressProof: "",
          FeinProof: "",
          HolderProof: "",
          NameProof: "",
          SellerProof: "",
          selectedFiledBusinessName: "",
          relevantSellerName: "",
          selectedReason: "",
          selectedRows: []
        })
        if (isSeller) {
          this.setState({
            fileForOptions: []
          })
        }
        this.props.removeSelectedRows()
        this.props.getshowSelectederrormsg("", false)
      } else if (type === "save") {
        this.setState({
          isDisabledTab: true,

        })
        let selectedRowsId = [];
        let selectedRows = [...this.props.selectedRows]
        if (selectedRows.length > 0 && this.props.activeTab === "SP") {
          var newArray = selectedRows.filter(function (el) {
            return el.propertyStatus !== "RESULT-APPROVED" && el.propertyStatus !== "FILING-CLM FILED"
          });
          selectedRows = newArray
        }
        if (selectedRows && selectedRows.length && selectedRows.length > 0) {
          selectedRows.forEach((item, index) => {
            selectedRowsId.push(item._id)
          })
        }

        let editObj = {
          // status: this.state.selectedValidatedStatus,
          note: this.state.selectednote,
          claimID: this.state.claimID,
          addressProof: this.state.AddressProof,
          feinProof: this.state.FeinProof,
          holderProof: this.state.HolderProof,
          nameProof: this.state.NameProof,
          sellerProof: this.state.SellerProof,


          ids: selectedRowsId,
        }

        // if (this.state.selectedValidatedStatus && this.state.selectedValidatedStatus.length > 0) {
        if (this.state.newselectedStatus && this.state.newselectedStatus.status.length > 0) {

          editObj.status = this.state.newselectedStatus.status
        }
        if (this.state.newselectedReason && this.state.newselectedReason.reason) {
          editObj.reason = this.state.newselectedReason.reason
        }
        if (this.state.selectedFiledBusinessName) {
          editObj.fileFor = this.state.selectedFiledBusinessName.BusinessName ? this.state.selectedFiledBusinessName.BusinessName : this.state.selectedFiledBusinessName
        }
        if (this.state.relevantSellerName) {
          editObj.relevantSellerName = this.state.relevantSellerName.BusinessName ? this.state.relevantSellerName.BusinessName : this.state.relevantSellerName

        }
        // if (this.state.addressArr && this.state.addressArr.length > 1) {
        //   await this.setState({
        //     showSelectederror: true,
        //     errormsg: "Please select the same Address Lines for all the selected rows before saving the record"
        //   })
        // } else 
        // let uniquebusinessName = [...new Set(selectedRows.map((item) => item.businessName))];

        let uniqueState = [...new Set(selectedRows.map((item) => item.sourceState))];
        let uniquefileFor = [...new Set(selectedRows.map((item) => item.fileFor))];


        if (selectedRowsId && selectedRowsId.length === 0) {
          await this.setState({
            showSelectederror: true,
            isDisabledTab: false,
            errormsg: configMessages.norecordsSelected
          })
          this.props.getshowSelectederrormsg(configMessages.norecordsSelected, true, "error")
        }
        else if (this.state.newselectedStatus.status === "Filed" && (uniqueState.length > 1 || uniquefileFor.length > 1)) {
          await this.setState({
            showSelectederror: true,
            isDisabledTab: false,
            errormsg: configMessages.sameFileforSelectedRecords
          })
          this.props.getshowSelectederrormsg(configMessages.sameFileforSelectedRecords, true, "error")
        }
        else if (!this.state.newselectedStatus.status && !this.state.newselectedReason && this.state.selectednote.length === 0 && this.state.claimID.length === 0) {
          await this.setState({
            showSelectederror: true,
            isDisabledTab: false,
            errormsg: configMessages.selectAnyone
          })
          //  this.props.getshowSelectederrormsg(configMessages.selectAnyone, true, "error")

        }
        else if (this.state.newselectedStatus.status && this.state.newselectedStatus.status.length === 0 && this.state.selectednote.length <= 25 && this.state.claimID.length === 0) {
          this.setState({
            isDisabledTab: false
          })
          if ((!this.state.newselectedReason || this.state.newselectedReason && this.state.newselectedReason.reason.length > 0 && this.state.newselectedReason.reason === "") || this.state.newselectedStatus.status === "" || (typeof (this.state.newselectedStatus.status) != "string" && this.state.newselectedStatus.status.length > 1)) {
            await this.setState({
              showSelectederror: true,
              isDisabledTab: false,
              errormsg: "Please select the Status and Status Reason to update or add note"
            })
            // this.props.getshowSelectederrormsg("Please select the Status and Status Reason to update or add note or claim id ", true, "error")
          }
        } else if (this.state.newselectedStatus.status && this.state.newselectedStatus.status.length > 0 && (this.state.newselectedReason === "" || this.state.newselectedReason === null)) {
          this.setState({
            isDisabledTab: false,
            showSelectederror: true,
            isDisabledTab: false,
            errormsg: configMessages.statusReasonMandatory

          })
          // this.props.getshowSelectederrormsg("Please select Status Reason to update ", true, "error")
        }
        //Commented out to remove the checking mapped/relevanr Seller names conditions for now when mapping properties.
        // else if (
        //   ((this.state.newselectedStatus && ( this.state.newselectedStatus.status === config.autoSelectStatus)
        //     && ((this.state.relevantSellerName.length === 0 && this.state.isSelectedRowValuerelevantSellerName.length === 0)
        //       || (this.state.selectedFiledBusinessName.length === 0 && this.state.isSelectedRowValuemappedName.length === 0)))
        //   ) && this.props.selectedRows.length === 1) {
        //   await this.setState({
        //     showSelectederror: true,
        //     isDisabledTab: false,
        //     errormsg: configMessages.mappedNameRelavantSellerNameMandatory
        //   })
        //   // this.props.getshowSelectederrormsg("Please select the Mapped Name and Relevent Seller Name before saving", true, "error")
        // }
        // else if (this.state.newselectedStatus.status === config.autoSelectStatus && this.props.selectedRows.length > 1 && this.isEmptyMappedOrSellerNamesExist(this.props.selectedRows)) {
        //   await this.setState({
        //     showSelectederror: true,
        //     isDisabledTab: false,
        //     errormsg: configMessages.emptyNamesError
        //   })
        //   // this.props.getshowSelectederrormsg("Please select the recods with same Mapped Name and Relevent Seller Name and SourceState before saving", true, "error")

        // } 

        else {
          await this.setState({
            editObj: editObj,
            showSelectederror: false,
            errormsg: ""
          })
          if ((this.state.newselectedStatus.status === null || this.state.newselectedStatus.status === undefined || this.state.newselectedStatus.status === "") && this.props.selectedRows && this.props.selectedRows.length === 1 && this.state.selectednote && this.state.selectednote.length > 0) {
            await this.setState({
              isOpenConfirmationModal: true,
            })
          } else {
            this.saveValidatedRecord()
          }
        }

      }

      // this.setState({

      // })
    }
  }
  /**
   * Checks if empty mapped name & relevant seller names exists in selected rows.
   * @param {Array} selRows 
   * @returns {Boolean}
   */
  isEmptyMappedOrSellerNamesExist = (selRows) => {
    for (let i = 0; i < selRows.length; i++) {
      if (!selRows[i].fileFor || !selRows[i].relevantSellerName) {
        return true;
      }
    }
    return false;
  }
  

  setFiledStatus = async (e) => {
    if (e === "true") {
      await this.setState({ blockFileFields: true, selectedValidatedStatus: null })
    } else {
      await this.setState({ blockFileFields: false })
    }
  }

  setFiledNote = async (e) => {
    // if (e === "true") {
    //   await this.setState({ blockPropertyFields: true })
    // } else {
    await this.setState({ blockPropertyFields: false })
    // }
  }

  removeFrozenRecords = async (e) => {
    await this.setState({
      value: e.value
    })
    let columnsData = JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));

    let columns = columnsData.details[this.props.selectedViewType ? this.props.selectedViewType : "Default"]

    for (let i = 0; i < columns.length; i++) {
      columns[i].frozen = false
    }
    await localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columnsData))
    await this.props.selectedViewType(e.value, "Frozen")
  }
  selectedViewType = async (e) => {

    await this.setState({
      selectedViewType: e
    })
    await this.props.selectedViewType(e)
  }


  editorTemplate(option) {
    if (!option.value) {
      return option.label;
    }
    else {
      return (
        <span style={{ margin: '.5em .25em 0 0' }} data-toggle="tool-tip"
          title={option.value.tooltip}
        >{option.label}</span>
      );
    }
  }



  handlegetdata = () => {

  }


  render() {
    const { optFields, selectedRows } = this.props

    const customFilter = (option, searchText) => {
      if (
        option && option.data && option.data.BusinessName && option.data.BusinessName.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    };
    let settingsData = this.props.settingsData
    let isEditted = localStorage.getItem("ISEdited") ? true : false
    return (
      <>
        {
          this.props.type !== "Negative Notice List Viewer" && this.props.screenPermissions === "Edit" && (this.props.PropertyData && this.props.PropertyData.selectedSeller && this.props.PropertyData.selectedSeller.EstateID && (this.props.type === "Property Editor"||this.props.type === "Deal Sizing Property Editor") && this.props.screenPermissions === "Edit") || this.props.type === "Claims" || this.props.type === "ClaimsManagement" ?
            < div className="ml-3 d-flex" style={{ marginTop: 10 }
            }>
              <div
                style={(this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor"||this.props.type === "Deal Sizing Property Editor")) || (this.props.type === "OPRA2 Property Editor" && this.props.PropertyData) ? {} : this.props.type === "Claims" ? {} : this.props.type === "ClaimsManagement" && this.props.totalRecordsLength > 0 ? {} : { display: 'none' }}
              >
                <div className="col-sm-12 row" style={{height:155}}>
                  <div className="col-sm-6 row">
                    <div className="col-sm-12 row" style={{ height: 60,width:900 }}>
                      <div className="col-sm-6">
                        <div
                          className="p-inputgroup searchField" style={{ display: "grid" }}
                        >
                          <span>Prop. Status
                          </span>
                          <Dropdown
                            value={this.props.selectedRows.length === 0 ? "" : this.state.newselectedStatus}
                            // appendTo={document.body}
                            filter={true}
                            style={{ borderLeft: "1px solid lightgray", height: 34 }}
                            options={this.state.propertyStatusArray}
                            disabled={this.props.type === "ClaimsManagement" && (this.props.selectedRows.length === 0) ? true : isEditted || this.props.isDisabledTab || this.props.selectedRows.length === 0 || this.state.blockFileFields}
                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "newStatus")}
                            itemTemplate={this.editorTemplate}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className=""
                          style={this.props.selectedRows.length > 0 && this.state.newselectedStatus && this.state.newselectedStatus.status ? { display: "grid" } : { display: "none" }
                          }
                        >
                          <span>Prop. Status Reason
                            {this.state.newselectedStatus && this.state.newselectedStatus.status.length > 0 ? <span >
                              <FontAwesomeIcon icon={faStarOfLife}
                                className='pl-1'
                                color='red'
                                data-toggle="tool-tip"
                                title="Mandotary Field"
                                style={{ width: 11 }}
                              /></span> : null}
                          </span>
                          <Dropdown
                            value={this.props.selectedRows.length === 0 ? "" : this.state.newselectedReason}
                            style={{ borderLeft: "1px solid lightgray", height: 34 }}
                            options={this.state.propertyReasonArray}
                            filter={true}
                            disabled={this.props.isDisabledTab || this.state.blockFileFields || isEditted}
                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "newReason")}
                            itemTemplate={this.editorTemplate}
                          />
                        </div>

                      </div>
                    </div>
                    {this.state.showSelectederror ?
                      <div className="col-sm-12 row mt-2 mb-2 errorColor" style={{ marginLeft: 1 }}>{this.state.errormsg}</div>
                      : null}
                    <div className="col-sm-12 row" style={{ height: 60 }}>
                      <div className="col-sm-4">
                        <div style={this.props.type === "Claims" ? { display: "none" } : { display: "grid" }} className='sellerCNNamesDropDown'>
                          <span>Mapped Name
                            {/* Hiding mandatory indication as check condition removed for now */}
                            {/* {((this.props.selectedRows.length === 1 &&
                              this.state.newselectedStatus &&
                               this.state.newselectedStatus.status === config.autoSelectStatus)
                               )
                               // || ( this.props.selectedRows.length !== 1 && optFields === false ))
                               ? <span >
                              <FontAwesomeIcon icon={faStarOfLife}
                                className='pl-1'
                                color='red'
                                data-toggle="tool-tip"
                                title="Mandotary Field"
                                style={{ width: 11 }}
                              /></span> : null} */}
                          </span>
                          {/* <Dropdown
                            value={this.props.selectedRows.length > 0 ? this.state.selectedFiledBusinessName : ""}
                            filter={true}
                            // appendTo={document.body}
                            filter={true}
                            style={{ borderLeft: "1px solid lightgray", height: 34 }}
                            options={this.state.mappedNames}
                            disabled={this.state.blockFileFields || isEditted}
                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "selectedFiledBusinessName")}
                          /> */}
                          <Select
                            styles={customStyles}
                            name="status"
                            id={"status"}
                            value={this.props.selectedRows.length > 0 ? this.state.selectedFiledBusinessName : ""}
                            isSearchable={true}
                            onChange={(e) => this.handlePropertiesEditChange(e, "selectedFiledBusinessName")}
                            options={this.state.mappedNames}
                            clearable={false}
                            // getOptionLabel={labelField}
                            // getOptionValue={valueField}
                            className={this.state.isEditShow ? 'react-select-isedited' : 'react-select'}
                            // className="react-select-isedited "
                            placeholder={""}
                            isDisabled={this.state.blockFileFields || isEditted}
                            classNamePrefix={`react-select`}
                           
                            style={
                              { background: 'white', textTransform: 'capitalize', color: 'black' }}
                            filterOption={customFilter}
                          />
                        </div>


                      </div>
                      <div className="col-sm-4">
                        <div style={this.props.type === "Claims" ? { display: "none" } : { display: "grid" }}>
                          <span>Relevant Seller Name
                            {/* Hiding mandatory indication as check condition removed for now */}
                            {/* {((this.props.selectedRows.length === 1 &&
                              this.state.newselectedStatus &&
                              this.state.newselectedStatus.status === config.autoSelectStatus))
                              // || ( this.props.selectedRows.length !== 1 && optFields === false ))
                              ?
                              
                              <span >
                              <FontAwesomeIcon icon={faStarOfLife}
                                className='pl-1'
                                color='red'
                                data-toggle="tool-tip"
                                title="Mandotary Field"
                                style={{ width: 11 }}
                              /></span> : null} */}
                          </span>
                          {/* <Dropdown
                            value={this.props.selectedRows.length > 0 ? this.state.relevantSellerName : ""}
                            filter={true}
                            // appendTo={document.body}
                            filter={true}
                            style={{ borderLeft: "1px solid lightgray", height: 34 }}
                            options={this.state.fileForOptions}
                            disabled={this.state.blockFileFields || isEditted}
                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "relevantSellerName")}
                          /> */}
                          <Select
                            styles={customStyles}
                            name="status"
                            id={"status"}
                            value={this.props.selectedRows.length > 0 ? this.state.relevantSellerName : ""}
                            isSearchable={true}
                            onChange={(e) => this.handlePropertiesEditChange(e, "relevantSellerName")}
                            options={this.state.fileForOptions}
                            clearable={false}
                            // getOptionLabel={labelField}
                            // getOptionValue={valueField}
                            className={this.state.isEditShow ? 'react-select-isedited' : 'react-select'}
                            // className="react-select-isedited "
                            placeholder={""}
                            isDisabled={this.state.blockFileFields || isEditted}
                            classNamePrefix={`react-select`}
                            style={
                              { background: 'white', textTransform: 'capitalize', color: 'black' }}
                            filterOption={customFilter}
                          />
                        </div>
                      </div>
                      {/* <div className="col-sm-4">
                        {this.props.type === "ClaimsManagement" ? null : <div >
                          <div style={this.props.type === "ClaimsManagement" ? { display: "none" } : { display: "grid" }}>
                            <span>{this.props.type === "Claims" ? "State Claim ID" : " St. Claim ID"}</span>
                            <InputText
                              // type="search"
                              value={this.state.claimID ? this.state.claimID : ""}
                              name='claimID'
                              id='claimID'
                              disabled={isEditted}
                              style={{ height: "34px" }}
                              // disabled={this.state.blockPropertyFields || isEditted}
                              onChange={(e) => this.handlePropertiesEditChange(e.target.value, "claimID")}

                            />
                          </div>
                        </div>}

                      </div> */}

                    </div>
                  </div>
                  <div className="col-sm-6 row" style={{ height: 60 }}>
                    <span style={{ color: "Blue", "font-weight": "bold", }}>Mapping Note :
                    </span>                    <InputTextarea
                      cols={50}
                      rows={7}
                      value={this.props.selectedRows.length > 0 ? this.state.selectednote : ""}
                      style={{
                        borderLeft: "1px solid lightgray", marginBottom: "12px", marginTop: "6px", marginLeft: "10px",maxHeight:130
                      }}
                      disabled={this.props.isDisabledTab || this.state.blockPropertyFields || isEditted}
                      // onFocus={this.changeCursorPos}
                      onChange={(e) => this.handlePropertiesEditChange(e.target.value, "note")}
                    />
                    {this.props.type === "Property Editor"||this.props.type === "Deal Sizing Property Editor" ? <FontAwesomeIcon icon={faCopy}
                      className='pl-1'
                      color='red'
                      data-toggle="tool-tip"
                      title="Copy"
                      onClick={(e) => this.clipToCopyBoard(this.state.selectednote)}
                      style={{ width: 15 }}
                    /> : null}
                    <div class="col-sm-4" style={this.props.type === "ClaimsManagement" ? { float: "right" } : { float: "right" }} >
                      {this.props.screenPermissions === "Edit" && (this.props.PropertyData && this.props.PropertyData.selectedSeller && this.props.PropertyData.selectedSeller.EstateID && (this.props.type === "Property Editor"||this.props.type === "Deal Sizing Property Editor") && this.props.screenPermissions === "Edit") || this.props.type === "ClaimsManagement" ?
                        <div style={(((this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor"||this.props.type === "Deal Sizing Property Editor"))) || (this.props.type === "OPRA2 Property Editor" && this.props.PropertyData)) || this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? { display: 'flex' } : { display: 'none' }}>
                            <Button color="primary"
                              outline
                              className="p-1 ml-2 mr-3"
                              style={{ cursor: "pointer", height: 34, width: 115, }}
                              disabled={this.props.isDisabledTab || isEditted || this.state.copiedOnceAddressProof || this.state.copiedOnceFeinProof || this.state.copiedOnceHolderProof || this.state.copiedOnceNameProof || this.state.copiedOnceSellerProof}
                              onClick={() => this.handlePropertiesEditChange("", "save")}
                            >
                              Save
                            </Button>
                          <>
                            <Button color="danger"
                              outline
                              className="p-1 ml-2 mr-3"
                              disabled={this.props.isDisabledTab || isEditted}
                              style={{ cursor: "pointer", height: 34, width: 115, }}
                              onClick={() => this.handlePropertiesEditChange("", "Cancel")}
                            >
                              Cancel
                            </Button>
                          </>
                        </div>
                        : null}
                    </div>


                  </div>




                </div>
                <div className='Row d-none'>
                  <div
                    className="searchField mr-2" style={{ display: "grid" }}
                  >
                    <p style={{ marginBottom: "10px", color: "Blue", "font-weight": "bold", }}> Evidence</p>
                    
                    <div>
                      <div class="row">
                        <div
                          className=" col-sm-1" style={{ display: "grid" }}
                        >
                          {
                            !this.state.SellerProofExpand ?
                              <div className="d-flex"><span>
                                <span>{"Seller"}</span>
                                <InputTextarea
                                  // type="search"
                                  value={this.state.SellerProof ? this.state.SellerProof : ""}
                                  name='SellerId'
                                  id='SellerId'

                                  style={{
                                    width: "250px",
                                    height: "32px"
                                  }}
                                  disabled={isEditted}
                                  // disabled={this.state.blockPropertyFields || isEditted}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "SellerProof")}


                                />
                              </span>
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>

                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("SellerProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        title="Copy"
                                        data-toggle="tooltip"
                                        // component={renderCheckBoxField}
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        name={'copy'}
                                        className=" ml-2"
                                        checked={this.state.copySellerProof}
                                        onChange={(e) => this.copySellerProof("SellerProof")}
                                      />
                                    </div> : null}
                                </span>

                              </div> :
                              <div className="d-flex"><span>
                                <span>{"Seller"}</span>
                                <InputTextarea
                                  // rows={2} cols={30}
                                  style={{
                                    width: "250px",
                                    height: "68%",
                                  }}
                                  name='SellerId'
                                  id='SellerId'
                                  disabled={isEditted}
                                  value={this.state.SellerProof ? this.state.SellerProof : ""}
                                  // onClick={(e) => this.sellerProofExpand("SellerProof")}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "SellerProof")}
                                // autoResize
                                />
                              </span>
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>

                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("SellerProof")}
                                      style={{ width: 15 }}
                                    /> */}
                                  {/* </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        title="Copy"
                                        data-toggle="tooltip"
                                        // component={renderCheckBoxField}
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        name={'copy'}
                                        className=" ml-2"
                                        checked={this.state.copySellerProof}
                                        onChange={(e) => this.copySellerProof("SellerProof")}
                                      />
                                    </div> : null}
                                </span>
                              </div>
                          }



                        </div>
                        <div
                          className=" col-sm-1" style={{ display: "grid", marginLeft: "160px" }}
                        >

                          {
                            !this.state.NameProofExpand ?
                              <div className="d-flex"><span>
                                <span>{"Name"}</span>
                                <InputTextarea
                                  // type="search"
                                  value={this.state.NameProof ? this.state.NameProof : ""}
                                  name='sellerName'
                                  id='sellerName'
                                  disabled={isEditted}
                                  style={{
                                    width: "250px",
                                    height: "32px"
                                  }}
                                  // disabled={this.state.blockPropertyFields || isEditted}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "NameProof")}

                                />
                              </span >
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>
                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("NameProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        // component={renderCheckBoxField}
                                        name={'copy'}
                                        className=" ml-2"
                                        checked={this.state.copyNameProof}
                                        onChange={(e) => this.copySellerProof("NameProof")}
                                      />
                                    </div> : null}</span>
                              </div> :
                              <div className="d-flex"><span>
                                <span>{"Name"}</span>
                                <InputTextarea
                                  style={{
                                    width: "250px",
                                    height: "68%",
                                    marginBottom: "5px"
                                  }}
                                  name='sellerName'
                                  id='sellerName'
                                  disabled={isEditted}
                                  value={this.state.NameProof ? this.state.NameProof : ""}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "NameProof")}
                                // autoResize
                                /></span>
                                <div className='mt-4'>
                                  {/* <span >
                                      <FontAwesomeIcon icon={faArrowRight}
                                        className='pl-1 ml-2'
                                        color='red'
                                        data-toggle="tool-tip"
                                        title="Expand"
                                        onClick={(e) => this.sellerProofExpand("NameProof")}
                                        style={{ width: 15 }}
                                      />
                                    </span> */}
                                  {this.state.enableCopyProof ?
                                    <span >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        // component={renderCheckBoxField}
                                        name={'copy'}
                                        className=" ml-2"
                                        checked={this.state.copyNameProof}
                                        onChange={(e) => this.copySellerProof("NameProof")}
                                      />
                                    </span> : null}</div>
                              </div>
                          }


                        </div>



                        <div
                          className="col-sm-1" style={{ display: "grid", marginLeft: "160px" }}
                        >

                          {
                            !this.state.AddressProofExpand ?
                              <div className="d-flex"><span>
                                <span> {"Address"}</span>
                                <InputTextarea
                                  // type="search"
                                  value={this.state.AddressProof ? this.state.AddressProof : ""}
                                  name='sellerAddress'
                                  id='sellerAddress'
                                  disabled={isEditted}
                                  style={{
                                    width: "250px",
                                    height: "32px"
                                  }}
                                  // disabled={this.state.blockPropertyFields || isEditted}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "AddressProof")}

                                />
                              </span>
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>

                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("AddressProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        // component={renderCheckBoxField}
                                        name={'copy'}
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        className=" ml-2"
                                        checked={this.state.copyAddressProof}
                                        onChange={(e) => this.copySellerProof("AddressProof")}
                                      />
                                    </div> : null}
                                </span>
                              </div> :
                              <div className="d-flex"><span>
                                <span>{"Address"}</span>
                                <InputTextarea

                                  style={{
                                    width: "250px"
                                    ,
                                    height: "68%",
                                  }}
                                  name='sellerAddress'
                                  id='sellerAddress'
                                  disabled={isEditted}
                                  value={this.state.AddressProof ? this.state.AddressProof : ""}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "AddressProof")}
                                // autoResize
                                />
                              </span>
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>

                                  <FontAwesomeIcon icon={faArrowRight}
                                    className='pl-1'
                                    color='red'
                                    data-toggle="tool-tip"
                                    title="Expand"
                                    onClick={(e) => this.sellerProofExpand("AddressProof")}
                                    style={{ width: 15 }}
                                  />
                                </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        // component={renderCheckBoxField}
                                        name={'copy'}
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        className=" ml-2"
                                        checked={this.state.copyAddressProof}
                                        onChange={(e) => this.copySellerProof("AddressProof")}
                                      />
                                    </div> : null}
                                </span>
                              </div>
                          }
                        </div>



                        <div
                          className=" col-sm-1" style={{ display: "grid", marginLeft: "160px" }}
                        >
                          {
                            !this.state.FeinProofExpand ?
                              <div className="d-flex"><span>
                                <span> {"FEIN"}</span>
                                <InputTextarea
                                  // type="search"
                                  value={this.state.FeinProof ? this.state.FeinProof : ""}
                                  name='sellerFein'
                                  id='sellerFein'
                                  style={{
                                    width: "250px",
                                    height: "32px"
                                  }}
                                  disabled={isEditted}
                                  // disabled={this.state.blockPropertyFields || isEditted}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "FeinProof")}

                                />
                              </span>
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>
                                      <FontAwesomeIcon icon={faArrowRight}
                                        className='pl-1'
                                        color='red'
                                        data-toggle="tool-tip"
                                        title="Expand"
                                        onClick={(e) => this.sellerProofExpand("FeinProof")}
                                        style={{ width: 15 }}
                                      />
                                    </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        // component={renderCheckBoxField}
                                        name={'copy'}
                                        className=" ml-2"
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        checked={this.state.copyFeinProof}
                                        onChange={(e) => this.copySellerProof("FeinProof")}
                                      />
                                    </div> : null}
                                </span>
                              </div> :
                              <div className="d-flex"><span>
                                <span>{"FEIN"}</span>
                                <InputTextarea

                                  style={{
                                    width: "250px"
                                    ,
                                    height: "68%",
                                  }}
                                  name='sellerFein'
                                  id='sellerFein'
                                  disabled={isEditted}
                                  value={this.state.FeinProof ? this.state.FeinProof : ""}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "FeinProof")}
                                // autoResize 
                                />
                              </span>
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>
                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("FeinProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        // component={renderCheckBoxField}
                                        name={'copy'}
                                        className=" ml-2"
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        checked={this.state.copyFeinProof}
                                        onChange={(e) => this.copySellerProof("FeinProof")}
                                      />
                                    </div> : null}
                                </span>
                              </div>
                          }




                        </div>
                        <div
                          className=" col-sm-1" style={{ display: "grid", marginLeft: "160px" }}
                        >
                          {
                            !this.state.HolderProofExpand ?
                              <div className="d-flex"><span>
                                <span> {"Holder"}</span>
                                <InputTextarea
                                  // type="search"
                                  value={this.state.HolderProof ? this.state.HolderProof : ""}
                                  name='sellerHolder'
                                  style={{
                                    width: "250px",
                                    height: "32px"
                                    ,
                                    // height: "100%"
                                  }}
                                  id='sellerHolder'
                                  disabled={isEditted}
                                  // disabled={this.state.blockPropertyFields || isEditted}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "HolderProof")}

                                />
                              </span>
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>
                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("HolderProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        // component={renderCheckBoxField}
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        name={'copy'}
                                        className=" ml-2"
                                        checked={this.state.copyHolderProof}
                                        onChange={(e) => this.copySellerProof("HolderProof")}
                                      />
                                    </div>
                                    : null}
                                </span>
                              </div> :
                              <div className="d-flex"><span>
                                <span>{"Holder"}</span>
                                <InputTextarea

                                  style={{
                                    width: "250px",
                                    height: "68%",
                                  }}
                                  id='sellerHolder'
                                  disabled={isEditted}
                                  value={this.state.HolderProof ? this.state.HolderProof : ""}
                                  onChange={(e) => this.handlePropertiesEditChange(e.target.value, "HolderProof")}
                                // autoResize 
                                />
                              </span>
                                <span className='mt-4'>
                                  {/* <div className='ml-2'>
                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("HolderProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                  {this.state.enableCopyProof ?
                                    <div >
                                      <Checkbox
                                        label=''
                                        type='checkbox'
                                        // component={renderCheckBoxField}
                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                        name={'copy'}
                                        className=" ml-2"
                                        checked={this.state.copyHolderProof}
                                        onChange={(e) => this.copySellerProof("HolderProof")}
                                      />
                                    </div>
                                    : null}
                                </span>
                              </div>
                          }
                        </div>
                        <div
                          className=" col-sm-1" style={{ display: "grid", marginLeft: "140px" }}
                        >
                          <Button color="primary"
                            outline
                            className="p-1 ml-2 mr-3"
                            disabled={this.state.copiedOnceAddressProof || this.state.copiedOnceFeinProof || this.state.copiedOnceHolderProof || this.state.copiedOnceNameProof || this.state.copiedOnceSellerProof ? false : true}
                            style={{ cursor: "pointer", height: 34, width: 166, marginTop: "20px" }}
                            onClick={() => this.handlePropertiesEditChangeProof("", "save")}
                          >
                            Copy & Save Evidence
                          </Button>

                        </div>







                      </div>
                    </div>
                  </div>
                </div>

                

              </div>
            </div >

            : null
        }
      
        {
          this.state.isOpenConfirmationModal ?
            this.getConfirmaionModel() : null
        }
      </>

    );
  }
}
export default withTranslation('common')(EditorComponent);