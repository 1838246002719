import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';
import config from '../../../../config/config'

// config file
export default class DealSizingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'OPRA2 Property Editor',
      propertyFilterSortOptions: [
        { label: 'Property Name', value: "propertyName" },
        { label: 'Prop.Address', value: "propertyAddressLine1" },
        { label: 'Business Name', value: "businessName" },
        { label: 'Property Zip Code', value: "propertyZipCode" },
        { label: 'Property City', value: "propertyCity" },
        { label: 'Property State', value: "propertyState" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getReportSummaryDetails = async (response) => {
    await this.setState({ hidePropValues: false, TotalKnownSubsidiaries: response.knownSubsidiaryCount, MinimumNumberOfPropertiesIdentified: response.minProperties, EstimatedOpportunitySize: response.estimatedOppSize, Numberofstateswithpropertie: response.statesWithProperties, Numberofpropertiesk: response.properties_10k, Numberofpropertiesp: response.properties_50k })
  }

  getTableForDSReport() {
    let val
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: config.currency,
    });
    if (this.state.EstimatedOpportunitySize) {
      val = dollarUS.format(this.state.EstimatedOpportunitySize).slice(0, -3)
    }
    return (
      <div className='col-sm-6 pl-0 mt-3'>
        <div className='d-flex'>
          <div className='col-sm-5 pl-0' style={{ fontWeight: 'bold' }}>Total known subsidiaries</div>
          {":"}
          <div className='col-sm-4' >{this.state.hidePropValues ? "" : this.state.TotalKnownSubsidiaries}</div>
        </div>
        <div className='d-flex'>
          <div className='col-sm-5 pl-0' style={{ fontWeight: 'bold' }}>Minimum Number of Properties identified</div>
          {":"}
          <div className='col-sm-4'>{this.state.hidePropValues ? "" : this.state.MinimumNumberOfPropertiesIdentified}</div>
        </div>
        <div className='d-flex'>
          <div className='col-sm-5 pl-0' style={{ fontWeight: 'bold' }}>Estimated Opportunity Size</div>
          {":"}
          <div className='col-sm-4'>{this.state.hidePropValues ? "" : val ? val : ""}</div>
        </div>
        <div className='d-flex'>
          <div className='col-sm-5 pl-0' style={{ fontWeight: 'bold' }}>Number of states with properties</div>
          {":"}
          <div className='col-sm-4'>{this.state.hidePropValues ? "" : this.state.Numberofstateswithpropertie}</div>
        </div>
        <div className='d-flex'>
          <div className='col-sm-5 pl-0' style={{ fontWeight: 'bold' }}>{"Number of properties > $10K"}</div>
          {":"}
          <div className='col-sm-4'>{this.state.hidePropValues ? "" : this.state.Numberofpropertiesk}</div>
        </div>
        <div className='d-flex'>
          <div className='col-sm-5 pl-0' style={{ fontWeight: 'bold' }}>{"Number of properties > $50K"}</div>
          {":"}
          <div className='col-sm-4'>{this.state.hidePropValues ? "" : this.state.Numberofpropertiesp}</div>
        </div>
      </div>
    )
  }

  getSettingsData = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];

    fetchMethodRequest('GET', `PropertyStatus?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["propertyStatuses"].length > 0) {
        let propertyStatuses = response["propertyStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        this.setState({ settingsData: StatusArray })
      }
    })
    return this.state.settingsData;
  }




  getTableFields = () => {

    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "field": "BusinessName",
        "mobile": true,
        "fieldType": "redirect",
        "header": "Business Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "Properties",
        "allowInt": true,
        filterType: "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "No. of Properties",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "States",
        "mobile": true,
        "fieldType": "StatesArray",
        "header": "States",
        "displayInSettings": true,
        "filter": false,
        // "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "KnownProperties",
        "mobile": true,
        "fieldType": "ShowData1",
        "allowInt": true,
        filterType: "num",
        "header": "No. of Prop with known $",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },


    ]
    return data;
  };
  getFormFields = () => {

    return
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          sortField={"BusinessName"}
          deleteRequired={false}
          getReportSummaryDetail={this.getReportSummaryDetails}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          isClearFilters={true}
          propertyFilterSortOptions={this.state.propertyFilterSortOptions}
          globalSearchFieldName='user'
          scrollHeight={"580px"}
          globalSearch={""}
          getTableForDSReport={this.getTableForDSReport()}
          type={"Deal Sizing Report For Companies"}
          displayright={true}
          icon='lnr lnr-menu-circle'
          settingsData={this.state.settingsData}
          // fontAwesome={true}
          routeTo='dealsizingreport'
          displayViewOfForm='modal'
          activeTab={"Green"}
          apiResponseKey='deal_sizing_reports'
          className={true}
          apiUrl={"nsscoredproperties/dealsizingsummary"}
          entityType='employee'
        />

      </span>
    );
  }
}