import React from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
export default class autoComplete extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,
        })
    };
    constructor(props) {
        super(props);
        this.state = {
            isValidate: false,
            filteredSuggestions: [],
            errorMessage: '',
            sessionExpiryModal: false,
            isEditShow: false,
        };
        this.getAllSuggestions('', 'cont');

    }

    // to get all suggestions in autocomplete
    getAllSuggestions = (event, type) => {

        this.setState({
            isValidate: false,
            noData: false,
            errorMessage: ''
        });
        let filterCriteria = {
            limit: 30,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };
        if (this.props.filterCriteria) {
            filterCriteria['criteria'] = this.props.filterCriteria
        }
        if (event && event.query && (this.props.searchField || this.props.input) && this.props.input.value) {
            filterCriteria['criteria'].push({
                "key": this.props.searchField,
                "value": event.query,
                "type": "regexOr"
            });
        };

        let apiUrl = this.props.searchApi;
        let url
        if (apiUrl) {
            if (apiUrl === "businessNames/companies") {
                let filterCriteria = { "limit": 20, "page": "1", "criteria": [], "sortfield": "CompanyName", "direction": "asc", "globalSearch": { "value": event.query, "type": "user" } }
                url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;

            } else if (this.props.filterCriteria) {
                url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}&ignoreUnassigned=true`;
            } else {
                if (!this.props.type.includes("Queue") && this.props.input.name !== "estateName") {

                    url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
                } else {
                    if (this.props.type == "Deal Sizing Queue") {
                        url = `${apiUrl}?filter={"limit":10,"page":1,"criteria":[{"key":"EntityName","value":"${event.query ? event.query : ""}","type":"regexOr"}],"sortfield":"EntityName","direction":"desc"}`;
                    } if (this.props.type === "Regular Scoring Queue") {
                        url = `${apiUrl}?filter={"limit":10,"page":"1","criteria":[],"sortfield":"created","direction":"desc","globalSearch":{"value":"${encodeURIComponent(event.query ? event.query : event)}","type":"user"}}`;
                    } else {
                        if (event.query) {
                            url = `${apiUrl}?str=${event.query ? event.query : ""}`;
                        } else {
                            url = `${apiUrl}`
                        }
                    }
                    apiUrl = "sellers"

                }
            }
            return fetch('GET', url)
                .then((response) => {
                    if (response) {
                        let dropdownData = [];
                        if (apiUrl === "businessNames/companies") {
                            apiUrl = "companies"
                        }
                        if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                            dropdownData = response[apiUrl]
                        } else if (response && response.errorMessage && response.errorMessage === "Session expired please login again.") {
                            this.setState({
                                sessionExpiryModal: true
                            });
                        }
                        if (dropdownData && dropdownData.length === 0) {
                            this.setState({
                                filteredSuggestions: [],
                                noData: true
                            });
                        } else {
                            this.setSuggestions(dropdownData);
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        } else {
            if (this.props.className) {
                url = `properties/DealsDropdown?str=${event && event.query ? event.query : this.props.input.value && typeof this.props.input.value === "object" ? this.props.input.value.estateName : this.props.input.value}`;
                let userBody = {
                    str: event && event.query ? event.query : this.props.input.value && typeof this.props.input.value === "object" ? this.props.input.value.estateName : this.props.input.value,
                }
                return fetch('GET', url, userBody)
                    .then((response) => {
                        if (response) {
                            let apiUrl = "sellers"
                            let dropdownData = [];
                            if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                                dropdownData = response[apiUrl]
                            } else if (response && response.errorMessage && response.errorMessage === "Session expired please login again.") {
                                this.setState({
                                    sessionExpiryModal: true
                                });
                            }
                            if (dropdownData && dropdownData.length === 0) {
                                this.setState({
                                    filteredSuggestions: [],
                                    noData: true
                                });
                            } else {

                                this.setSuggestions(dropdownData);
                            }
                        }
                    }).catch((err) => {
                        return err;
                    });
            }

        }

    }
    // set filtered sugeestions, removing duplicates
    setSuggestions = async (dropdownData) => {
        if (this.props.input && this.props.input.value) {
            if (this.props.input.value.length > 0) {
                let values = this.props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        if (this.props.searchField) { } else {
            if (this.props.handleEstateNames) {
                this.props.handleEstateNames('', '', dropdownData)
            }
        }
        if (this.props.dealDropDown) {
            dropdownData = dropdownData.map((e) => { return { ...e, custLabel: `${e.DealType} - ${e.SellerNickName} (${e.DealYear ? e.DealYear : ""})`, estateName: e.EstateName, estateID: e.EstateID } })
            // dropdownData = dropdownData.map((e) => { return { ...e, custLabel: `${e.DealType} - ${e.SellerNickName} (${e.DealYear ? e.DealYear : ""})` } })
        }
        await this.setState({
            filteredSuggestions: dropdownData
        });
    }

    // on selected value from suggestions
    onSelectRecord(e) {
        this.setState({
            noData: false,
            isValidate: false,
            errorMessage: '',
            isEditShow: this.props.isEditedShow ? true : false,
        });
        if (this.props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            // this.getAllSuggestions(e.value)

            if (this.props.searchField) { } else {
                if (this.props.handleEstateNames) {
                    this.props.handleEstateNames(e.value, name, this.state.filteredSuggestions)
                }
            }
            if (e && e.value) {
                this.removeDuplicates(e.value, name);
            }
        }

    }

    // to remove duplicates
    removeDuplicates() {
        if (this.state.filteredSuggestions) {
            this.setSuggestions(this.state.filteredSuggestions);
        }
    }
    itemTemplate(brand) {

        if (brand.SellerNickName) {
            return (
                <div className="p-clearfix">
                    <div >{`${brand.DealType} - ${brand.SellerNickName} (${brand.DealYear ? brand.DealYear : ""})`}</div>
                </div>
            );
        } else {
            return (
                <div className="p-clearfix">
                    <div >{brand.taskID + "-" + brand.title}</div>
                </div>
            );
        }
    }

    render() {
        const {
            placeholder, input, multiple
        } = this.props;
        const { touched, error } = this.props.meta;
        return (
            <div className="date-picker">
                <span className={`p-fluid ${this.state.isEditShow ? "autocomplete_is_edit_show" : ""}`}>
                    <AutoComplete style={{ background: 'white' }}
                        multiple={multiple}
                        value={(input && input.value) ? input.value : null}
                        suggestions={this.state.filteredSuggestions}
                        completeMethod={(e) => this.getAllSuggestions(e)}
                        size={20}
                        minLength={3}
                        itemTemplate={this.props.itemTemplate ? this.itemTemplate : null}
                        placeholder={placeholder}
                        dropdown={true}
                        onChange={(e) => this.onSelectRecord(e)}
                        field={this.props.dealDropDown ? "custLabel" : this.props.searchField ? this.props.searchField : "estateName"}
                        disabled={this.props.disabled}
                    />
                </span>
                <div style={this.state.noData && touched && error ? { height: 30 } : { height: 10 }}>
                    {touched && error && <span className="form__form-group-error">{error}</span>}
                    {this.state.noData && <span className="form__form-group-error">{'No records found'}</span>}
                    {this.state.isValidate ?
                        <span className="form__form-group-error">{this.state.errorMessage}</span>
                        : null}
                </div>
                {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}
            </div>
        )
    }
}