import React from 'react';
import Table from 'react-bootstrap/Table';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { InputText } from 'primereact/inputtext';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import config from '../../../config/config';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
//Loader
import Loader from '../../App/Loader';
import fetchMethodRequest from '../../../config/service';
// config file
export default class ValidatedPropertyUpdaterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            click: false,
            showHelp: false,
            data: [],
            tableRows: [],
            progress: "0"
        };
    }

    componentDidMount = async () => {
    }

    onPropertyIdChange = async (e) => {
        this.setState({
            PropertyId: e.target.value
        })
    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    getSearchDataFromServer = async () => {
        this.setState({
            isLoading: true
        })
        this.handlePercentage('1')
        let url = `seller/updatePropertyAddressDetails?estateID=${this.props.estate.EstateID}&estateName=${this.props.estate.EstateName}`

        await fetchMethodRequest('GET', url)
            .then(async (response) => {
                if (response) {
                    if (response.respCode && response.respMessage) {
                        showToasterMessage(response.respMessage, 'success');
                        await this.setState({
                            data: response.details,
                            isLoading: false
                        })
                    } else if (response.errorMessage) {
                        // display message
                        showToasterMessage(response.errorMessage, 'error');
                        this.setState({
                            isLoading: false
                        })
                    }
                }
            })

        this.props.closeValidatedPropertyUpdaterModal()
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.openValidatedPropertyUpdaterModal} fade={false}
                style={{ width: 1500 }}
                    className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'
                >
                    <ModalBody className='deleteModalBody' style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>{`The system will update ALL properties for this deal in OPRA2 with the latest available information in the UP Property Database. Depending on the size of the deal, this process can take anywhere from few minutes to up to 4 hours. You will get an email once this process is completed.`}</div>
                        <Button color="primary" outline
                            onClick={this.props.closeValidatedPropertyUpdaterModal}
                            className='deleteModalBtn marginRight'>{'No'}</Button>
                        <Button color="primary" outline onClick={this.getSearchDataFromServer}
                            className='deleteModalBtn'>{'Yes'}</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}