import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";
import fetchMethodRequest from '../../../../config/service';
import configMessages from '../../../../config/configMessages';

// config file
export default class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      type: "Utilization-Integrity"
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  componentDidMount = async () => {
    if (window.location.href.includes("integrity")) {
      this.setState({ type: "Utilization - Integrity" });
    } else {
      this.setState({ type: "Utilization - US Team" });
    }
    await this.getDepartments()
  }

  getDepartments = () => {
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }]
    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          if (response && response.departments) {
            let labels = response.departments;
            let modifiedLabels = [];
            for (let label of labels) {
              modifiedLabels.push({
                label: label.departmentName,
                value: label.departmentName,
                departmentId: label._id

              })
            } const sorted = modifiedLabels.sort((a, b) => {
              let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            await this.setState({
              taskDepartmentItems: sorted
            })

          }
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  getTableFields = () => {

    let data = [
      {
        textAlign: "left",
        width: 100,
        field: "label",
        mobile: true,
        header: "Step",
        fieldType: "label",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 100,
        field: "user",
        mobile: true,
        header: "Team Member",
        fieldType: "splitVal",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        field: "monday",
        mobile: true,
        header: "Mon",
        fieldType: "Array",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        field: "tuesday",
        mobile: true,
        header: "Tue",
        fieldType: "Array",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 170,
        field: "wednesday",
        fieldType: "Array",
        mobile: true,
        header: "Wed",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 170,
        field: "thursday",
        fieldType: "Array",
        mobile: true,
        header: "Thu",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 170,
        field: "friday",
        mobile: true,
        header: "Fri",
        fieldType: "Array",
        filter: false,
        sortable: false,
        show: true,
        textCapitalize: true,
      },
      // {
      //   textAlign: "left",
      //   width: 170,
      //   field: "saturday",
      //   fieldType: "Array",
      //   mobile: true,
      //   header: "Sat",
      //   filter: false,
      //   sortable: false,
      //   show: true,
      //   textCapitalize: true,
      // }, {
      //   textAlign: "left",
      //   width: 170,
      //   field: "sunday",
      //   fieldType: "Array",
      //   mobile: true,
      //   header: "Sun",
      //   filter: false,
      //   sortable: false,
      //   show: true,
      //   textCapitalize: true,
      // },

    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "user_name",
        label: "User Name",
        id: "user_name",
        placeholder: "User Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        onRef={(ref) => (this.datatableref = ref)}
        getTableFields={this.getTableFields}
        // formFields={this.getFormFields}
        sortedField={"label"}
        groupField={"label"}
        statusFilter={false}
        className={true}
        exportRequried={false}
        sortField="label"
        printRequried={false}
        addRequried={false}
        editRequired={false}
        deleteRequired={false}
        viewRequired={false}
        settingsRequired={false}
        filterRequired={false}
        gridRequried={false}
        sample={false}
        globalSearchFieldName='user'
        taskDepartmentItems={this.state.taskDepartmentItems}
        apiResponseKey="results"
        // globalSearch={'Search'}
        type={this.state.type}
        displayright={true}
        icon='chart-bars'
        routeTo='reports'
        displayViewOfForm='modal'
        apiUrl={apiCalls.ReportsDashboard}
        entityType='employee'
      />
    );
  }
}
