import fetchMethodRequest from '../config/service';
import dateFormats from '../containers/UI/FormatDate/formatDate';

const HIDE_REL_IDS = [56, 60, 72, 80];
export const DOC_SCOPE_DEAL = 'Deal';
export const DOC_SCOPE_CLAIMID = 'ClaimId';
export const DOC_SCOPE_STATE = 'State';
export const DOC_SCOPE_REL_SELLER = 'Relevant Seller';
export const DOC_SCOPE_MAPPED_NAME = 'Mapped Name';
export const DOC_SCOPE_ADDRESS = 'Address';
export const DOC_UPLOAD_TAB_NAME = 'Document Upload';
export const AVAIL_DOCS_TAB_NAME = 'Available Doccuments';
export const CLAIM_PKT = 'CLM PACKET';
export const MAPPED_NAME_ADDRESS_EVID = 'MAPPED NAME ADDRESS EVID';
export const CHAIN_OF_TITLE_DOC_TYPE = 'MAPPED NAME CHAIN OF TITLE';

export const getCategories = async () => {
    const result = await fetchMethodRequest('GET', 'documentCategory?type=exportToCsv');
    if (result && result.documentCategories && result.documentCategories.length > 0) {
        return Promise.resolve(result.documentCategories);
    } else {
        return Promise.resolve([]);
    }
}

export const getSources = async () => {
    const result = await fetchMethodRequest('GET', 'documentSource?type=exportToCsv');
    if (result && result.documentSources && result.documentSources.length > 0) {
        return Promise.resolve(result.documentSources);
    } else {
        return Promise.resolve([]);
    }
}

export const getDocTypes = async (filterCriteria) => {
    let url
    if (filterCriteria) {
        url = `documentType?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`
    } else {
        url = 'documentType?type=exportToCsv'
    }
    const result = await fetchMethodRequest('GET', url);
    if (result && result.documentTypes && result.documentTypes.length > 0) {
        return Promise.resolve(result.documentTypes);
    } else {
        return Promise.resolve([]);
    }
}

export const getRelevenceTypes = async (filterCriteria) => {
    let url
    if (filterCriteria) {
        url = `relevanceType?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`
    } else {
        url = 'relevanceType?type=exportToCsv'
    }
    const result = await fetchMethodRequest('GET', url);
    if (result && result.relevanceTypes && result.relevanceTypes.length > 0) {
        const filteredRel = result.relevanceTypes.filter((e) => HIDE_REL_IDS.includes(e.Id) === false);
        filteredRel.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0))
        return Promise.resolve(filteredRel);
    } else {
        return Promise.resolve([]);
    }
}

export const updateDocumentRecord = async (data) => {
    const result = await fetchMethodRequest('POST', `UpdateDocuments`, data);
    return Promise.resolve(result);
}

export const getSharepointDetails = async (dealID, documentID) => {
    const result = await fetchMethodRequest('POST', `GetPDFDocument?DealId=${dealID}&DocumentID=${documentID}`);
    if (result && result.value && result.value.length > 0) {
        return Promise.resolve(result.value);
    } else {
        return Promise.resolve([]);
    }
}

export const getDocumentList = async (body) => {
    const result = await fetchMethodRequest('POST', `getDocuments`, body);
    if (result && result.respCode && result.respCode === 200 && result.outResponse && result.outResponse.details && result.outResponse.details.length > 0) {
        const docList = getUniqueDocuments(result.outResponse.details);
        return Promise.resolve(docList);
    } else {
        return Promise.resolve([]);
    }
}

const getUniqueDocuments = (docList) => {
    let uniqList = [];
    docList.forEach(function (item) {
        let i = uniqList.findIndex(x => (x.id == item.id));
        if (i === -1) {
            uniqList.push(item);
        }
    });
    return uniqList;
}

export const getDeals = async (searchValue, searchType) => {
    let api = 'properties/DealsDropdown'
    if (searchType === 'str') {
        api = `${api}?str=${searchValue}`;
    } else {
        api = `${api}?dealID=${searchValue}`;
    }
    const result = await fetchMethodRequest('GET', api);
    if (result && result.sellers && result.sellers.length > 0) {
        return Promise.resolve(result.sellers.map((e) => { return { ...e, combName: `${e.DealType} - ${e.SellerNickName} (${e.DealYear ? e.DealYear : ''})` } }));
    } else {
        return Promise.resolve([]);
    }
}
export const getSubCategories = async () => {
    const filters = { "criteria": [{ "key": "activeIndicator", "value": true, "type": "eq", "addFilter": true }, { "key": "categoryName", "value": "Claim Packet", "type": "eq", "addFilter": true }] }
    const result = await fetchMethodRequest('GET', `subcategory?type=exportToCsv&filter=${JSON.stringify(filters)}`);
    if (result && result.subCategories) {
        return Promise.resolve(result.subCategories);
    } else {
        return Promise.resolve([]);
    }
}
export const getNames = async (type, dealID) => {
    const result = await fetchMethodRequest('GET', `seller/sellerRelevanceNames?estateId=${dealID}&nameType=${type}`);
    if (result && result.sellerRelevanceNames && result.sellerRelevanceNames.length > 0) {
        return Promise.resolve(result.sellerRelevanceNames);
    } else {
        return Promise.resolve([]);
    }
}

export const getStateClaimIds = async (dealId, stateCode) => {
    const filter = {
        "criteria": [
            { "key": "CompanyID", "value": dealId, "type": "eq" },
            { "key": "SourceState", "value": stateCode, "type": "eq" },
            { "key": "ClaimStatus", "value": 'Paid', "type": "ne" }
        ],
    };
    const result = await fetchMethodRequest('GET', `nsClaim?filter=${JSON.stringify(filter)}&type=exportToCsv&project=false`);
    if (result && result.claims && result.claims.length > 0) {
        const data = result.claims.filter((elem) => elem.stateClaimID)
        return Promise.resolve(data);
    } else {
        return Promise.resolve([]);
    }
}

export const getStateAttributes = async (dealType, stateCode, selCategory) => {
    let criteria = [
        { "key": "state", "value": stateCode ? stateCode : 'No State', "type": "eq", "addFilter": true },
        { "key": "Category", "value": "Claim Packet", "type": "eq", "addFilter": true },
        { "key": "activeIndicator", "value": true, "type": "eq", "addFilter": true },
        { "key": "dealType", "value": dealType, "type": "eq", "addFilter": true }
    ];
    if (selCategory) {
        criteria.push({ "key": "SubCategory", "value": selCategory, "type": "eq", "addFilter": true });
    }
    const filter = { "criteria": criteria, limit: 100 }
    const result = await fetchMethodRequest('GET', `StateUpFiling?type=exportToCsv&filter=${JSON.stringify(filter)}`);
    if (result && result.stateUpFilings && result.stateUpFilings.length > 0) {
        let stateUpFilings = result.stateUpFilings.filter((elem) => elem.RelevanceTypeName && elem.DocumentTypeName);
        stateUpFilings.sort((a, b) => a.ValueSequence - b.ValueSequence);
        return Promise.resolve(stateUpFilings);
    } else {
        return Promise.resolve([]);
    }
}

export const getAvailableDocuments = async (selData, stateAttributes, categories, from, docType, screen) => {
    let relTypes = [];
    let docTypes = [];
    let subcategoriesData = {};    
    if (categories && categories.length > 0) {
        categories.forEach((e) => {
            subcategoriesData[e.subCategoryName] = {
                SubCategory: e.subCategoryName,
                DocumentScope: e.documentScopeName,
                relevanceType: [],
                DocumentType: []
            }
            if(e.subCategoryName === 'ADDR EVID'){
                subcategoriesData[e.subCategoryName].BusinessLocationID = '';
            }
        });
    }
    
    stateAttributes.forEach((e) => {
        if (e.DocumentTypeName) {
            docTypes.push(e.DocumentTypeName);
        }
        if (e.RelevanceTypeName && !relTypes.includes(e.RelevanceTypeName)) {
            relTypes.push(e.RelevanceTypeName);
        }
        if (subcategoriesData[e.SubCategory]) {
            if (from && from === 'chainOfTitle') {
                subcategoriesData[e.SubCategory].relevanceType.push('CHAIN OF TITLE');
                subcategoriesData[e.SubCategory].DocumentType.push('MAPPED NAME CHAIN OF TITLE');
            } else {
                if (subcategoriesData[e.SubCategory].relevanceType && e.RelevanceTypeName && !subcategoriesData[e.SubCategory].relevanceType.includes(e.RelevanceTypeName)) {
                    subcategoriesData[e.SubCategory].relevanceType.push(e.RelevanceTypeName);
                }
                if (subcategoriesData[e.SubCategory].DocumentType && e.DocumentTypeName) {
                    subcategoriesData[e.SubCategory].DocumentType.push(e.DocumentTypeName);
                }
            }

        }
    });
    if (from && from === 'chainOfTitle') {
        docTypes = ['MAPPED NAME CHAIN OF TITLE'];
        relTypes = ['CHAIN OF TITLE']
    }
    let data = (Object.values(subcategoriesData));
    for (let i = 0; i < data.length; i++) {
        data[i].relevanceType = data[i].relevanceType.join(',');
        data[i].DocumentType = data[i].DocumentType.join(',');
    }
    let body = {
        DealId: selData.deal && selData.deal.DealID ? selData.deal.DealID : selData.DealId ? selData.DealId : '',
        relevanceType: relTypes.join(','),
        DocumentType: docTypes.join(','),
        Body: data
    }
    if (from && (from === 'claimBuilder' || from === 'chainOfTitle')) {
        body = { ...body, ...selData }
    } else {
        if (selData.selMappedName && selData.selMappedName.BusinessNamesID) {
            body.MappedNameID = selData.selMappedName.BusinessNamesID;
        }
        if (selData.selRelSellerName && selData.selRelSellerName.BusinessNamesID) {
            body.BusinessNameId = selData.selRelSellerName.BusinessNamesID;
        }
        if (selData.selState && selData.selState.shortCode) {
            body.State = selData.selState.shortCode;
        }
        if (selData.selStateClaimId && selData.selStateClaimId.stateClaimID) {
            body.StateClaimID = selData.selStateClaimId.stateClaimID;
        }

    }
    if (docType && docType === 'OPP CLMNT INFO') {
        body.DealId = 1
        body.BusinessNameId = null
        body.OppDealId = 1
    }
    const result = await fetchMethodRequest('POST', `getDocuments?SpData=${screen && screen === "AvailableDocs" ? false : true}`, body)
    if (result && result.outResponse && result.outResponse.details && result.outResponse.details.length > 0) {
        return Promise.resolve(result.outResponse.details);
    } else {
        return Promise.resolve([]);
    }
}

export const formatAvailDocumentsData = (statAttributes, uploadedDocs) => {
    let tableData = [];
    for (let i = 0; i < statAttributes.length; i++) {
        let rowData = statAttributes[i];
        if (rowData.DocumentTypeName) {
            rowData.documentNames = [];
            rowData.documentNotes = [];
            rowData.documentNumber = [];
            rowData.docId = [];
            rowData.fileId = [];
            rowData.documentSourceName = [];
            rowData.documentSourceTypeName = [];
            rowData.documentTagName = [];
            let uploadedDocsWithDate = [];
            let uploadedDocsWithoutDate = [];
            for (let i = 0; i < uploadedDocs.length; i++) {
                if (uploadedDocs[i].createdDateUTC) {
                    uploadedDocsWithDate.push(uploadedDocs[i])
                } else {
                    uploadedDocsWithoutDate.push(uploadedDocs[i]);
                }
            }
            uploadedDocsWithDate.sort((a, b) => new Date(b.createdDateUTC) - new Date(a.createdDateUTC));
            const allUploadDocs = [...uploadedDocsWithDate, ...uploadedDocsWithoutDate];
            allUploadDocs.forEach((e) => {
                if (e.documentTypeName === rowData.DocumentTypeName && e.relevanceTypeName === rowData.RelevanceTypeName && !rowData.documentNumber.includes(e.documentNumber)) {
                    rowData.documentNames.push(`${e.fileName}${e.createdDateUTC ? ` - ${dateFormats.formatDate(e.createdDateUTC, "MM/DD/YYYY")}` : ''}`);
                    rowData.documentNotes.push(e.documentRelevanceNotes);
                    rowData.documentNumber.push(e.documentNumber);
                    rowData.docId.push(e._id);
                    rowData.fileId.push(e.sharePointDetails && e.sharePointDetails[0] && e.sharePointDetails[0].id ? e.sharePointDetails[0].id : '');
                    rowData.documentSourceName.push(e.documentSourceName);
                    rowData.documentSourceTypeName.push(e.documentSourceTypeName);
                    rowData.documentTagName.push(e.documentTagName);
                }
            })
        }
        tableData.push(rowData);
    }
    return tableData;
}

export const getDocTags = async () => {
    const res = await fetchMethodRequest('GET', `${'documentTag'}?type=exportToCsv`);
    if (res && res.documentTags) {
        return Promise.resolve(res.documentTags);
    } else {
        return Promise.resolve([]);
    }
}

export const getDocSourceTypes = async () => {
    const res = await fetchMethodRequest('GET', `${'documentSourceType'}?type=exportToCsv`);
    if (res && res.documentSourceTypes) {
        return Promise.resolve(res.documentSourceTypes);
    } else {
        return Promise.resolve([]);
    }
}
