import React from 'react';
import { Panel } from 'primereact/panel';
import { DocSelectFields } from '../../DocumentUpload/components/DocSelectFields';
import { DocTable } from '../../DocumentUpload/components/DocTable';
import fetchMethodRequest from '../../../../config/service';
import Loader from '../../../App/Loader';
import * as documentsAPIs from '../../../../shared/documentsApis';

class AvailableDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateAttributes: [],
            isLoading: false,
            docParams: null,
            showConfigError: false
        }
        this.docParams = {};
    }
    componentDidMount() {
        if (document.getElementsByClassName("layout layout--collapse").length === 0) {
            document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
        }
    }

    /**
     * Get the State attributes & available documents data & formatte the data to show.
     * @param {Object} formData  user selected data from top input fields
     * @param {Array} categories All categories
     */
    getAllAvailableDocuments = async (formData, categories) => {
        this.setState({ isLoading: true, showConfigError: false });
        this.docParams = {
            DealId: formData.deal && formData.deal.DealID ? formData.deal.DealID : '',
            MappedNameId: formData.selMappedName && formData.selMappedName.BusinessNamesID ? formData.selMappedName.BusinessNamesID : '',
            BusinessNameId: formData.selRelSellerName && formData.selRelSellerName.BusinessNamesID ? formData.selRelSellerName.BusinessNamesID : '',
            State: formData.selState && formData.selState.shortCode ? formData.selState.shortCode : '',
            StateClaimID: formData.selStateClaimId && formData.selStateClaimId.stateClaimID ? formData.selStateClaimId.stateClaimID : ''
        }
        const stateCode = formData && formData.selState && formData.selState.shortCode ? formData.selState.shortCode : '';
        const dealType = formData && formData.deal && formData.deal.DealType ? formData.deal.DealType : '';
        const stateAttributes = await documentsAPIs.getStateAttributes(dealType, stateCode); // get the sate attributes data from. 
        let tableData = stateAttributes;
        let showConfigError = true;
        if (stateAttributes && stateAttributes.length > 0) {
            showConfigError = false;
            const availDocuments = await documentsAPIs.getAvailableDocuments(formData, stateAttributes, categories, null, null, "AvailableDocs"); // get available document list.
            if (availDocuments && availDocuments.length > 0) {
                tableData = documentsAPIs.formatAvailDocumentsData(stateAttributes, availDocuments); // Combine both state attributes & documents data.
            }

        }
        this.setState({ stateAttributes: tableData, showConfigError: showConfigError, isLoading: false });
    }

    /**
     * on click 'plus' icon in table will redirect to document upload screen.
     * @param {Object} rowData 
     */
    redirectToUpload = (rowData, type, index) => {
        let url = `/Sellers/documentUpload?dealId=${this.docParams.DealId}&mappedNameId=${this.docParams.MappedNameId}&relSellerNameId=${this.docParams.BusinessNameId}&stateCode=${this.docParams.State}&stateClaimId=${this.docParams.StateClaimID}&rowData=${encodeURIComponent(JSON.stringify(rowData))}`;
        if (type === 'edit') {
            url = `${url}&type=${type}&index=${index}`
        }
        window.open(url, '_blank', 'noopener=true');
    }

    /**
     * on click 'PDF' icon in table will redirect to pdf Editor screen.
     * @param {Object} rowData 
     */
    redirectToPDFEditor = (rowData, index) => {
        if (rowData && rowData.docId && rowData.docId[index]) {//
            this.setState({ isLoading: true });
            fetchMethodRequest('GET', `getDocumentById?Id=${rowData.docId[index]}`).then(async (res) => {
                let data = res && res.respCode && res.outResponse && res.outResponse.details ? res.outResponse.details : null;
                if (data) {
                    // const sharePointDeatils = await documentsAPIs.getSharepointDetails(data.dealId, data.documentNumber);
                    // data.sharePointDetails = sharePointDeatils;
                    localStorage.setItem('pdfDocDetails',JSON.stringify(data));
                    let url = `/pdfEditor?details=loadDoc`;
                    window.open(url, '_blank', 'noopener=true');
                }
                this.setState({ isLoading: false });
            })
        }
    }

    /**
     * Clear table data on change top input field values.
     */
    clearTableData = () => {
        this.setState({
            stateAttributes: [],
            showConfigError: false
        });
    }


    render() {
        return (
            <div className='mt-2 mx-1 document-upload'>
                <Panel header='Available Documents' className='w-100'>
                    <DocSelectFields
                        tab={documentsAPIs.AVAIL_DOCS_TAB_NAME}
                        getStateAttributes={this.getStateAttributes}
                        setLoader={(value) => this.setState({ isLoading: value })}
                        getAllAvailableDocuments={this.getAllAvailableDocuments}
                        clearTableData={this.clearTableData}
                        names={this.props.names}
                        relSellerNames={this.props.relSellerNames}
                        stateClaimIds={this.props.stateClaimIds}
                        setDocumentsDropdownsData={this.props.setDocumentsDropdownsData}
                    />
                    {this.state.stateAttributes && this.state.stateAttributes.length > 0 ?
                        <DocTable
                            stateAttributes={this.state.stateAttributes}
                            tab={documentsAPIs.AVAIL_DOCS_TAB_NAME}
                            redirectToUpload={(row, type, index) => this.redirectToUpload(row, type, index)}
                            redirectToPDFEditor={this.redirectToPDFEditor}
                        />
                        :
                        <>{this.state.showConfigError && <div className='text-danger config-error-message mt-4'>No Document Relevance found. Please contact the OPRA2 team to check the configurations.</div>}</>
                    }
                </Panel>
                <Loader loader={this.state.isLoading} />
            </div>
        )
    }
}
export default AvailableDocuments;