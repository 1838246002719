
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    Button, Card, CardBody, ModalBody, Row, Table
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'

import { MultiSelect } from 'primereact/multiselect';
// fecth method from service.js file
import fetch from '../../../config/service';
import configMessage from '../../../config/configMessages';
import configImages from '../../../config/configImages';
// show message 
import validate from '../../Form/validate';
import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';
// import { FontAwesomeIcon } from '@fortawesome/free-solid-svg-icons';
import { faArrowCircleLeft, faSave, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import MultiSelect from '../../Form/components/MultiSelect'
import DefaultInput from '../../../shared/components/form/DefaultInput';

import Select from '../../../shared/components/form/Select';
import CheckBox from '../../../shared/components/form/CheckBox';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import dateFormats from '../../UI/FormatDate/formatDate';
import ExpiredSessionModal from '../CommonModals/ExpiredSessionModal';
import SessionWarningModal from './SessionWarningModal'

//config
import apiCalls from '../../../config/apiCalls';
import configMessages from '../../../config/configMessages';
import config from '../../../config/config';

// import DefaultInput from 'shared/components/form/DefaultInput';
let timeout

const Container1 = styled.div`
  display: flex;
`
const Container = styled.div`
  border: 1px solid white;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDragging
            ? 'skyblue' : '#e5e5e5'};
`
const Container5 = styled.div`
  margin: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
   width: 100%;

`

const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDraggingOver ? 'skyblue' : 'white'}
  flex-grow: 1;
  min-height: 100px;
`

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class UserPasswordResetModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: false,
            colored: false,
            header: true,
            rowData: {},
            progress: 0,
            sortDone: false,
            hideSplitBtn: false,
            showCaseClosedDate: false,
            timer: false,
            formType: "",
            copiedA: false,
            copiedB: false,
            exhibit1Array: [],
            exhibit2Array: [],
            exhibit2String: [],
            exhibit1String: [],
            showErrorMessage: false,
            sessionExpiryModal: false,
            matrixQstns: [],
            checkedListPurpose: "",
            filterCriteria: { criteria: [] },
            checklists: [],
            key: 0,
            selectedStatus: [],
            tempCheckList: [],
            isSelectedData: [],
            activeTab: "1",
            showNames: true,
            errorResponse: "",
            isShowChecklistError: false,
            isSplittedStates: [],
            ismoveAll: false,
            additionalselectedStates: [],
            statestab2Data: [],
            selectedValues2: [],
            downtoleft: [],
            getValues1: [],
            getValues: [],
            ismoveAlltoleft: false,
            upCount: 0,
            downCount: 0,
            leftCount: 0,
            rightCount: 0,
            actions: [],
            isSelectAll: false,
            comments: [],
            isSplitData: [],
            lengthNote: 0,
            taskNote: "",
            last2Notes: [],
            showFields: [],
            otherStates: [],
            issplitAll: false,
            tableFields: '',
            selectedValues: [],
            totalFields: [],
            exportData: [],
            apierrorResponse: false,
            disabledField: false,
            assignedValue: false,
            issplitbyStatus: false
        };
    }

    componentDidUpdate() {
        if (this.state.apierrorResponse) {
            setTimeout(() => this.setState({ apierrorResponse: false, apierrorMessage: '' }), 20000);
        }
        if (this.state.copiedA) {
            setTimeout(() => this.setState({ copiedA: false }), 20000);
        }
        if (this.state.copiedB) {
            setTimeout(() => this.setState({ copiedB: false }), 20000);
        }
        // if (localStorage.getItem('loggedTime')) {
        //     let loggedTime = localStorage.getItem('loggedTime')
        //     if (timeout) {
        //         clearTimeout(timeout)
        //     }
        //     timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
        // }
        // this.getTab2Body()
    }



    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }

    }
    cancelexpiredSessionModal = async () => {
        await this.setState({
            expiredSessionModal: false
        })
    }
    expiredSessionModal() {
        return (
            <ExpiredSessionModal
                openConfirmationModal={this.state.expiredSessionModal}
                cancelSessionWarningModal={this.cancelexpiredSessionModal}
                getData={this.getDataFromServer}

            />
        )
    }
    componentDidMount = async () => {
        // this.props.onRef(this);
        let sessionexpired = await localStorage.getItem('sessionexpired')
        let role = await JSON.parse(localStorage.getItem('loginCredentials'))

        if (role && role.roleName) {
            await this.setState({ role: role.roleName })
        }
        if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
        }
        let url1 = decodeURI(window.location.href)
        let x = url1.split("/")
        let y = x[x.length - 1]
        if (window.location.href.includes("crMatrixEdit")) {
            this.props.change("crMatrixVersion", y)
            this.setMatrixVersion(y)
            await this.setState({ version: y })
        }
        else {
            let url2 = `${apiCalls.SellerCRMatrix}`;
            fetch("GET", url2)
                .then(async (response) => {

                    if (response && response.distinctCrMatrixVersions) {
                        this.props.change("crMatrixVersion", response.distinctCrMatrixVersions[0])
                        this.setMatrixVersion(response.distinctCrMatrixVersions[0])
                        await this.setState({ version: response.distinctCrMatrixVersions[0] })

                    }
                }).catch((err) => {
                    return err;
                });
        }

        // if (window.location.href.includes("sellersEdit")) {
        //     console.log("koiouhgj")
        //     let url = `${apiCalls.Companies}/filter={criteria:[{key : "DimSellerID",value:${y},type:"eq"}]}`;
        //     return fetch("GET", url)
        //         .then(async (response) => {
        //             console.log("repsonndidheee", response)
        //             if (response && response.details) {

        //             } else if (response && response.errorMessage) {
        //                 // display message
        //                 // showToasterMessage(response.errorMessage, 'error');
        //                 this.getErrorMessage(response.errorMessage)
        //             }
        //             this.setState({
        //                 isLoading: false,
        //             })
        //             if (this.props.filterData) {
        //                 this.props.getKanbanCards(this.props.filterData)
        //             } else {
        //                 this.props.getKanbanCards()
        //             }


        //         }).catch((err) => {
        //             return err;
        //         });
        // }
        this.setState({ formType: "Add" })

    }

    cancelSessionWarningModal = async () => {
        await this.setState({
            sessionWarning: false
        })
    }


    //stay signed in Modal
    getSessionWraningModal() {
        return (
            <SessionWarningModal
                openConfirmationModal={this.state.sessionWarning}
                cancelSessionWarningModal={this.cancelSessionWarningModal}
                getData={this.getKanbanCards}

            />
        )
    }

    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }

    submit = (values) => {
        let quesObj = {}, method, apiUrl = ''
        if (window.location.href.includes("crMatrixEdit")) {
            method = "PUT"
            apiUrl = apiCalls.crMatrix
            let version = values.crMatrixVersion
            let checkValues = values
            let setMatrixId = this.state.setMatrixId
            let questionsArray = [], newCrMatrix = []
            for (let qstn of this.state.matrixQstns) {
                if (qstn.qstnId) {
                    questionsArray.push({ CrMatrixID: qstn.qstnId, "version": version, "question": qstn.qstn, crMatrixRule: qstn.CrMatrixRule, questionNumber: qstn.questionNumber })

                } else {
                    newCrMatrix.push({ "version": version, "question": qstn.qstn, crMatrixRule: qstn.CrMatrixRule, questionNumber: qstn.questionNumber })

                }

            }
            // for (let value in values) {
            //     if (value.includes("matrixId_")) {
            //         let matrixId = value.split("_")
            //         for (let checkValue in checkValues) {
            //             if (checkValue === "matrixQstn_" + JSON.parse(matrixId[1])) {
            //                 let answer = setMatrixId.find(o =>
            //                     o.QuestionNumber === JSON.parse(matrixId[1]));
            //                 if (answer) {
            //                     questionsArray.push({ CrMatrixID: answer.CrMatrixID, "version": version, "question": values[value], crMatrixRule: checkValues[checkValue], questionNumber: JSON.parse(matrixId[1]) })
            //                 } else {
            //                     newCrMatrix.push({ "version": version, "question": values[value], crMatrixRule: checkValues[checkValue], questionNumber: JSON.parse(matrixId[1]) })

            //                 }
            //             }
            //         }
            //     }
            // }
            quesObj.newVersion = version
            quesObj.version = this.state.version
            quesObj.sort = this.state.sortDone
            quesObj.crMatrices = questionsArray
            quesObj.newCrMatrices = newCrMatrix

        } else {
            method = "POST";
            apiUrl = apiCalls.crMatrix
            let version = values.crMatrixVersion

            // let checkValues = values
            let questionsArray = []
            for (let qstn of this.state.matrixQstns) {
                questionsArray.push({ "version": version, "question": qstn.qstn, crMatrixRule: qstn.CrMatrixRule, questionNumber: qstn.questionNumber })

            }

            // for (let value in values) {
            //     if (value.includes("matrixId_")) {
            //         let matrixId = value.split("_")
            //         for (let checkValue in checkValues) {
            //             if (checkValue === "matrixQstn_" + JSON.parse(matrixId[1])) {
            //                 questionsArray.push({ "version": version, "question": values[value], crMatrixRule: checkValues[checkValue], questionNumber: JSON.parse(matrixId[1]) })
            //             }
            //         }
            //     }
            // }
            quesObj.version = version
            quesObj.crMatrices = questionsArray
        }

        this.sendDataToServer(method, apiUrl, quesObj)
    }

    successSubmit = async (method, apiUrl, userBody) => {
        await this.setState({
            issplitbyStatus: false,
            isShowChecklistError: this.props.type === "add" ? true : false,

        })
        this.sendDataToServer(method, apiUrl, userBody)
    }

    sendDataToServer = async (method, url, body) => {
        await this.setState({
            // isLoading: true,
            method: method,
            apiUrl: url,
            userBody: body
        })
        if (this.state.version === body.version && !window.location.href.includes("crMatrixEdit")) {
            await this.setState({ apierrorMessage: "please change the version", apierrorResponse: true })
        } else {
            await this.setState({
                isLoading: true,

            })
            this.handlePercentage('1')
            return fetch(method, url, body)
                .then(async (response) => {
                    if (response && response.respCode && (response.respCode === 204 || response.respCode === 200 || response.respCode === 205)) {
                        // display message
                        // showToasterMessage(response.respMessage, 'success');

                        this.props.reset();
                        this.cancelReset();

                    } else if (response && response.errorMessage) {
                        // display message
                        // showToasterMessage(response.errorMessage, 'error');
                        this.getErrorMessage(response.errorMessage)
                    }
                    this.setState({
                        isLoading: false,
                    })
                    if (this.props.filterData) {
                        this.props.getKanbanCards(this.props.filterData)
                    } else {
                        this.props.getKanbanCards()
                    }


                }).catch((err) => {
                    return err;
                });
        }

    }

    setMatrixVersion = async (e) => {
        await this.setState({ isLoading: true })
        this.handlePercentage('1')
        let filterCriteria = {}
        filterCriteria['criteria'] = [
            { key: "Version", value: `${e}`, type: "eq" }
        ];
        let url1 = `${apiCalls.crMatrix}?filter=${JSON.stringify(filterCriteria)}`;
        return fetch("GET", url1)
            .then(async (response) => {
                let qstnsArray = []
                if (response && response.crMatrices) {
                    if (window.location.href.includes("crMatrixEdit")) {
                        await this.setState({ setMatrixId: response.crMatrices })
                    }
                    for (let crMatrice of response.crMatrices) {

                        qstnsArray.push({ qstn: crMatrice.Question, qstnId: crMatrice.CrMatrixID, CrMatrixRule: crMatrice.CrMatrixRule, questionNumber: crMatrice.QuestionNumber })
                    }
                    for (let SellerCrMatrice of response.crMatrices) {
                        this.props.change(`matrixId_${SellerCrMatrice.QuestionNumber}`, SellerCrMatrice.Question)
                        this.props.change(`matrixQstn_${SellerCrMatrice.QuestionNumber}`, SellerCrMatrice.CrMatrixRule)

                    }
                    await this.setState({ matrixQstns: qstnsArray, isLoading: false })
                }
            }).catch((err) => {
                return err;
            });

    }

    cancelReset = async () => {
        this.props.load({})
        await this.props.reset();
        // await this.props.cancelReset();

        await this.setState({ isKanbanModelClose: true })
    }


    handleCheck = async (dealType) => {
        this.props.reset()
        await this.setState({
            dealType: dealType
        })
    }
    //
    connectBussiness = async () => {
        let companyIdArray = [], unSelectedBusinessIds = []
        if (this.state.isSelectedData.length > 0) {
            for (let companyId of this.state.isSelectedData) {
                if (companyId.show === true) {
                    companyIdArray.push(companyId.value)
                } else if (companyId.show === false) {
                    unSelectedBusinessIds.push(companyId.value)
                }
            }
            let body = {
                businessIDs: companyIdArray,
                unSelectedBusinessIDs: unSelectedBusinessIds,
                sellerID: JSON.stringify(this.state.dimSellerId)
            }
            return fetch('POST', apiCalls.connectBusiness, body)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        this.cancelReset()
                        await localStorage.setItem("SuccessMessage", response.respMessage)
                        this.setState({ apierrorResponse: false, apierrorMessage: `Please select any business under Selected Business Names before clicking to connect` })

                    } else if (response && response.errorMessage) {

                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        await this.setState({
                            sessionWarning: true,
                        })
                    }
                    await this.setState({ isLoading: false })
                }).catch((err) => {
                    return err;
                });
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any business under Selected Business Names before clicking to connect` })

        }

    }

    issplitSucces = async () => {
        await this.setState({ isLoading: true, splitbyeachCard: false })
        this.handlePercentage('1')
        return fetch('POST', `tasks/split/${this.props.rowData._id}`, this.state.splitBody)
            .then(async (response) => {
                if (response && response.respMessage) {
                    // this.cancelReset()
                    await this.setState({ splitbyeachCard: false })
                    this.getErrorMessage(response.respMessage)
                } else if (response && response.errorMessage) {
                    await this.setState({ splitbyeachCard: false })
                    this.getErrorMessage(response.errorMessage)
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                    })
                }
                await this.setState({ isLoading: false })
            }).catch((err) => {
                return err;
            });
    }

    getErrorMessage = (errorMessage) => {
        this.setState({
            apierrorMessage: errorMessage.replace("\r\n", ""),
            apierrorResponse: true
        })

    }
    changeCaseStatus = (e, name) => {

        if (name === "CaseStatus") {
            if (e === "Closed") {
                this.setState({ showCaseClosedDate: true })
            } else {
                this.setState({ showCaseClosedDate: false })
            }
        }
    }



    handleCompanyNames = async (e) => {
        // this.props.chan({ entityName: e.CompanyName })
        this.props.change('entityName', e.CompanyName)
        this.props.change('companyName', e.CompanyName)
        this.props.change('leadSeller', e.CompanyName)

    }

    copyToClipboard = async (type) => {

        if (type && type === "exhibit-A") {
            // let exhibit1String = this.state.exhibit1String.toString()
            // exhibit1String

            navigator.clipboard.writeText(this.state.exhibit1String.join("\n"))
            this.setState({ copiedA: true })
        } else {
            navigator.clipboard.writeText(this.state.exhibit2String.join("\n"))
            this.setState({ copiedB: true })
        }

        return true
    }

    getModalBody(handleSubmit, dealtype) {


        const { type } = this.props
        return (
            <div>
                <form className="form" onSubmit={handleSubmit(this.submit)}>
                    <ModalBody className={"modal__body edit_modal"}>
                        <Card className='pb-0 '>
                            {/* <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" /> */}
                            <CardBody className=' p-0'
                            >
                                <div className='row mx-1 mt-3 col-sm-12 pl-0 pr-0'>
                                    {this.state.isShowChecklistError ?
                                        <div className="m-auto ml-0 mb-4" style={{ color: "red", textAlign: "center" }}>{this.state.errorResponse}
                                        </div> : ""}
                                </div>

                                <div className='row mx-1 mt-3 col-sm-12'>
                                    <div className='row ml-0 col-sm-6  mr-0' style={this.state.nameChild ? { display: "block" } : { display: "none" }}>
                                        <label className="form__form-group-label">Subsidiary Estates</label>
                                        <div className='usermessagesText subsidary d-block' style={{ height: "90px", overflow: "auto", border: "1px solid lightgray" }}>
                                            <ul style={{ borderBottom: "none" }} className="mr-0 pl-0">
                                                {this.props.rowData && this.props.rowData.subsidiaryEstates && this.props.rowData.subsidiaryEstates.map(function (name, index) {
                                                    return <li style={{ listStyle: "none" }}>{name}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='ml-auto d-flex' style={{ float: "right", marginLeft: "auto" }}>
                                        <Button

                                            color='primary'
                                            className={type === "edit" ? "mb-0 mt-0" : "mb-0 "}
                                            outline
                                            type="submit">
                                            <FontAwesomeIcon
                                                // style={{ marginLeft: "auto" }}
                                                icon={faSave}
                                            />
                                            {type === 'edit'
                                                ?
                                                'Update'
                                                : 'Save'
                                            }
                                        </Button>
                                        <Button color='danger' outline type="button" className={type === 'edit' ? " ml-1 mb-0 mt-0" : "mr-auto ml-1 mb-0 "} onClick={this.cancelReset}>
                                            <FontAwesomeIcon
                                                style={{ width: "15px" }}
                                                icon={faArrowCircleLeft}
                                            />
                                            {('Back') ? ('Cancel') : 'Cancel'} </Button>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={this.state.key2}
                                            onSelect={(k) => this.setState({ key2: k })}
                                            className="mb-3"
                                        >

                                            {this.state.dealType === "CR" && this.state.dimSellerId ? <Tab eventKey="CR Matrix" title="CR Matrix" >
                                                <div className="row mx-1 mt-3 col-sm-12">
                                                    <div className={"col-sm-8"}   >
                                                        <div className="form__form-group pb-1">
                                                            <div className='form__form-group-field kanban_notesfield' style={{ float: "right" }}>
                                                                <label className="form__form-group-label" >{"CR Matrix Version"} </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"col-sm-4"} >
                                                        <Field
                                                            name={"crMatrixVersion"}
                                                            component={Select}
                                                            options={this.state.matrixVersionArray}
                                                            onChange={(e) => this.setMatrixVersion(e)}
                                                            // onChange={(e) => this.handleChange(e)}
                                                            // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                            placeholder={"crMatrixVersion"}
                                                        // isDisable={item.disable ? item.disable : false}
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.matrixQstns && this.state.matrixQstns.length > 0 ? this.state.matrixQstns.map((item, index) => (
                                                    <div className="row mx-1 mt-3 col-sm-12">
                                                        <div className={"col-sm-8"}   >
                                                            <div className="form__form-group pb-1">
                                                                <div className='form__form-group-field kanban_notesfield' style={{ float: "right" }}>
                                                                    <label className="form__form-group-label" >{item.qstn} </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"col-sm-4 d-flex"} >
                                                            <Field
                                                                name={"matrixId_" + item.qstnId}
                                                                component={DefaultInput}
                                                                id={item.qstnId}


                                                            // validate={[required]}
                                                            // placeholder={item.placeholder}
                                                            />

                                                            <FontAwesomeIcon icon={faQuestion}
                                                                className='ml-2'
                                                                color='red'
                                                                data-toggle="tool-tip"
                                                                title={item.CrMatrixRule ? item.CrMatrixRule : "No Rule For This Question"}
                                                                style={{ width: 8 }}
                                                            />
                                                        </div>
                                                    </div>
                                                )) : null}

                                            </Tab> : null}
                                        </Tabs>
                                    </div>


                                    <div className="row" style={{ width: "100%" }}>
                                        {type === 'edit' ?
                                            <div className={type === 'edit' ? "col-sm-6" : "col-sm-12 pl-0 pr-0"} style={!this.state.showTimer ? {} : { pointerEvents: "none" }}>

                                            </div>
                                            : null}
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                        <span className="formError m-2 mt-4">{this.state.apierrorResponse ? <span style={{ height: 30, }}>{this.state.apierrorMessage}</span> : null}</span>

                    </ModalBody>
                </form >
            </div >);
    }



    onChangesplitAll = async (e) => {
        this.setState(prevState => ({ issplitAll: !prevState.issplitAll }))
    }

    onChangeMoveAll = async () => {
        // this.setState(prevState => ({ismoveAll: !prevState.ismoveAll }));
        if (this.state.ismoveAll) {
            this.setState({ ismoveAll: false });
        } else {
            this.setState({ ismoveAll: true, apierrorMessage: "", apierrorResponse: false });
        }
        if (!this.state.ismoveAll) {
            let select_list = this.state.statestab2Data
            for (var obj of select_list) {
                obj.show = true
            }
            this.setState({ statestab2Data: select_list })
        } else {
            let select_list = this.state.statestab2Data
            for (var obj2 of select_list) {
                obj2.show = false
            }
            this.setState({ statestab2Data: select_list })
        }
    }

    newSelectedStates = async () => {
        this.setState({
            upCount: this.state.upCount + 1
        })

        let selectt5 = this.state.statestab2Data;
        let selectt6 = JSON.parse(JSON.stringify(this.state.statestab2Data));
        let split_arr3 = this.state.selectedStates;
        var newArray = selectt5.filter(function (el) {
            return el.show === true
        });
        if (newArray && newArray.length > 0) {
            for (var obj2 of selectt5) {
                var obj3 = Object.assign({}, obj2)
                if (obj2.show === true) {
                    obj3.show = false
                    split_arr3.push(obj3)
                    selectt6.splice(selectt6.findIndex(a1 => a1.label === obj2.label), 1)
                }
            }
            this.setState({ selectedStates: split_arr3, statestab2Data: selectt6 })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any bussiness under All Unselected  before clicking` })
        }
        if (this.state.ismoveAll) {
            await this.setState({ ismoveAll: false });
        }
        // }
    }

    getValues = async (cardtype, value) => {
        let selectTab2_States = this.state.statestab2Data
        if (value && value.label && selectTab2_States && selectTab2_States.length > 0) {
            selectTab2_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }
        this.setState({
            statestab2Data: selectTab2_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })
    }

    getValues1 = async (cardtype, value) => {
        let select_States = this.state.setBussinessNames
        if (value && value.label && select_States && select_States.length > 0) {
            select_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }
        let index = select_States.findIndex(filterarr => filterarr.show === true && filterarr.label.includes("--"))
        if (index != -1) {
            await this.setState({ showErrorMessage: true })
        } else {
            await this.setState({ showErrorMessage: false })
        }
        this.setState({
            setBussinessNames: select_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })
    }

    getnewExistingValues = async (cardtype, value, data, dataType) => {
        let isSplitt_States = this.state.isSelectedData
        if (value && value.label && isSplitt_States && isSplitt_States.length > 0) {
            isSplitt_States.map(col => {
                if (col.label === value.label) {
                    col.show = !col.show
                }
                return col
            })
        }
        this.setState({
            isSelectedData: isSplitt_States,
            apierrorResponse: false,
            apierrorMessage: ""
        })

    }

    removedStates = async () => {
        this.setState({
            downCount: this.state.downCount + 1
        })
        let selectt4 = this.state.selectedStates;
        let selectt5 = JSON.parse(JSON.stringify(this.state.selectedStates));
        let split_arr2 = this.state.statestab2Data;
        var newArray = selectt4.filter(function (el) {
            return el.show === true
        });
        if (newArray && newArray.length > 0) {
            for (var obj2 of selectt4) {
                var obj3 = Object.assign({}, obj2)
                if (obj2.show === true) {
                    obj3.show = false
                    split_arr2.push(obj3)
                    selectt5.splice(selectt5.findIndex(a1 => a1.label === obj2.label), 1)
                }
            }
            this.setState({ selectedStates: selectt5, statestab2Data: split_arr2 })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any bussiness under All Unselected  before clicking` })
        }
        if (this.state.ismoveAlltoleft) {
            await this.setState({ ismoveAlltoleft: false });
        }
        // }

    }
    moveLeftToRight = async () => {
        this.setState({
            leftCount: this.state.leftCount + 1
        })
        if (this.state.setBussinessNames) {
            let selectt = this.state.setBussinessNames;
            let selectt1 = this.state.setBussinessNames ? JSON.parse(JSON.stringify(this.state.setBussinessNames)) : null;
            let split_arr = this.state.isSelectedData;
            var newArray = selectt.filter(function (el) {
                return el.show === true
            });
            if (newArray && newArray.length > 0) {
                for (var obj of selectt) {
                    var obj1 = Object.assign({}, obj)
                    if (obj.show === true) {
                        obj1.show = false
                        split_arr.push(obj1)
                        selectt1.splice(selectt1.findIndex(a => a.label === obj.label), 1)
                    }
                }
                this.setState({ setBussinessNames: selectt1, isSelectedData: split_arr, hideSplitBtn: true })
            } else {
                this.setState({ apierrorResponse: true, apierrorMessage: `Please select any business under Business Names before clicking` })
            }
            if (this.state.ismoveAlltoleft) {
                await this.setState({ ismoveAlltoleft: false });
            }
            this.setState({
                showErrorMessage: false
            })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any business under Business Names before clicking` })

        }
    }

    moveRighttoLeft = async () => {
        this.setState({
            upCount: this.state.upCount + 1
        })
        if (this.state.isSelectedData) {
            let selectt2 = this.state.isSelectedData;
            let selectt3 = JSON.parse(JSON.stringify(this.state.isSelectedData));
            let split_arr1 = this.state.setBussinessNames;
            var newArray = selectt2.filter(function (el) {
                return el.show === true
            });
            if (newArray && newArray.length > 0) {
                for (var obj2 of selectt2) {
                    var obj3 = Object.assign({}, obj2)
                    if (obj2.show === true) {
                        obj3.show = false
                        split_arr1.push(obj3)
                        selectt3.splice(selectt3.findIndex(a1 => a1.label === obj2.label), 1)
                    }
                }
                this.setState({ setBussinessNames: split_arr1, isSelectedData: selectt3, hideSplitBtn: selectt3.length > 0 ? true : false })
            } else {
                this.setState({ apierrorResponse: true, apierrorMessage: `Please select any business under Selected Business Names before clicking` })
            }
            if (this.state.isSelectAll) {
                await this.setState({ isSelectAll: false });
            }
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any business under Selected Business Names before clicking` })

        }
    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    isSelectNo = () => {
        if (this.props.type === "add" || this.props.type === "edit") {
            this.setState({
                issplitbyStatus: false,
                // hideSplitBtn: true
            })
        }
        this.setState({
            splitbyeachCard: false,
            hideSplitBtn: true
        })
    }

    flattenArray = (arrayVal) => {
        let val = '';
        if (arrayVal) {
            val = JSON.stringify(arrayVal);
            val = val.replace(/"/g, '')
                .replace(/\[/g, '')
                .replace(/]/g, '');
        }
        return val;
    }

    onChangeMoveAlltoleft = () => {
        if (this.state.ismoveAlltoleft) {
            this.setState({ ismoveAlltoleft: false });
        } else {
            this.setState({ ismoveAlltoleft: true, apierrorMessage: "", apierrorResponse: false });
        }
        if (!this.state.ismoveAlltoleft) {
            let select_list = this.state.setBussinessNames
            for (var obj of select_list) {
                obj.show = true
            }
            this.setState({ setBussinessNames: select_list })
        } else {
            let select_list = this.state.setBussinessNames
            for (var obj2 of select_list) {
                obj2.show = false
            }
            this.setState({ setBussinessNames: select_list })
        }
    }

    onChangeselectAll() {
        if (this.state.isSelectAll) {
            this.setState({ isSelectAll: false });
        } else {
            this.setState({ isSelectAll: true, apierrorMessage: "", apierrorResponse: false });
        }
        if (!this.state.isSelectAll) {
            let select_list = this.state.isSelectedData
            for (var obj of select_list) {
                obj.show = true
            }
            this.setState({ isSelectedData: select_list })
        } else {
            let select_list = this.state.isSelectedData
            for (var obj2 of select_list) {
                obj2.show = false
            }
            this.setState({ isSelectedData: select_list })
        }
    }
    // async getBussiness(e) {
    //     let bussinessArray = []
    //     this.setState({ setBusinesssearched: e.target.value })

    //     let filterCriteria = `{"limit":20,"page":"1","criteria":[],"sortfield":"CompanyName","direction":"asc","globalSearch":{"value":"${e.target.value}","type":"user"}}`
    //     if (e.key === "Enter") {
    //         await this.setState({ isLoading: true })
    //         this.handlePercentage('1')
    //         fetch('GET', `${apiCalls.BusinessSeller}?filter=${filterCriteria}`)
    //             .then(async (response) => {
    //                 if (response && response.companies) {
    //                     for (let comp of response.companies) {
    //                         bussinessArray.push({ label: comp.CompanyName + (comp.EntityName ? `  --  ${comp.EntityName}` : ""), value: comp.CompanyID })
    //                     }
    //                     await this.setState({ setBussinessNames: bussinessArray })
    //                     await this.setState({ isLoading: false })


    //                 } else if (response && response['errorMessage'] === configMessages.warningMessage) {
    //                     await this.setState({
    //                         sessionWarning: true,
    //                         isLoading: false
    //                     })
    //                 }
    //             }).catch((err) => {
    //                 return err;
    //             });
    //     }
    // }
    setValuesNames(e) {
        this.setState({ selectedStatus: e.value })
    }

    getTab2Body() {
        return (
            <div>
                <ModalBody className='modal__body edit_modal' style={{
                    padding: 10,
                    // overflowX: "hidden",
                    // overflowY: "clip",
                    height: 580
                }}>
                    <Card className='pb-0 ml-4'>
                        <CardBody className='row p-0 d-block'>
                            {/* <Loader loader={this.state.isLoading} progress={this.state.progress} /> */}

                            <div >
                                <div className="col-sm-12 row" style={{ width: "100%" }}>
                                    <div className="col-sm-5 pl-0 pr-0">
                                        <Row className=" ml-0 mr-0" >
                                            <label><b>{"Business Name"}</b></label>
                                            <span className="ml-1">{this.state.showErrorMessage ? <span style={{ height: 30, color: "red" }}>{"There are some companies already connected to another seller."}</span> : null}</span>

                                            <span className='d-flex float-right split ml-auto' >
                                                <label className='d-flex float-right' ><input type="checkbox"
                                                    checked={this.state.ismoveAlltoleft}
                                                    onChange={(e) => this.onChangeMoveAlltoleft("ismoveAlltoleft")}
                                                /> Select All</label>

                                            </span>




                                            <Card className="pb-0" style={{ minHeight: "160px", height: '375px', overflow: 'auto', border: "1px solid grey" }}>
                                                {this.state.setBussinessNames && this.state.setBussinessNames.length > 0 ? this.state.setBussinessNames.map((item, index) => {
                                                    return <div className='d-flex m-1'>
                                                        <input key={`up-${index}`}
                                                            style={{ marginTop: 3, height: 16 }}
                                                            className='col-1 mr-0 pl-0'
                                                            onChange={(e) => this.getValues1("existing", item, item.label)}
                                                            name={`up-${this.state.upCount}-${index}`}
                                                            id={`up-${this.state.upCount}-${index}`}
                                                            component={CheckBox}
                                                            label={item.label}
                                                            type="checkbox"
                                                            //value={item.show}
                                                            checked={item.show}
                                                            defaultChecked={item.show}
                                                        /><label className='mb-0'>{item.label}</label>
                                                    </div>
                                                }) : null}
                                            </Card>
                                        </Row>


                                    </div>
                                    <div className="col-sm-2 mt-5">
                                        <Row className="">
                                            <span style={{ width: "100%", marginLeft: "43%" }}>
                                                <p className="ml-auto mr-auto"><img
                                                    src={configImages.leftIcon}
                                                    alt=""
                                                    className="mt-2 mb-2"
                                                    style={{ width: "15px", height: "15px" }}
                                                    data-toggle="tool-tip"
                                                    title="click to move selected business to right"
                                                    onClick={() => this.moveLeftToRight()} /></p>
                                                <p className="ml-auto mr-auto mt-0"><img
                                                    src={configImages.rightIcon}
                                                    alt=""
                                                    className="mb-2"
                                                    style={{ width: "15px", height: "15px" }}
                                                    data-toggle="tool-tip"
                                                    title="click to move unselected states to left"
                                                    onClick={() => this.moveRighttoLeft()} /></p>
                                            </span>
                                        </Row>
                                    </div>
                                    <div className="col-sm-5 pl-0 pr-0">
                                        <div className="d-flex">
                                            <label className="split_label mb-0"><b>{"Selected Business Names"}</b></label>
                                            <span className='d-flex float-right split ml-auto' >
                                                <label className='d-flex float-right' ><input type="checkbox"
                                                    checked={this.state.isSelectAll}
                                                    onChange={() => this.onChangeselectAll('remember')}
                                                /> Select All</label>

                                            </span>
                                        </div>
                                        <Card style={{ minHeight: "160px", height: '427px', overflow: 'auto', border: "1px solid grey" }}>
                                            {this.state.isSelectedData && this.state.isSelectedData.length > 0 ? this.state.isSelectedData.map((item2, index2) => {
                                                return <div className='d-flex m-1'>
                                                    {/* <Field key={`left-${index2}`}
                                                            className='col-2 mr-0 my-0 pl-0'
                                                            name={`left-${this.state.leftCount}-${index2}`}
                                                            id={`left-${this.state.leftCount}-${index2}`}
                                                            component={CheckBox}
                                                            label={item2.label}
                                                            //value={item2.show}
                                                            checked={item2.show}
                                                            defaultChecked={item2.show}
                                                            onChange={(e) => this.getnewExistingValues("newExisting", item2, this.state.isSelectedData)}

                                                        /> */}
                                                    <input key={`left-${index2}`}
                                                        className='col-1 mr-0 pl-0'
                                                        style={{ marginTop: 3, height: 16 }}
                                                        // name={`left-${this.state.leftCount}-${index2}`}
                                                        id={`left-${this.state.leftCount}-${index2}`}
                                                        type="checkbox"
                                                        component={CheckBox}
                                                        label={item2.label}
                                                        //value={item2.show}
                                                        checked={item2.show}
                                                        defaultChecked={item2.show}
                                                        onChange={(e) => this.getnewExistingValues("newExisting", item2, this.state.isSelectedData)}

                                                    /><label className='mb-0'>{item2.label}</label>

                                                </div>

                                            }) : null}
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>
                    <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.splitbyeachCard ?
                        <span>
                            {this.state.isSplitBy === "States" ?
                                <span>Are you sure you want to split task, one task per state?</span>
                                : <span>Are you sure you want to split task, one task per Name?</span>}
                            <p> <Button
                                color='primary'
                                outline
                                type="button"
                                className=" ml-1 mb-0 mt-0" onClick={() => this.issplitSucces()}>Yes
                            </Button><Button
                                color='primary'
                                outline
                                type="button"
                                className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                                </Button></p>
                        </span> : null}</span>

                </ModalBody>
            </div >
        );


    }
    addNames() {
        this.setState({ showNames: false })
    }
    addNamesBack() {
        this.setState({ showNames: true })
    }
    setNames() {
        return (

            <div >
                <div style={{ marginLeft: "1000px" }}>
                    {!this.state.showNames ?
                        <Button
                            color='primary'
                            outline
                            type="button"
                            className=" ml-10 mb-0 mt-2" onClick={() => this.addNamesBack()}>Back
                        </Button>
                        : null}
                    <Button
                        color='primary'
                        outline
                        type="button"
                        className=" ml-10 mb-0 mt-2" onClick={() => this.addNames()}>Add Names
                    </Button>
                </div>
                {this.state.showNames ?
                    <ModalBody className='modal__body edit_modal' style={{
                        padding: 10,
                        // overflowX: "hidden",
                        // overflowY: "clip",
                        height: 580
                    }}>
                        <Card className='pb-0 ml-4'>
                            <CardBody className='row p-0 d-block'>
                                <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />

                                <div >
                                    <div className="col-sm-12 row" style={{ width: "100%" }}>
                                        <div className="col-sm-5 pl-0 pr-0">
                                            <Row className=" ml-0 mr-0" >
                                                <label><b>{"Business Name"}</b></label>
                                                <MultiSelect
                                                    style={{ width: "97%" }}
                                                    name="Bussiness"
                                                    value={this.state.selectedStatus}
                                                    options={this.state.isSelectedData}
                                                    onChange={(e) => this.setValuesNames(e)}
                                                />
                                            </Row>
                                        </div>
                                        <div className="col-sm-5 pl-0 pr-0">
                                            <div className="d-flex">
                                                <label className="split_label mb-0"><b>{"Selected Business Names"}</b></label>
                                                <span className='d-flex float-right split ml-auto' >
                                                    <label className='d-flex float-right' ><input type="checkbox"
                                                        checked={this.state.isSelectAll}
                                                        onChange={() => this.onChangeselectAll('remember')}
                                                    /> Select All</label>

                                                </span>
                                            </div>

                                            <Card style={{ minHeight: "160px", height: '427px', overflow: 'auto', border: "1px solid grey" }}>
                                                {this.state.isSelectedData && this.state.isSelectedData.length > 0 ? this.state.isSelectedData.map((item2, index2) => {
                                                    return <div className='d-flex m-1'>
                                                        {/* <Field key={`left-${index2}`}
                                                            className='col-2 mr-0 my-0 pl-0'
                                                            name={`left-${this.state.leftCount}-${index2}`}
                                                            id={`left-${this.state.leftCount}-${index2}`}
                                                            component={CheckBox}
                                                            label={item2.label}
                                                            //value={item2.show}
                                                            checked={item2.show}
                                                            defaultChecked={item2.show}
                                                            onChange={(e) => this.getnewExistingValues("newExisting", item2, this.state.isSelectedData)}

                                                        /> */}
                                                        <input key={`left-${index2}`}
                                                            className='col-1 mr-0 pl-0'
                                                            style={{ marginTop: 3, height: 16 }}
                                                            // name={`left-${this.state.leftCount}-${index2}`}
                                                            id={`left-${this.state.leftCount}-${index2}`}
                                                            type="checkbox"
                                                            component={CheckBox}
                                                            label={item2.label}
                                                            //value={item2.show}
                                                            checked={item2.show}
                                                            defaultChecked={item2.show}
                                                            onChange={(e) => this.getnewExistingValues("newExisting", item2, this.state.isSelectedData)}

                                                        /><label className='mb-0'>{item2.label}</label>

                                                    </div>

                                                }) : null}
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>
                        <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.splitbyeachCard ?
                            <span>
                                {this.state.isSplitBy === "States" ?
                                    <span>Are you sure you want to split task, one task per state?</span>
                                    : <span>Are you sure you want to split task, one task per Name?</span>}
                                <p> <Button
                                    color='primary'
                                    outline
                                    type="button"
                                    className=" ml-1 mb-0 mt-0" onClick={() => this.issplitSucces()}>Yes
                                </Button><Button
                                    color='primary'
                                    outline
                                    type="button"
                                    className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                                    </Button></p>
                            </span> : null}</span>

                    </ModalBody> : <div className="row mx-1 mt-3 col-sm-12">

                        <div className={"col-sm-6"} >
                            <div className="form__form-group pb-1">
                                <label className="form__form-group-label">Name</label>
                                <div className='form__form-group-field kanban_notesfield'>
                                    <Field
                                        type='text'
                                        name='Name'
                                        component={DefaultInput}
                                        id='Name'
                                        // validate={[required]}
                                        placeholder={('Name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-6"} >
                            <div className="form__form-group pb-1">
                                <label className="form__form-group-label">Issue</label>
                                <div className='form__form-group-field kanban_notesfield'>
                                    <Field
                                        name="issue"
                                        component={Select}
                                        options={this.state.issues}
                                        // validate={[required]}
                                        placeholder='Select Issue'
                                        onChange={(e) => this.onchangeIssue(e)}
                                    // isDisable={view === "loggedThroughmail" || rowData && rowData.assignTo === "Unassigned" || type === "add" || (type === "edit" && (assignedValue) || (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-6"} >
                            <div className="form__form-group pb-1">
                                <label className="form__form-group-label">Name Connection</label>
                                <div className='form__form-group-field kanban_notesfield'>
                                    <Field
                                        name="NameConnection"
                                        component={Select}
                                        options={this.state.nameConnection}
                                        // validate={[required]}
                                        placeholder='Name Connection'
                                        onChange={(e) => this.onchangeIssue(e)}
                                    // isDisable={view === "loggedThroughmail" || rowData && rowData.assignTo === "Unassigned" || type === "add" || (type === "edit" && (assignedValue) || (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-6"} >
                            <div className="form__form-group pb-1">
                                <label className="form__form-group-label">Connected Name</label>
                                <div className='form__form-group-field kanban_notesfield'>
                                    <Field
                                        type='text'
                                        name='ConnectedName'
                                        component={DefaultInput}
                                        id='Connected Name'
                                        // validate={[required]}
                                        placeholder={('Connected Name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-6"} >
                            <div className="form__form-group pb-1">
                                <label className="form__form-group-label">Source</label>
                                <div className='form__form-group-field kanban_notesfield'>
                                    <Field
                                        type='text'
                                        name='Source'
                                        component={DefaultInput}
                                        id='Source'
                                        //   validate={[required]}
                                        placeholder={('Source')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-6"} >
                            <div className="form__form-group pb-1">
                                <label className="form__form-group-label">Notes</label>
                                <div className='form__form-group-field kanban_notesfield'>
                                    <Field
                                        type='textArea'
                                        name='Notes'
                                        component={DefaultInput}
                                        id='Notes'
                                        //   validate={[required]}
                                        placeholder={('Notes')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"col-sm-6"} >
                            <div className="form__form-group pb-1">
                                <label className="form__form-group-label">Geographic Restriction</label>
                                <div className='form__form-group-field kanban_notesfield'>
                                    <Field
                                        type='textArea'
                                        name='GeographicRestriction'
                                        component={DefaultInput}
                                        id='GeographicRestriction'
                                        // validate={[required]}
                                        placeholder={('Geographic Restriction')}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>}
            </div >
        );


    }
    onchangeIssue(e) {

    }
    setAddress() {
        return (
            <div>
                <ModalBody className='modal__body edit_modal' style={{
                    padding: 10,
                    // overflowX: "hidden",
                    // overflowY: "clip",
                    height: 580
                }}>
                    <Card className='pb-0 ml-4'>
                        <CardBody className='row p-0 d-block'>
                            <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />

                            <div >
                                <div className="col-sm-12 row" style={{ width: "100%" }}>
                                    <div className="col-sm-5 pl-0 pr-0">
                                        <Row className=" ml-0 mr-0" >
                                            <label><b>{"Business Name"}</b></label>
                                            <MultiSelect
                                                style={{ width: "97%" }}
                                                name="Bussiness"
                                                value={this.state.selectedStatus}
                                                options={this.state.isSelectedData}
                                                onChange={(e) => this.setValuesNames(e)}
                                            />
                                        </Row>
                                    </div>
                                    <div className="col-sm-5 pl-0 pr-0">
                                        <div className="d-flex">
                                            <label className="split_label mb-0"><b>{"Selected Business Address"}</b></label>
                                            <span className='d-flex float-right split ml-auto' >
                                                <label className='d-flex float-right' ><input type="checkbox"
                                                    checked={this.state.isSelectAll}
                                                    onChange={() => this.onChangeselectAll('remember')}
                                                /> Select All</label>

                                            </span>
                                        </div>
                                        <Field
                                            type='text'
                                            name='bussinessAdresss'
                                            component={DefaultInput}
                                            id='bussinessAdresss'
                                            onKeyPress={(e) => this.getBussiness(e)}

                                            // onChange={(e) => this.getBussiness(e)}
                                            // validate={[required]}
                                            placeholder={('Search Business Address')}
                                        />
                                        <Card style={{ minHeight: "160px", height: '427px', overflow: 'auto', border: "1px solid grey" }}>
                                            {this.state.isSelectedData && this.state.isSelectedData.length > 0 ? this.state.isSelectedData.map((item2, index2) => {
                                                return <div className='d-flex m-1'>
                                                    {/* <Field key={`left-${index2}`}
                                                            className='col-2 mr-0 my-0 pl-0'
                                                            name={`left-${this.state.leftCount}-${index2}`}
                                                            id={`left-${this.state.leftCount}-${index2}`}
                                                            component={CheckBox}
                                                            label={item2.label}
                                                            //value={item2.show}
                                                            checked={item2.show}
                                                            defaultChecked={item2.show}
                                                            onChange={(e) => this.getnewExistingValues("newExisting", item2, this.state.isSelectedData)}

                                                        /> */}
                                                    <input key={`left-${index2}`}
                                                        className='col-1 mr-0 pl-0'
                                                        style={{ marginTop: 3, height: 16 }}
                                                        // name={`left-${this.state.leftCount}-${index2}`}
                                                        id={`left-${this.state.leftCount}-${index2}`}
                                                        type="checkbox"
                                                        component={CheckBox}
                                                        label={item2.label}
                                                        //value={item2.show}
                                                        checked={item2.show}
                                                        defaultChecked={item2.show}
                                                        onChange={(e) => this.getnewExistingValues("newExisting", item2, this.state.isSelectedData)}

                                                    /><label className='mb-0'>{item2.label}</label>

                                                </div>

                                            }) : null}
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>
                    <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.splitbyeachCard ?
                        <span>
                            {this.state.isSplitBy === "States" ?
                                <span>Are you sure you want to split task, one task per state?</span>
                                : <span>Are you sure you want to split task, one task per Name?</span>}
                            <p> <Button
                                color='primary'
                                outline
                                type="button"
                                className=" ml-1 mb-0 mt-0" onClick={() => this.issplitSucces()}>Yes
                            </Button><Button
                                color='primary'
                                outline
                                type="button"
                                className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                                </Button></p>
                        </span> : null}</span>

                </ModalBody>
            </div >
        );


    }
    onDragEnd = async (result) => {


        let crMatQstn = this.state.matrixQstns
        let matrxQstns = crMatQstn[result.source.index]
        let destQstn = crMatQstn[result.destination.index]
        if (result.source.index > result.destination.index) {
            crMatQstn.splice(result.source.index, 1)
            crMatQstn.splice(result.destination.index, 0, matrxQstns);
        } else {
            crMatQstn.splice(result.source.index, 1)
            crMatQstn.splice(result.destination.index, 0, matrxQstns);
        }
        let i = 1
        for (let crIndex of crMatQstn) {
            crIndex.questionNumber = i
            i++
        }
        await this.setState({ matrixQstns: crMatQstn, sortDone: true })

        // dropped outside the list
        // if (!result.destination) {
        //     return;
        // }
    }
    setQuestion = async (e, number) => {
        let crMatQstn = this.state.matrixQstns
        let index = crMatQstn.findIndex(filterarr => filterarr.questionNumber === number)
        crMatQstn[index].qstn = e.target.value
        await this.setState({ matrixQstns: crMatQstn })
    }
    setRule = async (e, number) => {
        let crMatQstn = this.state.matrixQstns
        let index = crMatQstn.findIndex(filterarr => filterarr.questionNumber === number)
        crMatQstn[index].CrMatrixRule = e.target.value
        await this.setState({ matrixQstns: crMatQstn })
    }
    TaskhistoryTab() {
        return (
            <div className='col-12 px-0 splitTasks m-3' style={{ height: 400, width: "96%", overflow: "auto" }}>
                <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader mb-2" />
                <Table bordered={true} className={this.state.isLoading ? "mt-4" : ""}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Label/Purpose</th>
                            <th>Assigned To</th>
                        </tr>
                    </thead>
                    <tbody style={{ maxHeight: 80 }}>
                        {this.state.activities && this.state.activities.length > 0 ?
                            this.state.activities.map((item, i) => {
                                return <tr key={i}>
                                    <td >{dateFormats.formatDate(item.created, config.dateFormat)}</td>
                                    <td> {item.details.title}/{item.details.purpose}</td>
                                    <td> {item.details.assignTo}</td>

                                </tr>
                            }) : ""}
                    </tbody>

                </Table>
            </div>
        )

    }
    addNewRow() {
        let qestns = this.state.matrixQstns
        let lastElement = qestns[qestns.length - 1]
        let qstnNum = lastElement && lastElement.questionNumber ? lastElement.questionNumber : 0
        qestns.push({ qstn: "", qstnId: "", CrMatrixRule: "", questionNumber: qstnNum + 1 })
        this.setState({ matrixQstns: qestns })
    }

    render() {
        const { handleSubmit, t, type, rowData } = this.props;
        const { activeTab } = this.state;
        return (
            <Card className="cardForListMargin m-0 card">
                <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />

                <CardBody className="tableCardBody">
                    <form className="form" onSubmit={handleSubmit(this.submit)}>
                        <Row className="col-sm-12">
                            <h4><span className='postionRelative pt-2'>
                                <span className={`tableheadericon lnr lnr-users`} />
                                <b
                                ><Link to={"/crmatrix"} onClick={this.cancelReset} className="tableType pr-0" >
                                        {window.location.href.includes("crMatrixEdit") ? `Edit Cr Matrix Questions` : "Add Cr Matrix Questions"}
                                    </Link>{window.location.href.includes("sellersEdit") && this.state.showEntityName ? ` - ${this.state.showEntityName}` : null}</b>

                            </span>
                            </h4>
                        </Row>


                        <div style={{ width: "100%" }}>
                            <ModalBody className='modal__body edit_modal' style={{
                                padding: 10,
                                // overflowX: "hidden",
                                // overflowY: "clip",
                                height: 580
                            }}>
                                <span className="topbar__centerresponsered topBarImageAlignment" >{this.state.apierrorResponse ? this.state.apierrorMessage : null}</span>

                                <Card className='pb-0 ml-4' style={{ width: "100%" }}>
                                    <CardBody className='row p-0 d-block'>
                                        <div className='ml-auto mb-2 pr-4' style={{ float: "right" }}>
                                            <Button
                                                // style={{ float: "right" }}
                                                color='primary'
                                                className={type === "edit" ? "mb-0 mt-0" : "mb-0 "}
                                                outline
                                                type="submit">
                                                <FontAwesomeIcon
                                                    style={{ width: "15px" }}
                                                    icon={faSave}
                                                />
                                                {type === 'edit'
                                                    ?
                                                    'Update'
                                                    : 'Save'
                                                }
                                            </Button>
                                            <Button color='danger' outline type="button" className={type === 'edit' ? " ml-1 mb-0 mt-0" : "mr-auto ml-1 mb-0 "} onClick={this.cancelReset}>
                                                <FontAwesomeIcon
                                                    style={{ width: "15px" }}
                                                    icon={faArrowCircleLeft}
                                                />
                                                {('Back') ? ('Cancel') : 'Cancel'} </Button>
                                            <Button color="primary"
                                                // size="sm"
                                                // className='p-1 mt-1'
                                                outline
                                                // style={this.props.type === "Users" ? {} : { marginTop: 4 }}
                                                style={this.props.type === "List View" && this.state.userRole ? { display: "block", padding: "6px 25px", marginTop: "2px" } :
                                                    this.props.type === "Users" ? { padding: "5px 25px" } :
                                                        this.props.type !== "List View" ? { padding: "6px 25px", marginTop: "2px" } :
                                                            { display: "none" }}
                                                className="mb-0 ml-1 mr-0"
                                                onClick={(e) => this.addNewRow('add')}>
                                                <FontAwesomeIcon
                                                    icon='plus'
                                                    className='Addbtn' size='sm'
                                                    data-toggle="tool-tip" title={t("Add")}
                                                    onClick={(e) => this.addNewRow('add')}
                                                />
                                                Add New Question
                                            </Button>

                                        </div>
                                        {/* <Loader loader={this.state.isLoading} progress={this.state.progress} /> */}
                                        <div className="row mx-1 mt-3 col-sm-12">
                                            <div className={"col-sm-2"}   >
                                                <div className="form__form-group pb-1">
                                                    <div className='form__form-group-field kanban_notesfield mt-2' style={{ float: "right", fontWeight: '600' }}>
                                                        <label className="form__form-group-label" >{"CR Matrix Version"} </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-sm-4"} style={{ textAlign: "right" }}  >

                                                <Field
                                                    name={"crMatrixVersion"}
                                                    component={DefaultInput}
                                                    // options={this.state.matrixVersionArray}
                                                    // onChange={(e) => this.setMatrixVersion(e)}
                                                    // onChange={(e) => this.handleChange(e)}
                                                    // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                    placeholder={"crMatrixVersion1"}
                                                // isDisable={item.disable ? item.disable : false}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-12 d-flex' >
                                            <span className='col-sm-6' style={{ fontWeight: 'bold' }}>
                                                <label className="form__form-group-label" >{"CR Matrix Questions"} </label>
                                            </span>
                                            <span className='col-sm-6' style={{ fontWeight: 'bold' }}>
                                                <label className="form__form-group-label" >{"CR Matrix Rule"} </label>
                                            </span>
                                        </div>
                                        {/* {this.state.matrixQstns && this.state.matrixQstns.length > 0 ? this.state.matrixQstns.map((item, index) => (
                                            <div className="row mx-1 col-sm-12">
                                                <div className={"col-sm-6 d-flex pr-0 key_table"} >
                                                    <Field
                                                        name={"matrixId_" + item.questionNumber}
                                                        component={DefaultInput}
                                                        id={"item.qstnId"}

                                                    // validate={[required]}
                                                    // placeholder={item.placeholder}
                                                    />

                                                </div>
                                                <div className={"col-sm-6 d-flex pl-0 key_table"} >
                                                    <Field
                                                        name={"matrixQstn_" + item.questionNumber}
                                                        component={DefaultInput}
                                                        id={"item.qstnId"}

                                                    // validate={[required]}
                                                    // placeholder={item.placeholder}
                                                    />


                                                </div>
                                            </div>
                                        )) : null} */}
                                        {/* {console.log("this.state.matsbsksQsthn", this.state.matrixQstns)} */}
                                        <DragDropContext onDragEnd={this.onDragEnd} >
                                            <Container1>
                                                <Container5>
                                                    <Droppable droppableId={"matrixId_"} type="TASK" >
                                                        {(provided, snapshot) => (
                                                            <TaskList
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {
                                                                    this.state.matrixQstns && this.state.matrixQstns.length > 0 ? this.state.matrixQstns.map((item, index) => (

                                                                        <Draggable
                                                                            key={index}
                                                                            draggableId={"matrix" + item.questionNumber}
                                                                            index={index}
                                                                        >

                                                                            {(provided, snapshot) => (

                                                                                <Container
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={provided.innerRef}
                                                                                    isDragging={snapshot.isDragging}
                                                                                > <div className="row mx-1 col-sm-12" >
                                                                                        <div className={"col-sm-6 d-flex pr-0 key_table"} >
                                                                                            <input
                                                                                                name={"matrixId_" + item.questionNumber}
                                                                                                component={DefaultInput}
                                                                                                id={"item.qstnId"}
                                                                                                onChange={(e) => this.setQuestion(e, item.questionNumber)}
                                                                                                className={"key_table"}

                                                                                                value={item.qstn}

                                                                                            // validate={[required]}
                                                                                            // placeholder={item.placeholder}
                                                                                            />

                                                                                        </div>
                                                                                        <div className={"col-sm-6 d-flex pl-0 key_table"} >
                                                                                            <input
                                                                                                name={"matrixQstn_" + item.questionNumber}
                                                                                                component={DefaultInput}
                                                                                                id={"item.qstnId"}
                                                                                                className={"key_table"}
                                                                                                onChange={(e) => this.setRule(e, item.questionNumber)}
                                                                                                value={item.CrMatrixRule}
                                                                                            // validate={[required]}
                                                                                            // placeholder={item.placeholder}
                                                                                            />


                                                                                        </div>
                                                                                    </div>
                                                                                </Container>

                                                                            )}
                                                                        </Draggable>

                                                                    )
                                                                    ) : null}
                                                                {provided.placeholder}
                                                            </TaskList>
                                                        )}
                                                    </Droppable>
                                                </Container5>
                                            </Container1>
                                        </DragDropContext>
                                    </CardBody>
                                </Card>
                                {/* <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span> */}


                            </ModalBody>
                        </div >



                    </form >

                    {this.state.isnotesModal ? this.getNotesModal() : null}
                    {
                        this.state.sessionExpiryModal ?
                            <Redirect to="/log_in" />
                            : null
                    }
                    {this.state.forceLogout ? <Redirect to="/log_in" /> : ""}
                    {this.state.isKanbanModelClose ? <Redirect to="/crmatrix" /> : ""}
                    {this.state.expiredSessionModal ? this.expiredSessionModal() : null}

                    {this.state.sessionWarning ? this.getSessionWraningModal() : null}
                </CardBody >
            </Card >
        )
    }

}

UserPasswordResetModal = reduxForm({
    form: "User Reset Form11", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(UserPasswordResetModal);

// You have to connect() to any reducers that you wish to connect to yourself
UserPasswordResetModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(UserPasswordResetModal);

export default withTranslation('common')(UserPasswordResetModal);
