import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Employee from './components/Status';
const employee = ({ t }) => (
  <Container className="Custom_container">
    <Employee />
  </Container>
);

employee.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(employee);
